import React from "react";
// Customizable Area Start
import BuyerForgotPasswordController, {
    Props
} from "./BuyerForgotPasswordController.web";

import { withStyles, StyleRules } from "@material-ui/core/styles";
import { Grid, Box } from '@material-ui/core';
import { App_Logo, Pwd_visible_eye, Wave, hidePassword } from "../../email-account-registration/src/assets";
import { imgLeftFrame } from "./assets";
import { ErrorTag, EyeImage, FormBox, HeadingTypography, ImageSubBox, InputGrid, InputTagPassword, LeftGrid, RightBox, RightGrid, SmallImageBox, StyledButton, SubHeadingTypography } from "../../../components/src/CustomComponentForgotPassword.web";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

const styles: StyleRules = {
    // Customizable Area Start
    root: {
        background: "#000",
        minHeight: "100vh"
    },
    firstWave: {
        position: 'absolute',
        bottom: 0,
        width: '100%'
    },
    secondWave: {
        position: 'absolute',
        bottom: 0,
        right: '12%',
        width: '100%'
    },
    // Customizable Area End
}

export class BuyerResetPassword extends BuyerForgotPasswordController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;

        return (
            <Grid container className={classes.root}>
            <Loader loading={this.state.loading} />
                <LeftGrid item md={5} >
                    <ImageSubBox>
                        <img src={App_Logo} style={{ maxWidth: '100%' }} alt="logo-img3" />
                        <SmallImageBox>
                            <img src={imgLeftFrame} style={{ maxWidth: '100%' }} alt="frame-img3" />
                        </SmallImageBox>
                    </ImageSubBox>
                </LeftGrid>
                <RightGrid item md={7} >
                    <RightBox>
                        <HeadingTypography>Reset Password </HeadingTypography>
                        <SubHeadingTypography>Set your new password</SubHeadingTypography>
                        <FormBox container>
                            <InputGrid item xs={12} style={{ margin: "35px 0px 0px" }}>
                                <Box style={{ position: 'relative' }}>
                                    <InputTagPassword
                                        id="outlined-number"
                                        label="Password"
                                        onKeyDown={this.handlePasswordKey}
                                        type="text"
                                        value={this.state.showPassword ? this.state.copyPassword : "*".repeat(this.state.password.length)}
                                        className={this.state.showPassword ? "" : "showStar"}
                                        onChange={this.handlePasswordChange}
                                        onBlur={this.handlePasswordValidation}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="outlined"
                                        autoComplete="off"
                                    />
                                    <EyeImage src={this.state.showPassword ? hidePassword : Pwd_visible_eye} alt="" onClick={this.toggleShowPassword} className={this.state.showPassword ? "showStar" : ""} />
                                </Box>
                                <ErrorTag style={{ margin: '16px 0px' }}>{this.state.passwordError}</ErrorTag>
                            </InputGrid>
                            <InputGrid item xs={12} style={{ margin: "35px 0px 0px" }}>
                                <Box style={{ position: 'relative' }}>
                                    <InputTagPassword
                                        id="outlined-number"
                                        label="Re-enter password"
                                        onKeyDown={this.handleConfirmPasswordKey}
                                        type="text"
                                        value={this.state.showConfirmPassword ? this.state.copyConfirmPassword : "*".repeat(this.state.confirmPassword.length)}
                                        className={this.state.showConfirmPassword ? "" : "showStar"}
                                        onChange={this.handleConfirmPasswordChange}
                                        onBlur={this.handlePasswordValidation}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="outlined"
                                        autoComplete="off"
                                    />
                                    <EyeImage src={this.state.showConfirmPassword ? hidePassword : Pwd_visible_eye} alt="" onClick={this.toggleShowConfirmPassword} className={this.state.showConfirmPassword ? "showStar" : ""} />
                                </Box>
                                {<ErrorTag style={{ margin: '16px 0px' }}>{this.state.confirmPasswordError}</ErrorTag>}
                            </InputGrid>
                        </FormBox>
                        <StyledButton onClick={this.handleResetPassword}>Continue</StyledButton>
                    </RightBox>
                    <img src={Wave} className={classes.firstWave} alt="wave-img3" />
                    <img src={Wave} className={classes.secondWave} alt="wave-img3" />
                </RightGrid>
            </Grid>
        )
        // Customizable Area End
    }
}

// Customizable Area Start
export default withStyles(styles)(BuyerResetPassword);
// Customizable Area End
