import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData,setStorageData } from "../../../framework/src/Utilities";
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
interface Banner {
  id: string;
  type: string;
  attributes: {
    name: string;
    status: string;
    platform: string;
    redirection_url: string;
    duration: string;
    start_at: string;
    expire_at: string;
    banner: string
  }
}

export interface Store {
  id: number;
  name: null | string;
  business_name: string;
  phone_number: number;
  email: string;
  address: {
    id: number;
    country: string;
    latitude: null;
    longitude: null;
    address: null | string;
    addressble_id: number;
    addressble_type: string;
    address_type: string;
    created_at: string;
    updated_at: string;
    address_line_1: string;
    address_line_2: string;
    pin_code: number;
    city: string;
    state: string;
    shipment_type: string;
    account_id: number
  };
  profile_photo: string;
  background_image: string
}

export interface Category {
  id: number;
  type: string;
  attributes: {
    id: number;
    name: string;
    slug: string;
    light_icon: string
    created_at: string;
    updated_at: string;
    selected: boolean
  }
}


interface CityCollections {
  popular_cities: string[];
  other_cities: string[];
}

export const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000
};

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  banners: Array<Banner>;
  stores: Array<Store>,
  loading: boolean;
  categoryData: Array<Category>;
  categorySlice: {[key:string]: number};
  storeSlice: {[key:string]: number};
  storesSidbarData: Array<{[key:string]: string}>;
  storeSortValue: string;
  isSeeAll: boolean;
  citiesModal:boolean;
  citiesData:CityCollections
  message:string
  showError:boolean,
  messageModal: boolean,
  currentLocation:string,
  searchKeyword:string,
  latitude:number | null
  longitude:number | null
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CfdigitalmallController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getBannersApiID:string = "";
  getCategoryDataApiID: string = "";
  getStoresApiID: string = "";
  getLocationCityId:string =""
  getCitiesId:string=""
  getLatLongId:string=""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
        banners: [],
        stores: [],
        loading: false,
        categoryData: [],
        categorySlice:{
          startIdx: 0,
          endIdx: 10
        },
        storeSlice:{
          startIdx: 0,
          endIdx: 6
        },
        storesSidbarData :[
          {
            name: "newest"
          },
          {
            name: "most rated"
          },
          {
            name: "nearest"
          },
          {
            name: "alphabetical (A to Z)"
          },
          {
            name: "Alphabetical (Z to A)"
          },
        ],
        storeSortValue: "newest",
        isSeeAll: false,
        citiesModal:false,
        citiesData:{
          popular_cities: [],
          other_cities: []
        },
        showError:false,
        message:"",
        messageModal: false,
        currentLocation:configJSON.selectLocation,
        searchKeyword:"",
        latitude:null,
        longitude: null
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id
    ) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (this.isApiSuccess(responseJson)) {
        this.apiSuccess(apiRequestCallId, responseJson);
      } else {
        let errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    this.handleCityResponse(from, message)
    this.handleCitiesResponse(from, message)
    this.handlegetLatLongResponse(from, message)
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
 
  async componentDidMount(): Promise<void> {
    this.getBanners();
    this.getCategories();
    this.getCitiesData()
    const currentCity = await getStorageData("userCity")
    if(currentCity){
      this.setState({currentLocation: currentCity})
      this.selectedCity(currentCity)
    }else{
      this.selectedCity(configJSON.defaultAddress.name)
    }
  }
  apiCallFunction = async (data: {method: string; endPoint: string; body?:{[key:string]:string}}) => {
    this.setState({
      loading: true
    })
    let { method, endPoint, body } = data;
    const header = { 
      "Content-Type": "application/json"};
    let apiRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    apiRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    apiRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    apiRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    apiRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body ? apiRequestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(body)
        )
      : apiRequestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          body
        );
    runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
    return apiRequestMessage.messageId;
  };

  getBanners = async() => {
    this.getBannersApiID = await this.apiCallFunction({
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.getBannersEndPoint
    })
  }

  getCategories = async() => {
    this.getCategoryDataApiID = await this.apiCallFunction({
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.getCategoriesEndPoint
    })
  }

  getStores = async(city:string) => {
    const currentLocation = this.state.currentLocation === configJSON.selectLocation ? this.state.currentLocation :null
    const endpoint = `${configJSON.getStoresEndPoint}?latitude=${this.state.latitude}&longitude=${this.state.longitude}&city=${city}`
    this.getStoresApiID = await this.apiCallFunction({
      method: configJSON.validationApiMethodType,
      endPoint: endpoint
    })
  }

  isApiSuccess = (response: Banner) => {
    return response ? true : false
  }

  apiSuccess = (apiCallId: string, response: {data: Array<Banner>} & {stores: Array<Store>} & {data:  Array<Category>}) => {
    if (apiCallId === this.getBannersApiID) {
      this.setState({
        banners: response.data
      })
    }
    if (apiCallId === this.getCategoryDataApiID) {
      this.setState({
        categoryData: response.data})
    }
    if (apiCallId === this.getStoresApiID) {
      this.setState({
        stores: response.stores,
        citiesModal:false
      }, ()=> {
        if(this.state.isSeeAll){
          this.setState({
            storeSlice: {
              startIdx: 0,
              endIdx: this.state.stores.length
            }
          })
        }
      })
    }
    this.setState({
      loading: false
    })
  }

  goToStore = (storeId: number) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage),"Store");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        message.addData(getName(MessageEnum.NavigationScreenNameMessage), storeId);
        this.send(message)
  };

  goToBuyerHome = (viewType: string) => {
    const msgData: Message = new Message(
      getName(MessageEnum.NavigationMessageLandingPage)
    );
    msgData.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msgData.addData(getName(MessageEnum.NavigationScreenNameMessage), "BuyerDashboard");
    msgData.addData(getName(MessageEnum.LandingPageViewType), viewType);
    this.send(msgData);
  };
  

  handleStoreSeeAll = () => {
    this.setState({
      storeSlice: {
        startIdx: this.state.storeSlice.startIdx,
        endIdx: this.state.stores.length
      },
      isSeeAll: true
    })
  }

  handleStoreSort = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      storeSortValue: (event.target as HTMLInputElement).value
    })
  };

  handleChangeLocationModal=()=>{
    this.setState({citiesModal:true})
  }

  handleCloseLocation=()=>{
    this.setState({citiesModal:false})
  }
  getGpsLocation = () => {
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
            this.showPosition,
            this.showError,
            {
              enableHighAccuracy: true,
              maximumAge: 60000, 
              timeout: 6000 
            }
        );
    } else {
        this.setState({
            message: configJSON.unSupportedMessage,
            messageModal: true,
          })
    }
};

showPosition = async(position: GeolocationPosition) => {
  const latitude = position.coords.latitude;
  const longitude = position.coords.longitude;
  const endPoint = `${configJSON.getCity}lat=${latitude}&lon=${longitude}&format=json`
  const buyertoken = await getStorageData("buerLoginToken");
  const header = { "token": buyertoken, "Content-Type": 'application/json' };
  const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
  this.getLocationCityId = requestMessage.messageId;
  requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
  requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
  requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.validationApiMethodType);
  runEngine.sendMessage(requestMessage.id, requestMessage);
};


showError = (error: GeolocationPositionError) => {
  switch (error.code) {
      case error.PERMISSION_DENIED:
        this.setState({
          message: configJSON.userDeniedGeoLocation,
          messageModal: true,
        });
        this.handleCloseLocation();
          break;
      case error.POSITION_UNAVAILABLE:
          this.setState({
              message: configJSON.locatonInformationUnavailable,
              messageModal: true,
            })
          break;
      case error.TIMEOUT:
          this.setState({
              message: configJSON.locationTimedOut,
              messageModal: true,
            })
          break;
  }
};

handleClosemessageModal=()=>{
  this.setState({
      messageModal: false
  })
}

handleCityResponse = (from: string, message: Message) => {
  if (this.getLocationCityId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
    let currentCity;
      if (responseJson) {
        if (responseJson.address.city) {
          currentCity = responseJson.address.city.replace(" District", "").replace(" City", "");
        } else if (responseJson.address.county) {
          currentCity = responseJson.address.county.replace(" District", "").replace(" City", "");
        } else {
          currentCity = responseJson.address.state_district.replace(" District", "").replace(" City", "");
        }
        this.setState({
          currentLocation: currentCity
        });
        setStorageData('userCity', currentCity);
        this.handleCloseLocation()
      }
      this.selectedCity(currentCity)
  }
}
handleCitiesResponse=(from: string, message: Message)=>{
  if (this.getCitiesId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
    const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson) {
      this.setState({
        citiesData: responseJson
      });
    }
}
}
handlegetLatLongResponse=(from: string, message: Message)=>{
  if (this.getLatLongId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
    const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson) {
     const latitude =responseJson[0].lat
     const longitude =  responseJson[0].lon
     this.setState({latitude:latitude,longitude:longitude},()=>{
      this.getStores(this.state.currentLocation);
     })
    }
}
}

searchCity=(event:React.ChangeEvent<{ value: string }>)=>{
  this.setState({searchKeyword:event.target.value},()=>{
    this.getCitiesData()
  })
}


getCitiesData=async()=>{
  const endPoint = `${configJSON.getCities}${this.state.searchKeyword}`
  const header = { "Content-Type": 'application/json' };
  const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
  this.getCitiesId = requestMessage.messageId;
  requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
  requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
  requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.validationApiMethodType);
  runEngine.sendMessage(requestMessage.id, requestMessage);
}
selectedCity=(eachCity:string)=>{
  setStorageData('userCity', eachCity);
  this.setState({currentLocation:eachCity,loading:true})
  const endPoint = `${configJSON.getLatLong}${eachCity}&format=json&addressdetails=1&limit=1`
  const header = { "Content-Type": 'application/json' };
  const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
  this.getLatLongId = requestMessage.messageId;
  requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
  requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
  requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.validationApiMethodType);
  runEngine.sendMessage(requestMessage.id, requestMessage);
}
  goToFeedMall = (route : string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), route);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  // Customizable Area End
}
