import React from "react";

// Customizable Area Start

import AccountRegistrationController, { Props } from "./AccountRegistrationController";
import {
    Box,
    Grid,
    Typography,
    Button,
    styled,
    InputLabel,
} from "@material-ui/core";
import { Alert } from '@material-ui/lab';

import {
    App_Logo, centerImage, leftImage, rightImage, profileImgLeft, profileImgCenter, profileImgRight, ImageOverlay, radar, BoxShaddow_Img, Save_logo_2, selectBox, Wave
} from "./assets";
import OtpInputField from "./OtpInputField";
import LoginFailModalWeb from "../../../components/src/LoginFailModal.web";

// Customizable Area End




export default class RegistrationOTP extends AccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
            <>
                { }
                <ParentGridOTP container>
                    <ImageGridOTP item md={6}>
                        <ImageSubBox>
                            <ImageLogoOTP src={App_Logo} alt="Logo Image" />
                            <SmallImageBoxOTP>
                                <SideImageDivOTP>
                                    <SideImageOTP src={leftImage} alt="Logo Image" />
                                    <Box style={{
                                        position: 'absolute', left: 25, top: 21, display: 'flex', gap: 21, borderRadius: '46px',
                                        background: 'rgba(255, 31, 113, 0.25)', backdropFilter: 'blur(13.591408729553223px)', padding: '12px 18px'
                                    }}>
                                        <img src={radar} />
                                        <Typography
                                            style={{
                                                color: '#FFF',
                                                fontFamily: 'Poppins-Regular',
                                                fontSize: '20px',
                                                fontStyle: 'normal',
                                                fontWeight: 600,
                                                lineHeight: 'normal',
                                                letterSpacing: '0.229px'
                                            }}
                                        >
                                            24
                                        </Typography>
                                    </Box>
                                    <img style={{ position: 'absolute', right: 18, top: 20 }} src={Save_logo_2} />
                                    <Box style={{ position: 'absolute', left: 38, bottom: 25, display: 'flex', gap: '25.43px', alignItems: 'center', zIndex: 1, }}>
                                        <img src={profileImgLeft} />
                                        <Typography style={{ color: 'white', fontWeight: 500, fontSize: '20px', lineHeight: 'normal', letterSpacing: '0.25px', fontFamily: "'Poppins-Regular', sans-serif", }}>
                                            Mike Torello
                                        </Typography>
                                    </Box>
                                    <img style={{ position: 'absolute', bottom: 0, left: 0, width: '100%' }} src={ImageOverlay} alt="overlay" />
                                    <ShadowSideImgOTP src={BoxShaddow_Img} alt="overlay" />
                                </SideImageDivOTP>
                                <LeftImageDivOTP>
                                    <LeftImageOTP src={centerImage} alt="Logo Image" />
                                    <Box style={{
                                        position: 'absolute', left: 25, top: 21, display: 'flex', gap: 21, borderRadius: '46px',
                                        background: 'rgba(255, 31, 113, 0.25)', backdropFilter: 'blur(13.591408729553223px)', padding: '12px 18px'
                                    }}>
                                        <img src={radar} />
                                        <Typography
                                            style={{
                                                color: '#FFF',
                                                fontFamily: 'Poppins-Regular',
                                                fontSize: '20px',
                                                fontStyle: 'normal',
                                                fontWeight: 600,
                                                lineHeight: 'normal',
                                                letterSpacing: '0.229px'
                                            }}
                                        >
                                            24
                                        </Typography>
                                    </Box>
                                    <img style={{ position: 'absolute', right: 18, top: 20 }} src={Save_logo_2} />
                                    <Box style={{ position: 'absolute', left: 38, bottom: 25, display: 'flex', gap: '25.43px', alignItems: 'center', zIndex: 1, }}>
                                        <img src={profileImgCenter} />
                                        <Typography style={{ color: 'white', fontWeight: 500, fontSize: '20px', lineHeight: 'normal', letterSpacing: '0.25px', fontFamily: "'Poppins-Regular', sans-serif", }}>
                                            Col. Roderick
                                        </Typography>
                                    </Box>
                                    <img style={{ position: 'absolute', bottom: 0, left: 0, width: '100%' }} src={ImageOverlay} alt="overlay" />
                                    <ShadowCenterImgOTP src={BoxShaddow_Img} alt="overlay" />
                                </LeftImageDivOTP>
                                <SideImageDivOTP>
                                    <CategorySelectBoxOTP style={{ width: 150, height: 150, borderRadius: '50%', backgroundColor: 'linear-gradient(137deg, #FFB2CF 0%, #FEC559 100%)', position: 'absolute', right: -75, top: -75, zIndex: 11 }}>
                                        <img style={{ transform: 'scale(1.59)' }} src={selectBox} alt="gradient" />
                                        <span style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', color: 'white', textAlign: 'center', fontSize: 35, fontWeight: 500 }}> Select Your Category</span>
                                    </CategorySelectBoxOTP>
                                    <SideImageOTP src={rightImage} alt="Logo Image" />
                                    <SideImageOTPBox >
                                        <img src={radar} />
                                        <LiveCountText>
                                            24
                                        </LiveCountText>
                                    </SideImageOTPBox>
                                    <img style={{ position: 'absolute', right: 18, top: 20 }} src={Save_logo_2} />
                                    <Box style={{ position: 'absolute', left: 38, bottom: 25, display: 'flex', gap: '25.43px', alignItems: 'center', zIndex: 1, }}>
                                        <img src={profileImgRight} />
                                        <Typography style={{ color: 'white', fontWeight: 500, fontSize: '20px', lineHeight: 'normal', letterSpacing: '0.25px', fontFamily: "'Poppins-Regular', sans-serif", }}>
                                            Theodore T.
                                        </Typography>
                                    </Box>
                                    <img style={{ position: 'absolute', bottom: 0, left: 0, width: '100%' }} src={ImageOverlay} alt="overlay" />
                                    <ShadowSideImgOTP src={BoxShaddow_Img} alt="overlay" />

                                </SideImageDivOTP>
                            </SmallImageBoxOTP>
                        </ImageSubBox>
                    </ImageGridOTP>
                    <RightGridOTP item md={6}>
                        <WaveImgOTP src={Wave} alt="overlay" />
                        <WaveImg2OTP src={Wave} alt="overlay" />
                        <ContentBoxOTP>
                            <ContentInnerBox>
                                <HeadingTypographyOTP>Verify <span>{this.state.accountType == "sms_account" ? "Mobile" : "email"}</span></HeadingTypographyOTP>
                                <Box>
                                    <ContentTypographyOTP variant="body1" >Please enter the OTP shared via {this.state.accountType == "sms_account" ? "SMS" : "email"}</ContentTypographyOTP>
                                </Box>
                                <FormBoxOTP container>
                                    <OtpInputField value={this.state.otpState} valueLength={6} onChange={this.handleOTPState} />

                                    <Box style={{ width: '100%' }}>
                                        <TimerBox style={{ display: 'flex', gap: 10, alignItems: 'center', justifyContent: 'space-between' }}>
                                            <Typography style={{
                                                color: 'white',
                                                fontSize: '16px',
                                                fontStyle: 'normal',
                                                fontWeight: 600,
                                                lineHeight: '19px',
                                                fontFamily: "'Poppins-Regular', sans-serif",
                                                opacity: "0.7"
                                            }}>00:{this.state.time < 10 && 0}{this.state.time}</Typography>
                                            <InputLabel style={{
                                                color: 'white',
                                                fontSize: '16px',
                                                fontStyle: 'normal',
                                                fontWeight: 600,
                                                lineHeight: '19px',
                                                fontFamily: "'Poppins-Regular', sans-serif",
                                                borderBottom: '2px solid white',
                                                cursor: this.state.time === 0 ? "pointer" : "normal",
                                                opacity: "0.7"
                                            }}
                                                onClick={this.resendClick}

                                            >
                                                Resend the code
                                            </InputLabel>
                                        </TimerBox>
                                        {this.state.otpError ? (<ErrorTag>{this.state.otpError}</ErrorTag>) : ""}
                                        <ButtonBox >
                                            {this.state.showMobileField.toString()}
                                            <SignupButton variant="contained" color="primary" onClick={this.state.accountType == "sms_account" ? this.validateOTPMobile : this.validateOTPEmail}>
                                                Continue
                                            </SignupButton>
                                        </ButtonBox>
                                    </Box>
                                </FormBoxOTP>
                            </ContentInnerBox>
                        </ContentBoxOTP>
                    </RightGridOTP>
                    <LoginFailModalWeb
                        displayPopup={this.state.successMessage}
                        closeErrorPopup={this.successAlertOTP}
                        titleMessage={'Signup Successful'}
                        errorMessage={'You are successfully registered with Flixoo Live'} />
                </ParentGridOTP>
            </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const ParentGridOTP = styled(Grid)({
    background: "#000",
    minHeight: "100vh"
})
const LeftImageDivOTP = styled(Box)({
    position: 'relative',
    // left: '22px',
    marginTop: 369,
    '@media(max-width: 959px)': {
        left: 'unset',
        marginTop: 'unset',
    },
    '@media(max-width: 1280px)': {
        marginBottom: '50px',
    },
    '@media(max-width: 767px)': {
        marginBottom: '0px',
    }
})
const SideImageDivOTP = styled(Box)({
    position: 'relative',
    // left: '22px',
    marginTop: 325,
    transform: 'scale(0.63)',
    '@media(max-width: 959px)': {
        left: 'unset',
        marginTop: 'unset',
    },
})
const LeftImageOTP = styled('img')({
    width: "282px",
    objectFit: 'cover',
    borderRadius: '15px',
    height: '100%',
})
const SideImageOTP = styled('img')({
    width: "282px",
    objectFit: 'cover',
    borderRadius: '15px',
    height: '100%',
})
const SideImageOTPBox = styled(Box)({
    position: 'absolute',
    left: 25,
    top: 21,
    display: 'flex',
    gap: 21,
    borderRadius: '46px',
    background: 'rgba(255, 31, 113, 0.25)',
    backdropFilter: 'blur(13.591408729553223px)',
    padding: '12px 18px'
})
const LiveCountText = styled(Typography)({
    color: '#FFF',
    fontFamily: 'Poppins-Regular',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    letterSpacing: '0.229px'
})
const RightGridOTP = styled(Grid)({
    background: 'black',
    width: '100%',
    position: "relative",
    overflow: "hidden",
    height: '100vh',
    '@media(max-width: 960px)': {
        height: "unset",
    },
    '@media(max-width: 767px)': {
        marginTop: -100,
    },
    '@media(max-width: 500px)': {
        marginTop: -250,
    }
})

const ContentInnerBox = styled(Box)({
    '@media(max-width: 767px)': {
        margin: "0px 20px"
    }
})
const ImageGridOTP = styled(Grid)({
    // border: '1px solid #FFF',
    background: 'rgba(255, 255, 255, 0.25)',
    backdropFilter: 'blur(13.591408729553223px)',
    width: "100%",
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    height: '100vh',
    '@media(max-width: 959px)': {
        height: 'unset',
        alignItems: 'flex-end',
        paddingBottom: '50px',
        background: 'black',
        border: 'none'
    },
    '@media(min-width: 1200)': {
        height: "100%",
    }
})
const TimerBox = styled(Box)({
    '@media(max-width: 480px)': {
        maxWidth: "300px",
        margin: "auto"
    }
})

const ImageLogoOTP = styled('img')({
    position: 'absolute',
    top: 91,
    left: 20,
    '@media(max-width: 959px)': {
        top: -130,
        left: 30,
        width: '180px'
    }
})
const SmallImageBoxOTP = styled(Box)({
    display: 'flex',

    // '@media(min-width: 767px) and (max-width: 900px)': {
    //     transform: 'scale(0.6)'
    // },
    '@media(max-width: 767px)': {
        marginTop: 100,
    },
})
const ImageSubBox = styled(Box)({
    maxWidth: 800,
    position: 'relative',
    '@media(min-width: 900px) and (max-width: 959px)': {
        marginTop: '200px', transform: 'scale(0.8)',
        transformOrigin: 'top',
    },
    '@media(max-width: 900px)': {
        marginTop: '200px',
        transform: 'scale(0.8)',
        transformOrigin: 'top',
    },
    '@media(max-width: 620px)': {
        marginTop: '150px',
        transform: 'scale(0.6)',
        transformOrigin: 'top',
    },
    '@media(max-width: 500px)': {
        marginTop: '150px',
        transform: 'scale(0.4)',
        transformOrigin: 'top',
    },
    '@media(min-width: 1540px) and (max-width: 1920px)': {
        transform: 'scale(0.8)',
        transformOrigin: 'top',
    },
    '@media(min-width: 1280px) and (max-width: 1539px)': {
        transformOrigin: 'top',
        transform: 'scale(0.6)',
    },
    '@media(min-width: 1100px) and (max-width: 1280px)': {
        transform: 'scale(0.5)',
        transformOrigin: 'top',
        marginTop: 50,
        minHeight: '100vh',
    },
    '@media(min-width: 960px) and (max-width: 1100px)': {
        transform: 'scale(0.4)',
        transformOrigin: 'top',
        marginTop: 50,
        minHeight: '100vh',
    },
})
const ContentBoxOTP = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    marginTop: 313,
    '@media(min-width: 1440px) and (max-width: 1920px)': {
        marginTop: 200,
        transform: 'scale(0.8)',
        transformOrigin: 'top',
    },
    '@media(max-width: 1280px)': {
        marginTop: 50,
        paddingBottom: 50,
    },
    '@media(min-width: 1280px) and (max-width: 1439px)': {
        transformOrigin: 'top',
        marginTop: 200,
        transform: 'scale(0.6)',
    },
    '@media(max-width: 959px)': {
        marginLeft: 0,
        marginTop: 30,
        paddingBottom: 50,
    },
    '@media(min-width: 1100px) and (max-width: 1280px)': {
        transform: 'scale(0.7)',
        // transformOrigin: 'top',
    },
    '@media(min-width: 960px) and (max-width: 1100px)': {
        transform: 'scale(0.6)',
        // transformOrigin: 'top',
    },
})

const ContentTypographyOTP = styled(Typography)({
    color: '#FFF',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    maxWidth: 678,
    paddingBottom: 51,
    textAlign: 'center',
    fontFamily: "'Poppins-Regular', sans-serif",
    '@media(max-width: 959px)': {
        fontSize: '20px',
        lineHeight: '28px',
        paddingInline: 20,
    }
})
const HeadingTypographyOTP = styled(Typography)({
    color: '#FFF',
    textAlign: 'center',
    fontFamily: "'Poppins-Regular', sans-serif",
    paddingBottom: 10,
    fontSize: '60px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    '@media(max-width: 959px)': {
        fontSize: '44px',
        letterSpacing: '-1.8px',
        lineHeight: '58px',
        paddingInline: 20,
    }
})

const ButtonBox = styled(Box)({
    display: "flex",
    "& .MuiButton-containedPrimary:hover": {
        backgroundColor: "#F5EA16"
    },
    '@media(max-width: 480px)': {
        display: 'block'
    }
})
const SignupButton = styled(Button)({
    borderRadius: '34px',
    background: '#F5EA16',
    width: '100%',
    maxWidth: '412px',
    margin: '40px auto',
    height: '68px',
    color: '#000',
    textAlign: 'center',
    fontFamily: 'Poppins-Regular',
    fontSize: '22px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    letterSpacing: '0.417px',
    textTransform: "initial",
});
const OverlayBoxOTP = styled(Box)({
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: 'rgba(0, 0, 0, 0.50)',
    filter: 'blur(13px)',
    width: '100vw',
    height: '100vh',
});
const AlertBoxOTP = styled(Alert)({
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: 'white',
    textAlign: 'center',
    borderRadius: 20,
    maxWidth: 300,
    zIndex: 1,
    '& .MuiSvgIcon-root': {
        position: 'absolute',
        top: '10px',
        right: '10px'
    },
    '& .MuiAlert-message': {
        width: '100%'
    },
    '& .MuiAlert-icon': {
        display: 'none'
    },
    "& .MuiButton-containedPrimary:hover": {
        backgroundColor: "#8080805e"
    }
});
const AlertHeadTextOTP = styled(Box)({
    color: '#000',
    textAlign: 'center',
    fontFamily: 'Poppins-Regular, sans-serif',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    borderBottom: '1px solid #E9E9E9',
    paddingBottom: '20px',
    maxWidth: '200px',
    margin: 'auto',
});
const AlertTextOTP = styled(Box)({
    color: '#000',
    textAlign: 'center',
    fontFamily: 'Poppins-Regular, sans-serif',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    paddingTop: '20px'
})
const FormBoxOTP = styled(Grid)({
    width: '100%',
    maxWidth: '478px',
    margin: "auto",
    '& .otp-group': {
        display: "flex",
        gap: "12.5px",
        '& input': {
            fontSize: "24px",
            border: '3px solid #ffffff7a',
            borderRadius: '16px!important',
            textDecoration: '2px solid underline',
            textUnderlinePosition: 'under'
        },
    },
    '@media (max-width: 600px)': {
        width: '90%',
        margin: '0 auto',
        justifyContent: 'center'
    },
    '& .MuiInput-underline::before': {
        borderBottom: 'none',
    },
    '& .MuiInput-underline::after': {
        borderBottom: 'none',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled)::before': {
        borderBottom: 'none'
    },
    "& .MuiNativeSelect-select:focus": {
        backgroundColor: 'white',
    },
    '& .MuiOutlinedInput-input': {
        fontSize: 31,
        border: '1px solid rgba(255, 255, 255, 0.50)',
        background: 'black',
        borderRadius: 12,
        color: 'white',
        height: '46px',
        textAlign: 'center'
    },
    "& .MuiCheckbox-root": {
        color: "#fff"
    },
    '& input::-webkit-outer-spin-button': {
        WebkitAppearance: 'none',
    },
    '& input::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
    },
    '& input[type="number"]': {
        MozAppearance: 'textfield',
        textDecoration: 'underline'
    }
});
const ShadowSideImgOTP = styled('img')({
    position: 'absolute',
    bottom: -50,
    left: -25,
    width: '120%',
});
const ShadowCenterImgOTP = styled('img')({
    position: 'absolute',
    bottom: -50,
    left: -70,
    width: '423px',
    height: '53px'
});
const ErrorTag = styled(Typography)({
    fontSize: 18,
    color: 'red',
    paddingTop: 15,
});
const CategorySelectBoxOTP = styled(Box)({
    '@media(max-width: 767px)': {
        display: 'none'
    }
})
const WaveImgOTP = styled('img')({
    position: 'absolute',
    bottom: 0,
    width: '100%'
})
const WaveImg2OTP = styled('img')({
    position: 'absolute',
    bottom: 0,
    left: -169,
    width: '100%'
})


// Customizable Area End
