import React from "react";
// Customizable Area Start
import {
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  TextInput,
  ScrollView,
  TouchableWithoutFeedback,
  ActivityIndicator,
  Platform,
  SafeAreaView,
  KeyboardAvoidingView
} from "react-native";
// Customizable Area End
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp
} from "react-native-responsive-screen";
import { RFValue } from "react-native-responsive-fontsize";
// // @ts-ignore
// import { Dropdown } from "react-native-material-dropdown";
import moment from "moment";
import FedexintegrationController, {
  Props
} from "./FedexintegrationController";
// // @ts-ignore
// import DatePicker from "react-native-datepicker";
export default class Fedexintegration extends FedexintegrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  
  // Customizable Area Start
  setTextInputValue = (attr_name: string, value: string) => {
    this.setState({ [attr_name]: value });
  };
  checkLongFunc = () => {
    let checklong = parseFloat(this.state.longitude);
    if (Number.isInteger(checklong)) {
      this.setState({
        dlongerr: "please enter decimal value e.g 12.345345"
      });
    } else {
      this.setState({
        dlongerr: ""
      });
    }
  };
  checkLatFunc = () => {
    let checklong = parseFloat(this.state.latitude);
    if (Number.isInteger(checklong)) {
      this.setState({
        dlaterr: "please enter decimal value e.g 12.345345"
      });
    } else {
      this.setState({
        dlaterr: ""
      });
    }
  };
  checkpLongFunc = () => {
    let checklong = parseFloat(this.state.p_longitude);
    if (Number.isInteger(checklong)) {
      this.setState({
        plongerr: "please enter decimal value e.g 12.345345"
      });
    } else {
      this.setState({
        plongerr: ""
      });
    }
  };
  checkpLatFunc = () => {
    let checklong = parseFloat(this.state.p_latitude);
    if (Number.isInteger(checklong)) {
      this.setState({
        platerr: "please enter decimal value e.g 12.345345"
      });
    } else {
      this.setState({
        platerr: ""
      });
    }
  };
  setDeliveryArrivalTime = (end_date: string) => {
    var end_dateUTC = moment.utc(
      end_date.substr(0, end_date.indexOf(" ")) +
        " " +
        end_date.substr(end_date.indexOf(" "), end_date.length),
      "YYYY-MM-DD  HH:mm:ss"
    );
    if (
      end_dateUTC.isAfter(
        this.state.delivery_begin_at.replace(/\s/g, "T") + "Z"
      )
    ) {
      this.setState({
        delivery_end_at: end_date,
        deliveryDateError: ""
      });
    } else {
      this.setState({
        deliveryDateError:
          "delivery arrival begin time should be before delivery arrival end window"
      });
    }
  };

  setPickupArrivalTime = (end_date: string) => {
    var end_dateUTC = moment.utc(
      end_date.substr(0, end_date.indexOf(" ")) +
        " " +
        end_date.substr(end_date.indexOf(" "), end_date.length),
      "YYYY-MM-DD  HH:mm:ss"
    );
    if (
      end_dateUTC.isAfter(this.state.pickup_begin_at.replace(/\s/g, "T") + "Z")
    ) {
      this.setState({
        pickup_end_at: end_date,
        pickupDateError: ""
      });
    } else {
      this.setState({
        pickupDateError:
          "pickup arrival begin time should be before pickup arrival end window"
      });
    }
  };
  // Customizable Area End

  render() {
    return (
      //Merge Engine DefaultContainer
      <SafeAreaView style={styles.defaultContainer}>
        <KeyboardAvoidingView
          behavior={Platform.OS === "ios" ? "padding" : "height"}
          enabled
          keyboardVerticalOffset={Platform.OS == "ios" ? hp("11%") : hp("1%")}
          style={{ flex: 1 }}
        >
          {/* Customizable Area Start */}
          <ScrollView
            ref={scroll => {
              this.scroll = scroll;
            }}
            contentInsetAdjustmentBehavior="automatic"
            contentContainerStyle={styles.contentContainer}
            style={styles.scrollViewStyle}
            onContentSizeChange={() => {
              if (this.state.isSubmit) {
                this.scroll.scrollToEnd({ animated: true });
              }
              if (this.state.loadingShipment) {
                this.scroll.scrollToEnd({ animated: true });
              }
            }}
          >
            <TouchableWithoutFeedback onPress={() => this.hideKeyboard()}>
              <View style={styles.innerContainer}>
                <View style={styles.textInputView}>
                  <Text style={styles.inputLabel}>Load Description</Text>
                  <TextInput
                    testID="txtInputload_description"
                    style={[styles.textInput, {}]}
                    onChangeText={text =>
                      this.setTextInputValue("load_description", text)
                    }
                    value={this.state.load_description}
                  />
                </View>
                <Text style={styles.inputLabel}>
                  Packaging Type<Text style={styles.inputLabel}>*</Text>
                </Text>
                <View style={styles.dropdownStyle}>
                  {/* <Dropdown
                    testID="dropdownPackageType"
                    inputContainerStyle={styles.customDropdownStyle}
                    containerStyle={styles.customDropdownStyle1}
                    label="Select Type"
                    data={this.state.item_type_list}
                    onChangeText={(value: any) =>
                      this.setState({ item_type: value })
                    }
                  /> */}
                </View>

                <View style={styles.deliveryBarStyle}>
                  <Text style={styles.shipLabel}>Delivery Details:</Text>
                </View>
                <View style={styles.textInputView}>
                  <Text style={styles.inputLabel}>
                    Name<Text style={styles.inputLabel}>*</Text>
                  </Text>
                  <TextInput
                    testID="txtInputName"
                    style={[styles.textInput, {}]}
                    onChangeText={text => this.setTextInputValue("name", text)}
                    value={this.state.name}
                  />
                </View>
                <View style={styles.textInputView}>
                  <Text style={styles.inputLabel}>
                    Phone<Text style={styles.inputLabel}>*</Text>
                  </Text>
                  <TextInput
                    testID="txtInputPhone"
                    keyboardType="number-pad"
                    style={[styles.textInput, {}]}
                    maxLength={10}
                    onChangeText={text => this.setTextInputValue("phone", text)}
                    value={this.state.phone}
                  />
                </View>
                <View style={styles.textInputView}>
                  <Text style={styles.inputLabel}>
                    Email<Text style={styles.inputLabel}>*</Text>
                  </Text>
                  <TextInput
                    testID="txtInputEmail"
                    keyboardType="email-address"
                    style={[styles.textInput, {}]}
                    onChangeText={text => this.setTextInputValue("email", text)}
                    value={this.state.email}
                  />
                </View>
                <View style={styles.textInputView}>
                  <Text style={styles.inputLabel}>
                    Address<Text style={styles.inputLabel}>*</Text>
                  </Text>
                  <TextInput
                    testID="txtInputAddress"
                    style={[styles.textInput, {}]}
                    onChangeText={text =>
                      this.setTextInputValue("address", text)
                    }
                    value={this.state.address}
                  />
                </View>
                <View style={styles.textInputView}>
                  <Text style={styles.inputLabel}>
                    City<Text style={styles.inputLabel}>*</Text>
                  </Text>
                  <TextInput
                    testID="txtInputCity"
                    style={[styles.textInput, {}]}
                    onChangeText={text => this.setTextInputValue("city", text)}
                    value={this.state.city}
                  />
                </View>
                <View style={styles.textInputView}>
                  <Text style={styles.inputLabel}>
                    Country<Text style={styles.inputLabel}>*</Text>
                  </Text>
                  <TextInput
                    testID="txtInputCountry"
                    style={[styles.textInput, {}]}
                    onChangeText={text =>
                      this.setTextInputValue("country", text)
                    }
                    value={this.state.country}
                  />
                </View>
                <View style={styles.textInputView}>
                  <Text
                    style={[styles.inputLabel, { marginVertical: hp("1%") }]}
                  >
                    Begining At<Text style={styles.inputLabel}>*</Text>
                  </Text>
                  {/* <DatePicker
                    style={styles.basicDateStyle}
                    date={this.state.delivery_begin_at}
                    mode="datetime"
                    testID="delivery_begingdate"
                    placeholder="selectDate"
                    format="YYYY-MM-DD HH:mm:ss"
                    minDate={moment().year() + "-01-01"}
                    maxDate={1 + moment().year() + "-01-01"}
                    confirmBtnText="Confirm"
                    cancelBtnText="Cancel"
                    customStyles={{
                      datePicker: {
                        backgroundColor: "#d1d3d8",
                        justifyContent: "center"
                      },
                      dateIcon: {
                        position: "absolute",
                        right: 5,
                        top: 4
                      },
                      dateInput: {
                        borderColor: "#ccc",
                        borderWidth: 1,
                        height: hp("5%"),
                        borderRadius: hp("1%"),
                        width: "100%",
                        //color: "#ccc",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        paddingHorizontal: 10
                      }
                    }}
                    onDateChange={(begin_date: any) => {
                      this.setState({
                        delivery_begin_at: begin_date
                      });
                    }}
                  /> */}
                </View>
                <View style={styles.textInputView}>
                  <Text
                    style={[styles.inputLabel, { marginVertical: hp("1%") }]}
                  >
                    End At<Text style={styles.inputLabel}>*</Text>
                  </Text>
                  {/* <DatePicker
                    // date={
                    //   this.state.delivery_end_at === ""
                    //     ? this.state.delivery_end_at
                    //     : this.state.delivery_end_time
                    // }
                    // mode={this.state.delivery_end_at === "" ? "date" : ""}
                    style={styles.basicDateStyle}
                    date={this.state.delivery_end_at}
                    mode="datetime"
                    testID="delivery_enddate"
                    placeholder="selectDate"
                    //placeholderTextColor="red" HH:mm:ss
                    format="YYYY-MM-DD HH:mm:ss"
                    // minDate={"01-01-" + moment().year()}
                    // maxDate={"01-01-" + moment().year() + 1}
                    minDate={moment().year() + "-01-01"}
                    maxDate={1 + moment().year() + "-01-01"}
                    confirmBtnText="Confirm"
                    cancelBtnText="Cancel"
                    customStyles={{
                      datePicker: {
                        backgroundColor: "#d1d3d8",
                        justifyContent: "center"
                      },
                      dateIcon: {
                        position: "absolute",
                        right: 5,
                        top: 4
                      },
                      dateInput: {
                        borderColor: "#ccc",
                        borderWidth: 1,
                        height: hp("5%"),
                        borderRadius: hp("1%"),
                        width: "100%",
                        //color: "#ccc",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        paddingHorizontal: 10
                      }
                    }}
                    onDateChange={(end_date: any) => {
                      this.setDeliveryArrivalTime(end_date);
                    }}
                  /> */}
                </View>
                {this.state.deliveryDateError !== "" ? (
                  <View style={styles.textInputView}>
                    <Text style={[styles.inputLabel, { color: "red" }]}>
                      {this.state.deliveryDateError}
                    </Text>
                  </View>
                ) : null}
                <View style={styles.textInputView}>
                  <Text style={styles.inputLabel}>instructions</Text>
                  <TextInput
                    testID="txtInputinstructions"
                    style={[styles.textInput, {}]}
                    onChangeText={text =>
                      this.setTextInputValue("instructions", text)
                    }
                    value={this.state.instructions}
                  />
                </View>
                {/* pickup_attributes */}
                <View style={styles.deliveryBarStyle}>
                  <Text style={styles.shipLabel}>Pickup Details:</Text>
                </View>
                <View style={styles.textInputView}>
                  <Text style={styles.inputLabel}>
                    Name<Text style={styles.inputLabel}>*</Text>
                  </Text>
                  <TextInput
                    testID="txtInputPName"
                    style={[styles.textInput, {}]}
                    onChangeText={text =>
                      this.setTextInputValue("p_name", text)
                    }
                    value={this.state.p_name}
                  />
                </View>
                <View style={styles.textInputView}>
                  <Text style={styles.inputLabel}>
                    Phone<Text style={styles.inputLabel}>*</Text>
                  </Text>
                  <TextInput
                    testID="txtInputPPhone"
                    keyboardType="number-pad"
                    maxLength={10}
                    style={[styles.textInput, {}]}
                    onChangeText={text =>
                      this.setTextInputValue("p_phone", text)
                    }
                    value={this.state.p_phone}
                  />
                </View>
                <View style={styles.textInputView}>
                  <Text style={styles.inputLabel}>
                    Email<Text style={styles.inputLabel}>*</Text>
                  </Text>
                  <TextInput
                    testID="txtInputPEmail"
                    keyboardType="email-address"
                    style={[styles.textInput, {}]}
                    onChangeText={text =>
                      this.setTextInputValue("p_email", text)
                    }
                    value={this.state.p_email}
                  />
                </View>
                <View style={styles.textInputView}>
                  <Text style={styles.inputLabel}>
                    Address<Text style={styles.inputLabel}>*</Text>
                  </Text>
                  <TextInput
                    testID="txtInputPAddress"
                    style={[styles.textInput, {}]}
                    onChangeText={text =>
                      this.setTextInputValue("p_address", text)
                    }
                    value={this.state.p_address}
                  />
                </View>
                <View style={styles.textInputView}>
                  <Text style={styles.inputLabel}>
                    City<Text style={styles.inputLabel}>*</Text>
                  </Text>
                  <TextInput
                    testID="txtInputPCity"
                    style={[styles.textInput, {}]}
                    onChangeText={text =>
                      this.setTextInputValue("p_city", text)
                    }
                    value={this.state.p_city}
                  />
                </View>
                <View style={styles.textInputView}>
                  <Text style={styles.inputLabel}>
                    Country<Text style={styles.inputLabel}>*</Text>
                  </Text>
                  <TextInput
                    testID="txtInputPCountry"
                    style={[styles.textInput, {}]}
                    onChangeText={text =>
                      this.setTextInputValue("p_country", text)
                    }
                    value={this.state.p_country}
                  />
                </View>
                <View style={styles.textInputView}>
                  <Text style={styles.inputLabel}>instructions</Text>
                  <TextInput
                    testID="txtInputp_instructions"
                    style={[styles.textInput, {}]}
                    onChangeText={text =>
                      this.setTextInputValue("p_instructions", text)
                    }
                    value={this.state.p_instructions}
                  />
                </View>
                <View style={styles.textInputView}>
                  <Text
                    style={[styles.inputLabel, { marginVertical: hp("1%") }]}
                  >
                    Begining At<Text style={styles.inputLabel}>*</Text>
                  </Text>
                  {/* <DatePicker
                    style={styles.basicDateStyle}
                    date={this.state.pickup_begin_at}
                    mode="datetime"
                    testID="pickup_begingdate"
                    placeholder="selectDate"
                    format="YYYY-MM-DD HH:mm:ss"
                    minDate={moment().year() + "-01-01"}
                    maxDate={1 + moment().year() + "-01-01"}
                    confirmBtnText="Confirm"
                    cancelBtnText="Cancel"
                    customStyles={{
                      datePicker: {
                        backgroundColor: "#d1d3d8",
                        justifyContent: "center"
                      },
                      dateIcon: {
                        position: "absolute",
                        right: 5,
                        top: 4
                      },
                      dateInput: {
                        borderColor: "#ccc",
                        borderWidth: 1,
                        height: hp("5%"),
                        borderRadius: hp("1%"),
                        width: "100%",
                        //color: "#ccc",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        paddingHorizontal: 10
                      }
                    }}
                    onDateChange={(begin_date: any) => {
                      this.setState({
                        pickup_begin_at: begin_date
                      });
                    }}
                  /> */}
                </View>
                <View style={styles.textInputView}>
                  <Text
                    style={[styles.inputLabel, { marginVertical: hp("1%") }]}
                  >
                    End At<Text style={styles.inputLabel}>*</Text>
                  </Text>
                  {/* <DatePicker
                    style={styles.basicDateStyle}
                    date={this.state.pickup_end_at}
                    mode="datetime"
                    testID="pickup_enddate"
                    placeholder="selectDate"
                    format="YYYY-MM-DD HH:mm:ss"
                    minDate={moment().year() + "-01-01"}
                    maxDate={1 + moment().year() + "-01-01"}
                    confirmBtnText="Confirm"
                    cancelBtnText="Cancel"
                    customStyles={{
                      datePicker: {
                        backgroundColor: "#d1d3d8",
                        justifyContent: "center"
                      },
                      dateIcon: {
                        position: "absolute",
                        right: 5,
                        top: 4
                      },
                      dateInput: {
                        borderColor: "#ccc",
                        borderWidth: 1,
                        height: hp("5%"),
                        borderRadius: hp("1%"),
                        width: "100%",
                        //color: "#ccc",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        paddingHorizontal: 10
                      }
                    }}
                    onDateChange={(end_date: any) => {
                      this.setPickupArrivalTime(end_date);
                    }}
                  /> */}
                </View>
                {this.state.pickupDateError !== "" ? (
                  <View style={styles.textInputView}>
                    <Text style={[styles.inputLabel, { color: "red" }]}>
                      {this.state.pickupDateError}
                    </Text>
                  </View>
                ) : null}
                {/* pickup_attributes */}
                <View style={styles.textInputView}>
                  <Text style={styles.inputLabel}>
                    Amount<Text style={styles.inputLabel}>*</Text>
                  </Text>
                  <TextInput
                    testID="txtInputAmount"
                    keyboardType="decimal-pad"
                    style={[styles.textInput, {}]}
                    onChangeText={text =>
                      this.setTextInputValue("amount", text)
                    }
                    value={this.state.amount}
                  />
                </View>
                <View style={styles.textInputView}>
                  <Text style={styles.inputLabel}>
                    Currency<Text style={styles.inputLabel}>*</Text>
                  </Text>
                  <TextInput
                    testID="txtInputCurrency"
                    style={[styles.textInput, {}]}
                    onChangeText={text =>
                      this.setTextInputValue("currency", text)
                    }
                    value={this.state.currency}
                  />
                </View>
                <View
                  style={[
                    {
                      flex: 1,
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      marginVertical: hp("2%")
                    }
                  ]}
                >
                  <Text style={styles.shipLabel}>
                    Delivery Location Details:
                  </Text>
                </View>
                <View style={styles.textInputView}>
                  <Text style={styles.inputLabel}>longitude</Text>
                  <TextInput
                    testID="txtInputlongitude"
                    keyboardType="decimal-pad"
                    onBlur={() => this.checkLongFunc()}
                    style={[styles.textInput, {}]}
                    onChangeText={text =>
                      this.setTextInputValue("longitude", text)
                    }
                    value={this.state.longitude}
                  />
                </View>
                {this.state.dlongerr !== "" ? (
                  <View style={[styles.textInputView]}>
                    <Text
                      style={[
                        styles.inputLabel,
                        { textAlign: "center", color: "red" }
                      ]}
                    >
                      {this.state.dlongerr}
                    </Text>
                  </View>
                ) : null}
                <View style={styles.textInputView}>
                  <Text style={styles.inputLabel}>Latitude</Text>
                  <TextInput
                    testID="txtInputlatitude"
                    onBlur={() => this.checkLatFunc()}
                    keyboardType="decimal-pad"
                    style={[styles.textInput, {}]}
                    onChangeText={text =>
                      this.setTextInputValue("latitude", text)
                    }
                    value={this.state.latitude}
                  />
                </View>
                {this.state.dlaterr !== "" ? (
                  <View style={[styles.textInputView]}>
                    <Text
                      style={[
                        styles.inputLabel,
                        { textAlign: "center", color: "red" }
                      ]}
                    >
                      {this.state.dlaterr}
                    </Text>
                  </View>
                ) : null}
                <View style={styles.deliveryBarStyle}>
                  <Text style={styles.shipLabel}>Pickup Location Details:</Text>
                </View>
                <View style={styles.textInputView}>
                  <Text style={styles.inputLabel}>
                    longitude<Text style={styles.inputLabel}>*</Text>
                  </Text>
                  <TextInput
                    testID="txtInputPlongitude"
                    keyboardType="decimal-pad"
                    onBlur={() => this.checkpLongFunc()}
                    style={[styles.textInput, {}]}
                    onChangeText={text =>
                      this.setTextInputValue("p_longitude", text)
                    }
                    value={this.state.p_longitude}
                  />
                </View>
                {this.state.plongerr !== "" ? (
                  <View style={[styles.textInputView]}>
                    <Text
                      style={[
                        styles.inputLabel,
                        { textAlign: "center", color: "red" }
                      ]}
                    >
                      {this.state.plongerr}
                    </Text>
                  </View>
                ) : null}
                <View style={styles.textInputView}>
                  <Text style={styles.inputLabel}>
                    Latitude<Text style={styles.inputLabel}>*</Text>
                  </Text>
                  <TextInput
                    testID="txtInputPlatitude"
                    onBlur={() => this.checkpLatFunc()}
                    keyboardType="decimal-pad"
                    style={[styles.textInput, {}]}
                    onChangeText={text =>
                      this.setTextInputValue("p_latitude", text)
                    }
                    value={this.state.p_latitude}
                  />
                </View>
                {this.state.platerr !== "" ? (
                  <View style={[styles.textInputView]}>
                    <Text
                      style={[
                        styles.inputLabel,
                        { textAlign: "center", color: "red" }
                      ]}
                    >
                      {this.state.platerr}
                    </Text>
                  </View>
                ) : null}
                <View
                  style={[
                    {
                      flex: 1,
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      marginVertical: hp("2%")
                    }
                  ]}
                >
                  <Text style={styles.shipLabel}>Item Details:</Text>
                </View>
                <View style={styles.textInputView}>
                  <Text style={styles.inputLabel}>
                    Length<Text style={styles.inputLabel}>*</Text>
                  </Text>
                  <TextInput
                    testID="txtInputLength"
                    keyboardType="decimal-pad"
                    style={[styles.textInput, {}]}
                    onChangeText={text =>
                      this.setTextInputValue("length", text)
                    }
                    value={this.state.length}
                  />
                </View>
                <View style={styles.textInputView}>
                  <Text style={styles.inputLabel}>
                    Width<Text style={styles.inputLabel}>*</Text>
                  </Text>
                  <TextInput
                    testID="txtInputWidth"
                    keyboardType="decimal-pad"
                    style={[styles.textInput, {}]}
                    value={this.state.width}
                    onChangeText={text => this.setTextInputValue("width", text)}
                  />
                </View>
                <View style={styles.textInputView}>
                  <Text style={styles.inputLabel}>
                    Height<Text style={styles.inputLabel}>*</Text>
                  </Text>
                  <TextInput
                    testID="txtInputHeight"
                    keyboardType="decimal-pad"
                    style={[styles.textInput, {}]}
                    onChangeText={text =>
                      this.setTextInputValue("height", text)
                    }
                    value={this.state.height}
                  />
                </View>
                <View style={styles.textInputView}>
                  <Text style={styles.inputLabel}>Quantity</Text>
                  <TextInput
                    testID="txtInputquantity"
                    keyboardType="decimal-pad"
                    style={[styles.textInput, {}]}
                    onChangeText={text =>
                      this.setTextInputValue("quantity", text)
                    }
                    value={this.state.quantity}
                  />
                </View>
                <View style={styles.textInputView}>
                  <Text style={styles.inputLabel}>
                    Weight<Text style={styles.inputLabel}>*</Text>
                  </Text>
                  <TextInput
                    testID="txtInputweight"
                    keyboardType="decimal-pad"
                    style={[styles.textInput, {}]}
                    onChangeText={text =>
                      this.setTextInputValue("weight", text)
                    }
                    value={this.state.weight}
                  />
                </View>
                <View
                  style={{
                    flex: 1,
                    opacity: this.state.isSubmit ? 0.2 : 1,
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <TouchableOpacity
                    style={styles.submitButton}
                    testID="btnCreateShipment"
                    onPress={() => {
                      if (this.state.isShippmentCreated) {
                      } else {
                        this.createShippment();
                      }
                    }}
                  >
                    <Text style={styles.submitButtonText}>Submit</Text>
                  </TouchableOpacity>
                </View>
                {this.state.loadingShipment ? (
                  <ActivityIndicator
                    style={{ flex: 1, opacity: 1 }}
                    color="blue"
                    size="small"
                  />
                ) : null}
                {/* {this.state.isSubmit ? (
                <TouchableOpacity
                  style={styles.submitButton}
                  testID="TrackShipment"
                  onPress={() => {
                    this.props.navigation.navigate(
                      "FedexintegrationOrderTracking",
                      {
                        shippmentDetails: this.state.new_shippment_detail,
                        authKey: this.state.token,
                      }
                    );
                  }}
                >
                  <Text
                    style={[styles.submitButtonText, { fontSize: RFValue(16) }]}
                  >
                    Track Order
                  </Text>
                </TouchableOpacity>
              ) : null} */}
              </View>

              {/* Merge Engine UI Engine Code */}
              {/* Customizable Area End */}
            </TouchableWithoutFeedback>
          </ScrollView>
          {/* Customizable Area End */}
        </KeyboardAvoidingView>
      </SafeAreaView>
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    // flex: 1,
    // //padding: 16,
    // marginLeft: "auto",
    // marginRight: "auto",
    // width: Platform.OS === "web" ? "75%" : "100%",
    // maxWidth: 650,
    // backgroundColor: "#ffffff"
  },
  contentContainer: {
    //     flex:1,
    // height: height,
    //justifyContent: 'center',
    //alignItems: 'center',
  },
  scrollViewStyle: {
    // position: 'absolute',
    backgroundColor: "#fff",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
    // paddingTop: 60
  },
  basicDateStyle: {
    width: wp("90%")
  },
  deliveryBarStyle: {
    flex: 1,
    justifyContent: "flex-start",
    alignItems: "flex-start",
    marginVertical: hp("2%")
  },
  textInputView: {
    //padding: 24,
    flex: 1,
    //justifyContent: "space-around",
    marginVertical: hp("1%")
  },
  defaultContainer: {
    flex: 1,
    backgroundColor: "#fff"
  },
  innerContainer: {
    flex: 1,
    marginHorizontal: wp("6%")
    //justifyContent: "center",
    //alignItems: "center",
    // marginBottom: 30
  },
  customDropdownStyle1: {
    padding: 5
  },
  customDropdownStyle: {
    borderBottomColor: "transparent"
  },
  dropdownStyle: {
    flex: 1,
    justifyContent: "center",
    //alignItems: "center",
    // marginTop: hp("4%"),
    borderWidth: 1,
    borderColor: "#ccc",
    marginVertical: hp("1%"),
    width: wp("90%"),
    height: hp("6%"),
    borderRadius: hp("1%")
  },
  inputLabel: {
    // marginBottom: 2,
    fontSize: RFValue(16),
    lineHeight: RFValue(22)
  },
  shipLabel: {
    // marginBottom: 2,
    fontSize: RFValue(18),
    lineHeight: RFValue(30),
    color: "rgb(84, 89, 95)",
    textAlign: "left"
  },
  textInput: {
    fontSize: RFValue(16),
    lineHeight: RFValue(22),
    borderWidth: 1,
    borderColor: "#ccc",
    width: wp("90%"),
    height: hp("6%"),
    borderRadius: hp("1%"),
    paddingHorizontal: wp("2%")
  },
  orderTrackButton: {
    alignSelf: "flex-end",
    marginTop: hp("4%"),
    height: hp("4%"),
    width: wp("50%"),
    justifyContent: "center",
    backgroundColor: "blue",
    borderRadius: hp("1%"),
    marginVertical: hp("1%")
  },
  submitButton: {
    height: hp("6%"),
    justifyContent: "center",
    backgroundColor: "blue",
    borderRadius: hp("1%"),
    marginVertical: hp("1%"),
    width: wp("90%")
  },
  submitButtonText: {
    textAlign: "center",
    color: "#fff",
    lineHeight: RFValue(20),
    fontSize: RFValue(20),
    fontWeight: "600"
  },
  title: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  }
});
// Customizable Area End
