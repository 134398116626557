import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

interface ConfirmationModalProps {
  open: boolean;
  handleClose: () => void;
  handleDelete: () => void;
  title: string;
  description: string;
  cancelButtonText: string;
  deleteButtonText: string;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  open,
  handleClose,
  handleDelete,
  title,
  description,
  cancelButtonText,
  deleteButtonText
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {cancelButtonText}
        </Button>
        <Button onClick={handleDelete} color="primary" autoFocus data-test-id="delete">
          {deleteButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmationModal;
