import React from "react";
// Customizable Area Start
import SellerForgotPasswordController, {
    Props
} from "./SellerForgotPasswordController.web";

import { withStyles, StyleRules } from "@material-ui/core/styles";
import {
    Box,
    Grid,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { App_Logo, Pwd_visible_eye, Wave, hidePassword } from "../../email-account-registration/src/assets";
import { AlertBox, AlertHeadText, AlertText, InputTagPassword, ErrorTag, FormBox, HeadingTypography, InputGrid, EyeImage, LeftGrid, LogoText, OverlayBox, RightBox, RightGrid, StyledButton, SubHeadingTypography } from "../../../components/src/CustomComponentSellerForgotPassword.web";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

const styles: StyleRules = {
    // Customizable Area Start
    root: {
        background: "#000",
        minHeight: "100vh"
    },
    firstWave: {
        position: 'absolute',
        bottom: 0,
        width: '100%'
    },
    secondWave: {
        position: 'absolute',
        bottom: 0,
        right: '12%',
        width: '100%'
    },
    // Customizable Area End
}

export class SellerResetPassword extends SellerForgotPasswordController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;

        return (
            <Grid container className={classes.root}>
                <Loader loading={this.state.Loading} />

                <LeftGrid item md={5} >
                    <Box>
                        <img src={App_Logo} alt="3" />
                        <LogoText >Live shopping & market place</LogoText>
                    </Box>
                </LeftGrid>

                <RightGrid item md={7} >

                    <RightBox>
                        <HeadingTypography>Create a new password</HeadingTypography>
                        <SubHeadingTypography>Set your new password</SubHeadingTypography>
                        <FormBox container>
                            <InputGrid item xs={12} style={{ margin: "35px 0px 0px" }}>
                                <Box style={{ position: 'relative' }}>
                                    <InputTagPassword
                                        id="outlined-number"
                                        label="New password"
                                        onKeyDown={this.handlePasswordKey}
                                        type="text"
                                        value={this.state.ShowPassword ? this.state.CopyPassword : "*".repeat(this.state.Password.length)}
                                        className={this.state.ShowPassword ? "" : "showStar"}
                                        onBlur={this.handlePasswordValidation}
                                        onChange={this.handlePasswordChange}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="outlined"
                                        autoComplete="off"
                                    />
                                    <EyeImage src={this.state.ShowPassword ? hidePassword : Pwd_visible_eye} alt="" onClick={this.toggleShowPassword} className={this.state.ShowPassword ? "showStar" : ""} />
                                </Box>
                                <ErrorTag style={{ margin: '16px 0px' }}>{this.state.PasswordError}</ErrorTag>
                            </InputGrid>
                            <InputGrid item xs={12} style={{ margin: "35px 0px 0px" }}>
                                <Box style={{ position: 'relative' }}>
                                    <InputTagPassword
                                        id="outlined-number"
                                        label="Confirm new password"
                                        onKeyDown={this.handleConfirmPasswordKey}
                                        type="text"
                                        value={this.state.ShowConfirmPassword ? this.state.CopyConfirmPassword : "*".repeat(this.state.ConfirmPassword.length)}
                                        className={this.state.ShowConfirmPassword ? "" : "showStar"}
                                        onBlur={this.handlePasswordValidation}
                                        onChange={this.handleConfirmPasswordChange}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="outlined"
                                        autoComplete="off"
                                    />
                                    <EyeImage src={this.state.ShowConfirmPassword ? hidePassword : Pwd_visible_eye} alt="" onClick={this.toggleShowConfirmPassword} className={this.state.ShowConfirmPassword ? "showStar" : ""} />
                                </Box>
                                {<ErrorTag style={{ margin: '16px 0px' }}>{this.state.ConfirmPasswordError}</ErrorTag>}
                            </InputGrid>
                        </FormBox>
                        <StyledButton onClick={this.handleResetPassword}>Continue</StyledButton>
                    </RightBox>

                    {this.state.ShowAlert &&
                        <Box className="alert-box">
                            <OverlayBox></OverlayBox>
                            <AlertBox severity="error">
                                <AlertHeadText>Error <CloseIcon onClick={this.closeAlert} /></AlertHeadText>
                                <AlertText>Account not found</AlertText>
                            </AlertBox>
                        </Box>}

                </RightGrid>

                <img src={Wave} className={classes.firstWave} alt="wave3" />
                <img src={Wave} className={classes.secondWave} alt="wave3" />
            </Grid>
        )
        // Customizable Area End
    }
}

// Customizable Area Start
export default withStyles(styles)(SellerResetPassword);
// Customizable Area End