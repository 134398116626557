import React from "react";
// Customizable Area Start
import LocationAccessController, { Props } from "./LocationAccessController.web";
import { createTheme, withStyles, Theme } from "@material-ui/core/styles";
import { Box, Grid, Typography, Button } from "@material-ui/core";
import { App_Logo ,leftSideCategoriesImg,locationImage} from "./assets"
import { configJSON } from "./LocationAccessController.web";
import MessageModalWeb from "../../../components/src/MessageModal.web";



const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
});

export const webStyles = (theme: Theme) => ({
    mainContainer: {
        height: '100vh',
    },
    leftContainer: {
        backgroundColor: '#404040',
        padding: theme.spacing(2),
    },
    rightContainer: {
        backgroundColor: '#000000',
        color: '#ffffff',
        display: 'flex',
        flexDirection: 'column' as const,
        alignItems: 'center' as const,
        justifyContent: 'center',
    },
    logo: {
        marginBottom: theme.spacing(2),
    },
    image: {
        width: '100%',
        maxWidth: '300px',
        marginBottom: theme.spacing(2),
    },
    notNow: {
        marginTop: "1em",
        color: '#FFFFFF',
        textDecoration:"underline",
        fontFamily: 'Poppins !important',
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '18px',
        pointer:"cursor",

    },
    appLogo:{
        height:"3.5em",
        margin:"1em 0em 0em 2em"
    },
    leftSideCategoriesImgContainer:{
        display:"flex",
        justifyContent:"center",
        alignItems:"center" as const,
        height:"100vh",
        width:"100%"
    },
    leftSideCategoriesImg:{
        width:"37em",
        height:"23em"
    },
    enableYourLocationTitle:{
        color: '#FFFFFF',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: 'Poppins !important',
        fontSize: '40px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '60px',
         margin:"1em 0em 0.25em 0em"
    },
    enableYourLocationSubtitle:{
        color: '#E4E4E4',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: 'Poppins !important',
        fontSize: '18px',
        fontWeight: 400,
        lineHeight: '27px',
    },
    allowButton:{
        padding:"1em 12em",
        background: '#F5EA16',
        borderRadius:"22px",
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: 'Poppins !important',
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
        pointer:"cursor",
        textTransform:"none" as const,
        marginTop:"4em",
        "&:hover":{
            background: '#F5EA16',
        }
    }
});

// Customizable Area End

export class LocationAccess extends LocationAccessController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    // Customizable Area End
    render() {
        // Customizable Area Start
       const {classes} = this.props
        return (
            <Box  data-test-id="mainContainer">
                 <Grid container className={classes.mainContainer}>
                    <Grid item xs={5} className={classes.leftContainer}>
                        <Box className={classes.logo}>
                           <img src={App_Logo} className={classes.appLogo}/>
                        </Box>
                        <Box className={classes.leftSideCategoriesImgContainer}>
                            <img src={leftSideCategoriesImg} alt="categoriesImg" className={classes.leftSideCategoriesImg} />
                        </Box>
                    </Grid>
                    <Grid item xs={7} className={classes.rightContainer}>
                        <Box>
                            <img src={locationImage} alt="locationImage"/> 
                        </Box>
                        <Typography  className={classes.enableYourLocationTitle}>
                          {configJSON.enableYourLocation}
                        </Typography>
                        <Typography className={classes.enableYourLocationSubtitle}>
                           {configJSON.allowFlixoo}
                        </Typography>
                        <Button className={classes.allowButton} onClick={this.getGpsLocation} data-test-id="allowButton">
                           {configJSON.allowButton}
                        </Button>
                        <Typography className={classes.notNow} onClick={this.navigateToHome}>
                           {configJSON.notNow}
                        </Typography>
                    </Grid>
                </Grid>
                <MessageModalWeb
                    data-test-id="messageModal"
                    displayPopup={this.state.messageModal}
                    closeErrorPopup={this.handleClosemessageModal}
                    errorMessage={this.state.message}
                />
            </Box>
        );
        // Customizable Area End
    }
}


// Customizable Area Start
export default withStyles(webStyles)(LocationAccess)
// Customizable Area End