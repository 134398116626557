Object.defineProperty(exports, '__esModule', {
  value: true
});

// Customizable Area Start
exports.apiContentType = 'application/json';
exports.apiFormContentType = 'multipart/form-data';
exports.getApiMethod = 'GET';
exports.postApiMethod = 'POST';
exports.putApiMethod = 'PUT';
exports.getSellerContactEndpoint="/bx_block_chat/get_contacts?type=seller";
exports.getBuyerContactEndpoint="/bx_block_chat/get_contacts?type=buyer";
exports.postCreateChannelEndPoint="/bx_block_chat/chat_channels";
exports.getChannelData="/bx_block_chat/get_channels";
exports.postOrderAcceptApiEndPoint="/bx_block_productdescription/accept_request";
exports.postOrderRejectApiEndPoint="/bx_block_productdescription/reject_request";
exports.postBargainOfferApiEndPoint = "bx_block_productdescription/bargain_orders";
exports.getInventoryEndPoint = "bx_block_catalogue/catalogues/get_seller_catalogues";

exports.getChatListApiEndPoint = 'chat/chats';
exports.showChatApiEndPoint = 'chat/chats';
exports.getChatHistoryApiEndPoint = 'chat/chats/history';
exports.searchMessageApiEndPoint = 'chat/chats/search_messages';
exports.searchChatGroupApiEndPoint = 'chat/chats/search';
exports.createChatRoomApiEndPoint = 'chat/chats';
exports.addUserToChatApiEndPoint = 'chat/chats/add_user';
exports.leaveChatApiEndPoint = 'chat/chats/leave';
exports.sendMessageApiEndPoint = 'chat/chats';
exports.updateReadMessageApiEndPoint = 'chat/chats/read_messages';
exports.updateChatApiEndPoint = 'chat/chats';

exports.getMyChatsApiEndpoint = 'chat/chats/mychats';

exports.createButtonText = 'Create';
exports.createRoomButtonText = 'Create Room'
exports.addButtonText = 'Add';
exports.closeButtonText = 'Close';
exports.addAccountText = 'Add Account';
exports.leaveChatRoomText = 'Leave Chat';
exports.sendText = 'Send';
exports.cancelText = 'Cancel';
exports.errorTitle = 'Error';
exports.errorAllFieldsAreMandatory = 'All fields are mandatory.';
exports.dummyName="Breakout_Swinger";
exports.dummyDate="12:10";
exports.dummyLastMessage="Hiii ...";
exports.messageTxt="Messages";
exports.dummyLastSeenTxt="Last Seen 2hrs ago";
exports.createNewGroup="Create new group";
exports.newChannelTxt="New Channel";
exports.onlineText="Online";
exports.chatText="Chat";
exports.helloTxt="Hello";
exports.loremIpsumTxt="Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestias velit voluptatem magni cupiditate dolorem.";
exports.todayTxt="Today";
exports.privacyText1="We ensure a clear and transparent privacy policy";
exports.privacyText2="We obtain user consent for collecting personal information";
exports.privacyText3="We have security features like end-to-end encryption";
exports.forYourSafety="For your safety";
exports.applicationPdf="application/pdf";
exports.applicationDoc="application/msword";
exports.applicationDocx="application/vnd.openxmlformats-officedocument.wordprocessingml.document";
exports.docText="doc";
exports.docxText="docx";
exports.pdfText="pdf";
exports.imageText="image";
exports.attachmentText="Attachment";
exports.makePaymentText="Make Payment";
exports.acceptTxt="Accepted";
exports.rejectTxt="Declined";
exports.pendingTxt="Pending";
exports.reasonSubtitleTxt="Select the reason for the decline of the order request";
exports.pleaseEnterTxt="Please enter the reason";
exports.orderStatusTxt="Order Status :-";
exports.offerPriceRegex=/^(?!0\d)\d*(\.\d{0,4})?$/;
exports.acceptedBySellerTxt="Offer accepted by seller";
exports.declinedBySellerTxt="Offer declined by seller";
exports.acceptedByBuyerTxt="Offer accepted by buyer";
exports.declinedByBuyerTxt="Offer declined by buyer";
exports.acceptedByYouTxt="Offer accepted by you";
exports.declinedByYouTxt="Offer declined by you";
  // Customizable Area End