import React from "react";

import {
    // Customizable Area Start
    Box,
    Grid,
    Typography,
    TextField,
    styled,
    MenuItem,
    InputLabel,
    Select,
    Button,
    Checkbox,
    FormControlLabel,
    Radio,
    // Customizable Area End 
} from "@material-ui/core";

// Customizable Area Start
import {
    twoWave,
    App_Logo, Pwd_visible_eye, indiaFlag, hidePassword,
    regStepOne
} from "./assets";
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { Link } from 'react-router-dom';
import SellerTermsAndCondtion from './SellerTermsAndCondtion.web'


const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});
import {configJSON} from "./AccountRegistrationController";
// Customizable Area End

import AccountRegistrationController, {
    Props,
} from "./AccountRegistrationController";
import Loader from "../../../components/src/Loader.web";

class SellerSignUp extends AccountRegistrationController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
     renderErrormsg = ()=>{
        return (
            this.state.checkedStoreTypeError === true && <ErrorTag style={{ marginTop: '-26px' }} >Please Select Store Type</ErrorTag>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props; 
        const {userType} =  this.state;
        // Customizable Area End
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <Loader loading={this.state.isLoading} />
                <section className={classes.sectionSellerSignUp}>
                    <Box className={classes.SignupBgWrapper}>
                        <Box className={'overlayImg'}>
                            <img src={twoWave} alt="" />
                        </Box>
                        <Box className={classes.formWrapper}>
                            <Grid container spacing={2}>
                                <Grid item lg={5} md={6} sm={12} xs={12}>
                                    <Box className={'leftColumn'}>
                                        <img src={App_Logo} alt="" />
                                        <Typography variant="h6" component={'h6'}>{'Live shopping & market place'}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item lg={7} md={6} sm={12} xs={12}>
                                    <Box className={'rightColumn'}>
                                        <Box className={'rightColumnInner'}>
                                            {this.getIfCondition(userType === "creator",
                                                <Box className={'formHeading'}>
                                                    <Box className="stepOneImgBox">
                                                        <img
                                                            src={regStepOne}
                                                        />
                                                    </Box>
                                                    <Typography variant="h6" component={'h6'}>{configJSON.basicInfo}</Typography>
                                                    <Typography component={'p'}>{configJSON.enterSignUpInfo}</Typography>
                                                </Box>,
                                            <Box className={'formHeading'}>
                                                <Typography variant="h6" component={'h6'}>{'Welcome '}</Typography>
                                                <Typography component={'p'}>{configJSON.enterInfo}</Typography>
                                            </Box>
                                            )}
                                            <Box
                                                component="form"
                                                className={'signupFormWrapper'}
                                                data-test-id="sellerSignupForm"
                                            >
                                                <Box className={'inputFeild'}>
                                                {this.getIfCondition(userType==="creator",
                                                <></>,
                                                    <TextField
                                                        id="outlined-number"
                                                        label="Business Name"
                                                        variant="outlined"
                                                        data-test-id="businessName"
                                                        name="business_Name"
                                                        placeholder={configJSON.businessNamePlaceholder}
                                                        error={this.state.businessNameError != ""}
                                                        helperText={this.state.businessNameError}
                                                        value={this.state.business_Name}
                                                        onChange={this.handleBusinessNameError}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                                </Box>
                                                <Box className={'inputFeild'}>
                                                    <TextField
                                                        id="outlined-textarea"
                                                        type="text"
                                                        data-test-id="fullName"
                                                        placeholder={this.getIfCondition(userType==="creator",configJSON.creatorNamePlaceHolder,configJSON.fullNamePlaceholder) as string} 
                                                        onFocus={this.handleFocus}
                                                        onBlur={this.handleBlur}
                                                        label={this.getIfCondition(userType==="creator","Creator's Name","Your Name")}
                                                        variant="outlined"
                                                        name="fullName"
                                                        value={this.state.fullName}
                                                        onKeyPress={this.handleNameKeyPress}
                                                        onChange={this.handleNameLength}
                                                        inputProps={{
                                                            maxLength: 30,
                                                        }}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                    {this.state.fullNameError !== "" && (
                                                        <ErrorTag>{this.state.fullNameError}</ErrorTag>
                                                    )}
                                                </Box>
                                                <Box className={'inputFeild'}>
                                                    <TextField
                                                        id="outlined-textarea"
                                                        label="Username"
                                                        data-test-id="userName"
                                                        name="userName"
                                                        placeholder={configJSON.userNamePlaceholder}
                                                        onChange={this.handleSelectUserMaxLength}
                                                        error={this.state.userNameError !== ""}
                                                        helperText={this.state.userNameError}
                                                        variant="outlined"
                                                        value={this.state.selectedUsername.trim()}
                                                        inputProps={{
                                                            maxLength: 30,
                                                        }}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                    {this.state.username.length > 0 ?
                                                        (
                                                            <>
                                                                <Box style={{ display: "flex", gap: "5px", fontFamily: 'Poppins-Medium', color: "white", marginTop: 8 }}>
                                                                    <Typography style={{ fontSize: '12px', fontFamily: 'Poppins-Medium', lineHeight: 'normal' }}>Available:</Typography>
                                                                    <Box style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
                                                                        {this.state.username.map((i: any, index: any) => <Typography key={index} data-test-id={`uname${index}`} onClick={() => {
                                                                            this.setState({ selectedUsername: i })
                                                                        }} style={{ color: "yellow", cursor: "pointer", fontSize: '12px', fontFamily: 'Poppins-Medium', lineHeight: 'normal' }} >{i}</Typography>)}
                                                                    </Box>
                                                                </Box>
                                                            </>)
                                                        : ""}
                                                </Box>
                                                {this.getIfCondition(userType==="creator",<></>,
                                                <Box className={'inputField'} style={{ position: 'relative' }}>
                                                    <InputLabel
                                                        className={'BusinessLabel textWhite customShrink'}
                                                        shrink={true}
                                                        htmlFor="demo-simple-select"
                                                        data-test-id="businessType"
                                                    >
                                                        Business Type
                                                    </InputLabel>
                                                    <Select
                                                        className={classes.BusinessSelect}
                                                        labelId="demo-simple-select"
                                                        id="demo-simple-select"
                                                        data-test-id="businessSelect"
                                                        placeholder={configJSON.selectAnyone}
                                                        value={this.state.selectBusiness || configJSON.selectAnyone}
                                                        onChange={this.hadleChangeBuisnessType}
                                                        variant="outlined"
                                                        MenuProps={{
                                                            anchorOrigin: {
                                                                vertical: 'bottom',
                                                                horizontal: 'left',
                                                            },
                                                            transformOrigin: {
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                            },
                                                            getContentAnchorEl: null,
                                                            PaperProps: {
                                                                style: {
                                                                    backgroundColor: 'black',
                                                                    border: '2px solid rgba(255, 255, 255, 0.50)',
                                                                    borderRadius: '0px 0px 12px 12px',
                                                                    margin: '-14px 0px',
                                                                    borderTop: 'none'
                                                                },
                                                            },
                                                        }}
                                                    >
                                                         <MenuItem
                                                                className={classes.businessTypeLabel}
                                                                value={configJSON.selectAnyone} 
                                                            >
                                                                {configJSON.selectAntoneLabel}
                                                         </MenuItem>
                                                        
                                                        {this.businessOptions.map(option => (
                                                            <MenuItem
                                                                key={option.value}
                                                                style={{
                                                                    backgroundColor: '#000000',
                                                                    borderBottom: '1px solid rgba(255, 255, 255, 0.10)',
                                                                    color: '#ffffff',
                                                                }}
                                                                value={option.value}
                                                            >
                                                                {option.label}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                    <ErrorTag>{this.state.BusinessTypeError}</ErrorTag>
                                                </Box>
)}
                                                <Box className="inputFeild">
                                                    <Box sx={{ display: 'flex', gridGap: '15px' }}>
                                                        <div>
                                                            <div className={classes.countryCodeFeild} >
                                                                <label className={'countryCodeLable'}>Code</label>
                                                                <div className={'countryCodeSelect'} data-test-id="countryCode" onClick={this.handleIsSelect} >

                                                                    <p style={{ paddingRight: '10px', transform: "scale(2)" }}
                                                                        defaultValue={"in"}>{this.state.selectedValue?.label}</p>
                                                                    {this.state.selectedValue?.value}
                                                                    {this.state.isSelect ? <ExpandLessIcon style={{ marginRight: '-15px' }} /> : <ExpandMoreIcon style={{ marginRight: '-15px' }} />}

                                                                </div>
                                                                {this.state.isSelect &&
                                                                    <CountryDiv style={{
                                                                        position: 'absolute', top: '65px', width: '114px', listStyle: 'none', background: 'black',
                                                                        borderRadius: 10, border: '1px solid rgba(255, 255, 255, 0.50)', zIndex: 11, height: 150, overflowY: 'scroll'
                                                                    }}>
                                                                        {this.state.dataSource.map((item: any, index: any) => (
                                                                            <li data-test-id={`countryValue${index}`} key={index} onClick={this.selectCountryFlag.bind(this, item)} style={{ color: 'white', padding: '7px 14px', borderBottom: '1px solid rgba(255, 255, 255, 0.50)' }}>
                                                                                <span style={{ paddingRight: 8, display: 'inline-block' }}>{item.label}</span>
                                                                                {item?.value}</li>
                                                                        ))}
                                                                    </CountryDiv>}
                                                            </div>
                                                        </div>

                                                        <TextField
                                                            type="number"
                                                            id="outlined-textarea"
                                                            data-test-id="mobileNo"
                                                            placeholder={configJSON.mobileNumberLabel}
                                                            label="Mobile number"
                                                            name="mobileNumber"
                                                            variant="outlined"
                                                            value={this.state.phoneNumber}
                                                            onChange={(event: any) => this.handlePhoneChange(event.target.value)}
                                                            error={this.state.mobileNumberError != ""}
                                                            helperText={this.state.mobileNumberError}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                    </Box>
                                                </Box>

                                                <Box className={'inputFeild'}>
                                                    <TextField
                                                        type="email"
                                                        id="outlined-textarea"
                                                        data-test-id="email"
                                                        label="Email Id"
                                                        variant="outlined"
                                                        placeholder={configJSON.email}
                                                        onChange={this.handleChangeEmailSeller}
                                                        value={this.state.emailSellerSignup}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                    <ErrorTag>{this.state.emailError}</ErrorTag>
                                                </Box>
                                                <InputGrid item xs={12}>
                                                    <Box style={{ position: 'relative' }}>
                                                        <InputTagPwd
                                                            id="outlined-number"
                                                            label="Create Password"
                                                            data-test-id="password"
                                                            placeholder={configJSON.placeHolderP}
                                                            onKeyDown={this.handleKey}
                                                            type="text"
                                                            value={this.state.showPassword ? this.state.copyPassword : "*".repeat(this.state.password.length)}
                                                            className={this.state.showPassword ? "" : "showStar"}
                                                            onChange={this.handlePwdChange}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            InputProps={{
                                                                autoComplete: 'off',
                                                            }}
                                                            variant="outlined"
                                                        />
                                                        <EyeImage src={this.state.showPassword ? hidePassword : Pwd_visible_eye} alt="" onClick={this.toggleShowPassword} className={this.state.showPassword ? "showStar" : ""} data-test-id="eyeBtnId" />
                                                    </Box>
                                                    {<ErrorTag>{this.state.pwdError}</ErrorTag>}
                                                </InputGrid>
                                                <InputGrid item xs={12}>
                                                    <Box style={{ position: 'relative' }}>
                                                        <InputTagPwd
                                                            id="outlined-number"
                                                            label="Confirm Password"
                                                            data-test-id="confirmPassword"
                                                            placeholder={configJSON.confirmPassword}
                                                            type="text"
                                                            onKeyDown={this.handleKey1}
                                                            value={this.state.showPassword1 ? this.state.copyPassword1 : "*".repeat(this.state.reTypePassword.length)}
                                                            className={this.state.showPassword1 ? "" : "showStar"}
                                                            onChange={this.handleConfirmPwdChange}
                                                            onBlur={this.validatePassword}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            InputProps={{
                                                                autoComplete: 'off',
                                                            }}
                                                            variant="outlined"
                                                        />

                                                        <EyeImage src={this.state.showPassword1 ? hidePassword : Pwd_visible_eye} alt="" onClick={this.toggleShowPassword1} className={this.state.showPassword1 ? "showStar" : ""} data-test-id="conEyeBtnId"/>
                                                    </Box>
                                                    {<ErrorTag>{this.state.reTypepasswordError}</ErrorTag>}
                                                </InputGrid>
                                                {this.getIfCondition(userType==="creator",<></>,
                                                <>
                                                <Box style={{ display: 'flex', alignItems: 'center' , gap:"2rem"}}>
                                                    <InputLabel style={{ color: 'white', fontSize: '16px', fontFamily: 'Poppins-Regular', marginBottom: '8px' }}>
                                                        Store type
                                                    </InputLabel>
                                                <Box style={{ display: 'flex',  gap: '20px'}}>
                                                   <div style={{display:"flex",justifyContent:"flex-start",alignItems:"center"}}>
                                                   <FormControlLabel
                                                        control={
                                                            <Radio
                                                                checked={this.state.storeType === 'online'}
                                                                onChange={this.handleStoreTypeChange}
                                                                value="online"
                                                                color="primary"
                                                                style={{ padding: 0, color: '#F5EA16' }}
                                                            />
                                                        }
                                                        label={<span style={{ display:"none" }}>Online</span>}
                                                    />
                                                    <label style={{color: 'white', fontSize: '16px', fontFamily: 'Poppins-Regular',gap:"10px",}}>Online</label>
                                                   </div><br/>
                                                  <div style={{display:"flex",justifyContent:"flex-start",alignItems:"center"}}>
                                                  <FormControlLabel
                                                        control={
                                                            <Radio
                                                                checked={this.state.storeType === 'offline'}
                                                                onChange={this.handleStoreTypeChange}
                                                                value="offline"
                                                                color="primary"
                                                                style={{ padding: 0, color: '#F5EA16' }}
                                                            />
                                                        }
                                                       label={<span style={{display:"none"  }}>Offline</span>}
                                                    />
                                                    <label style={{marginRight:"20px" ,color: 'white', fontSize: '16px', fontFamily: 'Poppins-Regular',}}>Offline</label>
                                                  </div>
                                                      
                                                </Box>  
                                                </Box>
                                             {this.renderErrormsg()}
                                                </>
                                            )}
                                                <Box style={{ display: 'flex', gap: "4px" }}>
                                                    <Checkbox
                                                        data-test-id="check"
                                                        color="primary"
                                                        style={{
                                                            padding: 0,
                                                            backgroundColor: this.state.isChecked ? "transparent" : "white",
                                                            width: "17px",
                                                            height:" 17px",
                                                            borderRadius:"5px",
                                                            marginTop:"2px",
                                                            marginRight:"4px"
                                                          }}
                                                        checked={this.state.isChecked}
                                                        onChange={this.handleIsChecked}
                                                        value={this.state.isChecked}

                                                    />
                                                    <InputLabel style={{
                                                        color: 'white',
                                                        fontSize: '16px',
                                                        fontStyle: 'normal',
                                                        fontWeight: 500,
                                                        lineHeight: '19px',
                                                        fontFamily: "Poppins-Regular",
                                                        textAlign: 'start',
                                                    }}>
                                                        Agree with our
                                                        < Button
                                                            onClick={this.sellerTermsAndCondition}
                                                            data-test-id='SellerTermsAndCondition'
                                                            style={{ textTransform: "initial", color: "#1A4CFF", padding: '0px', fontSize: '16px', lineHeight: '19px', fontFamily: 'Poppins-Regular' }}
                                                        >
                                                            &nbsp;Terms and Conditions&nbsp;
                                                        </Button>
                                                        <span>&amp;&nbsp;</span>
                                                        <Button
                                                            data-test-id='sellerPrivacyPolicy'
                                                            onClick={this.sellerPrivacyPolicy}
                                                            style={{ textTransform: "initial", color: "#1A4CFF", padding: '0px', fontSize: '16px', lineHeight: '19px', fontFamily: 'Poppins-Regular' }}
                                                        >Privacy Policy</Button>
                                                    </InputLabel>
                                                </Box>
                                                <Box>
                                                    {this.state.checkedError === true && <ErrorTag style={{ marginTop: '-26px' }}>Terms and Conditions not accepted</ErrorTag>}
                                                </Box>
                                                <Box className={classes.submitBtn}>
                                                    <Button variant="contained"
                                                        data-test-id="submit"
                                                        onClick={this.sellerSignUp}
                                                    >
                                                        {configJSON.signUp}
                                                    </Button>
                                                </Box>
                                                <Box className={classes.horizontalLine}>
                                                    <p>or</p>
                                                </Box>
                                            </Box>
                                            <Box>
                                                <span
                                                    style={{
                                                        fontFamily: 'Poppins-Medium',
                                                        fontSize: '16px',
                                                        lineHeight: '24px',
                                                        color: '#ffffff',
                                                    }}>Already have an account&nbsp;
                                                </span>
                                                <span>
                                                    <Link
                                                        to={'SellerLogin'}
                                                        style={{
                                                            fontFamily: 'Poppins-Medium',
                                                            fontSize: '16px',
                                                            lineHeight: '24px',
                                                            color: '#F5EA16',
                                                        }}>Login</Link>
                                                </span>
                                                <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '4px' }}>
                                                    <img src={indiaFlag} alt="" />
                                                    <Typography style={{ fontFamily: 'Poppins-Regular', fontSize: '16px', color: 'white', margin: '0px' }}>Made in Bharat</Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </section>
                <SellerTermsAndCondtion sellerTermsConditionData={this.state.termsConditionPrivacyPolicyData} navigation={undefined} id={""} sellerModalOpen={this.state.sellerModalOpen} handleSellerModalClose={this.handleSellerModalClose} showModal={false} />
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const styles = (theme: any) => ({
    businessTypeLabel:{
        display: "none"
    },
    sectionSellerSignUp: {
        width: '100%',
        height: '100%',
        color: '#ffffff !important',
        '& p': {
            marginLeft: '3px !important',
            marginTop: '5px',
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            fontSize: '16px',
            lineHight: '18px',
            color: 'rgba(255, 255, 255) !important',
            padding: '0 5px',
            backgroundColor: 'black',
        },
        '& .MuiCheckbox-colorPrimary': {
            color: "#ffffff",
            height: 'max-content',
            marginTop: '-2px',
        },
        '& .MuiCheckbox-colorPrimary.Mui-checked': {
            color: 'yellow',
        },
    },
    SignupBgWrapper: {
        width: '100%',
        minHeight: '1530px',
        backgroundColor: '#000000',
        position: 'relative' as const,
        '& .overlayImg': {
            position: 'absolute',
            width: '100%',
            bottom: '0',
            left: '0',
            '& img': {
                width: '100%',
                height: '280px',
                objectFit: 'cover',
            },
        },
    },
    formWrapper: {
        padding: '100px',
        position: 'relative' as const,
        zIndex: 1,
        [theme.breakpoints.down('sm')]: {
            padding: '20px',
        },
        '& .leftColumn': {
            marginTop: '56%',
            [theme.breakpoints.down('sm')]: {
                marginTop: '0%',
            },
            '& img': {
                maxWidth: '156px',
                lineHeight: '62px',
            },
            '& h6': {
                fontFamily: 'Poppins-Medium',
                color: '#ffffff',
                fontSize: '20px',
                lineHeight: '30px',
            },
        },
        '& .rightColumn': {
            minHeight: '600px',
            border: "1px solid rgba(255, 255, 255, 0.5)",
            borderRadius: '20px',
            padding: '32px 16px',
            display: 'flex',
            justifyContent: 'center',
            maxWidth: '660px',
            width: '100%',
            [theme.breakpoints.down('sm')]: {
                padding: '20px',
            },
            [theme.breakpoints.down('xs')]: {
                padding: '10px',
            },

            '& .rightColumnInner': {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                gridGap: '40px',
                maxWidth: '542px',
                width: '100%',
                "& .stepOneImgBox": {
                    marginBottom: "30px"
                },
                '& .formHeading': {
                    maxWidth: '422px',
                    '& h6, & p': {
                        color: 'white',
                    },
                    '& h6': {
                        fontFamily: 'Poppins-SemiBold',
                        fontSize: '32px',
                        lineHieght: '48px',
                        fontWeight: 600
                    },
                    '& p': {
                        fontFamily: 'Poppins-Regular',
                        fontSize: '18px',
                        lineHeight: '27px',
                        opacity: '0.5',
                    },
                },
                '& .signupFormWrapper': {
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    gridGap: '30px',
                    '& .MuiTextField-root': {
                        width: '100%',
                    },
                    '& .MuiInputLabel-outlined': {
                        color: '#ffffff',
                    },
                    '& input': {
                        color: '#ffffff',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        color: '#ffffff',
                        borderColor: 'rgba(255, 255, 255, 0.5)',
                        borderRadius: '12px',
                        height: '56px',

                    },
                    '& .MuiInputBase-input::placeholder': {
                        color: '#ffffff',
                        opacity: 0.5,
                        fontSize: '14px'
                    },
                    '& .MuiInputBase-input': {
                        '&:focus': {
                            caretColor: 'white',
                        },
                    },
                    '& .BusinessLable': {
                        color: '#ffffff',
                        maxWidth: 'max-content',
                        background: 'black',
                        height: '18px',
                        marginLeft: '16px',
                        marginBottom: '-12px',
                        zIndex: 999,
                        position: 'relative',
                        padding: '0 3px',
                        fontSize: '12px',
                    },
                    '& .MuiInputBase-root': {
                        width: '100%',
                    },
                    '& .MuiSelect-select': {
                        color: '#ffffff',
                        opacity:1,
                        textAlign: 'left',
                    },
                    '& .MuiSelect-select:before': {
                        color: '#ffffff',
                        opacity:0.5,
                    },
                    '& .customShrink': {
                        position: 'absolute',
                        fontSize: '16px',
                        color: 'rgba(255, 255, 255)',
                        transform: "translate(14px, -6px) scale(0.75)",
                        height: '14px',
                        backgroundColor: '#000000',
                        padding: '0 5px',
                        zIndex: '9',
                        '& .MuiInputBase-input::placeholder': {
                            color: '#ffffff',
                            opacity: 0.5,
                          },
                    },
                    '& .BusinessSelect': {
                        '& svg': {
                            color: "white",
                        },
                    },
                    '& .inputFeild': {
                        '& .MuiFormLabel-root': {
                            fontFamily: 'Poppins-Regular !important',
                            fontSize: '16px',
                            lineHeight: '18px',
                            color: "#ffffff"
                        },
                        '& .MuiInputBase-input::placeholder': {
                            color: '#ffffff',
                            opacity: 0.5,
                          },
                        '& .BusinessLable.textWhite': {
                            fontSize: '16px',
                            lineHeight: '15px',
                            color: "#ffffff"
                        },
                    },
                },
            },
        },
    },
    customCheckBox: {
        "& .MuiCheckbox-root": {
            color: "white !important",
            padding: '0px !important',
        },
        "& .Mui-checked.MuiCheckbox-root": {
            color: "#F5EA16 !important",
            padding: '0px !important',
        },
        '& .MuiSvgIcon-root': {
            fontSize: '1rem',
            lienHeight: '18px',
        },
    },
    countryCodeFeild: {
        position: 'relative' as const,
        '& .countryCodeLable': {
            backgroundColor: '#000000',
            color: 'rgba(255, 255, 255)',
            fontSize: "12px",
            position: 'absolute',
            top: "-8px",
            left: "15px",
            display: 'inline-block',
            padding: '0px 5px',
        },
        '& .countryCodeSelect': {
            width: '129px',
            height: '52px',
            border: '1px solid rgba(255, 255, 255, 0.50)',
            borderRadius: 10,
            color: 'white',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gridGap: '4px',
            '& p': {
                marginTop: '15px !important',
            },
        },
    },
    submitBtn: {
        marginBittom: '25px',
        '& .MuiButton-contained': {
            width: '100%',
            height: '56px',
            backgroundColor: '#F5EA16',
            borderRadius: '24px',
            fontSize: '16px',
            lineHeight: '24px',
            fontFamily: 'Poppins-Medium',
            color: '#444444',
            textTransform: 'capitalize',
            fontWeight: 600
        },
    },
    // BusinessSelect: {
    //     '& .MuiSelect-select': {
    //         color: '#ffffff',
    //     },
    //     '& .MuiSelect-select:placeholder': {
    //         color: '#ffffff',
    //         opacity: 0.5,
    //     },
    // },
    horizontalLine: {
        width: '100%',
        height: '2px',
        position: 'relative' as const,
        backgroundColor: "rgba(255, 255, 255, 0.5)",
        '& p': {
            textAlign: 'center',
            backgroundColor: 'black',
            marginTop: '-9px',
            width: 'max-content',
            margin: 'auto',
            padding: '0px 7px',
            fontFamily: 'Poppins-Regular',
            fontSize: '12px',
            position: "absolute" as const,
            left: '47%' as const,
            lineHieght: '18px',
            color: 'rgba(255, 255, 255, 0.5)',
            postion: "absolute" as const,
        }
    },
    passwordError: {
        fontSize: "12px",
        textAlign: 'left' as const,
        marginTop: "-27px",
        marginLeft: "14px",
        color: 'red',
    }

});
const InputGrid = styled(Grid)({
    '& img.showStar': {
        filter: 'brightness(15)',
        width: 24
    }
});
const InputTagPwd = styled(TextField)({
    width: '100%',
    borderRadius: '12px',
    color: 'white',
    height: 55,
    fontWeight: 400,
    backgroundColor: 'transparent',
    '& ::placeholder': {
        color: 'rgba(0, 0, 0)',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
        color: 'rgba(255, 255, 255, 0.30)',
        fontSize: '12px',
        lineHeight: '18px',
        fontFamily: 'Poppins-Regular',
    },
    '& .MuiOutlinedInput-input': {
        fontSize: "18px!important",
        position: "unset",
        padding: "18.5px",
        maxWidth: 'unset'
    },
    '&.showStar .MuiOutlinedInput-input': {
        fontSize: "18px!important",
        position: "absolute",
        top: "0px",
        maxWidth: '95%',
        '&::placeholder': {
        color: '#ffffff',
        opacity: 0.5,
    }
    },
    // '.BusinessSelect::placeholder': {
    //     color: '#ffffff',

    // },
    
    '& .svg': {
        color: '#ffffff',
    },

});
const EyeImage = styled('img')({
    position: "absolute",
    right: 20,
    top: 13,
    zIndex: 1,
    height: "24px",
    width: '24px !important',
    cursor: 'pointer'
});
const ErrorTag = styled(Typography)({
    fontSize: 12,
    color: 'red',
    textAlign: 'left' as const
});
const CountryDiv = styled('div')({
    "&::-webkit-scrollbar": {
        display: "none",
    },

    "-ms-overflow-style": "none",
    "scrollbar-width": "none",
})

export default withStyles(styles)(SellerSignUp);
export { SellerSignUp }
// Customizable Area End
