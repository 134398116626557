import React from "react";
import {
    // Customizable Area Start
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    Box
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { withStyles } from '@material-ui/core/styles';
import { App_Logo } from "../../blocks/email-account-registration/src/assets";

export interface Props {
    classes: any;
    displayPopup: boolean;
    closeErrorPopup: any;
    errorMessage: string;
}
// Customizable Area End

class MessageModal extends React.Component<Props> {
    constructor(props: Readonly<Props>) {
        super(props);

    }
    render() {
        // Customizable Area Start
        const { classes, displayPopup, closeErrorPopup, errorMessage } = this.props
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <Dialog
                    open={displayPopup}
                    onClose={closeErrorPopup}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    PaperProps={{
                        style: {
                            borderRadius: 25, // Adjust the border radius as needed
                            maxWidth: '450px',
                            width: '100%'
                        },
                    }}
                >
                    <DialogTitle id="alert-dialog-title">
                        <Box style={{ display: 'flex', justifyContent: 'center' }}>
                            <img src={App_Logo} alt="" style={{ maxWidth: '160px' }} />
                            <img src={require("./close.svg")} alt={'close'} onClick={closeErrorPopup} style={{ cursor: 'pointer', position: 'absolute', top: '16px', right: '16px' }} />
                        </Box>
                    </DialogTitle>
                    <DialogContent style={{ marginTop: '-20px' }}>
                        <DialogContentText id="alert-dialog-description" className={classes.popUpContent}>
                            {errorMessage}
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            </>
            // Customizable Area End
        )
    }
}

// Customizable Area Start
const styles: any = () => ({
    displayFlexBetween: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: '10px',
        "& .MuiCheckbox-root": {
            color: "white",
            padding: '0px',
        },
        "& .Mui-checked.MuiCheckbox-root": {
            color: "#F5EA16"
        }
    },
    popUpTitle: {
        color: 'black',
        fontWeight: 'bold',
        fontSize: '18px',
        padding: '5px 0'
    },
    popUpContent: {
        color: 'black',
        fontSize: '18px',
        padding: '10px',
        textAlign: 'center'
    },
    cursorPointer: {
        cursor: 'pointer',
        marginRight: '-12px'
    }
})
export default withStyles(styles)(MessageModal);
// Customizable Area End