import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Dialog, DialogContent, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { imgConfetti } from "../../blocks/landingpage/src/assets";
import { App_Logo } from "../../blocks/email-account-registration/src/assets";

interface myProps {
    openTunedBox: boolean;
    closeTunedBox: () => void;
}

const useStyles = makeStyles(() => ({
    stayTunedDialog: {
        "& .MuiBackdrop-root": {
            background: '#000000B2'
        }
    },
    stayTunedContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth: '526px',
        gap: '16px',
        padding: '24px'
    }
}));

export default function StayTuned(props: myProps) {

    const { openTunedBox, closeTunedBox } = props;
    const classes = useStyles();

    return (
        <Dialog
            open={openTunedBox}
            onClose={closeTunedBox}
            PaperProps={{
                style: {
                    borderRadius: 20,
                },
            }}
            className={classes.stayTunedDialog}
        >
            <DialogContent className={classes.stayTunedContainer}>
                <CloseIcon onClick={closeTunedBox} style={{ position: 'absolute', top: '12px', right: '12px', cursor: 'pointer' }} />
                <img src={App_Logo} alt="" />
                <img src={imgConfetti} />
                <Typography style={{ fontSize: '20px', fontWeight: 600, color: '#444444' }}>Stay Tuned!</Typography>
                <Typography style={{ fontSize: '16px', color: '#444444CC', textAlign: 'center' }}>You've discovered a future feature! We're working to bring this to you soon. Stay tuned for more updates and thanks for exploring <b>Flixoo Live</b></Typography>
            </DialogContent>
        </Dialog>
    )
}
