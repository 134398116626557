import React from "react";
// Customizable Area Start
import SellerForgotPasswordController, {
    Props
} from "./SellerForgotPasswordController.web";

import { withStyles, StyleRules } from "@material-ui/core/styles";
import {
    Box,
    Grid,
} from '@material-ui/core';
import { App_Logo, Wave } from "../../email-account-registration/src/assets";
import { ErrorTag, FormBox, HeadingTypography, InputGrid, InputTag, LeftGrid, LogoText, RightBox, RightGrid, StyledButton, SubHeadingTypography } from "../../../components/src/CustomComponentSellerForgotPassword.web";
import Loader from "../../../components/src/Loader.web";
import LoginFailModalWeb from "../../../components/src/LoginFailModal.web";
// Customizable Area End

const styles: StyleRules = {
    // Customizable Area Start
    root: {
        background: "#000",
        minHeight: "100vh"
    },
    firstWave: {
        position: 'absolute',
        bottom: 0,
        width: '100%'
    },
    secondWave: {
        position: 'absolute',
        bottom: 0,
        right: '12%',
        width: '100%'
    },
    // Customizable Area End
}

export class SellerForgotPassword extends SellerForgotPasswordController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;

        return (
            <Grid container className={classes.root}>
                <Loader loading={this.state.Loading} />

                <LeftGrid item md={5} >
                    <Box>
                        <img src={App_Logo} alt="1" />
                        <LogoText >Live shopping & market place</LogoText>
                    </Box>
                </LeftGrid>

                <RightGrid item md={7} >

                    <RightBox>
                        <HeadingTypography>Forgot password</HeadingTypography>
                        <SubHeadingTypography>Please enter your email address or mobile number associated with your account.</SubHeadingTypography>
                        <FormBox container>
                            <div style={{ display: 'flex', gap: '10px', width: '100%', margin: "35px 0px" }}>
                                <InputGrid item xs={12}>
                                    <InputTag
                                        id="outlined-number"
                                        type="text"
                                        label='Email Id / Mobile number'
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onBlur={this.handleInputValidation}
                                        onChange={this.handleInputChange}
                                        variant="outlined"
                                    />
                                </InputGrid>
                            </div>
                            <ErrorTag>{this.state.ShowMobileField ? this.state.PhoneError : this.state.EmailError}</ErrorTag>
                        </FormBox>
                        <StyledButton onClick={this.sendOtp}>Get OTP</StyledButton>
                    </RightBox>

                    <LoginFailModalWeb
                        displayPopup={this.state.ShowAlert}
                        closeErrorPopup={this.closeAlert}
                        titleMessage={'Error'}
                        errorMessage={this.state.errMessage} />

                </RightGrid>

                <img src={Wave} className={classes.firstWave} alt="wave1" />
                <img src={Wave} className={classes.secondWave} alt="wave1" />
            </Grid>
        )
        // Customizable Area End
    }
}

// Customizable Area Start
export default withStyles(styles)(SellerForgotPassword);
// Customizable Area End