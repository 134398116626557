// Customizable Area Start
import React from "react";

import {
    Button,
    IconButton,
    // Customizable Area Start
    Tabs,
    Tab,
    Badge,Box,Avatar,styled,Typography,TextField,List, ListItem, ListItemAvatar, ListItemText
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import CloseIcon from '@material-ui/icons/Close';
import '../assets/css/styleSeller.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

import {
    star,
    story1,
    story2,
    story3,
    story4,
    story5,
    story6,
    AddGrayIc,
    business1,
    business2,
    business3,
    business4,
    business5,
    calendarIc,
    notificationIcon,
    notificationRedDotIc,
    hamburgerIc,
    postCardImgSeller,
    likeIc,
    likerSeller,
    CloseIc,
    profileImg,
    commentIc,
    collaborationSeller,
    fileSucessIc,
    AddYellowIc,
    EditYellowIc,
    bannerImageBlankSeller

} from "./assets";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import SellerSidebar from "../../landingpage/src/SellerSidebar.web";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Popover from '@material-ui/core/Popover'
import StayTuned from "../../../components/src/StayTuned.web";
import SellerNotificationModal from "../../../components/src/SellerNotificationModal.web";
const errorMessageLive = (
    <div style={{ textAlign: 'center', fontSize: '16px', color: '#D32F2F' }}>
      <p><strong>Oops!</strong></p>
      <p>The live streaming feature is only available on mobile. To watch live, please use our mobile app for an enhanced viewing experience.</p>
      <p><strong>Download the App Now!</strong></p>
    </div>
  );
const truthyValue = (key: any) => {
    if (key !== "" || key !== null || !key.length) {
        return key
    } else {
        return ""
    }
}
const ReplyContainer = styled(Box)({
    alignItems: 'center',
    marginTop: '5px',
    display: 'flex',
    '& .blankLine': {
        marginRight: '10px',
        height: '3px',
        background: '#B5B3A76E',
        width: '50px',
    },
    '& .text': {
        cursor: 'pointer', 
        color: '#1E1A00',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
})
const CommentContainer = styled(Box)({
    marginRight: '10px',
    marginTop: '20px',
    border: '1px solid black',
    borderRadius: '50px',
    maxWidth: '500px',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    "& .avatar":{
        marginLeft: '10px',
    },
    "& .input": {
        flex: 1,
        '& .MuiOutlinedInput-root': {
            borderColor: 'transparent',
            borderRadius: '30px',
            '& .MuiOutlinedInput-notchedOutline': {
                border: 'none'
            }
        },
    },
    "& .postButton": {
        borderRadius: '30px',
        border: 'none',
        fontWeight: 'bold',
        padding: '6px 16px',
        marginLeft: '10px',
      },
})
// Customizable Area End

import CustomisableSellerProfilesControllerWeb, {
    PostDataPayloadType,
    Props, baseURL
} from "./CustomisableSellerProfilesControllerWeb";
import MessageModalWeb from "../../../components/src/MessageModal.web";
import StoryListing from "../../../components/src/CustomStoryListing.web";
import PostCardRow from "../../../components/src/PostCartRow.web";
import { conditionalRenderer } from "../../../components/src/utils";
import MessageModalDownloadWeb from "../../../components/src/MessageModalDownload.web";

export default class SellerProfile extends CustomisableSellerProfilesControllerWeb {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    abstractSellerData = () => {
        let businessName = ""
        let username = ""
        let profilephoto = ""
        let backgroundphoto = ""
        let role = ""
        let bio = ""
        if (this.state.sellerProfileData) {
            businessName = truthyValue(this.state.sellerProfileData.attributes.business_name)
            username = truthyValue(this.state.sellerProfileData.attributes.user_name)
            profilephoto = truthyValue(this.state.sellerProfileData.attributes.profile_photo)
            backgroundphoto = truthyValue(this.state.sellerProfileData.attributes.background_photo)
            role = truthyValue(this.state.sellerProfileData.attributes.role.name)
            bio = truthyValue(this.state.sellerProfileData.attributes.my_bio)
        }
        return { businessName, username, profilephoto, backgroundphoto, role, bio }
    }

    renderStoryModal = () => {
        return (
            <StoryListing
                data-test-id="storyModalTestID"
                allStoryHeader={this.state.allStoryHeader}
                storyMuted={this.state.storyMuted}
                toggleMute={this.toggleMute}
                calculateHours={this.calculateHours}
                likeStory={this.likeStory}
                setSwiperRef={this.setSwiperRef}
                currentStoryIndex={this.state.currentStoryIndex}
                userStory={this.state.userStory}
                showNextButton={this.showNextButton}
                showPrevButton={this.showPrevButton}
                changeStory={this.changeStory}
                instaModal={this.state.instaModal}
                instaModalIndex={this.state.instaModalIndex}
                handleClose={this.handleClose}
                handleNext={this.handleNext}
                handlePrev={this.handlePrev} 
                userID={this.state.BuyerDataId}
                storyId={() =>{}} 
                commentMessage={() =>{}}    
                isMessageSent={""}     
            />
        );
    };
    parseAndHighlightMentions = (comment: string ) => {
        const mentionRegex = /(@\w+)/g;
        const parts = (comment || '').split(mentionRegex);
    
        return parts.map((part, index) => {
          if (part.startsWith('@')) {
            const username = part.slice(1);
              return (
                <span key={index} style={{color: 'rgb(0, 55, 107)' }} className="mention" data-username={username}>
                  {part}
                </span>
              );
          }
          // Return as plain text
          return <span key={index}>{part}</span>;
        });
      };
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { businessName, username, profilephoto, backgroundphoto, role, bio } = this.abstractSellerData()
        const allRead = this.state.allNotifications.every(notification => notification.attributes.is_read);
        // Customizable Area End
        return (
            // Customizable Area Start
            <div className="main-div">
                <div className="dashboard-outer" data-test-id="sellerProfileMainContainer">
                    <div className="dashboard-inner">
                        <SellerSidebar
                            navigation={""}
                            id={""}
                            classes={"profile"}
                            isOpen={this.state.isOpen}
                            data-test-id="sellerSidebar"
                            closeModal={() => this.toggleDrawer()}
                        />
                        <div className="right-outer">
                            <div className="right-inner">
                                <div className="title-wrap">
                                    <div className="left-wrap">
                                        <span className="title">Profile</span>
                                    </div>
                                    <div className="right-wrap">
                                        <div className="btn-wrap">
                                            <div className="account-list" onClick={this.OpenAccountList}>
                                                <div className="account-title">{username}</div>
                                                <div className="account-list-icon">{this.state.accountListOpen ? <ExpandLess /> : <ExpandMore />}</div>
                                            </div>
                                            <Popover
                                                className="account-list-popover"
                                                open={this.state.accountListOpen}
                                                onClose={() => this.CloseAccountList()}
                                                anchorEl={this.state.accountListAnchorEl}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'right',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right',
                                                }}
                                            >
                                                <div className="account-list-details">
                                                    <div className="person-detail-wrap">
                                                        <div className="img-wrap">
                                                            <Avatar src={profilephoto} alt="Profile">
                                                            </Avatar>
                                                        </div>
                                                        <div className="detail">
                                                            <span className="title">{businessName}</span>
                                                            <span className="name">{(role == 'seller' ? "Seller’s account" : 'Buyer’s account')}</span>
                                                        </div>
                                                        <div className="account-select">
                                                            <img src={fileSucessIc} alt="file success" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </Popover>
                                            <IconButton className="icon-btn">
                                                <img src={calendarIc} alt="calendar" />
                                            </IconButton>
                                            <IconButton className="icon-btn"  onClick={this.handleNotificationBoxToggle} data-test-id="notificationIcon">
                                            <Badge color="secondary" variant={ !allRead? "dot" : "standard"}>
                                                <img src={notificationIcon} alt="notification" />
                                             </Badge>
                                            </IconButton>
                                          
                                            <IconButton
                                                className="icon-btn hamburger-btn"
                                                data-test-id="hamburger-btn"
                                                onClick={() => this.toggleDrawer()}
                                            >
                                                <img src={hamburgerIc} alt="hamburger" />
                                               
                                            </IconButton>
                                        </div>
                                           <>
                                                {this.state.isNotificationBoxOpen && (
                                                    <SellerNotificationModal closeNotificationBox={this.handleNotificationBoxToggle} allNotifications={this.state.allNotifications}  selectedTab={this.state.selectedTab} handleTabChange={this.handleTabChange} />
                                                )}
                                            </>
                                    </div>
                                </div>
                                <section className="banner-section-seller">
                                    {backgroundphoto ?
                                        <div className="banner-img-wrap-seller">
                                            <img src={backgroundphoto} alt="banner Img" />
                                        </div>
                                        :
                                        <div className="banner-img-wrap-seller no-bg">
                                            <img className="no-bg" src={bannerImageBlankSeller} alt="banner Img" />
                                        </div>
                                    }
                                    <div className="profile-detail-seller">
                                        <div className="profile-detail-inner-seller">
                                            <div className="profile-photo-wrap-seller">
                                                <Avatar src={profilephoto} className="profile-photo-seller">
                                                </Avatar>
                                                <IconButton className="edit-icon">
                                                    <img src={profilephoto ? EditYellowIc : AddYellowIc} alt="Edit" />
                                                    <input
                                                        accept="image/*"
                                                        id="upload-avatar-pic"
                                                        type="file"
                                                        data-test-id="uploadFile"
                                                        className="upload"
                                                        onChange={this.handleSellerProfileImage}
                                                    />
                                                </IconButton>
                                            </div>
                                            <div className="profile-description-wrap">
                                                <div className="name-wrap">
                                                    <span className="name">{businessName}</span>
                                                    <span className="review">
                                                        4.5
                                                        <i>
                                                            <img src={star} alt="Review" />
                                                        </i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <section className="following-section-seller">
                                    <div className="following-followers-wrap">
                                        <div className="title-number-wrap"
                                            data-test-id="followers"
                                            onClick={()=>this.handleOpenModalFollowers()}>
                                            <span className="title">Followers</span>
                                            <span className="number">{this.state.sellerFollowersData.length}</span>
                                        </div>
                                        <div className="title-number-wrap"
                                            data-test-id="following"
                                            onClick={() => this.openStayTunedBox()}>
                                            <span className="title">Following</span>
                                            <span className="number">0</span>
                                        </div>
                                        <div className="title-number-wrap">
                                            <span className="title">Reviews</span>
                                            <span className="number">0</span>
                                        </div>
                                    </div>
                                    <div className="business-service-wrap">
                                        <div className="inner-wrap">
                                            <div className="business-facility-main" data-test-id="cash" onClick={() => this.handleOpenModalCash()}>
                                                <div className="business-facility">
                                                    <img src={business1} alt="Edit" />
                                                </div>
                                                <span>cash on delivery</span>
                                            </div>
                                            <div className="business-facility-main" data-test-id="shipping" onClick={() => this.handleOpenModalShipping()}>
                                                <div className="business-facility">
                                                    <img src={business2} alt="Edit" />
                                                </div>
                                                <span>Avg. shipping time</span>
                                            </div>
                                            <div className="business-facility-main" data-test-id="order" onClick={() => this.handleOpenModalOrder()} style={{display: this.state.storeTypedata=== "offline"?"block":"none"}}>
                                                <div className="business-facility">
                                                    <img src={business3} alt="Edit" />
                                                    <span className="business-text">On Order</span>
                                                </div>
                                                <span>On Order</span>
                                            </div>
                                            <div className="business-facility-main" data-test-id="item" onClick={() => this.handleOpenModalItem()}>
                                                <div className="business-facility">
                                                    <img src={business4} alt="Edit" />
                                                </div>
                                                <span>Items sold</span>
                                            </div>
                                            <div className="business-facility-main" data-test-id="bargain" onClick={() => this.handleOpenModalBargain()} style={{display: this.state.storeTypedata=== "offline"?"block":"none"}}>
                                                <div className="business-facility">
                                                    <img src={business5} alt="Edit" />
                                                </div>
                                                <span>Bargain</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="business-content-wrap">
                                        <p>{bio}</p>
                                    </div>
                                    <div className="btn-wrap">
                                        <Button
                                            className="custom-button primary-yellow seller-profile-yellow"
                                            component={Link} to={'/SellerUserBusinessEdit'}
                                        >
                                            User & business
                                        </Button>
                                        <Button className="custom-button outline-yellow seller-profile-transparent" data-test-id="buyerOpenStayTuned" onClick={this.openStayTunedBox}>
                                            View as buyer
                                        </Button>
                                    </div>
                                    <div className="stories-highlights-wrap">
                                        <div className="inner-wrap">
                                            <div className="story-outer" onClick={this.createStoryNav}>
                                                <div className="story-new">
                                                    <IconButton className="edit-icon-story">
                                                        <img src={AddGrayIc} alt="Edit" />
                                                    </IconButton>
                                                </div>
                                                <div>
                                                    <p className="story-new-text">New</p>
                                                </div>
                                            </div>
                                            {this.state.userStory.map((item, index) => {
                                                return (
                                                    <>
                                                        {
                                                            item?.attributes?.creator_details?.id == this.state.BuyerDataId &&
                                                            <div className="story" data-test-id={`openStoryTestID${index}`} onClick={() => { this.handleClickOpen(index) }}>
                                                                {item.attributes.media.type === "image" ?
                                                                    <Avatar src={item.attributes.media.url} data-test-id={`imgUserTestID${index}`} className="profile-photo" />
                                                                    : <video src={item.attributes.media.url}
                                                                        autoPlay={false}
                                                                        width={"100%"}
                                                                        height={"100%"}
                                                                        muted={this.state.storyMuted}
                                                                        playsInline />
                                                                }
                                                            </div>
                                                        }
                                                    </>
                                                )
                                            })}
                                        </div>
                                        {this.state.allStoryHeader.length?this.renderStoryModal():<></>}
                                    </div>
                                </section>
                                <section className="tabs-section">
                                    <div className="tabs-wrap">
                                        <Tabs
                                            value={this.state.tabValue}
                                            onChange={this.handleChange}
                                            className="custom-tabs-list"
                                            data-test-id="sellerProfileTab"
                                        >
                                            <Tab label="Posts" disableRipple />
                                            <Tab label="Lives" disableRipple />
                                            <Tab label="Top products" disableRipple />
                                            <Tab label="Collaborations" disableRipple />
                                        </Tabs>
                                        {this.state.tabValue === 0 && (
                                            <div className="seller-tabs-content">
                                                <div className="seller-inner-tab-content">
                                                    <div className="seller-inner-tab-wrap">
                                                        <div className="post-cards-wrapp">
                                                            <Box className="postInnerContainer">
                                                                {this.state.sellerPostsData.map(
                                                                    (postData: PostDataPayloadType, postIndex: number) => {
                                                                        return (
                                                                            <PostCardRow
                                                                                item={postData}
                                                                                postKeyId={postIndex}
                                                                                postId={postData.id}
                                                                                handleToggleComment={this.handleToggleComment}
                                                                                handleToggleLikePost={this.handlePostLikeUnlike}
                                                                                data-test-id={`postCardComponentTestId${postIndex}`}
                                                                            />
                                                                        );
                                                                    }
                                                                )}
                                                            </Box>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    
                                        {this.state.showCommentBox && (
                                            <>
                                                <div
                                                    style={{
                                                        position: "fixed",
                                                        top: 0,
                                                        left: 0,
                                                        right: 0,
                                                        bottom: 0,
                                                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                                                        zIndex: 999, 
                                                    }}
                                                />
                                            <Box
                                                className="suggestionCommentBoxPopup"
                                                style={{
                                                    position: "fixed",
                                                    top: "50%",
                                                    left: "50%",
                                                    transform: "translate(-50%, -50%)",
                                                    width: "auto",
                                                    overflowY: "auto",
                                                    backgroundColor: "white",
                                                    padding: "20px",
                                                    borderRadius: "10px",
                                                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                                                    zIndex: 1000
                                                }}
                                            >
                                                <Box className="commentBoxHeader" style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                                                    <Typography variant="h6">Comments</Typography>
                                                    <IconButton onClick={this.handleCommentBoxClose}>
                                                        <CloseIcon />
                                                    </IconButton>
                                                </Box>

                                                <Box className="suggestionMainCommentContainer" data-testid="suggestion-main-comment-container" style={{ maxHeight: "400px", overflowY: "auto", scrollbarWidth:"none" }}>
                                                    {this.state.listCommentData.data.map((item, itemIndex) => (
                                                        <Box id="test4" className="suggestedUserBox" key={itemIndex} style={{ margin: '10px 0px'}}>
                                                            <Box className="suggestionLeftBoxForComment" style={{display: "flex",gap: "12px", alignItems: "start", margin: '10px 0px'}}>
                                                                <Box data-test-id="test5" className="avatarSuggestionBox">
                                                                    <Avatar src={item?.attributes?.details?.profile_photo} className="suggestionAvatar" />
                                                                </Box>
                                                                <Box id="test6" className="suggestionInfoBox">
                                                                    <Typography className="userNameTxt foruser">{item?.attributes?.details?.full_name}</Typography>
                                                                    <Typography data-test-id="test7" className="userFullNameTxt">
                                                                        {this.parseAndHighlightMentions(String(item?.attributes?.details?.comment || ''))}
                                                                    </Typography>
                                                                    <Box className="suggestionLinkComment changesclass" style={{display: 'flex',justifyContent: 'start',alignItems: 'center',gap: '15px'}}>
                                                                        <Typography id="test8" className="userNameTxt">{item?.attributes?.details?.created_at}</Typography>
                                                                        <Typography
                                                                            onClick={() => this.likeCommentApiCall(item.id)}
                                                                            style={{
                                                                                cursor: 'pointer',
                                                                                color: conditionalRenderer((item.attributes.details.other_like), 'rgb(0, 55, 107)', '#44444480')
                                                                            }}
                                                                            data-test-id="test9"
                                                                            className="userFullNameTxt"
                                                                        >
                                                                            {item?.attributes?.details?.like_count} Likes
                                                                        </Typography>
                                                                        <Typography
                                                                            className="userFullNameTxt testcases"
                                                                            style={{ cursor: 'pointer', color: item.id === this.state.setReplyId ? "rgb(245, 234, 22)" : "" }}
                                                                            data-test-id="oneTapReply"
                                                                            onClick={() => this.clickOnReplyForGetId(item.id)}
                                                                        >
                                                                            Reply
                                                                        </Typography>
                                                                    </Box>

                                                                    {item?.attributes?.details?.replies_count > 0 && (
                                                                        <ReplyContainer>
                                                                            <div id="test10" className="blankLine"></div>
                                                                            <Typography
                                                                                data-test-id="clickReply"
                                                                                className="text"
                                                                                variant="body2"
                                                                                onClick={() => this.viewDetilsExpandable(parseInt(item?.id))}
                                                                            >
                                                                                {!this.state.clickReply.includes(parseInt(item.id)) ? "View" : "Hide"} {item?.attributes?.details?.replies_count} replies
                                                                            </Typography>
                                                                        </ReplyContainer>
                                                                    )}

                                                                    {this.state.clickReply.includes(parseInt(item.id)) &&
                                                                        item.attributes.details.sub_post_comments.map((value, itemIndex) => (
                                                                            <Box className="suggestionLeftBoxForComment" key={itemIndex} style={{display: "flex",gap: "12px", alignItems: "start", margin: '10px 0px'}}>
                                                                                <Box className="avatarSuggestionBox">
                                                                                    <Avatar src={value.profile_photo} className="suggestionAvatar" />
                                                                                </Box>
                                                                                <Box className="suggestionInfoBox">
                                                                                    <Typography data-test-id="test21" className="userNameTxt">{value.full_name}</Typography>
                                                                                    <Typography id="test22" className="userFullNameTxt">
                                                                                        {this.parseAndHighlightMentions(String(value.comment || ''))}
                                                                                    </Typography>

                                                                                    <Box className="suggestionLinkComment adddedclass" style={{display: 'flex',justifyContent: 'start',alignItems: 'center',gap: '15px'}}>
                                                                                        <Typography data-test-id="test23" className="userNameTxt" style={{ marginTop: '4px' }}>
                                                                                            {value.created_at}
                                                                                        </Typography>
                                                                                        <Typography
                                                                                            style={{
                                                                                                cursor: 'pointer',
                                                                                                color: conditionalRenderer((value.other_like), 'rgb(0, 55, 107)', '#44444480')
                                                                                            }}
                                                                                            data-test-id="sallerReplyId"
                                                                                            onClick={() => this.likeReplyCommentApiCall(value?.sub_comment_id)}
                                                                                            id="test24"
                                                                                            className="userFullNameTxt"
                                                                                        >
                                                                                            {value.like_count} Likes
                                                                                        </Typography>
                                                                                    </Box>
                                                                                </Box>
                                                                            </Box>
                                                                        ))
                                                                    }
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    ))}
                                                </Box>

                                                <CommentContainer>
                                                    <Avatar
                                                        alt="User Avatar"
                                                        className="avatar"
                                                        src={this.state.profileImage}
                                                    />
                                                    <div style={{ position: "relative" }}>
                                                        <TextField
                                                            onChange={this.handleInputChange}
                                                            onKeyDown={this.handleKeyDown}
                                                            className="input"
                                                            fullWidth
                                                            data-test-id="changeValueTestId"
                                                            value={this.state.addComment}
                                                            variant="outlined"
                                                            placeholder="Add a Comment"
                                                            style={{width:"100%"}}
                                                        />
                                                    </div>
                                                    <Button
                                                        onClick={this.state.setReplyId === "" ? this.postCommentApiCall : this.postCommentOnCommentApiCall}
                                                        color="default"
                                                        className="postButton"
                                                        variant="outlined"
                                                        disabled={this.state.addComment.length === 0}
                                                    >
                                                        POST
                                                    </Button>
                                                </CommentContainer>
                                            </Box>
                                            </>
                                        )}
                                        {this.state.tabValue === 1 && (
                                            <div className="seller-tabs-content">
                                                <div className="seller-inner-tab-content">
                                                    <div className="seller-inner-tab-wrap">
                                                        <div className="post-cards-wrap">
                                                            {this.state.sellerLiveStreamData.map((item: any) => (
                                                                <div className="post-card" key={item.id}>
                                                                    <div className="inner-wrap">
                                                                        <div className="img-wrap" onClick={this.openLiveModal}>
                                                                            <div className="inner-img-wrap">
                                                                            <img src={`${baseURL}${item.attributes?.catalogues[0]?.images[0]?.url}`} alt="Card img" />
                                                                            </div>
                                                                            <div className="content-wrap-product">
                                                                                <span className="product-title">{item.attributes?.catalogues[0]?.catalogue_title}</span>
                                                                                <span className="product-content">{item.attributes.catalogues[0]?.catalogue_description}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {this.state.tabValue === 2 && (
                                            <div className="seller-tabs-content">
                                                <div className="seller-inner-tab-content">
                                                    <div className="seller-inner-tab-wrap">
                                                        <div className="post-cards-wrap">
                                                            {this.state.sellerTopProduct.map((item: any) => (
                                                                <div className="post-card" >
                                                                    <div className="inner-wrap">
                                                                        <div className="img-wrap" >
                                                                            <div className="inner-img-wrap">
                                                                                <img src={`${baseURL}${item.attributes.product_images[0].url}`} alt="Card img" onClick={()=>this.topProductClick(item.id)} />
                                                                            </div>
                                                                            <div className="content-wrap-product">
                                                                                <span className="product-title" onClick={()=>this.topProductClick(item.id)}>{item.attributes.title}</span>
                                                                                <span className="product-content">{item.attributes.description}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
                <StayTuned openTunedBox={this.state.openTunedBox} data-test-id="closeStayTuned" closeTunedBox={this.closeTunedBox} />
                <Dialog
                    open={this.state.openModalFollowing}
                    data-test-id="followingClose"
                    onClose={() => this.handleCloseModalFollowing()}
                    className="custom-dialog"
                >
                    <DialogTitle className="title-wrap">
                        <span>Following</span>
                        <IconButton
                            aria-label="close"
                            className="close-btn"
                            data-test-id="followingCloseButton"
                            onClick={() => this.handleCloseModalFollowing()}
                        >
                            <img src={CloseIc} alt="Close" />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent className="dialog-content">
                        <div className="inner-wrap">
                            <div className="person-detail-wrap">
                                <div className="img-wrap">
                                    <img src={profileImg} alt="Profile" />
                                </div>
                                <div className="detail">
                                    <span className="title">Breakout_Swinger1</span>
                                    <span className="name">Vaibhav Verma1</span>
                                </div>
                                <div className="unfollow-btn">
                                    <Button className="custom-button outline-yellow">
                                        Unfollow
                                    </Button>
                                </div>
                            </div>
                            <div className="person-detail-wrap">
                                <div className="img-wrap">
                                    <img src={profileImg} alt="Profile" />
                                </div>
                                <div className="detail">
                                    <span className="title">Breakout_Swinger2</span>
                                    <span className="name">Vaibhav Verma2</span>
                                </div>
                                <div className="unfollow-btn">
                                    <Button className="custom-button outline-yellow">
                                        Unfollow
                                    </Button>
                                </div>
                            </div>
                            <div className="person-detail-wrap">
                                <div className="img-wrap">
                                    <img src={profileImg} alt="Profile" />
                                </div>
                                <div className="detail">
                                    <span className="title">Breakout_Swinger3</span>
                                    <span className="name">Vaibhav Verma3</span>
                                </div>
                                <div className="unfollow-btn">
                                    <Button className="custom-button outline-yellow">
                                        Unfollow
                                    </Button>
                                </div>
                            </div>
                            <div className="person-detail-wrap">
                                <div className="img-wrap">
                                    <img src={profileImg} alt="Profile" />
                                </div>
                                <div className="detail">
                                    <span className="title">Breakout_Swinger4</span>
                                    <span className="name">Vaibhav Verma4</span>
                                </div>
                                <div className="unfollow-btn">
                                    <Button className="custom-button outline-yellow">
                                        Unfollow
                                    </Button>
                                </div>
                            </div>
                            <div className="person-detail-wrap">
                                <div className="img-wrap">
                                    <img src={profileImg} alt="Profile" />
                                </div>
                                <div className="detail">
                                    <span className="title">Breakout_Swinger5</span>
                                    <span className="name">Vaibhav Verma5</span>
                                </div>
                                <div className="unfollow-btn">
                                    <Button className="custom-button outline-yellow">
                                        Unfollow
                                    </Button>
                                </div>
                            </div>
                            <div className="person-detail-wrap">
                                <div className="img-wrap">
                                    <img src={profileImg} alt="Profile" />
                                </div>
                                <div className="detail">
                                    <span className="title">Breakout_Swinger6</span>
                                    <span className="name">Vaibhav Verma6</span>
                                </div>
                                <div className="unfollow-btn">
                                    <Button className="custom-button outline-yellow">
                                        Unfollow
                                    </Button>
                                </div>
                            </div>
                            <div className="person-detail-wrap">
                                <div className="img-wrap">
                                    <img src={profileImg} alt="Profile" />
                                </div>
                                <div className="detail">
                                    <span className="title">Breakout_Swinger7</span>
                                    <span className="name">Vaibhav Verma7</span>
                                </div>
                                <div className="unfollow-btn">
                                    <Button className="custom-button outline-yellow">
                                        Unfollow
                                    </Button>
                                </div>
                            </div>
                            <div className="person-detail-wrap">
                                <div className="img-wrap">
                                    <img src={profileImg} alt="Profile" />
                                </div>
                                <div className="detail">
                                    <span className="title">Breakout_Swinger8</span>
                                    <span className="name">Vaibhav Verma8</span>
                                </div>
                                <div className="unfollow-btn">
                                    <Button className="custom-button outline-yellow">
                                        Unfollow
                                    </Button>
                                </div>
                            </div>
                            <div className="person-detail-wrap">
                                <div className="img-wrap">
                                    <img src={profileImg} alt="Profile" />
                                </div>
                                <div className="detail">
                                    <span className="title">Breakout_Swinge9r</span>
                                    <span className="name">Vaibhav Verma9</span>
                                </div>
                                <div className="unfollow-btn">
                                    <Button className="custom-button outline-yellow">
                                        Unfollow
                                    </Button>
                                </div>
                            </div>
                            <div className="person-detail-wrap">
                                <div className="img-wrap">
                                    <img src={profileImg} alt="Profile" />
                                </div>
                                <div className="detail">
                                    <span className="title">Breakout_Swinger10</span>
                                    <span className="name">Vaibhav Verma10</span>
                                </div>
                                <div className="unfollow-btn">
                                    <Button className="custom-button outline-yellow">
                                        Unfollow
                                    </Button>
                                </div>
                            </div>
                            <div className="person-detail-wrap">
                                <div className="img-wrap">
                                    <img src={profileImg} alt="Profile" />
                                </div>
                                <div className="detail">
                                    <span className="title">Breakout_Swinger11</span>
                                    <span className="name">Vaibhav Verma11</span>
                                </div>
                                <div className="unfollow-btn">
                                    <Button className="custom-button outline-yellow">
                                        Unfollow
                                    </Button>
                                </div>
                            </div>
                            <div className="person-detail-wrap">
                                <div className="img-wrap">
                                    <img src={profileImg} alt="Profile" />
                                </div>
                                <div className="detail">
                                    <span className="title">Breakout_Swinger12</span>
                                    <span className="name">Vaibhav Verma12</span>
                                </div>
                                <div className="unfollow-btn">
                                    <Button className="custom-button outline-yellow">
                                        Unfollow
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
                <Dialog
                    open={this.state.openModalFollowers}
                    data-test-id="followersClose"
                    onClose={() => this.handleCloseModalFollowers()}
                    className="custom-dialog"
                >
                    <DialogTitle className="title-wrap">
                        <span>Followers</span>
                        <IconButton
                            aria-label="close"
                            className="close-btn"
                            data-test-id="followersCloseButton"
                            onClick={() => this.handleCloseModalFollowers()}
                        >
                            <img src={CloseIc} alt="Close" />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent className="dialog-content">
                        <div className="inner-wrap">
                        {this.state.sellerFollowersData.map((follower:any, index:any) => (
                            <div className="person-detail-wrap">
                                <div className="img-wrap">
                                <Avatar className="avatars" src={follower.attributes.account.profile_url} alt={follower.attributes.account.user_name} />
                                </div>
                                <div className="detail">
                                    <span className="title">{follower.attributes.account.user_name}</span>
                                    <span className="name">{follower.attributes.account.full_name}</span>
                                </div>
                                <div className="unfollow-btn">
                                    <Button className="custom-button outline-yellow" onClick={()=>this.removeSellerFollowers(follower.attributes.account_id)}>
                                        Remove
                                    </Button>
                                </div>
                            </div>
                        ))}
                        </div>
                    </DialogContent>
                </Dialog>
                <Dialog
                    open={this.state.cashModal}
                    data-test-id="cashClose"
                    onClose={() => this.handleCloseModalCash()}
                    className="custom-dialog rounded-border"
                >
                    <DialogContent className="dialog-content">
                        <div className="inner-wrap">
                            <div className="business-facility">
                                <img src={business1} alt="Edit" />
                            </div>
                            <span className="business-facility-title">Cash on delivery</span>
                            <span className="business-facility-content">Cash on Delivery (COD) is a payment method where the recipient pays for goods at the time of delivery rather than in advance. This option can increase customer trust and convenience, particularly in regions where online payment options are less prevalent or trusted.</span>
                        </div>
                    </DialogContent>
                </Dialog>
                <Dialog
                    open={this.state.shippingModal}
                    data-test-id="shippingClose"
                    onClose={() => this.handleCloseModalShipping()}
                    className="custom-dialog rounded-border"
                >
                    <DialogContent className="dialog-content">
                        <div className="inner-wrap">
                            <div className="business-facility">
                                <img src={business2} alt="Edit" />
                            </div>
                            <span className="business-facility-title">Avg. Shipping Time</span>
                            <span className="business-facility-content">Average Shipping Time refers to the typical duration it takes for an item to be delivered from the time an order is placed until it reaches the customer. This metric is crucial for customer satisfaction and can influence purchasing decisions. Businesses strive to minimize average shipping time to remain competitive.Average Shipping Time refers to the typical duration it takes for an item to be delivered from the time an order is placed until it reaches the customer. This metric is crucial for customer satisfaction and can influence purchasing decisions. Businesses strive to minimize average shipping time to remain competitive.</span>
                        </div>
                    </DialogContent>
                </Dialog>
                <Dialog
                    open={this.state.orderModal}
                    data-test-id="orderClose"
                    onClose={() => this.handleCloseModalOrder()}
                    className="custom-dialog rounded-border"
                >
                    <DialogContent className="dialog-content">
                        <div className="inner-wrap">
                            <div className="business-facility">
                                <img src={business3} alt="Edit" />
                                <span className="business-text">On Order</span>
                            </div>
                            <span className="business-facility-title">On Order</span>
                            <span className="business-facility-content"> Items that are "On Order" are products that have been ordered from suppliers but have not yet arrived in the inventory. This status indicates that the stock is being replenished and will soon be available for sale. Managing on-order inventory is essential for maintaining stock levels and meeting customer demand.</span>
                        </div>
                    </DialogContent>
                </Dialog>
                <Dialog
                    open={this.state.itemModal}
                    data-test-id="itemClose"
                    onClose={() => this.handleCloseModalItem()}
                    className="custom-dialog rounded-border"
                >
                    <DialogContent className="dialog-content">
                        <div className="inner-wrap">
                            <div className="business-facility">
                                <img src={business4} alt="Edit" />
                            </div>
                            <span className="business-facility-title">Items Sold</span>
                            <span className="business-facility-content">The term "Item Sold" refers to a product that has been purchased by a customer. Tracking items sold helps businesses understand their sales performance, inventory turnover, and customer preferences. This information is crucial for strategic planning, inventory management, and marketing efforts.</span>
                        </div>
                    </DialogContent>
                </Dialog>
                <Dialog
                    open={this.state.bargainModal}
                    data-test-id="bargainClose"
                    onClose={() => this.handleCloseModalBargain()}
                    className="custom-dialog rounded-border"
                >
                    <DialogContent className="dialog-content">
                        <div className="inner-wrap">
                            <div className="business-facility">
                                <img src={business5} alt="Edit" />
                            </div>
                            <span className="business-facility-title">Bargain</span>
                            <span className="business-facility-content">A bargain is an item offered at a lower price than usual, often perceived as a good deal by customers. Bargains can be used as promotional tools to attract customers, clear out inventory, or boost sales during specific periods. Effective use of bargains can enhance customer satisfaction and increase sales volume.</span>
                        </div>
                    </DialogContent>
                </Dialog>
                <Dialog open={false} 
                // onClose={onClose} 
                className="custom-dialogs">
                    <div className="dialog-contents">
                        <div className="dialog-titles">
                            <Typography variant="h6">Followers</Typography>
                            <IconButton className="close-icons" 
                            // onClick={onClose}
                            >
                                <CloseIcon />
                            </IconButton>
                        </div>
                        <List>
                            {this.state.sellerFollowersData.map((follower:any, index:any) => (
                                <ListItem key={index} divider className="list-items">
                                    <ListItemAvatar>
                                        <Avatar className="avatars" src={follower.attributes.account.profile_url} alt={follower.attributes.account.user_name} />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={<span className="primary-texts">{follower.attributes.account.user_name}</span>}
                                        secondary={<span className="secondary-texts">{follower.attributes.account.full_name}</span>}
                                    />
                                    <Button className="remove-buttons">Remove</Button>
                                </ListItem>
                            ))}
                        </List>
                    </div>
                </Dialog>
                <MessageModalWeb data-test-id="errorMessage" displayPopup={this.state.errorModal} closeErrorPopup={this.errorModalClose} errorMessage={this.state.imageError} />
                <MessageModalDownloadWeb data-test-id="errorMessage" displayPopup={this.state.openLiveModal} closeErrorPopup={this.closeLiveModal} errorMessage={""}/>
            </div>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
// Customizable Area End
// Customizable Area End