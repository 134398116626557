import React, { useMemo } from "react";
const RE_DIGIT = new RegExp(/^\d+$/);

export type Props = {
  value: string;
  valueLength: number;
  onChange: (value: string) => void;
};
import {
  styled,
} from "@material-ui/core";

export default function OtpInputField({ value, valueLength, onChange }: Props) {
  const valueItems = useMemo(() => {
    const valueArray = value.split("");
    const items: Array<string> = [];

    for (let i = 0; i < valueLength; i++) {
      const char = valueArray[i];

      if (RE_DIGIT.test(char)) {
        items.push(char);
      } else {
        items.push("");
      }
    }

    return items;
  }, [value, valueLength]);

  const focusToNextInputField = (target: HTMLElement) => {
    const nextElementSibling = target.nextElementSibling as HTMLInputElement | null;

    if (nextElementSibling) {
      nextElementSibling.focus();
    }
  };
  const focusToPrevInputField = (target: HTMLElement) => {
    const previousElementSibling = target.previousElementSibling as HTMLInputElement | null;

    if (previousElementSibling) {
      previousElementSibling.focus();
    }
  };
  const inputOnChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    idx: number
  ) => {
    const target = e.target;
    let targetValue = target.value.trim();
    const isTargetValueDigit = RE_DIGIT.test(targetValue);

    if (!isTargetValueDigit && targetValue !== "") {
      return;
    }

    targetValue = isTargetValueDigit ? targetValue : " ";

    const targetValueLength = targetValue.length;
    console.log(targetValueLength, { targetValue })
    if (targetValueLength === 1) {
      const newValue =
        value.substring(0, idx) + targetValue + value.substring(idx + 1);

      onChange(newValue);

      if (!isTargetValueDigit) {
        return;
      }

      focusToNextInputField(target);
    }
    else if (targetValueLength === valueLength) {
      onChange(targetValue);
    }
  };
  const inputOnKeyDown = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { key } = e;
    console.log({ e })
    const target = e.target as HTMLInputElement;
    if (key === "ArrowRight" || key === "ArrowDown") {
      e.preventDefault();
      return focusToNextInputField(target);
    }

    if (key === "ArrowLeft" || key === "ArrowUp") {
      e.preventDefault();
      return focusToPrevInputField(target);
    }

    const targetValue = target.value;

    target.setSelectionRange(0, targetValue.length);

    if (e.key !== "Backspace" || targetValue !== "") {
      return;
    }


    focusToPrevInputField(target);
  };
  const inputOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    const { target } = e;

    target.setSelectionRange(0, target.value.length);
  }; 
  const inputOnPaste = (e: any) => {
   
  
    const pastedData = e.clipboardData.getData("text/plain");
    const pastedArray = pastedData.split("");
  
    for (let i = 0; i < pastedArray.length; i++) {
      const char = pastedArray[i];
  
      if (RE_DIGIT.test(char)) {
        const nextIdx = valueItems.findIndex((item) => item === "");
  
        if (nextIdx !== -1) {
          valueItems[nextIdx] = char;
          onChange(valueItems.join(""));
        }
      }
    }
  };
  

  return (
    <InputGroup className="otp-group" 
    >
      {valueItems.map((digit, idx) => (
        <InputField
          key={idx}
          data-test-id="otp-input"
          type="text"
          id={`otp-input-${idx}`}
          inputMode="numeric"
          autoComplete="one-time-code"
          pattern="\d{1}"
          maxLength={valueLength}
          className="otp-input"
          value={digit}
          onChange={(e) => inputOnChange(e, idx)}
          onKeyDown={inputOnKeyDown}
          onFocus={inputOnFocus}
          onPaste={inputOnPaste}
        />
      ))}
    </InputGroup>
  );
}

const InputField = styled('input')({
  width: '69px',
  borderRadius: '12px',
  marginBottom: 17,
  backgroundColor: 'black',
  color: 'white',
  height: '85px',
  textAlign: 'center',
  fontSize: '31px!important',
  fontWeight: 700,
  '@media(max-width: 480px)': {
    width: '39px',
    height: '45px',
    fontSize: '16px !important',
    paddingBottom: 0
  },
})

const InputGroup = styled('div')({
  '@media(max-width: 767px)': {
    gap: '9.5 !important',
    justifyContent:'center' 
  }
})
