Object.defineProperty(exports, "__esModule", {
  value: true,
});

exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Get Location";
exports.labelSorryText = "Sorry!";
exports.labelSorryBodyText = "There is no VR Centre in your city";
exports.cityListApiEndPoing = "bx_block_location/cities";
exports.updateProfileEndPoint = "bx_block_profile/profiles";
exports.validationApiMethodType = "GET";
exports.getApiMethodType = "GET";
exports.updateMethodType = "PUT";
exports.btnGetLocationTitle = "Get Location";
exports.btnDenyLocationTitle = "Do not Allow";
exports.btnSelectCenterTitle = "Select a Centre";
exports.errorTitle = "Error";

// Customizable Area Start
exports.enableYourLocation = "Enable your location"
exports.allowFlixoo = "Allow Flixoo to access your location"
exports.allowButton = "Allow"
exports.notNow = "Not now"
exports.unsupportedMessage = "Geolocation is not supported by this browser."
exports.userDeniedGeoLocation = "User denied the request for Geolocation."
exports.locatonInformationUnavailable ="Location information is unavailable."
exports.locationTimedOut ="The request to get user location timed out"
exports.postLocationEndPoint = "bx_block_landingpage/digital_mall/store_coordinates"
// Customizable Area End
