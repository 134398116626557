import React from "react";

import {
    // Customizable Area Start
    Box,
    Grid,
    Typography,
    styled,
    Button,
    makeStyles,
    Theme,
    createStyles,

    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import {
    twoWave,
    App_Logo,
} from "./assets";
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        instructions: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
        backButton: {
            marginRight: theme.spacing(1),
        },
    }),
);
const theme = createTheme({
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
});
// Customizable Area End

import AccountRegistrationController, {
    Props,
} from "./AccountRegistrationController";

class SubmittedDetails extends AccountRegistrationController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        // Customizable Area End
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <section className={classes.sectionSellerSignUp}>
                    <Box className={classes.SignupBgWrapper}>
                        <Box className={'overlayImg'}>
                            <img src={twoWave} alt="" />
                        </Box>
                        <Box className={classes.formWrapper}>
                            <ParentGrid container spacing={2} style={{ maxWidth: '1440px', margin: 'auto' }}>
                                <Grid item lg={5} md={6} sm={12} xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                                    <Box className={'leftColumn'}>
                                        <img src={App_Logo} alt="" />
                                        <Typography variant="h6" component={'h6'}>{'Live shopping & market place'}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item lg={7} md={6} sm={12} xs={12}>
                                    <Box className={'rightColumn'}>
                                        <Box className={'rightColumnInner'}>
                                            <Box style={{display: 'flex', alignItems: 'center', width: '100%'}}>
                                            <Box style={{minWidth: 56, height: 56, borderRadius: '50%', background: '#4BB543', display: 'flex', justifyContent:'center', alignItems: 'center'}}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="38" height="28" viewBox="0 0 38 28" fill="none">
                                                    <path d="M3.33301 14.7333L13.6663 25L34.333 3" stroke="white" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
                                                </svg>
                                            </Box>
                                            <Box style={{width: '100%', height: 3, background: 'gray'}}></Box>
                                            <Box style={{minWidth: 56, height: 56, borderRadius: '50%', background: '#4BB543', display: 'flex', justifyContent:'center', alignItems: 'center'}}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="38" height="28" viewBox="0 0 38 28" fill="none">
                                                    <path d="M3.33301 14.7333L13.6663 25L34.333 3" stroke="white" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
                                                </svg>
                                            </Box>
                                            <Box style={{width: '100%', height: 3, background: 'gray'}}></Box>
                                            <Box style={{minWidth: 56, height: 56, borderRadius: '50%', background: '#4BB543', display: 'flex', justifyContent:'center', alignItems: 'center'}}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="38" height="28" viewBox="0 0 38 28" fill="none">
                                                    <path d="M3.33301 14.7333L13.6663 25L34.333 3" stroke="white" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
                                                </svg>
                                            </Box>
                                            </Box>
                                            <Box className={'formHeading'}>
                                                <Typography variant="h6" component={'h6'}>{'Registration submitted '}</Typography>
                                                <Typography component={'p'}>{`Your seller profile registration has been `} <br /> {`successfully submitted`}</Typography>
                                            </Box>
                                            <Box className={classes.submitBtn}>
                                                <Button variant="contained"
                                                    onClick={this.goToSellerDashboard}
                                                >
                                                    Continue
                                                </Button>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                            </ParentGrid>
                        </Box>
                    </Box>
                </section>
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const styles = (theme: any) => ({
    sectionSellerSignUp: {
        width: '100%',
        height: '100%',
        color: '#ffffff !important',
    },
    SignupBgWrapper: {
        width: '100%',
        minHeight: '100vh',
        backgroundColor: '#000000',
        position: 'relative' as const,
        '& .overlayImg': {
            position: 'absolute',
            width: '100%',
            bottom: '0',
            left: '0',
            '& img': {
                objectFit: 'cover',
                width: '100%',
            },
        },
    },
    formWrapper: {
        padding: '100px',
        display: 'flex',
        minHeight: '100vh',
        [theme.breakpoints.down('sm')]: {
            padding: '20px',
        },
        '& .leftColumn': {
            '& h6': {
                fontFamily: 'Poppins-Medium',
                color: '#ffffff',
                fontSize: '20px',
                lineHeight: '30px',
            },
            '& img': {
                maxWidth: '156px',
                lineHeight: '62px',
            },
            [theme.breakpoints.down('sm')]: {
                marginTop: '0%',
            },
        },
        '& .rightColumn': {
            border: "1px solid rgba(255, 255, 255, 0.5)",
            maxWidth: 660,
            padding: '50px 52px 60px 52px',
            borderRadius: '20px',
            [theme.breakpoints.down('xs')]: {
                padding: '10px',
            },
            [theme.breakpoints.down('sm')]: {
                margin: 'auto',
                padding: '20px',
                marginTop: '50px',
                marginBottom: '150px',
            },
            '& .rightColumnInner': {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                gridGap: '40px',
                alignItems: 'center',
                textAlign: 'center',
                '& .formHeading': {
                    '& p': {
                        fontSize: '18px',
                        fontFamily: 'Poppins-Regular',
                        opacity: '0.5',
                        lineHeight: '27px',
                    },
                },
                '& h6, & p': {
                    color: 'white',
                },
                '& h6': {
                    fontSize: '32px',
                    lineHieght: '48px',
                    fontFamily: 'Poppins-SemiBold',
                },
                '& .signupFormWrapper': {
                    display: 'flex',
                    flexDirection: 'column',
                    gridGap: '30px',
                    width: '100%',
                    '& .MuiTextField-root': {
                        width: '100%',
                    },
                },
            },
        },
    },
    submitBtn: {
        width: "100%",
        '& .MuiButton-contained': {
            width: '100%',
            backgroundColor: '#F5EA16',
            height: '56px',
            borderRadius: '24px',
            fontSize: '16px',
            fontFamily: 'Poppins-Medium',
            lineHeight: '24px',
            textTransform: 'capitalize',
            color: '#444444',
        },
    },

});
const ParentGrid = styled(Grid)({
    '@media(max-width: 900px)': {
        width: '100%'
    }
});

export default withStyles(styles)(SubmittedDetails);
export { SubmittedDetails }
// Customizable Area End
