import React, { Component } from 'react';
import {
    Box,
    styled,
    Typography,
    Avatar,
    Button,
    Grid
} from "@material-ui/core";
import { commentIcon, heartIcon, shareBtn, userAdd } from './assets';
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel } from 'react-responsive-carousel';
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { crossIcon } from '../../blocks/LiveStreaming/src/assets';
import ReactPlayer from 'react-player';
import { Instagram } from "react-content-loader";
import { getStorageData } from '../../framework/src/Utilities';

const MyInstagramLoader = () => <Instagram />;
interface ImageOrVideo {
    id: number;
    filename: string;
    url: string;
    type: string;
}

interface CreatorDetails {
    id: number;
    full_name: string;
    profile_url: string | null;
}

interface TagDetail {
    profile_photo: string ;
    id: number;
    name: string;
    user_name: string;
    profile_url: string;
  }
    
interface ProductImage {
    id: number;
    url: string;
}

interface ProductVariant {
    id: number;
    catalogue_id: number;
    catalogue_variant_color_id: number;
    catalogue_variant_size_id: number;
    price: string;
    stock_qty: number;
    on_sale: boolean;
    sale_price: string;
    discount_price: string | null;
    length: number | null;
    breadth: number | null;
    height: number | null;
    created_at: string;
    updated_at: string;
    block_qty: number | null;
}

interface Product {
    id: number;
    name: string;
    description: string;
    price: number;
    product_images: ProductImage[];
    variants: ProductVariant[];
}

interface LastLikedBy {
    id: number;
    user_name: string;
    profile_url: string;
};
interface PostAttributes {
    product_detail?:  Product[]
    tag_list_details: TagDetail[];
    id: number;
    name: string | null;
    description: string | null;
    body: string;
    location: string | null;
    account_id: number;
    active_message: boolean;
    created_at: string;
    updated_at: string;
    images_and_videos: ImageOrVideo[];
    creator_details: CreatorDetails;
    like_count: number;
    is_liked: boolean;
    comment_count: number;
    is_following_seller: boolean;
    last_liked_by?: LastLikedBy
}

interface PostDataPayload {
    id: string;
    type: string;
    isTagList: boolean;
    attributes: PostAttributes;
}

interface PostItemProps {
    item: PostDataPayload;
    postId: string;
    postKeyId: number;
    handleToggleLikePost: (postId: string) => void;
    handleToggleComment: (postId: string,commentbox:string) => void;
    clickFollowButton?: (creatorId: number,postId: string) => void;
    clickTageListButton?: (postId: string) => void;
    closeTagUserList?: (postId: string) => void;
    varientProductClick?: (productId: number) => void;
}

interface State {
    currentProductIndex: number;
    loader: boolean;
    role: string;
}

class PostCardRow extends Component<PostItemProps, State> {
    constructor(props: PostItemProps) {
        super(props);

        this.state = {
            currentProductIndex: 0,
            loader: true,
            role: ""
        };
    }


    async componentDidMount() {
        let role = await getStorageData("role")
        this.setState({ role: role })
          setTimeout(() => {
            this.setState({
                loader: false
            })
          }, 2000); 
    }

    renderTagUserList() {
        const { postId, item, closeTagUserList = () => { } } = this.props;
    
        const { tag_list_details = [] } = item.attributes;
    
        return (
            <div className="tag-box">
                <Box className="heade-txt">
                    <Typography style={{ fontWeight: 800 }}>Tagged Users</Typography>
                    <img
                        style={{ cursor: "pointer" }}
                        src={crossIcon}
                        alt="Close"
                        onClick={() => closeTagUserList(postId)}
                    />
                </Box>
                {tag_list_details.length === 0 ? (
                    <Typography style={{ padding: "16px", textAlign: "center" }}>
                        No tagged users
                    </Typography>
                ) : (
                    tag_list_details.map((suggestion) => (
                        <div key={suggestion.id} className="tag-item">
                            <Avatar
                                alt="User Avatar"
                                className="avatar"
                                src={suggestion.profile_url}
                            />
                            <div className="nameAdjustment">
                                <Typography>{suggestion.user_name}</Typography>
                                <Typography>{suggestion.name}</Typography>
                            </div>
                        </div>
                    ))
                )}
            </div>
        );
    }    

    render() {
        const { item, handleToggleLikePost, postId, handleToggleComment, clickFollowButton = ()=>{}, 
        clickTageListButton = ()=>{}, varientProductClick = ()=>{}} = this.props;

        return (
            <PostCardBox>
                {this.state.loader && [1, 1, 1, 1].map((_, index) => (
                    <Instagram />
                )) }
                <Box className="postCardBox" >
                    {item.isTagList && this.renderTagUserList()}
                    <Box className="postCardHeaderBox">
                        <Box className="headerLeftBox">
                            <Box className="avatarBox">
                                <Avatar src={item.attributes.creator_details.profile_url || ''} alt={item.attributes.creator_details.full_name} />
                                {console.log(item.attributes,'check creator details')}
                                
                            </Box>
                            <Box className="userInfoBox">
                                <Typography className="userNameTxt">{item.attributes.creator_details.full_name}<span className="userTypeTxt">Seller</span></Typography>
                                <Typography className="postedTimeTxt">{item.attributes.created_at}</Typography>
                            </Box>
                        </Box>
                        <Box className="headerRightBox">
                            {item.attributes.is_following_seller ?
                                <Box className="followBox">
                                    <Button onClick={() => clickFollowButton(item.attributes.creator_details.id, postId)} className="followUnfollowBtn">Following</Button>
                                </Box> :
                                <div className='count-txt'> 
                                    {this.state.role === "seller" ? "" : 
                                    <img style={{cursor: "pointer"}} src={userAdd} onClick={() => clickFollowButton(item.attributes.creator_details.id, postId)} />
                                    }
                                </div>
                                }
                        </Box>
                    </Box>

                    <Box>
                        <Box className="postImageBox">
                            {item?.attributes?.images_and_videos?.length === 1 ? (
                               item.attributes.images_and_videos[0].type === 'video' ? (
                                <ReactPlayer
                                  style={{height: "unset !important"}}
                                  controls
                                  className="postImage1"
                                  url={item.attributes.images_and_videos[0].url}
                                />
                              ) : (
                                <img
                                  src={item.attributes.images_and_videos[0].url}
                                  alt="Post"
                                  className="postImage"
                                />
                              )
                            ) : (
                                <Carousel
                                    showThumbs={false}
                                    swipeable={true}
                                    renderArrowNext={(onClickHandler, hasNext, label) =>
                                        hasNext && (
                                            <button
                                                type="button"
                                                onClick={onClickHandler}
                                                title={label}
                                                className='nextArrowBtn'
                                            >
                                                <ArrowForwardIos className="postSlideBtn"/>
                                            </button>
                                        )
                                    }
                                    renderArrowPrev={(onClickHandler, hasPrev, label) =>
                                        hasPrev && (
                                            <button
                                                type="button"
                                                onClick={onClickHandler}
                                                title={label}
                                                className="prevArrowBtn"
                                            >
                                                <ArrowBackIos className="postSlideBtn"/>
                                            </button>
                                        )
                                    }
                                >
                                        {item?.attributes?.images_and_videos.map(
                                            (media: { url: string; type: string }, index: number) => (
                                                <Box key={index} className="imageContainer">
                                                    {media.type === 'video' ? (
                                                        <ReactPlayer
                                                            style={{height: "unset !important"}}
                                                            url={media.url}
                                                            controls
                                                            className="postImage1"
                                                        />
                                                    ) : (
                                                        <img
                                                            src={media.url}
                                                            alt={`Media ${index + 1}`}
                                                            className="postImage"
                                                        />
                                                    )}
                                                </Box>
                                            )
                                        )}
                                </Carousel>
                            )}
                        </Box>

                        <Box className="postBottomBox">
                            <Box className="likeCommentBox">
                               <Box className="likeCmtInnerBox">{item.attributes.is_liked ? <FavoriteIcon className="liked" onClick={() => handleToggleLikePost(postId)}/> : <img src={heartIcon} className={`heartIcon`} onClick={() => handleToggleLikePost(postId)} /> }<span>{item.attributes.like_count > 0 && item.attributes.like_count}</span></Box>
                                <Box className="likeCmtInnerBox" onClick={() => handleToggleComment(postId,"commentbox")}>
                                    <img src={commentIcon} className="commentIcon" /><span>{item.attributes.comment_count > 0 && item.attributes.comment_count}</span>
                                </Box>
                            </Box>
                            <Box className="likedByDetailBox">
                                {item.attributes.like_count > 0 && (
                                    <>
                                        <Avatar
                                            src={item.attributes.last_liked_by?.profile_url}
                                            alt="Person"
                                            className="likedByImg"
                                        />
                                        <Typography className="likedInfoTxt">
                                            Liked by &nbsp;
                                            <span className="boldText">{item.attributes.last_liked_by?.user_name}</span>
                                            {item.attributes.like_count > 1 ? (
                                                <> and <span className="boldText">{item.attributes.like_count - 1} {item.attributes.like_count - 1 === 1 ? "other": "others" }</span></>
                                            ) : (
                                                <span className="boldText"></span>
                                            )}
                                        </Typography>
                                    </>
                                )}
                            </Box>

                            <Box className="descriptionBox">
                                <Typography className="descriptionTxt">
                                    <span className="boldText">{item.attributes.creator_details.full_name}</span> {item.attributes.body}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </PostCardBox>
        );
    }
}

// Styles for the custom navigation buttons
const PostCardBox = styled(Box)({
    "& .tag-box::-webkit-scrollbar": {
        width: "6px"
    },
    "& .tag-box::-webkit-scrollbar-thumb": {
        backgroundColor: "rgba(0, 0, 0, 0.2)",
        borderRadius: "3px"
    },
    "& .tag-box::-webkit-scrollbar-track": {
        background: "rgba(0, 0, 0, 0.1)"
    },
    "& .lestthen3": {
        display: "flex"
    },
    "& .varientImagehead": {
        display: "flex",
        gap: "8px",
        borderRadius: "8px !important",
        border : "1px solid #444444",
        margin: "5px",
        padding: "5px",
        width: "149px",
        cursor: "pointer"
    },
    "& .varientImage": {
        height: "60px",
        width: "60px",
        borderRadius: "8px !important"
    },
    "& .varientImageside": {
        display: "flex",
        flexDirection: "column",
        textAlign: "left",
        gap: "3px"
    },
    "& .postMainContainer": {
        height: "532px",
        overflowY: "auto",
        scrollBehavior: "smooth",
    },
    "& .postContainer": {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
    },
    "& .postHeaderBox": {
        width: "100%",
        height: "100px",
        "@media(max-width:1220px)": {
            padding: "10px",
        },
    },
    "& .tag-item": {
        padding: "14px 0",
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        gap: "8px"
    },
    "& .tag-item:hover": {
        backgroundColor: "#f0f0f0",
    },
    "& .count-txt": {
        display:"flex",
        alignItems: "center",
        gap : "4px"
    },
    "& .heade-txt": {
        display:"flex",
        alignItems: "center",
        justifyContent:"space-between"
    },
    "& .nameAdjustment": {
        display:"flex",
        flexDirection: "column"
    },
    "& .tag-box": {
        position: "absolute",
        background: "white",
        zIndex: 999,
        left: "30%",
        bottom: "30%",
        padding: "24px",
        width: "268px",
        height: "278px",
        borderRadius: "15px",
        overflowY:"scroll"
    },
    "& .postCardBox": {
        position:"relative",
        maxWidth: "440px",
        width: "100%",
        marginBottom: "46px",
        "@media(max-width:1220px)": {
            padding: "10px",
        },
        minWidth:"440px",
        "@media(max-width:1200px)": {
            minWidth:"370px",
        },
        "@media(max-width:410px)":{
            width:"100%",
            minWidth:"0px"
        }
    },
    "& .postCardHeaderBox": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    "& .headerLeftBox": {
        display: "flex",
        gap: "20px",
        justifyContent: "center",
        alignItems: "center"
    },
    "& .userNameTxt": {
        fontSize: "14px",
        fontWeight: 600,
        color: "#444444"
    },
    "& .postedTimeTxt": {
        color: "#444444B2",
        fontSize: "12px",
        fontWeight: 400,
    },
    "& .postImageBox": {
        marginTop: "16px",
        maxWidth: "425px",
        width: "100%",
        maxHeight: "508px",
        borderRadius: "10px",
    },
    "& .imageContainer":{
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        height:"100%",
        minHeight: "508px"
    },
    "& .postImage": {
        width: "100%",
        maxHeight: "508px",
        maxWidth: "510px",
        objectFit: "cover",
        borderRadius: "10px",
        height: "100%"
    },
    "& .postImage1": {
        width: "100% !important",
        maxHeight: "508px",
        borderRadius: "10px",
        height: "100% !important"
    },
    "& .postBottomBox": {
        marginTop: "12px",
        wordBreak:"break-all"
    },
    "& .likeCommentBox":{
        display:"flex",
        alignItems: "center"
    },
    "& .likeCmtInnerBox":{
        display:"flex",
        gap:"8px",
        alignItems: "center"
    },
    "& .liked":{
        color:"red",
    },
    "& .commentIcon":{
        marginLeft:"14px",
        height:"24px",
        width: "24px"
    },
    "& .shareIcon":{
        marginLeft:"14px",
        height:"20px",
        width: "20px",
        marginTop:"1px"
    },
    "& .likedByImg": {
        width: "17px",
        height: "17px",
        marginRight: "16px"
    },
    "& .likedByDetailBox": {
        margin: "8px 0px",
        display: "flex",
        alignItems: "center"
    },
    "& .boldText": {
        fontSize: "14px",
        fontWeight: 800,
        color: "#444444"
    },
    "& .likedInfoTxt": {
        fontSize: "14px",
        fontWeight: 400,
        color: "#444444"
    },
    "& .descriptionTxt": {
        fontSize: "14px",
        fontWeight: 100,
        color: "#444444"
    },
    "& .userTypeTxt": {
        marginLeft: "10px",
        fontWeight: 500,
        fontSize: "12px",
        color: "#444444CC"
    },
    "& .nextArrowBtn":{
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        background: 'rgba(0, 0, 0, 0.5)',
        borderRadius: '50%',
        color: '#fff',
        border: 'none',
        cursor: 'pointer',
        zIndex: 2,
        padding: '4px',
        right:"15px"
    },
    "& .prevArrowBtn":{
        top: '50%',
        transform: 'translateY(-50%)',
        position: 'absolute',
        borderRadius: '50%',
        background: 'rgba(0, 0, 0, 0.5)',
        border: 'none',
        color: '#fff',
        zIndex: 2,
        cursor: 'pointer',
        left:"15px",
        padding: '4px',
    },
    "& .postSlideBtn":{
        width:"20px",
        height:"20px"
    },
    "& .followUnfollowBtn": {
        textTransform: "none",
        border: "1px solid #F5EA16",
        borderRadius: "50px",
        padding: "6px 16px"
    },
})

export default PostCardRow;
