export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const addProductImage = require("../assets/addProductImage.png");
export const bellIcon = require("../assets/bellIcon.png");
export const calendarIcon = require("../assets/calendarIcon.png");
export const plusIcon = require("../assets/plusIcon.png");
export const uploadIcon = require("../assets/uploadIcon.png");
export const exclamatoryIcon = require("../assets/exclamatoryIcon.png");
export const removeIcon = require("../assets/removeIcon.png");
export const imageAddButton = require("../assets/imageAddButton.png");
export const videoAddButton = require("../assets/videoAddButton.png");
export const backButton = require("../assets/backButton.png");
export const gear = require("../assets/exclamatoryIcon.png");
export const productListingImage = require("../assets/productListing.png");
export const selectAllButton = require("../assets/selectAllButton.png");
export const deleteIcon = require("../assets/deleteIcon.png");
export const checkBoxIcon =  require("../assets/checkBoxIcon.png");
export const editIcon = require("../assets/editIcon.png");
export const successVector = require("../assets/successVector.png");
export const editProductIcon = require("../assets/editProductIcon.png");
export const addVariantIcon = require("../assets/addVariantIcon.png");
export const searchIcon = require("../assets/searchIcon.png");
export const hamburgerIc = require("../assets/hamburger.svg")
export const Additems = require("../assets/Additems.svg");
export const Upload = require("../assets/Upload.svg");
export const Correct = require("../assets/Correct.svg");
export const cancel = require("../assets/cancel.svg");
export const filters = require("../assets/filters.png");
