import React from "react";
import {
    // Customizable Area Start
    Container,
    Button,
    Box,
    AppBar,
    Toolbar,
    Typography
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import {
    faceBook,
    instagram,
    twitter,
} from "./assets";

export interface Props {
    classes: any;
}
// Customizable Area End

class AppFooter extends React.Component<Props> {
    constructor(props: Readonly<Props>) {
        super(props);

    }
    render() {
        // Customizable Area Start
        const { classes } = this.props
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <section className={classes.sectionFooter}>
                    <AppBar position="static" className={classes.footerBar}>
                        <Container maxWidth="xl" className={classes.footerContainer}>
                            <Toolbar className={classes.footerToolbar}>
                                <Box className={classes.footerBlackBox}>
                                    <Box >
                                        <Box className={classes.navigationlLinks}>
                                            <Typography onClick={() => window.location.href = "/About"} className={classes.footerNames}>
                                                {'About'}
                                            </Typography>
                                            <Typography onClick={() => window.location.href = "/Privacy"} className={classes.footerNames} >
                                                {'Privacy'}
                                            </Typography>
                                            <Typography onClick={() => window.location.href = "/FAQ"} className={classes.footerNames}>
                                                {'FAQs'}
                                            </Typography>
                                            <Typography onClick={() => window.location.href = "/Terms"} className={classes.footerNames} >
                                                {'Terms'}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box className={classes.footerCopywrite}>
                                        <p >
                                            {new Date().getFullYear()} © Desire. All Rights Reserved.
                                        </p>
                                    </Box>
                                </Box>
                            </Toolbar>
                        </Container>
                    </AppBar>
                </section>
            </>
            // Customizable Area End
        )
    }
}

// Customizable Area Start
const styles = (theme: any) => ({
    sectionFooter: {
        [theme.breakpoints.down('sm')]: {
            marginTop: '100px',
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: '20px',
        },
    },
    footerBar: {
        height: '100px',
        backgroundColor: "#000000",
    },
    footerContainer: {
        margin: 'auto',
    },
    footerTopBar: {
        backgroundColor: '#fbf4b2',
    },
    footerTopBox: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '40px 10px',
    },
    footerNavigationLink: {
        marginRight: '56px',
        '& a': {
            fontFamily: 'Poppins-Medium',
            fontSize: '16px',
            lineHeight: '24px',
            textTransform: 'initial',
        },
        '& .MuiButton-label': {
            alignItems: 'start',
            justifyContent: 'start',
        }
    },
    footerBlackBox: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            gridGap: '40px',
            '& p': {
                padding: '0px',
                margin: '0px',
            },
        },
    },
    navigationlLinks: {
        display: 'flex',
        gridGap: '40px',
        [theme.breakpoints.down('md')]: {
            gridGap: '25px',
        },
        '& a': {
            fontFamily: 'Poppins-Regular',
            fontSize: '16px',
            lineHeight: '24px',
            backgroundColor: 'transparent',
            textTransform: 'capitalize',
            color: "#ffffff",
            '&:hover': {
                backgroundColor: 'transparent',
                color: "#ffffff",
            }
        }
    },
    footerCopywrite: {
        '& p': {
            fontFamily: 'Poppins-Regular',
            fontSize: '16px',
            lineHeight: '24px',
            marginRight: '150px',
            [theme.breakpoints.down('md')]: {
                marginRight: '0px',
            },
        },
    },
    footerToolbar:{
        [theme.breakpoints.down('xs')]: {
            padding:"5px 5px",
        },
    },
    footerNames:{
        padding: '6px 16px', 
        cursor: 'pointer',
        transition: 'opacity 0.3s',
        '&:hover': {
          opacity: 0.5,
        },
    }
})
export default withStyles(styles)(AppFooter);
// Customizable Area End