Object.defineProperty(exports, '__esModule', {
  value: true
})

// Customizable Area Start
exports.apiContentType = 'application/json'
exports.getApiMethod = 'GET'
exports.postApiMethod = 'POST'
exports.deleteApiMethod = 'DELETE'
exports.putApiMethod = 'PUT'


exports.getOrdersApiEndPoint = 'shopping_cart/orders'
exports.createOrderItemApiEndPoint = 'shopping_cart/order_items'
exports.deleteOrderItemApiEndPoint = 'shopping_cart/orders'
exports.favouriteItemEndPoint = "bx_block_favourite/favourites"

exports.getCartDataEndPoint = "bx_block_order_management/cart_items";
exports.putCartUpdateEndPoint = "bx_block_order_management/update_cart_item";
exports.deleteCartItemEndPoint = "bx_block_order_management/remove_item_from_cart";
exports.deleteStoreEndPoint = "bx_block_address/store_addresses/";
exports.clearCartEndPoint = "bx_block_order_management/clear_cart";
exports.getSingleCartEndPoint = "bx_block_order_management/show_seller_cart?id=";
exports.getOrderStatusEndPoint = "bx_block_order_management/place_order_in_cart"
exports.getOrderPaymentInitiateEndPoint= "bx_block_order_management/initiate_payment";

exports.porterAddressAddEndPoint = "bx_block_order_management/orders/13/create_porter_order";

exports.errorTitle = 'Error'
exports.errorAllFieldsAreMandatory = 'All fields are mandatory.'
exports.OrderSuccessText = "Order Successful";
exports.orderPlacedSuccesfully = "Order Created Succesfully !!";
exports.showOrderTxt = "Show order history";
exports.goToHomeText = "Go To home page";
exports.errorTxt = "Error";
exports.somethingWrongTxt = "Something went wrong";
exports.orderNotPlaced = "Order is not placed successfully.";
exports.pleaseTryAgain = "please try again";
exports.tryAgain = "Try Again";
// Customizable Area End
