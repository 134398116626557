import React from "react";
// Customizable Area Start
import BuyerQrCodePaymentController, { Props, configJSON } from "./BuyerQrCodePaymentController.web";
import { createTheme, withStyles, Theme } from "@material-ui/core/styles";
import {  Box ,Typography,Grid,Divider,Button,Dialog,DialogContent} from "@material-ui/core";
import {qrLogo,tickIcon} from "./assets"
import { QRCode } from 'react-qrcode-logo';

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
});

export const webStyles = (theme: Theme) => ({
    BuyerQrCodePaymentMaincontainer:{
     display:"flex",
     justifyContent:"center",
     alignItems:"center",
     height:"100vh"
    },
    verticalDivider: {
        display: "block", 
        [theme.breakpoints.down('md')]: {
            display: "none" 
        }
    },
    horizontalDivider: {
        display: "none", 
        [theme.breakpoints.down('md')]: {
            display: "block" 
        }
    },
    leftSideContainer:{
        display:"flex",
        flexDirection:"column" as const,
        justifyContent:"center",
        alignItems:"center"
    },
    rightSideContainer:{
        display:"flex",
        flexDirection:"column" as const,
        justifyContent:"center",
        alignItems:"center",
        padding:"0em 2em"
    },
    qrTitle:{
        color: '#555',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: 'normal',
        marginBottom:"3em"
    },
    receiptTxt:{
        color: '#555',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
    },
    shareReceiptBtn:{
        width:"15em",
        height: '3em',
        background: '#F5EA16',
        borderRadius:"15px",
        color: '#444',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
        pointer:"cursor",
        textTransform:"none" as const,
        margin:"2em 0em",
        "&:hover":{
            background: '#F5EA16',
        }
    },
    confirmOrderTxt:{
        color: '#555',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
    },
    confirmOrderBtn:{
        width:"15em",
        height: '3em',
        background: '#F5EA16',
        borderRadius:"15px",
        color: '#444',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
        pointer:"cursor",
        textTransform:"none" as const,
        margin:"2em 0em",
        "&:hover":{
            background: '#F5EA16',
        }
    },

    dialogOuter: {
        "& .MuiBackdrop-root": {
            background: '#0000004D',
            backdropFilter: "blur(2px)"
        }
    },
    dialogInner: {
        display: 'flex',
        flexDirection: 'column' as const,
        gap: '16px',
        padding: '32px',
        '@media(max-width: 697px)': {
            padding: '16px',
        },
        height: '260px',
    },
    dialogTitleBox: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
    },
    dialogTitle:{
        fontSize: '18px',
        fontWeight: 500 
    },
    descriptionContainer:{ 
        display: 'flex', 
        flexDirection: 'column' as const,
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '100%'
    },
    imageIconBox: {
        background: '#000000',
        borderRadius: '50%',
        width: '42px',
        height: '42px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '8px',
    },
    tickIcon:{
        height: '24px',
        width: '24px'
    },
    orderPlacedSucessfully:{
       fontSize: '16px',
       maxWidth: '240px', 
       textAlign: 'center' as const,
       fontWeight: 600 
    },
    showOrderHistory:{
        fontSize: '16px',
        maxWidth: '228px',
        textAlign: 'center' as const,
        color: '#0073E4',
        cursor: 'pointer'
    },
    notifyButton: {
        width: '100%',
        borderRadius: '50px',
        background: '#F5EA16',
        fontSize: '16px',
        fontWeight: 700,
        textTransform: 'none' as const,
        padding: '1.5px 8px',
        '&:hover': {
            backgroundColor: '#F5EA16'
        },
        maxWidth: '256px',
        height: '46px',  
    },


});

// Customizable Area End

export class BuyerQrCodePayment extends BuyerQrCodePaymentController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    // Customizable Area End
    render() {
        // Customizable Area Start
        const {classes} = this.props
        const upiId = this.state.cartDetails?.seller_details?.attributes?.upi_id
        const phoneNumber = this.state.cartDetails?.seller_details?.attributes?.phone_number
        const totalAmount = this.state.cartDetails?.total
        const qrCodeUrl = upiId ? `upi://pay?pa=${upiId}&am=${totalAmount}&cu=INR` : ''
        return (
            <Box data-test-id="mainContainer">
                <Grid container className={classes.BuyerQrCodePaymentMaincontainer}>
                    {
                        qrCodeUrl ? (
                            <>
                                <Grid item xs={12} md={4}>
                                    <Box className={classes.leftSideContainer}>
                                        <Typography className={classes.qrTitle}>{configJSON.qrTitle}</Typography>
                                        <QRCode value={qrCodeUrl} logoImage={qrLogo} size={300} />

                                    </Box>
                                </Grid>
                                <Divider orientation="vertical" className={classes.verticalDivider} />
                                <Divider orientation="horizontal" className={classes.horizontalDivider} />
                                <Grid item xs={12} md={4}>
                                    <Box className={classes.rightSideContainer}>
                                        <Typography className={classes.receiptTxt}>{configJSON.receiptTxt}</Typography>
                                        <Button data-test-id="receiptButton" className={classes.shareReceiptBtn} onClick={this.handleShareReceipt}>{configJSON.shareReceipt}</Button>
                                        <Typography className={classes.confirmOrderTxt}>{configJSON.confirmOrderTxt}</Typography>
                                        <Button data-test-id="confirmButton" className={classes.confirmOrderBtn} onClick={this.handleConfirmOrder}>{configJSON.confirmOrder}</Button>
                                    </Box>
                                </Grid>
                            </>
                        ) : (
                            !this.state.loading && (<Typography data-test-id="no-qr-code" className={classes.qrTitle}>Could not able to generate the QR code</Typography>)
                        )
                    }
                </Grid>
                <Dialog
                    open={this.state.openPaymentSuccessModal}
                    PaperProps={{
                        style: {
                            borderRadius: 23,
                            maxWidth: '425px',
                            minHeight: '337px',
                            width: '100%'
                        },
                    }}
                    className={classes.dialogOuter}
                >
                    <DialogContent className={classes.dialogInner}>
                        <Box className={classes.dialogTitleBox}>
                            <Typography className={classes.dialogTitle} data-test-id="orderSuccessful">{configJSON.orderSuccessful}</Typography>
                        </Box>
                        <Divider />
                        <Box className={classes.descriptionContainer}>
                            <Box className={classes.imageIconBox}>
                                <img src={tickIcon} className={classes.tickIcon} />
                            </Box>
                            <Typography className={classes.orderPlacedSucessfully}>{configJSON.orderPlacedSuccessfully}</Typography>
                            <Typography className={classes.showOrderHistory} onClick={this.navigateToOrderList}>{configJSON.showOrderHistory}</Typography>
                            <Button className={classes.notifyButton} onClick={this.handleHomePageNavigation}>
                                {configJSON.goToHomePage}
                            </Button>
                        </Box>
                    </DialogContent>
                </Dialog>
            </Box>
        );
        // Customizable Area End
    }
}


// Customizable Area Start
export default withStyles(webStyles)(BuyerQrCodePayment)
// Customizable Area End