import React from "react";
// Customizable Area Start
import BuyerShoppingCartController, {
    OrderItems,
    Props
} from "./BuyerShoppingCartController.web";

import { withStyles, StyleRules,Theme,createStyles } from "@material-ui/core/styles";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Paper, Stepper, Step, StepLabel, Grid, Box, Typography, Button, styled, Divider, Dialog, DialogContent, Avatar, TextField, FormHelperText, RadioGroup, FormControlLabel, Radio, Snackbar,CircularProgress } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import CloseIcon from '@material-ui/icons/Close';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Loader from "../../../components/src/Loader.web";
const baseURL = require("../../../framework/src/config.js").baseURL
import { liveArchive, liveRadar, liveArchiveBlank, noDataFound } from "../../landingpage/src/assets";
import { exclamation, imgEdit, imgVector, master, paytm, rupay, visa } from "./assets";
import AddIcon from '@material-ui/icons/Add';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MessageModalWeb from "../../../components/src/MessageModal.web";
import StayTuned from "../../../components/src/StayTuned.web";
import LoginFailModalWeb from "../../../components/src/LoginFailModal.web";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
// Customizable Area End

const styles: StyleRules = {
    // Customizable Area Start
    root: {
        minHeight: "100vh",
        marginTop: '100px'
    },
    cartContainer: {
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center'
    },
    cartStepper: {
        maxWidth: '577px',
        width: '100%',
        '@media(max-width: 492px)': {
            padding: '24px 0px'
        },
        '& .MuiStepIcon-root': {
            color: '#ECECEC',
            height: '32px',
            width: '32px'
        },
        '& .MuiStepIcon-text': {
            display: 'none'
        },
        '& .MuiStepIcon-root.MuiStepIcon-active': {
            color: '#42C25F',
        },
        '& .MuiStepIcon-root.MuiStepIcon-completed': {
            display: 'none',
        },
        '& .MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
            fontSize: '14px',
            color: '#000000'
        },
        '& .MuiStepConnector-alternativeLabel': {
            top: '16px',
            left: 'calc(-50% + 16px)',
            right: 'calc(50% + 16px)',
        },
        '& .MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel': {
            background: '#42C25F',
            width: '32px',
            height: '32px',
            borderRadius: '50%',
        },
    },
    cartBox: {
        maxWidth: '1296px',
        width: '100%',
        border: 'solid #0000004D',
        borderWidth: '1px 0'
    },
    leftCartBox: {
        borderRight: '1px solid #0000004D',
        padding: '64px',
        display: 'flex',
        alignItems: 'start',
        flexDirection: 'column',
        gap: '16px',
        '@media(max-width: 956px)': {
            alignItems: 'center',
            padding: '32px'
        },
    },
    itemBox: {
        display: 'flex',
        justifyContent: 'space-between',
        maxWidth: '626px',
        width: '100%',
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        borderRadius: '6px',
        padding: '24px',
        minHeight: '190px',
        gap: '24px',
        '@media(max-width: 575px)': {
            flexWrap: 'wrap',
            justifyContent: 'center',
        },
    },
    itemRemoved: {
        transform: 'translateX(100%)',
        opacity: 0,
        color: 'red',
    },
    deletedText: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        fontSize: '24px',
        fontWeight: 'bold',
        color: 'red',
        zIndex: 1, 
    },
    quantityBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        '@media(max-width: 492px)': {
            flexWrap: 'wrap',
            gap: '12px',
            justifyContent: 'center'
        },
    },
    btnBox: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: "center",
        padding: '0px 2px',
        border: '1px solid #000000',
        borderRadius: '35px',
        maxWidth: '96px',
        width: '100%'
    },
    stockOutTxt: {
        background: '#FFEBEB',
        fontSize: '14px',
        color: '#FF5656',
        minHeight: '27px',
        width: '136px',
        margin: '8px -24px 0px 0px',
        borderRadius: '6px 0px 0px 6px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    removeBtn: {
        border: '1px solid #F5EA16',
        borderRadius: '50px',
        fontSize: '14px',
        fontWeight: 500,
        textTransform: 'none',
        padding: '1.5px 8px'
    },
    notifyBtn: {
        borderRadius: '50px',
        background: '#F5EA16',
        fontSize: '14px',
        fontWeight: 500,
        textTransform: 'none',
        padding: '1.5px 8px',
        '&:hover': {
            backgroundColor: '#F5EA16'
        },
    },
    checkoutBtn: {
        borderRadius: '50px',
        background: '#F5EA16',
        fontSize: '20px',
        textTransform: 'none',
        marginTop: '16px',
        '&:hover': {
            backgroundColor: '#F5EA16'
        },
    },
    rightCartBox: {
        display: 'flex',
        justifyContent: 'center',
        padding: '64px 0px'
    },
    totalBox: {
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
        maxWidth: '332px',
        width: '100%'
    },
    priceBox: {
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        alignItems: 'center'
    },
    priceText: {
        fontSize: '14px'
    },
    orderTotalText: {
        fontSize: '14px',
        fontWeight: 800
    },
    offerBtn: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '16px 24px',
        cursor: 'pointer',
        border: '1px solid #E6E6E6',
        borderRadius: '8px'
    },
    dialogBackground: {
        "& .MuiBackdrop-root": {
            background: '#0000004D',
            backdropFilter: "blur(2px)"
        }
    },
    offerDialog: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        gap: '16px',
        padding: '32px',
        '@media(max-width: 697px)': {
            padding: '16px',
        },
        height: '534px',
    },
    notifyDialog: {
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        padding: '32px',
        '@media(max-width: 697px)': {
            padding: '16px',
        },
        height: '260px',
    },
    offerTitleBox: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
    },
    offerBox: {
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        overflowX: 'hidden',
        "&::-webkit-scrollbar": {
            width: "6px",
        },
        "&::-webkit-scrollbar-track": {
            borderRadius: "10px",
            background: "#D9D9D955"
        },
        "&::-webkit-scrollbar-thumb": {
            background: "#D9D9D9",
            borderRadius: "10px",
        },
    },
    applyBtn: {
        borderRadius: '50px',
        border: '1px solid #000000',
        fontSize: '14px',
        fontWeight: 500,
        padding: '0px',
        textTransform: 'none',
        maxWidth: '130px',
        width: '100%'
    },
    offerTab: {
        display: 'flex',
        padding: '16px',
        width: '100%',
        borderRadius: '8px',
        justifyContent: 'space-between',
        border: '1px solid #E6E6E6'
    },
    offerLeftBox: {
        maxWidth: '170px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '16px'
    },
    discountBox: {
        background: '#FFDFEB',
        borderRadius: '50px',
        fontSize: '16px',
        fontWeight: 500,
        display: 'flex',
        justifyContent: 'center'
    },
    closeBtn: {
        cursor: 'pointer',
        position: 'absolute',
        right: '32px',
        '@media(max-width: 697px)': {
            right: '12px',
        },
    },
    vectorBox: {
        background: '#4BB543',
        borderRadius: '50%',
        width: '42px',
        height: '42px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '8px'
    },
    offerAplliedTxt: {
        background: '#E5FFD6',
        color: '#4B9C49',
        fontSize: '14px',
        maxWidth: '271px',
        borderRadius: '6px',
        padding: '3.5px 12px'
    },
    suggestionContainer: {
        '& .slick-slide': {
            height: "auto",
            maxWidth: '340px',
            width: '100%',
        },
        '& .slick-track': {
            display: 'flex !important',
            gap: '16px',
            alignItems: 'center',
            height: "384px",
            overflowX: "scroll"
        },
        '& .slick-next': {
            display: 'none !important'
        },
        '& .slick-prev': {
            display: 'none !important'
        }
    },
    noDataImage: {
        width: '100%',
        animation: 'fadeIn 2s',
        maxWidth: '300px',
        WebkitAnimation: 'fadeIn 2s',
        MozAnimation: 'fadeIn 2s',
        msAnimation: 'fadeIn 2s',
        OAnimation: 'fadeIn 2s',
    },
    suggestionBox: {
        maxWidth: '340px',
        position: "relative",
        display: 'flex !important',
        flexDirection: 'column',
        gap: '16px',
    },
    archiveBox: {
        position: "absolute",
        right: "24px",
        top: "24px",
        height: '31px',
        width: '32px',
        borderRadius: "31px",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: "#00000099",
    },
    profileBox: {
        display: 'flex',
        alignItems: 'center',
        gap: '24px'
    },
    profilePicture: {
        display: "flex",
        alignItems: "center",
        gap: '8px'
    },
    profileTxt: {
        WebkitLineClamp: 1,
        overflow: 'hidden',
        display: '-webkit-box',
        whiteSpace: 'normal',
        WebkitBoxOrient: 'vertical',
        textOverflow: 'ellipsis',
        fontSize: '14px'
    },
    liveCountBox: {
        display: "flex",
        alignItems: "center",
        justifyContent: 'space-around',
        position: "absolute",
        top: "24px",
        left: "24px",
        borderRadius: "15px",
        background: "#FF1F7140",
        padding: "4px 10px",
        height: '28px',
        width: '80px'
    },
    liveCount: {
        fontSize: "16px",
        fontWeight: 500,
        color: "#FFFFFF",
    },
    savedAddContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '36px',
        '@media(max-width: 956px)': {
            alignItems: 'center',
        },
    },
    addressContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        width: '100%',
        '@media(max-width: 956px)': {
            alignItems: 'center',
        },
    },
    codBtns: {
        gap: '24px',
        width: '100%',
        '@media(max-width: 956px)': {
            alignItems: 'center',
        },
    },
    addressBox: {
        maxWidth: '522px',
        width: '100%',
        border: '1px solid #E6E6E6',
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        padding: '28px'
    },
    paymentText:{
        fontSize:"14px"
    },
    imageBoxStyle:{
        display: 'flex', 
        gap: '8px', 
        flexWrap: 'wrap' 
    },
    radioBtnStyle:{
        color:"black",
        "& .PrivateRadioButtonIcon-root-199":{
            color:"black",
        },
        "& .MuiRadio-colorSecondary.Mui-checked":{
            color:"black",
        },
        "& .MuiRadio-colorSecondary":{
            color:"black",
        }
    },
    addressBtn: {
        maxWidth: '522px',
        height: '69px',
        width: '100%',
        border: '1px solid #E6E6E6',
        borderRadius: '8px',
        display: 'flex',
        alignItems: 'center',
        gap: '12px',
        padding: '0px 24px',
        cursor: 'pointer'
    },
    deliveryType: {
        maxWidth: '522px',
        width: '100%',
        border: '1px solid #E6E6E6',
        borderRadius: '8px',
        display: 'flex',
        alignItems: 'center',
        gap: '12px',
        padding: '0px 15px',
        cursor: 'pointer'
    },
    paymentBtn: {
        maxWidth: '522px',
        height: '69px',
        width: '100%',
        boxShadow: '0px 1px 6px 0px #00000026',
        borderRadius: '8px',
        display: 'flex',
        alignItems: 'center',
        gap: '12px',
        padding: '0px 24px',
        cursor: 'pointer'
    },
    addBox: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        gap: '16px',
        '@media(max-width: 956px)': {
            alignItems: 'center',
        },
    },
    addressFields: {
        maxWidth: '509px',
        width: '100%',
        height: '50px',
        '& .MuiOutlinedInput-input': {
            padding: '18.5px',
            fontSize: '14px'
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderRadius: '10px',
            border: '1px solid #E1E1E1'
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #E1E1E1'
        },
        '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
            padding: '9px !important'
        }
    },
    errorTxt: {
        color: 'red',
        fontSize: '14px',
        marginTop: '8px'
    },
    radioLabel: {
        '& .MuiTypography-body1': {
            fontSize: '14px',
            maxWidth: '240px'
        }
    },
    // Customizable Area End
}

export class BuyerShoppingCart extends BuyerShoppingCartController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    cartSteps = [
        { label: 'Cart', complete: true },
        { label: 'Information', complete: false },
        { label: 'Shipping', complete: false },
        { label: 'Payment', complete: false }
    ];

    cartData = [
        { name: 'Furniture', desc: 'Sofa set, Dining Chair, Recliner etc...', price: '₹ 999', inStock: false },
        { name: 'Electronics', desc: 'Electronics - Camera , Mobile, Headphone etc...', price: '₹ 699', inStock: true }
    ]

    offerData = [
        { name: 'Summer bonanza', desc: 'Get 30% off on all products in Ivan Morais’s Live Shop ', discount: '30% off', available: true },
        { name: 'Summer bonanza', desc: 'Get 30% off on all products in Ivan Morais’s Live Shop ', discount: '30% off', available: true },
        { name: 'Summer bonanza', desc: 'Get 30% off on all products in Ivan Morais’s Live Shop ', discount: '30% off', available: false },
    ]

    offerDialogBox = () => {
        const { classes } = this.props;
        const { openOfferDialog, appliedIndex } = this.state;
        return (
            <Dialog
                open={openOfferDialog}
                onClose={this.closeOfferDialogBox}
                PaperProps={{
                    style: {
                        borderRadius: 23,
                        maxWidth: '462px',
                        width: '100%'
                    },
                }}
                className={classes.dialogBackground}
            >
                <DialogContent className={classes.offerDialog}>
                    <Box className={classes.offerTitleBox}>
                        <Typography style={{ fontSize: '18px', fontWeight: 500 }}>Applicable offers</Typography>
                        <CloseIcon data-test-id="offer-dialog-close" onClick={this.closeOfferDialogBox} className={classes.closeBtn} />
                    </Box>
                    <Divider />
                    <Box className={classes.offerBox}>
                        {this.offerData.map((item: any, index: number) => (
                            <Box data-test-id={`offer-${index}`} className={classes.offerTab}>
                                <Box className={classes.offerLeftBox}>
                                    <Typography style={{ fontSize: '14px', fontWeight: 500 }}>{item.name}</Typography>
                                    <Typography style={{ fontSize: '12px', color: '#6E6E6E' }}>{item.desc}</Typography>
                                    {item.available ?
                                        <>
                                            {appliedIndex === index ?
                                                <Typography style={{ fontSize: '14px', fontWeight: 500, color: '#379A4C' }}>Applied !!</Typography> :
                                                <Button data-test-id={`applybtn-${index}`} className={classes.applyBtn} onClick={() => this.applyOffer(index)}>Apply Now</Button>}
                                        </>
                                        : <Typography style={{ fontSize: '12px', color: '#007AF2' }}>*Not available for this order</Typography>}
                                </Box>
                                <Box style={{ maxWidth: '73px', width: '100%' }}>
                                    <Box className={classes.discountBox}>{item.discount}</Box>
                                </Box>
                            </Box>
                        ))}
                    </Box>
                </DialogContent>
            </Dialog>
        )
    }

    notifyDialogBox = () => {
        const { classes } = this.props;
        const { openNotifyDialog } = this.state;
        return (
            <Dialog
                open={openNotifyDialog}
                onClose={this.closeNotifyDialogBox}
                PaperProps={{
                    style: {
                        borderRadius: 23,
                        maxWidth: '316px',
                        width: '100%'
                    },
                }}
                className={classes.dialogBackground}
            >
                <DialogContent className={classes.notifyDialog}>
                    <Box className={classes.offerTitleBox}>
                        <Typography style={{ fontSize: '18px', fontWeight: 500 }}>Request  successful</Typography>
                        <CloseIcon onClick={this.closeNotifyDialogBox} className={classes.closeBtn} />
                    </Box>
                    <Divider />
                    <Box className={classes.offerBox} style={{ gap: '32px', alignItems: 'center' }}>
                        <Box className={classes.vectorBox}>
                            <img src={imgVector} />
                        </Box>
                        <Typography style={{ fontSize: '14px', maxWidth: '215px', textAlign: 'center' }}>You will be notified  on your registered email when item will be restocked</Typography>
                    </Box>
                </DialogContent>
            </Dialog>
        )
    }

    addressTextFields = () => {
        const { classes } = this.props;
        const { errorMessage, email, countryList, country, stateList, cityList, mobile, pincode, flatNo, address, city, state, name, updateAddress } = this.state;
        return (
            <Box className={classes.addBox}>
                <Typography style={{ fontSize: '16px', fontWeight: 600 }}>Add Address</Typography>
                <Box style={{ maxWidth: '502px', width: '100%' }} >
                    <TextField
                        data-test-id="email-field"
                        placeholder="Email"
                        variant="outlined"
                        className={classes.addressFields}
                        onBlur={this.ValidateEmail}
                        onChange={this.handleEmailChange}
                        value={email}
                    />
                    <FormHelperText className={classes.errorTxt}>{errorMessage.email}</FormHelperText>
                </Box>
                <Box style={{ maxWidth: '502px', width: '100%' }} >
                    <Autocomplete
                        id="combo-box-demo"
                        data-test-id="countries-list-field"
                        options={countryList.map((item: any) => item.name)}
                        getOptionLabel={(option: any) => option}
                        style={{ width: '100%', maxWidth: '509px' }}
                        onBlur={this.ValidateCountry}
                        onChange={this.handleCountryChange}
                        open={this.state.toggleCountryDropdown}
                        onFocus={this.toggleCountryDropdownHandler}
                        onClose={this.closeCountryDropdownHandler}
                        value={country}
                        renderInput={(params: any) => (
                            <TextField
                                {...params}
                                placeholder="Country"
                                variant="outlined"
                                className={classes.addressFields}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <ExpandMoreIcon onClick={this.toggleCountryDropdownHandler} />
                                    ),
                                }}
                            />)}
                    />
                    <FormHelperText className={classes.errorTxt}>{errorMessage.country}</FormHelperText>
                </Box>
                <Box style={{ maxWidth: '502px', width: '100%' }} >
                    <TextField
                        placeholder="Mobile"
                        variant="outlined"
                        type="number"
                        className={classes.addressFields}
                        onBlur={this.ValidateMobile}
                        onChange={this.handleMobileChange}
                        value={mobile}
                    />
                    <FormHelperText className={classes.errorTxt}>{errorMessage.mobile}</FormHelperText>
                </Box>
                <Box style={{ maxWidth: '502px', width: '100%' }} >
                    <TextField
                        placeholder="Name"
                        variant="outlined"
                        className={classes.addressFields}
                        onBlur={this.ValidateName}
                        onChange={this.handleNameChange}
                        value={name}
                    />
                    <FormHelperText className={classes.errorTxt}>{errorMessage.name}</FormHelperText>
                </Box>
                <Box style={{ maxWidth: '502px', width: '100%' }} >
                    <TextField
                        placeholder="Address"
                        variant="outlined"
                        className={classes.addressFields}
                        onBlur={this.ValidateAddress}
                        onChange={this.handleAddressChange}
                        value={address}
                    />
                    <FormHelperText className={classes.errorTxt}>{errorMessage.address}</FormHelperText>
                </Box>
                <Box style={{ maxWidth: '502px', width: '100%' }} >
                    <TextField
                        placeholder="Building, Suit, etc."
                        variant="outlined"
                        className={classes.addressFields}
                        onBlur={this.ValidateFlatNo}
                        value={flatNo}
                        onChange={this.handleFlatNoChange}
                    />
                    <FormHelperText className={classes.errorTxt}>{errorMessage.flatNo}</FormHelperText>
                </Box>
                <Box style={{ maxWidth: '502px', width: '100%' }} >
                    <Autocomplete
                        id="combo-box-demo"
                        data-test-id="state-field"
                        options={stateList.map((item: any) => item.name)}
                        getOptionLabel={(option: any) => option}
                        style={{ width: '100%', maxWidth: '509px' }}
                        onBlur={this.ValidateState}
                        value={state}
                        disabled={stateList.length === 0}
                        onChange={this.handleStateChange}
                        open={this.state.toggleStateDropdown}
                        onFocus={this.toggleStateDropdownHandler}
                        onClose={this.closeStateDropdownHandler}
                        renderInput={(params: any) => (
                            <TextField
                                {...params}
                                placeholder="State"
                                variant="outlined"
                                className={classes.addressFields}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <ExpandMoreIcon onClick={this.toggleStateDropdownHandler} />
                                    ),
                                }}
                            />)}
                    />
                    <FormHelperText className={classes.errorTxt}>{errorMessage.state}</FormHelperText>
                </Box>
                <Box style={{ display: 'flex', justifyContent: 'space-between', maxWidth: '502px', width: '100%' }}>
                    <Box style={{ maxWidth: '242px', width: '100%' }} >
                        <Autocomplete
                            id="combo-box-demo"
                            data-test-id="city-field"
                            options={cityList}
                            getOptionLabel={(option: any) => option}
                            style={{ width: '100%' }}
                            onBlur={this.ValidateCity}
                            onChange={this.handleCityChange}
                            open={this.state.toggleCityDropdown}
                            onClose={this.closeCityDropdownHandler}
                            onFocus={this.toggleCityDropdownHandler}
                            value={city}
                            disabled={cityList.length === 0}
                            renderInput={(params: any) => (
                                <TextField
                                    {...params}
                                    placeholder="City"
                                    variant="outlined"
                                    className={classes.addressFields}
                                    style={{ maxWidth: '242px' }}
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <ExpandMoreIcon onClick={this.toggleCityDropdownHandler} />
                                        ),
                                    }}
                                />)}
                        />
                        <FormHelperText className={classes.errorTxt}>{errorMessage.city}</FormHelperText>
                    </Box>
                    <Box style={{ maxWidth: '242px', width: '100%' }} >
                        <TextField
                            data-test-id="pincode-field"
                            placeholder="PIN Code"
                            variant="outlined"
                            type="number"
                            style={{ maxWidth: '242px' }}
                            className={classes.addressFields}
                            onBlur={this.ValidatePincode}
                            value={pincode}
                            onChange={this.handlePincodeChange}
                        />
                        <FormHelperText className={classes.errorTxt}>{errorMessage.pincode}</FormHelperText>
                    </Box>
                </Box>
                <Box style={{ display: 'flex', justifyContent: 'space-between', maxWidth: '502px', width: '100%' }}>
                    <Button
                        className={classes.notifyBtn}
                        style={{ maxWidth: '242px', width: '100%', height: '50px' }}
                        onClick={this.saveNewAddress}
                    >
                        {updateAddress ? 'Update' : 'Save'}
                    </Button>
                    <Button
                        className={classes.removeBtn}
                        style={{ maxWidth: '242px', width: '100%', height: '50px' }}
                        onClick={this.cancelNewAddress}
                    >
                        Cancel
                    </Button>
                </Box>
            </Box>
        )
    }

    orderSuccessDialogBox = () => {
        const { classes } = this.props;
        const { orderSuccessDialog } = this.state;
        return (
            <Dialog
                data-test-id="order-success-dialog"
                open={orderSuccessDialog}
                PaperProps={{
                    style: {
                        borderRadius: 23,
                        maxWidth: '425px',
                        minHeight: '337px',
                        width: '100%'
                    },
                }}
                className={classes.dialogBackground}
            >
                <DialogContent className={classes.notifyDialog}>
                    <Box className={classes.offerTitleBox}>
                        <Typography style={{ fontSize: '18px', fontWeight: 500 }}>Order Successful</Typography>
                    </Box>
                    <Divider />
                    <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', height: '100%' }}>
                        <Box className={classes.vectorBox} style={{ height: '64px', width: '64px' }}>
                            <img src={imgVector} style={{ height: '23px', width: '33px' }} />
                        </Box>
                        <Typography data-test-id="order-status" style={{ fontSize: '16px', textAlign: 'center', fontWeight: 600 }}>{this.state.deliveryType==="1"?"Your OTP and order details are available in 'Show Order History'.":"Order placed Successfully !!"}</Typography>
                        <Typography style={{ fontSize: '16px', maxWidth: '228px', textAlign: 'center', color: '#0073E4', cursor: 'pointer' }} onClick={this.navigateToOrderList}>Show order history</Typography>
                        <Button
                            data-test-id="navigation-btn"
                            className={classes.notifyBtn}
                            style={{ maxWidth: '256px', height: '46px', fontWeight: 700, fontSize: '16px', width: '100%' }}
                            onClick={this.handleHomePageNavigation}
                        >
                            Go to  Home Page
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>
        )
    }

    orderFailDialogBox = () => {
        const { classes } = this.props;
        const { orderFailDialog } = this.state;
        return (
            <Dialog
                open={orderFailDialog}
                onClose={this.closeOrderFailDialogBox}
                PaperProps={{
                    style: {
                        borderRadius: 23,
                        maxWidth: '425px',
                        minHeight: '390px',
                        width: '100%'
                    },
                }}
                className={classes.dialogBackground}
            >
                <DialogContent className={classes.notifyDialog}>
                    <Box className={classes.offerTitleBox}>
                        <Typography style={{ fontSize: '18px', fontWeight: 500 }}>Error</Typography>
                        <CloseIcon onClick={this.closeOrderFailDialogBox} className={classes.closeBtn} />
                    </Box>
                    <Divider />
                    <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', height: '100%' }}>
                        <Box className={classes.vectorBox} style={{ height: '64px', width: '64px' }}>
                            <img src={exclamation} />
                        </Box>
                        <Typography style={{ fontSize: '16px', maxWidth: '228px', textAlign: 'center', fontWeight: 600 }}>Something went wrong</Typography>
                        <Typography style={{ fontSize: '16px', maxWidth: '181px', textAlign: 'center' }}>
                            Order is not placed successfully.
                            <b />
                            <span style={{ color: '#FF5454', display: 'block' }}>please try again</span>
                        </Typography>

                        <Button
                            className={classes.notifyBtn}
                            style={{ maxWidth: '256px', height: '46px', fontWeight: 700, fontSize: '16px', width: '100%' }}
                            onClick={this.placeOrder}
                        >
                            Try Again
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>
        )
    }

    sliderProps = {
        className: "slider variable-width",
        dots: false,
        infinite: true,
        centerMode: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
    };

    slidder() {
        const { classes, navigation } = this.props;
        const { suggestionData, wishListProduct } = this.state;
        return (
            <Slider {...this.sliderProps}>
                {suggestionData.map((item: any, index: number) => (
                    <Box
                        className={classes.suggestionBox}
                        key={index}
                        data-testid='suggestionBox'
                    >
                        {
                            this.checkProductStatus(wishListProduct, parseInt(item.id))
                                ? (
                                    <Box className={classes.archiveBox} data-test-id={`removeWishItem-${index}`}  onClick={() => this.deleteProductItem(wishListProduct, parseInt(item.id))}>
                                        <img src={liveArchive} style={{ height: '16px', width: '16px' }} />
                                    </Box>
                                ) : (
                                    <Box className={classes.archiveBox} data-test-id={`addWishItem-${index}`}  onClick={() => this.updateWishListItem(item.id, item.type)}>
                                        <img src={liveArchiveBlank} style={{ height: '16px', width: '16px' }} />
                                    </Box>
                                )
                        }
                        <img src={`${baseURL}${item.attributes.product_images[0].url}`} style={{ height: '256px', maxWidth: '340px', width: '100%', borderRadius: '16px', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', objectFit: 'cover' }} onClick={() => { navigation.push('ProductDescription', `?id=${encodeURIComponent(item.id)}`, encodeURIComponent(item.id)) }} />
                        <Box className={classes.profileBox}>
                            <Box className={classes.profilePicture}>
                                <Avatar src={item.attributes.host_image} style={{ height: '40px', width: '40px' }} />
                            </Box>
                            <Box>
                                <Typography className={classes.profileTxt} style={{ fontWeight: 500 }} >{item.attributes.host_name}</Typography>
                                <Typography className={classes.profileTxt} style={{ color: "#A5A4A4" }} >{item.attributes.description}</Typography>
                            </Box>
                        </Box>
                    </Box>
                ))}
            </Slider>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes, navigation } = this.props;
        const { loading, comingSoon, updateAddress, activeStep, cartData, messageBox, addressMessageBox, addressMessageTxt, messageTxt, addressAvailable, addNewAddress, selectedAddressID, shippingAddressData, message, messageModel, suggestionData,messageOpenQty,messageQty } = this.state;

        const orderItems = cartData?.order_items
        return (
            <Paper className={classes.root}>
                <Content>
                 <div id="myref"></div>
                {this.offerDialogBox()}
                {this.notifyDialogBox()}
                {this.orderSuccessDialogBox()}
                {this.orderFailDialogBox()}
                <StayTuned openTunedBox={comingSoon} closeTunedBox={this.closeStayTuned} />
                <Loader loading={loading} />
                {
                    !messageTxt && 
                    <>
                <Grid container className={classes.cartContainer}>
                    <Stepper data-test-id={`stepClickdCheckTestId`} activeStep={activeStep} alternativeLabel className={classes.cartStepper}>
                        {this.cartSteps.map((item: any, index: number) => (
                            <Step data-test-id={`stepClickTestId${index}`} onClick={() => this.stepClick(index)} key={item.label} disabled={!item.complete && index !== activeStep}>
                                <StepLabel>{item.label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <Grid container className={classes.cartBox} data-test-id="delete-item-icon">
                        {activeStep === 0 && 
                            <Grid item xs={12} md={7} className={classes.leftCartBox}>
                                {orderItems?.map((item: OrderItems, index: number) => (
                                    <>
                                        {this.state.removedItems[item.id] ? (
                                            <Box data-test-id={`remove-container-${item.id}`} key={item.id}
                                                style={{
                                                    background: '#E74C3C',
                                                    color: 'white',
                                                    padding: '10px 20px',
                                                    borderRadius: '6px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    maxWidth: '626px',
                                                    width: '100%',
                                                    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                                                    minHeight: '190px',
                                                }}
                                            >
                                                Removed
                                            </Box>
                                            
                                        )  : (<>
                                        <Box className={classes.itemBox} key={item.id}>
                                        <Box data-test-id={`order-item-image-container-${index}`} style={{ width: '100%', maxWidth: '142px', height: '142px', borderRadius: '4px' }}>
                                            {
                                                item.attributes.catalogue.attributes.product_images?.length && (
                                                    <img
                                                        src={`${baseURL}${item.attributes.catalogue.attributes.product_images[0].url}`}
                                                        onClick={()=>this.handleImageClick(item.attributes.catalogue_id)}
                                                        style={{ maxWidth: '142px', height: '100%', cursor: 'pointer', borderRadius: '4px', border: '1px solid #EEEAEA' }} />
                                                )
                                            }
                                        </Box>
                                        
                                        <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'end', justifyContent: 'space-between', width: '100%' }}>
                                            <Box style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                                                <Box style={{ maxWidth: '217px' }}>
                                                    <Typography className={classes.profileTxt} style={{ WebkitLineClamp: 2, fontSize: '18px', fontWeight: 500 }} data-test-id="titleId">{item.attributes.catalogue.attributes.title}</Typography>
                                                    <Typography className={classes.profileTxt} style={{ WebkitLineClamp: 3, color: '#6E6E6E', marginTop: '8px' }}>{item.attributes.catalogue.attributes.description}</Typography>
                                                    {item.attributes.catalogue.attributes.inventory_details.stock_quantity === 0 &&
                                                        <Box data-test-id="out-of-stock" className={classes.stockOutTxt}>*Item out of stock</Box>}
                                                </Box>
                                                <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
                                                    <Box sx={{ fontSize: '24px', fontWeight: 500, fontFamily: "Arial !important" }}>₹ {item.attributes.unit_price}</Box>
                                                </Box>
                                            </Box>
                                            <Box className={classes.quantityBox} >
                                                {item.attributes.catalogue.attributes.inventory_details.stock_quantity === 0 ?
                                                    <Box></Box> :
                                                    <Box className={classes.offerAplliedTxt} style={{ color: 'white', background: 'white' }}>Summer Bonanza 30% off applied !!</Box>}
                                                {item.attributes.catalogue.attributes.inventory_details.stock_quantity === 0 ?
                                                    <Box style={{ display: 'flex', gap: '26px' }} >
                                                        <Button className={classes.removeBtn} onClick={()=>this.deleteItemFromCart(item.attributes)}>Remove</Button>
                                                        <Button data-test-id="notify-me" className={classes.notifyBtn} onClick={this.openNotifyDialogBox}>Notify Me</Button>
                                                    </Box> :
                                                    <Box className={classes.btnBox}>
                                                        <RemoveCircleOutlineIcon  style={{ width: '20px', cursor: 'pointer' }} onClick={() => this.updateOrDeleteItem(item.attributes, false)} />
                                                        <Typography data-test-id="qtyTxtId">{item.attributes.quantity}</Typography>
                                                        <AddCircleOutlineIcon data-test-id="add-item-icon" style={{ width: '20px', cursor: 'pointer' }} onClick={() => this.updateQuantintyInCart(item.attributes, true)} />
                                                    </Box>}
                                            </Box>
                                        </Box>
                                        </Box>
                                        </>
                                        )}</>
                                ))}
                            </Grid>}
                        {activeStep === 1 &&
                            <Grid item xs={12} md={7} className={classes.leftCartBox}>
                                <Box className={classes.savedAddContainer} >
                                    <Box data-test-id="shipping-address-step" className={classes.addressContainer}>
                                        <Typography style={{ fontSize: '16px', fontWeight: 600 }} data-test-id="addressTitleId">{updateAddress ? "Edit your Delivery Address" : "Saved Address"}</Typography>
                                        {addressAvailable && !updateAddress && <RadioGroup
                                            value={selectedAddressID}
                                            onChange={this.handleAddressSelection}
                                            style={{ maxWidth: '522px', width: '100%', gap: '24px' }}
                                        >
                                            {shippingAddressData.map((data: any) => (
                                                <Box className={classes.addressBox} key={data.id}>
                                                    <Box style={{ display: 'flex', gap: '8px', justifyContent: 'space-between', alignItems: 'center' }}>
                                                        <FormControlLabel value={data.id} control={<Radio style={{ color: 'black' }} />} label={data.attributes.name} />
                                                        <Box style={{ display: 'flex', gap: '16px' }}>
                                                            <DeleteOutlineIcon onClick={() => this.deleteSavedAddress(data.id)} style={{ cursor: 'pointer', width: '24px',color:"red" }} />
                                                        </Box>
                                                    </Box>
                                                    <Box style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                                                        <Typography style={{ fontSize: '14px', fontWeight: 600, color: '#787878' }} >Email</Typography>
                                                        <Typography style={{ fontSize: '14px' }} data-test-id="shippingAddressEmailId">{data.attributes.email}</Typography>
                                                    </Box>
                                                    <Box style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                                                        <Typography style={{ fontSize: '14px', fontWeight: 600, color: '#787878' }} >Mobile no.</Typography>
                                                        <Typography style={{ fontSize: '14px' }} data-test-id="shippingAddressEmailId">{data.attributes.mobile}</Typography>
                                                    </Box>
                                                    <Box style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                                                        <Typography style={{ fontSize: '14px', fontWeight: 600, color: '#787878' }}>Shipping/Billing address</Typography>
                                                        <Typography style={{fontSize: '14px',maxWidth: '358px',overflow: 'hidden',textOverflow: 'ellipsis',whiteSpace: 'nowrap'}}>{data.attributes.address}</Typography>
                                                        <Typography style={{fontSize: '14px',maxWidth: '358px',overflow: 'hidden',textOverflow: 'ellipsis',whiteSpace: 'nowrap'}}>{data.attributes.flat_no}</Typography>
                                                        <Typography style={{fontSize: '14px',maxWidth: '358px',overflow: 'hidden',textOverflow: 'ellipsis'}}>{data.attributes.city}, {data.attributes.state}-{data.attributes.pin_code}</Typography>                                                          
                                                    </Box>
                                                    
                                                    <Button data-testid="edit-address-button" onClick={() => this.editShippingAddress(data)} variant="outlined" style={{ border: '1px solid #F5EA16', color: '#555555', padding: '8px 16px', borderRadius: '6px'}}>Edit Address</Button>
                                                </Box>))}
                                        </RadioGroup>}
                                    </Box>
                                    {addNewAddress ?
                                        this.addressTextFields() :
                                        <Box data-test-id="add-new-address-button" className={classes.addressBtn} onClick={this.handleAddNewAddress}>
                                            <AddIcon />
                                            <Typography style={{ fontSize: '16px', fontWeight: 500 }}>Add New Address</Typography>
                                        </Box>
                                    }
                                </Box>
                            </Grid>}
                        {activeStep === 2 &&
                            <Grid item xs={12} md={7} className={classes.leftCartBox}>
                                <Box style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '36px' }}>
                                <Typography style={{ fontSize: '16px', fontWeight: 600 }}>Delivery Type</Typography>
                                            <Box className={classes.deliveryType}>
                                                <RadioGroup style={{width:"100%"}} value={this.state.deliveryType} data-testid="delivery-type-radio-group" onChange={this.postDeliveryType} >
                                                    <FormControlLabel value="1" control={<Radio style={{ color: 'black' }} />} label="Self Pickup" />
                                                    <Box style={{ borderBottom:"1px solid #E6E6E6" }} ></Box>
                                                    <FormControlLabel value="2" control={<Radio style={{ color: 'black' }} />} label="Delivery By Flixoo" />
                                                </RadioGroup>
                                            </Box>
                                </Box>
                            </Grid>}
                        {activeStep === 3 && 
                        this.state.deliveryType==="2"?
                            <Grid item xs={12} md={7} className={classes.leftCartBox}>
                                <Box style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '36px' }}>
                                    <Box data-test-id="payment-method-container" className={classes.addressContainer}>
                                        <Typography style={{ fontSize: '16px', fontWeight: 600 }}>Payment Method</Typography>
                                        <RadioGroup data-test-id="payment-options" onChange={this.handlePaymentMethod} value={this.state.paymentMethod} className={classes.codBtns} >
                                        <Snackbar data-test-id="snackbar-test-id" open={this.state.openPaymentStatus} autoHideDuration={6000} onClose={this.handleSnackbarToogle} anchorOrigin={{vertical:"bottom", horizontal:"right"}} >
                                            <Alert severity={this.state.severity === "error" ? "error" : "success"} onClose={this.handleSnackbarToogle}>
                                                  {this.state.paymentStatusText}
                                            </Alert>
                                        </Snackbar>
                                        <Box className={classes.addressBox}>
                                                <CustomFormControlLabel
                                                    control={<RadioMui />}
                                                    value="cashfree"
                                                    label={
                                                        <Box>
                                                            <Typography className={classes.paymentText}>{`Cashfree Secure (UPI, Net Banking, Debit Cards, Credit Cards, Wallets and more...`}</Typography>
                                                            <Box className={classes.imageBoxStyle}>
                                                                {[visa, master, rupay, paytm].map((img: string) => (
                                                                    <img src={img} />
                                                                ))}
                                                            </Box>
                                                        </Box>
                                                    }
                                                />
                                            </Box>
                                            <Box className={classes.paymentBtn}>
                                                <FormControlLabel
                                                    className={classes.radioLabel}
                                                    value="upi"
                                                    control={<Radio style={{ color: 'black' }} />}
                                                    label="UPI (GPay, PhonePe, etc.)" />
                                            </Box>
                                            <Box className={classes.paymentBtn}>
                                                <FormControlLabel
                                                    className={classes.radioLabel}
                                                    value="cod"
                                                    control={<Radio style={{ color: 'black' }} />}
                                                    label="Cash on Delivery/Pay on Delivery Cash, UPI and Cards accepted." />
                                            </Box>
                                        </RadioGroup>
                                    </Box>
                                </Box>
                            </Grid>
                            :
                            activeStep === 3 && 
                        this.state.deliveryType==="1" &&
                            <Grid 
                            data-test-id="grid-payment" 
                            item xs={12} md={7} 
                            className={classes.leftCartBox}>
                                <Box style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '36px' }}>
                                    {this.state.Otp>0?
                                    <Box data-test-id="payment-method-container" className={classes.addressContainer}>
                                        <Typography style={{ fontSize: '16px', fontWeight: 600 }}>Share OTP</Typography>
                                        <Typography style={{ fontSize: '12px', fontWeight: 400, color:"#555555B2" }}>Kindly share the following OTP with the seller when picking up the order:</Typography>
                                        <Box style={{border:"1px solid #E6E6E6", height:"240px",width:"630px", borderRadius:"10px"}}>
                                            <Box style={{display:"flex", padding:"22px", justifyContent:"center"}}>
                                                <Box position="relative"
                                                        display="inline-flex"
                                                        justifyContent="center"
                                                        alignItems="center">
                                                        <StyledCircularProgress variant="determinate"
                                                                value={100}
                                                                size={80}
                                                                thickness={5}
                                                                style={{ color: '#979797', position: 'absolute' }}/>
                                                            <StyledCircularProgress variant="determinate"
                                                                value={this.state.percentage}
                                                                size={80}
                                                                thickness={5}
                                                                style={{ color: '#F5EA16' }}/>
                                                            <Box position="absolute"
                                                                display="flex"
                                                                justifyContent="center"
                                                                alignItems="center">
                                                                <Typography color="textPrimary" style={{fontSize:"14px"}}>{this.formatTime(this.state.remainingTime)}
                                                                </Typography></Box>
                                                </Box>
                                                <Box display="flex" alignItems="center" width="270px" marginLeft="30px"><InfoOutlinedIcon style={{ color: '#757575', marginRight: 8 }} />
                                                                <Typography variant="body2" style={{ color: '#424242' }}>Note: Self-pickup request will expire within 24 hours.
                                                                </Typography>
                                                </Box>
                                            </Box>
                                            <Typography style={{fontFamily:"Poppins",fontSize:"80px", 
                                                fontWeight:800, color:"#F5EA16", letterSpacing:"10px", display:"flex", 
                                                justifyContent:"center"}}>{this.state.Otp}</Typography>
                                                        
                                        </Box>
                                        <Box>
                                        <Typography style={{ fontSize: '16px', fontWeight: 600, padding:"15px 0" }}>Seller Details</Typography>
                                        <Box style={{border:"1px solid #E6E6E6", height:"150px",width:"630px", borderRadius:"10px", padding:"15px"}}>
                                        <Box style={{padding:"10px 0"}}>
                                        <Typography style={{ fontSize: '14px', fontWeight: 600, color:"#787878" }}>Name</Typography>
                                        <Typography style={{ fontSize: '14px' }}>{this.state.currentSellerName}</Typography>
                                        </Box>
                                        <Box>
                                        <Typography style={{ fontSize: '14px', fontWeight: 600, color:"#787878" }}>UPI ID</Typography>
                                        <Typography style={{ fontSize: '14px'}}>{this.state.currentSellerUpi}</Typography>
                                        </Box>
                                        </Box>
                                        </Box>
                                    </Box>:
                                    <Box style={{fontSize:"18px",fontWeight:600, margin:"auto"}}>Click the 'Place Order' button to receive your OTP.</Box>}
                                </Box>
                            </Grid>}

                        <Grid item xs={12} md={5} className={classes.rightCartBox}>
                        {(activeStep === 0 || activeStep === 1)  && 
                        <Box className={classes.totalBox}>
                         <Box style={{ display: 'flex', flexDirection: 'row', gap: '20px', border: '1px solid #E6E6E6', borderRadius: '8px', padding: '14px 22px', justifyContent:"space-between",alignItems:"center" }}>
                            
                                        <Typography className={classes.orderTotalText}>Order Total</Typography>
                                        <Box sx={{ fontSize: '14px', fontWeight: 500, fontFamily: "Arial !important" }}>₹ {cartData.sub_total}</Box>
                            
                        </Box>
                         <Button data-test-id="proceed-to-checkout" className={classes.checkoutBtn} onClick={this.handleCheckout} disabled={addNewAddress || updateAddress}>Proceed</Button>
                         </Box>
                         }
                        {activeStep === 2  && 
                            <Box data-testid="class-box"  className={classes.totalBox}>
                                <Box 
                                    style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '8px',
                                    border: '1px solid #E6E6E6',
                                    borderRadius: '8px',
                                    padding: '24px'
                                    }}
                                    >
                                    <Typography 
                                    style={{ fontSize: '16px', 
                                        fontWeight: 500 }}
                                    >Payment Summary</Typography>
                                    <Box className={classes.priceBox}>
                                        <Typography className={classes.priceText}
                                        >Product Subtotal</Typography>
                                        <Box sx={{ fontSize: '14px', 
                                            fontWeight: 500, 
                                            fontFamily: "Arial !important" }}>₹ {cartData.sub_total}</Box>
                                    </Box>
                                    <Box className={classes.priceBox}>
                                        <Typography className={classes.priceText}
                                        >Tax</Typography>
                                        <Box sx={{ fontSize: '14px', 
                                            fontWeight: 500,
                                            fontFamily: "Arial !important" }}>₹ {cartData.total_tax || cartData.tax}</Box>
                                    </Box>
                                    <Box data-testid="shipping-box" 
                                    className={classes.priceBox}
                                    style={{display:cartData.shipping_charge?
                                    "flex":"none"}}>
                                        <Typography className={classes.priceText}
                                        >Shipping</Typography>
                                        <Box sx={{ fontSize: '14px', 
                                            fontWeight: 500, 
                                            fontFamily: "Arial !important" }}>₹ {cartData.shipping_charge}</Box>
                                    </Box>
                                    <Box className={classes.priceBox}>
                                        <Typography className={classes.priceText}
                                        >Discount</Typography>
                                        <Box sx={{ fontSize: '14px', 
                                            fontWeight: 500, 
                                            fontFamily: "Arial !important" }}>-₹ {Number(cartData.applied_discount) + 
                                            Number(cartData.shipping_discount||0)}.00</Box>
                                    </Box>
                                    <Divider />
                                    <Box className={classes.priceBox}>
                                        <Typography className={classes.priceText}
                                        >Total</Typography>
                                        <Box sx={{ fontSize: '14px', 
                                            fontWeight: 500, 
                                            fontFamily: "Arial !important" }}>₹ {cartData.total}</Box>
                                    </Box>
                                </Box>
                                <Button data-test-id="proceed-to-checkout" className={classes.checkoutBtn} onClick={this.handleCheckout} disabled={addNewAddress || updateAddress}>Proceed</Button>
                            </Box>}
                            {activeStep === 3  && 
                            <Box 
                            className={classes.totalBox}>
                                <Box 
                                style={{ display: 'flex', 
                                flexDirection: 'column', 
                                gap: '8px', 
                                border: '1px solid #E6E6E6', 
                                borderRadius: '8px', 
                                padding: '24px' }}
                                >
                                    <Typography
                                    style={{ fontSize: '16px', 
                                    fontWeight: 500 }}
                                    >Payment Summary</Typography>
                                    <Box 
                                    className={classes.priceBox}>
                                        <Typography className={classes.priceText}
                                        >Product Subtotal</Typography>
                                        <Box 
                                        sx={{ fontSize: '14px', 
                                        fontWeight: 500, 
                                        fontFamily: "Arial !important" }}
                                        >₹ {cartData.sub_total}</Box>
                                    </Box>
                                    <Box className={classes.priceBox}>
                                        <Typography 
                                        className={classes.priceText}>Tax</Typography>
                                        <Box 
                                        sx={{ fontSize: '14px', 
                                        fontWeight: 500, 
                                        fontFamily: "Arial !important" }}
                                        >₹ {cartData.total_tax || cartData.tax}</Box>
                                    </Box>
                                    <Box 
                                    className={classes.priceBox} 
                                    style={{display:cartData.shipping_charge?
                                    "flex":"none"}}>
                                        <Typography className={classes.priceText}>Shipping</Typography>
                                        <Box 
                                        sx={{ fontSize: '14px', 
                                        fontWeight: 500, 
                                        fontFamily: "Arial !important" }}
                                        >₹ {cartData.shipping_charge}</Box>
                                    </Box>
                                    <Box className={classes.priceBox}>
                                        <Typography className={classes.priceText}
                                        >Discount</Typography>
                                        <Box 
                                        sx={{ fontSize: '14px', 
                                        fontWeight: 500, 
                                        fontFamily: "Arial !important" }}
                                        >-₹ {Number(cartData.applied_discount) + 
                                        Number(cartData.shipping_discount||0)}.00</Box>
                                    </Box>
                                    <Divider />
                                    <Box className={classes.priceBox}>
                                        <Typography className={classes.priceText}>Total</Typography>
                                        <Box
                                        sx={{ fontSize: '14px', 
                                        fontWeight: 500, 
                                        fontFamily: "Arial !important" }}
                                        >₹ {cartData.total}</Box>
                                    </Box>
                                </Box>
                                <Box className={classes.offerBtn} onClick={this.stayTuned}>
                                    <Typography 
                                    style={{ fontSize: '14px' }}
                                    >Applicable offers</Typography>
                                    <ArrowForwardIosIcon />
                                </Box>
                                <Button data-test-id="proceeddd-to-checkout" className={classes.checkoutBtn} onClick={this.handleCheckout} disabled={addNewAddress || updateAddress}>Place Order</Button>
                            </Box>}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid>
                    <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '40px 40px 0px', flexWrap: 'wrap' }}>
                        <Typography style={{ fontSize: '24px', fontWeight: 500 }}>You May Also Like</Typography>
                        <Typography style={{ fontSize: '16px', cursor: 'pointer' }} onClick={this.navigateToSeeAll}>{'See all >'}</Typography>
                    </Box>
                    <Grid className={classes.suggestionContainer}>
                    {suggestionData.length > 0 ?
                        this.slidder() :
                        <Box style={{ width: '100%', display: 'flex', height: '50vh', justifyContent: 'center', alignItems: 'center' }}>
                            <img src={noDataFound} className={classes.noDataImage} />
                        </Box>}
                    </Grid>
                </Grid>
                </>
                }
                <MessageModalWeb
                    displayPopup={messageBox}
                    closeErrorPopup={this.closeMessageBox}
                    errorMessage={messageTxt}
                    data-test-id="modalId"
                    />
                <MessageModalWeb
                    displayPopup={messageModel}
                    closeErrorPopup={this.closeMessageModal}
                    errorMessage={message} />
                <LoginFailModalWeb
                    displayPopup={addressMessageBox}
                    closeErrorPopup={this.closeAddessErrorBox}
                    errorMessage={addressMessageTxt}
                />
                <MessageModalWeb
                    displayPopup={messageOpenQty}
                    closeErrorPopup={this.closeMessageQty}
                    errorMessage={messageQty} />
                </Content>
            </Paper>
        )
        // Customizable Area End
    }
}

// Customizable Area Start
const Content = styled(Box)({
    "& .MuiSvgIcon-root":{
        fontSize: "32px"
    }
})

const RadioMui = styled(Radio)({
    color: "black",
    "& .PrivateRadioButtonIcon-root-199": {
        color: "black",
    },
    "& .MuiRadio-colorSecondary.Mui-checked": {
        color: "black",
    },
    "& .MuiRadio-colorSecondary": {
        color: "black",
    }
});

const CustomFormControlLabel = styled(FormControlLabel)({
    "& .MuiRadio-root": {
        color: "black",
    },
    "& .Mui-checked": {
        color: "black",
    },
});
export const StyledCircularProgress = withStyles((theme: Theme) =>
    createStyles({
      root: {
        position: 'relative',
      },
      circle: {
        strokeLinecap: 'round',
      },
    })
  )(CircularProgress);
export default withStyles(styles)(BuyerShoppingCart);
// Customizable Area End
