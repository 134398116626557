import React from "react";
import { Dialog, DialogContent, Typography, Box } from "@material-ui/core";
import { withStyles, WithStyles, Theme } from '@material-ui/core/styles';
import { sucessImageIcon } from "./assets";

// Define interface for props
interface SuccessDialogProps extends WithStyles<typeof styles> {
    open: boolean;
    closeFn: () => void;
    successTitle: string;
    successMessage: string;
}

class SuccessDialog extends React.Component<SuccessDialogProps> {
    render() {
        const { classes, open, closeFn, successTitle, successMessage } = this.props;
        return (
            <Dialog
                open={open}
                onClose={closeFn}
                PaperProps={{
                    style: {
                        borderRadius: 23,
                        maxWidth: '526px',
                        minHeight: '228px',
                        width: '100%',
                        paddingTop:"1em",
                    },
                }}

            >
                <DialogContent>
                    <Box className={classes.contentBox}>
                        <img src={sucessImageIcon} alt="Success Vector" className={classes.successImage} />
                        <Typography variant="h6" className={classes.successTitle}>{successTitle}</Typography>
                        <Typography variant="body1" className={classes.successMessage}>
                            {successMessage}
                        </Typography>
                    </Box>
                </DialogContent>
            </Dialog>
        )
    }
}

const styles = (theme: Theme) => ({
    contentBox: {
        display: 'flex',
        flexDirection: 'column' as const,
        alignItems: 'center' as const,
        justifyContent: 'center',
        height: '100%',
        gap: '12px'
    },
    successTitle: {
        textAlign: 'center' as const,
        fontWeight: 600,
        color: '#555555'
    },
    successMessage: {
        textAlign: 'center' as const,
        color: '#555555',
        cursor: 'pointer'
    },
    successImage:{

    }
});

export default withStyles(styles)(SuccessDialog);
