import React from "react";

// Customizable Area Start
import AccountRegistrationController, { Props } from "./AccountRegistrationController";
import {
    Box,
    Grid,
    Typography,
    Button,
    styled,
    InputLabel, TextField, Checkbox
} from "@material-ui/core";
import { Alert } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';
import TermsAndConditions3 from "../../TermsAndConditions3/src/TermsAndConditions3";
import SellerTermsAndCondtionWeb from "./SellerTermsAndCondtion.web";
import {
    App_Logo, Pwd_visible_eye, Wave, centerImage, leftImage, rightImage, SignupArrow, AppleLogo, GoogleLogo, FacebookLogo, Save_logo_2, profileImgLeft, profileImgCenter, profileImgRight, ImageOverlay, radar, BoxShaddow_Img, selectBox, indiaFlag, cancelBtn, hidePassword
} from "./assets";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import LoginFailModalWeb from "../../../components/src/LoginFailModal.web";
import LoaderBtn from "../../../components/src/LoaderBtn.web";
import GoogleButton from "../../../components/src/GoogleButton.web";
const configJSON = require("./config");
import AppleLogin from 'react-apple-login'
// Customizable Area End


export default class AccountRegistration extends AccountRegistrationController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderFullNameInput() {
        return (
            <InputGrid item xs={12}>
                <InputTag
                    data-test-id="full-name-input"
                    id="outlined-number"
                    onFocus={this.handleFocus}
                    onBlur={this.handleBlur}
                    label="First Name & Last Name"
                    type="text"
                    // value = {this.state.resultState.additionalUserInfo.profile.name}
                    inputRef={this.ref}
                    onChange={this.handleFullNameChange}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    variant="outlined"

                />
                {this.state.fullNameError !== "" && (
                    <ErrorTag>{this.state.fullNameError}</ErrorTag>
                )}
            </InputGrid>
        );
    }

    renderUsernameInput() {
        // Render the input for username
        return (
            <InputGrid item xs={12}>
                <InputTag
                    data-test-id="username-input"
                    id="outlined-number"
                    label="User Name"
                    type="text"
                    value={this.state
                        .selectedUsername}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={this.handleSelectUsername}
                    variant="outlined"
                    // value={this.state.username}
                    autoComplete="off"

                />
                {this.state.selectedUsername === "" ? (<ErrorTag>{this.state.userNameError}</ErrorTag>) : ""}
                {this.state.username.length > 0 ?
                    (
                        <>
                            <Box style={{ display: "flex", gap: "40px", color: "white", marginTop: 8 }}>
                                <Typography style={{ fontSize: '18px', fontWeight: 500, lineHeight: 'normal' }}>Available:</Typography>
                                <Box style={{ display: "flex", columnGap: "20px", rowGap: '5px', flexWrap: "wrap" }}>
                                    {this.state.username.map((i: any) => <Typography onClick={() => {
                                        this.setState({ selectedUsername: i })
                                    }} style={{ color: "yellow", cursor: "pointer", fontSize: '18px', fontWeight: 500, lineHeight: 'normal' }} >{i}</Typography>)}
                                </Box>
                            </Box>
                        </>)
                    : ""}

            </InputGrid>
        )
    }

    renderPasswordInput() {
        // Render the input for password
        return (
            <InputGrid item xs={12} style={{ marginTop: "10px" }}>
                <Box style={{ position: 'relative' }}>
                    <InputTagPwd
                        data-test-id="passowrd-input"
                        id="outlined-number"
                        label="Password"
                        onKeyDown={this.handleKey}
                        type="text"
                        value={this.state.showPassword ? this.state.copyPassword : "*".repeat(this.state.password.length)}
                        className={this.state.showPassword ? "" : "showStar"}
                        onChange={this.handlePwdChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                        autoComplete="off"
                    />
                    <EyeImage style={{ top: 20}} src={this.state.showPassword ? hidePassword : Pwd_visible_eye} alt="" onClick={this.toggleShowPassword} className={this.state.showPassword ? "showStar" : ""} />
                </Box>
                {<ErrorTag>{this.state.pwdError}</ErrorTag>}
            </InputGrid>
        )
    }

    renderConfirmPasswordInput() {
        // Render the input for confirming the password
        return (
            <InputGrid item xs={12} style={{ marginBottom: 10 }}>
                <Box style={{ position: 'relative' }}>
                    <InputTagPwd
                        data-test-id="confirm-passowrd-input"
                        id="outlined-number"
                        label="Re-enter password"
                        onKeyDown={this.handleKey1}
                        type="text"
                        value={this.state.showPassword1 ? this.state.copyPassword1 : "*".repeat(this.state.reTypePassword.length)}
                        className={this.state.showPassword1 ? "" : "showStar"}
                        onChange={this.handleConfirmPwdChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                        autoComplete="off"
                    />

                    <EyeImage src={this.state.showPassword1 ? hidePassword : Pwd_visible_eye} alt="" onClick={this.toggleShowPassword1} className={this.state.showPassword1 ? "showStar" : ""} />
                </Box>
                {<ErrorTag>{this.state.reTypepasswordError}</ErrorTag>}
            </InputGrid>
        )
    }

    renderBuyerGoogleSignup() {
        return (
            <ParentGridWebGoogle container style={{ height: '100vh' }}>
                <ImageGrid item lg={6}>
                    <ImageSubBox1>
                        <ImageLogo src={App_Logo} alt="Logo Image" />
                        <SmallImageBox>
                            <SideImageDiv>
                                <SideImage src={leftImage} alt="Logo Image" />
                                <SideImgInnerBox >
                                    <img src={radar} />
                                    <p style={{
                                        color: '#FFF',
                                        fontFamily: 'Poppins-Regular',
                                        fontSize: '20px',
                                        fontStyle: 'normal',
                                        fontWeight: 600,
                                        lineHeight: 'normal',
                                        letterSpacing: '0.229px'
                                    }}>24</p>
                                </SideImgInnerBox>
                                <SaveLogoImg src={Save_logo_2} />
                                <Box style={{
                                    position: 'absolute',
                                    left: 38,
                                    bottom: 25,
                                    display: 'flex',
                                    gap: '25.43px',
                                    alignItems: 'center',
                                    zIndex: 1,
                                }}>
                                    <img src={profileImgLeft} />
                                    <p style={{
                                        color: 'white',
                                        fontWeight: 500,
                                        fontSize: '20px',
                                        lineHeight: 'normal',
                                        letterSpacing: '0.25px',
                                        fontFamily: "'Poppins-Regular', sans-serif",
                                    }}>Mike Torello</p>
                                </Box>
                                <img style={{ position: 'absolute', bottom: 0, left: 0, width: '100%' }} src={ImageOverlay} alt="overlay" />
                                <ShadowSideImg src={BoxShaddow_Img} alt="overlay" />
                            </SideImageDiv>
                            <LeftImageDiv>
                                <img style={{
                                    width: "282px",
                                    objectFit: 'cover',
                                    borderRadius: '15px',
                                    height: '100%'
                                }} src={centerImage} alt="Logo Image" />
                                <div style={{
                                    position: 'absolute', left: 25, top: 21, display: 'flex', gap: 21, borderRadius: '46px',
                                    background: 'rgba(255, 31, 113, 0.25)', backdropFilter: 'blur(13.591408729553223px)', padding: '12px 18px'
                                }}>
                                    <img src={radar} />
                                    <p
                                        style={{
                                            color: '#FFF',
                                            fontFamily: 'Poppins-Regular',
                                            fontSize: '20px',
                                            fontStyle: 'normal',
                                            fontWeight: 600,
                                            lineHeight: 'normal',
                                            letterSpacing: '0.229px'
                                        }}
                                    >24</p>
                                </div>
                                <img style={{ position: 'absolute', right: 18, top: 20 }} src={Save_logo_2} />
                                <div style={{ position: 'absolute', left: 38, bottom: 25, display: 'flex', gap: '25.43px', alignItems: 'center', zIndex: 1, }}>
                                    <img src={profileImgCenter} />
                                    <Typography style={{ color: 'white', fontWeight: 500, fontSize: '20px', lineHeight: 'normal', letterSpacing: '0.25px', fontFamily: "'Poppins-Regular', sans-serif", }}>Col. Roderick</Typography>
                                </div>
                                <img style={{ position: 'absolute', bottom: 0, left: 0, width: '100%' }} src={ImageOverlay} alt="overlay" />
                                <ShadowCenterImg src={BoxShaddow_Img} alt="overlay" />
                            </LeftImageDiv>
                            <SideImageDiv>
                                <CategorySelectBox style={{ width: 150, height: 150, borderRadius: '50%', backgroundColor: 'linear-gradient(137deg, #FFB2CF 0%, #FEC559 100%)', position: 'absolute', right: -75, top: -75, zIndex: 11 }}>
                                    <img style={{ transform: 'scale(1.59)' }} src={selectBox} alt="gradient" />
                                    <span style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', color: 'white', textAlign: 'center', fontSize: 35, fontWeight: 500 }}> Select Your Category</span>
                                </CategorySelectBox>
                                <SideImage src={rightImage} alt="Logo Image" />
                                <div style={{
                                    position: 'absolute', left: 25, top: 21, display: 'flex', gap: 21, borderRadius: '46px',
                                    background: 'rgba(255, 31, 113, 0.25)', backdropFilter: 'blur(13.591408729553223px)', padding: '12px 18px'
                                }}>
                                    <img src={radar} />
                                    <Typography
                                        style={{
                                            color: '#FFF',
                                            fontFamily: 'Poppins-Regular',
                                            fontSize: '20px',
                                            fontStyle: 'normal',
                                            fontWeight: 600,
                                            lineHeight: 'normal',
                                            letterSpacing: '0.229px'
                                        }}
                                    >24</Typography>
                                </div>
                                <img style={{ position: 'absolute', right: 18, top: 20 }} src={Save_logo_2} />
                                <Box style={{ position: 'absolute', left: 38, bottom: 25, display: 'flex', gap: '25.43px', alignItems: 'center', zIndex: 1, }}>
                                    <img src={profileImgRight} />
                                    <Typography style={{ color: 'white', fontWeight: 500, fontSize: '20px', lineHeight: 'normal', letterSpacing: '0.25px', fontFamily: "'Poppins-Regular', sans-serif", }}>Theodore T.</Typography>
                                </Box>
                                <img style={{ position: 'absolute', bottom: 0, left: 0, width: '100%' }} src={ImageOverlay} alt="overlay" />
                                <ShadowSideImg src={BoxShaddow_Img} alt="overlay" />

                            </SideImageDiv>
                        </SmallImageBox>
                    </ImageSubBox1>
                </ImageGrid>
                <RightGrid item lg={6}>
                    <WaveImg src={Wave} alt="overlay" />
                    <WaveImg2 src={Wave} alt="overlay" />
                    <ContentBoxWrapper>
                        <ContentBox >
                            <Box style={{ zIndex: 1 }} >
                                <HeadingTypography variant="h1" >Sign Up</HeadingTypography>
                                <Box style={{ display: 'flex', gap: 19 }}>
                                    <ContentTypography variant="body1" >Create account to start using FlixooLive</ContentTypography>
                                    <ArrowImg src={SignupArrow} alt="Arrow_logo" />
                                </Box>
                                <FormBox container style={{ width: '100%' }}>
                                    <InputTag
                                        id="outlined-number"
                                        onFocus={this.handleFocus}
                                        onBlur={this.handleBlur}
                                        label="First Name & Last Name"
                                        type="text"
                                        inputRef={this.ref}
                                        value={this.state.fullName}
                                        onChange={this.handleFullNameChange}
                                        style={{ marginBottom: '35px' }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="outlined"

                                    />

                                    <Box style={{ display: 'flex', gap: 10, width: '100%', marginTop: "10px" }}>
                                        {this.state.showMobileField && (
                                            <Box>
                                                <Box style={{ position: 'relative' }}>
                                                    <label style={{ background: 'black', color: 'rgba(255, 255, 255, 0.30)', fontSize: 14, position: 'absolute', top: -10, left: 15, display: 'inline-block', padding: '0px 5px' }}>Code</label>
                                                    <Box onClick={this.handleIsSelect} style={{ width: '114px', height: '58px', border: '1px solid rgba(255, 255, 255, 0.50)', borderRadius: 10, color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        <p style={{ paddingRight: '10px' }}>{this.state.selectGoogleSignupUser?.label}</p> {this.state.selectGoogleSignupUser?.value}
                                                    </Box>
                                                    {this.state.isSelect &&
                                                        <CountryDiv style={{
                                                            position: 'absolute', top: '65px', width: '114px', listStyle: 'none', background: 'black',
                                                            borderRadius: 10, border: '1px solid rgba(255, 255, 255, 0.50)', zIndex: 11, height: 150, overflowY: 'scroll'
                                                        }}>
                                                            {this.state.dataSource.map((item: any, index: any) => (
                                                                <li onClick={this.selectCountryFlag.bind(this, item)} style={{ color: 'white', padding: '7px 14px', borderBottom: '1px solid rgba(255, 255, 255, 0.50)' }}>
                                                                    <span style={{ paddingRight: 8, display: 'inline-block' }}>{item.label}</span>
                                                                    {item?.value}</li>
                                                            ))}
                                                        </CountryDiv>}
                                                </Box>
                                            </Box>
                                        )
                                        }
                                        <InputGrid item xs={12}>
                                            <InputTag
                                                type="text"
                                                id="outlined-number"
                                                label={this.state.label}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                variant="outlined"
                                                value={this.state.value || this.state.resultState.additionalUserInfo.profile.email}
                                                onChange={this.handleChange}
                                                placeholder="Enter a number or character"
                                            />
                                            {this.state.valueError ? <ErrorTag> please enter Email or Mobile</ErrorTag> : this.emailMobileError()}
                                        </InputGrid>

                                    </Box>
                                    {this.renderUsernameInput()}

                                    <Box style={{ width: '100%' }}>
                                        <Box style={{ display: 'flex', gap: 10, alignItems: 'center', marginBottom: 20 }}>
                                            <Checkbox
                                                color="primary"
                                                data-test-id="checkBoxId"
                                                style={{ padding: 0 }}
                                                checked={this.state.isChecked}
                                                onChange={this.handleIsChecked}
                                            />
                                            <InputLabel style={{
                                                color: 'white',
                                                fontSize: '16px',
                                                fontStyle: 'normal',
                                                fontWeight: 500,
                                                lineHeight: '19px',
                                                fontFamily: "'Poppins-Regular', sans-serif",
                                            }}>
                                                Agree with our< Button
                                                    onClick={this.buyerTermsAndCondition}
                                                    data-test-id='buyerTermsAndCondition1'
                                                    style={{ textTransform: "initial", color: "#1A4CFF", padding: '0px', fontSize: '16px', lineHeight: '19px', fontFamily: 'Poppins-Regular' }}
                                                >
                                                    &nbsp;Terms and Conditions&nbsp;
                                                </Button>& <Button
                                                    onClick={this.buyerPrivacyPolicy}
                                                    data-test-id='buyerPrivacyPolicy'
                                                    style={{ textTransform: "initial", color: "#1A4CFF", padding: '0px', fontSize: '16px', lineHeight: '19px', fontFamily: 'Poppins-Regular' }}
                                                >Privacy Policy</Button>
                                            </InputLabel>
                                        </Box>
                                        <Box>
                                            {this.state.checkedError === true && <ErrorTag style={{ marginTop: "-20px" }}>Terms and Conditions Not Accepted</ErrorTag>}
                                        </Box>
                                        <ButtonBox >
                                            <SignupButton variant="contained" color="primary" data-test-id="googleSignUpBtn" onClick={this.googleSignupApi} >
                                                {this.state.signupButtonText}
                                            </SignupButton>
                                        </ButtonBox>
                                    </Box>
                                </FormBox>
                            </Box>
                        </ContentBox>
                    </ContentBoxWrapper>
                </RightGrid>
            </ParentGridWebGoogle>
        )
    }

    emailMobileError() {
        return this.state.showMobileField ? (<ErrorTag>{this.state.phoneError}</ErrorTag>) : (<ErrorTag>{this.state.emailError}</ErrorTag>)

    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start

            <>
                {this.state.googleSignup ?
                    this.renderBuyerGoogleSignup()
                    :
                    (<ParentGrid container>
                        <ImageGrid item lg={6}>
                            <ImageSubBox>
                                <ImageLogo src={App_Logo} alt="Logo Image" />
                                <SmallImageBox>
                                    <SideImageDiv>
                                        <SideImage src={leftImage} alt="Logo Image" />
                                        <SideImgInnerBox >
                                            <img src={radar} />
                                            <LiveCount>24</LiveCount>
                                        </SideImgInnerBox>
                                        <SaveLogoImg src={Save_logo_2} />
                                        <ProfileImageBox>
                                            <img src={profileImgLeft} />
                                            <ProfileName>Mike Torello</ProfileName>
                                        </ProfileImageBox>
                                        <img style={{ position: 'absolute', bottom: 0, left: 0, width: '100%' }} src={ImageOverlay} alt="overlay" />
                                        <ShadowSideImg src={BoxShaddow_Img} alt="overlay" />
                                    </SideImageDiv>
                                    <LeftImageDiv>
                                        <LeftImage src={centerImage} alt="Logo Image" />
                                        <Box style={{
                                            position: 'absolute', left: 25, top: 21, display: 'flex', gap: 21, borderRadius: '46px',
                                            background: 'rgba(255, 31, 113, 0.25)', backdropFilter: 'blur(13.591408729553223px)', padding: '12px 18px'
                                        }}>
                                            <img src={radar} />
                                            <Typography
                                                style={{
                                                    color: '#FFF',
                                                    fontFamily: 'Poppins-Regular',
                                                    fontSize: '20px',
                                                    fontStyle: 'normal',
                                                    fontWeight: 600,
                                                    lineHeight: 'normal',
                                                    letterSpacing: '0.229px'
                                                }}
                                            >24</Typography>
                                        </Box>
                                        <img style={{ position: 'absolute', right: 18, top: 20 }} src={Save_logo_2} />
                                        <Box style={{ position: 'absolute', left: 38, bottom: 25, display: 'flex', gap: '25.43px', alignItems: 'center', zIndex: 1, }}>
                                            <img src={profileImgCenter} />
                                            <Typography style={{ color: 'white', fontWeight: 500, fontSize: '20px', lineHeight: 'normal', letterSpacing: '0.25px', fontFamily: "'Poppins-Regular', sans-serif", }}>Col. Roderick</Typography>
                                        </Box>
                                        <img style={{ position: 'absolute', bottom: 0, left: 0, width: '100%' }} src={ImageOverlay} alt="overlay" />
                                        <ShadowCenterImg src={BoxShaddow_Img} alt="overlay" />
                                    </LeftImageDiv>
                                    <SideImageDiv>
                                        <CategorySelectBox style={{ width: 150, height: 150, borderRadius: '50%', backgroundColor: 'linear-gradient(137deg, #FFB2CF 0%, #FEC559 100%)', position: 'absolute', right: -75, top: -75, zIndex: 11 }}>
                                            <img style={{ transform: 'scale(1.59)' }} src={selectBox} alt="gradient" />
                                            <span style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', color: 'white', textAlign: 'center', fontSize: 35, fontWeight: 500 }}> Select Your Category</span>
                                        </CategorySelectBox>
                                        <SideImage src={rightImage} alt="Logo Image" />
                                        <Box style={{
                                            position: 'absolute', left: 25, top: 21, display: 'flex', gap: 21, borderRadius: '46px',
                                            background: 'rgba(255, 31, 113, 0.25)', backdropFilter: 'blur(13.591408729553223px)', padding: '12px 18px'
                                        }}>
                                            <img src={radar} />
                                            <Typography
                                                style={{
                                                    color: '#FFF',
                                                    fontFamily: 'Poppins-Regular',
                                                    fontSize: '20px',
                                                    fontStyle: 'normal',
                                                    fontWeight: 600,
                                                    lineHeight: 'normal',
                                                    letterSpacing: '0.229px'
                                                }}
                                            >24</Typography>
                                        </Box>
                                        <img style={{ position: 'absolute', right: 18, top: 20 }} src={Save_logo_2} />
                                        <Box style={{ position: 'absolute', left: 38, bottom: 25, display: 'flex', gap: '25.43px', alignItems: 'center', zIndex: 1, }}>
                                            <img src={profileImgRight} />
                                            <Typography style={{ color: 'white', fontWeight: 500, fontSize: '20px', lineHeight: 'normal', letterSpacing: '0.25px', fontFamily: "'Poppins-Regular', sans-serif", }}>Theodore T.</Typography>
                                        </Box>
                                        <img style={{ position: 'absolute', bottom: 0, left: 0, width: '100%' }} src={ImageOverlay} alt="overlay" />
                                        <ShadowSideImg src={BoxShaddow_Img} alt="overlay" />

                                    </SideImageDiv>
                                </SmallImageBox>
                            </ImageSubBox>
                        </ImageGrid>
                        <RightGrid item lg={6}>
                            <WaveImg src={Wave} alt="overlay" />
                            <WaveImg2 src={Wave} alt="overlay" />
                            <ContentBox>
                                <Box style={{ zIndex: 1 }} >
                                    <HeadingTypography variant="h1" >Sign Up</HeadingTypography>
                                    <Box style={{ display: 'flex', gap: 19 }}>
                                        <ContentTypography variant="body1" >Create account to start using FlixooLive</ContentTypography>
                                        <ArrowImg src={SignupArrow} alt="Arrow_logo" />
                                    </Box>
                                    <FormBox container>
                                        {this.renderFullNameInput()}

                                        <div style={{ display: 'flex', gap: 10, width: '100%', marginTop: "10px" }}>
                                            {this.state.showMobileField && (
                                                <div>
                                                    <div style={{ position: 'relative' }}>
                                                        <label style={{ background: 'black', color: 'rgba(255, 255, 255, 0.30)', fontSize: 14, position: 'absolute', top: -10, left: 15, display: 'inline-block', padding: '0px 5px' }}>Code</label>
                                                        <div onClick={this.handleIsSelect} style={{ width: '114px', height: '58px', border: '1px solid rgba(255, 255, 255, 0.50)', borderRadius: 10, color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                            <p style={{ paddingRight: '10px' }}>{this.state.selectedValue?.label}</p> {this.state.selectedValue?.value}
                                                        </div>
                                                        {this.state.isSelect &&
                                                            <CountryDiv style={{
                                                                position: 'absolute', top: '65px', width: '114px', listStyle: 'none', background: 'black',
                                                                borderRadius: 10, border: '1px solid rgba(255, 255, 255, 0.50)', zIndex: 11, height: 150, overflowY: 'scroll'
                                                            }}>
                                                                {this.state.dataSource.map((item: any, index: any) => (
                                                                    <li onClick={this.selectCountryFlag.bind(this, item)} style={{ color: 'white', padding: '7px 14px', borderBottom: '1px solid rgba(255, 255, 255, 0.50)' }}>
                                                                        <span style={{ paddingRight: 8, display: 'inline-block' }}>{item.label}</span>
                                                                        {item?.value}</li>
                                                                ))}
                                                            </CountryDiv>}
                                                    </div>
                                                </div>
                                            )
                                            }
                                            <InputGrid item xs={12}>
                                                <InputTag
                                                    data-test-id="mobile-email-field"
                                                    type="text"
                                                    id="outlined-number"
                                                    label={this.state.label}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    variant="outlined"
                                                    value={this.state.value}
                                                    onChange={this.handleChange}
                                                    placeholder="Enter a number or character"
                                                />
                                                {this.state.valueError ? <ErrorTag> please enter Email or Mobile</ErrorTag> : this.emailMobileError()}
                                            </InputGrid>

                                        </div>
                                        {this.renderUsernameInput()}
                                        {this.renderPasswordInput()}
                                        {this.renderConfirmPasswordInput()}

                                        <Box style={{ width: '100%' }}>
                                            <Box style={{ display: 'flex', gap: 10, alignItems: 'center', marginBottom: 20 }}>
                                                <Checkbox
                                                    data-test-id="terms-condition-checkbox"
                                                    color="primary"
                                                    style={{ padding: 0 }}
                                                    checked={this.state.isChecked}
                                                    onChange={this.handleIsChecked}
                                                />
                                                <InputLabel style={{
                                                    color: 'white',
                                                    fontSize: '16px',
                                                    fontStyle: 'normal',
                                                    fontWeight: 500,
                                                    lineHeight: '19px',
                                                    fontFamily: "'Poppins-Regular', sans-serif",
                                                }}>
                                                    Agree with our< Button
                                                        onClick={this.buyerTermsAndCondition}
                                                        data-test-id='buyerTermsAndCondition'
                                                        style={{ textTransform: "initial", color: "#1A4CFF", padding: '0px', fontSize: '16px', lineHeight: '19px', fontFamily: 'Poppins-Regular' }}
                                                    >
                                                        &nbsp;Terms and Conditions&nbsp;
                                                    </Button>& <Button
                                                        onClick={this.buyerPrivacyPolicy}
                                                        data-test-id='buyerPrivacyPolicy'
                                                        style={{ textTransform: "initial", color: "#1A4CFF", padding: '0px', fontSize: '16px', lineHeight: '19px', fontFamily: 'Poppins-Regular' }}
                                                    >Privacy Policy</Button>
                                                </InputLabel>
                                            </Box>
                                            <Box>
                                                {this.state.checkedError === true && <ErrorTag style={{ marginTop: "-20px" }}>Terms and Conditions Not Accepted</ErrorTag>}
                                            </Box>
                                            <ButtonBox >
                                                <SignupButton data-test-id="signup-button" variant="contained" color="primary" onClick={this.checkValidation} >
                                                    {this.state.isLoading? "":this.state.signupButtonText}
                                                    <LoaderBtn loading={this.state.isLoading} />
                                                </SignupButton>
                                            </ButtonBox>
                                            {this.state.showAlert &&
                                                <Box className="alert-box">
                                                    <OverlayBox></OverlayBox>
                                                    <AlertBox severity="error">
                                                        <CloseIcon onClick={this.closeAlert} style={{ cursor: 'pointer' }} />
                                                        <AlertHeadText>Error</AlertHeadText>
                                                        {this.state.alertError.map((ele: any) => {
                                                            return (
                                                                (Object.keys(ele)[0] == 'Full phone number') ?
                                                                    <AlertText>Mobile Number has already been taken.</AlertText>
                                                                    :
                                                                    <AlertText>{Object.entries(ele).join(" ").replace(',', " ")}</AlertText>
                                                            )
                                                        })}


                                                    </AlertBox>
                                                </Box>}

                                            <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', maxWidth: 412, margin: '0 auto' }}>
                                            <GoogleButton onSuccess={this.googleLoginApi}/>
                                                <Box style={{ width: 80, height: 58, backgroundColor: 'black', borderRadius: '20px', display: "flex", justifyContent: "center", border: '1.409px solid rgba(255, 255, 255, 0.50)', alignItems: 'center', cursor: "pointer", }}>
                                                    <img src={FacebookLogo} alt="Facebook_logo" />
                                                </Box>

                                                <AppleLogin
                                                    clientId="com.flixoo.web"
                                                    data-test-id="appleLogin"
                                                    redirectURI={window.location.origin}
                                                    render={(renderProps) => (
                                                        <Box onClick={renderProps.onClick} style={{ width: 80, height: 58, backgroundColor: 'black', borderRadius: '20px', display: "flex", justifyContent: "center", border: '1.409px solid rgba(255, 255, 255, 0.50)', alignItems: 'center', cursor: "pointer", }}>
                                                            <img style={{ filter: 'invert(1)' }} src={AppleLogo} alt="Apple_logo" />
                                                        </Box>
                                                    )}
                                                    usePopup={true}
                                                    responseType="code id_token"
                                                    responseMode="form_post"
                                                    scope="name email"
                                                    callback={this.handleCallback}
                                                />

                                                
                                            </Box>
                                        </Box>
                                        <Box style={{ color: 'white', textAlign: 'center', marginTop: 40, marginBottom: 110 }}>
                                        {configJSON.alreadyAccount}

                                            <span style={{ color: "#F5EA16", cursor: "pointer", marginLeft: 5 }} onClick={this.gotoBuyerLogin}>
                                                Login
                                            </span>
                                            <Box sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center', gridGap: '5px', height: '16'
                                            }}
                                                style={{ marginTop: '28px' }}>
                                                <img src={indiaFlag} alt="" />
                                                <Typography component={'p'} style={{ fontFamily: 'Poppins-Regular', fontSize: '16px', }}>Made in Bharat</Typography>
                                            </Box>
                                        </Box>
                                    </FormBox>
                                </Box>
                            </ContentBox>
                        </RightGrid>
                    </ParentGrid>)
                }
                <LoginFailModalWeb
                    displayPopup={this.state.googleSignUpError}
                    closeErrorPopup={this.closeErrorPopup}
                    titleMessage={'Invalid Credentials'}
                    errorMessage={'Email has already been taken'} />
                <SellerTermsAndCondtionWeb sellerTermsConditionData={this.state.termsConditionPrivacyPolicyData} navigation={undefined} id={""} sellerModalOpen={this.state.sellerModalOpen} handleSellerModalClose={this.handleSellerModalClose} showModal={false} />
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const ShadowCenterImg = styled('img')({
    position: 'absolute',
    bottom: -50,
    left: -70,
    width: '423px',
    height: '53px'
})
const CountryDiv = styled('div')({
    '&::-webkit-scrollbar': {
        display: 'none',
    },

    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',
})
const ShadowSideImg = styled('img')({
    position: 'absolute',
    bottom: -50,
    left: -25,
    width: '120%',
})
const WaveImg = styled('img')({
    position: 'absolute',
    bottom: 0,
    width: '100%'
})
const WaveImg2 = styled('img')({
    position: 'absolute',
    bottom: 0,
    left: -169,
    width: '100%'
})
const ParentGrid = styled(Grid)({
    background: "#000",

    // '@media(min-width: 1919px)': {
    //     minHeight:"100vh"
    // },
})

const ParentGridWebGoogle = styled(Grid)({
    background: "black",

    // '@media(min-width: 1919px)': {
    //     minHeight:"100vh"
    // },
})
const LeftImageDiv = styled(Box)({
    position: 'relative',
    marginTop: 369,
    '@media(max-width: 959px)': {
        left: 'unset',
        marginTop: 'unset',
    },
    '@media(max-width: 1280px)': {
        marginBottom: '50px',
    },
    '@media(max-width: 767px)': {
        marginBottom: '0px',
    }
})
const CategorySelectBox = styled(Box)({
    '@media(max-width: 767px)': {
        display: 'none'
    }
})
const SideImageDiv = styled(Box)({
    position: 'relative',
    marginTop: 325,
    transform: 'scale(0.63)',
    '@media(max-width: 959px)': {
        left: 'unset',
        marginTop: 'unset',
    },
    '@media(max-width: 767px)': {
        transform: 'scale(1)',
        marginBottom: '50px',
        marginTop: '50px',
    }
})
const LeftImage = styled('img')({
    width: "282px",
    objectFit: 'cover',
    borderRadius: '15px',
    height: '100%',
})
const SideImage = styled('img')({
    width: "282px",
    objectFit: 'cover',
    borderRadius: '15px',
    height: '100%',
})
const SideImgInnerBox = styled(Box)({
    position: 'absolute',
    left: 25, top: 21,
    display: 'flex',
    gap: 21,
    borderRadius: '46px',
    background: 'rgba(255, 31, 113, 0.25)',
    backdropFilter: 'blur(13.591408729553223px)',
    padding: '12px 18px'
})
const LiveCount = styled(Typography)({
    color: '#FFF',
    fontFamily: 'Poppins-Regular',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    letterSpacing: '0.229px'
})
const SaveLogoImg = styled('img')({
    position: 'absolute',
    right: 18,
    top: 20
})
const ProfileImageBox = styled(Box)({
    position: 'absolute',
    left: 38,
    bottom: 25,
    display: 'flex',
    gap: '25.43px',
    alignItems: 'center',
    zIndex: 1,
})
const ProfileName = styled(Typography)({
    color: 'white',
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: 'normal',
    letterSpacing: '0.25px',
    fontFamily: "'Poppins-Regular', sans-serif",
})
const RightGrid = styled(Grid)({
    background: 'black',
    width: '100%',
    position: 'relative',
    overflow: 'hidden',
})

const ContentBoxWrapper = styled(Box)({
    marginTop: '0px !important',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
})
const ContentBox = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    marginTop: 70,
    '@media(max-width: 959px)': {
        marginLeft: 0,
        marginTop: 30,
        paddingBottom: 50,
    }
})
const ImageGrid = styled(Grid)({
    background: 'rgba(255, 255, 255, 0.25)',
    backdropFilter: 'blur(13.591408729553223px)',
    width: "100%",
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    '@media(max-width: 959px)': {
        minHeight: '350px',
        alignItems: 'flex-end',
        paddingBottom: '50px',
        background: 'unset',
        border: 'none'
    },
    '@media(min-width: 1200)': {
        height: "100%",
    }
})
const ImageSubBox = styled(Box)({
    '@media(max-width: 767px)': {
        height: '300px',
    },
    '@media(min-width: 900px) and (max-width: 959px)': {
        marginTop: '200px',
    },
    '@media(min-width: 768px) and (max-width: 900px)': {
        marginTop: '100px',
    },
})

const ImageSubBox1 = styled(Box)({
    '@media(max-width: 767px)': {
        height: '301px',
    },
    '@media(min-width: 900px) and (max-width: 959px)': {
        marginTop: '201px',
    },
    '@media(min-width: 768px) and (max-width: 900px)': {
        marginTop: '101px',
    },
})


const ImageLogo = styled('img')({
    position: 'absolute',
    top: 91,
    left: 119,
    '@media(max-width: 959px)': {
        top: 30,
        left: 30,
        width: '180px'
    }
})
const SmallImageBox = styled(Box)({
    display: 'flex',
    '@media(min-width: 1440px) and (max-width: 1800px)': {
        transform: 'scale(0.8)'
    },
    '@media(min-width: 1280px) and (max-width: 1439px)': {
        transform: 'scale(0.6)'
    },
    '@media(min-width: 767px) and (max-width: 900px)': {
        transform: 'scale(0.6)'
    },
    '@media(max-width: 767px)': {
        transform: 'scale(0.3)',
        gap: '15px'
    },

})

const ContentTypography = styled(Typography)({
    color: '#FFF',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    maxWidth: 678,
    paddingBottom: 40,
    fontFamily: "'Poppins-Regular', sans-serif",
    '@media(max-width: 959px)': {
        fontSize: '20px',
        lineHeight: '28px',
        paddingInline: 20,
    }
})
const ArrowImg = styled('img')({
    position: "relative", bottom: "57px",
    '@media(max-width: 767px)': {
        right: 20
    }
})
const HeadingTypography = styled(Typography)({
    color: '#FFF',
    fontSize: '40px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    maxWidth: 694,
    fontFamily: "'Poppins-Regular', sans-serif",
    '@media(max-width: 959px)': {
        fontSize: '44px',
        letterSpacing: '-1.8px',
        lineHeight: '58px',
        paddingInline: 20,
    }
})
const InputGrid = styled(Grid)({
    marginBottom: 35,
    '& img.showStar': {
        filter: 'brightness(15)',
        width: 30
    }
});
const ErrorTag = styled(Typography)({
    fontSize: 18,
    color: 'red',
    paddingTop: 15,
});
const InputTag = styled(TextField)({
    width: '100%',
    borderRadius: '12px',
    backgroundColor: 'white',
    color: 'white',
    height: 55,
    fontFamily: 'Poppins-Regular',
    // fontSize: '18px!important',
    fontWeight: 400,
    '& ::placeholder': {
        color: 'rgba(0, 0, 0)',
        // fontSize:'18px!important',
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: 'unset'
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
        border: 'unset'
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
        padding: '0 10px',
        background: 'black',
        color: 'rgba(255, 255, 255, 0.30)',
        fontFamily: 'Poppins-Regular',
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 400,
    },
});
const InputTagPwd = styled(TextField)({
    width: '100%',
    borderRadius: '12px',
    backgroundColor: 'black',
    color: 'white',
    height: 55,
    fontFamily: 'Poppins-Regular',
    fontWeight: 400,
    '& ::placeholder': {
        color: 'rgba(0, 0, 0)',
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: 'unset'
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
        border: 'unset'
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
        padding: '0 10px',
        background: 'black',
        color: 'rgba(255, 255, 255, 0.30)',
        fontFamily: 'Poppins-Regular',
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 400,
    },
    '& .MuiOutlinedInput-input': {
        fontSize: "18px!important",
        position: "unset",
        padding: "18.5px",
        maxWidth: 'unset',
    },
    '&.showStar .MuiOutlinedInput-input': {
        fontSize: "18px!important",
        position: "absolute",
        top: "0px",
        maxWidth: '539px',
        '@media(max-width: 600px)': {
            width: '93%'
        },
        '@media(max-width: 500px)': {
            width: '90%'
        },
        '@media(max-width: 400px)': {
            width: '87%'
        }
    },

});
const EyeImage = styled('img')({
    position: 'absolute',
    right: 20,
    top: 17,
    zIndex: 1,
    cursor: "pointer",
    height: '24px',
    width: '24px !important',
});
const ButtonBox = styled(Box)({
    display: "flex",
    "& .MuiButton-containedPrimary:hover": {
        backgroundColor: "#F5EA16"
    }
})
const SignupButton = styled(Button)({
    borderRadius: '34px',
    background: '#F5EA16',
    width: '100%',
    maxWidth: '412px',
    margin: '40px auto',
    height: '68px',
    color: '#000',
    textAlign: 'center',
    fontFamily: 'Poppins-Regular',
    fontSize: '22px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    letterSpacing: '0.417px',
    textTransform: "initial",
});
const OverlayBox = styled(Box)({
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: 'rgba(0, 0, 0, 0.50)',
    filter: 'blur(13px)',
    width: '100vw',
    height: '100vh',
});
const AlertBox = styled(Alert)({
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: 'white',
    textAlign: 'center',
    borderRadius: 20,
    maxWidth: 400,
    zIndex: 1,
    '& .MuiSvgIcon-root': {
        position: 'absolute',
        top: '10px',
        right: '10px'
    },
    '& .MuiAlert-message': {
        width: '100%'
    },
    '& .MuiAlert-icon': {
        display: 'none'
    },
    "& .MuiButton-containedPrimary:hover": {
        backgroundColor: "#8080805e"
    }
});
const AlertHeadText = styled(Box)({
    color: '#000',
    textAlign: 'center',
    fontFamily: 'Poppins-Regular, sans-serif',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    borderBottom: '1px solid #E9E9E9',
    paddingBottom: '20px',
    maxWidth: '380px',
    margin: 'auto',
});
const AlertText = styled(Box)({
    color: '#000',
    textAlign: 'center',
    fontFamily: 'Poppins-Regular, sans-serif',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    paddingTop: '15px'
})
const FormBox = styled(Grid)({
    width: '100%',
    maxWidth: '578px',
    justifyContent: 'center',
    '@media (max-width: 600px)': {
        width: '90%',
        margin: '0 auto',

    },
    '& .MuiInput-underline::before': {
        borderBottom: 'none',
    },
    '& .MuiInput-underline::after': {
        borderBottom: 'none',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled)::before': {
        borderBottom: 'none'
    },
    "& .MuiNativeSelect-select:focus": {
        backgroundColor: 'white',
    },
    '& .MuiOutlinedInput-input': {
        fontSize: 18,
        border: '1px solid rgba(255, 255, 255, 0.50)',
        background: 'black',
        borderRadius: 12,
        color: 'white'
    },
    "& .MuiCheckbox-root": {
        color: "white",
        background: "white",
        height: "17px",
        width: "17px",
        borderRadius: "5px"
    },
    "& .Mui-checked.MuiCheckbox-root": {
        color: "#F5EA16",
        background: "black"
    }
});

const StyledDialog = styled(Dialog)({
    '& h2': {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        borderBottom: '1px solid #E9E9E9',
        marginBottom: '15px',
        paddingBottom: '20px',
    },
    '& .MuiDialog-paper': {
        width: '317px',
        height: '166px',
        borderRadius: "20px",
    }
});

const StyledDialogTitle = styled(DialogTitle)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '10px',
    '& .MuiCheckbox-root': {
        color: 'white',
        padding: '0px',
    },
    '& .Mui-checked.MuiCheckbox-root': {
        color: '#F5EA16',
    },
});

const StyledPopUpTitle = styled('div')({
    color: 'black',
    fontWeight: 'bold',
    fontSize: '18px',
    padding: '5px 0',
});

const StyledPopUpContent = styled(DialogContentText)({
    color: 'black',
    fontSize: '18px',
    padding: '10px',
    textAlign: 'center',
});

const StyledCursorPointer = styled('div')({
    cursor: 'pointer',
});

const LoderContainer = styled(Box)({
    position: "fixed",
    minHeight: "100vh",
    width: "100%",
    top: 0,
    left: 0,
    backgroundColor: "red"
})

// Customizable Area End
