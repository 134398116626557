// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { WithStyles } from "@material-ui/core";
import { getStorageData } from "../../../framework/src/Utilities";
import { Message } from "../../../framework/src/Message";

interface CartDetails{    
      id:string,
      seller_details:{
        attributes:{
            upi_id:string | null,
            phone_number:number | null
        }
      },
      total: number
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles{
    navigation: any;
    id: string;
    // Customizable Area Start
   
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    openPaymentSuccessModal:boolean,
    loading: boolean,
    cartDetails:CartDetails
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export default class BuyerQrCodePaymentController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    confirmOrderApiCallId:string=""
    getCartItemsApiCallId:string=""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area End
        ];

        // Customizable Area Start
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
     
        // Customizable Area End

        this.state = {
            // Customizable Area Start
            openPaymentSuccessModal:false,
            loading: false,
            cartDetails:{
                id:"",
                seller_details: {
                    attributes: {
                        upi_id: null,
                        phone_number:null
                    }
                },
                total: 0
            }
            // Customizable Area End
        };

    }

    // Customizable Area Start

    async componentDidMount() {
        this.getCartDetails();
    }


    async receive(from: string, message: Message) {
        this.handleCartDetailsResponse(from, message)
        this.handleConfirmOrderResponse(from, message)
    }

    handleCartDetailsResponse=(from: string, message: Message)=>{
        if (this.getCartItemsApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiResponse.data) {
                this.setState({cartDetails: apiResponse.data[0].attributes, loading: false});
            }
        }
    }

    handleConfirmOrderResponse=(from: string, message: Message)=>{
        if (this.confirmOrderApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiResponse?.data) {
                this.setState({openPaymentSuccessModal: true});
            }
        }
    }

    getCartDetails = async () => {
        this.setState({
            loading: true
        })
        const header = { "Content-Type": "application/json", "token": await getStorageData("buerLoginToken") };

        const getCartRequestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getCartItemsApiCallId = getCartRequestMessage.messageId;
        getCartRequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),configJSON.cartDetailsEndPoint);
        getCartRequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        getCartRequestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
        runEngine.sendMessage(getCartRequestMessage.id, getCartRequestMessage);
    }

    handleConfirmOrder=async()=>{
        const cartID = this.state.cartDetails?.id

        const httpBody = {"id": cartID}

        const header = { "Content-Type": "application/json", "token": await getStorageData("buerLoginToken") };

        const confirmRequestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.confirmOrderApiCallId = confirmRequestMessage.messageId;
        confirmRequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),configJSON.confirmOrderEndPoint);
        confirmRequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        confirmRequestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
        confirmRequestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.putMethod);
        runEngine.sendMessage(confirmRequestMessage.id, confirmRequestMessage);
    }

    closeOrderSuccessDialogBox = () => {
        this.setState({
            openPaymentSuccessModal: false,
        });
    }

    handleHomePageNavigation = () => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage),"BuyerDashboard");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message)
    }

    navigateToOrderList = () => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage),"CustomisedOrderStatus");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message)
    }

    handleShareReceipt=()=>{
        const phoneNumber = this.state.cartDetails?.seller_details?.attributes?.phone_number
        const whatsappLink = `https://api.whatsapp.com/send?phone=${phoneNumber}`
        window.open(whatsappLink, '_blank')
    }

    
    // Customizable Area End
}