import { getStorageData } from "framework/src/Utilities";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
const navigation = require('react-navigation');

interface ApiCallPayloadType{
    contentType?: string;
    method: string;
    endPoint: string;
    body?: object;
    type?: string;
}

interface PaymentDetailPayload{
    payment_status: string;
}

interface OrderStatusApiRes{
    status: number;
    message: string;
    payment_detail: PaymentDetailPayload;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: typeof navigation;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    isSuccessModal: boolean;
    isErrorModal: boolean;
    isLoading: boolean;
    orderId: number | string;
    errorMessage: string;
    // Customizable Area End
}

interface SS {
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export default class CashFreeOrderStatusController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getOrderPaymentStatus: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceDataMessage),
            getName(MessageEnum.NavigationMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIResponceSuccessMessage),
            getName(MessageEnum.NavigationTargetMessage),
            getName(MessageEnum.NavigationPropsMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            isSuccessModal: false,
            isErrorModal: false,
            isLoading: false,
            orderId: '',
            errorMessage: 'Order is not placed successfully.'
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            )
            if (responseJson.status === 500) {
                this.showAlert("Error", "Internal Server Error");
                this.setState({ isLoading: false });
                return;
            }
            if (responseJson && !responseJson.errors) {
                if(this.getOrderPaymentStatus == apiRequestCallId){
                    this.orderSuccessCallBack(responseJson);
                }
            } else if (responseJson && responseJson.errors) {
                this.getAllErrorCallBack();
            }
        }
        // Customizable Area End
    }


    // Customizable Area Start
    async componentDidMount() {
        const array = window.location.pathname.split("/");
        const orderId = array[array.length - 1];
        this.setState({ orderId: orderId }, () => {
            this.getOrderPaymentPlacedStatus();
        });
    };
    
    getOrderPaymentPlacedStatus = async () => {
       this.getOrderPaymentStatus = await this.apiCall({
        contentType: configJSON.apiContentType,
        method: configJSON.putApiMethod,
        endPoint: configJSON.getOrderStatusEndPoint,
        body: {
        "id": String(this.state.orderId),
        },
        type: ""
       })
    };

    apiCall = async (apiReqData: ApiCallPayloadType) => {
        const { contentType, method, endPoint, body, type } = apiReqData;
        this.setState({ isLoading: true });
        const buyerToken = await getStorageData('buerLoginToken');
        const header = {
            token: buyerToken,
            "Content-Type": contentType,
        };
        const requestApiMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        requestApiMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));
        requestApiMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),method);
        requestApiMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),endPoint);
        body && type != 'formData' ?
        requestApiMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)) : 
        requestApiMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),body);
        runEngine.sendMessage(requestApiMessage.id, requestApiMessage);
        return requestApiMessage.messageId;
    };

    orderSuccessCallBack = (responseJson: OrderStatusApiRes) => {
        this.setState({ isLoading: false });
        if (responseJson.payment_detail && responseJson.payment_detail.payment_status === "SUCCESS") {
            this.setState({ isSuccessModal: true });
        } else if (responseJson.message || (responseJson.payment_detail && responseJson.payment_detail.payment_status === "NOT_ATTEMPTED")) {
            this.goToBuyerHome();
        } else if (responseJson.payment_detail && responseJson.payment_detail.payment_status === "FAILED") {
            this.setState({ isErrorModal: true });
        }
        this.setState({ isLoading: false });
    };

    getAllErrorCallBack = () => {
        this.setState({ isLoading: false, isErrorModal : true });
    };

    goToBuyerShoppingCart = (cartId: number) => {
        const shoppingCartMessage: Message = new Message(getName(MessageEnum.NavigationMessage));
        shoppingCartMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        shoppingCartMessage.addData(getName(MessageEnum.NavigationTargetMessage), "BuyerShoppingCart");
        shoppingCartMessage.addData(getName(MessageEnum.NavigationScreenNameMessage), cartId);
        this.send(shoppingCartMessage);
    };

    goToBuyerHome = () => {
        const homeMessageData: Message = new Message(getName(MessageEnum.NavigationMessageLandingPage));
        homeMessageData.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        homeMessageData.addData(getName(MessageEnum.NavigationScreenNameMessage), "BuyerDashboard");
        this.send(homeMessageData);
    };

    goToOrderHistory = () => {
        const messageData: Message = new Message(getName(MessageEnum.NavigationMessageLandingPage));
        messageData.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        messageData.addData(getName(MessageEnum.NavigationScreenNameMessage), "CustomisedOrderStatus");
        this.send(messageData);
    };
    // Customizable Area End
}