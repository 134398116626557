import BuyerShoppingCartController from "../../blocks/shoppingcart/src/BuyerShoppingCartController.web";
import EmailAccountRegistrationController from "../../blocks/email-account-registration/src/AccountRegistrationController";

export const checkLocationPermission = (innerState: {setState:Function}) => {
    if (navigator.permissions) {
        navigator.permissions.query({ name: 'geolocation' }).then((result) => {
            if (result.state === 'granted') {
                innerState.setState({ permissionStatus: 'Granted' })
                getLocation(innerState);
            } else if (result.state === 'prompt') {
                innerState.setState({ permissionStatus: 'Prompting' });
                getLocation(innerState);
            } else if (result.state === 'denied') {
                innerState.setState({
                    errorMessage: 'Location access denied. Please enable location access in your browser settings.',
                    permissionStatus: 'Denied',
                });
            }

            result.onchange = () => {
                if (result.state === 'granted') {
                    innerState.setState({ permissionStatus: 'Granted' });
                    getLocation(innerState);
                } else if (result.state === 'denied') {
                    innerState.setState({
                        errorMessage: 'Location access denied. Please enable location access in your browser settings.',
                        permissionStatus: 'Denied',
                    });
                }
            };
        });
    } else {
        getLocation(innerState);
    }
};

export const getLocation = (instance: {setState:Function}) => {
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
            position => {
                instance.setState({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                    errorMessage: '',
                });
            },
            error => {
                instance.setState({ errorMessage: error.message });
            }
        );
    } else {
        instance.setState({ errorMessage: 'Geolocation is not supported by this browser.' });
    }
};

export const conditionalRenderer = (condition: boolean, trueStamt: string, falseStmt: string) => {
    return condition ? trueStamt : falseStmt
}