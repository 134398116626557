import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { getStorageData } from "framework/src/Utilities";
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  favourites: any;
  isVisible: boolean;
  activeId: number;
  favouriteId: number;
  favouriteType: string;
  activeCreatedAt: string;
  activeUpdatedAt: string;
  title: string;
  content: string;
  value: any;
  loading: boolean;
  wishListLiveStream: any
  wishListProduct: any
  wishListLiveStreamFavData: any
  wishListProductFavData: any
  message: string;
  messageModel: boolean;
  showSidebar: boolean;
  showingAll: boolean;
  showAllLiveEvents: boolean;
  openModalFilter: boolean;
  wishListFilter: string;
  searchText: string
  // Customizable Area End
}

interface SS {
  id: any;
   // Customizable Area Start
  // Customizable Area End
}

export default class FavouritesController extends BlockComponent<Props, S, SS> {
  value: any;
  editorState: any;
  favouritesApiCallId: any;
  deleteFavouritesApiCallId: any;
  addFavouritesApiCallId: any;
  richtext: any;
  editor: any;
  onChange: (editorState: any) => void;
  setEditor: (editor: any) => void;
  focusEditor: () => void;

  // Customizable Area Start
  apiTopSearch: string = ''
    apiSubCategoriers: string = ''
    apigetWishListData: string = ''
    addItemInCartApiCallId: string = ''
    apiAddToWishList: string = ''
    apiDeleteFromWishList: string = ''
    timer: any
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.editorState = null; //createEditorStateWithText("");

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.timer = null;

    this.state = {
      token: "",
      favourites: [],
      isVisible: false,
      activeId: 0,
      favouriteId: 0,
      favouriteType: "",
      activeCreatedAt: "",
      activeUpdatedAt: "",
      title: "",
      content: "",
      value: this.value,
      loading: false,
      wishListLiveStream: [],
      wishListProduct: [],
      wishListLiveStreamFavData: [],
      wishListProductFavData: [],
      message: '',
      messageModel: false,
      showSidebar: false,
      showingAll: false,
      showAllLiveEvents: false,
      openModalFilter: false,
      wishListFilter: '',
      searchText: '',
      // Customizable Area End
    };
    this.onChange = (value) => {
      this.value = value;
      this.setState({ value: value });
    };
    this.setEditor = (editor) => {
      this.editor = editor;
    };
    this.focusEditor = () => {
      if (this.editor) {
        this.editor.focus();
      }
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }
  async componentDidMount() {
    // Customizable Area Start
        this.getTopSearchData('')
        this.getWishlistData();
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      runEngine.debugLog("Message Recived", message);

      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token });
      this.getFavourites(token);
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      runEngine.debugLog("API Message Recived", message);
      if (responseJson && responseJson.data) {
        if (apiRequestCallId === this.favouritesApiCallId) {
          this.setState({ favourites: responseJson.data });
        }
        if (apiRequestCallId === this.addFavouritesApiCallId) {
          this.props.navigation.goBack();
        }
      }

      switch (apiRequestCallId) {
        case this.apiTopSearch:
          this.setState({
            loading: false,
            wishListLiveStreamFavData: responseJson?.wishlist_items?.live_stream_ids,
            wishListProductFavData: responseJson?.wishlist_items?.product_ids
          })
          break;
        case this.apigetWishListData:
          this.setState({
            loading: false,
            wishListLiveStream: responseJson?.live_streams,
            wishListProduct: responseJson?.products
          })
          break;
        case this.addItemInCartApiCallId:
          this.checkAddtoCart(responseJson);
          break;
        case this.apiAddToWishList:
          this.checkItemAddedWishList(responseJson);
          break;
        case this.apiDeleteFromWishList:
          this.checkItemRemovedWishList(responseJson);
          break;
        default:
          break;
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  setModal = (item: any) => {
    this.setState({
      activeId: item.id,
      favouriteId: item.attributes.favouriteable_id,
      favouriteType: item.attributes.favouriteable_type,
      activeCreatedAt: item.attributes.created_at,
      activeUpdatedAt: item.attributes.updated_at,
      isVisible: !this.state.isVisible,
    });
  };
  hideModal = () => {
    this.setState({ isVisible: !this.state.isVisible });
  };

  addFavourites() {
    //@ts-ignore
    this.props.navigation.navigate("AddFavourites");
  }

  setType = (txt: string) => {
    this.setState({ favouriteType: txt });
  };

  setID = (txt: string) => {
    this.setState({ favouriteId: Number(txt) });
  };

  deleteFavorite(id: number) {
    this.deleteFavouritesApiCall(configJSON.favouritesApiEndPoint + `/${id}`);
  }

  addFavouritesCall = () => {
    if (this.state.favouriteType.trim() === "") {
      this.showAlert(configJSON.configError, configJSON.configErrorType);
      return false;
    } else if (this.state.favouriteId <= 0) {
      this.showAlert(configJSON.configError, configJSON.configErrorId);
      return false;
    } else {
      var data = {
        favouriteable_id: this.state.favouriteId,
        favouriteable_type: this.state.favouriteType,
      };

      const header = {
        "Content-Type": configJSON.favouritesApiApiContentType,
        token: this.state.token,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addFavouritesApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.favouritesApiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify({ data: data })
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };

  deleteFavouritesApiCall = (endPointURL: string) => {
    const header = {
      "Content-Type": configJSON.favouritesApiApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteFavouritesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPointURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getFavourites = (token: string) => {
    const header = {
      "Content-Type": configJSON.favouritesApiApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.favouritesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.favouritesApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleModalFilter = () => {
    this.setState({ openModalFilter: !this.state.openModalFilter });
  };

  showFilter = () => {
    this.getWishlistData();
    this.handleModalFilter();
  }

  clearFilter = () => {
    this.setState({ wishListFilter: '' });
    this.handleModalFilter();
    this.getWishlistData();
  }

  handleFilterChange: any = (event: any) => {
    this.setState({ wishListFilter: event.target.value, searchText: '' });
  };

  handleChangeSearch = (event: any) => {
    const { value } = event.target;
    this.setState({ searchText: value })
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.handleSubmitSearchFilter(event)
    }, 500)
  };

  handleSubmitSearchFilter = (e: any) => {
    e.preventDefault();
    this.getWishlistData();
  }

  checkAddtoCart = (responseJson: any) => {
    if (responseJson.message) {
      this.setState({
        message: responseJson.message,
        loading: false,
        messageModel: true
      })
    } else if (responseJson.errors) {
      this.setState({
        message: responseJson.errors,
        loading: false,
        messageModel: true
      })
    }
  }

  checkItemAddedWishList = (responJson: any) => {
    if (responJson.message) {
      this.getTopSearchData('');
      this.closeMessageModal();
      this.setState({
        message: responJson.message
      })
    } else {
      this.setState({ message: responJson.errors })
      this.closeMessageModal();
    }
  }

  checkItemRemovedWishList = (responJson: any) => {
    if (responJson.message) {
      this.getTopSearchData('');
      this.closeMessageModal();
      this.setState({ message: responJson.message })
      this.getWishlistData()
    } else {
      this.setState({
        message: responJson.errors[0]
      })
      this.closeMessageModal();
    }
  }

  closeMessageModal = () => { this.setState({ messageModel: !this.state.messageModel }) }

  toggleDrawer = (open: boolean) =>
    (event: any) => {
      if (
        event.type === 'keydown' &&
        (
          (event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
      ) { return; }
      this.setState({ showSidebar: open });
    };

  showALL = () => {
    this.setState({
      showingAll: !this.state.showingAll,
      showAllLiveEvents: !this.state.showAllLiveEvents,
    });
  }

  checkWishStatus = (producArray: any, ID: any) => {
    const index = producArray.findIndex(
      (item: any) => item.live_stream_id === ID
    );
    if (index !== -1) {
      return true;
    } else {
      return false;
    }
  }

  deleteWishItem = (producArray: any, ID: any) => {
    const index = producArray.find(
      (item: any) => item.live_stream_id === ID).favourite_id;

    if (index) {
      this.deleteWishListItem(index)
    } else { return false; }
  }

  checkProductStatus = (producArray: any, ID: any) => {
    const index = producArray.findIndex(
      (item: any) =>
        item.product_id === ID);
    if (index !== -1) { return true; } else {
      return false;
    }
  }

  deleteProductItem = (producArray: any, ID: any) => {
    const index = producArray.find((item: any) => item.product_id === ID).favourite_id;

    if (index) {
      this.deleteWishListItem(index)
    } else {
      return false;
    }
  }

  getTopSearchData = async (subCateQueryUrl: string) => {
    this.setState({ loading: true });
    const headers = {
      token: await getStorageData('buerLoginToken'),
      "Content-Type": configJSON.favouritesApiApiContentType,
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiTopSearch = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getTopSearchEndPoint}${subCateQueryUrl}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpGetMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headers
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getWishlistData = async () => {
    this.setState({ loading: true });
    const headers = {
      token: await getStorageData('buerLoginToken'),
      "Content-Type": configJSON.favouritesApiApiContentType,
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apigetWishListData = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.favouriteItemEndPoint}?type=${this.state.wishListFilter}&search=${this.state.searchText}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage), headers
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  addItemInCart = async (item: any) => {
    this.setState({ loading: true });
    const httpBody = {
      "quantity": 1,
      "catalogue_variant_id": Number(item.attributes.catalogue_variants[0].id),
      "catalogue_id": Number(item.id),
    }

    const header = {
      "token": await getStorageData("buerLoginToken"),
      "Content-Type": configJSON.favouritesApiApiContentType,
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.addItemInCartApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.addtoCartEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  updateWishListItem = async (id: number, type: string) => {
    const buyertoken = await getStorageData("buerLoginToken");
    if (buyertoken) {
      const header = {
        "Content-Type": "application/json",
        "token": buyertoken,
      };
      const body = {
        "data": {
          "type": type,
          "favouriteable_id": id,
        }
      }

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.apiAddToWishList = requestMessage.messageId;


      requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );

      requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.favouriteItemEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  deleteWishListItem = async (id: number) => {
    const buyertoken = await getStorageData("buerLoginToken");
    if (buyertoken) {
      const header = {
        "Content-Type": "application/json",
        "token": buyertoken,
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage));

      this.apiDeleteFromWishList = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.favouriteItemEndPoint}/${id}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpDeleteMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }
  // Customizable Area End
}
