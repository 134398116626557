import React from "react";
// Customizable Area Start
import AddNewProductController, { Props, configJSON } from "./AddNewProductController.web";
import { createTheme, withStyles, Theme } from "@material-ui/core/styles";
import { styled, Stepper, Step, StepLabel, Box, Grid, Typography,IconButton} from "@material-ui/core";
import UploadProduct from "./UploadProduct.web";
import ListingProduct from "./ListingProduct.web";
import { backButton,hamburgerIc } from "./assets"
import ShipmentProduct from "./ShipmentProduct.web";
import SellerSidebar from "../../landingpage/src/SellerSidebar.web";
import PackagingDetails  from "./PackagingDetails.web";
import ManufacturingDetails from "./ManufacturingDetails.web";

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
});

export const webStyles = (theme: Theme) => ({
    sideBar:{
        filter: 'drop-shadow(6px 0px 20px rgba(0, 0, 0, 0.05))',
        height:'100vh',
        background:"gray",
    },
    customStepperContainer:{
        width:"1010px"
    },
    label:{
        color: '#444',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '14px',
    },
    backButtonNavigation:{
        display:"flex",
        alignItems:"center",
        marginLeft:"4rem",
        marginBottom:"1em"
    },
    title:{
        color: 'rgba(68, 68, 68, 0.80)',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: 'Poppins',
        fontSize: '20px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
        marginLeft:"0.45em"
    },
    addNewproductMaincontainer:{
        margin:"5em 0em 0em",
        display:"flex"
    },
    [`@media (min-width: 992px)`]: {
        hideSideHamburger: {
          display: 'none'
        },
    },
    hideSideHamburgerContainer:{
        display:"flex",
        justifyContent:"end"
    }

});
const CustomStepper = styled(Stepper)({
    '& .MuiStepLabel-root': {
        color: 'orange',
    },
    '& .MuiStepIcon-root': {
        color:"white",
        border:"1px solid gray",
        borderRadius: '50%',
        fontSize: '2rem',
        '& .MuiStepIcon-text': {
            fill: '#444444',
        },
    },
    '& .MuiStepIcon-root.MuiStepIcon-active': {
        color: '#F5EA16',
        borderRadius: '50%',
        fontSize: '2rem',
        fontWeight:500,
        '& .MuiStepIcon-text': {
            fill: '#444444',
        },
    },

    '& .MuiStepIcon-completed': {
        color: '#F5EA16',
        borderRadius: '50%',
        fontSize: '2rem',
        background:"#444444",
        '& .MuiStepIcon-text': {
            fill: '#444444'
        },
    },
});
// Customizable Area End

export class AddNewProduct extends AddNewProductController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    // Customizable Area End
    render() {
        // Customizable Area Start
        const {activeStep} = this.state
        const {classes} = this.props
        return (
            <Box data-test-id="mainContainer" className={classes.addNewproductMaincontainer}>
                <div className="dashboard-outer">
                <div ref={this.rootNewProductRef}></div>
                            <div className="dashboard-inner">
                            <SellerSidebar
                                isOpen={this.state.isOpen}
                                data-test-id="sellerSidebar"
                                activetabName= "inventory"
                                closeModal={this.toggleSideDrawer}
                                navigation={""}
                                id={""}
                                classes={undefined}
                                />
                            </div>
                        </div>
                <Grid container>
                    <Grid item xs={12}>

                        <Box style={{paddingTop:"5em"}}>
                            <Box>
                                <Box className={classes.customStepperContainer}>
                                    <Box className={classes.backButtonNavigation}>
                                        <Box m={0}><img src={backButton} alt="backButton" onClick={()=>this.handleBackNavigation()}/></Box>
                                        <Typography className={classes.title}>{this.state.isEdit ?configJSON.editTitle : configJSON.title}</Typography>
                                    </Box>
                                    <CustomStepper activeStep={activeStep} alternativeLabel>
                                        {configJSON.steps.map((eachObject:any) => (
                                            <Step key={eachObject.id}>
                                                <StepLabel className={classes.label}>{eachObject.label}</StepLabel>
                                            </Step>
                                        ))}
                                    </CustomStepper>
                                </Box>
                                {activeStep === 0 && <UploadProduct navigation={this.props.navigation} id={this.props.id} handleActiveStep={this.handleActiveStep} />}
                                {activeStep === 1 && <ListingProduct navigation={this.props.navigation} id={this.props.id} handleActiveStep={this.handleActiveStep} />}
                                {activeStep === 2 && <ShipmentProduct handleActiveStep={this.handleActiveStep}/>}
                                {activeStep === 3 && <PackagingDetails handleActiveStep={this.handleActiveStep}/> }
                                {activeStep === 4 && <ManufacturingDetails handleActiveStep={this.handleActiveStep} navigation={this.props.navigation} /> }
                            </Box>
                        </Box>
                    </Grid>
                </Grid>

            </Box>
        );
        // Customizable Area End
    }
}


// Customizable Area Start
export default withStyles(webStyles)(AddNewProduct)
// Customizable Area End