import React from 'react';

interface DateStyleProps {
    content: string;
}

export default function EffectiveDateFormat(props: DateStyleProps) {
    let final = props.content.split(/<sup>(.*?)<\/sup>/)
    return (
        <>{final[0]}<sup>{final[1]}</sup>{final[2]}</>
    )
  }