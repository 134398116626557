import React from "react";
// Customizable Area Start
import UploadProductController ,{Props, configJSON} from "./UploadProductController.web";
import { createTheme,  withStyles, Theme } from "@material-ui/core/styles";
import {Button,Typography,Box,Grid} from "@material-ui/core";
import {exclamatoryIcon, uploadIcon,removeIcon, imageAddButton,videoAddButton} from "./assets"
import MessageModalWeb from "../../../components/src/MessageModal.web";

let config = require('../../../framework/src/config')

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
});

export const webStyles = (theme: Theme) => ({
    imageSection:{
        height:"auto",
        border: '1px dashed rgba(68, 68, 68, 0.60)',
        borderRadius:"8px",
        padding:"1em",
        maxWidth: "100%"
    },

    validtionInfoContainer:{
        background:'rgba(245, 234, 22, 0.10);',
        marginTop:"1em",
        borderRadius:"8px",
        padding:"1.5em 0.75em"
    },
    infoIcon:{
        marginTop:"0.25rem"
    },
    sizeText:{
        marginLeft:"0.35rem"
    },
    uploadImagesMainContainer:{
        padding:"0em 5em"
    },
    imageDefaultPreviewCard:{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
    },
    uploadHereText:{
        color: '#555',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 500,
    },
    uploadText:{
        color: '#555',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 600,
        textDecorationLine: 'underline',
    } ,  
    previewImagesSection:{ 
        position: "relative" as const,
        margin:"0em 0.5em" 
    },
    previewVideosSection:{ 
        position: "relative" as const,
        margin:"0em 0.5em" 
    },
    eachPreviewImage:{
         width: "100%",
         height: "8.5em",
    },
    eachvideoPreviewImage:{
        width: "100%",
        height: "8.5em",
        objectFit:"cover" as const
    },
    removeIcon:{
        position: "absolute" as const, 
        top:"50%",
        right: "50%"
    },
    infoTitle:{
        color: 'rgba(68, 68, 68, 0.70)',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight:500,
        lineHeight: 'normal',
    },
    infoValue:{
        color: 'rgba(68, 68, 68, 0.70)',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: 'Poppins',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 300,
        lineHeight: 'normal',
    },
    uploadTitle:{
        color: '#555',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: 'normal',
        marginTop:"3em",
        marginBottom:"1em"
    },
    flexContainer:{
        display:'flex'
    },
    buttonContainer:{
        display:"flex",
        justifyContent:"flex-end",
        paddingTop:"8em",
        paddingBottom:"2em",
        width:"100%"
    },
    saveButton:{
        width: '21em',
        height: '3em',
        background: '#F5EA16',
        borderRadius:"25px",
        color: '#444',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
        pointer:"cursor",
        textTransform:"none" as const,
        "&:hover":{
            background: '#F5EA16',
        }
    },
  defaultAddButton:{
     marginTop:"0.5em"
  }
    
});
// Customizable Area End

export class UploadProduct extends UploadProductController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    // Customizable Area End
    render() {
        // Customizable Area Start
        const {classes} = this.props
        const {selectedImages,selectedVideos} = this.state
        const saveButtondisable = selectedImages.length 
        return (
        <Box className={classes.uploadImagesMainContainer} data-test-id="UploadProductMainContainer">
            <Box>
            <Typography className={classes.uploadTitle}>{configJSON.uploadImages}({selectedImages.length}/{configJSON.imageLimit})</Typography>
            <Box>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={8}>
                <Box className={classes.imageSection}>
                <input
                        ref={this.inputRef}
                        type="file"
                        accept=".jpg, .png"
                        onChange={this.handleImageChange}
                        style={{ display: "none" }}
                        id="imageInput"
                        multiple
                        data-test-id="imageInput"
                    />
                { selectedImages.length === 0 &&
                <>
                                <label htmlFor="imageInput">
                                    <Box className={classes.imageDefaultPreviewCard}>
                                        <Box>
                                            <img src={uploadIcon} />
                                            <Typography  className={classes.uploadHereText}>
                                                <Typography component="span" className={classes.uploadText}>
                                                {configJSON.upload}
                                                </Typography>
                                                {" "}
                                                {configJSON.here}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </label>
                </>}
                { selectedImages.length > 0 &&
                <>  
                <Grid container spacing={2} data-test-id="imageGrid" >
                            {selectedImages?.map((image: any, index: number) => 
                          {  
                            return(
                                <Grid key={index}
                                    item
                                    xs={12} 
                                    md={4} 
                                    lg={2}
                                > 
                                    <Box key={index} className={classes.previewImagesSection}   >
                                                    <img
                                                        src={image.url || URL.createObjectURL(image)}
                                                        alt={`${configJSON.preview} ${index + 1}`}
                                                    className={classes.eachPreviewImage}
                                                    />
                                        <Box
                                            className={classes.removeIcon}
                                            onClick={() => this.handleRemoveImage(index)}
                                            data-test-id={`removeImage${index}`}
                                        >
                                            <img src={removeIcon} alt="removeIcon"  />
                                        </Box>
                                    </Box>
                                </Grid>
                            )
                          }
                            )}
                            {selectedImages.length < 6 && (
                                <label htmlFor="imageInput2" onClick={this.handleAddImageClick} data-test-id="imageAddButton">
                                    <img src={imageAddButton} alt="imageAddButton" className={classes.defaultAddButton} />
                                </label>
                                )}
                        </Grid>  
                </>
                 }
                    </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                    <Box className={classes.validtionInfoContainer} >
                        <Box className={classes.flexContainer}>
                            <Box component="span">
                            <img src={exclamatoryIcon} className={classes.infoIcon}/>
                            </Box>
                            <Box>
                            <Box className={classes.sizeText}>
                                <Typography component="span" className={classes.infoTitle}>{configJSON.sizeMax}</Typography>
                                <Typography component="span" className={classes.infoValue}>{configJSON.imageSizeValue}</Typography>
                            </Box>
                            <Box>
                                <Typography component="span" className={classes.infoTitle}>{configJSON.format}</Typography>
                                <Typography component="span" className={classes.infoValue}>{configJSON.imageFormatValue}</Typography>
                            </Box>
                            </Box>
                        </Box>
                        
                    </Box>
                    </Grid>
                </Grid>
            </Box>
            </Box>
            <Box>
            <Typography className={classes.uploadTitle}>{configJSON.uploadVideos}({selectedVideos.length}/{configJSON.videoLimit})</Typography>
            <Box>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={8}>
                <Box className={classes.imageSection}>
                    <input
                        ref={this.videoInputRef}
                        type="file"
                        accept="video/*"
                        onChange={this.handleVideoChange}
                        style={{ display: "none" }}
                        id="videoInput"
                        multiple
                        data-test-id="videoInput"
                    />
                { selectedVideos.length === 0 &&
                <>
                                <label htmlFor="videoInput">
                                    <Box className={classes.imageDefaultPreviewCard}>
                                        <Box>
                                            <img src={uploadIcon} />
                                            <Typography  className={classes.uploadHereText}>
                                                <Typography component="span" className={classes.uploadText}>
                                                {configJSON.upload}
                                                </Typography>
                                                {" "}
                                                {configJSON.here}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </label>
                </>}
                                {selectedVideos.length > 0 && (
                                    <Box>
                                            <Grid container spacing={2} data-test-id="videoGrid">
                                                {selectedVideos?.map((video: any, index: number) => {
                                                    
                                                    return(
                                                    <Grid key={index} item xs={12} md={4} lg={4}>
                                                        <Box key={index} className={classes.previewVideosSection}>
                                                        <video src={video.url || URL.createObjectURL(video)} className={classes.eachvideoPreviewImage}/>
                                                            <Box
                                                                className={classes.removeIcon}
                                                                onClick={() => this.handleRemoveVideo(index)}
                                                                data-test-id={`removeVideo${index}`}
                                                            >
                                                                <img src={removeIcon} alt="removeIcon" />
                                                            </Box>
                                                        </Box>
                                                  </Grid>
                                                     )})}
                              
                                {selectedVideos.length < 2 && (
                                    <label htmlFor="videoInput" onClick={this.handleAddVideoClick} data-test-id="videoAddButton">
                                        <img src={videoAddButton} alt="videoAddButton" className={classes.defaultAddButton} />
                                    </label>
                                )}
                            </Grid>
                    
                    </Box>
                )}
                </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                    <Box className={classes.validtionInfoContainer} >
                        <Box className={classes.flexContainer}>
                            <Box component="span">
                            <img src={exclamatoryIcon} className={classes.infoIcon}/>
                            </Box>
                            <Box>
                            <Box className={classes.sizeText}>
                                <Typography component="span" className={classes.infoTitle}>{configJSON.sizeMax}</Typography>
                                <Typography component="span" className={classes.infoValue}>{configJSON.videoSizeValue}</Typography>
                            </Box>
                            <Box>
                                <Typography component="span" className={classes.infoTitle}>{configJSON.format}</Typography>
                                <Typography component="span" className={classes.infoValue}>{configJSON.videoFormatValue}</Typography>
                            </Box>
                            </Box>
                        </Box>
                        
                    </Box>
                    </Grid>
                </Grid>
            </Box>
            </Box>
            <Box className={classes.buttonContainer}>
            <Button data-test-id="saveButton" className={classes.saveButton} onClick={()=>this.handleSaveButton()} disabled={!saveButtondisable}>{configJSON.save}</Button>
            </Box>
            <MessageModalWeb data-test-id="errorMessage" displayPopup={this.state.errorModal} closeErrorPopup={this.errorModalClose} errorMessage={this.state.errorMessage} />
            </Box>
        );
        // Customizable Area End
    }
}


// Customizable Area Start
export default withStyles(webStyles)(UploadProduct)
// Customizable Area End