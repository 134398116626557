import React from "react";
// Customizable Area Start
import StoreViewController, { Props ,configJSON,Catalogue,LiveStream,StoreAddress} from "./StoreViewController.web";
import { createTheme, withStyles, Theme,styled} from "@material-ui/core/styles";
import {  Box, Grid, Button,Typography,Divider,Radio} from "@material-ui/core";
import {blackTickIcon,ratingIcon,locationIcon,phoneIcon,emailIcon,codImg,onOrderImg,bargainImg,bookAppointmentIcon,liveCount} from "./assets"
import StayTuned from "../../../components/src/StayTuned.web";
import MessageModalWeb from "../../../components/src/MessageModal.web";
import Loader from "../../../components/src/Loader.web";



const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
});

interface BlackRadioProps {
  checked: boolean;
 
}

export const webStyles = (theme: Theme) => ({
    mainContainer: {
        margin: "9em 5em 0em",
        [theme.breakpoints.down('md')]: {
          margin: "5em 3em 0em",
        },
        '@media (max-width: 600px)': {
         margin:"0"
        },
      },
      storeDetailsContainer: {
        position: "relative" as const,
        width: "100%",
        '@media (max-width: 600px)': {
          width:"100%"
         },
      },
      storeImage: {
        width: "100%",
        height: "13.25em",
        objectFit: "cover" as const,
      },
      storeInfoBox: {
        position: "absolute" as const,
        top: "95%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "90%",
        borderRadius: "15px 15px 0px 0px",
        background: "#ffffff",
        margin: "2em 0em",
        padding: "1em",
        boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.1)",
        '@media (max-width: 600px)': {
          left: "47.5%",
         }, 
      },
      buttonContainer: {
        margin: "8em 0em 3em 2em",
        '@media (max-width: 600px)': {
          margin: "7em 0em 2em 1em",
        },
      },
      storeInfoUpperSection:{
        display:'flex',
        justifyContent:"space-between"
      },
      storeInfoMiddleSection:{
        display:'flex',
        margin:"1em 0em",
        flexWrap: "wrap" as const
      },
      ratingContainer:{
        display:'flex',
        alignItems:"center",
        height:"auto",
        color: '#2E2E2E',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: 'Poppins !important',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '16px',
      },
      storeName:{
        display:'flex',
        alignItems:"center",
        color: '#2E2E2E',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: 'Poppins !important',
        fontSize: '20px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '30px',
      },
      blackTickIcon:{
        height:"24px",
        width:'24px',
        marginLeft:"0.5em"
      },
      ratingIcon:{
        height:"16px",
        width:"15.87px",
        marginRight:"0.35em",
      },
      storeInfoMiddleSectionIcon:{
        height:"18px",
        width:"18px",
        marginRight:"0.25em"
      },
      storeInfoMiddleSectionBox:{
        display:'flex',
        alignItems:"center",
        height:"auto",
        color: '#6E6E6E',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: 'Poppins !important',
        fontSize: '0.9em',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '1em',
        textDecoration: 'underline',
        [theme.breakpoints.down('md')]: {
          fontSize: '0.5em',
        },
      },
      divider:{
        margin:"0em 0.5em"
      },
      codImage:{
        height:"30px",
        width:"84px",
        '@media (max-width: 600px)': {
          height:"22px",
          width:"57px",
        },
      },
      onOrderImage:{
      height:"30px",
      width:"84px",
      margin:"0em 1em",
      '@media (max-width: 600px)': {
        height:"22px",
        width:"57px",
      },
    },
    visitSellerButton:{
      width: '10em',
      height: '2.7em',
      background: '#F5EA16',
      borderRadius:"22px",
      color: '#444',
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontFamily: 'Poppins',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: 'normal',
      pointer:"cursor",
      textTransform:"none" as const,
      marginRight:"1em",
      "&:hover":{
          background: '#F5EA16',
      },
      '@media (max-width: 600px)': {
        width: '8em',
        height: '2.3em',
        fontSize: '12px',
      },
    },
    bookAppointmentButton:{
      width: '13.6em',
      height: '2.7em',
      background: 'none',
      border:'1px solid black',
      borderRadius:"22px",
      color: '#444',
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontFamily: 'Poppins',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: 'normal',
      pointer:"cursor",
      textTransform:"none" as const,
      
      
      "&:hover":{
          background: 'none'
      },
      '@media (max-width: 600px)': {
        width: '14em',
        height: '2.3em',
        fontSize: '12px',
      },
      "& .MuiButton-startIcon":{
        marginRight:"0"
      }
    },
    productsContainer:{
      marginTop:"1em",
      '@media (max-width: 600px)': {
        marginLeft:"1em"
      },
    },
    productsTitle:{
      color: '#2E2E2E',
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontFamily: 'Poppins !important',
      fontSize: '20px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '26px',
    },
    productGrid: {
      display: "flex",
      flexWrap: "wrap" as const,
      justifyContent: "flex-start",
      marginTop: "2em",
      '@media (max-width: 600px)': {
        flexWrap: "nowrap" as const,
        overflowX: 'auto',
        marginTop: "0",
        width:"100vw"
      },
    },
    productContainer: {
      border: "1px solid #ddd",
      borderRadius: "8px",
      textAlign: "center" as const,
      margin: "1em 1em 1em 0",
      width: "100%", 
      maxWidth: "calc(25% - 16px)",
      paddingBottom: "1em",
      cursor:"pointer",
      [theme.breakpoints.down('sm')]: {
        maxWidth: "calc(45% - 16px)",
      },
      [theme.breakpoints.down('xs')]: {
        maxWidth: "150px",
        flex: "0 0 auto",
      },
    },
    productImage: {
      width: "100%",
      height: "10.8em",
      objectFit:"fill" as const,
      borderRadius: "5px"
    },

    productName: {
      color: '#4E4E4E',
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontFamily: 'Poppins !important',
      fontSize: '18px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '27px',
      margin:"1em 0.45em",
      display: '-webkit-box',
      WebkitLineClamp: 2,
      WebkitBoxOrient: 'vertical' as const,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      height:"3em",
      '@media (max-width: 600px)': {
        fontSize: '14px',
        height:"1.2em",
        margin:"0",
        lineHeight: 'normal',
      },
    },
    productPrice: {
      textDecoration: "line-through",
      color: '#6E6E6E',
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontFamily: 'Poppins !important',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '24px',
      [theme.breakpoints.down('md')]: {
        fontSize: '10px',
        margin:"0.5em"
      },
    },
    productSellingPrice: {
      color: '#4E4E4E',
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontFamily: 'Poppins !important',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '24px',
      [theme.breakpoints.down('md')]: {
        fontSize: '10px',
        margin:"0.5em 0.5em 0 1.5em"
      },
    },
    addToCartButton: {
      marginTop: "8px",
      width: '80%',
      height: '2.56em',
      background: '#F5EA16',
      borderRadius:"22px",
      color: '#000000',
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontFamily: 'Poppins',
      fontSize: '12px',
      fontWeight: 500,
      lineHeight: '18px',
      pointer:"cursor",
      textTransform:"none" as const,
      "&:hover":{
          background: '#F5EA16',
      }
    },
    priceContainer:{
      display:'flex',
      justifyContent:"space-evenly",
      width:"60%"
    },
    liveStreamTitle: {
      color: '#2E2E2E',
      fontFamily: 'Poppins !important',
      fontSize: '18px',
      fontWeight: 500,
      lineHeight: '27px',
      marginTop: '0.45em',
      display: '-webkit-box',
      WebkitLineClamp: 1,
      WebkitBoxOrient: 'vertical' as const,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      marginLeft:"0.5em"
    },
    liveStreamDescription: {
      color: '#4E4E4E',
      fontFamily: 'Poppins !important',
      fontSize: '14px',
      lineHeight: '20px',
      marginTop: '0.2em',
      
    },
    liveSection: {
      display: 'flex',
      flexWrap:"wrap" as const,
      justifyContent: 'flex-start',
      padding: '20px',
      paddingLeft: "0",
      backgroundColor: 'white',
      '@media (max-width: 600px)': {
        flexWrap:"nowrap" as const,
        overflowX: 'auto',
        padding: '0px',
        width:"100vw"
      },
    },
    liveItem: {
      width: '31%',
      borderRadius: '10px',
      overflow: 'hidden',
      border: "1px solid #ddd",
      margin: "1em 1em 1em 0",
      '@media (max-width: 600px)': {
        width: '150px',
        margin: "0.5em 1em 1em 0",
        flex: "0 0 auto",
      },
    },
    liveImageContainer: {
      position: 'relative' as const,
      width: '100%'
    },
    liveImage: {
      width: '100%',
      borderRadius: '10px',
      height:"13em",
      objectFit:"fill" as const
    },
    watchingNumberIcon: {
      position: 'absolute' as const,
      top: '10px',
      left: '10px',
    },
    storeLocation:{
      color: '#0A0A0A',
      fontFamily: 'Poppins !important',
      fontSize: '20px',
      fontWeight: 600,
      lineHeight: '30px',
      marginLeft:"2em"
    },
    eachStoreAddressContainer:{
      display:"flex",
      marginTop:"1em"

    },
    storeLocationHeading:{
      color: '#3E3E3E',
      fontFamily: 'Poppins !important',
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '18px',
    },
    storeLocationaddress:{
      color: '#3E3E3E',
      fontFamily: 'Poppins !important',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '18px',
    },
    storeLoc:{
      '@media (max-width: 600px)': {
       display:"none"
      },
    },
    noRecord:{
      height:"100px",
      fontSize:"22px",
      fontWeight: 400,
      marginTop:"30px",
      '@media (max-width: 600px)': {
        height:"50px",
        fontSize:"16px",
        marginTop:"25px"
       },
    }
});

const BlackRadio = withStyles({
  root: {
    color: "#3E3E3E",
    marginTop:"-1em",
    '&$checked': {
      color: "#3E3E3E",
    },
  },
  checked: {},
})((props: BlackRadioProps) => <Radio color="default" {...props} />);

const Sidebar = styled(Box)({
  width: "15em",
  borderRight: "5px solid #D9D9D94D",
  backgroundColor: "#fff",
  boxSizing: "border-box",
  flexShrink: 0,
  textAlign: "center",
  "& .feedBtn":{
    backgroundColor: "#F5EA16",
    color: "#000",
    borderRadius: "36px",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    padding: "4px",
    width: "194px",
    height: "33px",
    marginTop: "45px"
  }
})



// Customizable Area End

export class StoreView extends StoreViewController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    renderProductsSection() {
      const { classes } = this.props;
      const {storeViewData} = this.state
      return (
        <Box className={classes.productGrid}>
          {storeViewData?.catalogues?.length === 0 ? (
          <Typography className={classes.noRecord}>No record was found</Typography>) : 
          (storeViewData?.catalogues?.map((product:Catalogue, index:number) => (
            <Box key={index} className={classes.productContainer}>
            <Box data-test-id="productCardTestId" onClick={() => this.handleProdDescriptionNavigate(product.id)}>
              <img
                src={product.catalogue_image}
                alt={product.title}
                className={classes.productImage}
              />
              <Typography className={classes.productName}>{product.title}</Typography>
              <Box className={classes.priceContainer}>
              <Typography className={classes.productSellingPrice}>
                {configJSON.rupeeSymbol}{product.selling_price}
              </Typography>
              <Typography className={classes.productPrice}>₹{product.mrp}</Typography>
              </Box>
            </Box>
              
              <Button  data-test-id={`addToCart${product.id}`} className={classes.addToCartButton} onClick={()=>this.handleAddToCart(product?.id,product?.catalogue_variant_ids[0])}>{configJSON.addToCart}</Button>
            </Box>
          )))}
        </Box>
      );
    }

    renderLiveSection() {
      const { classes } = this.props;
      const {storeViewData} = this.state
      return (
        <Box className={classes.liveSection}>
          {storeViewData?.live_streams?.length === 0 ? (
          <Typography className={classes.noRecord}>No Live Events found</Typography>) : 
          (storeViewData?.live_streams?.map((stream:LiveStream) => (
            <Box key={stream.id} className={classes.liveItem}>
              <Box className={classes.liveImageContainer}>
              <img
                src={stream.thumbnail}
                alt="Live Stream"
                className={classes.liveImage}
              />
              <img src={liveCount} alt="Watching Number" className={classes.watchingNumberIcon} />
              </Box>
              <Box component="span"  className={classes.liveStreamTitle}>{stream.title}-{stream.description}</Box>
              
            </Box>
          )))}
        </Box>
      );
    }

    renderAddress(){
      const { classes } = this.props;
      const {storeViewData} = this.state
      return (
        <Box className={classes.storeAdressContainer}>
          {storeViewData?.store_addresses?.map((eachAddress:StoreAddress,index:number) => (
            <Box className={classes.eachStoreAddressContainer} key={eachAddress.id}> 
            <BlackRadio  checked={true} />
            <Box>
            <Typography className={classes.storeLocationHeading}>{configJSON.storeLocation}{index + 1}:</Typography>
            <Typography className={classes.storeLocationaddress}>{eachAddress.address_line_1 + ", "+ eachAddress.address_line_2 + ", "+
            eachAddress.city + ", "+ eachAddress.state}</Typography>
              </Box>
            </Box>
          ))}
        </Box>
      );
    }


    // Customizable Area End
    render() {
        // Customizable Area Start
        const {classes} = this.props
        const {storeViewData} = this.state
        return (
            <Box data-test-id="mainContainer" className={classes.mainContainer}>
            <Loader loading={this.state.loading} />
            <Grid container>
           
              <Grid item md={9} lg={9}>
                <Box className={classes.storeDetailsContainer}>
                  <img
                    src={storeViewData?.store?.background_image}
                    alt="storeViewData"
                    className={classes.storeImage}
                  />
                  <Box className={classes.storeInfoBox}>
                    <Box className={classes.storeInfoUpperSection}>
                        <Typography className={classes.storeName}>
                            {storeViewData?.store?.business_name}
                            <img src={blackTickIcon} className={classes.blackTickIcon}/>
                        </Typography>
                        <Box className={classes.ratingContainer}>
                        <img src={ratingIcon} className={classes.ratingIcon}/>
                            {configJSON.ratingMock}
                        </Box>
                    </Box>
                    <Box  className={classes.storeInfoMiddleSection}>
                       <Box className={classes.storeInfoMiddleSectionBox}>
                        <img src={locationIcon} className={classes.storeInfoMiddleSectionIcon}/>
                        {/* showing adress 1 and  city as of now*/}
                        {storeViewData?.store?.address?.addressble_id  + " " +storeViewData?.store?.address?.address_line_1 + " " + storeViewData?.store?.address?.city}
                        </Box>
                        <Divider orientation="vertical" flexItem  className={classes.divider}/>
                        <Box className={classes.storeInfoMiddleSectionBox} >
                        <img src={phoneIcon} className={classes.storeInfoMiddleSectionIcon} />
                          +{storeViewData?.store?.phone_number}
                        </Box> 
                        <Divider orientation="vertical" flexItem  className={classes.divider}/>
                        <Box className={classes.storeInfoMiddleSectionBox}>
                        <img src={emailIcon}  className={classes.storeInfoMiddleSectionIcon}/>
                           {storeViewData?.store?.email}
                        </Box>

                    </Box>
                    <Box className={classes.storeInfoBottomSection}>
                    <img src={codImg} className={classes.codImage}/>
                    <img src={onOrderImg}  className={classes.onOrderImage}/>
                    <img src={bargainImg}  className={classes.codImage} />
                    </Box>
                  </Box>
                </Box>
                <Box className={classes.buttonContainer}>
                  <Button className={classes.visitSellerButton} onClick={this.openStayTunedBox}>{configJSON.visitSeller}</Button>
                  <Button className={classes.bookAppointmentButton}
                    onClick={this.openStayTunedBox}
                    startIcon={<img src={bookAppointmentIcon} />}
                  >{configJSON.bookAppointment}</Button>
                </Box>
                <Box className={classes.productsContainer}>
                  <Typography className={classes.productsTitle}>{configJSON.products}</Typography>
                  {this.renderProductsSection()}
                  {storeViewData?.live_streams.length >0 && <Typography className={classes.productsTitle}>{configJSON.live}</Typography>}
                  {this.renderLiveSection()}
                </Box>
              </Grid>
              <Grid  className={classes.storeLoc} item md={3} lg={3}>
              <Typography className={classes.storeLocation}>{configJSON.storeLocation}</Typography>
                 {this.renderAddress()}
              </Grid>
            </Grid>
            <MessageModalWeb
            data-test-id="messageModal"
            displayPopup={this.state.messageModal}
            closeErrorPopup={this.handleClosemessageModal}
            errorMessage={this.state.message} />
            <StayTuned openTunedBox={this.state.openTunedBox} closeTunedBox={this.closeTunedBox} />
          </Box>
        );
        // Customizable Area End
    }
}


// Customizable Area Start
export default withStyles(webStyles)(StoreView)
// Customizable Area End