import React from "react";
// Customizable Area Start
import {
    Box,
    styled,
    Typography,
    Divider,
    Button
} from "@material-ui/core";
import { exclamation, imgVector } from "./assets";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

import CashFreeOrderStatusController, { configJSON, Props } from "./CashFreeOrderStatusController.web";

export default class Chat extends CashFreeOrderStatusController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    rendeOrderSuccessView = () => {
        return (
            <PaymentStatusView >
                <Box className="mainContainer">
                    <Box className='innerChild'>
                        <Box className="iconBox">
                            <img src={imgVector} />
                        </Box>
                        <Typography className="orderMessage">{configJSON.orderPlacedSuccesfully}</Typography>
                        <Typography className="showOrderMessage" data-test-id="goToHistoryTestId" onClick={this.goToOrderHistory}>{configJSON.showOrderTxt}</Typography>
                        <Button variant='contained' className='orderButtons' data-test-id="goToHomeTestId" onClick={this.goToBuyerHome}>{configJSON.goToHomeText}</Button>
                    </Box>
                </Box>
            </PaymentStatusView>
        )
    };

    renderOrderFailView = () => {
        return (
            <PaymentStatusView>
                <Box className="mainContainer">
                    <Divider />
                    <Box className='innerChild'>
                        <Box className="iconBox">
                            <img src={exclamation} />
                        </Box>
                        <Typography className="orderMessage">{configJSON.somethingWrongTxt}</Typography>
                        <Typography className="errorMessage">{this.state.errorMessage}</Typography>
                        <span className="tryAgainMessage" >{configJSON.pleaseTryAgain}</span>
                        <Button variant='contained' className='orderButtons' data-test-id="gotToCartTestId" onClick={() => this.goToBuyerShoppingCart(Number(this.state.orderId))}>{configJSON.tryAgain}</Button>
                    </Box>
                </Box>
            </PaymentStatusView>
        )
    };
    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            <PaymentScreenWrapper>
                <Loader loading={this.state.isLoading} />
                <Box className="mainContainer">
                    {this.state.isErrorModal && this.renderOrderFailView()}
                    {this.state.isSuccessModal && this.rendeOrderSuccessView()}
                </Box>
            </PaymentScreenWrapper>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const PaymentScreenWrapper = styled(Box)({
    "& .mainContainer": {
        height: "91vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
});

const PaymentStatusView = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection:"column",
    "& .mainContainer": {
        padding: "20px",
        borderRadius: "20px",
        width: "100%",
        maxWidth: "425px",
    },
    "& .modalHeading": {
        fontSize: "18px",
        fontWeight: 500,
        lineHeight: "27px",
        color: "#000000",
        margin: "auto"
    },
    "& .errorMessage": {
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        color: "#000000",
        textAlign: "center",
    },
    "& .successMessage": {
        fontSize: "16px",
        fontWeight: 400,
        color: "#000000",
        textAlign: "center",
        marginTop: "15px",
        margin: "auto"
    },
    "& .tryAgainMessage": {
        fontSize: "16px",
        fontWeight: 400,
        color: "#FF5454",
        textAlign: "center"
    },
    '& .modalHeader': {
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "15px"
    },
    "& .closeIconButton": {
        border: "none",
        outline: "none",
        background: "transparent"
    },
    '& .innerChild': {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: "15px"
    },
    "& .iconBox": {
        width: "64px",
        height: "64px",
        backgroundColor: "#000000",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "100px",
        marginTop: "10px"
    },
    '& .confirmModalText': {
        fontWeight: 400,
        fontSize: 22,
        lineHeight: '22px',
        color: '#390689',
    },
    '& .orderButtons': {
        background: 'yellow',
        color: 'black',
        padding: '10px 55px',
        fontWeight: 700,
        borderRadius: 30,
        textTransform: 'capitalize',
        fontSize: 16,
        lineHeight: '24px',
        boxShadow: "none",
        marginBottom: "10px",
    },
    "& .orderMessage": {
        fontSize: "16px",
        fontWeight: 600,
        lineHeight: "24px",
        color: "#000000",
        textAlign: "center",
    },
    "& .showOrderMessage": {
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        color: "#0073E4",
        textAlign: "center",
        cursor:"pointer",
    },
})
// Customizable Area End
