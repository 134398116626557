import React from "react";

import {
  // Customizable Area Start
  Box
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import LandingPageBanner from "./LandingPageBanner.web";
import TwoColumnCardNew from "./TwoColumnCardNew.web";
import PerformaceCounter from "./PerformaceCounter.web";
import AboutPlateform from "./AboutPlateform.web";
import Categories from "./Categories.web";
import Creaters from "./Creaters.web"
import BottomImgSection from "./BottomImgSection.web";
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import LandingPageControllerWeb, {
  Props,
} from "./LandingPageControllerWeb";
class LandingPageNew extends LandingPageControllerWeb {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props
    // Customizable Area End
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Box className={classes.sectionNewLandingPage}>
          <LandingPageBanner navigation={undefined} id={""} />
          <TwoColumnCardNew navigation={undefined} id={""} />
          <PerformaceCounter navigation={undefined} id={""} />
          <AboutPlateform navigation={undefined} id={""} />
          <Categories navigation={undefined} id={""} />
          <Creaters navigation={undefined} id={""} />
          <BottomImgSection navigation={undefined} id={""} />
        </Box>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = (theme: any) => ({
  sectionNewLandingPage: {
    '& h1': {
      fontFamily: 'Poppins-Regular',
      [theme.breakpoints.down('md')]: {
        fontSize: '45px !important',
        lineHeight: '50px !important',
      }
    }
  }
})
export default withStyles(styles)(LandingPageNew);
export { LandingPageNew }
// Customizable Area End
