import React from "react";

import {
    // Customizable Area Start
    Box,
    Container,
    Typography,
    Card,
    CardMedia,
    CardContent
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});
// Customizable Area End

import LandingPageControllerWeb, {
    Props,
} from "./LandingPageControllerWeb";
import { baseURL } from "../../bulkuploading/src/BulkUploadingController";

class Categories extends LandingPageControllerWeb {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const { categoriesData } = this.state
        // Customizable Area End
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <section className={`${classes.sectionCategories} ${'sectionPadding'}`}>
                    <Container maxWidth={'lg'}>
                        <Box className={classes.categoriesWrapper}>
                            <Typography variant={'h1'} component={'h1'}>{'Our Categories'}</Typography>
                            <Box className={classes.categoriesInner}>
                                {categoriesData.slice(0, 8)?.map((items: any, index: number) => (
                                    <Card className={'cardBox'} key={index}>
                                        <CardMedia
                                            component="img"
                                            height="140"
                                            alt={''}
                                            image={`${baseURL}${items.attributes.light_icon}`}
                                        />
                                        <CardContent>
                                            <Typography component={'p'}>
                                                {items.attributes.name}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                ))}
                            </Box>
                        </Box>
                    </Container>
                </section>
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const styles = (theme: any) => ({
    categoriesWrapper: {
        '& h1': {
            fontSize: '64px',
            lineHeight: '64px',
            textAlign: 'center',
            marginBottom: '100px',
            [theme.breakpoints.down('sm')]: {
                marginBottom: '0px',
            },
        },
    },
    cardBox: {
        maxWidth: 'max-content',
        padding: '5px',
        borderRadius: '24px',
    },
    categoriesInner: {
        display: 'grid',
        gridTemplateColumns: "repeat(4, 1fr)",
        gridGap: '30px',
        overflow: 'hidden',
        padding: '90px 0px 40px 0px',
        [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: "repeat(3, 1fr)",
            gridGap: '60px',
        },
        [theme.breakpoints.down('768')]: {
            gridTemplateColumns: "repeat(2, 1fr)",
        },
        [theme.breakpoints.down('xs')]: {
            gridTemplateColumns: "1fr",
        },
        '& .cardBox': {
            height: '440px',
            maxheight: '440px',
            padding: '10px',
            borderRadius: '24px',
            boxShadow: '5px 9px 11px -3px rgba(0,0,0,0.3)',
            [theme.breakpoints.down('sm')]: {
                height: '100%',
            },

            '& img': {
                height: '325px',
                borderRadius: '24px',
                [theme.breakpoints.down('sm')]: {
                    height: '380px',
                    maxWidth: '100%',
                },
                [theme.breakpoints.down('xs')]: {
                    objectFit: 'contain',
                },
            },
            '&:nth-child(even)': {
                marginTop: '-85px',
                [theme.breakpoints.down('sm')]: {
                    marginTop: '0px',
                },
            },
            '& p': {
                fontFamily: 'Poppins-Regular',
                maxWidth: '150px',
                margin: '0 auto',
                textAlign: 'center',
                fontSize: '22px',
                lineHeight: '33px',
            },
        },

    },
});

export default withStyles(styles)(Categories);
export { Categories }
// Customizable Area End
