import React from "react";
import {
    // Customizable Area Start
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    Box,Grid,IconButton
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { withStyles } from '@material-ui/core/styles';
import { App_Logo } from "../../blocks/email-account-registration/src/assets";

export interface Props {
    classes: any;
    displayPopup: boolean;
    closeErrorPopup: any;
    errorMessage: string;
}
// Customizable Area End

class MessageModalDownload extends React.Component<Props> {
    constructor(props: Readonly<Props>) {
        super(props);

    }
    render() {
        // Customizable Area Start
        const { classes, displayPopup, closeErrorPopup } = this.props
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <Dialog
                    open={displayPopup}
                    onClose={closeErrorPopup}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    PaperProps={{
                        style: {
                            borderRadius: 25, // Adjust the border radius as needed
                            maxWidth: '450px',
                            width: '100%'
                        },
                    }}
                >
                    <DialogTitle id="alert-dialog-title">
                        <Box style={{ display: 'flex', justifyContent: 'center' }}>
                            <img src={App_Logo} alt="" style={{ maxWidth: '160px' }} />
                            <img src={require("./close.svg")} alt={'close'} onClick={closeErrorPopup} style={{ cursor: 'pointer', position: 'absolute', top: '16px', right: '16px' }} />
                        </Box>
                    </DialogTitle>
                    <DialogContent style={{ marginTop: '-20px' }}>
                        <p className={classes.boldText}>Oops!</p>
                        <p>The live streaming feature is only available on mobile. To watch live, please use our mobile app for an enhanced viewing experience.</p>
                        <p className={classes.boldText}>Download the App Now!</p>

                        <Grid container spacing={2} className={classes.iconContainer}>
                            <Grid item>
                                <IconButton
                                    href="https://play.google.com/store"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={classes.icon}
                                >
                                   <img src={require("./google.png")} alt={'close'} style={{ cursor: 'pointer', width:"120px", height:"40px" }} />
                                </IconButton>
                            </Grid>
                            <Grid item>
                                <IconButton
                                    href="https://www.apple.com/app-store/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={classes.icon}
                                >
                                     <img src={require("./appleStore.png")} alt={'close'} style={{ cursor: 'pointer', width:"120px", height:"40px" }} />
                                    
                                </IconButton>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
            </>
            // Customizable Area End
        )
    }
}

// Customizable Area Start
const styles: any = () => ({
    displayFlexBetween: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: '10px',
        "& .MuiCheckbox-root": {
            color: "white",
            padding: '0px',
        },
        "& .Mui-checked.MuiCheckbox-root": {
            color: "#F5EA16"
        }
    },
    popUpTitle: {
        color: 'black',
        fontWeight: '600',
        fontSize: '18px',
        padding: '5px 0',
        alignText:"center"
    },
    popUpContent: {
        color: 'black',
        fontSize: '18px',
        padding: '10px',
        textAlign: 'center'
    },
    cursorPointer: {
        cursor: 'pointer',
        marginRight: '-12px'
    },
    popUpContents: {
        textAlign: 'center',
        fontSize: '16px',
        color: '#D32F2F',
        lineHeight: '1.5',
      },
      iconContainer: {
        display: 'flex',
        justifyContent: 'center',
        gap: '20px',
      },
      icon: {
        fontSize: '50px',
        cursor: 'pointer',
      },
      boldText: {
        fontWeight: 'bold',
        textAlign:"center"
      },
})
export default withStyles(styles)(MessageModalDownload);
// Customizable Area End