// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { WithStyles } from "@material-ui/core";
import { getStorageData } from "../../../framework/src/Utilities";
import { Message } from "../../../framework/src/Message";
interface Address {
  id: number;
  country: string;
  latitude: number | null;
  longitude: number | null;
  address: string | null;
  addressble_id: number;
  addressble_type: string;
  address_type: string;
  created_at: string;
  updated_at: string;
  address_line_1: string;
  address_line_2: string;
  pin_code: number;
  city: string;
  state: string;
  shipment_type: string;
  account_id: number;
}

interface Store {
  id: number;
  name: string;
  business_name: string;
  phone_number: string;
  email: string;
  address: Address;
  profile_photo: string;
  background_image: string;
}

export interface Catalogue {
  id: number;
  title: string;
  discount: string;
  selling_price: number;
  mrp: number;
  catalogue_image: string;
  catalogue_variant_ids: number[];
}

export interface LiveStream {
  id: number;
  title: string;
  description: string;
  thumbnail: string;
}

export interface StoreAddress {
  id: number;
  address_line_1: string;
  address_line_2: string;
  pin_code: number;
  city: string;
  state: string;
  country: string;
  latitude: number | null;
  longitude: number | null;
  account_id: number;
  created_at: string;
  updated_at: string;
}
interface StoreViewData {
  store: Store;
  catalogues: Catalogue[];
  live_streams: LiveStream[];
  store_addresses: StoreAddress[];
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles{
    navigation: any;
    id: string;
    // Customizable Area Start
   
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    storeViewData:StoreViewData
    openTunedBox:boolean
    tokenPresentOrNot:null | string
    loading:boolean
    messageModal:boolean,
    message:string
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
   
    // Customizable Area End
}

export default class StoreViewController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getStoreViewId:string=""
    addToCartCallId:string=""
   
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area End
        ];

        // Customizable Area Start
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
       
        // Customizable Area End

        this.state = {
            // Customizable Area Start
            storeViewData:{
              store: {
                id: 0,
                name: "",
                business_name: "",
                phone_number: "",
                email: "",
                address: {
                    id: 0,
                    country: "",
                    latitude: null,
                    longitude: null,
                    address: null,
                    addressble_id: 0,
                    addressble_type: "",
                    address_type: "",
                    created_at: "",
                    updated_at: "",
                    address_line_1: "",
                    address_line_2: "",
                    pin_code: 0,
                    city: "",
                    state: "",
                    shipment_type: "",
                    account_id: 0
                },
                profile_photo: "",
                background_image: ""
            },
            catalogues: [],
            live_streams: [],
            store_addresses: []
            },
            openTunedBox:false,
            tokenPresentOrNot:null,
            loading:true,
            messageModal:false,
            message:""
            // Customizable Area End
        };

    }

    // Customizable Area Start

    async componentDidMount() {
        const tokenPresentOrNot =  await getStorageData('buerLoginToken')
        const array = window.location.pathname.split("/");
        const id = array[array.length - 1];
        this.getStoreDetails(id)
        this.setState({tokenPresentOrNot:tokenPresentOrNot})
    }

    async receive(from: string, message: Message) {
        this.handleDeleteResponse(from, message)
        this.handleCartResponse(from, message)
    }

    handleDeleteResponse = (from: string, message: Message) => {
        if (this.getStoreViewId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiResponse?.store) {
                this.setState({ storeViewData: apiResponse ,loading:false})
            }
        }
    }
    handleCartResponse = (from: string, message: Message) => {
        if (this.addToCartCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiResponse) {
                this.updateCartstate(apiResponse)
            }
        }
    }

    getStoreDetails = async (storeId:string) => {

        this.setState({ loading: true });
        const header = this.state.tokenPresentOrNot === null ?{ "Content-Type": "application/json" }:{ "Content-Type": "application/json" , "token": this.state.tokenPresentOrNot}
        const endPoint = `${ configJSON.storeEndPoint}${storeId}`
        const storeRequestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getStoreViewId = storeRequestMessage.messageId;
        storeRequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
        storeRequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        storeRequestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.validationApiMethodType);
        runEngine.sendMessage(storeRequestMessage.id, storeRequestMessage);
    }
    openStayTunedBox = () => {
        this.setState({
            openTunedBox: true
        })
    }

    closeTunedBox = () => {
        this.setState({
            openTunedBox: false
        })
    }
    checkTokenPresent=()=>{
        if(this.state.tokenPresentOrNot === null){
          const message = new Message(getName(MessageEnum.NavigationMessage));
          message.addData(getName(MessageEnum.NavigationTargetMessage),"GetStartedLogin");
          message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
          this.send(message)
        }
      }
      handleClosemessageModal=()=>{
        this.setState({
            messageModal: false
        })
      }

      updateCartstate = (responseJson: {message:string,errors:string}) => {
        if (responseJson.message) {
          this.setState({
            loading: false,
            message: responseJson.message,
            messageModal: true,
          })
        } else if (responseJson.errors) {
          this.setState({
            loading: false,
            message: responseJson.errors,
            messageModal: true
          })
        }
      }


    handleAddToCart = async(productDescriptionID:number,selectedVariantID:number) => {
        this.checkTokenPresent()
        this.setState({ loading: true });
        const buyerToken = await getStorageData('buerLoginToken')
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.addToCartCallId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.addToCartEndPoint
        );
        const header = {
          "Content-Type": configJSON.validationApiContentType,
          "token": buyerToken
        };
    
        const body = {
          catalogue_id: productDescriptionID,
          catalogue_variant_id: selectedVariantID,
          quantity: 1
        };
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(body)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.exampleAPiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
      }
  handleProdDescriptionNavigate = (productID: number) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "ProductDescription");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationScreenNameMessage), productID);
    this.send(message);
  };
    // Customizable Area End
}