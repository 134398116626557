import React from "react";

import {
    // Customizable Area Start
    Box,
    Container,
    Typography,
    Card,
    CardMedia,
    CardContent
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});
// Customizable Area End

import LandingPageControllerWeb, {
    Props,
} from "./LandingPageControllerWeb";

class Creaters extends LandingPageControllerWeb {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const { meetTheCreatersData } = this.state;
        // Customizable Area End
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <section className={`${classes.sectionCreaters} ${'sectionPadding'}`}>
                    <Container maxWidth={'lg'}>
                        <Box className={classes.creatersWrapper}>
                            <Typography variant={'h1'} component={'h1'}>{'Meet the creators'}</Typography>
                            <Box className={classes.creatersInner}>
                                {meetTheCreatersData.slice(0, 5)?.map((items: any) => (
                                    <Card className={'creatersBoxWrapper'}>
                                        <CardMedia
                                            className={'creatersImage'}
                                            component="img"
                                            height="140"
                                            image={items.attributes.image}
                                            alt={''}
                                        />
                                        <CardContent>
                                            <Typography className={'creatersContent'} component={'p'}>
                                                {items.attributes.name}
                                            </Typography>

                                        </CardContent>
                                    </Card>
                                ))}
                            </Box>
                        </Box>
                    </Container>
                </section>
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const styles = (theme: any) => ({
    sectionCreaters: {
        paddingBottom: '70px',
        [theme.breakpoints.down('md')]: {
            paddingBottom: '0px',
        },
    },
    creatersWrapper: {
        '& h1': {
            fontSize: '64px',
            lineHeight: '64px',
            textAlign: 'center',
            marginBottom: '130px',
            [theme.breakpoints.down('xs')]: {
                marginBottom: '50px',
            },
        },
    },
    creatersInner: {
        display: 'grid',
        gridTemplateColumns: "repeat(5, 1fr)",
        gridGap: '25px',
        [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: "repeat(3, 1fr)",
            gridGap: '40px',
        },
        [theme.breakpoints.down('768')]: {
            gridTemplateColumns: "repeat(2, 1fr)",
        },
        [theme.breakpoints.down('xs')]: {
            gridTemplateColumns: "1fr",
        },
        '& .creatersBoxWrapper': {
            height: '295px',
            maxHeight: '315px',
            padding: '9px',
            border: '1px solid #F5EA16',
            borderRadius: '24px',
            boxShadow: '5px 9px 11px -3px rgba(0,0,0,0.3)',
            [theme.breakpoints.down('sm')]: {
                height: '100%',
                maxHeight: 'initial',
            },
            '& .creatersContent': {
                maxWidth: '97px',
                margin: '1px auto',
                textAlign: 'center',
                fontSize: '18px',
                lineHeight: '27px',
                [theme.breakpoints.down('sm')]: {
                    fontSize: '22px',
                    lineHeight: '30px',
                    maxWidth: '120px',
                },
            },

            '& img': {
                height: '198px',
                borderRadius: '24px',
                [theme.breakpoints.down('sm')]: {
                    height: '380px',
                    maxWidth: '100%',
                },
                [theme.breakpoints.down('xs')]: {
                    objectFit: 'contain',
                },
            },
            '&:nth-child(even)': {
                marginTop: '-51px',
                [theme.breakpoints.down('sm')]: {
                    marginTop: '0px',
                },
            },
        },

    },
});

export default withStyles(styles)(Creaters);
export { Creaters }
// Customizable Area End
