Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.productApiContentType = "application/json";
exports.apiMethodTypeGet = "POST";
exports.putMethod = "PUT"
exports.getMethod ="GET"

exports.productAPiEndPoint = "/qr_codes/qr_codes";
exports.confirmOrderEndPoint   = "bx_block_order_management/place_order_in_cart"
exports.cartDetailsEndPoint = "bx_block_order_management/cart_items"

exports.qrTitle = "Please scan the QR for doing payment"
exports.receiptTxt = "Pleases share the payment receipt once payment is successful"
exports.confirmOrderTxt = "Pleases confirm the order once receipt is shared"
exports.shareReceipt  =" Share receipt"
exports.confirmOrder = "Confirm Order"
exports.orderSuccessful  = "Order Successful"
exports.orderPlacedSuccessfully = "Order placed Successfully !!"
exports.showOrderHistory = "Show order history"
exports.goToHomePage = " Go to  Home Page"

exports.sellerProfileDataAPIEndpoint = "account_block/get_seller_profile"
exports.profileQrCode = "Profile QR Code"
exports.generate = "Generate"
exports.generating = "Generating..."
exports.view = "View"
exports.download = "Download"
exports.someThingWentWrong = "'Oops, something went wrong!'"
exports.copy ="Copy"
exports.qrCodeDesc = "Scan here to shop at our digital store"
exports.qrCodeDescBottom = "India’s 1st live selling Digital mall"

// Customizable Area End
