import React from "react";
// Customizable Area Start
import {
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  ScrollView,
  Modal,
  Platform,
} from "react-native";
// Customizable Area End

// Customizable Area Start
import {
  Typography,
  Box,
  Button,
  Avatar,
  TextField,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  IconButton
} from "@material-ui/core";

import { createTheme, withStyles, ThemeProvider } from "@material-ui/core/styles";
import { noDataFound, liveArchive, liveArchiveBlank, searchIc, filterIc, CloseIc } from './assets'
const baseURL = require("../../../framework/src/config.js").baseURL
import { Link } from 'react-router-dom';

const theme = createTheme({
  palette: {
    primary: {
      contrastText: "#fff",
      main: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

import FavouritesController, {Props
} from "./FavouritesController";
import Loader from "../../../components/src/Loader.web";
import MessageModalWeb from "../../../components/src/MessageModal.web";
// Customizable Area End

// Customizable Area Start   
class Favourites extends FavouritesController {
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start    


  wishItem() {
    const { classes } = this.props;
    const { wishListProduct, wishListProductFavData } = this.state;
    return (
      <Box className={classes.contentBlockContainer}>
        <Box
          className={classes.contentBlockHeading}>
          <Typography
            style={{ fontSize: "22px", fontWeight: 400 }}>Products</Typography>
        </Box>
        <Box className={classes.contentBlockContent} style={{ flexWrap: "wrap", overflowX: 'unset' }}>
          {(wishListProduct).length > 0 ?
            (wishListProduct).map((item: any, index: any) => (
              <Box
                className={classes.contentBlockElement}
                key={index}>
                <Box
                  style={{ cursor: 'pointer' }}>
                  {
                    this.checkProductStatus(wishListProductFavData, parseInt(item.attributes.item_detail.id))
                      ? (
                        <Box className={classes.contentBlockArchive}
                          data-test-id={`removeWishItem-${index}`}
                          onClick={() => this.deleteProductItem(wishListProductFavData, parseInt(item.attributes.item_detail.id))}>
                          <img src={liveArchive}
                            alt="wished" />
                        </Box>
                      ) : (
                        <Box
                          className={classes.contentBlockArchive}
                          data-test-id={`addWishItem-${index}`}
                          onClick={
                            () => this.updateWishListItem(item.id, item.type)
                          }>
                          <img
                            src={liveArchiveBlank} alt="not-wished" />
                        </Box>
                      )
                  }
                  <Link to={`ProductDescription?id=${item.attributes.item_detail.id}`}>
                    <img src={`${baseURL}${item.attributes.item_detail.attributes.product_images[0].url}`} style={{ height: '263px', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', width: '190px', borderRadius: '10px' }} />
                  </Link>
                </Box>
                <Box className={classes.contentBlockDesc}>
                  <Box className={classes.contentBlockUser}>
                    <Avatar src={item.attributes.item_detail.account.attributes.profile_photo} />
                    <Typography style={{ fontSize: "12px", fontWeight: 500 }} >{item.attributes.item_detail.account.attributes.full_name}</Typography>
                  </Box>
                  <Typography className={classes.description} style={{ fontSize: "12px", fontWeight: 500 }} >{item.attributes.item_detail.attributes.title}</Typography>
                  <Typography className={classes.description} style={{ fontSize: "12px", color: "#A5A4A4" }} >{item.attributes.item_detail.attributes.description}</Typography>
                  <Box sx={{ fontSize: '16px', fontWeight: 500, fontFamily: "Arial !important" }} >₹ {item.attributes.item_detail.attributes.selling_price}</Box>
                  <Button className={classes.addButton} data-test-id={`addToCart-${index}`} onClick={() => this.addItemInCart(item.attributes.item_detail)} >Add to cart</Button>
                </Box>
              </Box>
            ))
            : <Box className={classes.noDataFoundImgBox}>
              <img src={noDataFound} className={classes.noDataFoundImg} />
            </Box>}
        </Box>
      </Box>
    )
  }
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { loading, wishListLiveStream, wishListProduct, message, messageModel, wishListLiveStreamFavData } = this.state;
    let favLiveId = 0
    let favProductId = 0
    return (
      <ThemeProvider theme={theme}>
        <Loader loading={loading} />
        <Box className={classes.landingPage}>
          <Box
            className={classes.contentContainer}>
            <Box className={classes.mainTitleWrap}>
              <Typography className={classes.mainTitle}>Wishlist</Typography>
            </Box>
            <Box style={{ marginTop: '25px' }}>
              <form onSubmit={(e) => this.handleSubmitSearchFilter(e)}>
                <Box className={classes.searchFilterWrap}>
                  <Box className={classes.searchWrap}>
                    <img src={searchIc} alt="search icon" className={classes.searchImg} data-test-id="searchIconClick" onClick={(e) => this.handleSubmitSearchFilter(e)} />
                    <TextField
                      className={classes.searchbar}
                      data-test-id="searchText"
                      onChange={this.handleChangeSearch}
                      value={this.state.searchText}
                      placeholder="Search Bookmarked...">
                    </TextField>
                  </Box>
                  <Box style={{ marginRight: '40px' }}>
                    <img src={filterIc} alt="search icon" className={classes.searchImg} data-test-id="filterButtonClick" onClick={() => { this.handleModalFilter() }} />
                  </Box>
                </Box>
              </form>
            </Box>
            {wishListLiveStream &&
              <Box className={classes.contentBlockContainer}>
                <Box
                  className={classes.contentBlockHeading}>
                  <Typography style={{ fontSize: "22px", fontWeight: 400 }}>
                    Live Events</Typography>
                  <Typography data-test-id="showAll" onClick={this.showALL}
                    style={{ fontSize: "14px", fontWeight: 400, cursor: 'pointer' }}>{this.state.showingAll ? `View Less >` : `View All >`}</Typography>
                </Box>
                <Box className={classes.contentBlockContent} style={{ flexWrap: this.state.showingAll ? "wrap" : "nowrap", overflowX: this.state.showingAll ? 'unset' : 'auto' }}>
                  {(wishListLiveStream).length > 0 ? (wishListLiveStream).map((item: any, index: any) => (
                    <Box
                      className={classes.contentBlockElement} key={index}>
                      {
                        this.checkWishStatus(wishListLiveStreamFavData, parseInt(item.attributes.item_detail.id))
                          ? (
                            <Box
                              className={classes.contentBlockArchive}
                              data-test-id={`removeWishItemEvent-${index}`}
                              onClick={
                                () => this.deleteWishItem(wishListLiveStreamFavData, parseInt(item.attributes.item_detail.id))}>
                              <img src={liveArchive}
                                alt="wished" />
                            </Box>
                          ) : (
                            <Box className={classes.contentBlockArchive}
                              onClick={() => this.updateWishListItem(item.id, item.attributes.item_detail.type)}>
                              <img
                                src={liveArchiveBlank} alt="not-wished"
                              />
                            </Box>
                          )
                      }
                      <Link to={`BuyersLiveStreaming?id=${item.id}`}>
                        <img src={`${baseURL}${item.attributes.item_detail.attributes.thumbnail}`}
                          style={{ height: '263px', width: '190px', borderRadius: '10px', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', }} />
                      </Link>
                      <Box className={classes.contentBlockDesc}>
                        <Box
                          className={classes.contentBlockUser}>
                          <Avatar
                            src={item.attributes.item_detail.attributes.host_image} style={{ height: '24px', width: '24px' }} />
                          <Typography style={{ fontSize: "12px", fontWeight: 500 }} >{item.attributes.item_detail.attributes.host_name}</Typography>
                        </Box>
                        <Typography className={classes.textDescription} style={{ fontSize: "12px", fontWeight: 500 }} >{item.attributes.item_detail.attributes.title}</Typography>
                        <Typography
                          className={classes.textDescription} style={{ fontSize: "12px", color: "#A5A4A4" }} >{item.attributes.item_detail.attributes.description}</Typography>
                      </Box>
                    </Box>
                  ))
                    : <Box className={classes.noDataFoundImgBox}>
                      <img
                        src={noDataFound} className={classes.noDataFoundImg} />
                    </Box>}
                </Box>
              </Box>
            }
            {wishListProduct &&
              (this.wishItem())
            }
          </Box>
        </Box>
        <Dialog
          open={this.state.openModalFilter}
          data-test-id="filterClose"
          onClose={() => this.handleModalFilter()}
          className={`${classes.roundModal} filter-dialog`}
        >
          <DialogTitle className="title-wrap">
            <span>Filter</span>
            <IconButton
              aria-label="close"
              className="close-btn"
              data-test-id="filterCloseButton"
              onClick={() => this.handleModalFilter()}
            >
              <img src={CloseIc} alt="Close" />
            </IconButton>
          </DialogTitle>
          <DialogContent className="dialog-content">
            <div className="filter-order-category">
              <div className="radio-container">
                <label className="radio-position">
                  <span>All</span>
                  {this.state.wishListFilter == '' ?
                    <input type="radio" name="orderType" value="" data-test-id="radioFilterButton" onChange={this.handleFilterChange} checked />
                    :
                    <input type="radio" name="orderType" value="" onChange={this.handleFilterChange} />
                  }
                </label>
                <label className="radio-position">
                  <span>Live Events</span>
                  {this.state.wishListFilter == 'live_streams' ?
                    <input type="radio" name="orderType" value="live_streams" onChange={this.handleFilterChange} checked />
                    :
                    <input type="radio" name="orderType" value="live_streams" onChange={this.handleFilterChange} />
                  }
                </label>
                <label className="radio-position">
                  <span>Products</span>
                  {this.state.wishListFilter == 'products' ?
                    <input type="radio" name="orderType" value="products" onChange={this.handleFilterChange} checked />
                    :
                    <input type="radio" name="orderType" value="products" onChange={this.handleFilterChange} />
                  }
                </label>
              </div>
            </div>
          </DialogContent>
          <DialogActions className="btn-wrap-filter">
            <Button
              data-test-id="filterCloseModalButtonResult"
              onClick={() => this.showFilter()}
              className="custom-button primary-yellow buyer-profile-filter-yellow"
            >
              Show results
            </Button>
            <Button
              data-test-id="filterCloseModalButtonClear"
              onClick={() => this.clearFilter()}
              className="custom-button outline-yellow"
            >
              Clear
            </Button>
          </DialogActions>
        </Dialog>
        <MessageModalWeb
          displayPopup={messageModel}
          closeErrorPopup={this.closeMessageModal}
          errorMessage={message} />
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    
  },
})
const style = (theme: any) => ({
  fontStyle: {
        color: "#000"
    },
    asideMenu: {
        [theme.breakpoints.down("sm")]: {
            display: "none",
        },
        [theme.breakpoints.up("sm")]: {
          width: "240px",
          display: "block",
        },
    },
    landingPage: {
      minHeight: '100vh',
        display: 'flex',
        "&::-webkit-scrollbar-track": {
          borderRadius: "3px",
          background: "#D9D9D955"
        },
        "&::-webkit-scrollbar": {
          width: "7px",
          position: 'absolute',
          left: 0,
        },
        "&::-webkit-scrollbar-thumb": {
          borderRadius: "3px",
          background: "#D9D9D9",
        },
        [theme.breakpoints.down("sm")]: {
          marginTop: 0
        },
        [theme.breakpoints.up("sm")]: {
          marginTop: "100px"
        },
        [theme.breakpoints.down("xs")]: {
          flexDirection: 'column',
          alignItems: 'start',
          marginTop: '100px',
        },
        '& .hamburger': {
          display: 'none',
          [theme.breakpoints.down("xs")]: {
            display: "block",
          },
        }
      },
      contentContainer: {
        flex: 1,
        padding: '30px 0 30px 30px',
      },
      contentHeader: {
        padding: '0 20px 0 0',
        display: 'flex',
        overflowX: 'auto',
        [theme.breakpoints.up("sm")]: {
          width: "calc(100vw - 310px)"
        },
        [theme.breakpoints.down("sm")]: {
          width: "100vw"
        },
        "&::-webkit-scrollbar": {
          height: "6px",
        },
        "&::-webkit-scrollbar-track": {
          background: "#D9D9D955",
          borderRadius: "10px",
        },
        "&::-webkit-scrollbar-thumb": {
          borderRadius: "10px",
          background: "#D9D9D9",
        },
      },
      contentBlockContainer: {
        marginTop: '20px',
        [theme.breakpoints.down("sm")]: {
          width: "100vw"
        },
        [theme.breakpoints.up("sm")]: {
          width: '100%'
        },
      },
      contentBlockHeading: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0 50px 0 0',
      },
      contentBlockContent: {
        display: 'flex',
        gap: '50px',
        alignItems: 'center',
        marginTop: '20px',
        paddingBottom: '25px',
        overflowX: 'auto',
        scrollbarColor: '#d9d9d9 #d9d9d955',
        scrollbarWidth: 'thin',
        '&::-webkit-scrollbar': {
          height: '6px',
        },
        '&::-webkit-scrollbar-track': {
          borderRadius: '10px',
          background: '#D9D9D955',
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#D9D9D9',
          borderRadius: '10px',
        },
      },
      contentBlockElement: {
        position: 'relative',
        width: '100%',
        maxWidth: '190px',
        display: 'flex',
        gap: '16px',
        flexDirection: 'column',
        animation: 'fadeIn 2s',
      },
      contentBlockDesc: {
        display: 'flex',
        alignItems: 'start',
        flexDirection: 'column',
        gap: '12px',
      },
      contentBlockUser: {
        alignItems: 'center',
        display: 'flex',
        gap: '8px',
      },
      contentBlockArchive: {
        position: 'absolute',
        right: '12px',
        top: '12px',
        borderRadius: '25px',
        padding: '6px 5px 5px 5px',
        background: '#00000099',
        cursor: 'pointer',
      },
      noDataFoundImgBox: {
        width: '100%',
        justifyContent: 'center',
        display: 'flex',
        [theme.breakpoints.down(350)]: {
          justifyContent: 'start',
          marginLeft: '-8px'
        },
      },
      noDataFoundImg: {
        width: '100%',
        maxWidth: '300px',
        animation: 'fadeIn 2s',
      },
      contentBlockNumber: {
        color: '#ffffff',
        fontSize: '12px',
        fontWeight: 500,
        marginLeft: '10px',
      },
      story: {
        borderRadius: '60px',
        background: '#ffffff',
        width: '94px',
        padding: '3.46px',
        height: '94px',
      },
      textDescription: {
        display: '-webkit-box',
        WebkitLineClamp: '2',
        overflow: 'hidden',
        whiteSpace: 'normal',
        WebkitBoxOrient: 'vertical',
        textOverflow: 'ellipsis',
        height: '36px',
      },
      gridLayout: {
        display: 'grid',
        gridTemplateColumns: 'repeat(4, 1fr)',
        maxHeight: '410px',
        gridRowGap: '20px',
        gridColumnGap: '10px',
        [theme.breakpoints.down("xs")]: {
          gridTemplateColumns: 'repeat(3 , 1fr)',
        },
        [theme.breakpoints.down(400)]: {
          maxHeight: '350px',
        },
        [theme.breakpoints.down(376)]: {
          maxHeight: '330px',
        },
        [theme.breakpoints.down(500)]: {
          gridTemplateColumns: 'repeat(2 , 1fr)',
          maxHeight: '390px',
        },
        [theme.breakpoints.down(281)]: {
          maxHeight: '250px',
          gridTemplateColumns: 'repeat(1 , 1fr)',
        },
        '& .gridImg': {
          height: '185px',
          [theme.breakpoints.down(281)]: {
            maxWidth: '100% !important',
          },
        }
      },
      description: {
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        WebkitLineClamp: 2,
        height: '36px',
        textOverflow: 'ellipsis',
        whiteSpace: 'normal',
      },
    addButton: {
      borderRadius: '50px',
      height: '28px',
      background: '#F5EA16',
      padding: '8px 16px',
      fontSize: '14px',
      lineHeight: '21px',
      color: '#000000',
      textTransform: 'none',
      '&:hover': {
          backgroundColor: '#F5EA16'
      },
  },
  mainTitle:{
    fontFamily: "Poppins !important",
    fontWeight: 500,
    fontSize: "18px",
    color: "#000",
    paddingBottom: '10px',
    paddingLeft: '30px'
  },
  mainTitleWrap:{
    borderBottom: '1px solid #E6E6E6',
    marginLeft: '-30px'
  },
  searchWrap: {
    maxWidth: '682px',
    width: '100%',
    border: '1px solid #C5C5C5',
    borderRadius: '62px',
    padding: '8px 20px',
    display: 'flex',
    alignItems: 'center',
    '@media(max-width: 1060px)': {
      width: '80%'
    },
    '@media(max-width: 700px)': {
      width: '70%'
    },
  },
  searchbar: {
    paddingLeft: '30px',
    width: '100%',
    '& .MuiInput-underline:before': {
      borderBottom: 'none'
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      border: 'none'
    },
  },
  searchFilterWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 15px'
  },
  roundModal: {
    '& .MuiDialog-paper': {
      borderRadius: '40px'
    },
  },
  searchImg: {
    cursor: 'pointer'
  }
})

// @ts-ignore
export default withStyles(style)(Favourites);
export {Favourites}
// Customizable Area End
