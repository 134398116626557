import React from "react";

import {
    // Customizable Area Start
    Box,
    Grid,
    Typography,
    TextField,
    styled,
    Button,
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { applogo, mask_group } from "./assets";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import { createTheme, withStyles } from "@material-ui/core/styles";


const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});
// Customizable Area End

import EmailAccountLoginController, {
    Props,
} from "./EmailAccountLoginController";
import LoginFailModalWeb from "../../../components/src/LoginFailModal.web";

class LoginWithMobile extends EmailAccountLoginController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    phoneError() {
        return (this.state.phoneError !== "" && (
            <div className="red-text">{this.state.phoneError}</div>
        ))
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        // Customizable Area End
        return (
            // Customizable Area Start
            <Box justifyContent="center" alignItems="center" 
                    style={{ backgroundColor: 'black',
                            position: 'relative', 
                            minHeight: '100vh' }}>
                <Grid container className={classes.gridContainer}>
                    <Grid item xs={12} md={5} style={{ padding: '0 20px', margin: '45px 0', }}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Box>
                                <img src={applogo} alt="" />
                                <Typography variant="h6" style={{ color: 'rgb(256,256,256,1)' }} >{'Live shopping & market place'}</Typography>
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={6} style={{ margin: '45px 0', padding: '0 20px' }} >
                        <Box
                            sx={{
                                border: '1px solid #fff',
                                borderRadius: '20px',
                                p: 3,
                                width: '100%',
                                maxWidth: '660px',
                                padding: '48px 60px 65px',
                                zIndex: 1,
                                margin: '0 auto',
                                position: 'relative',
                            }}
                        >
                            <Typography variant="h4" style={{
                                textAlign: "center",
                                fontSize: '32px',
                                fontWeight: 600,
                                lineHeight: '1',
                                letterSpacing: '0px',
                                color: "#fff",
                                marginTop: '5px',
                                fontFamily: "Poppins !important",
                            }}>
                                Enter Your Mobile Number
                            </Typography>
                            <Typography style={{
                                textAlign: "center",
                                fontFamily: 'Poppins',
                                fontSize: '18px',
                                fontWeight: 400,
                                lineHeight: '1',
                                letterSpacing: '0px',
                                margin: '30px auto 0',
                                color: "rgba(255, 255, 255, 0.6)",
                                maxWidth: '422px',
                                width: '100%',
                            }}>Enter your 10 digit mobile number to login into Flixoo</Typography>

                            <form onSubmit={(e)=>this.checkValidationMobile(e)}>
                                <Grid container style={{ marginTop: '60px', marginBottom: '20px' }}>
                                    <Grid container spacing={2}>
                                        <Box className={classes.formBox}>
                                            <div>
                                                <div className={classes.countryCodeFeild} >
                                                    <label className={'countryCodeLable'}>Code</label>
                                                    <div className={'countryCodeSelect'} data-test-id="countryCode" onClick={this.handleIsSelect} >
                                                        <p style={{ paddingRight: '10px', transform: "scale(2)" }}
                                                            defaultValue={"in"}>{this.state.selectedValue?.label}</p>
                                                        {this.state.selectedValue?.value}
                                                        {this.state.isSelect ? <ExpandLessIcon className={classes.expandSVG} /> : <ExpandMoreIcon className={classes.expandSVG} />}

                                                    </div>
                                                    {this.state.isSelect && 
                                                        <CountryDiv 
                                                            data-test-id="countryChange" 
                                                            className={classes.country}>
                                                            {this.state.dataSource.map((item: any, index: any) => (
                                                                <li data-test-id={`countryValue${index}`} key={index} onClick={this.selectCountryFlag.bind(this, item)} style={{ color: 'white', padding: '7px 14px', borderBottom: '1px solid rgba(255, 255, 255, 0.50)' }}>
                                                                    <span style={{ paddingRight: 8, display: 'inline-block' }}>{item.attributes.emoji_flag}</span>
                                                                    {item.attributes.country_code}</li>
                                                            ))}
                                                        </CountryDiv>
                                                        }
                                                </div>
                                            </div>
                                            <div style={{flex: 1}}>
                                                <TextField
                                                    label="Mobile Number"
                                                    variant="outlined"
                                                    fullWidth
                                                    InputLabelProps={{
                                                        shrink: true,
                                                        style: { color: "rgba(255, 255, 255, 0.5)" },
                                                    }}
                                                    type="number"
                                                    data-test-id="mobileNumberText"
                                                    onChange={this.handleMobileNumberChange}
                                                    value={this.state.mobileNumber}
                                                    className={classes.customTextField}
                                                />
                                                {this.phoneError()}
                                            </div>
                                        </Box>
                                        <Button variant="contained" style={{
                                            backgroundColor: "rgba(245, 234, 22, 1)", color: "#444444", height: "56", marginTop: '40px',
                                            textTransform: 'none',
                                            borderRadius: '35px',
                                            fontSize: '16px', fontWeight: 600, fontFamily: 'Poppins !important'
                                        }} fullWidth
                                        data-test-id="mobileSendOtp"
                                        type="submit"
                                        >
                                            Get OTP
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </Box>
                    </Grid>
                </Grid>
                <Box style={{ 
                    left: '0', 
                    right: '0', 
                    width: '100%', 
                    position: 'absolute', 
                    bottom: '0' }}>
                    <img src={mask_group} alt="Mask Group" style={{ width: '100%', height: 'auto', objectFit: 'cover' }} />
                </Box>
                <LoginFailModalWeb 
                displayPopup={this.state.emailModal} 
                closeErrorPopup={this.emailModalClose} 
                titleMessage={'Invalid Credentials'}
                errorMessage={"Please enter valid mobile number"} />
            </Box>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const styles = (theme: any) => ({
    customTextField: {
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "rgba(255, 255, 255, 0.50)",
                borderRadius: "10px",
            },
            "&.Mui-focused fieldset": {
                borderColor: "rgba(255, 255, 255, 0.50)",
            },
            "&:hover fieldset": { borderColor: "rgba(255, 255, 255, 0.50)" },
            '& .MuiInputBase-input::placeholder': {
                color: '#ffffff',
                opacity: 1,
                fontSize: '14px'
            },
        },
        "& label": { color: "white" },
        "& input": {
            color: "white",
        },
    },
    horizontalLine: {
        position: 'relative' as const,
        backgroundColor: "rgba(255, 255, 255, 0.5)",
        width: '100%',
        height: '1px',
        '& p': {
            textAlign: 'center',
            backgroundColor: 'black',
            marginTop: '-9px',
            margin: 'auto',
            padding: '0px 7px',
            fontFamily: 'Poppins-Regular',
            fontSize: '12px',
            position: "absolute" as const,
            left: '47%' as const,
            lineHieght: '18px',
            color: 'rgba(255, 255, 255, 0.5)',
            postion: "absolute" as const,
        }
    },
    countryCodeFeild: {
        position: 'relative' as const,
        '& .countryCodeLable': {
            backgroundColor: '#000000',
            zIndex: 1,
            color: 'rgba(255, 255, 255, 0.50)',
            fontSize: "12px",
            position: 'absolute',
            top: "-8px",
            left: "15px",
            display: 'inline-block',
            padding: '0px 5px',
        },
        '& .countryCodeSelect': {
            width: '129px',
            height: '57px',
            border: '1px solid rgba(255, 255, 255, 0.50)',
            borderRadius: 10,
            color: 'white',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gridGap: '4px',
            '@media(max-width: 480px)': {
                width: '100px',
                height: '55px',
                border: '1px solid rgba(255, 255, 255, 0.50)',
                borderRadius: 10,
                color: 'white',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gridGap: '3px',
            },
            '@media(max-width: 376px)': {
                width: '100%',
                height: '55px',
                border: '1px solid rgba(255, 255, 255, 0.50)',
                borderRadius: 10,
                color: 'white',
                display: 'flex',
                alignItems: 'center',
                gridGap: '3px',
                justifyContent: 'flex-start',
                position: 'relative'
            },
            '& p': {
                marginTop: '15px !important',
                '@media(max-width: 375px)': {
                    marginLeft: '25px !important',
                },
            },
        },
    },
    gridContainer: {
        height: '100%',
        alignItems: 'center',
        '@media(max-width: 960px)': {
            height: 'auto',
            alignItems: 'center',
        },
    },
    expandSVG: {
        marginRight: '-15px',
        '@media(max-width: 375px)': {
            position: 'absolute',
            right: 10,
            marginRight: 0,
        },
    },
    formBox:{
        display: 'flex',
        gridGap: '15px',
        width: '100%',
        '@media(max-width: 376px)': {
            display: 'flex',
            gridGap: '15px',
            flexDirection: 'column',
            width: '100%',
        },
    },
    country:{
        position: 'absolute', 
        top: '65px', 
        width: '114px', 
        listStyle: 'none', 
        background: 'black',
        borderRadius: 10, 
        border: '1px solid rgba(255, 255, 255, 0.50)', 
        zIndex: 11, 
        height: 150, 
        overflowY: 'scroll',
        '@media(max-width: 375px)': {
            width: '100%', 
        },
    }
});

const CountryDiv = styled('div')({
    '&::-webkit-scrollbar': {
        display: 'none',
    },

    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',
})
//@ts-ignore
export default withStyles(styles)(LoginWithMobile);
export {LoginWithMobile}
// Customizable Area End
