export const insertPhoto = require("../assets/insert_photo.png");
export const addIcon = require("../assets/add_icon.png");
export const micIcon = require("../assets/mic_icon.svg");
export const plusIcon = require("../assets/plus_icon.png");
export const gallaryIcon = require("../assets/gallary_icon.png");
export const searchIcon = require("../assets/search_icon.png");
export const chatBackground = require("../assets/chat_background.png");
export const smileyIcon = require("../assets/smiley_icon.png");
export const selectAllIcon = require("../assets/select_all_icon.png");
export const newChannelIcon = require("../assets/new_channel_icon.png");
export const createGroupIcon = require("../assets/create_group_icon.png");
export const dummyPersonImage = require("../assets/dummy_person_img.png");
export const hamburgerIcon = require("../assets/hamburger.svg");
export const plusHeaderIcon = require("../assets/plusIcon.png");
export const calenderIcon = require("../assets/calendarIcon.png");
export const pinIcon = require("../assets/pin_icon.svg");
export const emojiIcon = require("../assets/emoji_icon.svg");
export const noChatImg = require("../assets/no_chat.png");
export const tickIcon = require("../assets/tick_icon.png");
export const newMessageIcon = require("../assets/new_messages_icon.png");
export const profileIcon = require("../assets/profile_icon.png");
export const termsCheckIcon = require("../assets/terms_tick_icon.png");
export const bellIcon = require("../assets/bellIcon.png");
export const backButton = require("../assets/backButton.png");
export const flixooLoader = require("../assets/FlixooLoader.json");
export const plusSvgIcon = require("../assets/plus_icon.svg");
export const tickSvg = require("../assets/tick_icon.svg");
export const micIconPng = require("../assets/mic_icon.png");
export const infoIcon = require("../assets/information.svg");
export const offerAccepted = require("../assets/offer_accepted.svg");
export const offerDeclined = require("../assets/offer_rejected.svg");
export const vectorIcn = require("../assets/Vector.png");