import React, { useRef } from "react";
import {
  // Customizable Area Start
  IconButton,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Badge,
  Box,
  Paper,
  Tabs,
  Tab,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { withStyles } from '@material-ui/core/styles';
import "../assets/css/style.css";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import {
  searchIc,
  businessAnouce,
  busineessPattern,
  calendarIc,
  notificationIc,
  notificationRedDotIc,
  hamburgerIc,
  clockYellow,
  progressStep1,
  progressStep2,
  progressStep3,
  progressStep4,
  closeIc,
  rightArrowProgress,
  PlayIcBlack,
  MessageQuesIc,
  PlayIcWhite,
  clockIc,
  downArrowIc,
  congratulations
} from "./assets";
import { Link } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import SellerSidebar from "./SellerSidebar.web";
import ReactPlayer from 'react-player';
const baseURL = require("../../../framework/src/config.js").baseURL
import Carousel from "react-material-ui-carousel";
// Customizable Area End

import LandingPageControllerWeb, { Props } from "./LandingPageControllerWeb";

class SellerDashboard extends LandingPageControllerWeb {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { videoTutorial, selectedVideo, faqData, analyticsData, sellerBusinessName, isNotificationBoxOpen, allNotifications, selectedTab, DynamicSellerBanner } = this.state;
    const { classes } = this.props;
    // Customizable Area End
    return (
      // Customizable Area Start
      <div className="main-div">
        <div className="dashboard-outer">
          <div className="dashboard-inner">
            <SellerSidebar
              navigation={"/SellerDashboard"}
              id={""}
              classes={undefined}
              isOpen={this.state.isOpen}
              data-test-id="sellerSidebar"
              activetabName="dashboard"
              closeModal={() => this.toggleDrawer()}
            />
            <div className="right-outer">
              <div className="right-inner">
                <div className="title-wrap">
                  <div className="left-wrap">
                    <span className="title">Flixoo Live Seller Hub</span>
                  </div>
                  <div className="right-wrap" style={{position:"relative"}}>
                    <div className="btn-wrap">
                      <IconButton className="icon-btn">
                        <img src={searchIc} alt="search" />
                      </IconButton>
                      <IconButton className="icon-btn">
                        <img src={calendarIc} alt="calendar" />
                      </IconButton>
                      <Box className={classes.headerCartBox} onClick={this.handleNotificationBoxToggle} data-test-id="notification-click">
                        <Badge color="secondary" variant="dot">
                          <img src={notificationIc} alt="notification" />
                        </Badge>
                        
                      </Box>
                      <IconButton
                        className="icon-btn hamburger-btn"
                        data-test-id="hamburger-btn"
                        onClick={() => this.toggleDrawer()}
                      >
                        <img src={hamburgerIc} alt="hamburger" />
                      </IconButton>
                    </div>
                    <Box className={classes.notificationPopup}>
                          {isNotificationBoxOpen && (
                            <NotificationBox closeNotificationBox={this.handleNotificationBoxToggle} allNotifications={allNotifications} markAllAsRead={this.markAllNotificationsAsRead} classes={classes} selectedTab={selectedTab} handleTabChange={this.handleTabChange} />
                          )}
                        </Box>
                  </div>
                </div>
                {this.state.sellerProfilePercentage > 0 && this.state.sellerProfilePercentage < 100 &&
                  <div className="progress-wrap">
                    <div className="progress-inner">
                      <div className="progress-img-wrap">
                        <div className="inner-img-wrap">
                          <CircularProgress
                            variant="determinate"
                            value={100}
                            size={100}
                            thickness={4}
                            className="bottom-circle"
                          />
                          <CircularProgress
                            variant="determinate"
                            value={this.state.sellerProfilePercentage}
                            size={100}
                            thickness={4}
                            className="progress-circle"
                          />
                          <span className="value">{this.state.sellerProfilePercentage}%</span>
                        </div>
                      </div>
                      <div className="content-wrap">
                        <div className="content-inner">
                          <span>
                            Please complete your seller registration to start
                            selling on Flixoo Live
                          </span>
                          <Link to={this.state.sellerProfileRedirect}>Complete Profile</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                }
                {this.state.sellerProfilePercentage == 100 && !this.state.sellerAdminVerified &&
                  <div className="progress-wrap">
                    <div className="progress-inner">
                      <div className="progress-img-wrap">
                        <div className="inner-img-wrap">
                          <img src={clockYellow} alt="Clock Img" />
                        </div>
                      </div>
                      <div className="content-wrap">
                        <div className="content-inner">
                          <span>
                            Your seller registration has been completed. Please wait for Flixoo approval. It can take up to 1 business day
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                }
                {(!this.state.sellerActivities && this.state.sellerProfilePercentage == 100 && this.state.sellerAdminVerified) &&
                  <div className="progress-cards-wrap">
                    <div className="progress-cards-inner">
                      <div className="card-item">
                        <div className="card-inner">
                          <div className="img-wrap">
                            <div className="inner-img-wrap">
                              <img src={progressStep1} alt="Progress" />
                            </div>
                          </div>
                          <div className="content-wrap">
                            <span>Start creating your first post</span>
                            <IconButton className="icon-btn">
                              <img src={rightArrowProgress} alt="Arrow" />
                            </IconButton>
                          </div>
                        </div>
                      </div>
                      <div className="card-item">
                        <div className="card-inner">
                          <div className="img-wrap">
                            <div className="inner-img-wrap">
                              <img src={progressStep2} alt="Progress" />
                            </div>
                          </div>
                          <div className="content-wrap">
                            <span>Create your first live</span>
                            <IconButton className="icon-btn">
                              <img src={rightArrowProgress} alt="Arrow" />
                            </IconButton>
                          </div>
                        </div>
                      </div>
                      <div className="card-item">
                        <div className="card-inner">
                          <div className="img-wrap">
                            <div className="inner-img-wrap">
                              <img src={progressStep3} alt="Progress" />
                            </div>
                          </div>
                          <div className="content-wrap">
                            <span>Schedule your first live</span>
                            <IconButton className="icon-btn">
                              <img src={rightArrowProgress} alt="Arrow" />
                            </IconButton>
                          </div>
                        </div>
                      </div>
                      <div className="card-item">
                        <div className="card-inner">
                          <div className="img-wrap">
                            <div className="inner-img-wrap">
                              <img src={progressStep4} alt="Progress" />
                            </div>
                          </div>
                          <div className="content-wrap">
                            <span>Add your product / service</span>
                            <IconButton className="icon-btn">
                              <img src={rightArrowProgress} alt="Arrow" />
                            </IconButton>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                }
                {(this.state.sellerActivities && this.state.sellerProfilePercentage == 100) &&
                  <div className="business-analytics">
                    <Carousel
                      animation="slide"
                      swipe={true}
                      indicators={false}
                    >
                      {DynamicSellerBanner.map((items: any, index: number) => (
                        <div key={index} className="business-baner">
                          <div className="banner-img-content">
                            <div className="business-anounce-wrap">
                              <img src={businessAnouce} alt="business-anounce" />
                            </div>
                            <span>{items.attributes.name}</span>
                          </div>
                          <div className="banner-pattern">
                            <div className="business-pattern-wrap">
                              <img src={`${baseURL}${items.attributes.banner}`} alt="business-pattern" />
                            </div>
                          </div>
                        </div>
                      ))}
                    </Carousel>
                    <div className="business-overview">
                      <div className="overview-title">
                        <span>Today’s Overview</span>
                      </div>
                      <div className="overview-table-data">
                        <div className="outer-border">
                          <div className="inner-wrap">
                            <div className="inner-content">
                              {analyticsData?.map((items: any, index: number) => (
                                <div className="table-data" key={index}>
                                  <div className="data-title">
                                    <span>{items.title}</span>
                                  </div>
                                  <div className="number-data">
                                    {items.data?.map((itemData: any, indexNumber: number) => (
                                      <div className="number-data-content" key={indexNumber}>
                                        <div className="inner-content">
                                          <span className="title">{itemData.title}</span>
                                          <span className="content">{itemData.subTitle}</span>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                }
                <div className="video-questions-block">
                  <div className="btn-wrap">
                    <Button
                      className={
                        this.state.displaySection === "video" ||
                          this.state.displaySection === ""
                          ? "custom-button outline-yellow font"
                          : "custom-button outline-black font"
                      }
                      data-test-id="video-btn"
                      onClick={() => this.clickVideoButton()}
                    >
                      <i><img src={PlayIcBlack} alt="Video" /></i>
                      Video Tutorials
                    </Button>
                    <Button
                      className={
                        this.state.displaySection === "faq"
                          ? "custom-button outline-yellow font"
                          : "custom-button outline-black font"
                      }
                      data-test-id="faq-btn"
                      onClick={() => this.clickFaqButton()}
                    >
                      <i>
                        <img src={MessageQuesIc} alt="Video" />
                      </i>
                      General FAQ
                    </Button>
                  </div>
                  {this.state.displaySection === "" ||
                  this.state.displaySection === "video" ? (
                    <div className="video-cards-wrap">
                      <div className="video-cards-inner">
                          {videoTutorial?.map((items: any, index: number) => (
                            <div className="card-item" key={items.id}>
                              <div className="card-inner">
                                <div className="img-wrap">
                                  <div className="inner-img-wrap">
                                    <img src={items.attributes.thumb_image} alt="Progress" />
                                  </div>
                                  <IconButton className="icon-btn" data-test-id={`video-btn-${index}`} onClick={() => this.handleImageClick(items.attributes.video.url)}>
                                    <img src={PlayIcWhite} alt="Play" />
                                  </IconButton>
                                </div>
                                <div className="content-wrap">
                                  <span className="title"> 
                                    <Typography data-test-id="videoTitleWrapText" noWrap={(this.state.videoTitleWrapText === "" || this.state.videoTitleWrapText !== items.id) ? true : false} onClick={()=>this.videoTitleWrapToggle(items.id)}>{items.attributes.title}</Typography>
                                  </span>
                                  <span className="time">
                                    <i>
                                      <img src={clockIc} alt="Clock" />
                                    </i>
                                    {`${Math.floor(items.attributes.video.video_size.duration / 60)} min ${(items.attributes.video.video_size.duration % 60).toFixed(0)} sec`}
                                  </span>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                {this.state.displaySection === "" ||
                  this.state.displaySection === "faq" ? (
                  <div className="accordion-wrap">
                    {faqData?.map((items: any) => (
                      <Accordion className="custom-accordion" key={items.id}>
                        <AccordionSummary className="accordion-head">
                          <i className="arrow">
                            <img src={downArrowIc} alt="Down Arrow" />
                          </i>
                          {items.title}
                        </AccordionSummary>
                        <AccordionDetails className="accordion-body">
                          {items.content}
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <Dialog
            open={this.state.openModalVideoPlayer}
            data-test-id="videoClose"
            onClose={() => this.handleCloseModalVideoPlayer()}
            className="custom-dialog video-dialog"
          >
            <DialogContent className="dialog-content">
              <IconButton
                aria-label="close"
                className="close-btn"
                data-test-id="dialogCloseVideos"
                onClick={() => this.handleCloseModalVideoPlayer()}
              >
                <img src={closeIc} alt="Close" />
              </IconButton>
              <div className="inner-wrap">
                <div className="video-player">
                  <ReactPlayer
                    url={selectedVideo}
                    controls
                    playing
                  />
                </div>
              </div>
            </DialogContent>
          </Dialog>
          <Dialog
            open={this.state.modalOpen}
            data-test-id="modalClose"
            onClose={() => this.modalClose()}
            className="custom-dialog congratulations-dialog"
          >
            <DialogContent className="dialog-content">
              <div className="inner-wrap">
                <div className="img-wrap">
                  <img src={congratulations} alt="Review" />
                </div>
                <div className="content-wrap">
                  <span className="title">Congratulations! {sellerBusinessName}</span>
                  <span className="content">
                    Your profile has been approved. Good luck on your Flixoo
                    journey. Start selling now.
                  </span>
                </div>
              </div>
            </DialogContent>
          </Dialog>
        </div>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
interface NotificationBoxProps {
  closeNotificationBox: () => void;
  allNotifications: any[];
  markAllAsRead: () => void;
  selectedTab: any;
  handleTabChange: any;
  classes: any;
}


const OfferBox = ({ notificationData }: { notificationData: Array<any> }) => {
  return (
    <>
      {notificationData.map((notification: any, index: number) => (
        <Box key={index} style={{ backgroundColor: notification.attributes.is_read ? 'transparent' : '#E3E3E3' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gridGap: '10px', padding: '10px 15px', boxShadow: '0px 3px 20px 0px rgba(0, 0, 0, 0.10)', margin: '0px 10px' }}>
            <Box style={{
              minWidth: '50px',
              minHeight: '50px',
              borderRadius: '50%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'start'
            }}>
              <img src={notificationIc} alt="" />
            </Box>
            <Box>
              <Typography style={{ color: '#000000', fontWeight: 'bold' }}>{notification.attributes.headings}</Typography>
              <Typography style={{ color: '#000000', fontWeight: notification.attributes.is_read ? 'normal' : 'bold' }}>{notification.attributes.contents}</Typography>
            </Box>
          </Box>
          {index !== notificationData.length - 1 && (
            <Box style={{ border: '0px solid rgba(0,0,0, 0.1)', width: '90%', height: '1px', margin: '8px auto' }}></Box>
          )}
        </Box>
      ))}
    </>
  );
};

const NotificationBox: React.FC<NotificationBoxProps> = ({ closeNotificationBox, allNotifications, classes, selectedTab, handleTabChange }) => {

  const notificationBoxRef = useRef<HTMLDivElement | null>(null);


  return (
    <Paper
      ref={notificationBoxRef}
      style={{ backgroundColor: '#ffffff' }}
      className="NotificationBox"
    >
      <Box style={{ paddingBottom: '15px' }}>
        <Box style={{ backgroundColor: '#ffffff', height: '87px', borderTopLeftRadius: '20px', borderTopRightRadius: '20px', }}>
          <h6 style={{ color: '#000000', margin: '0px', padding: '35px 25px', fontSize: '16px', lineHeight: '24px' }} >All notifications</h6>
        </Box>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          className={classes.styleTabHead}
        >
          <Tab label="Social" className={classes.styleTabNotification} />
          <Tab label="Business" className={classes.styleTabNotification} />
        </Tabs>
        <Box style={{ marginTop: '20px', maxHeight: '465px', overflowY: 'scroll' }}>
          <OfferBox notificationData={allNotifications} />
        </Box>
      </Box>
    </Paper>
  );
};
const styles = (theme: any) => ({
  AppBarBox: {
    height: '103px',
    backgroundColor: "#000000",
    '& .MuiToolbar-regular': {
      margin: 'auto 0',
    },
    '& #toggle-nav': {
      display: 'none',
    },
    '& .menu-icon': {
      display: 'none'
    },
    '& .HeaderGetStartedBtn': {
      maxWidth: '100%',
    },
    [theme.breakpoints.down(1150)]: {
      height: '70px',
      '& #toggle-nav': {
        display: 'block',
      },
      '& .menu-icon': {
        display: 'block',
      },
      '& #toggle-nav:checked ~ .headerLinkBox': {
        left: '0%',
      }
    },
  },
  headerCartBox: {
    cursor: 'pointer',
    borderRadius: '10px',
    backgroundColor: '#fff',
    boxShadow: '0px 0px 20px 1px rgba(0,0,0,0.1)',
    marginLeft: '24px',
    overflow: 'hidden',
    transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    padding: '12px',
    '& img': {
      width: '24px',
      height: '24px',
    },
    '& .NotificationBox': {
      headerCartBox: {
    cursor: 'pointer',
    borderRadius: '10px',
    backgroundColor: '#fff',
    boxShadow: '0px 0px 20px 1px rgba(0,0,0,0.1)',
    marginLeft: '24px',
    overflow: 'hidden',
    transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    padding: '12px',
    '& img': {
      width: '24px',
      height: '24px',
    },
    '& .NotificationBox': {
      position: "fixed", 
      top: "80px",        
      right: "40px", 
      maxWidth: "475px",
      borderRadius: "20px",
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.4)",
      zIndex: 1,
      [theme.breakpoints.down(1150)]: {
        top: "150px",
        right: "140px",
      },
      [theme.breakpoints.down('xs')]: {
        top: '150px',
        right: '6px',
        marginLeft: '10px',
      },
    },
  },
      right: "40px",
      maxWidth: "475px",
      borderRadius: "20px",
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.4)",
      zIndex: 1,
      [theme.breakpoints.down(1150)]: {
        top: "150px",
        right: "140px",
      },
      [theme.breakpoints.down('xs')]: {
        top: '150px',
        right: '6px',
        marginLeft: '10px',
      },
    },
  },
  notificationPopup: {
    width: '470px',
    position: 'absolute',
    marginLeft: '700px',
    marginTop: "7px"
  },
  styleTabHead: {
    "& .MuiTabs-flexContainer": {
      display: 'flex',
      width: '400px',
      justifyContent: 'space-between',

    },
    "& .MuiTabs-indicator": {
      height: '4px',
      backgroundColor: "#F5EA16",
    },
    "& .MuiTab-root.Mui-selected": {
      color: '#000000',
    },
    borderBottom: '1px solid #E6E6E6',
    padding: '0px 32px',
    '@media(max-width: 697px)': {
      padding: '0px',
      "& .MuiTabs-flexContainer": {
        justifyContent: 'flex-start',
        width: 'auto',
      },
    },
  },
  styleTabNotification: {
    color: '#00000066',
    fontWeight: 500,
    fontSize: '14px',
    whiteSpace: 'nowrap',
    width: '104px',
    textTransform: 'none'
  }
});

//@ts-ignore
export default withStyles(styles)((SellerDashboard));
export { SellerDashboard }
export { NotificationBox };
//export default SellerDashboard;
// Customizable Area End
