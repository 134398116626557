import React from "react";

import {
    // Customizable Area Start
    Box,
    Typography,
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { withStyles } from "@material-ui/core/styles";

const truthyValue = (key: any) => {
    if (key !== "" || key !== null || !key.length) {
        return key
    } else {
        return ""
    }     
}

import {
    whiteAppLogo
} from "./assets";
// Customizable Area End

import LandingPageControllerWeb, {
    Props,
} from "./LandingPageControllerWeb";

class Terms extends LandingPageControllerWeb {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    abstractTermsData = () => {
        let mainHeading = ""
        let description = ""
        if (this.state.generalTermsData) {
            mainHeading = truthyValue(this.state.generalTermsData.attributes.heading)
            description = truthyValue(this.state.generalTermsData.attributes.description)
        }
        return { mainHeading, description }
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const { mainHeading, description } = this.abstractTermsData();
        // Customizable Area End
        return (
            // Customizable Area Start
            <div className={classes.initialHeight}>
                <div id="myref"></div>
                <Box className={classes.termsConditionHeader}>
                    <Box>
                        <img style={{ objectFit: 'contain' }} className={classes.termsConditionHeaderInner} src={whiteAppLogo} alt="" />
                    </Box>
                </Box>
                <Box className={classes.mainDiv}>
                    <Box
                        className={classes.titleWrap}>
                        <Typography
                            className={classes.title}>{mainHeading}
                        </Typography>
                    </Box>
                    <Box
                        className={classes.contentWrap}>
                        <Typography className={classes.content}
                            dangerouslySetInnerHTML={{ __html: description }}>
                        </Typography>
                    </Box>
                </Box>
            </div>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const styles = (theme: any) => ({
    initialHeight: {
        minHeight: '90vh'
    },
    title: {
        fontFamily: 'Poppins !important',
        color: '#000',
        fontStyle: 'normal',
        fontSize: '24px',
        fontWeight: 600,
        letterSpacing: '0.3px',
        lineHeight: '24px',
    },
    mainDiv:{
        maxWidth: '1160px', 
        width: '100%', 
        padding: '30px 15px 65px',
        margin: '0 auto',
        '@media (max-width: 991px)': {
            padding: '15px 15px 65px',
        },
    },
    contentTitleOuter: {
        fontFamily: 'Poppins !important',
        color: '#000',
        fontStyle: 'normal',
        fontSize: '18px',
        fontWeight: 500,
        padding: '22px 0',
        lineHeight: 'normal',
    },
    content: {
        color: '#000',
        fontSize: '18px',
        fontFamily: 'Poppins !important',
        fontWeight: 400,
        fontStyle: 'normal',
        padding: '15px 0',
        lineHeight: 'normal',
    },
    yellowContent: {
        fontFamily: 'Poppins !important',
        fontSize: '18px',
        lineHeight: 'normal',
        color: '#F5EA16',
        fontWeight: 700,
        fontStyle: 'normal',
    },
    contentWrap: {
        margin: '0 7px'
        // padding: '0 175px',
        // '@media (max-width: 1180px)': {
        //     padding: '0 58px',
        // },
        // '@media (max-width: 525px)': {
        //     padding: '0 20px',
        // },
    },
    contentTitle: {
        fontSize: '18px',
        fontStyle: 'normal',
        color: '#000',
        fontFamily: 'Poppins !important',
        lineHeight: 'normal',
        padding: '15px 0',
        fontWeight: 600,
    },
    contentTitleLite: {
        color: '#000',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '18px',
        fontFamily: 'Poppins !important',
        padding: '15px 0',
        lineHeight: 'normal',
    },
    blueLinkText: {
        fontFamily: 'Poppins !important',
        color: '#001AFF',
        fontStyle: 'normal',
        fontSize: '18px',
        lineHeight: 'normal',
        textDecoration: 'none',
        fontWeight: 400,
    },
    termsConditionHeader: {
        width: '100%',
        height: '100px',
        backgroundColor: '#000000',
    },
    termsConditionHeaderInner: {
        // objectFit: 'contain',
        marginLeft: '64px',
    }
});

export default withStyles(styles)(Terms);
export { Terms }
// Customizable Area End
