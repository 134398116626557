import React from "react";

import {
    // Customizable Area Start
    Box,
    Typography,
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { withStyles } from "@material-ui/core/styles";
import {
    whiteAppLogo
} from "./assets";

const truthyValue = (key: any) => {
    if (key !== "" || key !== null || !key.length) {
        return key
    } else {
        return ""
    }     
}
// Customizable Area End

import LandingPageControllerWeb, {
    Props,
} from "./LandingPageControllerWeb";

class About extends LandingPageControllerWeb {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    abstractAboutData = () => {
        let mainHeading = ""
        let heading1 = ""
        let heading2 = ""
        let heading3 = ""
        let img1 = ""
        let img2 = ""
        let img3 = ""
        let description1 = ""
        let description2 = ""
        let description3 = ""
        if (this.state.generalAboutData) {
            mainHeading = truthyValue(this.state.generalAboutData.attributes.main_heading)
            heading1 = truthyValue(this.state.generalAboutData.attributes.heading_1)
            heading2 = truthyValue(this.state.generalAboutData.attributes.heading_2)
            heading3 = truthyValue(this.state.generalAboutData.attributes.heading_3)
            img1 = truthyValue(this.state.generalAboutData.attributes.image1)
            img2 = truthyValue(this.state.generalAboutData.attributes.image2)
            img3 = truthyValue(this.state.generalAboutData.attributes.image3)
            description1 = truthyValue(this.state.generalAboutData.attributes.description_1)
            description2 = truthyValue(this.state.generalAboutData.attributes.description_2)
            description3 = truthyValue(this.state.generalAboutData.attributes.description_3)
        }
        return { mainHeading, heading1, heading2, heading3, img1, img2, img3, description1, description2, description3 }
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const { mainHeading, heading1, heading2, heading3, img1, img2, img3, description1, description2, description3 } = this.abstractAboutData();
        // Customizable Area End
        return (
            // Customizable Area Start
            <div className={classes.initialHeight}>
                <div id="myref"></div>
                <Box className={classes.termsConditionHeader}>
                    <Box>
                        <img className={classes.termsConditionHeaderInner} src={whiteAppLogo} alt="" />
                    </Box>
                </Box>
                <Box className={classes.mainDiv}>
                    <Box className={classes.titleWrap}>
                        <Typography className={classes.title}>{mainHeading}</Typography>
                    </Box>
                    {img1 &&
                        <Box className={classes.imgContentWrap}>
                            <Box className={classes.imgWrap}>
                                <Box className={classes.imgBorder}>
                                    <img src={img1} alt="about image 1" className={classes.contentImage} />
                                </Box>
                            </Box>
                            <Box className={classes.contentWrap}>
                                <Typography
                                    className={classes.contentTitle}>{heading1}</Typography>
                                <Typography className={classes.content} dangerouslySetInnerHTML={{ __html: description1 }}></Typography>
                            </Box>
                        </Box>
                    }
                    {img2 &&
                        <Box className={`${classes.imgContentWrap} ${classes.imgContentWrapReverse}`}>
                            <Box className={classes.contentWrap}>
                                <Typography
                                    className={classes.contentTitle}>{heading2}</Typography>
                                <Typography className={classes.content} dangerouslySetInnerHTML={{ __html: description2 }}></Typography>
                            </Box>
                            <Box className={classes.imgWrap}>
                                <Box className={classes.imgBorder}>
                                    <img src={img2} alt="about image 2" className={classes.contentImage} /></Box>
                            </Box>
                        </Box>
                    }
                    {img3 &&
                        <Box className={classes.imgContentWrap}>
                            <Box className={classes.imgWrap}>
                                <Box className={classes.imgBorder}>
                                    <img src={img3} alt="about image 3" className={classes.contentImage} />
                                </Box>
                            </Box>
                            <Box className={classes.contentWrap}>
                                <Typography
                                    className={classes.contentTitle}>{heading3}</Typography>
                                <Typography className={classes.content} dangerouslySetInnerHTML={{ __html: description3 }}></Typography>
                            </Box>
                        </Box>
                    }
                </Box>
            </div>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const styles = (theme: any) => ({
    initialHeight: { minHeight: '90vh' },
    mainDiv: {
        maxWidth: '1160px',
        width: '100%',
        padding: '30px 15px 65px',
        margin: '0 auto',
        '@media (max-width: 991px)': {
            padding: '15px 15px 65px',
        },
    },
    titleWrap: {
        // padding: '65px 188px'
        margin: '0 0 10px'
    },
    title: {
        color: '#000',
        fontFamily: 'Poppins !important',
        fontSize: '24px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '16px',
        letterSpacing: '0.3px',
    },
    imgContentWrap: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: '71px 0',
        margin: '0 -15px -30px',
        '@media (max-width: 991px)': {
            flexDirection: 'column',
            padding: '35px 0',
        },
    },
    imgContentWrapReverse: {
        '@media (max-width: 991px)': {
            flexDirection: 'column-reverse',
        },
    },
    contentTitle: {
        color: '#000',
        fontFamily: 'Poppins !important',
        fontSize: '24px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
    },
    content: {
        color: '#000',
        fontFamily: 'Poppins !important',
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
        marginTop: '15px'
    },
    yellowContent: {
        color: '#F5EA16',
        fontFamily: 'Poppins !important',
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: 'normal',
    },
    imgWrap: {
        flex: '0 0 50%',
        maxWidth: '50%',
        padding: '0 20px',
        margin: '0 0 30px',
        '@media (max-width: 991px)': {
            flex: '0 0 100%',
            maxWidth: '100%',
        },
    },
    contentWrap: {
        flex: '0 0 50%',
        maxWidth: '50%',
        padding: '0 20px',
        margin: '0 0 30px',
        '@media (max-width: 991px)': {
            flex: '0 0 100%',
            maxWidth: '100%',
        },
    },
    contentImage: {
        maxWidth: '100%',
        height: 'auto',
        display: 'block',
        margin: '0 auto',
        objectFit: 'cover'
    },
    imgBorder: {
        borderRadius: '16px',
        overflow: 'hidden'
    },
    termsConditionHeader: {
        width: '100%',
        height: '100px',
        backgroundColor: '#000000',
    },
    termsConditionHeaderInner: {
        objectFit: 'contain',
        marginLeft: '64px',
    }
});

// @ts-ignore
export default withStyles(styles)(About);
export { About }
// Customizable Area End
