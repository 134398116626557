import React from "react";

import {
    // Customizable Area Start
    Box,
    Grid,
    Typography,
    TextField,
    Button,
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import {
    twoWave,
    App_Logo
} from "./assets";
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";


const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});
import { configJSON } from "./AccountRegistrationController";
// Customizable Area End

import AccountRegistrationController, {
    Props,
} from "./AccountRegistrationController";

class EditEmail extends AccountRegistrationController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        // Customizable Area End
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <section className={classes.sectionEditEmail}>
                    <Box className={classes.editEmailWrapper}>
                        <Box className={'editEmailOverlayImg'}>
                            <img src={twoWave} alt="" />
                        </Box>
                        <Box className={classes.editEmailInner}>
                            <Grid container spacing={2}>
                                <Grid item lg={5} md={6} sm={12} xs={12} style={{ display: 'flex', alignItems: "center" }}>
                                    <Box className={'editEmailLeftColumn'}>
                                        <img src={App_Logo} alt="" />
                                        <Typography variant="h6" component={'h6'}>{'Live shopping & market place'}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item lg={7} md={6} sm={12} xs={12}>
                                    <Box className={'editEmailRightColumn'}>
                                        <Box className={'editEmailRightColumnInner'}>
                                            <Box className={'editEmailHeading'}>
                                                <Typography variant="h6" component={'h6'} className={classes.editEmailSectionTitle}>{'Edit your Email Id '}</Typography>
                                                <Typography component={'p'}>{'Enter your email ID to log in to Flixoo.'}</Typography>
                                            </Box>
                                            <Box className={'editEmailInputFeild'} style={{ width: '100%' }}>
                                                <TextField
                                                    id="outlined-textarea"
                                                    data-test-id="editEmail"
                                                    label="Email Id"
                                                    variant="outlined"
                                                    onFocus={this.handleFocusEmail}
                                                    placeholder="Enter your email id"
                                                    onChange={this.handleChangeEmailSeller}
                                                    value={this.state.emailSellerSignup}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}

                                                />
                                                <p style={{ color: 'red' }}>{this.state.emailError}</p>
                                            </Box>
                                            <Button
                                                data-test-id="editEmailSubmit"
                                                onClick={this.editEmailVerifications}
                                                className={classes.getOtpButtonEmail}
                                            >{configJSON.getOtp}
                                            </Button>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </section>
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const styles = (theme: any) => ({
    getOtpButtonEmail: {
        width: '100%',
        backgroundColor: '#F5EA16',
        color: '#444444',
        height: '56px',
        fontFamily: 'Poppins',
        lineHeight: '24px',
        borderRadius: '24px',
        textTransform: "none" as const,
        pointer: "cursor",
        fontSize: '1em  !important',
        fontWeight: 600,
        '&:hover': {
            backgroundColor: '#F5EA16',
        },
    },
    editEmailSectionTitle: {
        color: 'var(--0, #FFF) !important',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: 'Poppins',
        fontSize: '32px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: 'normal',
    },
    sectionEditEmail: {
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            height: '100vh',
        },
        color: '#ffffff !important',
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            fontSize: '15px',
            lineHight: '18px',
            color: 'rgba(255, 255, 255, 0.5) !important',
            padding: '0 5px',
            backgroundColor: 'black',
        },
        '& .MuiCheckbox-colorPrimary': {
            color: "#ffffff",
            height: 'max-content',
        },
        '& .MuiCheckbox-colorPrimary.Mui-checked': {
            color: 'yellow',
        },
    },
    editEmailWrapper: {
        width: '100%',
        height: '100vh',
        maxHeight: '100vh',
        backgroundColor: '#000000',
        [theme.breakpoints.down('sm')]: {
            height: '100vh',
        },
        position: 'relative' as const,
        '& .editEmailOverlayImg': {
            position: 'absolute',
            width: '100%',
            height: 'auto',
            bottom: '0',
            left: '0',
            '& img': {
                width: '100%',
                objectFit: 'cover',
            },
        },
    },
    editEmailInner: {
        padding: '0 100px',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            padding: '20px',
        },
        '& p': {
            margin: '5px 0 0 0',
            textAlign: 'start',
        },
        '& .editEmailLeftColumn': {
            [theme.breakpoints.down('sm')]: {
                marginTop: '0%',
            },
            '& img': {
                maxWidth: '156px',
                lineHeight: '62px',
            },
            '& h6': {
                fontFamily: 'Poppins-Medium',
                color: '#ffffff',
                fontSize: '20px',
                lineHeight: '30px',
            },
        },
        '& .editEmailRightColumn': {
            border: "1px solid rgba(255, 255, 255, 0.5)",
            borderRadius: '20px',
            minHeight: '428px',
            maxWidth: '660px',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            [theme.breakpoints.down('sm')]: {
                padding: '20px',
            },
            [theme.breakpoints.down('xs')]: {
                padding: '10px',
            },

            '& .editEmailRightColumnInner': {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-evenly',
                alignItems: 'center',
                textAlign: 'center',
                maxWidth: '542px',
                width: '100%',
                '& .MuiFormControl-root': {
                    width: '100%',
                },
                '& .MuiOutlinedInput-input': {
                    color: '#ffffff',
                    border: '1px solid',
                    borderColor: 'rgba(255, 255, 255, 0.5)',
                    borderRadius: '12px',
                    height: '56px',
                    padding: '0px 5px',
                },
                '& .editEmailHeading': {
                    maxWidth: '425px',
                    margin: '0 auto',
                    width: '100%',
                    '& h6, & p': {
                        color: 'white',
                    },
                    '& h6': {
                        fontFamily: 'Poppins-SemiBold',
                        fontSize: '32px',
                        lineHieght: '48px',
                    },
                    '& p': {
                        fontFamily: 'Poppins-Regular',
                        fontSize: '18px',
                        lineHeight: '27px',
                        opacity: '0.5',
                    },
                },
                "& .MuiOutlinedInput-notchedOutline": {
                    border: "none !important"
                },
                '& .MuiInputBase-input::placeholder': {
                    color: '#ffffff',
                    opacity: 1,
                    fontSize: '14px'
                },
                '& .MuiInputBase-input': {
                    '&:focus': {
                        caretColor: 'white',
                    },
                },
            },
        },
    },
});

export default withStyles(styles)(EditEmail);
export { EditEmail }
// Customizable Area End
