import React, { Component } from "react";
import { Grid, Box, Typography, Avatar, Button,styled } from "@material-ui/core";

const ScrollContainer = styled(Box)({
  display: "flex",
  overflowX: "auto",
  padding: "10px 0",
  gap: "16px",
  "&::-webkit-scrollbar": {
    height: "8px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#ccc",
    borderRadius: "4px",
  },
});

const ImageCard = styled(Box)({
  flex: "0 0 auto",
  width: "158px",
  borderRadius: "8px",
  overflow: "hidden",
  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
  position: "relative",
  "& img": {
    width: "100%",
    height: "120px",
    objectFit: "cover",
  },
  "& button": {
    width:"132px",
    position: "absolute",
    bottom: "19px",
    left: "42%",
    transform: "translateX(-50%)",
    background: "none",
    color: "#fff",
    fontSize: "16px",
    padding: "4px 8px",
    fontWeight: 500,
    borderRadius: "16px",

  },
});

interface HeaderProps {
  title: string;
  category: string;
  description: string;
  avatarUrl: string;
  images: { src: string; alt: string }[];
  buttonLabel?: string;
  onButtonClick?: (index: number) => void;
}

class Header extends Component<HeaderProps> {
  static defaultProps = {
    buttonLabel: "Visit Store →",
    onButtonClick: () => {},
  };

  handleButtonClick = (index: number) => {
    if (this.props.onButtonClick) {
      this.props.onButtonClick(index);
    }
  };

  render() {
    const { title, category, description, avatarUrl, images, buttonLabel } = this.props;

    return (
      <Box sx={{ maxWidth: "574px", margin: "0 auto", padding: "1px 24px", border: "1px solid #6E6E6E33", borderRadius: "12px" }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <Avatar src={avatarUrl} alt="Profile"  style={{width:"64px", height:"64px"}} />
          </Grid>
          <Grid style={{marginTop:"25px"}} item xs>
            <Typography style={{fontSize:"18px", fontWeight: 600, color:"#2E2E2E"}}>{title}</Typography>
            <Typography style={{fontSize:"14px", fontWeight: 400, color:"#4E4E4E"}}>
              {category}
            </Typography>
            <Typography style={{fontSize:"14px", fontWeight: 400, color: "##6E6E6E33"}}>
              {description}
            </Typography>
          </Grid>
        </Grid>

        <ScrollContainer>
          {images.map((image, index) => (
            <ImageCard key={index}>
              <img src={image.src} alt={image.alt} />
              <Button onClick={() => this.handleButtonClick(index)}>{buttonLabel}</Button>
            </ImageCard>
          ))}
        </ScrollContainer>
      </Box>
    );
  }
}

export default Header;
