import React from 'react';
import { Grid, Box, Typography, TextField, Button } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { styled } from '@material-ui/core/styles';


const SmallImageBox = styled(Box)({
    '@media(max-width: 956px)': {
        display: 'none'
    },
})

const LeftGrid = styled(Grid)({
    background: '#404040',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    '@media(max-width: 959px)': {
        height: 'auto',
        background: 'black',
        border: 'none'
    },
})

const RightGrid = styled(Grid)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'black',
    width: '100%',
    position: "relative",
    overflow: "hidden",
})

const RightBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '500px',
    padding: '16px',
    width: '100%',
    maxWidth: '666px',
    gap: '16px',
    position: 'relative',
    zIndex: 1,
    '@media(max-width: 956px)': {
        justifyContent: 'normal',
    },
})

const HeadingTypography = styled(Typography)({
    color: '#FFFFFF',
    fontSize: '60px',
    fontWeight: 500,
    lineHeight: '90px',
    textAlign: 'center',
    '@media(max-width: 550px)': {
        fontSize: '33px',
        lineHeight: '50px',
    }
})

const SubHeadingTypography = styled(Typography)({
    color: '#FFFFFF',
    fontSize: '18px',
    lineHeight: '27px',
    textAlign: 'center',
    maxWidth: '422px',
    width: '100%',
    '@media(max-width: 550px)': {
        fontSize: '16px'
    }
})

const ImageSubBox = styled(Box)({
    maxWidth: 800,
    position: 'relative',
    '@media(max-width: 1539px)': {
        transform: 'scale(0.8)'
    },
})

const OverlayBox = styled(Box)({
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: 'rgba(0, 0, 0, 0.50)',
    filter: 'blur(13px)',
    width: '100vw',
    height: '100vh',
});

const AlertBox = styled(Alert)({
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: 'white',
    textAlign: 'center',
    borderRadius: 20,
    minWidth: 300,
    zIndex: 1,
    '& .MuiSvgIcon-root': {
        position: 'absolute',
        right: '10px',
        cursor: 'pointer'
    },
    '& .MuiAlert-message': {
        width: '100%'
    },
    '& .MuiAlert-icon': {
        display: 'none'
    },
    "& .MuiButton-containedPrimary:hover": {
        backgroundColor: "#8080805e"
    }
});

const AlertHeadText = styled(Box)({
    color: '#000',
    textAlign: 'center',
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: '27px',
    borderBottom: '1px solid #E9E9E9',
    padding: '0px 10px 10px',
    maxWidth: '200px',
    margin: 'auto',
});

const AlertText = styled(Box)({
    color: '#000',
    textAlign: 'center',
    fontSize: '18px',
    lineHeight: '27px',
    padding: '10px'
})

const CountryDiv = styled('div')({
    '&::-webkit-scrollbar': {
        display: 'none',
    },

    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',
})

const InputGrid = styled(Grid)({
    '& img.showStar': {
        filter: 'brightness(15)',
        width: 30
    }
});

const ErrorTag = styled(Typography)({
    fontSize: '16px',
    color: 'red',
    margin: '-16px 0px 16px',
    textAlign: 'start',
    width: '100%',
    '@media(max-width: 550px)': {
        fontSize: '14px'
    }
});

const InputTag = styled(TextField)({
    width: '100%',
    borderRadius: '12px',
    backgroundColor: 'white',
    color: 'white',
    height: '56px',
    '& ::placeholder': {
        color: 'rgba(0, 0, 0)',
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: 'unset'
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
        border: 'unset'
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
        padding: '0 10px',
        background: 'black',
        color: '#FFFFFF80',
        fontSize: '18px',
        fontStyle: 'normal',
    },
    '& .MuiOutlinedInput-input': {
        padding: "18.5px",
    },
});

const FormBox = styled(Grid)({
    width: '100%',
    maxWidth: '634px',
    justifyContent: 'center',
    '@media (max-width: 600px)': {
        width: '90%',
        margin: '0 auto',
    },
    '& .MuiInput-underline::before': {
        borderBottom: 'none',
    },
    '& .MuiInput-underline::after': {
        borderBottom: 'none',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled)::before': {
        borderBottom: 'none'
    },
    "& .MuiNativeSelect-select:focus": {
        backgroundColor: 'white',
    },
    '& .MuiOutlinedInput-input': {
        fontSize: 18,
        border: '1px solid rgba(255, 255, 255, 0.50)',
        background: 'black',
        borderRadius: 12,
        color: 'white'
    },
    "& .MuiCheckbox-root": {
        color: "white",
        background: "white",
        height: "17px",
        width: "17px",
        borderRadius: "5px"
    },
    "& .Mui-checked.MuiCheckbox-root": {
        color: "#F5EA16",
        background: "black"
    }
});

const StyledButton = styled(Button)({
    borderRadius: '34px',
    background: '#F5EA16',
    width: '100%',
    maxWidth: '412px',
    height: '68px',
    color: '#000000',
    fontSize: '22px',
    fontWeight: 500,
    lineHeight: '33px',
    textTransform: 'none',
    '&:hover': {
        backgroundColor: '#F5EA16'
    },
});


const InputTagPassword = styled(TextField)({
    width: '100%',
    borderRadius: '12px',
    backgroundColor: 'black',
    color: 'white',
    '& .MuiOutlinedInput-input': {
        padding: '18.5px 22px'
    },
    '& ::placeholder': {
        color: 'rgba(0, 0, 0)',
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: 'unset'
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
        border: 'unset'
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
        padding: '0 10px',
        background: 'black',
        color: '#FFFFFF80',
        fontSize: '18px',
        fontStyle: 'normal',
    },

});

const EyeImage = styled('img')({
    position: 'absolute',
    right: 20,
    top: 17,
    zIndex: 1,
    cursor: "pointer",
    height: '24px',
    width: '24px !important',
});

const TimerBox = styled(Box)({
    '@media(max-width: 480px)': {
        maxWidth: "300px",
        margin: "auto"
    }
})

const FormBoxVerifyOTP = styled(Grid)({
    width: '100%',
    maxWidth: '478px',
    margin: "auto",
    '& .otp-group': {
        display: "flex",
        gap: "12.5px",
        '& input': {
            fontSize: "24px",
            border: '3px solid #ffffff7a',
            borderRadius: '16px!important',
            textDecoration: '2px solid underline',
            textUnderlinePosition: 'under'
        },
    },
    '@media (max-width: 600px)': {
        width: '90%',
        margin: '0 auto',
        justifyContent: 'center'
    },
    '& .MuiInput-underline::before': {
        borderBottom: 'none',
    },
    '& .MuiInput-underline::after': {
        borderBottom: 'none',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled)::before': {
        borderBottom: 'none'
    },
    "& .MuiNativeSelect-select:focus": {
        backgroundColor: 'white',
    },
    '& .MuiOutlinedInput-input': {
        fontSize: 31,
        border: '1px solid rgba(255, 255, 255, 0.50)',
        background: 'black',
        borderRadius: 12,
        color: 'white',
        height: '46px',
        textAlign: 'center'
    },
    "& .MuiCheckbox-root": {
        color: "#fff"
    },
    '& input::-webkit-outer-spin-button': {
        WebkitAppearance: 'none',
    },
    '& input::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
    },
    '& input[type="number"]': {
        MozAppearance: 'textfield',
        textDecoration: 'underline'
    }
});

export {
    SmallImageBox,
    LeftGrid,
    RightGrid,
    RightBox,
    HeadingTypography,
    SubHeadingTypography,
    ImageSubBox,
    OverlayBox,
    AlertBox,
    AlertHeadText,
    AlertText,
    CountryDiv,
    InputGrid,
    ErrorTag,
    InputTag,
    FormBox,
    StyledButton,
    InputTagPassword,
    EyeImage,
    TimerBox,
    FormBoxVerifyOTP
}