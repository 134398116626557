import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
const configJSON = require("./config");
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    classes: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    InputValue: string;
    PhoneError: string;
    ShowMobileField: boolean;
    EmailError: string;
    Mobile: string;
    Phone: string;
    Email: string;
    Loading: boolean;
    SelectedValue: any;
    ShowAlert: boolean;
    DataSource: any;
    OtpState: any;
    ShowPassword: boolean;
    Password: string;
    PasswordError: string;
    CopyPassword: string;
    ShowConfirmPassword: boolean;
    ConfirmPassword: string;
    ConfirmPasswordError: string;
    CopyConfirmPassword: string;
    isMobileSelected: boolean;
    errMessage: any;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class SellerForgotPasswordController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    SendOtpApiCallId: string = '';
    GetCountryCodeApiCallId: string = '';
    ResetPasswordApiCallId: string = '';
    ValidateOTPApiCallId: string = '';
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
        ];

        this.state = {
            Loading: false,
            ShowMobileField: false,
            InputValue: '',
            EmailError: '',
            PhoneError: '',
            Phone: '',
            Email: '',
            Mobile: '',
            SelectedValue: {},
            ShowAlert: false,
            DataSource: [],
            OtpState: '',
            ShowPassword: false,
            Password: '',
            PasswordError: '',
            CopyPassword: '',
            ShowConfirmPassword: false,
            ConfirmPassword: '',
            ConfirmPasswordError: '',
            CopyConfirmPassword: '',
            isMobileSelected: false,
            errMessage: ''
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId === this.GetCountryCodeApiCallId) {
                if (responseJson.data) {
                    this.setState({
                        SelectedValue: responseJson.data[0],
                        DataSource: responseJson.data,
                    });
                }
            } else if (apiRequestCallId === this.SendOtpApiCallId) {
                this.setState({ Loading: false });
                if (responseJson.data) {
                    setStorageData('sellerVerifyOtpToken', responseJson.meta.token);
                    (window.location.pathname === '/SellerForgotPassword' && setStorageData("sellerResendOTP", this.state.ShowMobileField ? this.state.Phone : this.state.Email));
                    this.props.navigation.navigate(`SellerVerifyOtp`)
                }
                else if (responseJson.errors[0]) {
                    this.setState({ ShowAlert: true, errMessage: Object.values(responseJson.errors[0]) })
                }
            } else if (apiRequestCallId === this.ValidateOTPApiCallId) {
                this.setState({ Loading: false });
                if (responseJson.messages?.[0]) {
                    removeStorageData('sellerVerifyOtpToken')
                    removeStorageData('sellerResendOTP')
                    setStorageData('sellerResetPasswordToken', responseJson.meta.token);
                    this.props.navigation.navigate(`SellerResetPassword`)
                }
                else if (responseJson.errors?.[0]) {
                    this.setState({ ShowAlert: true, errMessage: Object.values(responseJson.errors[0]) })
                }
            } else if (apiRequestCallId === this.ResetPasswordApiCallId) {
                this.setState({ Loading: false });
                if (responseJson.data) {
                    removeStorageData('sellerResetPasswordToken')
                    this.props.navigation.navigate(`SellerLogin`)
                }
            }

        }
        // Customizable Area End
    }

    async componentDidMount() {
        // Customizable Area Start
        const sellerResendOTP = await getStorageData("sellerResendOTP");
        this.setState({
            isMobileSelected: /[a-zA-Z]/.test(sellerResendOTP)
        })
        // Customizable Area End
    }

    // Customizable Area Start

    closeAlert = () => {
        this.setState({ ShowAlert: false })
    }

    handleOTPState = (val: any) => {
        this.setState({ OtpState: val })
    }

    setError = (stateKey: keyof S, errorMsg: string) => {
        this.setState((prevState) => ({
            ...prevState,
            [stateKey]: errorMsg,
        }));
    };

    handleInputValidation = () => {
        const { ShowMobileField, Phone, Email } = this.state;

        if (ShowMobileField) {
            if (Phone.trim().length === 0) {
                this.setState({ PhoneError: "" });
                return true;
            }
            if (Phone.length !== 12) {
                this.setError("PhoneError", 'Please Enter Valid Mobile number');
                return false;
            }
            this.setState({ PhoneError: "" });
        } else {
            if (!ShowMobileField) {
                if (Email.trim().length === 0) {
                    this.setState({ EmailError: "" });
                    return true;
                }
                if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,3}$/g.test(Email)) {
                    this.setError("EmailError", 'Enter valid email i.e. johndoe@gmail.com');
                    return false;
                }
                this.setState({ EmailError: "" });
            }
        }

        return true;
    };

    handleInputChange = (event: any) => {
        const InputValue = event.target.value;
        const hasCharacter = /[a-zA-Z]/.test(InputValue);

        if (this.state.ShowMobileField) {
            const numericValue = InputValue.replace(/\D/g, '');
            this.setState({ Phone: numericValue, PhoneError: "" }, () => {
                if (this.state.Phone.trim().length === 0) {
                    this.setState({ PhoneError: "" });
                }
            });
        } else {
            this.setState({ Email: InputValue, EmailError: "" }, () => {
                if (this.state.Email.trim().length === 0) {
                    this.setState({ EmailError: "" });
                }
            });
        }

        this.setState({ ShowMobileField: InputValue === "" ? false : !hasCharacter });
    };

    sendOtp = () => {
        const { ShowMobileField, Phone, Email } = this.state;

        let isValid = true;

        if (ShowMobileField) {
            if (Phone.length !== 12) {
                this.setError("PhoneError", 'Please Enter Valid Mobile number');
                isValid = false;
            } else {
                this.setState({ PhoneError: "" });
            }
        } else {
            if (Email !== "" && !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,3}$/g.test(Email)) {
                this.setError("EmailError", 'Enter valid email i.e. johndoe@gmail.com');
                isValid = false;
            } else if (Email == "") {
                this.setError("EmailError", 'Please Enter Valid Email Id / Mobile number');
                isValid = false;
            } else {
                this.setState({ EmailError: "" });
            }
        }

        if (isValid) {
            this.continueSendOtp();
        }
    };

    handlePasswordKey = (event: any) => {
        const { ShowPassword, CopyPassword } = this.state;
        const keyCode = event.keyCode || event.which;
        if (keyCode == 8) {
            const position = event.target.selectionStart;
            if (!ShowPassword) {
                let value = CopyPassword.split("")
                value.splice(position - 1, 1)
                this.setState({ CopyPassword: value.join("") })

            }
        }
    }

    handleConfirmPasswordKey = (event: any) => {
        const { ShowConfirmPassword, CopyConfirmPassword } = this.state;
        const keyCode = event.keyCode || event.which;
        if (keyCode == 8) {
            const position1 = event.target.selectionStart;
            if (!ShowConfirmPassword) {
                let value = CopyConfirmPassword.split("")
                value.splice(position1 - 1, 1)
                this.setState({ CopyConfirmPassword: value.join("") })

            }
        }
    }

    handlePasswordValidation = () => {
        const { CopyPassword, CopyConfirmPassword } = this.state;

        const resetErrors = () => {
            this.setState({
                PasswordError: "",
                ConfirmPasswordError: ""
            });
        };

        if (CopyPassword.trim().length === 0) {
            resetErrors();
            return true;
        }

        const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%^&+=!]).{8,}$/;
        if (!passwordRegex.test(CopyPassword)) {
            this.setError("PasswordError", configJSON.errorPasswordNotValid);
            return false;
        }

        resetErrors();

        if (CopyConfirmPassword.trim().length === 0) {
            return true;
        }

        if (CopyPassword !== CopyConfirmPassword) {
            this.setError("ConfirmPasswordError", configJSON.confirmPasswordError);
            return false;
        }

        return true;
    };

    handlePasswordChange = (event: any) => {
        const { ShowPassword, CopyPassword } = this.state;
        const { value } = event.target;
        this.setState({ Password: value });

        const updatedCopyPassword = ShowPassword ? value : CopyPassword + value.split("*").join("");
        this.setState({ CopyPassword: updatedCopyPassword, PasswordError: "" });
    };

    toggleShowPassword = () => {
        this.setState((prevState) => ({
            ShowPassword: !prevState.ShowPassword,
        }));
    };

    handleConfirmPasswordChange = (event: any) => {
        const { value } = event.target;
        const { ShowConfirmPassword, CopyConfirmPassword } = this.state;

        this.setState({ ConfirmPassword: value });

        const updatedCopyConfirmPassword = ShowConfirmPassword ? value : CopyConfirmPassword + value.split("*").join("");
        this.setState({ CopyConfirmPassword: updatedCopyConfirmPassword, ConfirmPasswordError: "" });
    };

    toggleShowConfirmPassword = () => {
        this.setState((prevState) => ({
            ShowConfirmPassword: !prevState.ShowConfirmPassword,
        }));
    };

    handleResetPassword = () => {
        const { CopyPassword, CopyConfirmPassword } = this.state;

        if (!/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%^&+=!]).{8,}$/.test(CopyPassword)) {
            this.setError("PasswordError", configJSON.errorPasswordNotValid);
        } else {
            this.setState({ PasswordError: "" });

            if (CopyPassword !== CopyConfirmPassword) {
                this.setError("ConfirmPasswordError", configJSON.confirmPasswordError);
            } else {
                this.setState({ ConfirmPasswordError: "" });
                this.resetPassword();
            }
        }
    }

    getCountryCode = () => {
        const header = { "Content-Type": "application/json" };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.GetCountryCodeApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `account/accounts/country_code_and_flag`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    continueSendOtp = () => {
        this.setState({ Loading: true });
        const { Email, Phone } = this.state

        const emailBody = {
            "data": {
                "attributes": {
                    "email": Email
                }
            }
        }

        const mobileBody = {
            "data": {
                "attributes": {
                    "full_phone_number": Phone
                }
            }
        }

        const header = { "Content-Type": "application/json" };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.SendOtpApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_forgot_password/otps`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(this.state.ShowMobileField ? mobileBody : emailBody));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    resendOtp = async () => {
        this.setState({ Loading: true });
        const payload = await getStorageData("sellerResendOTP");
        const mobileBody = {
            "data": {
                "attributes": {
                    "full_phone_number": payload
                }
            }
        }

        const emailBody = {
            "data": {
                "attributes": {
                    "email": payload
                }
            }
        }

        const header = { "Content-Type": "application/json" };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.SendOtpApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_forgot_password/otps`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(/[a-zA-Z]/.test(payload) ? emailBody : mobileBody));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    validateOTP = async () => {
        this.setState({ Loading: true });

        const httpBody = {
            "data": {
                "otp_code": this.state.OtpState
            }
        }

        const header = { "Content-Type": "application/json", token: await getStorageData('sellerVerifyOtpToken') };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.ValidateOTPApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_forgot_password/otp_confirmations`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    resetPassword = async () => {
        this.setState({ Loading: true });

        const { CopyPassword } = this.state

        const httpBody = {
            "data": {
                "token": await getStorageData('sellerResetPasswordToken'),
                "new_password": CopyPassword
            }
        }

        const header = { "Content-Type": "application/json" };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.ResetPasswordApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_forgot_password/passwords`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    // Customizable Area End
}