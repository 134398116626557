import React from "react";

import {
    // Customizable Area Start
    Box,
    Grid,
    Typography,
    TextField,
    styled,
    Button,
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import {
    twoWave,
    App_Logo
} from "./assets";
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});
import {configJSON} from "./AccountRegistrationController";
// Customizable Area End

import AccountRegistrationController, {
    Props,
} from "./AccountRegistrationController";

class EditMobileNumber extends AccountRegistrationController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        // Customizable Area End
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <section className={classes.sectionEditMobileNumber}>
                    <Box className={classes.editMobileNumberWrapper}>
                        <Box className={'overlayImg'}>
                            <img src={twoWave} alt="" />
                        </Box>
                        <Box className={classes.editMoibleNumberInner}>
                            <Grid container spacing={2}>
                                <Grid item lg={5} md={6} sm={12} xs={12} style={{ display: 'flex', alignItems: "center" }}>
                                    <Box className={'leftColumn'}>
                                        <img src={App_Logo} alt="" />
                                        <Typography variant="h6" component={'h6'}>{'Live shopping & market place'}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item lg={7} md={6} sm={12} xs={12}>
                                    <Box className={'rightSideBox'}>
                                        <Box className={'divRightColumnInner'}>
                                            <Box className={"rightSideTop"}
                                                sx={{ display: 'flex', flexDirection: 'Column', gridGap: '40px' }}>
                                                <Box className={'editMobileNumberHeading'}>
                                                    <Typography variant="h6" component={'h6'} className={classes.editsectionTitle}>{configJSON.editYourMobileNumber}</Typography>
                                                    <Typography component={'p'}>{'Enter your 10 digit mobile number to log in into Flixoo'}</Typography>
                                                </Box>
                                                <Box className="inputFeildEditMobileNumber">
                                                    <Box sx={{ display: 'flex', gridGap: '15px' }}>
                                                        <div>
                                                            <div className={classes.countryInputField} >
                                                                <label className={'divCountryCodeLable'}>Code</label>
                                                                <div className={'countryCodeSelect'} data-test-id="countryCode" onClick={this.handleIsSelect} >

                                                                    <p style={{ paddingRight: '10px', transform: "scale(2)" }}
                                                                        defaultValue={"in"}>{this.state.selectedValue?.label}</p> {this.state.selectedValue?.value}
                                                                                                                                         {this.state.isSelect ? <ExpandLessIcon style={{ marginRight: '-15px' }} /> : <ExpandMoreIcon style={{ marginRight: '-15px' }} />}   
                                                                </div>
                                                                {this.state.isSelect &&
                                                                    <CountryCodeDiv style={{
                                                                        position: 'absolute', top: '65px', width: '114px', listStyle: 'none', background: 'black',
                                                                        borderRadius: 10, border: '1px solid rgba(255, 255, 255, 0.50)', zIndex: 11, height: 150, overflowY: 'scroll'
                                                                    }}>
                                                                        {this.state.dataSource.map((item: any, index: any) => (
                                                                            <li data-test-id={`countryValue${index}`} key={index} onClick={this.selectCountryFlag.bind(this, item)} style={{ color: 'white', padding: '7px 14px', borderBottom: '1px solid rgba(255, 255, 255, 0.50)' }}>
                                                                                <span style={{ paddingRight: 8, display: 'inline-block' }}>{item.label}</span>
                                                                                {item?.value}</li>
                                                                        ))}
                                                                    </CountryCodeDiv>}
                                                            </div>
                                                        </div>

                                                        <TextField
                                                            id="outlined-textarea"
                                                            data-test-id="mobileNo"
                                                            label="Mobile number"
                                                            name="mobileNumber"
                                                            variant="outlined"
                                                            onFocus={this.handleFocusMobile}
                                                            placeholder={configJSON.mobileNumberLabel}
                                                            value={this.state.phoneNumber}
                                                            onChange={(event: any) => this.handlePhoneChange(event.target.value)}
                                                            error={this.state.mobileNumberError != ""}
                                                            helperText={this.state.mobileNumberError}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            style={{ color: '#ffffff', border: 'rgba(255, 255, 255, .5)', width: '100%' }}
                                                        />
                                                    </Box>
                                                </Box>
                                                <Box>
                                                    <Button
                                                        className={classes.getOtpButton}
                                                        data-test-id="editMobileNo"
                                                        onClick={this.editMoibleVerifications}>{configJSON.getOtp}
                                                    </Button>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </section>
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const styles = (theme: any) => ({
    getOtpButton:{
            width: '100%',
            backgroundColor: '#F5EA16',
            color: '#444444',
            height: '56px',
            fontFamily: 'Poppins',
            lineHeight: '24px',
            borderRadius: '24px',
            textTransform:"none" as const,
            pointer:"cursor",
            fontSize: '1em  !important',
            fontWeight: 600,
            '&:hover': {
                backgroundColor: '#F5EA16',
            },
    },
    editsectionTitle:{
        fontSize: '2em  !important',
        fontWeight: 600,
        lineHeight: 'normal',
    },
    sectionEditMobileNumber: {
        width: '100%',
        height: '100vh',
        color: '#ffffff !important',
        [theme.breakpoints.down('sm')]: {
            height: '100vh',
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            fontSize: '15px',
            lineHight: '18px',
            color: 'rgba(255, 255, 255, 0.5) !important',
            padding: '0 5px',
            backgroundColor: 'black',
        },
        '& .MuiCheckbox-colorPrimary': {
            color: "#ffffff",
            height: 'max-content',
        },
        '& .MuiCheckbox-colorPrimary.Mui-checked': {
            color: 'yellow',
        },
        "& .MuiOutlinedInput-notchedOutline": {
            border: "none !important"
        },
    },
    editMobileNumberWrapper: {
        width: '100%',
        height: '100vh',
        [theme.breakpoints.down('sm')]: {
            height: '100vh',
        },
        backgroundColor: '#000000',
        position: 'relative' as const,
        '& .overlayImg': {
            position: 'absolute',
            width: '100%',
            bottom: '0',
            left: '0',
            '& img': {
                width: '100%',
                // height: '280px',
            },
        },
    },
    editMoibleNumberInner: {
        padding: '0 100px',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            padding: '20px',
        },
        '& .leftColumn': {
            [theme.breakpoints.down('sm')]: {
                marginTop: '0%',
            },
            '& img': {
                maxWidth: '156px',
                lineHeight: '62px',
            },
            '& h6': {
                fontFamily: 'Poppins-Medium',
                color: '#ffffff',
                fontSize: '20px',
                lineHeight: '30px',
            },
        },
        '& .rightSideBox': {
            maxWidth: '660px',
            minHeight: '428px',
            maxHeight: '428px',
            border: "1px solid rgba(255, 255, 255, 0.5)",
            borderRadius: '20px',
            display: 'flex',
            justifyContent: 'center',
            alignItem: 'center',
            padding: '8px',
            '& .divRightColumnInner': {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                textAlign: 'center',
                maxWidth: '542px',
                width: '100%',
                '& .MuiOutlinedInput-input': {
                    color: '#ffffff',
                    border: '1px solid',
                    borderColor: 'rgba(255, 255, 255, 0.5)',
                    borderRadius: '12px',
                    height: '56px',
                    padding: '0px 5px',
                },
                '& .MuiInputBase-input': {
                    '&:focus': {
                        caretColor: 'white',
                    },
                },
                '& .editMobileNumberHeading': {
                    maxWidth: '425px',
                    width: '100%',
                    margin: '0 auto',
                    '& h6, & p': {
                        color: 'white',
                    },
                    '& h6': {
                        fontFamily: 'Poppins-SemiBold',
                        fontSize: '32px',
                        lineHieght: '48px',
                    },
                    '& p': {
                        fontFamily: 'Poppins-Regular',
                        fontSize: '18px',
                        lineHeight: '27px',
                        opacity: '0.5',
                    },
                },
            },
        },
    },

    countryInputField: {
        position: 'relative' as const,
        '& .divCountryCodeLable': {
            backgroundColor: '#000000',
            color: 'rgba(255, 255, 255, 0.30)',
            fontSize: "12px",
            position: 'absolute',
            top: "-8px",
            left: "15px",
            display: 'inline-block',
            padding: '0px 5px',
        },
        '& .countryCodeSelect': {
            width: '129px',
            height: '58px',
            border: '1px solid rgba(255, 255, 255, 0.50)',
            borderRadius: 10,
            color: 'white',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }
    },
});

const CountryCodeDiv = styled('div')({
    '&::-webkit-scrollbar': {
        display: 'none',
    },

    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',
})

export default withStyles(styles)(EditMobileNumber);
export { EditMobileNumber }
// Customizable Area End
