import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
export var navigationParamsId = "";
export var navigationParamsToken = "";
interface S {
  // Customizable Area Start
  address: string;
  city: string;
  length: string;
  width: string;
  height: string;
  weight: string;
  qunantity: string;
  country: string;
  email: string;
  name: string;
  phone: string;
  instructions: string;
  amount: string;
  currency: string;
  [key: string]: any;
  token: string;
  load_description: string;
  latitude: string;
  longitude: string;
  quantity: string;
  //pickup
  p_latitude: string;
  p_longitude: string;
  p_address: string;
  p_city: string;
  p_country: string;
  p_name: string;
  p_phone: string;
  p_email: string;
  p_instructions: string;
  isSubmit: boolean;
  stackable: boolean;
  item_type: string;
  errorMsg: string;
  loading: boolean;
  new_shippment_detail: any;
  shippment_detail: any;
  receiverInfo: any;
  trackId: string;
  arrivalWindow: any;
  loadingTrack: boolean;
  loadingShipment: boolean;
  isShippmentCreated: boolean;
  delivery_begin_at: string;
  delivery_end_at: string;
  delivery_end_time: string;
  pickup_begin_at: string;
  pickup_end_at: string;
  item_type_list: any;
  deliveryDateError: string;
  pickupDateError: string;
  params: any;
  dlongerr: string;
  dlaterr: string;
  plongerr: string;
  platerr: string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class FedexintegrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  fedxApiCallId: any;
  apiShipplmentDetailCallId: any;
  scroll: any;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      load_description: "",
      latitude: "",
      longitude: "",
      address: "",
      city: "",
      length: "",
      width: "",
      height: "",
      weight: "",
      qunantity: "",
      country: "",
      email: "",
      name: "",
      phone: "",
      instructions: "",
      amount: "",
      currency: "",
      token: "",
      quantity: "",
      item_type: "",
      item_type_list: [
        {
          value: "PALLET"
        },
        {
          value: "BOX"
        },
        {
          value: "OTHER"
        }
      ],
      stackable: true,
      //pick
      p_latitude: "",
      p_longitude: "",
      p_address: "",
      p_city: "",
      p_country: "",
      p_email: "",
      p_name: "",
      p_phone: "",
      p_instructions: "",
      isSubmit: false,
      loading: false,
      loadingTrack: false,
      loadingShipment: false,
      errorMsg: "",
      new_shippment_detail: "",
      shippment_detail: "",
      receiverInfo: "",
      trackId: "",
      arrivalWindow: "",
      isShippmentCreated: false,
      delivery_begin_at: "",
      delivery_end_at: "",
      delivery_end_time: "",
      pickup_begin_at: "",
      pickup_end_at: "",
      deliveryDateError: "",
      pickupDateError: "",
      params: "",
      dlongerr: "",
      dlaterr: "",
      plongerr: "",
      platerr: ""
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
  }
  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      runEngine.debugLog("Message Recived", message);
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token });
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.fedxApiCallId != null &&
      this.fedxApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var create_responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (create_responseJson && !create_responseJson.errors) {
        if (
          create_responseJson.length === 0 ||
          create_responseJson.status === 404
        ) {
          this.showAlert("Something went wrong!", "");
        } else if (create_responseJson.status === 500) {
          this.setState({
            loadingShipment: false
          });
          this.showAlert("Internal Server Error", "");
        } else {
          navigationParamsId = create_responseJson.waybill;
          navigationParamsToken = this.state.token;
          this.setState(
            {
              loadingShipment: false,
              isShippmentCreated: true,
              trackId: create_responseJson.waybill,
              isSubmit: true,
              new_shippment_detail: create_responseJson
            },
            () => {
              this.props.navigation.push("FedexintegrationOrderTracking", {
                shippmentDetails: this.state.new_shippment_detail,
                authKey: this.state.token
              });
            }
          );
        }
      }
    } else {
      this.setState({ loadingShipment: false });
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (errorReponse === undefined) {
        this.setState({
          errorMsg: "Something went wrong"
        });
      } else {
        this.setState({
          errorMsg: errorReponse
        });
      }
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiShipplmentDetailCallId != null &&
      this.apiShipplmentDetailCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        if (responseJson.length === 0 || responseJson.status === 404) {
          this.showAlert("Sipment Deatils Not Found!", "");
          this.setState({
            loadingTrack: false
          });
        } else {
          this.setState({
            loadingTrack: false,
            shipmentStatus: responseJson[0].shipmentStatus,
            shippment_detail: responseJson[0].delivery.location,
            receiverInfo: responseJson[0].receiverInfo,
            arrivalWindow: responseJson[0].delivery.arrivalWindow
          });
        }
      }
    } else {
      this.setState({ loadingTrack: false });
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (errorReponse === undefined) {
        this.setState({
          errorMsg: "Something went wrong"
        });
      } else {
        this.setState({
          errorMsg: errorReponse
        });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }
  validateEmail(mailid: string) {
    var reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return reg.test(String(mailid).toLowerCase());
  }
  createShippment(): boolean {
    if (
      this.validateEmail(this.state.email) &&
      this.validateEmail(this.state.p_email)
    ) {
      if (
        this.isStringNullOrBlank(this.state.name) ||
        this.isStringNullOrBlank(this.state.phone) ||
        this.isStringNullOrBlank(this.state.delivery_begin_at) ||
        this.isStringNullOrBlank(this.state.delivery_end_at) ||
        this.isStringNullOrBlank(this.state.pickup_begin_at) ||
        this.isStringNullOrBlank(this.state.pickup_end_at) ||
        this.isStringNullOrBlank(this.state.address) ||
        this.isStringNullOrBlank(this.state.city) ||
        this.isStringNullOrBlank(this.state.country) ||
        this.isStringNullOrBlank(this.state.p_phone) ||
        this.isStringNullOrBlank(this.state.p_address) ||
        this.isStringNullOrBlank(this.state.p_city) ||
        this.isStringNullOrBlank(this.state.p_country) ||
        this.isStringNullOrBlank(this.state.p_latitude) ||
        this.isStringNullOrBlank(this.state.p_longitude) ||
        this.isStringNullOrBlank(this.state.height) ||
        this.isStringNullOrBlank(this.state.width) ||
        this.isStringNullOrBlank(this.state.length) ||
        this.isStringNullOrBlank(this.state.amount) ||
        this.isStringNullOrBlank(this.state.currency) ||
        this.isStringNullOrBlank(this.state.quantity) ||
        this.isStringNullOrBlank(this.state.weight)
      ) {
        this.showAlert(
          configJSON.crateShippmentErr,
          configJSON.errorCrateShippmentErr
        );
        return false;
      }
    } else {
      this.showAlert(
        configJSON.crateShippmentMailErr,
        configJSON.errorCrateShippmentErr
      );
      return false;
    }

    this.setState({ loadingShipment: true });
    const header = {
      "Content-Type": configJSON.fedxApiContentType,
      token: this.state.token
    };
    const attrs = {
      data: {
        attributes: {
          shipments_attributes: [
            {
              full_truck: false,
              load_description: this.state.load_description,
              cod_value_attributes: {
                amount: parseFloat(this.state.amount),
                currency: this.state.currency
              },
              shipment_value_attributes: {
                amount: parseFloat(this.state.amount),
                currency: this.state.currency
              },
              delivery_attributes: {
                address: this.state.address,
                city: this.state.city,
                country: this.state.country,
                email: this.state.email,
                name: this.state.name,
                phone: this.state.phone,
                instructions: this.state.instructions,
                arrival_window_attributes: {
                  begin_at: this.state.delivery_begin_at + " UTC",
                  end_at: this.state.delivery_end_at + " UTC"
                },
                coordinate_attributes: {
                  latitude: this.state.latitude,
                  longitude: this.state.longitude
                }
              },
              pickup_attributes: {
                address: this.state.address,
                city: this.state.p_city,
                country: this.state.p_country,
                email: this.state.p_email,
                name: this.state.p_name,
                phone: this.state.p_phone,
                instructions: this.state.p_instructions,
                arrival_window_attributes: {
                  begin_at: this.state.pickup_begin_at + " UTC",
                  end_at: this.state.pickup_end_at + " UTC"
                },
                coordinate_attributes: {
                  latitude: this.state.p_latitude,
                  longitude: this.state.p_longitude
                }
              },
              items_attributes: [
                {
                  weight: parseFloat(this.state.weight),
                  quantity: parseInt(this.state.quantity),
                  stackable: this.state.stackable,
                  item_type: this.state.item_type,
                  dimension_attributes: {
                    height: parseInt(this.state.height),
                    length: parseInt(this.state.length),
                    width: parseInt(this.state.width)
                  }
                }
              ]
            }
          ]
        }
      }
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fedxApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createShippmentAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(attrs)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.fedxApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  shippmentDetails(authKey: string, id: string): boolean {
    this.setState({ loadingTrack: true });
    const header = {
      "Content-Type": configJSON.fedxApiContentType,
      token: authKey
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiShipplmentDetailCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_fedex_integration/shipments/" + id
      //configJSON.fedxGetShippmentDetailsEndpoint + "069BWH"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.fedxApiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  //Not used
  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };
  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(getName(MessageEnum.AuthTokenDataMessage), this.state.country);
    this.send(msg);
  }
  // Customizable Area End
}
