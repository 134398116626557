export const edit = require("../assets/edit.png");
export const Vector = require("../assets/Vector.png");
export const storyInfo = require("../assets/StoryInfo.svg");
export const avatarImg = require("../assets/Avatar.svg");
export const connectPerson = require("../assets/ConnectPerson.svg");
export const postImg = require("../assets/PostImg.svg");
export const likeIcon = require("../assets/HeartImg.svg");
export const arrowDownIcon = require("../assets/ArrowDown.svg");
export const instagramLogo = require("../assets/instagram_logo.png");
export const pause = require("../assets/pause.png");
export const play = require("../assets/play.png");
export const flixooLoader = require("../assets/FlixooLoader.json")