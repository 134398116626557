import React from "react";

import {
  StyleSheet,
  Platform,
  Dimensions,
  // Customizable Area Start
  Text,
  Image,
  TouchableOpacity,
  View,
  ScrollView,
  TouchableWithoutFeedback
  // Customizable Area End
} from "react-native";

const Height = Dimensions.get("window").height;

import { Props } from "./HelpCentreController";

import { FlatList } from "react-native-gesture-handler";
import { triangle } from "./assets";
import HelpCentreController from "./HelpCentreController";

// Customizable Area Start
import { helpFrame, liveChat, imgChat, searchNormal, success, fail } from "./assets";
import { Paper, Box, Typography, TextField, InputAdornment, Grid, Button, Dialog, DialogContent } from '@material-ui/core';
import { withStyles, StyleRules } from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';
import StayTuned from "../../../components/src/StayTuned.web";

const styles: StyleRules = {
  root: {
    minHeight: "100vh",
    marginTop: '100px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxShadow: 'none',
    '@media(max-width: 1156px)': {
      marginTop: '70px',
    },
  },
  topContainer: {
    backgroundImage: `url(${helpFrame})`,
    minHeight: '294px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '32px',
    padding: '8px',
    width: '100%'
  },
  outlinedStyle: {
    maxWidth: '333px',
    width: '100%',
    height: '45px',
    fontSize: '14px',
    color: '#FFFFFF',
    borderRadius: '8px',
    '& .MuiOutlinedInput-input': {
      padding: '16px 14px 16px 0px',
      fontSize: '14px',
      color: '#FFFFFF',
    },
    '& ::placeholder': {
      color: '#FFFFFF',
      opacity: 1
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '8px',
      border: '2px solid #E6E6E6'
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: '2px solid #E6E6E6'
    },
  },
  helpBox: {
    maxWidth: '1250px',
    width: '100%',
    border: 'solid #DCDCDC',
    borderWidth: '1px 0',
    padding: '32px',
    '@media(max-width: 956px)': {
      gap: '32px',
      border: 'none'
    },
  },
  imgBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    padding: '24px 32px',
    gap: '8px',
    maxWidth: '435px',
    width: '100%',
    border: '0.68px solid #DCDCDC',
    borderRadius: '10px'
  },
  textfieldStyle: {
    maxWidth: '389px',
    width: '100%',
    height: '36px',
    fontSize: '12px',
    color: '#AAAAAA',
    borderRadius: '8px',
    '& .MuiOutlinedInput-input': {
      fontSize: '12px',
      color: '#AAAAAA',
    },
    '& ::placeholder': {
      color: '#AAAAAA',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '8px',
      border: '0.5px solid #DCDCDC'
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: '0.5px solid #DCDCDC'
    },
  },
  textfieldBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: '32px',
    maxWidth: '548px',
    width: '100%',
  },
  submitBtn: {
    borderRadius: '50px',
    background: '#F5EA16',
    fontSize: '12px',
    height: '30px',
    width: '87px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#F5EA16'
    },
  },
  leftContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px 24px',
    borderRight: '1px solid #DCDCDC',
    '@media(max-width: 956px)': {
      border: 'none',
      padding: '0px'
    },
  },
  rightContainer: {
    display: 'flex',
    padding: '0px 32px',
    '@media(max-width: 956px)': {
      justifyContent: 'center',
      padding: '0px'
    },
  },
  liveChatImg: {
    height: '82px',
    width: '82px',
    alignSelf: 'end',
    cursor: 'pointer'
  },
  dialogBackground: {
    "& .MuiBackdrop-root": {
      background: '#0000004D',
    }
  },
  dialog: {
    display: 'flex',
    flexDirection: 'column',
    gap: '40px',
    padding: '24px',
    '@media(max-width: 697px)': {
      padding: '16px',
    },
    height: '283px',
  },
  markBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '124px',
    height: '124px',
    borderRadius: '50%'
  }
}
// Customizable Area End

export class HelpCentre extends HelpCentreController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  successDialogBox = () => {
    const { classes } = this.props;
    const { successDialog } = this.state;
    return (
      <Dialog
        open={successDialog}
        onClose={this.closeSuccessDialogBox}
        PaperProps={{
          style: {
            borderRadius: 20,
            maxWidth: '483px',
            minHeight: '347px',
            width: '100%'
          },
        }}
        className={classes.dialogBackground}
      >
        <DialogContent className={classes.dialog}>
          <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', gap: '24px', position: 'relative' }}>
            <CloseIcon style={{ position: 'absolute', top: '0px', right: '0px', cursor: 'pointer' }} onClick={this.closeSuccessDialogBox} />
            <Typography style={{ fontSize: '38px', textAlign: 'center', fontWeight: 600 }}>Thank you!</Typography>
            <Box className={classes.markBox} style={{ background: '#4BB543' }}>
              <img src={success} />
            </Box>
            <Typography style={{ fontSize: '20px', textAlign: 'center' }}>
              Your feedback has been submitted successfully
            </Typography>
          </Box>
        </DialogContent>
      </Dialog>
    )
  }

  failDialogBox = () => {
    const { classes } = this.props;
    const { failDialog } = this.state;
    return (
      <Dialog
        open={failDialog}
        onClose={this.closeFailDialogBox}
        PaperProps={{
          style: {
            borderRadius: 20,
            maxWidth: '483px',
            minHeight: '347px',
            width: '100%'
          },
        }}
        className={classes.dialogBackground}
      >
        <DialogContent className={classes.dialog}>
          <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', gap: '24px', position: 'relative' }}>
            <CloseIcon style={{ position: 'absolute', top: '0px', right: '0px', cursor: 'pointer' }} onClick={this.closeFailDialogBox} />
            <Typography style={{ fontSize: '38px', textAlign: 'center', fontWeight: 600 }}>Something wrong</Typography>
            <Box className={classes.markBox} style={{ background: '#ED2224' }}>
              <img src={fail} />
            </Box>
            <Typography style={{ fontSize: '20px', textAlign: 'center' }}>
              Please try again!
            </Typography>
          </Box>
        </DialogContent>
      </Dialog>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { userName, userEmail, description, openTuned } = this.state;
    return (
      <Paper className={classes.root}>
        <div id="myref"></div>
        {this.successDialogBox()}
        {this.failDialogBox()}
        <Box className={classes.topContainer}>
          <Typography style={{ fontSize: '20px', fontWeight: 600, color: '#FFFFFF' }}>
            Help & Support
          </Typography>
        </Box>
        <Grid container className={classes.helpBox}>
          <Grid item xs={12} md={5} className={classes.leftContainer}>
            <Box className={classes.imgBox}>
              <img src={imgChat} />
              <Typography style={{ fontSize: '20px', fontWeight: 600 }}>Chat with our executives</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={7} className={classes.rightContainer}>
            <Box className={classes.textfieldBox}>
              <Typography style={{ fontSize: '20px', fontWeight: 600 }}>Write to Us</Typography>
              <TextField
                variant="outlined"
                className={classes.textfieldStyle}
                placeholder="Your name"
                value={userName}
                onKeyDown={this.handleKeyDown}
                onChange={this.handleUserName}
                data-test-id="your-name"
              />
              <TextField
                variant="outlined"
                className={classes.textfieldStyle}
                placeholder="Your email"
                value={userEmail}
                data-test-id="email"
                type="email"
                onChange={this.handleUserEmail}
              />
              {this.state.userEmailError !== '' &&
                <div className="red-text">{this.state.userEmailError}</div>
              }
              <TextField
                variant="outlined"
                className={classes.textfieldStyle}
                style={{ height: '112px' }}
                placeholder="Write us something"
                multiline
                rows={4}
                value={description}
                onChange={this.handleDescription}
              />
              <Button className={classes.submitBtn} data-test-id="submit" onClick={this.createHelpTicket}>Submit</Button>
              <img src={liveChat} className={classes.liveChatImg} onClick={this.openTunedBox} />
            </Box>
          </Grid>
        </Grid>
        <StayTuned openTunedBox={openTuned} closeTunedBox={this.closeTunedBox} />
      </Paper>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export default withStyles(styles)(HelpCentre);
// Customizable Area End
