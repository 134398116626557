// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { WithStyles } from "@material-ui/core";
import { getStorageData } from "../../../framework/src/Utilities";
import { Message } from "../../../framework/src/Message";
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles{
    navigation: any;
    id: string;
    // Customizable Area Start
   
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    messageModal:boolean,
    message:string

    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class LocationAccessController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    postLocationDetailsId:string=""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area End
        ];

        // Customizable Area Start
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
       
        // Customizable Area End

        this.state = {
            // Customizable Area Start
            messageModal:false,
            message:""
            // Customizable Area End
        };

    }

    // Customizable Area Start

    async receive(from: string, message: Message) {
        this.handleLocationResponse(from, message)
        
    }

    handleLocationResponse = (from: string, message: Message) => {
        if (this.postLocationDetailsId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiResponse) {
                this.navigateToHome()
            }
        }
    }

     getGpsLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                this.showPosition,
                this.showError
            );
        } else {
            this.setState({
                message: configJSON.unSupportedMessage,
                messageModal: true,
              })
        }
    };

    showPosition = async(position: GeolocationPosition) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        const body = {
            "latitude": latitude,
            "longitude": longitude,
        
        }

        const buyertoken = await getStorageData("buerLoginToken");
        const header = { "token": buyertoken, "Content-Type": 'application/json' };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.postLocationDetailsId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.postLocationEndPoint);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.exampleAPiMethod);
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    

    navigateToHome=()=>{
        window.location.href =  window.location.origin
    }
    showError = (error: GeolocationPositionError) => {
        switch (error.code) {
            case error.PERMISSION_DENIED:
                this.navigateToHome() 
                break;
            case error.POSITION_UNAVAILABLE:
                this.setState({
                    message: configJSON.locatonInformationUnavailable,
                    messageModal: true,
                  })
                break;
            case error.TIMEOUT:
                this.setState({
                    message: configJSON.locationTimedOut,
                    messageModal: true,
                  })
                break;
        }
    };

    handleClosemessageModal=()=>{
        this.setState({
            messageModal: false
        })
      }

    // Customizable Area End
}