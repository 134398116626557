import React from "react";
// Customizable Area Start
import BuyerForgotPasswordController, {
    Props
} from "./BuyerForgotPasswordController.web";

import { withStyles, StyleRules } from "@material-ui/core/styles";
import { Grid } from '@material-ui/core';
import { App_Logo, Wave } from "../../email-account-registration/src/assets";
import { imgLeftFrame } from "./assets";
import { ErrorTag, FormBox, HeadingTypography, ImageSubBox, InputGrid, InputTag, LeftGrid, RightBox, RightGrid, SmallImageBox, StyledButton, SubHeadingTypography } from "../../../components/src/CustomComponentForgotPassword.web";
import Loader from "../../../components/src/Loader.web";
import LoginFailModalWeb from "../../../components/src/LoginFailModal.web";
// Customizable Area End

const styles: StyleRules = {
    // Customizable Area Start
    root: {
        background: "#000",
        minHeight: "100vh"
    },
    firstWave: {
        position: 'absolute',
        bottom: 0,
        width: '100%'
    },
    secondWave: {
        position: 'absolute',
        bottom: 0,
        right: '12%',
        width: '100%'
    },
    // Customizable Area End
}

export class BuyerForgotPassword extends BuyerForgotPasswordController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    emailMobileError() {
        return this.state.showMobileField ? (<ErrorTag>{this.state.phoneError}</ErrorTag>) : (<ErrorTag>{this.state.emailError}</ErrorTag>)

    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;

        return (
            <Grid container className={classes.root}>
                <Loader loading={this.state.loading} />
                <LeftGrid item md={5} >
                    <ImageSubBox>
                        <img src={App_Logo} style={{ maxWidth: '100%' }} alt="logo-img1" />
                        <SmallImageBox>
                            <img src={imgLeftFrame} style={{ maxWidth: '100%' }} alt="frame-img1" />
                        </SmallImageBox>
                    </ImageSubBox>
                </LeftGrid>
                <RightGrid item md={7} >
                    <RightBox>
                        <HeadingTypography>Forgot password</HeadingTypography>
                        <SubHeadingTypography>Enter Email or Mobile to Get an OTP</SubHeadingTypography>
                        <FormBox container>
                            <div style={{ display: 'flex', gap: '10px', width: '100%', margin: "35px 0px" }}>
                                <InputGrid item xs={12}>
                                    <InputTag
                                        type="text"
                                        id="outlined-number"
                                        label='Email / Mobile'
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="outlined"
                                        onChange={this.handleInputChange}
                                        onBlur={this.handleInputValidation}
                                    />
                                </InputGrid>
                            </div>
                            {this.emailMobileError()}
                        </FormBox>
                        <StyledButton onClick={this.sendOtp}>Continue</StyledButton>
                    </RightBox>
                    <img src={Wave} className={classes.firstWave} alt="wave-img1" />
                    <img src={Wave} className={classes.secondWave} alt="wave-img1" />
                    <LoginFailModalWeb
                        displayPopup={this.state.showAlert}
                        closeErrorPopup={this.closeAlert}
                        titleMessage={'Error'}
                        errorMessage={this.state.errorMessage} />
                </RightGrid>
            </Grid>
        )
        // Customizable Area End
    }
}

// Customizable Area Start
export default withStyles(styles)(BuyerForgotPassword);
// Customizable Area End
