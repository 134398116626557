import React from "react";

import {
    // Customizable Area Start
    Box,
    Container,
    Typography,
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import {

} from "./assets";
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});
// Customizable Area End

import LandingPageControllerWeb, {
    Props,
} from "./LandingPageControllerWeb";

class TwoColumnCardNew extends LandingPageControllerWeb {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const { whatWeAreData } = this.state
        // Customizable Area End
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <section className={`${classes.twoColumnCardNewSection} ${'sectionPadding'}`}>
                    <Container maxWidth={'lg'}>
                        <Box className={classes.cardWrapper} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gridGap: '100px' }}>
                            <Typography variant={'h1'} component={'h1'}>What we are</Typography>
                            <Box className={'cardContainer'}>
                                <Box className={'cardWrapper'}>
                                    {whatWeAreData?.map((item: any, index: any) => (
                                        <Box className={'cardColumn'} key={index}>
                                            <Box sx={{ display: 'flex', gridGap: '20px' }} className={classes.card}>
                                                <Box className={classes.cardLeft}><img src={item.attributes.image} alt="" /></Box>
                                                <Box sx={{ display: 'flex', flexDirection: 'column', gridGap: '15px' }} className={classes.cardRight}>
                                                    <Typography variant={'h5'} component={'h5'}>{item.attributes.title}</Typography>
                                                    <Typography component={'p'}>{item.attributes.description}</Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    ))}
                                </Box>
                            </Box>
                        </Box>
                    </Container>
                </section>
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const styles = (theme: any) => ({
    twoColumnCardNewSection: {
        marginTop: '100px',
    },
    cardWrapper: {
        '& h1': {
            fontSize: '64px',
            lineHeight: '64px',
        },
        '& .cardWrapper': {
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
            gridGap: '20px',
            [theme.breakpoints.down('sm')]: {
                justifyContent: 'center',
                gridGap: '35px',
            },
            [theme.breakpoints.down('768')]: {
                flexDirection: 'column',
            },
            '&  .cardColumn': {
                maxWidth: 'calc(50% - 67px)',
                flexBasis: 'calc(50% - 67px)',
                minHeight: '201px',
                [theme.breakpoints.down('768')]: {
                    maxWidth: '100%',
                    flexBasis: '100%',
                },
            },
        },

    },
    card: {
        padding: '0 30px',
        border: '2px solid #F5EA16',
        borderRadius: '24px',
        minHeight: '201px',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            padding: '20px 30px',
        },
        '& h5': {
            fontSize: '28px',
            lineHeight: '31.5px',
        },
        '& p': {
            fontFamily: 'Poppins-Regular',
            fontSize: '18px',
            lineHeight: '27px',
            maxWidth: '390px',
        },
    },
});

export default withStyles(styles)(TwoColumnCardNew);
export { TwoColumnCardNew }
// Customizable Area End
