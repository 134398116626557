import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Drawer, Box, Typography, Divider } from '@material-ui/core';
import { arrowDown, hamburger } from "../../blocks/landingpage/src/assets";
import CircularProgress from '@material-ui/core/CircularProgress';

const baseURL = require("../../framework/src/config.js").baseURL

interface myProps {
    categories: any;
    subCategories: any;
    loading: boolean;
    openSubCategory: any;
    handleClickCategory: any;
    handleClickSubCategory: any;
    subCategoryID: any;
}

const useStyles = makeStyles(() => ({
    asideMenuTitle: {
        padding: "10px",
        borderBottom: "1px solid #F2F3F6",
        fontSize: "18px",
        fontWeight: 400,
        color: "#9695A1"
    },
    asideMenuElement: {
        padding: "10px 0px 10px 12px",
        fontSize: "14px",
        color: "black"
    },
    menuElementTitle: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        cursor: "pointer"
    },
    menuChildElement: {
        padding: "10px 0px",
        cursor: 'pointer',
        fontSize: '14px',
    },
    rotateArrow: {
        transform: "rotate(180deg)"
    },
    asideMenuSmallScreen: {
        minWidth: "200px",
        width: "200px",
        padding: "20px",
    },
    asideMenuHamburger: {
        width: "70px",
        marginTop: "70px",
        '@media(min-width: 600px)': {
            display: "none",
        },
        '& .hamburger': {
            display: 'none',
            '@media(max-width: 600px)': {
                display: "block",
            },
        }
    },
    sidebar: {
        '@media(min-width: 600px)': {
            borderRight: '5px solid #D9D9D9',
            marginRight: '-5px',
            paddingLeft: '16px',
            paddingRight: '8px'
        },
    }
}));

export function BuyerSideBar(Props: myProps) {
    const classes = useStyles();

    const { categories, subCategories, loading, openSubCategory, handleClickCategory, handleClickSubCategory, subCategoryID } = Props;
    return (
        <Box className={classes.sidebar}>
            <Typography className={classes.asideMenuTitle}>Categories</Typography>
            <Box>
                {categories?.map((items: any, index: any, item: any) => (
                    <Box className={classes.asideMenuElement} key={index}>
                        <Box className={classes.menuElementTitle} onClick={() => handleClickCategory(items?.attributes?.id, index)}>
                            <Typography>{items?.attributes?.name}</Typography>
                            <img src={arrowDown} alt="arrow down" className={index === openSubCategory ? classes.rotateArrow : undefined} />
                        </Box>
                        {index === openSubCategory &&
                            <Box style={{ position: 'relative', display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: '10px' }}>
                                {loading ? <CircularProgress style={{ color: '#F5EA16', height: '14px', width: '14px' }} />
                                    : <React.Fragment>
                                        {subCategories?.map((item: any,) => (
                                            <Box style={{ width: '140px' }}>
                                                <Typography className={classes.menuChildElement} style={{ color: subCategoryID.includes(item.attributes.id) ? "#9695A1" : "black"}} onClick={() => handleClickSubCategory(item?.attributes?.id)}>{item?.attributes?.name}</Typography>
                                                <Divider />
                                            </Box>
                                        ))}
                                    </React.Fragment>}
                            </Box>
                        }
                    </Box>
                ))}
            </Box>
        </Box>
    );
}

export function BuyerSideBarDrawer(Props: myProps) {
    const classes = useStyles();
    const { categories, subCategories, loading, openSubCategory, handleClickCategory, handleClickSubCategory, subCategoryID } = Props;
    const [showSidebar, setShowSidebar] = useState(false);

    const toggleDrawer = (open: boolean) => (
        event: React.KeyboardEvent | React.MouseEvent,
    ) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }

        setShowSidebar(open);
    };

    return (
        <div className={classes.asideMenuHamburger}>
            <Button onClick={toggleDrawer(true)} className={'hamburger'}><img src={hamburger} /></Button>
            <Drawer anchor={'left'} open={showSidebar} onClose={toggleDrawer(false)}>
                <div className={classes.asideMenuSmallScreen}>
                    <BuyerSideBar loading={loading} categories={categories} subCategories={subCategories} subCategoryID={subCategoryID} openSubCategory={openSubCategory} handleClickCategory={handleClickCategory} handleClickSubCategory={handleClickSubCategory}/>
                </div>
            </Drawer>
        </div>
    );
}
