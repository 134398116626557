import React, { useRef,useEffect } from 'react';
import { Box, Typography, Paper, Tabs, Tab } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {bellIcon} from "./assets"


interface NotificationAttributes {
  id: number;
  created_by: number;
  headings: string;
  contents: string;
  app_url: string | null;
  is_read: boolean;
  read_at: string;
  notificationable_id: number;
  notificationable_type: string;
  created_at: string;
  updated_at: string;
  account_id: number;
}

interface Notification {
  id: string;
  type: string;
  attributes: NotificationAttributes;
}

interface NotificationBoxProps {
  closeNotificationBox: () => void;
  allNotifications: Notification[]; 
  selectedTab: number;
  handleTabChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
}


const useStyles = makeStyles((theme) => ({
  headerCartBox: {
    cursor: 'pointer',
    borderRadius: '10px',
    backgroundColor: '#fff',
    boxShadow: '0px 0px 20px 1px rgba(0,0,0,0.1)',
    marginLeft: '24px',
    overflow: 'hidden',
    transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    padding: '12px',
    '& img': {
      width: '24px',
      height: '24px',
    },
    '& .NotificationBox': {
      position: "absolute",
      top: "200px",
      right: "40px",
      maxWidth: "475px",
      borderRadius: "20px",
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.4)",
      zIndex: 1,
      [theme.breakpoints.down(1150)]: {
        top: "150px",
        right: "140px",
      },
      [theme.breakpoints.down('xs')]: {
        top: '150px',
        right: '6px',
        marginLeft: '10px',
      },
    },
  },
  styleTabHead: {
    "& .MuiTabs-flexContainer": {
      display: 'flex',
      width: '400px',
      justifyContent: 'space-between',
    },
    "& .MuiTabs-indicator": {
      height: '4px',
      backgroundColor: "#F5EA16",
    },
    "& .MuiTab-root.Mui-selected": {
      color: '#000000',
    },
    borderBottom: '1px solid #E6E6E6',
    padding: '0px 32px',
    '@media(max-width: 697px)': {
      padding: '0px',
      "& .MuiTabs-flexContainer": {
        justifyContent: 'flex-start',
        width: 'auto',
      },
    },
  },
  styleTabNotification: {
    color: '#00000066',
    fontWeight: 500,
    fontSize: '14px',
    whiteSpace: 'nowrap',
    width: '104px',
    textTransform: 'none'
  },
  mainContainer:{
    position: "absolute" as const ,
    right: "3%",
    top: "22%",
    zIndex: 1
  }
}));

// OfferBox component
const OfferBox: React.FC<{ notificationData: Notification[]}> = ({ notificationData }) => {
  return (
    <>
      {notificationData.map((notification: any, index: number) => (
        <Box key={index} style={{ backgroundColor: notification.attributes.is_read ? 'transparent' : '#E3E3E3' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gridGap: '10px', padding: '10px 15px', boxShadow: '0px 3px 20px 0px rgba(0, 0, 0, 0.10)', margin: '0px 10px' }}>
            <Box style={{
              minWidth: '50px',
              minHeight: '50px',
              borderRadius: '50%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'start'
            }}>
              <img src={bellIcon} alt="" />
            </Box>
            <Box>
              <Typography style={{ color: '#000000', fontWeight: 'bold' }}>{notification.attributes.headings}</Typography>
              <Typography style={{ color: '#000000', fontWeight: notification.attributes.is_read ? 'normal' : 'bold' }}>{notification.attributes.contents}</Typography>
            </Box>
          </Box>
          {index !== notificationData.length - 1 && (
            <Box style={{ border: '0px solid rgba(0,0,0, 0.1)', width: '90%', height: '1px', margin: '8px auto' }}></Box>
          )}
        </Box>
      ))}
    </>
  );
};

// NotificationBox component
const SellerNotificationModal: React.FC<NotificationBoxProps> = ({ closeNotificationBox, allNotifications, selectedTab, handleTabChange }) => {
  const classes = useStyles();
  const notificationBoxRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (notificationBoxRef.current && !notificationBoxRef.current.contains(event.target as Node)) {
        closeNotificationBox();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [closeNotificationBox]);

  return (
    <Box data-test-id="sellerNotificationModal">
    <Paper ref={notificationBoxRef} className={classes.mainContainer} >
      <Box style={{ paddingBottom: '15px' }}>
        <Box style={{ backgroundColor: '#ffffff', height: '87px', borderTopLeftRadius: '20px', borderTopRightRadius: '20px', }}>
          <h6 style={{ color: '#000000', margin: '0px', padding: '35px 25px', fontSize: '16px', lineHeight: '24px' }}>All notifications</h6>
        </Box>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          className={classes.styleTabHead}
        >
          <Tab label="Social" className={classes.styleTabNotification} />
          <Tab label="Business" className={classes.styleTabNotification} />
        </Tabs>
        <Box style={{ marginTop: '20px', maxHeight: '465px', overflowY: 'scroll' }}>
          <OfferBox notificationData={allNotifications} />
        </Box>
      </Box>
    </Paper>
    </Box>
  );
};

export default SellerNotificationModal