// Customizable Area Start
import React from "react";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { WithStyles } from "@material-ui/core";
import { getStorageData ,setStorageData} from "../../../framework/src/Utilities";
import { Message } from "../../../framework/src/Message";
let config = require('../../../framework/src/config')
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles{
    navigation: any;
    id: string;
    // Customizable Area Start
    handleActiveStep?:any
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    selectedImages:any,
    selectedVideos: any,
    errorModal:boolean,
    errorMessage:string,
    edit:boolean,
    editCatalougeId:string
    editImages:any
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class UploadProductController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    readonly inputRef: React.RefObject<HTMLInputElement>;
    readonly videoInputRef: React.RefObject<HTMLInputElement>;
    imageVideoUploadMsgId: string = "";
    getProductDetailsId: string = "";
    imageDeleteId: string = "";
    videoDeleteId: string = "";
    existingFileIds: string[] = [];
    existingVideoFileIds: string[] = [];
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area End
        ];

        // Customizable Area Start
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
        this.inputRef = React.createRef()
        this.videoInputRef = React.createRef()
        // Customizable Area End

        this.state = {
            // Customizable Area Start
            selectedImages:[],
            selectedVideos: [],
            errorModal:false,
            errorMessage:"",
            edit:false,
            editCatalougeId:"",
            editImages:[]
            // Customizable Area End
        };

    }
    

    // Customizable Area Start    

    async componentDidMount() {
      await setStorageData('currentStep',0);
      const catalogueID =  await getStorageData('catalogueID');
      const array = window.location.pathname.split("/");
      const id = array[array.length - 1];
      if(array.length>2 ){
        this.setState({edit:true,editCatalougeId:id})
        this.getProductDetails(id)
      }
      else if(catalogueID){
        this.getProductDetails(catalogueID)
      }

    }

    async receive(from: string, message: Message) {
      if (this.imageVideoUploadMsgId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
        const apiResponse = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
  
        if (apiResponse?.errors) {
          alert(apiResponse?.errors)
        } else {
          await setStorageData('catalogueID', apiResponse?.data?.id);
          await setStorageData('addVariantCatalogueID', apiResponse?.data?.id);
         this.props.handleActiveStep(1)
        }
      }

      if (this.getProductDetailsId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
        const apiResponse = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
  
        if (apiResponse?.errors) {
          alert(apiResponse?.errors)
        } else {
            if (apiResponse?.data.attributes.product_images) {
                const existingImages = apiResponse.data.attributes.product_images?.map(
                    (image: any) => ({
                        id: image.id,
                        url: config.baseURL + image.url
                    })
                );
                const existingVideos = apiResponse.data.attributes.product_videos?.map(
                    (video: any) => ({
                        id: video.id,
                        url: config.baseURL + video.url
                    })
                );
               
                this.existingVideoFileIds = existingVideos?.map((video: any) => video.id);
                this.existingFileIds = existingImages?.map((image: any) => image.id);
                this.setState({ selectedImages: existingImages || [],selectedVideos: existingVideos || [] });
            }
        }
      }
    }

    getProductDetails= async(catalogueId:string)=>{      
        const singupLogin =  await getStorageData("singupLogin");
    
        const endPoint = `${configJSON.catalogueEndpoint}/${catalogueId}`
        const headers = {
            token: singupLogin,
            "content-Type": configJSON.validationApiContentType,
        };

        const getInventoryListMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getProductDetailsId = getInventoryListMsg.messageId;
    
        getInventoryListMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        endPoint
        );
        getInventoryListMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getInventoryListMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
        );
    
        runEngine.sendMessage(getInventoryListMsg.id, getInventoryListMsg);
    

    }


    handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
      const files = e.target.files;
      if (files) {
          const maxFiles = 6; 
          if ((files.length + this.state.selectedImages.length) > 6) {
            this.setState({errorModal:true,errorMessage: `You can only select up to ${maxFiles} files.`})
              return;
          }
  
          const validImages = await Promise.all(Array.from(files).map(async (file) => {
              const fileDimensions = await this.getImageDimensions(file);
              
  
              if (file.size > 5 * 1024 * 1024) {
                this.setState({errorModal:true,errorMessage: `Image "${file.name}" exceeds the maximum size of 5 MB.`})
                  return null;
              }
  
              return file;
          }));
  
          const filteredImages = validImages.filter((image) => image !== null);
  
          if (filteredImages.length > 0) {
              this.setState((prevState) => ({
                  selectedImages: [...prevState.selectedImages, ...filteredImages],
              }));
  
              if (this.inputRef.current) {
                  this.inputRef.current.value = "";
              }
          }
      }
    };
  
    getImageDimensions = (file: File): Promise<{ width: number; height: number }> => {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const img = new Image();
                img.onload = () => {
                    resolve({ width: img.width, height: img.height });
                };
                img.src = e.target?.result as string;
            };
            reader.readAsDataURL(file);
        });
    };
  
    isValidRatio = (dimensions: { width: number; height: number }): boolean => {
        const aspectRatio = dimensions.width / dimensions.height;
        return aspectRatio === 1 || aspectRatio === 3 / 4;
    };
  
    handleRemoveImage = (index:number) => {
        if(this.state.selectedImages.length === 1 ){
            this.setState({errorModal:true,errorMessage: configJSON.imageErr})
            return
        }
        const updatedImages = [...this.state.selectedImages];
        const removedItem = updatedImages.splice(index, 1)[0];
        const idToBeDeleted = removedItem.id;

       
        if (this.existingFileIds.includes(idToBeDeleted)) {
            this.handleDeleteImagefromProduct(idToBeDeleted,index);
            this.existingFileIds = this.existingFileIds.filter(id => id !== idToBeDeleted);
        }
        this.setState({ selectedImages: updatedImages });
      

    };

    handleDeleteImagefromProduct=async(idToBeDeleted:string,index:number)=>{
        const sellerLoginToken =  await getStorageData("singupLogin");
        const catalogueID =  await getStorageData('catalogueID');
        let endPoint = `${configJSON.catalogueEndpoint}/${catalogueID}`
        const editCatalougeId = this.state.editCatalougeId
        if(editCatalougeId){
            endPoint = `${configJSON.catalogueEndpoint}/${this.state.editCatalougeId}`
        }
        const headers = {
            token: sellerLoginToken,
          };

          const formData = new FormData()
          formData.append(`product_images_attributes[${index}][id]`,idToBeDeleted );  
          formData.append(`product_images_attributes[${index}][_destroy]`,true + "");  
        
        
        const imageDelete = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.imageDeleteId = imageDelete.messageId;
      
        imageDelete.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          endPoint
        );
        imageDelete.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
          );
          imageDelete.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.patchMethodType
        );

        imageDelete.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          formData
        );
      
        runEngine.sendMessage(imageDelete.id, imageDelete);
    }

    handleAddImageClick = (event:any) => {
            event.preventDefault()
            this.inputRef?.current?.click();
    };

    handleVideoChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (!files) return;
    
        const maxFiles = 2;
        if (files.length + this.state.selectedVideos.length > maxFiles) {
            this.showErrorModal(`You can only select up to ${maxFiles} files.`);
            return;
        }
    
        const { selectedVideos } = this.state;
        const newVideos = Array.from(files).filter(file => !selectedVideos.some((video:File )=> video.name === file.name));
        if (newVideos.length !== files.length) {
            this.showErrorModal("You can't upload the same video twice.");
            return;
        }
    
        const validVideos = await this.validateVideos(newVideos);
        const filteredVideos = validVideos.filter(video => video !== null);
    
        if (filteredVideos.length > 0) {
            this.setState(prevState => ({
                selectedVideos: [...prevState.selectedVideos, ...filteredVideos],
            }));
            this.resetVideoInput();
        }
    };
    
    validateVideos = async (videos: File[]) => {
        return await Promise.all(videos.map(async file => {
            if (file.size > 10 * 1024 * 1024) {
                this.showErrorModal(`Video "${file.name}" exceeds the maximum size of 10 MB.`);
                return null;
            }
            if (file.type !== "video/mp4") {
                this.showErrorModal(`File "${file.name}" is not an MP4 video.`);
                return null;
            }
            return file;
        }));
    };
    
    showErrorModal = (errorMessage: string) => {
        this.setState({
            errorModal: true,
            errorMessage: errorMessage,
        });
    };
    
    resetVideoInput = () => {
        if (this.videoInputRef.current) {
            this.videoInputRef.current.value = "";
        }
    };
    

    handleRemoveVideo = (index: number) => {
        const updatedVideos = [...this.state.selectedVideos];
        const removedItem = updatedVideos.splice(index, 1)[0];
        const idToBeDeleted = removedItem.id;
     
        if (this.existingVideoFileIds.includes(idToBeDeleted)) {
            this.handleDeleteVideofromProduct(idToBeDeleted,index);
        }
    
        this.setState({
            selectedVideos: updatedVideos,
        });
    };

    handleDeleteVideofromProduct=async(idToBeDeleted:string,index:number)=>{
        const sellerLoginToken =  await getStorageData("singupLogin");
        const catalogueID =  await getStorageData('catalogueID');
        let endPoint = `${configJSON.catalogueEndpoint}/${catalogueID}`
        const  editCatalougeId = this.state.editCatalougeId
        if(editCatalougeId){
          endPoint = `${configJSON.catalogueEndpoint}/${this.state.editCatalougeId}`
        }
        const headers = {
            token: sellerLoginToken,
          };

          const formData = new FormData()
          formData.append(`product_videos_attributes[${index}][id]`,idToBeDeleted );  
          formData.append(`product_videos_attributes[${index}][_destroy]`,true + "");  
        
        const videoDelete = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.videoDeleteId = videoDelete.messageId;
      
        videoDelete.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          endPoint
        );
        videoDelete.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
          );
          videoDelete.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.patchMethodType
        );

        videoDelete.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          formData
        );
      
        runEngine.sendMessage(videoDelete.id, videoDelete);
    }

    handleAddVideoClick = (event:any) => {
            event.preventDefault()
            this.videoInputRef?.current?.click();
    };
    
    handleSaveButton=async()=>{
        const catalogueID =  await getStorageData('catalogueID');
        const edit = this.state.edit || catalogueID
        if(edit){
        const formData = new FormData()
        let endPointEdit = `${configJSON.createnewcatalouge}/${this.state.editCatalougeId}`
        
            this.state.selectedImages.forEach((image: any, index: number) => {
                    if (!image.url) {
                        formData.append(`product_images_attributes[${index}][avatar]`, image);  
                    }
            });
            this.state.selectedVideos.forEach((image: any, index: number) => {
                if (!image.url) {
                    formData.append(`product_videos_attributes[${index}][avatar]`, image);   
                }
            });
        
        const sellerLoginToken =  await getStorageData("singupLogin");

        const headers = {
            token: sellerLoginToken,
          };

        const imageVideoUploadMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.imageVideoUploadMsgId = imageVideoUploadMsg.messageId;
      
        imageVideoUploadMsg.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          endPointEdit
        );
        imageVideoUploadMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
          );
          imageVideoUploadMsg.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.patchMethodType
        );

        imageVideoUploadMsg.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          formData
        );
      
        runEngine.sendMessage(imageVideoUploadMsg.id, imageVideoUploadMsg);
    }
        else{
            const images = this.state.selectedImages.map((image: Blob | MediaSource) => URL.createObjectURL(image));
            const videos = this.state.selectedVideos.map((video: Blob | MediaSource) => URL.createObjectURL(video));
    
            localStorage.setItem('savedImages', JSON.stringify(images));
            localStorage.setItem('savedVideos', JSON.stringify(videos));
            this.props.handleActiveStep(1)
        }
    }
     
    errorModalClose=()=>{
        this.setState({errorModal:false,errorMessage:''})
    }
    
    // Customizable Area End
}