export const bannerImage = require("../assets/banner-img.png")
export const profileImg = require('../assets/profile-edit.png')
export const EditYellowIc = require('../assets/edit-yellow.svg')
export const EditGrayIc = require('../assets/edit-gray.svg')
export const star = require('../assets/star.svg')

export const story1 = require('../assets/story-1.png')
export const story2 = require('../assets/story-2.png')
export const story3 = require('../assets/story-3.png')
export const story4 = require('../assets/story-4.png')
export const story5 = require('../assets/story-5.png')
export const story6 = require('../assets/story-6.png')
export const yourOrdersCardImg = require('../assets/your-orders-card-img.png')
export const bargainCardImg = require('../assets/bargain-card-img.png')
export const postCardImg = require('../assets/post-card-img.png')
export const slider1 = require('../assets/slider-1.png')
export const slider2 = require('../assets/slider-2.png')
export const slider3 = require('../assets/slider-3.png')
export const slider4 = require('../assets/slider-4.png')
export const slider5 = require('../assets/slider-5.png')
export const slider6 = require('../assets/slider-6.png')
export const AddYellowIc = require("../assets/plus-yellow.svg")
export const AddWhiteIc = require("../assets/plus-white.svg")
export const filterIc = require("../assets/filter.svg")
export const searchIcon = require("../assets/search.svg")
export const archiveIc = require("../assets/archive.svg")
export const archiveBlankIc = require("../assets/archiveBlank.svg")
export const likeIc = require("../assets/like-outline.svg")
export const rightArrow = require("../assets/right-arrow.svg")
export const shareIc = require("../assets/share.svg")
export const CloseIc = require('../assets/close.svg')

export const imgPasswordInVisible = require('../assets/ic_password_invisible.png')
export const imgPasswordVisible = require('../assets/ic_password_visible.png')

// seller

export const bannerImageSeller = require("../assets/banner-img-seller.png")
export const bannerImageBlankSeller = require("../assets/blank-banner.svg")
export const profileImgSeller = require('../assets/profile-edit-seller.png')
export const EditWhitec = require('../assets/edit-white.svg')
export const AddGrayIc = require("../assets/plus-gray.svg")
export const business1 = require("../assets/business-1.png")
export const business2 = require("../assets/business-2.png")
export const business3 = require("../assets/business-3.png")
export const business4 = require("../assets/business-4.png")
export const business5 = require("../assets/business-5.png")
export const searchIc = require("../assets/search-black.svg");
export const calendarIc = require("../assets/calendar.svg");
export const notificationIcon = require("../assets/notification.svg");
export const notificationRedDotIc = require("../assets/notification-red-dot.svg");
export const hamburgerIc = require("../assets/hamburger.svg");
export const postCardImgSeller = require('../assets/post-card-img-seller.png')
export const likerSeller = require('../assets/liker.svg')
export const liveSeller = require('../assets/live-banner.png')
export const topProductSeller = require('../assets/top-product-banner.png')
export const collaborationSeller = require('../assets/collaboration-profile.png')
export const PasswordShowIc = require('../assets/password-eye.svg')
export const PasswordHideIc = require('../assets/password-eye-slash.svg')
export const SuccessIc = require('../assets/success.svg')
export const CloseDocumentIc = require('../assets/close-document.svg')
export const GalleryIc = require('../assets/gallery.svg')
export const AddGraySquareIc = require("../assets/add-square.svg")
export const EditGraySquareIc = require('../assets/edit-square.svg')
export const flxooTextLogo  =  require('../assets/flxooTextLogo.png')
export const qrFlixoo  =  require('../assets/qrFlixoo.png')
export const flixooLogoQR = require("../assets/flixooLogoQR.svg")
export const shareIcon = require('../assets/shareIcon.png')
export const downloadIcon  =  require('../assets/downloadIcon.png')


// sidebar
export const closeIc = require("../assets/close.svg");
export const dashboardIc = require("../assets/dashboard.svg");
export const dashboardGrayIc = require("../assets/dashboard-gray.svg");
export const createIc = require("../assets/create.svg");
export const createGrayIc = require("../assets/create-gray.svg");
export const feedIc = require("../assets/feed.svg");
export const feedGrayIc = require("../assets/feed-gray.svg");
export const ordersIc = require("../assets/orders.svg");
export const ordersGrayIc = require("../assets/orders-gray.svg");
export const inventoryIc = require("../assets/inventory.svg");
export const inventoryGrayIc = require("../assets/inventory-gray.svg");
export const messageIc = require("../assets/message.svg");
export const messageGrayIc = require("../assets/message-gray.svg");
export const paymentIc = require("../assets/payments.svg");
export const paymentGrayIc = require("../assets/payments-gray.svg");
export const settingIc = require("../assets/setting.svg");
export const settingGrayIc = require("../assets/setting-gray.svg");
export const arrowLeftIc = require("../assets/arrow-left.svg");
export const fileSucessIc = require("../assets/file-sucess.svg");
export const commentIc = require("../assets/comment.svg");
export const noDataFound  = require("../assets/noDataFound.jpg");
export const addUPIPlusIcon = require("../assets/addUPIPlusIcon.png");
export const qrLogo = require("../assets/qrLogo.png");
export const copy = require("../assets/copy.png");
export const share = require("../assets/share.png");