export const triangle = require("../assets/triangle.png");
export const progressBar = require("../assets/Circle.png");
export const settingIcon = require("../assets/settings.png");
export const progressIcon = require("../assets/Progress.png");
export const mailIcon = require("../assets/mail.png");
export const qrCodeIcon = require("../assets/qrcode.png");
export const payPalLogo = require("../assets/paypal.png");
export const rightArrow = require("../assets/Icon.png");
export const helpFrame = require("../assets/helpFrame.png");
export const imgChat = require("../assets/chat.png");
export const liveChat = require("../assets/liveChat.png");
export const searchNormal = require("../assets/search-normal.png");
export const success = require("../assets/success.png");
export const fail = require("../assets/fail.png");
