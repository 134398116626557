import React from "react";

import {
    // Customizable Area Start
    Box,
    Grid,
    Typography,
    Button,
    Link,
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { applogo, mask_group } from "./assets";
import OtpInputFieldSellerLogin from "../../../components/src/OtpInputFieldSellerLogin";
import { createTheme, withStyles } from "@material-ui/core/styles";


const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});
// Customizable Area End

import EmailAccountLoginController, {
    Props,
} from "./EmailAccountLoginController";
import LoginFailModalWeb from "../../../components/src/LoginFailModal.web";
import MessageModalWeb from "../../../components/src/MessageModal.web";

class OtpVerify extends EmailAccountLoginController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    otpError() {
        return (this.state.otpError !== "" && (
            <div className="red-text">{this.state.otpError}</div>
        ))
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        // Customizable Area End
        return (
            // Customizable Area Start
            <Box justifyContent="center" alignItems="center"
                style={{
                    backgroundColor: 'black',
                    position: 'relative',
                    minHeight: '100vh'
                }}>
                <Grid container className={classes.gridContainer}>
                    <Grid item xs={12} md={5} style={{ padding: '0 20px', margin: '45px 0' }}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Box>
                                <img src={applogo} alt="" />
                                <Typography variant="h6"
                                    style={{ color: 'rgb(256,256,256,1)' }} >{'Live shopping & market place'}</Typography>
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={6}
                        style={{ margin: '45px 0', padding: '0 20px' }} >
                        <Box
                            sx={{
                                border: '1px solid #fff',
                                borderRadius: '20px',
                                margin: '0 auto',
                                position: 'relative',
                                zIndex: 1,
                                padding: '48px 60px 65px',
                                p: 3,
                                width: '100%',
                                maxWidth: '660px'
                            }}
                        >
                            <Typography variant="h4" style={{
                                textAlign: "center",
                                marginTop: '5px',
                                lineHeight: '1',
                                letterSpacing: '0px',
                                color: "rgba(255, 255, 255, 1)",
                                fontSize: '32px',
                                fontWeight: 600
                            }}>
                                Enter Verification Code
                            </Typography>
                            <Typography className={classes.otpDesciption} style={{
                                textAlign: "center"
                            }}>Enter the OTP you got in your 10 digit mobile number</Typography>

                            <form>
                                <Grid container style={{ marginTop: '48px', marginBottom: '20px' }}>
                                    <Grid container spacing={2} style={{ justifyContent: 'center' }}>
                                        <Box style={{ textAlign: 'center' }}>
                                            <OtpInputFieldSellerLogin onChange={this.handleOTPStateMobile} value={this.state.otpStateMobile} valueLength={6} data-test-id="mobileOtp" />
                                            {this.otpError()}
                                            <Box style={{ textAlign: 'left', margin: '24px 10px' }}>
                                                <Typography paragraph>
                                                    <Link onClick={() => this.reSendSellerMobileOtp()} data-test-id="resendMobileOtp" style={{ fontSize: '12px', color: "rgba(255, 255, 255, 1)", cursor: 'pointer', textDecoration: 'underline', fontWeight: 500 }}>Re-send the code</Link>
                                                </Typography>
                                                <Typography paragraph style={{ marginTop: '12px' }}>
                                                    <Link href="/LoginWithMobile"
                                                        style={{ fontSize: '12px', color: "rgba(255, 255, 255, 1)", cursor: 'pointer', textDecoration: 'underline', fontWeight: 500 }}
                                                    >
                                                        Edit mobile number
                                                    </Link>
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Box className="">
                                        </Box>
                                        <Button variant="contained" style={{
                                            fontSize: '16px', fontWeight: 600, fontFamily: 'Poppins !important',
                                            backgroundColor: "rgba(245, 234, 22, 1)", color: "#444444", height: "56px", marginTop: '32px', textTransform: 'none', borderRadius: '35px',
                                        }} fullWidth
                                            onClick={this.checkValidationOtpMobile}
                                            data-test-id="otp-button"
                                        >
                                            Verify
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </Box>
                    </Grid >
                </Grid >
                <Box
                    style={{
                        right: '0',
                        bottom: '0',
                        width: '100%',
                        position: 'absolute',
                        left: '0',
                    }}>
                    <img src={mask_group} alt="Mask Group" style={{ height: 'auto', width: '100%', objectFit: 'cover' }} />
                </Box>
                <MessageModalWeb displayPopup={this.state.resendModal} closeErrorPopup={this.resendModalClose} errorMessage={"OTP sent successfully."} />
                <LoginFailModalWeb
                    displayPopup={this.state.emailModal}
                    closeErrorPopup={this.emailModalClose}
                    titleMessage={'Invalid Credentials'}
                    errorMessage={"Please enter valid OTP"} />
            </Box >
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const styles = (theme: any) => ({
    customTextField: {
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "white",
                borderRadius: "10px",
            },
            "&:hover fieldset": {
                borderColor: "white",
            },
            "&.Mui-focused fieldset": {
                borderColor: "white",
            },
        },
        "& label": {
            color: "white",
        },
        "& input": {
            color: "white",
        },
    },
    otpResend: {
        '@media(max-width: 480px)': {
            width: '39px',
            height: '45px',
            fontSize: '16px !important',
            paddingBottom: 0
        },
    },
    gridContainer: {
        height: '100%',
        alignItems: 'center',
        '@media(max-width: 960px)': {
            height: 'auto',
            alignItems: 'center',
        },
    },
    otpDesciption: {
        fontWeight: 400,
        lineHeight: 'normal',
        letterSpacing: '0px',
        maxWidth: '422px',
        width: '100%',
        margin: '20px auto 0',
        color: "rgba(255, 255, 255, 0.6)",
        marginTop: '10px',
        fontSize: '18px',
        fontFamily: "Poppins !important"
    }
});
export default withStyles(styles)(OtpVerify);
export { OtpVerify }
// Customizable Area End
