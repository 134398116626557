import { Grid, Box, Typography, TextField, Button } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { styled } from '@material-ui/core/styles';


const LeftGrid = styled(Grid)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    '@media(max-width: 959px)': {
        height: 'auto',
        border: 'none'
    },
})

const LogoText = styled(Typography)({
    color: '#ffffff',
    fontSize: '20px',
    lineHeight: '30px',
    margin: '16px 0px',
})

const RightGrid = styled(Grid)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'black',
    width: '100%',
    padding: '16px'
})

const RightBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    minHeight: '428px',
    padding: '32px 16px',
    width: '100%',
    maxWidth: '660px',
    gap: '16px',
    position: "relative",
    zIndex: 1,
    border: "1px solid rgba(255, 255, 255, 0.5)",
    borderRadius: '20px',
    '@media(max-width: 600px)': {
        padding: '20px',
    },
    '@media(max-width: 350px)': {
        padding: '10px',
    },
    '& .MuiFormControl-root': {
        width: '100%',
    },
    '& .MuiOutlinedInput-input': {
        color: '#ffffff',
        border: '1px solid',
        borderColor: 'rgba(255, 255, 255, 0.5)',
        borderRadius: '12px',
        height: '56px',
        padding: '0px 5px',
    },
    '@media(max-width: 956px)': {
        justifyContent: 'normal',
    },
})

const HeadingTypography = styled(Typography)({
    color: '#FFFFFF',
    fontSize: '32px',
    fontWeight: 600,
    lineHeight: '48px',
    textAlign: 'center',
})

const SubHeadingTypography = styled(Typography)({
    color: '#FFFFFF99',
    fontSize: '18px',
    lineHeight: '27px',
    textAlign: 'center',
    maxWidth: '422px',
    width: '100%',
    '@media(max-width: 550px)': {
        fontSize: '16px'
    }
})

const ImageSubBox = styled(Box)({
    maxWidth: 800,
    position: 'relative',
    '@media(max-width: 1539px)': {
        transform: 'scale(0.8)'
    },
})

const OverlayBox = styled(Box)({
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: 'rgba(0, 0, 0, 0.50)',
    filter: 'blur(13px)',
    width: '100vw',
    height: '100vh',
});

const AlertBox = styled(Alert)({
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: 'white',
    textAlign: 'center',
    borderRadius: 20,
    minWidth: 300,
    zIndex: 1,
    '& .MuiSvgIcon-root': {
        position: 'absolute',
        right: '10px',
        cursor: 'pointer'
    },
    '& .MuiAlert-message': {
        width: '100%'
    },
    '& .MuiAlert-icon': {
        display: 'none'
    },
    "& .MuiButton-containedPrimary:hover": {
        backgroundColor: "#8080805e"
    }
});

const AlertHeadText = styled(Box)({
    color: '#000',
    textAlign: 'center',
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: '27px',
    borderBottom: '1px solid #E9E9E9',
    padding: '0px 10px 10px',
    maxWidth: '200px',
    margin: 'auto',
});

const AlertText = styled(Box)({
    color: '#000',
    textAlign: 'center',
    fontSize: '18px',
    lineHeight: '27px',
    padding: '10px'
})

const CountryDiv = styled('div')({
    '&::-webkit-scrollbar': {
        display: 'none',
    },

    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',
})

const InputGrid = styled(Grid)({
    '& img.showStar': {
        filter: 'brightness(15)',
        width: 30
    }
});

const ErrorTag = styled(Typography)({
    fontSize: '16px',
    color: 'red',
    margin: '-16px 0px 16px',
    textAlign: 'start',
    width: '100%',
    '@media(max-width: 550px)': {
        fontSize: '14px'
    }
});

const InputTag = styled(TextField)({
    width: '100%',
    borderRadius: '12px',
    backgroundColor: 'white',
    color: 'white',
    height: '56px',
    '& ::placeholder': {
        color: 'rgba(0, 0, 0)',
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: 'unset'
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
        border: 'unset'
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
        padding: '0 10px',
        background: 'black',
        color: '#FFFFFF80',
        fontSize: '18px',
        fontStyle: 'normal',
    },
    '& .MuiOutlinedInput-input': {
        padding: "0px 18.5px",
    },
});

const FormBox = styled(Grid)({
    width: '100%',
    maxWidth: '542px',
    minHeight: '160px',
    justifyContent: 'center',
    '@media (max-width: 600px)': {
        width: '90%',
        margin: '0 auto',
    },
    '& .MuiInput-underline::before': {
        borderBottom: 'none',
    },
    '& .MuiInput-underline::after': {
        borderBottom: 'none',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled)::before': {
        borderBottom: 'none'
    },
    "& .MuiNativeSelect-select:focus": {
        backgroundColor: 'white',
    },
    '& .MuiOutlinedInput-input': {
        fontSize: 18,
        border: '1px solid rgba(255, 255, 255, 0.50)',
        background: 'black',
        borderRadius: 12,
        color: 'white'
    },
    "& .MuiCheckbox-root": {
        color: "white",
        background: "white",
        height: "17px",
        width: "17px",
        borderRadius: "5px"
    },
    "& .Mui-checked.MuiCheckbox-root": {
        color: "#F5EA16",
        background: "black"
    }
});

const StyledButton = styled(Button)({
    borderRadius: '34px',
    background: '#F5EA16',
    width: '100%',
    maxWidth: '542px',
    height: '56px',
    color: '#000000',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
    textTransform: 'none',
    '&:hover': {
        backgroundColor: '#F5EA16'
    },
});


const InputTagPassword = styled(TextField)({
    width: '100%',
    borderRadius: '12px',
    backgroundColor: 'black',
    color: 'white',
    '& .MuiOutlinedInput-input': {
        padding: '0px 22px'
    },
    '& ::placeholder': {
        color: 'rgba(0, 0, 0)',
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: 'unset'
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
        border: 'unset'
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
        padding: '0 10px',
        background: 'black',
        color: '#FFFFFF80',
        fontSize: '18px',
        fontStyle: 'normal',
    },

});

const EyeImage = styled('img')({
    position: 'absolute',
    right: 20,
    top: 17,
    zIndex: 1,
    height: '24px',
    width: '24px !important',
    cursor: "pointer",
});

const FormBoxVerifyOTP = styled(Grid)({
    width: '100%',
    maxWidth: '422px',
    margin: "auto",
    '& .otp-group': {
        display: "flex",
        justifyContent: 'space-between',
        width: '100%',
        maxWidth: '422px',
        '& input': {
            fontSize: "20px !important",
            border: '2px solid #ffffff7a',
            borderRadius: '10px!important',
            textDecoration: '2px solid underline',
            textUnderlinePosition: 'under'
        },
    },
    '& .otp-input': {
        width: '44px',
        height: '54px',
        '@media(max-width: 480px)': {
            width: '39px',
            height: '45px',
        },
    },
    '@media (max-width: 600px)': {
        width: '90%',
        margin: '0 auto',
        justifyContent: 'center'
    },
    '& .MuiInput-underline::before': {
        borderBottom: 'none',
    },
    '& .MuiInput-underline::after': {
        borderBottom: 'none',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled)::before': {
        borderBottom: 'none'
    },
    "& .MuiNativeSelect-select:focus": {
        backgroundColor: 'white',
    },
    '& .MuiOutlinedInput-input': {
        fontSize: '20px',
        border: '1px solid rgba(255, 255, 255, 0.50)',
        background: 'black',
        borderRadius: 12,
        color: 'white',
        height: '46px',
        textAlign: 'center'
    },
    "& .MuiCheckbox-root": {
        color: "#fff"
    },
    '& input::-webkit-outer-spin-button': {
        WebkitAppearance: 'none',
    },
    '& input::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
    },
    '& input[type="number"]': {
        MozAppearance: 'textfield',
        textDecoration: 'underline'
    }
});

export {
    LeftGrid,
    LogoText,
    RightGrid,
    RightBox,
    HeadingTypography,
    SubHeadingTypography,
    ImageSubBox,
    OverlayBox,
    AlertBox,
    AlertHeadText,
    AlertText,
    CountryDiv,
    InputGrid,
    ErrorTag,
    InputTag,
    FormBox,
    StyledButton,
    InputTagPassword,
    EyeImage,
    FormBoxVerifyOTP
}