Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetMethod = "GET";
exports.httpDeleteMethod = "DELETE";
exports.httpPostMethod = "POST";

exports.favouritesApiApiContentType = "application/json";
exports.favouritesApiEndPoint = "favourites/favourites";
exports.textInputPlaceHolder = "Enter Text";
exports.contentPlaceHolder = "Enter Content";
exports.configError = "Error";
exports.configErrorType = "Please enter a type";
exports.configErrorId = "Please enter a valid id";
exports.getTopSearchEndPoint = 'bx_block_advanced_search/search?page=1&per_page=10&type=top&'
exports.getChooseCatergoriesDataEndPoint = 'bx_block_categories/categories';
exports.subCategoriesDataEndPoint = 'bx_block_categories/sub_categories';
exports.favouriteItemEndPoint = "bx_block_favourite/favourites";
exports.addtoCartEndPoint = 'bx_block_order_management/add_item_to_cart';
// Customizable Area End