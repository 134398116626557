import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
  
  export interface LiveStreamId {
    favourite_id: number
    live_stream_id: number
  }
  
  export interface ProductId {
    favourite_id: number
    product_id: number
  }
// Customizable Area End

export const configJSON = require("./config");

export interface Props {

    // Customizable Area Start
    searchValue: any;
    classes: any;
    navigation: any;
    selectedValue: string;
    // Customizable Area End
}

interface S {
    // Customizable Area Start

    isVisibleModal: boolean;
    openItemIndex: number | null;
    displayPopup: boolean;
    showSidebar: boolean;
    scrollPosition: number;
    chooseCatergoriesData: any;
    chooseSubCatergoriesData:any;
    isChecked: boolean;
    checkedDataPost: any;
    classes: any;
    id: string;
    isOpen?: boolean;
    closeModal?: () => void;
    currentID: string | null;
    chooseCategoriesOpenClose: boolean;
    activeButton: string;
    responseTopSearch: any;
    responseTopSearchproduct: any;
    responseProductSearch: any;
    responseShowSearch: any;
    searchValue: any;
    selectedTab: number;
    filteredData: any;
    loading: boolean;
    showLoader: boolean;
    categoryData: any;
    subCategoryData: any;
    openSubCategory: any;
    openTunedBox: boolean;
    filterDataresponse: any;
    openFilterBox: boolean;
    openSortFilters: boolean;
    openCategoryFilter: boolean;
    openPriceFilter: boolean;
    openSizeFilter: boolean;
    openColorFilter: boolean;
    categoryID: string;
    subCategoryID: any;
    sortBy: string;
    priceFilter: string;
    minPrice: string;
    maxPrice: string;
    sizeFilter: any;
    colorFilter: any;
    matchRecords: any[];
    categories:any;
    forYouSubCategoryIDs: string;
    message: '';
    messageModel: boolean;
    wishListLiveStream: LiveStreamId[];
    wishListProduct: ProductId[];
    perPageCount: number;
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class SearchResultsController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getFilteredDataApiCallIdSearch: string = '';
    getSubCategoryDataApiCallIdSearch: string = '';
    getCategoryDataApiCallIdSearch: string = '';
    getForYouSubCategoryDataApiCallIdsearch: string = '';
    apiAddToWishList: string = '';
    apiDeleteFromWishList: string = '';
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.getSearchShowResultData = this.getSearchShowResultData.bind(this);
        this.getSearchTopResultData = this.getSearchTopResultData.bind(this);
        this.getSearchProductResultData = this.getSearchProductResultData.bind(this);
        this.getSearchResultFilterData = this.getSearchResultFilterData.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage)
        ];

        this.state = {
            classes: {},
            id: "",
            isVisibleModal: false,
            openItemIndex: null,
            displayPopup: false,
            showSidebar: false,
            scrollPosition: 0,
            chooseCatergoriesData: [],
            chooseSubCatergoriesData:[],
            isChecked: false,
            checkedDataPost: [],
            currentID: '',
            chooseCategoriesOpenClose: false,
            activeButton: 'Top',
            responseTopSearch: [],
            responseTopSearchproduct: [],
            responseProductSearch: [],
            responseShowSearch: [],
            searchValue: "",
            selectedTab: 0,
            filteredData: [],
            loading: false,
            showLoader: false,
            categoryData: [],
            subCategoryData: [],
            openSubCategory: null,
            openTunedBox: false,
            filterDataresponse: [],
            openFilterBox: false,
            openSortFilters: false,
            openCategoryFilter: false,
            openPriceFilter: false,
            openSizeFilter: false,
            openColorFilter: false,
            categoryID: '',
            subCategoryID: [],
            sortBy: '',
            priceFilter: '',
            minPrice: '',
            maxPrice: '',
            sizeFilter: [],
            matchRecords: [],
            categories:[],
            message: '',
            messageModel: false,
            forYouSubCategoryIDs: '',
            colorFilter: [],
            wishListLiveStream: [],
            wishListProduct: [],
            perPageCount: 1,
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            if (apiRequestCallId === this.getFilteredDataApiCallIdSearch) {
                if (responseJson.catalogues) {
                    this.setState({
                        loading: false,
                        filteredData: responseJson.catalogues.data,
                    })
                } else if (responseJson.errors) {
                    this.setState({
                        loading: false,
                        message: responseJson.errors[0].token,
                        messageModel: true
                    })
                }
                if (responseJson.live_streams) {
                    this.setState({
                        responseShowSearch: responseJson.live_streams.data
                    })
                }
            } else if (apiRequestCallId === this.getCategoryDataApiCallIdSearch) {
                if (responseJson.data) {
                    this.setState({
                        loading: false,
                        categoryData: responseJson.data
                    })
                } else if (responseJson.errors) {
                    this.setState({
                        loading: false,
                    })
                }
            }  else if (apiRequestCallId === this.getSubCategoryDataApiCallIdSearch) {
                if (responseJson.data) {
                    this.setState({
                        showLoader: false,
                        subCategoryData: responseJson.data
                    })
                } else if (responseJson.errors) {
                    this.setState({
                        showLoader: false,
                    })
                }
            } else if (apiRequestCallId === this.getForYouSubCategoryDataApiCallIdsearch) {
                if (responseJson.data) {
                    this.setState({
                        loading: false,
                        forYouSubCategoryIDs: responseJson.data.map((item: any) => item.id).join(',')
                    })
                } else if (responseJson.errors) {
                    this.setState({
                        loading: false,
                    })
                }
            }
            if (apiRequestCallId === this.apiChoosecategories) {
                this.setState({
                  chooseCatergoriesData: responseJson.data,
                  showLoader: false,
                });
              }

            // Check for filtered data API call
            if (apiRequestCallId === this.getFilteredDataApiCallIdSearch) {
                const matchRecords = this.filterMatchRecords(responseJson.catalogues.data);
    
                if (responseJson.catalogues) {
                    this.setState({
                        loading: false,
                        filteredData: responseJson.catalogues.data,
                        responseProductSearch: matchRecords,
                    });
                } else if (responseJson.errors) {
                    this.setState({
                        loading: false,
                    });
                }
            } else {
                // Handle other API calls
                if (apiRequestCallId === this.apiShowSearch) {
                    this.setState({ responseShowSearch: responseJson.live_streams.data });
                } else if (apiRequestCallId === this.apiTopSearch) {
                    this.setState({
                        responseShowSearch: responseJson.live_streams.data,
                        responseProductSearch: responseJson.catalogues.data,
                        wishListLiveStream: responseJson?.wishlist_items?.live_stream_ids,
                        wishListProduct: responseJson?.wishlist_items?.product_ids,
                        perPageCount: responseJson.meta.catalogue_total_pages
                    },()=>{console.log('tttt',this.state.perPageCount);this.getFilteredData();});
                } else if (apiRequestCallId === this.apiProductSearch) {
                    this.setState({ responseProductSearch: responseJson.catalogues.data });
                    console.log("search product data", responseJson.catalogues.data)
                } else if (apiRequestCallId === this.apiFilterData) {
                    this.setState({ filterDataresponse: responseJson });
                }
            }

            switch (apiRequestCallId) {
                case this.apiAddToWishList:
                    this.checkItemAddedWishList(responseJson);
                    break;
                case this.apiDeleteFromWishList:
                    this.checkItemRemovedWishList(responseJson);
                    break;
                default:
                    break;
            }
        }

        

        // Customizable Area End
    }
    // Customizable Area Start
    filterMatchRecords(filteredData: any[]) {
        const responseProductSearch = this.state.responseProductSearch || [];

        const matchRecords = filteredData.filter(filteredRecord =>
            responseProductSearch.some((productRecord: { id: number; }) => productRecord.id === filteredRecord.id)
        );
        return matchRecords;
    }


    apiChoosecategories: any;
    apiSubCategoriers: any;
    apiShowSearch: any;
    apiTopSearch: any;
    apiProductSearch: any;
    apiChooseCategoriesPost: any;
    apiFilterData: any;

    async componentDidMount() {
        this.getChooseCategoriesDatas()
        this.getSearchTopResultData()
        this.getCategoryDatas();
        this.getForYouSubCategoryData();
        const headingElement = document.getElementById("myref")
        if (headingElement) headingElement.scrollIntoView({ behavior: 'smooth' })

        const queryParameters = new URLSearchParams(window.location.search)
        const searchName = queryParameters.get("q")
        this.setState({ searchValue: searchName })
        
    }


    getChooseCategoriesDatas = async () => {
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            token: await getStorageData('buerLoginToken')
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apiChoosecategories = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getChooseCatergoriesDataEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            headers
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        
    }

    


    getSearchShowResultData = async () => {
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            token: await getStorageData('buerLoginToken')
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apiShowSearch = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.advanceSearchShowSearch + this.state.searchValue
        );


        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            headers
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getSearchTopResultData = async () => {
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            token: await getStorageData('buerLoginToken')
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiTopSearch = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.advanceSearchTopSearch + this.state.searchValue
        );


        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            headers
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getSearchProductResultData = async () => {
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            token: await getStorageData('buerLoginToken')
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiProductSearch = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.advanceSearchProductSearch + this.state.searchValue
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            headers
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getSearchResultFilterData = async () => {
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            token: await getStorageData('buerLoginToken')
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apiFilterData = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.BuyersearchResultfilterEndPoint + this.state.searchValue
        );


        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            headers
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleTopButtonClick = (buttonName: any) => {
        this.setState({ activeButton: buttonName }, () => {
            this.getSearchTopResultData();
        });
    };

    handleProductButtonClick = (buttonName: any) => {
        this.setState({ activeButton: buttonName }, () => {
            this.getSearchProductResultData();
        });
    };

    handleShowButtonClick = (buttonName: any) => {
        this.setState({ activeButton: buttonName });
    };

    handleColorChange = (color: string) => {
        const index = this.state.colorFilter.indexOf(color);
        if (index !== -1) {
            const updatedColorSearch = [...this.state.colorFilter];
            updatedColorSearch.splice(index, 1);
            this.setState({
                colorFilter: updatedColorSearch
            })
        } else {
            this.setState({
                colorFilter: [...this.state.colorFilter, color]
            });
        }
    }

    checkItemAddedWishList = (responJson: any) => {
        if (responJson.message) {
            this.getSearchTopResultData()
            this.closeMessageModal();
            this.setState({ message: responJson.message })
        } else {
            this.closeMessageModal();
            this.setState({ message: responJson.errors })
        }
    }

    checkItemRemovedWishList = (responJson: any) => {
        if (responJson.message) {
            this.closeMessageModal();
            this.getSearchTopResultData()
            this.setState({ 
                message: responJson.message })
        } else {
            this.setState({ 
                message: responJson.errors[0] 
            })
            this.closeMessageModal();
        }
    }

    checkWishStatus = (producArray: any, ID: any) => {
        const index = producArray.findIndex(
            (item: any) => 
                item.live_stream_id === ID);
        if (index !== -1) { 
            return true; }
        else { return false;
        }
    }

    deleteWishItem = (producArray: any, ID: any) => {
        const index = producArray?.find(
            (item: any) => 
            item.live_stream_id === ID)?.favourite_id;
        if (index) { this.deleteWishListItem(index)
        }
        else { return false;
        }
    }

    checkProductStatus = (producArray: any, ID: any) => {
        const index = producArray.findIndex(
            (item: any) => 
            item.product_id === ID);
        if (index !== -1) { 
            return true; }
        else { return false;
        }
    }

    deleteProductItem = (producArray: any, ID: any) => {
        const index = producArray?.find((
            item: any) => 
            item.product_id === ID)?.favourite_id;
        if (index) { 
            this.deleteWishListItem(index) }
        else { return false; }
    }

    closeMessageModal = () => {
        this.setState({ messageModel: !this.state.messageModel })
    }

    handleClickSubCategory = (id: any) => {
        this.setState({
            subCategoryID: id
        }, () => {
            this.getFilteredData()
        })
    }

    getCategoryDatas = () => {
        this.setState({
            loading: true
        });
        const header = { "Content-Type": "application/json" };
        const requestMessages = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getCategoryDataApiCallIdSearch = requestMessages.messageId;
        requestMessages.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_categories/categories`);
        requestMessages.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessages.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessages.id, requestMessages);
    }

    getSubCategoryDatas = (id: any) => {
        const header = { "Content-Type": "application/json" };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getSubCategoryDataApiCallIdSearch = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_categories/sub_categories?category_id=${id}`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
      closeMakeOfferPopup = () => {
        this.setState({ displayPopup: false });
        this.setState({ chooseCategoriesOpenClose: false })
      }

      getForYouSubCategoryData = async () => {
        this.setState({ showLoader: true })
        const header = { "Content-Type": "application/json", token: await getStorageData("buerLoginToken") };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getForYouSubCategoryDataApiCallIdsearch = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_categories/sub_categories/for_you_sub_categories`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleClickCategories = (id: any, index: number) => {
        this.setState((prevState) => {
            return {
                openSubCategory: prevState.openSubCategory === index ? null : index,
                subCategoryID: '',
                subCategoryData: [],
                categoryID: id
            }
        }, () => {
            this.getFilteredData()
            this.getSubCategoryDatas(id)
        });
    }

    toggleAsideMenuItem = (index: number) => {
        this.setState((prevState) => ({
            openItemIndex: prevState.openItemIndex === index ? null : index,
        }));
    };


    closeFilterDialogBox = () => {
        this.setState({
            openFilterBox: false,
        });
    }
    openFilterDialogBox = () => {
        this.setState({
            openFilterBox: true,
        });
    }

    openCategoryDialogBox = () => {
        this.setState({
            openCategoryFilter: true,
            openFilterBox: false
        });
    }

    openPriceDialogBox = () => {
        this.setState({
            openPriceFilter: true,
            openFilterBox: false
        });
    }

    openSizeDialogBox = () => {
        this.setState({
            openSizeFilter: true,
            openFilterBox: false
        });
    }

    openSortDialogBox = () => {
        this.setState({
            openSortFilters: true,
            openFilterBox: false
        });
    }


    openColorDialogBox = () => {
        this.setState({
            openColorFilter: true,
            openFilterBox: false
        });
    }
    closeSortDialogBox = () => {
        this.setState({
            openSortFilters: false,
        });
    }
    closeCategoryDialogBox = () => {
        this.setState({
            openCategoryFilter: false,
        });
    }
    handleSortByChange = (event: any) => {
        this.setState({
            sortBy: event.target.value,
        });
    };
    
    selectCategory = (id: any, index: number) => {
        this.state.openItemIndex !== index && this.getSubCategoryDatas(id)
        this.setState((prevState) => {
            return {
                openItemIndex: prevState.openItemIndex === index ? null : index,
            }
        });
        this.setState({
            categoryID: id,
            subCategoryID: '',
            subCategoryData: []
        })
    };

    handleClearCategory = () => {
        this.setState({
            categoryID: '',
            subCategoryID: '',
            openCategoryFilter: false
        }, () => {
            this.getFilteredData()
        })
    }

    handlePriceChangeSearch = (event: any) => {
        const { value } = event.target;

        const [minString, maxString] = value.split('-');
        this.setState({
            priceFilter: value,
            minPrice: minString,
            maxPrice: maxString
        })
    }


    handleClearPriceSearch = () => {
        this.setState({
            priceFilter: '',
            minPrice: '',
            maxPrice: '',
            openPriceFilter: false
        }, () => {
            this.getFilteredData()
        })
    }

    closeSizeDialogBox = () => {
        this.setState({
            openSizeFilter: false,
            openFilterBox: true
        });
    }

    

    handleClearSize = () => {
        this.setState({
            sizeFilter: '',
            openSizeFilter: false,
        }, () => {
            this.getFilteredData()
        })
    }

    closeColorDialogBox = () => {
        this.setState({
            openColorFilter: false,
            openFilterBox: true
        });
    }
    handleSizeChangeSearch = (event: any) => {
        const index = this.state.sizeFilter.indexOf(event.target.value);
        if (index !== -1) {
            const updatedColorSearch = [...this.state.sizeFilter];
            updatedColorSearch.splice(index, 1);
            this.setState({
                sizeFilter: updatedColorSearch
            })
        } else {
            this.setState({
                sizeFilter: [...this.state.sizeFilter, event.target.value]
            });
        }
    }

    handleClearSortBy = () => {
        this.setState({
            sortBy: '',
            openSortFilters: false,
        }, () => {
            this.getFilteredData()
        })
    }

    handleClearColor = () => {
        this.setState({
            colorFilter: '',
            openColorFilter: false,
        }, () => {
            this.getFilteredData()
        })
    }

    closePriceDialogBox = () => {
        this.setState({
            openPriceFilter: false,
            openFilterBox: true
        });
    }

    selectSubCategories = (id: any) => {
        const index = this.state.subCategoryID.indexOf(id)
        if (index !== -1) {
            const updatedSubCategoryID = [...this.state.subCategoryID]
            updatedSubCategoryID.splice(index, 1);
            this.setState({
                subCategoryID: updatedSubCategoryID
            })
        } else {
            this.setState({
                subCategoryID: [...this.state.subCategoryID, id]
            })
        }
    }

    handleMinPriceChange = (event: any) => {
        this.setState({
            minPrice: event.target.value
        })
    }

    handleMaxPriceChange = (event: any) => {
        this.setState({
            maxPrice: event.target.value
        })
    }

    toggleDrawer = (open: boolean) => (
        event: any,
      ) => {
        if (
          event.type === 'keydown' &&
          ((event as React.KeyboardEvent).key === 'Tab' ||
            (event as React.KeyboardEvent).key === 'Shift')
        ) {
          return;
        }
    
        this.setState({ showSidebar: open });
      };

    navigateOnHome = (id: any) => {
        setStorageData("categoryID", id);
        this.props.navigation.navigate("BuyerDashboard");
    }

    handleClearAll = () => {
        this.setState({
            openFilterBox: false,
            categoryID: '',
            sortBy: '',
            minPrice: '',
            priceFilter: '',
            sizeFilter: '',
            maxPrice: '',
            colorFilter: '',
            subCategoryID: ''
        }, () => {
            this.getFilteredData();
            this.getSearchTopResultData();
        })
    }

    getFilteredData = async () => {
        const { sortBy, minPrice, maxPrice, sizeFilter, colorFilter, categoryID, subCategoryID, forYouSubCategoryIDs } = this.state;

        this.setState({
            loading: true,
            openSortFilters: false,
            openFilterBox: false,
            openPriceFilter: false,
            openColorFilter: false,
            openSizeFilter: false,
            openCategoryFilter: false,
            
        });

        const header = { "Content-Type": "application/json", "token": await getStorageData("buerLoginToken") };
        const queryParam = [];

        // Add parameters only if the corresponding state values are present
        if (sortBy) queryParam.push(`sort=${sortBy}`);
        if (categoryID) queryParam.push(`category_id=${categoryID}`);
        if (colorFilter) queryParam.push(`color=${colorFilter.join(',')}`);
        if (subCategoryID) queryParam.push(subCategoryID.map((number: any) => `sub_category_id=${number}`).join('&'));
        if (maxPrice) queryParam.push(`end_price=${maxPrice}`);
        if (sizeFilter) queryParam.push(`size=${sizeFilter.join(',')}`);
        if (minPrice) queryParam.push(`start_price=${minPrice}`);
        if (!categoryID) queryParam.push(`sub_category_id=${forYouSubCategoryIDs}`);
        queryParam.push(`per_page=${this.state.perPageCount * 10}`)

        const queryString = queryParam.length > 0 ? queryParam.join("&") : "";

        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getFilteredDataApiCallIdSearch = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_advanced_search/search?type=top&search=${this.state.searchValue}&${queryString}`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    updateWishListItem = async (id: number, type: string) => {
        this.setState({ 
            showLoader: true })
        const buyertoken = await getStorageData("buerLoginToken");
        if (buyertoken) {
          const header = {"Content-Type": "application/json",
            "token": buyertoken,
          };
    
          if (type == "catalogue") { type = "product" 
            }
            const body = {
                "data": {
                    "type": type,
                    "favouriteable_id": id,
                }
            }
    
          const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
          );
    
          this.apiAddToWishList = requestMessage.messageId;
    
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),JSON.stringify(body)
          );
          requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
          );
    
          requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.favouriteItemEndPoint
          );
    
          requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.exampleAPiMethod
          );
    
          runEngine.sendMessage(requestMessage.id, 
            requestMessage);
        }
      }
    
      deleteWishListItem = async (id: number) => {
        this.setState({ 
            showLoader: true })
        const buyertoken = await getStorageData("buerLoginToken");
        if (buyertoken) {
          const header = {
            "token": buyertoken, 
            "Content-Type": "application/json" 
        };
    
          const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
          );
    
          this.apiDeleteFromWishList = requestMessage.messageId;
    
          requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),`${configJSON.favouriteItemEndPoint}/${id}`
          );
          requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
          );
    
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.delete
          );
    
          runEngine.sendMessage(requestMessage.id, 
            requestMessage);
        }
      }

      goToProductDescription = (productId: number) => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
            message.addData(getName(MessageEnum.NavigationTargetMessage),"ProductDescription");
            message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
            message.addData(getName(MessageEnum.NavigationScreenNameMessage), productId);
            this.send(message)
      };
    // Customizable Area End
}
