import React from "react";
// Customizable Area Start
import {
    Box,
    styled,
    Typography,
    Avatar,
    TextField,
    Button,
} from "@material-ui/core";
import PostCard from "../../../components/src/PostCard.web";
import PostShareViewController, { configJSON, Props } from "./PostShareViewController.web";
import { avatarImg } from "./assets";
// Customizable Area End

export default class PostShareView extends PostShareViewController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { sharedPostData } = this.state;
        return (
            <PostShareStyleWrapper>
                <Box className="rightspacingBox"></Box>
                {sharedPostData && 
                <PostCard
                    data-test-id={`postCardCommonCompTestID`}
                    item={sharedPostData}
                    postKeyId={Number(sharedPostData.id)}
                    postId={sharedPostData.id}
                    handleToggleLikePost={this.handleToggleLikePost}
                    handleToggleComment={this.handleToggleComment}
                    handlePostShareToggle={this.handlePostShareToggle}
                />}

                {this.state.isCommentBox === "commentbox" ?
                    <Box data-test-id='test1' className="suggestionCmtBox">
                        <Typography id='test2' className="heading">{configJSON.commentTxt}</Typography>
                        <Box data-test-id='test3' className="commentMainContainer">
                            {this.state.listCmtData.map((item, itemIndex) => (
                                <Box id="test4" className="userCommentDataBox" key={itemIndex}>
                                    <Box className="leftCmtBox">
                                        <Box data-test-id="test5" className="avatarBox">
                                            <Avatar src={item.attributes.details.profile_photo} className="avatar" />
                                        </Box>
                                        <Box id="test6" className="commentInfoBox">
                                            <Typography className="userNameText foruser">{item.attributes.details.full_name}</Typography>
                                            <Typography data-test-id="test7" className="fullNameTxt">{item.attributes.details.comment}</Typography>
                                            <Box className="commentBox2 changesclass">
                                                <Typography id="test8" className="userNameText mtUser">{item.attributes.details.created_at}</Typography>
                                                <Typography onClick={() => this.likeCommentApiCall(item.id)} style={this.customStyle(item.attributes.details)} data-test-id="likeCommentTestId" className="fullNameTxt">{item?.attributes?.details?.like_count} Likes</Typography>
                                                <Typography className="fullNameTxt testcases" data-test-id="replyOnTapTestId" onClick={() => this.clickOnReplyForGetId(item.id)}>Reply</Typography>
                                            </Box>

                                            {item.attributes.details.replies_count > 0 &&
                                                <ReplyStyleWrapper>
                                                    <div id="test10" className="dummyLine"></div>
                                                    <Typography data-test-id='viewExpandableTestId' className="text" variant="body2" onClick={() => this.viewExpandableDetails(parseInt(item.id))}>
                                                        {!this.state.openClickReply.includes(parseInt(item.id)) ? "View" : "Hide"} {item.attributes.details.replies_count} replies
                                                    </Typography>
                                                </ReplyStyleWrapper>}

                                            {
                                                this.state.openClickReply.includes(parseInt(item.id)) && 
                                                item.attributes.details.sub_post_comments.map((value, itemIndex) => (
                                                    <Box className="leftCmtBox" key={itemIndex}>
                                                        <Box className="avatarBox">
                                                            <Avatar src={value.profile_photo} className="avatar" />
                                                        </Box>
                                                        <Box className="commentInfoBox">
                                                            <Typography data-test-id='test21' className="userNameText">{value.full_name}</Typography>
                                                            <Typography id='test22' className="fullNameTxt">{value.comment}</Typography>

                                                            <Box className="commentBox2 adddedclass">
                                                                <Typography data-test-id="test23" className="userNameText mtUser">{value.created_at}</Typography>
                                                                <Typography style={this.customStyle(value)} data-test-id="likeReplyTestId" onClick={() => this.likeReplyCommentApiCall(value?.sub_comment_id)} id="test24" className="fullNameTxt">{value.like_count} Likes</Typography>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                ))
                                            }
                                        </Box>
                                    </Box>
                                </Box>
                            ))}
                        </Box>
                        <CommentStyleWrapper>
                            <Avatar
                                src={avatarImg}
                                alt="User Avatar"
                                className="avatar"
                            />
                            <TextField
                                className="input"
                                onChange={this.handleCommentInputChange}
                                data-test-id="commentInputTestId"
                                fullWidth
                                variant="outlined"
                                placeholder="Add a Comment"
                                value={this.state.commentValue}
                            />
                            <Button
                                onClick={this.state.setCommentReplyId === "" ? this.postCommentApiCallFn : this.postReCommentApiCall}
                                className="postButton"
                                variant="outlined"
                                color="default"
                                data-test-id="postBtnTestID"
                                >
                                {configJSON.postTxt}
                            </Button>
                        </CommentStyleWrapper>
                    </Box> : ''}
            </PostShareStyleWrapper>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const PostShareStyleWrapper = styled(Box)({
    display: "flex",
    justifyContent: "center",
    marginTop: "140px",
    height:"100%",
    "@media(max-width:1150px)": {
        marginTop: "80px",
    },
    "& .rightspacingBox": {
        maxWidth: "100px",
        padding: "0px 20px",
        width: "100%",
        "@media(max-width:700px)": {
            display: "none"
        },
    },
    "& .suggestionCmtBox": {
        width: "100%",
        borderLeft: "1px solid #44444433",
        marginTop: "30px",
        maxWidth: "440px",
        paddingRight: "60px",
        paddingLeft: "24px",
        marginLeft:"14px",
        "@media(max-width:800px)": {
            display: "none"
        },
    },
    "& .userCommentDataBox": {
        margin: '10px 0px'
    },
    "& .commentMainContainer": {
        overflow: 'scroll',
        marginTop: '10px',
        height: 'calc(100vh - 230px)',
    },
    "& .leftCmtBox": {
        display: "flex",
        margin: '10px 0px',
        gap: "12px",
        alignItems: "start",
    },
    "& .commentBox2": {
        justifyContent: 'start',
        display: 'flex',
        gap: '15px',
        alignItems: 'center',
    },
    "& .userNameText": {
        fontWeight: 500,
        fontSize: "14px",
        color: "#444444",
    },
    "& .mtUser":{
       marginTop:"4px"
    },
    "& .fullNameTxt": {
        fontWeight: 400,
        fontSize: "14px",
        marginTop: "4px",
        color: "#44444480",
    },
    "& .testcases":{
        cursor:"pointer",
    }
});

const CommentStyleWrapper = styled(Box)({
    marginRight: '10px',
    marginTop: '20px',
    border: '1px solid black',
    borderRadius: '50px',
    display: 'flex',
    maxWidth: '500px',
    alignItems: 'center',
    "& .avatar": {
        marginLeft: '10px',
    },
    "& .input": {
        flex: 1,
        '& .MuiOutlinedInput-root': {
            borderRadius: '30px',
            borderColor: 'transparent',
            '& .MuiOutlinedInput-notchedOutline': {
                border: 'none'
            }
        },
    },
    "& .postButton": {
        fontWeight: 'bold',
        border: 'none',
        borderRadius: '30px',
        marginLeft: '10px',
        padding: '6px 16px',
    },
});

const ReplyStyleWrapper = styled(Box)({
    alignItems: 'center',
    display: 'flex',
    marginTop: '5px',
    '& .dummyLine': {
        background: '#B5B3A76E',
        height: '3px',
        marginRight: '10px',
        width: '50px',
    },
    '& .text': {
        cursor: 'pointer',
        color: '#1E1A00',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
});
// Customizable Area End
