export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const applogo = require("../assets/yellow_logo.svg");
export const indialogo = require("../assets/india_logo.svg");
export const call_icon = require("../assets/call_icon.svg");
export const mask_group = require("../assets/group_.png");
 
export const loginImage = require("../assets/login.svg");
export const oval = require("../assets/oval.svg");
export const logo = require("../assets/logo.svg");
export const loginFormbg = require("../assets/loginFormbg.svg");
export const appleLogo = require("../assets/apple.svg");
export const facebookLogo = require("../assets/facebook.svg");
export const googleLogo = require("../assets/google.svg");
export const iphoneImg = require("../assets/iphoneImg.png");
export const iphoneLogo = require("../assets/iphoneLogo.png");
export const Collage = require("../assets/Collage.png");
export const Wave = require("../assets/Wave.svg");
export const close = require("../assets/close.svg");
export const IndianFlag = require("../assets/IndianFlag.svg");
export const getStartedImg = require("../assets/getStartedImg.png");
export const buyerLoginImg = require("../assets/buyerLoginImg.png");
export const checkbox = require("../assets/checkbox.png");
export const checkboxFilled = require("../assets/checkboxFilled.png");