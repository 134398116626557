import React from "react";
// Customizable Area Start
import PostCreationNewController from "./PostCreationNewController";
import {
    Box,
    Typography,
    styled,
    IconButton,
    Grid,
    Avatar,
    TextField,
    Button,
    Snackbar
} from "@material-ui/core";
import SellerSidebar from "../../landingpage/src/SellerSidebar.web";
import { backButton, calendarIc, crossIcon, filterImg, hamburgerIcon, notificationIc, plusSquareImg, searchModalIcon, uploadIcon } from "../../LiveStreaming/src/assets";
import { pause, play, Vector } from "./assets";
import { IOSSwitch } from "../../InventoryManagement3/src/ListingProduct.web";
import Pagination from '@material-ui/lab/Pagination';
import Loader from "../../../components/src/Loader.web";
let config = require('../../../framework/src/config')
import Autocomplete from '@material-ui/lab/Autocomplete';
import Alert from '@material-ui/lab/Alert';
import ReactPlayer from "react-player";
import MessageModalWeb from "../../../components/src/MessageModal.web";
// Customizable Area End

export interface Props {
    navigation: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class PostCreationNew extends PostCreationNewController {

    constructor(props: Props) {
        super(props);
    }
    // Customizable Area Start
    render0IndexImageVideoShow = () => { 
        const { previewImages, taggedUsers} = this.state;
        return (
            <Box style={styles.imagePreviewBox} position="relative">
            {this.isMediaObject(previewImages[0]) && previewImages[0].type === "video" ? (
                <div data-test-id="checkVisibilityTestID0"
                style={{ position: 'relative', display: 'inline-block' }} onMouseLeave={() => this.checkVisibility(0, false)}>
                    <ReactPlayer
                        style={styles.image}
                        ref={this.videoRef[0]}
                        url={previewImages[0].url}// Replace with your video URL
                        playing={previewImages[0].isPlaying}
                        onProgress={(state) => this.handleProgress(0, state)}
                        width="100%"
                        height="100%"
                        data-test-id="ReactPlayerTestID"
                        onMouseEnter={()=> this.checkVisibility(0,true)}
                    />
                    {previewImages[0].controlsDisabled && (
                        <div style={{
                            position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 10
                        }}>
                            <button
                                data-test-id="toglePlayTestID"
                                onClick={()=>this.togglePlayPause(0)}
                                style={{
                                    border: 'none', 
                                    width: '60px', 
                                    height: '60px', 
                                    fontSize: '16px',
                                    cursor: 'pointer', 
                                    borderRadius: "50%",
                                    color: "black",
                                    background: "none",
                                }}
                            >
                                {previewImages[0].isPlaying ? <img src={pause} alt="" /> : <img src={play} alt="" />}
                            </button>
                        </div>
                    )}
                    <div style={{
                        position: 'absolute', bottom: '10px', left: '50%', transform: 'translateX(-50%)', width: '90%'
                    }}>
                        <input
                        data-test-id="seekTestID"
                        type="range"
                        min={0}
                        max={1}
                        step="0.01"
                        value={previewImages[0].played}
                        onChange={(event) => this.handleSeekChange(0 , event)}
                        style={{
                            width: '100%',
                            appearance: 'none',  
                            height: '10px',  
                            background: '#F5EA16', 
                            borderRadius: '5px', 
                            outline: 'none',  
                            transition: 'background 0.3s ease', 
                        }}
                    />
                    </div>
                </div>
            ) : (
                <img
                    src={this.isMediaObject(previewImages[0]) ? previewImages[0].url : previewImages[0]}
                    style={styles.image}
                    alt={`Selected file ${0 + 1}`}
                />
            )}
            <img
                data-test-id="singleImagesTestID0"
                src={crossIcon}
                alt="Remove"
                style={styles.crossIcon}
                onClick={() => this.handleRemoveImage(0)}
            />
            {taggedUsers.map((tag, index) => (
                <Box
                    key={index}
                    style={{
                        ...styles.tagBox,
                        top: `${tag.y}px`,
                        left: `${tag.x}px`,
                    }}
                >
                    {tag.full_name}
                    <Box
                        style={{
                            position: "absolute",
                            top: "-10px",
                            left: "50%",
                            marginLeft: "-5px",
                            width: "0",
                            height: "0",
                            borderLeft: "5px solid transparent",
                            borderRight: "5px solid transparent",
                            borderBottom: "10px solid black",
                        }}
                    />
                </Box>
            ))}
        </Box>
        )
    }

    renderMoreIndexImageVideoShow = () => {
        const { previewImages } = this.state;
        return (
            <>
                {previewImages.map((media, index) => (
                    index > 0 && (
                        <Box key={index} style={styles.imagePreviewBox} position="relative">
                            {this.isMediaObject(media) && media.type === "video" ? (
                                <div data-test-id="checkVisibilityTestID1"style={{ position: 'relative', display: 'inline-block' }} onMouseLeave={() => this.checkVisibility(index, false)}>
                                    <ReactPlayer
                                        data-test-id="ReactPlayerTestID1"
                                        style={styles.image}
                                        ref={this.videoRef[index]}
                                        url={media.url}
                                        playing={media.isPlaying}
                                        onProgress={(state) => this.handleProgress(index, state)}
                                        width="100%"
                                        height="100%"
                                        onMouseEnter={() => this.checkVisibility(index, true)}
                                    />
                                    {media.controlsDisabled && (
                                        <div style={{
                                            position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 10
                                        }}>
                                            <button
                                                data-test-id="toglePlayTestID1"
                                                onClick={() => this.togglePlayPause(index)}
                                                style={{
                                                    border: 'none',
                                                    width: '60px',
                                                    height: '60px',
                                                    fontSize: '16px',
                                                    cursor: 'pointer',
                                                    borderRadius: "50%",
                                                    color: "black",
                                                    background: "none",
                                                }}
                                            >
                                                {media.isPlaying ? <img src={pause} alt="" /> : <img src={play} alt="" />}
                                            </button>
                                        </div>
                                    )}
                                    <div style={{
                                        position: 'absolute', bottom: '10px', left: '50%', transform: 'translateX(-50%)', width: '90%'
                                    }}>
                                        <input
                                            data-test-id="seekTestID1"
                                            type="range"
                                            min={0}
                                            max={1}
                                            step="0.01"
                                            value={media.played}
                                            onChange={(event) => this.handleSeekChange(index, event)}
                                            style={{
                                                width: '100%',
                                                appearance: 'none',
                                                height: '10px',
                                                background: '#F5EA16',
                                                borderRadius: '5px',
                                                outline: 'none',
                                                transition: 'background 0.3s ease',
                                            }}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <img
                                    src={this.isMediaObject(media) ? media.url : media}
                                    alt={`Preview ${index + 1}`}
                                    style={styles.image}
                                />
                            )}
                            <img
                                data-test-id="singleImagesTestID1"
                                src={crossIcon}
                                alt="Remove"
                                style={styles.crossIcon}
                                onClick={() => this.handleRemoveImage(index)}
                            />
                        </Box>
                    )
                ))}
            </>
        )
    }

    renderUploadImageSection = () => {
        const { previewImages } = this.state;
        return (
            <Grid item xs={12} md={12} lg={8}>
                <Box className={"spaceBetween"}>
                    <Typography className="uploadHeading">
                        Upload {previewImages.length}/6
                    </Typography>
                    <Typography data-test-id="clearAllImagesTestID" className="uploadHeading clearAllImage" onClick={this.handleRemoveAllImages}>
                        Clear all image
                    </Typography>
                </Box>
                {previewImages.length > 0 &&
                    <Box style={styles.galleryContainer}>
                        {this.render0IndexImageVideoShow()}
                        {this.renderMoreIndexImageVideoShow()}
                        {previewImages.length < 6 && 
                        <Box style={styles.addIconBox}>
                            <label htmlFor="thumbnail-upload">
                                <input
                                    type="file"
                                    accept=".jpg, .png, .mp4, .mov"
                                    onChange={this.handlePostImageChange}
                                    style={{ display: 'none' }}
                                    id="thumbnail-upload"
                                    multiple
                                />
                                <Box style={styles.uploadIconPostContainer}>
                                    <img src={plusSquareImg} alt="Add icon" style={styles.addIconImage} />
                                </Box>
                            </label>
                        </Box>
                        }
                    </Box>
                }

                {previewImages.length === 0 &&
                    <Box className={"uploadPostContainer"} component="fieldset">
                        <label htmlFor="thumbnail-upload">
                            <input
                                type="file"
                                accept=".jpg, .png, .mp4, .mov"
                                onChange={this.handlePostImageChange}
                                style={{ display: 'none' }}
                                id="thumbnail-upload"
                                multiple
                                data-test-id="thumbImgInputTestId"
                            />
                            <Box className={"uploadIconPostContainer"} >
                                <img src={uploadIcon} alt="Upload Icon" className={"uploadPostIcon"} />
                                <Typography className={"uploadPostHereText"}>
                                    <Typography component="span" className={"uploadPostText"}>
                                        Upload
                                    </Typography>
                                    {" "}
                                    here
                                </Typography>
                            </Box>
                        </label>
                    </Box>
                }
                {this.state.isPhotoUploaded &&
                    <Typography className="reqText">Please Upload post</Typography>
                }
                <Box style={{ marginTop: "28px" }} display="flex" alignItems="center" width="100%" borderBottom="1px solid #E0E0E0" pb={1}>
                    <Avatar
                        alt="User Avatar"
                        src={this.state.profileImage}
                        style={{ width: 40, height: 40, margin: "0px 5px 0 0 " }}
                    />
                    <TextField
                        fullWidth
                        variant="standard"
                        placeholder="Write a caption..."
                        InputProps={{ disableUnderline: true }}
                        style={{ fontSize: '1rem' }}
                        value={this.state.captionText}
                        onChange={this.handlePostCaptionChange}
                        data-test-id="captionInputTestID"
                    />
                </Box>
                {this.state.isCaption &&
                    <Typography className="reqText">Please enter caption</Typography>
                }
                <Box className="tagPeopleBox" pb={1} >
                <Box className={"spaceBetween"}>
                    <Typography className="uploadHeading headingcolorchange" >Tag people</Typography>
                    <Typography className="uploadHeading" >{this.state.taggedUsers.length > 0 && `${this.state.taggedUsers.length} people`} </Typography>
                </Box>
                    <Autocomplete
                        data-test-id="autoCompleteTestId"
                        id="combo-box-demo"
                        noOptionsText="Please enter a valid user name! No users found"
                        options={this.state.tagList}
                        getOptionLabel={(option) => option.user_name}
                        value={this.state.taggedUsers} 
                        renderOption={(option) => (
                            <CustomGrid container alignItems="center" >
                              <Grid item>
                                <Avatar src={option?.profile_photo || ''} alt={option?.user_name} />
                              </Grid>
                              <Grid item xs>
                                <Box className="userInfoBox">
                                <Typography variant="body1">{option?.user_name} <span className="roleTxt">{option?.role.name}</span></Typography>
                                <Typography variant="body2" color="textSecondary">
                                  {option?.full_name}
                                </Typography>
                                </Box>
                              </Grid>
                            </CustomGrid>
                          )}
                        fullWidth
                        multiple={true}
                        onChange={(event, value) => this.handleTagUsers(value)}
                        renderInput={(params) => <TextField {...params} onChange={this.handleTagList} data-test-id="offerValueTestId" fullWidth type="text" variant="outlined" className="searchUserInputField" placeholder={`${this.state.searchQuery === '' ? "John Doe" : "" }`} label="Search User" value={this.state.searchQuery}  />}
                    />
                </Box>
                <Box className="selectMainBox">
                    <Box style={{ marginTop: "28px", paddingBottom: "16px" }} display="flex" alignItems="center" width="100%" borderBottom="1px solid #E0E0E0" pb={1}>
                        <Typography data-test-id="addProductTestID" onClick={this.handleShownventory} className="uploadHeading headingcolorchange">Add product</Typography>
                    </Box>
                    {this.renderSelectedProduct()}
                </Box>
            </Grid>
        )
    }

    renderActiveMessageSection = () => {
        return (
            <Grid item xs={12} md={12} lg={4}>
                <Box className={"switchPostContainer"}>
                    <Box>
                        <Typography className={"switchPostTitle"}>
                            Activate messaging
                        </Typography>
                        <Typography className={"switchPostDescription"}>
                            Turn this on if you want to get messages <br /> from people regarding this product.
                        </Typography>
                    </Box>
                    <IOSSwitch
                        data-test-id="switchTestID"
                        checked={this.state.activeMessage}
                        onChange={this.handleSwitchChange}
                    />
                </Box>
            </Grid>
        )
    }

    renderInventorySection = () => {
        return (
            <>
                {
                    this.state.inventoryList.length ?
                        <InventoryContainer data-test-id="invSectionTestID">
                            <Pagination data-test-id="paginationTestID" count={this.state.invCurrentTotalPage} size="large" onChange={this.handlePageChange} />
                            <Typography data-test-id="clearAllImagesTestID" className="uploadHeading clearAllImage" onClick={this.handleRemoveAllChecked}>
                                Clear all check
                            </Typography>
                            <InventoryList>
                                {this.state.inventoryList.map((inventory, index) => {
                                    return (
                                        <li key={inventory.id}>
                                            <StyledCheckBox htmlFor={"check" + inventory.id} className={inventory.checked ? "checkedStyle" : ""}>
                                                <input
                                                    checked={inventory.checked || this.state.checkedInventoryList.includes(inventory.id)}
                                                    type="checkbox" id={"check" + inventory.id}
                                                    onChange={(event) => this.handleInventoryChecked(event, inventory)}
                                                    data-test-id="inventoryCheckboxTestID"
                                                    value={inventory.id}
                                                />
                                                <span></span>
                                            </StyledCheckBox>
                                            <Box className="inventoryImage">
                                                <img src={config.baseURL + inventory.attributes.product_images[0]?.url} alt={inventory.attributes.title} />
                                            </Box>
                                            <Box className="inventoryDetails">
                                                <Typography className="inventoryName" data-test-id="inventoryTitleID">{inventory.attributes.title}</Typography>
                                                <Typography className="inventoryDesc">{inventory.attributes.description}</Typography>
                                            </Box>
                                            <Typography className="inventoryPrice">${inventory.attributes.mrp}</Typography>
                                        </li>
                                    )
                                })}
                            </InventoryList>
                            <Button  onClick={this.handleInventoryAddBtn} variant="contained" disableElevation data-test-id="inventoryAddBtnTestID" disabled={!Boolean(this.state.checkedInventoryList.length > 0)}>Add</Button>
                        </InventoryContainer>
                        :
                        <Box className="noInventoryStyle">
                            <Typography>No inventory found.</Typography>
                        </Box>
                }
            </>
        )
    }

    renderInventoryVarient = () => {
        return (
            <StyledModal>
                <Box className="liveModalOverlay" onClick={this.handleVarientModalClose} data-test-id="modalOverlayTestID" />
                <ModalBody>
                    <>
                        <Typography style={{ margin: "0" }} className={"mainPageTitle mainPageTitleChanged"} data-test-id="inventoryTxtLiveID">Add Variant</Typography>
                        <Typography style={{ margin: "0" }} className={"checkedInventory displayCenterVecor"} data-test-id="inventoryTxtLiveID"><img className="VectorImage" src={Vector} alt="" />Select the variant related to the post</Typography>
                        <InventoryContainer>
                            <InventoryList>
                                {this.state.inventoryVarient.map((inventoryVarient, index) => {
                                    return (
                                        <li key={inventoryVarient.id} className="boxShadowVarient ulListVarientPadding">
                                            <StyledCheckBox htmlFor={"check" + inventoryVarient.id} className={inventoryVarient.checked ? "checkedStyle" : ""}>
                                                <input
                                                    checked={inventoryVarient.checked || this.state.checkedVarientList.includes(inventoryVarient.id)}
                                                    type="checkbox" id={"check" + inventoryVarient.id}
                                                    onChange={(event) => this.handleInventoryVarientChecked(event, inventoryVarient)}
                                                    data-test-id="inventoryVarientItemCheckboxTestID"
                                                    value={inventoryVarient.id}
                                                />
                                                <span></span>
                                            </StyledCheckBox>
                                            <Box className="inventoryImage boxShadowVarient">
                                                <img className="boxShadowVarientimg" src={config.baseURL + inventoryVarient.attributes.product_images[0]?.url} />
                                            </Box>
                                            <Box className="inventoryDetails">
                                                <Typography className="inventoryName" data-test-id="inventoryTitleID">{this.state.inveCurSelectedName}</Typography>
                                                <Typography className="inventoryDesc">{this.state.inveCurSelectedDes}</Typography>
                                            </Box>
                                            <Typography className="inventoryPrice">${inventoryVarient.attributes.price}</Typography>
                                        </li>
                                    )
                                })}
                            </InventoryList>
                            <Button onClick={this.handleVarientAddBtn} style={{ width: "100%" }} variant="contained" disableElevation data-test-id="varientAddBtnTestID" disabled={!Boolean(this.state.checkedVarientList.length > 0)}>Add</Button>
                        </InventoryContainer>
                    </>
                </ModalBody>
            </StyledModal >
        )

    }

    renderSelectedProduct = () => {
        return (
            <InventoryContainer>
                <InventoryList>
                    {this.state.selectedProducts.map((selectedItem, index) => {
                        return (
                            <li key={selectedItem.id} className="boxShadowVarient ulListVarientPadding">
                                <Box className="inventoryImage boxShadowVarient selectedImages">
                                    <img className="boxShadowVarientimg" src={config.baseURL + selectedItem.attributes.product_images[0]?.url} />
                                </Box>
                                <Box className="inventoryDetails">
                                    <Typography className="inventoryName" data-test-id="inventoryTitleID">{this.findInventoryDetails("name",selectedItem.attributes.catalogue_id)}</Typography>
                                    <Typography className="inventoryDesc">{this.findInventoryDetails("desc",selectedItem.attributes.catalogue_id)}</Typography>
                                </Box>
                                <Typography className="inventoryPrice">${selectedItem.attributes.price}</Typography>
                            </li>
                        )
                    })}
                </InventoryList>
            </InventoryContainer>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            <>
                <PostMainContainer>
                    <Box className="liveStreamingPostSideMenu">
                        <div className="dashboard-outer">
                            <div className="dashboard-inner">
                                <SellerSidebar
                                    navigation={"/SellerDashboard"}
                                    id={""}
                                    classes={undefined}
                                    isOpen={this.state.isSideBarOpen}
                                    data-test-id="sellerSidebar"
                                    activetabName="create"
                                    closeModal={() => this.handlePostSideBar()}
                                    data-testid="isOpenTestId"
                                />
                            </div>
                        </div>
                    </Box>
                    <Loader loading={this.state.isLoader} />
                    <MainContainerInner>
                    <Snackbar data-test-id="snackbarToastTestId" className="snackBarAlert" open={this.state.isSnackOpen}  onClose={this.handleCloseSnackBar} anchorOrigin={{ vertical: "top", horizontal: "right" }} >
                        <Alert severity={this.state.snackbarSeverity} onClose={this.handleCloseSnackBar}>
                            {this.state.snackBarMessage}
                        </Alert>
                    </Snackbar>
                        <Box className="hideSideBarHamburgerSection">
                            <IconButton
                                data-test-id="hamburger-btn"
                                onClick={() => this.handlePostSideBar()}
                            >
                                <img src={hamburgerIcon} alt="hamburger" />
                            </IconButton>
                        </Box>
                        <Box className={"spaceBetween"}>
                            <Box className={"backBtnNavigation"} m={0}>
                                <img data-test-id="backButton" src={backButton} alt="backButton" onClick={() => this.handlePostBackButton()} />
                                {
                                    this.state.isInventoryOpen ?
                                        <Box>
                                            <Typography className={"mainPageTitle mainPageTitleChanged"} data-test-id="inventoryTxtLiveID">Inventory</Typography>
                                            <Typography className={"checkedInventory"}> {this.state.checkedInventoryList.length} of {this.state.invCurrentTotalCount ? this.state.invCurrentTotalCount : 0}</Typography>
                                        </Box>
                                        :
                                        <Typography className={"mainPageTitle"} data-test-id="inventoryTxtId">Create a post</Typography>
                                }

                            </Box>
                            <Box component='span' className={"flexContainer"}>
                                {
                                    this.state.isInventoryOpen &&
                                    <ModalSearch className="inventorySearch">
                                        <img src={searchModalIcon} alt="search" />
                                        <input onChange={this.handleInventoryPostSearch} value={this.state.searchProductText} type="text" placeholder={"Search for products"} data-test-id="inventorySearchTestID" />
                                        <img src={filterImg} alt="filter" className="inventoryFilter" />
                                    </ModalSearch>
                                }
                                {
                                    this.state.isInventoryOpen && <Box component="span" className={"headerUpIcons"}><img data-test-id="addPlusIconTestID" src={plusSquareImg} alt="plus square icon" onClick={this.handleAddProductPost} /></Box>

                                }
                                <Box component="span" className={"headerUpIcons"}><img src={calendarIc} alt="calendarIcon" /></Box>
                                <Box component="span" className={"headerUpIcons"}><img src={notificationIc} alt="bellIcon" /></Box>
                            </Box>
                        </Box>
                        <Grid container spacing={4}>
                            {this.state.isInventoryOpen ? (
                                this.renderInventorySection()
                            ) : (
                                <>
                                    {this.renderUploadImageSection()}
                                    <Box className={"saveAndButtonPostContainer"}>
                                        <Button data-test-id="saveBtnTestId" className={"saveAndButtonPost"} onClick={this.handlePostShare}>Share</Button>
                                    </Box>
                                </>
                            )}
                        </Grid>
                        {this.state.isVarientOpen && this.renderInventoryVarient()}
                    </MainContainerInner>
                    <MessageModalWeb
                        data-test-id="errorMessageTestId"
                        displayPopup={this.state.isErrorModal}
                        closeErrorPopup={this.errorPostModalClose}
                        errorMessage={this.state.errorMsgPost}
                    />
                </PostMainContainer>
            </>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const ModalBody = styled(Box)({
    position: "relative",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: "40px 20px",
    boxSizing: "border-box",
    maxWidth: "573px",
    maxHeight: "548px",
    width: "calc(100% - 60px)",
    backgroundColor: "#fff",
    borderRadius: "12px",
    overflow: "scroll"
});

const StyledModal = styled(Box)({
    top: "70px",
    position: "fixed",
    width: "calc(100% - 290px)",
    right: 0,
    zIndex: 2,
    height: "calc(100vh - 70px)",
    "@media(max-width: 991px)": {
        width: "100%"
    },
    "& .liveModalOverlay": {
        height: "100%",
        position: "absolute",
        width: "100%",
        display: "flex",
        backgroundColor: "rgba(0,0,0,0.8)",
        alignItems: "center",
        justifyContent: "center",
    },
    "& .VectorImage": {
        height: "16px",
        width: "16px",
        marginRight: "4px",
    },
    "& .displayCenterVecor": {
        display: "flex",
        alignItems: "center",
        marginTop: "12px !important",
    }
})
const StyledCheckBox = styled("label")({
    position: "relative",
    border: "1px solid rgba(161, 161, 161, 1)",
    borderRadius: "4px",
    width: "25px",
    height: "24px",
    "&.checkedStyle": {
        border: "1px solid transparent"
    },
    "& input": {
        visibility: "hidden",
    },
    "& input:checked + span": {
        backgroundColor: "rgba(245, 234, 22, 1)"
    },
    "& input:checked + span::before": {
        content: "''",
        borderWidth: "0px 2px 2px 0px",
        border: "1px solid",
        width: "6px",
        transform: "rotate(45deg)",
        position: "absolute",
        height: "14px",
        left: "8px",
        top: "2px",
    },
    "& span": {
        width: "108%",
        position: "absolute",
        top: "-2px",
        height: "108%",
        borderRadius: "4px",
        left: "-1px",
    }
});

const InventoryList = styled("ul")({
    margin: 0,
    padding: 0,
    marginTop: "20px",
    "& .custom-checkbox-pseudo input": {
        position: "absolute",
        cursor: "pointer",
        opacity: 0,
    },
    "& .boxShadowVarient": {
        boxShadow: "none !important",
    },
    "& .boxShadowVarientimg": {
        objectFit: "unset" as "unset",
        width: "56.73px !important",
        height: "34.46px !important",
        margin: "0 !important"
    },
    "& li": {
        display: "flex",
        alignItems: "center",
        padding: "20px",
        gap: "12px",
        borderRadius: "20px",
        boxShadow: "0 0 25px rgba(0,0,0,0.2)",
        marginBottom: "20px",
        "& .selectedImages": {
            width: "unset !important",
            height: "inset !important",
            padding: "0 8px 0 0 !important",
            objectFit: "unset" as "unset",
        },
        "& .inventoryImage": {
            width: "106px",
            height: "106px",
            padding: "8px",
            borderRadius: "8px",
            boxShadow: "0 0 5px rgba(0,0,0,0.4)",
            boxSizing: "border-box",
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            "& img": {
                objectFit: "cover",
                width: "81px",
                height: "84px",
            },
        },
        "& .inventoryDetails": {
            flex: 1,
            "& .inventoryName": {
                fontWeight: 500,
                color: "rgba(68, 68, 68, 1)",
                fontSize: "16px",
                lineHeight: "24px",
            },
            "& .inventoryDesc": {
                color: "rgba(68, 68, 68, 0.7)",
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "21px",
            }
        },
        "& .inventoryPrice": {
            color: "rgba(68, 68, 68, 1)",
            fontSize: "20px",
            fontWeight: 700,
            lineHeight: "30px",
        }
    }
});
const InventoryContainer = styled(Box)({
    marginTop: "28px",
    paddingBottom: "20px",
    width: "100%",
    "& img": {
        marginLeft: "auto",
        cursor: "pointer",
        display: "block"
    },
    "& .MuiButton-contained": {
        backgroundColor: "rgba(245, 234, 22, 1)",
        marginTop: "40px",
        width: "342px",
        display: "block",
        marginLeft: "auto",
        height: "47px",
        borderRadius: "40px",
    },
    "& .MuiButton-label": {
        textTransform: "capitalize"
    },
});

const ModalSearch = styled(Box)({
    display: "flex",
    alignItems: "center",
    backgroundColor: "#fff",
    padding: "12px",
    gap: "12px",
    borderRadius: "12px",
    boxShadow: "0 0 25px rgba(0,0,0,0.2)",
    "&.inventorySearch": {
        margin: "0 0.5em",
        width: "482px",
        height: "48px",
        "@media(max-width: 1200px)": {
            width: "100%",
            maxWidth: "460px",
        }
    },
    "& img": {
        cursor: "pointer"
    },
    "& input": {
        border: "none",
        outline: "none",
        flex: 1
    },
    "& .inventoryFilter": {
        borderLeft: "1px solid rgba(68, 68, 68, 0.1)",
        paddingLeft: "12px"
    }
});

const styles = {
    galleryContainer: {
        display: 'flex',
        overflowX: 'auto' as 'auto',
        whiteSpace: 'nowrap' as 'nowrap',
        width: '100%',
        padding: '16px',
        boxSizing: 'border-box' as 'border-box',
        border: "2px dashed #ccc",
        borderRadius: "10px",
        marginTop: "28px",
    },
    tagBox: {
        position: 'absolute' as 'absolute',
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        color: 'white',
        padding: '2px 5px',
        borderRadius: '5px',
        fontSize: '12px',
    },
    crossIcon: {
        width: "24px",
        height: "24px",
        cursor: "pointer",
        marginLeft: "8px",
        position: 'absolute' as 'absolute',
        top: 5,
        right: 5,
        background: "white",
        borderRadius: '50%',
    },
    imagePreviewBox: {
        width: '100%',
        marginRight: '10px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: '1px solid #ccc',
        borderRadius: '5px',
        position: 'relative' as 'relative',
        minWidth:"376px",
        maxWidth:"376px",
        maxHeight:"334px"
    },
    image: {
        width: '100%',
        height: '100%',
        objectFit: 'contain' as 'contain',
        borderRadius: '8px',
        minWidth:"376px",
        maxWidth:"376px",
        maxHeight:"334px"
    },
    addIconBox: {
        flex: '0 0 auto',
        width: '55%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: '1px solid #0000004D',
        borderRadius: '8px',
    },
    uploadIconPostContainer: {
        width: '50px',
        height: '50px',
    },
    addIconImage: {
        width: "86px",
        height: "86px",
    },
};
const PostMainContainer = styled(Box)({
    display: "flex",
    marginTop: "93px",
    "& .ulListVarientPadding": {
        padding: "0"
    },
    "@media (max-width: 1399px)": {
        marginTop: "70px"
    },
    "& .noInventoryStyle": {
        width: "100%",
        height: "calc(100vh - 186px)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    "& .liveStreamingPostSideMenu": {
        marginTop: "0px",
        "@media (max-width: 991px)": {
            marginTop: "20px"
        }
    },
    "& .switchPostContainer": {
        justifyContent: "space-between",
        display: "flex",
        marginBottom: "1em",
        alignItems: "center",
        marginTop: "28px"
    },
    "& .switchPostTitle": {
        color: '#555555',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontStyle: 'normal',
        fontFamily: 'Poppins',
        fontSize: '16px',
        margin: "0.2em 0em",
        fontWeight: 600,
        lineHeight: 'normal',
    },
    "& .switchPostDescription": {
        color: '#555555',
        fontStyle: 'normal',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: 'Poppins',
        fontSize: '14px',
        margin: "0.2em 0em",
        fontWeight: 400,
        lineHeight: 'normal',
    },
    "& .saveAndButtonPost": {
        width: '30%',
        height: '3em',
        background: '#F5EA16',
        borderRadius: "25px",
        color: '#444',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
        pointer: "cursor",
        textTransform: "none" as const,
        "&:hover": {
            background: '#F5EA16',
        }
    },
    "& .saveAndButtonPostContainer": {
        display: "flex",
        justifyContent: "flex-end",
        width: "100%",
        marginBottom: "2em",
        marginTop: "2em",
        "@media(max-width: 960px)": {
            margin: "1em"
        }
    },
})

const MainContainerInner = styled(Box)({
    overflow: "hidden",
    position: "relative",
    paddingTop: "2%",
    width: "100%",
    padding: "0em 2em",
    "& .uploadPostHereText": {
        fontFeatureSettings: "'clig' off, 'liga' off",
        color: '#555',
        fontSize: '16px',
        fontFamily: 'Poppins',
        fontWeight: 500,
        fontStyle: 'normal',
    },
    "& .uploadPostText": {
        color: '#555',
        fontFamily: 'Poppins',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontStyle: 'normal',
        fontSize: '16px',
        textDecorationLine: 'underline',
        fontWeight: 600,
    },
    "& .uploadPostIcon": {
        width: "48px",
        height: "48px"
    },
    "& .uploadPostContainer": {
        border: "2px dashed #ccc",
        borderRadius: "10px",
        height: "168px",
        alignItems: "center",
        display: "flex",
        position: "relative",
        justifyContent: "center",
        marginTop: "28px",
    },
    "& .uploadHeading": {
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: "600",
        lineHeight: "24px",
        textAlign: "left",
        color: "#555555",
        marginTop: "28px"
    },
    "& .clearAllImage": {
        textDecoration: "underline",
        cursor: "pointer"
    },
    "& .headingcolorchange": {
        color: "#000000",
        cursor: "pointer",
    },
    "& .tagPeopleBox": {
        marginTop: "28px",
        paddingBottom: "16px",
        display: "flex",
        width: "100%",
        borderBottom: "1px solid #E0E0E0",
        flexDirection:"column"
    },
    "& .searchUserInputField": {
        marginTop:"12px",
        borderRadius:"12px",
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":{
            borderColor:"grey"
        },
        "& .MuiInputLabel-root.Mui-focused":{
            color:"grey"
        },
        "& .MuiChip-root":{
            backgroundColor:"yellow"
        }
    },
    "& .selectMainBox": {
        display: "flex",
        flexDirection: "column"
    },
    "& .reqText": {
        color: "red",
    },
    "@media(max-width: 991px)": {
        paddingTop: "2%",
        padding: "0em 2em",
        width: "100%",
    },
    "& .hideSideBarHamburgerSection": {
        display: "none",
        "@media(max-width: 991px)": {
            display: "block",
            position: "absolute",
            top: "70px",
            right: "18px"
        }
    },
    "& .headerUpIcons": {
        width: '48px',
        borderRadius: '10px',
        height: '48px',
        background: 'var(--0, #FFF)',
        border: '1px solid var(--0, #FFF)',
        display: "flex",
        boxShadow: '0px 0px 20px 1px rgba(0, 0, 0, 0.10)',
        justifyContent: "center",
        alignItems: "center",
        flexShrink: 0,
        margin: "0em 0.5em",
    },
    "& .mainPageTitle": {
        fontSize: '20px',
        color: 'rgba(68, 68, 68, 0.80)',
        fontFamily: 'Poppins',
        fontWeight: 500,
        fontStyle: 'normal',
        marginLeft: "0.5em",
        lineHeight: 'normal',
    },
    "& .mainPageTitleChanged": {
        color: '#444444 !important',
        fontWeight: "600 !important",
    },
    "& .checkedInventory": {
        color: "rgba(68, 68, 68, 0.7)",
        fontSize: "14px",
        marginLeft: "0.5em"
    },
    "& .spaceBetween": {
        justifyContent: "space-between",
        display: "flex",
    },
    "& .backBtnNavigation": {
        alignItems: "center",
        display: "flex",
    },
    "& .flexContainer": {
        display: "flex"
    },
});

const CustomGrid = styled(Grid)({
   "& .userInfoBox":{
    marginLeft:"20px"
   },
   "& .roleTxt":{
    color:"grey",
    fontSize:"12px",
    marginLeft:"4px",
   }
});
// Customizable Area End
