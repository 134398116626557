import React from "react";

import {
    // Customizable Area Start
    Box,
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});
// Customizable Area End

import LandingPageControllerWeb, {
    Props,
} from "./LandingPageControllerWeb";

class BottomImgSection extends LandingPageControllerWeb {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const { bottomImage } = this.state;
        console.log("footer image", bottomImage)
        // Customizable Area End
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <section className={classes.bottomImageSection}>
                    {bottomImage?.map((items: any) => (
                        <Box className={classes.bottomImageWrapper}>
                            <img src={items.attributes.image} alt="" />
                        </Box>
                    ))}
                </section>
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const styles = (theme: any) => ({
    bottomImageSection: {
        width: '100%',
        maxHeight: '600px',
        '& img': {
            width: '100%',
            objectFit: 'cover',
            objectPosition: 'top',
        },
    },
    bottomImageWrapper:{
        '& img': {
            maxHeight: '600px',
        },
    },
});

export default withStyles(styles)(BottomImgSection);
export { BottomImgSection }
// Customizable Area End
