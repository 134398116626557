import React from "react";
// Customizable Area Start
import {
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  TextInput,
  ScrollView,
  TouchableWithoutFeedback,
  ActivityIndicator,
  Platform,
  SafeAreaView,
  KeyboardAvoidingView
} from "react-native";
// Customizable Area End
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp
} from "react-native-responsive-screen";
import { RFValue } from "react-native-responsive-fontsize";
import moment from "moment";
import FedexintegrationController, {
  Props,
  navigationParamsId,
  navigationParamsToken
} from "./FedexintegrationController";

export default class FedexintegrationOrderTracking extends FedexintegrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  componentDidMount(): any {
    //const datacship: navigationdata = this.props.navigation.state;
    this.shippmentDetails(navigationParamsToken, navigationParamsId);
  }
  // Customizable Area Start
  // Customizable Area End
  setTextInputValue = (attr_name: string, value: string) => {
    this.setState({ [attr_name]: value });
  };
  render() {
    //const dataship: navigationdata = this.props.navigation.state;
    return (
      //Merge Engine DefaultContainer
      <>
        <SafeAreaView style={styles.defaultContainer}>
          <KeyboardAvoidingView
            behavior={Platform.OS === "ios" ? "padding" : "height"}
            enabled
            keyboardVerticalOffset={
              Platform.OS === "ios" ? hp("11%") : hp("1%")
            }
            style={styles.keyboardavoidStyle}
          >
            {/* Customizable Area Start */}
            <ScrollView
              contentInsetAdjustmentBehavior="automatic"
              contentContainerStyle={styles.contentContainer}
              style={styles.scrollViewStyle}
            >
              <TouchableWithoutFeedback onPress={() => this.hideKeyboard()}>
                <View style={styles.innerContainer}>
                  <View style={styles.textInputView}>
                    <Text style={styles.inputLabel}>Enter Tracking Id</Text>
                    <TextInput
                      testID="txtInputtrackId"
                      style={[styles.textInput, {}]}
                      onChangeText={text =>
                        this.setTextInputValue("trackId", text)
                      }
                      value={
                        this.state.trackId === ""
                          ? navigationParamsId
                          : this.state.trackId
                      }
                    />
                  </View>
                  <TouchableOpacity
                    style={styles.submitButton}
                    testID="btnShipmentDetails"
                    onPress={() => {
                      this.shippmentDetails(
                        navigationParamsToken,
                        navigationParamsId
                      );
                    }}
                  >
                    <Text
                      style={[
                        styles.submitButtonText,
                        { fontSize: RFValue(16) }
                      ]}
                    >
                      GO
                    </Text>
                  </TouchableOpacity>
                  {this.state.loadingTrack ? (
                    <ActivityIndicator
                      style={styles.activityIndicatorStyle}
                      color="blue"
                      size="small"
                    />
                  ) : null}
                  {this.state.shippment_detail !== "" ? (
                    <View>
                      <View style={styles.headerBarView}>
                        <Text
                          style={[
                            styles.inputLabel,
                            { padding: 4, color: "rgb(62, 69, 79)" }
                          ]}
                        >
                          Order Status
                        </Text>
                      </View>
                      <View
                        style={[
                          styles.addressDetailsView,
                          { backgroundColor: "rgb(255, 255, 255)" }
                        ]}
                      >
                        <Text
                          style={[
                            styles.inputLabel,
                            { fontSize: RFValue(14), padding: 4 }
                          ]}
                        >
                          {this.state.shipmentStatus + " "}
                          {moment(this.state.arrivalWindow.begin).format(
                            "ddd, Do MMMM  YY"
                          )}
                        </Text>
                        <Text
                          style={[
                            styles.inputLabel,
                            { fontSize: RFValue(14), padding: 4 }
                          ]}
                        >
                          {moment(this.state.arrivalWindow.end).format(
                            "ddd, Do MMMM YYYY, h:mm a"
                          )}
                        </Text>
                      </View>
                      <View style={styles.headerBarView}>
                        <Text
                          style={[
                            styles.inputLabel,
                            { color: "rgb(62, 69, 79)", padding: 4 }
                          ]}
                        >
                          Shipping Address
                        </Text>
                      </View>
                      <View
                        style={[
                          styles.addressDetailsView,
                          { backgroundColor: "rgb(255, 255, 255)" }
                        ]}
                      >
                        <Text
                          style={[
                            styles.inputLabel,
                            { fontSize: RFValue(14), padding: 4 }
                          ]}
                        >
                          {this.state.shippment_detail.address}
                        </Text>
                        <Text
                          style={[
                            styles.inputLabel,
                            { fontSize: RFValue(14), padding: 4 }
                          ]}
                        >
                          Phone Number: {this.state.receiverInfo.phone}
                        </Text>
                      </View>
                    </View>
                  ) : null}
                </View>

                {/* Merge Engine UI Engine Code */}
                {/* Customizable Area End */}
              </TouchableWithoutFeedback>
            </ScrollView>
            {/* Customizable Area End */}
          </KeyboardAvoidingView>
        </SafeAreaView>
      </>
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  container: {},
  contentContainer: {},
  scrollViewStyle: {
    backgroundColor: "#fff",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
  },
  textInputView: {
    flex: 1,
    marginVertical: hp("1%")
  },
  activityIndicatorStyle: { flex: 1, opacity: 1 },
  headerBarView: {
    marginVertical: hp("2%"),
    width: wp("90%"),
    justifyContent: "center",
    height: hp("6%"),
    backgroundColor: "rgb(246, 248, 250)"
  },
  addressDetailsView: {
    flex: 1,
    width: wp("90%"),
    justifyContent: "center",
    backgroundColor: "rgb(247, 247, 247)"
  },
  defaultContainer: {
    flex: 1,
    backgroundColor: "#fff"
  },
  keyboardavoidStyle: {
    flex: 1
  },
  innerContainer: {
    flex: 1,
    marginHorizontal: wp("6%")
  },
  inputLabel: {
    fontSize: RFValue(16)
  },
  shipLabel: {
    fontSize: RFValue(18),
    lineHeight: RFValue(30),
    color: "rgb(84, 89, 95)",
    textAlign: "left"
  },
  textInput: {
    fontSize: RFValue(16),
    lineHeight: RFValue(22),
    borderWidth: 1,
    borderColor: "#ccc",
    width: wp("90%"),
    height: hp("6%"),
    borderRadius: hp("1%"),
    paddingHorizontal: wp("2%")
  },
  orderTrackButton: {
    alignSelf: "flex-end",
    marginTop: hp("4%"),
    height: hp("4%"),
    width: wp("50%"),
    justifyContent: "center",
    backgroundColor: "blue",
    borderRadius: hp("1%"),
    marginVertical: hp("1%")
  },
  submitButton: {
    height: hp("6%"),
    justifyContent: "center",
    backgroundColor: "blue",
    borderRadius: hp("1%"),
    marginVertical: hp("1%"),
    width: wp("90%")
  },
  submitButtonText: {
    textAlign: "center",
    color: "#fff",
    lineHeight: RFValue(20),
    fontSize: RFValue(20),
    fontWeight: "600"
  },
  title: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  }
});
// Customizable Area End
