import React from "react";

import {
    // Customizable Area Start
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Grid,
    TextField,
    Button,
    Box,
    InputAdornment,
    DialogContent,
    Dialog,
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { withStyles } from "@material-ui/core/styles";
import { downArrowIc, faqbannerImage, whiteSearchBar, success, fail } from "./assets";
import CloseIcon from '@material-ui/icons/Close';

interface IFAQ {
    id: number,
    title: string,
    content: string,
    created_at: string,
    updated_at: string,
    faq_for: string
}
// Customizable Area End

import InteractivefaqsSharedController, {Props} from "./InteractivefaqsSharedController"

class BuyerFaq extends InteractivefaqsSharedController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    successDialogBox = () => {
        const { classes } = this.props;
        const { successDialog } = this.state;
        return (
          <Dialog
            open={successDialog}
            onClose={this.handleSuccessDialogBox}
            data-test-id="close-succcess-dialog"
            PaperProps={{
              style: {
                borderRadius: 20,
                maxWidth: '483px',
                minHeight: '347px',
                width: '100%'
              },
            }}
            className={classes.dialogBackground}
          >
            <DialogContent className={classes.dialog}>
              <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', gap: '24px', position: 'relative' }}>
                <CloseIcon style={{ position: 'absolute', top: '0px', right: '0px', cursor: 'pointer' }} onClick={this.handleSuccessDialogBox} />
                <Typography style={{ fontSize: '38px', textAlign: 'center', fontWeight: 600 }}>Thank you!</Typography>
                <Box className={classes.markBox} style={{ background: '#4BB543' }}>
                  <img src={success} />
                </Box>
                <Typography style={{ fontSize: '20px', textAlign: 'center' }}>
                  Your feedback has been submitted successfully
                </Typography>
              </Box>
            </DialogContent>
          </Dialog>
        )
      }
    
      failDialogBox = () => {
        const { classes } = this.props;
        const { failDialog } = this.state;
        return (
          <Dialog
            open={failDialog}
            onClose={this.handleFailDialogBox}
            data-test-id="close-fail-dialog"
            PaperProps={{
              style: {
                borderRadius: 20,
                maxWidth: '483px',
                minHeight: '347px',
                width: '100%'
              },
            }}
            className={classes.dialogBackground}
          >
            <DialogContent className={classes.dialog}>
              <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', gap: '24px', position: 'relative' }}>
                <CloseIcon style={{ position: 'absolute', top: '0px', right: '0px', cursor: 'pointer' }} onClick={this.handleFailDialogBox} />
                <Typography style={{ fontSize: '38px', textAlign: 'center', fontWeight: 600 }}>Something wrong</Typography>
                <Box className={classes.markBox} style={{ background: '#ED2224' }}>
                  <img src={fail} />
                </Box>
                <Typography style={{ fontSize: '20px', textAlign: 'center' }}>
                  Please try again!
                </Typography>
              </Box>
            </DialogContent>
          </Dialog>
        )
      }

    searchQuery = () => {
        const { classes } = this.props;
        const { openAccordions, searchQuery } = this.state;
        const filteredPopularFaqData = this.getFilteredPopularFaqData();
        return (
            <>
                {searchQuery && (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Box style={{  marginTop: '10px' }}>
                            <div className="accordion-wrap main-div" style={{ marginTop: '10px' }}>
                                <Box className={classes.titleWrap} style={{ display: 'flex' }}>
                                    <Typography style={{ color: '#000', fontFamily: 'Poppins !important', fontSize: '24px', fontStyle: 'normal', fontWeight: 400, lineHeight: '16px', marginRight: '12px', letterSpacing: '0.3px', }}>{`Search Results: `}</Typography>
                                    <Typography style={{ color: '#000', fontFamily: 'Poppins !important', fontSize: '24px', fontStyle: 'normal', fontWeight: 600, lineHeight: '16px', letterSpacing: '0.3px', }}>{searchQuery}</Typography>
                                </Box>
                                <div>
                                    {
                                        filteredPopularFaqData.length > 0 ? (
                                            <Box style={{ width: '200%', marginTop: 40 }}>
                                                {filteredPopularFaqData.map((item: IFAQ, index: number) => (
                                                        <Accordion className={`${classes.customAccordionFaq} accordion-poppular`} key={item.id}>
                                                            <AccordionSummary className="accordion-head">
                                                                <Typography className={classes.QuestionTitleNew}>{index + 1}. {item.title}</Typography>
                                                                <i className="arrow" style={{ right: 10, top: 18, position: 'absolute' }}>
                                                                    <img src={downArrowIc} alt="Down Arrow" style={{ transform: openAccordions.safety ? 'rotate(180deg)' : 'rotate(270deg)' }} />
                                                                </i>
                                                            </AccordionSummary>
                                                            <AccordionDetails style={{ padding: '0 16px' }} className="accordion-body">
                                                                <Typography className={classes.AnswerTitle}>{item.content}</Typography>
                                                            </AccordionDetails>
                                                        </Accordion>
                                                ))}
                                            </Box>

                                        ) : (
                                            <Box className={classes.titleWrap}>
                                                <Typography data-test-id="faq-not-found-text" style={{ color: '#000', fontFamily: 'Poppins !important', fontSize: '24px', fontStyle: 'normal', lineHeight: '30px', letterSpacing: '0.3px' }}>{`Sorry, we couldn't find any matches for `}{<strong>{`"${searchQuery}"`}</strong>}</Typography>
                                                <Typography style={{ color: '#000', fontFamily: 'Poppins !important', fontSize: '18px', fontStyle: 'normal', lineHeight: '30px', letterSpacing: '0.3px', marginTop: '10px' }}>Please try again with different keywords or refine your search to get better results</Typography>
                                            </Box>
                                        )
                                    }
                                </div>
                            </div>
                        </Box>
                    </div>
                )}
            </>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const { BuyerFaqData, openAccordions, userName, userEmail, description, searchQuery } = this.state;
        // Customizable Area End
        return (
            // Customizable Area Start
            <div className={classes.root}>
                {this.successDialogBox()}
                {this.failDialogBox()}
                <Box className={classes.termsConditionHeader} style={{ position: 'relative' }}>
                    <Box className={classes.topContainer}>
                        <Typography style={{ fontSize: '20px', fontWeight: 600, color: '#FFFFFF' }}>
                            What can we help you with?
                        </Typography>
                        <TextField
                            variant="outlined"
                            data-test-id="search-faq-input"
                            className={classes.outlinedStyle}
                            placeholder="Search your question here"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <img src={whiteSearchBar} />
                                    </InputAdornment>
                                ),
                            }}
                            value={this.state.searchQuery}
                            onChange={this.handleSearchInputChange}
                        />
                    </Box>
                </Box>
                {!searchQuery && (
                    <Grid container style={{ display: 'flex', marginTop: '40px' }}>
                        <Grid item xs={11} sm={7}>
                            <Box className={classes.mainDiv} >
                                <Box className={classes.titleWrap}>
                                    <Typography className={classes.title}>FAQs</Typography>
                                </Box>
                                <div className="accordion-wrap main-div" style={{ marginTop: '20px' }}>
                                    <Accordion data-test-id="buyer-category" className="buyer-accordion" expanded={openAccordions.buyers} onChange={() => this.toggleAccordion('buyers')} style={{ marginBottom: '13px' }}>
                                        <AccordionSummary className="accordion-head">
                                            <Typography className={classes.AccordionTitle}>
                                                For Buyers
                                            </Typography>
                                            <i className="arrow" style={{ right: 10, top: 18, position: 'absolute' }}>
                                                <img src={downArrowIc} alt="Down Arrow" style={{ transform: openAccordions.buyers ? 'rotate(180deg)' : 'rotate(270deg)' }} />
                                            </i>
                                        </AccordionSummary>
                                        <AccordionDetails className="accordion-body">
                                            <div className="accordion-details" >
                                                {BuyerFaqData.buyer.length > 0 ? (
                                                    BuyerFaqData.buyer.map((item: IFAQ, index: number) => (
                                                        <Accordion 
                                                            className={classes.customAccordionFaq} 
                                                            key={item.id}>
                                                            <AccordionSummary className="accordion-head">
                                                                <Typography 
                                                                    className={classes.QuestionTitle}>{index+1}. {item.title}</Typography>
                                                            </AccordionSummary>
                                                            <AccordionDetails 
                                                                style={{padding: '0 16px'}} className="accordion-body">
                                                                <Typography className={classes.AnswerTitle}>
                                                                    {item.content}</Typography>
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    ))
                                                ) : (
                                                    <div style={{padding: '0 16px'}}>No FAQ available</div>
                                                )}
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>

                                    <Accordion data-test-id="general-category" expanded={openAccordions.general} onChange={() => this.toggleAccordion('general')} style={{ marginBottom: '13px' }}>
                                        <AccordionSummary className="accordion-head">
                                        <Typography className={classes.AccordionTitle}>General</Typography>
                                            <i className="arrow" style={{ right: 10, top: 18, position: 'absolute' }}>
                                                <img src={downArrowIc} alt="Down Arrow" style={{ transform: openAccordions.general ? 'rotate(180deg)' : 'rotate(270deg)' }} />
                                            </i>
                                        </AccordionSummary>
                                        <AccordionDetails className="accordion-body">
                                            <div className="accordion-details" >
                                                {BuyerFaqData.general_for_buyer.length > 0 ? (
                                                    BuyerFaqData.general_for_buyer.map((items: IFAQ, index: number) => (
                                                        <Accordion className={classes.customAccordionFaq} key={items.id}>
                                                            <AccordionSummary
                                                                className="accordion-head">
                                                            <Typography 
                                                                className={classes.QuestionTitle}>
                                                                    {index+1}. {items.title}
                                                            </Typography>
                                                            </AccordionSummary>
                                                            <AccordionDetails 
                                                                className="accordion-body" 
                                                                style={{padding: '0 16px'}}>
                                                            <Typography className={classes.AnswerTitle}>
                                                                {items.content}</Typography>
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    ))
                                                ) : (
                                                    <div style={{padding: '0 16px'}}>No FAQ available</div>
                                                )}
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>

                                    <Accordion data-test-id="safety-category" expanded={openAccordions.safety} onChange={() => this.toggleAccordion('safety')} style={{ marginBottom: '13px' }}>
                                        <AccordionSummary className="accordion-head">
                                        <Typography className={classes.AccordionTitle}>Safety & Guidelines</Typography>
                                            <i className="arrow" style={{ right: 10, top: 18, position: 'absolute' }}>
                                                <img src={downArrowIc} alt="Down Arrow" style={{ transform: openAccordions.safety ? 'rotate(180deg)' : 'rotate(270deg)' }} />
                                            </i>
                                        </AccordionSummary>
                                        <AccordionDetails className="accordion-body">
                                            <div className="accordion-details" >
                                                {BuyerFaqData.safety_and_guidline.length > 0 ? (
                                                    BuyerFaqData.safety_and_guidline.map((items: IFAQ, index: number) => (
                                                        <Accordion className={classes.customAccordionFaq} key={items.id}>
                                                            <AccordionSummary className="accordion-head">
                                                            <Typography className={classes.QuestionTitle}>{index+1}. {items.title}</Typography>
                                                            </AccordionSummary>
                                                            <AccordionDetails className="accordion-body" style={{padding: '0 16px'}}>
                                                            <Typography className={classes.AnswerTitle}>{items.content}</Typography>
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    ))
                                                ) : (
                                                    <div style={{padding: '0 16px'}}>No Safety & Guidelines available</div>
                                                )}
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>

                                    <Accordion data-test-id="write-category" expanded={openAccordions.write} onChange={() => this.toggleAccordion('write')} style={{ marginBottom: '13px' }}>
                                        <AccordionSummary className="accordion-head">
                                            <Typography className={classes.AccordionTitle}>Write to Us</Typography>
                                            <i className="arrow" style={{ right: 10, top: 18, position: 'absolute' }}>
                                                <img src={downArrowIc} alt="Down Arrow" style={{ transform: openAccordions.write ? 'rotate(180deg)' : 'rotate(270deg)' }} />
                                            </i>
                                        </AccordionSummary>
                                        <AccordionDetails className="accordion-body-about">
                                            <Box className={classes.textfieldBox}>
                                                <TextField
                                                    variant="outlined"
                                                    placeholder="Your name"
                                                    className={classes.textfieldStyle}
                                                    value={userName}
                                                    onChange={this.handleUserNamefaq}
                                                />
                                                <TextField
                                                    variant="outlined"
                                                    placeholder="Your email"
                                                    className={classes.textfieldStyle}
                                                    value={userEmail}
                                                    onChange={this.handleUserEmailfaq}
                                                />
                                                {this.state.userEmailError !== '' &&
                                                    <div className="red-text">{this.state.userEmailError}</div>
                                                }
                                                <TextField
                                                    variant="outlined"
                                                    placeholder="Write us something"
                                                    className={classes.textfieldStyle}
                                                    style={{ height: '112px' }}
                                                    multiline
                                                    rows={4}
                                                    value={description}
                                                    onChange={this.handleDescriptionfaq}
                                                />
                                                <Button className={classes.submitBtn} onClick={this.writeUsFaq} data-testid="faqsubmit">Submit</Button>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Box className={classes.mainDiv} style={{ marginTop: '20px' }}>
                                <Box className={classes.titleWrap}>
                                    <Typography className={classes.titlefaq}>Some popular  questions</Typography>
                                </Box>
                                <div className="accordion-wrap main-div" style={{ marginTop: '10px' }}>
                                    {BuyerFaqData.popular_question.length > 0 ? (
                                        BuyerFaqData.popular_question.map((items: IFAQ, index: number) => (
                                            
                                            <Accordion data-test-id={`popular-questions-${index}`} className={`${classes.customAccordionFaq} accordion-poppular`} key={items.id}>
                                                <AccordionSummary className="accordion-head">
                                                    <Typography className={classes.QuestionTitleNew}>{`${index + 1}. ${items.title}`}</Typography>
                                                    <i className="arrow" style={{ right: 10, top: 18, position: 'absolute' }}>
                                                        <img src={downArrowIc} alt="Down Arrow" style={{ transform: openAccordions.safety ? 'rotate(180deg)' : 'rotate(270deg)' }} />
                                                    </i>
                                                </AccordionSummary>
                                                <AccordionDetails className="accordion-body" style={{ padding: '0 16px' }}>
                                                    <Typography className={classes.AnswerTitle}>{items.content}</Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                        ))
                                    ) : (
                                        <div style={{padding: '0 16px'}}>No FAQ available</div>
                                    )}
                                </div>
                            </Box>
                        </Grid>
                    </Grid>
                )}

                {this.searchQuery()}
            </div>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const styles = (theme: any) => ({
    initialHeight: {
        minHeight: '90vh'
    },
    titleWrap: {
        margin: '0 0 10px'
    },

    termsConditionHeader: {
        backgroundColor: '#000000',
        width: '100%',
        //height: '100px',
    },
    mainDiv: {
        padding: '30px 15px 65px',
        margin: '0 auto',
        maxWidth: '1160px',
        width: '80%',
        '@media (max-width: 991px)': {
            padding: '15px 15px 65px',
        },
    },
    title: {
        fontWeight: 600,
        lineHeight: '16px',
        letterSpacing: '0.3px',
        color: '#000',
        fontFamily: 'Poppins !important',
        fontSize: '24px',
        fontStyle: 'normal',
    },

    termsConditionHeaderInner: {
        width: '100%',
        height: 'auto',
        display: 'block',
        marginTop: '100px'
    },
    titlefaq: {
        color: '#000',
        fontFamily: 'Poppins !important',
        fontSize: '19px',
        fontStyle: 'normal',
        fontWeight: '600',
        lineHeight: 'normal'
    },
    searchBar: {
        position: 'absolute',
        top: '48%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        background: 'transparent',
        color: '#fff',
        alignItems: 'center'
    },
    searchHeading: {
        border: "2px solid #fff",
        display: 'flex',
        borderRadius: '8px',
    },
    customAccordionFaq: {
        position: 'relative',
        transition: 'margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: '0px 1px 6px 0px rgba(0, 0, 0, 0.15) !important',
        background: 'var(--0, #FFF)',
        margin: '16px 0',
        textAlign: 'left',
        fontFamily: 'Poppins-Regular !important',
        boxSizing: 'border-box',
    },
    submitBtn: {
        borderRadius: '50px',
        background: '#F5EA16',
        fontSize: '12px',
        height: '30px',
        width: '87px',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#F5EA16'
        },
    },
    textfieldStyle: {
        maxWidth: '389px',
        width: '100%',
        height: '36px',
        fontSize: '12px',
        color: '#AAAAAA',
        borderRadius: '8px',
        '& .MuiOutlinedInput-input': {
            fontSize: '12px',
            color: '#AAAAAA',
        },
        '& ::placeholder': {
            color: '#AAAAAA',
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderRadius: '8px',
            border: '0.5px solid #DCDCDC'
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: '0.5px solid #DCDCDC'
        },
    },
    textfieldBox: {
        display: 'flex',
        flexDirection: 'column',
        gap: '32px',
        maxWidth: '548px',
        width: '100%',
    },
    forborder: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '50px',
        '@media (max-width: 600px)': {
            display: 'none'
        },
    },
    topContainer: {
        minHeight: '294px',
        backgroundImage: `url(${faqbannerImage})`,
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        gap: '32px',
        alignItems: 'center',
        padding: '8px',
        width: '100%'
      },
    outlinedStyle: {
        height: '45px',
        maxWidth: '333px',
        width: '100%',
        fontSize: '14px',
        borderRadius: '8px',
        color: '#FFFFFF',
        '& .MuiOutlinedInput-input': {
            fontSize: '14px',
            padding: '16px 14px 16px 0px',
            color: '#FFFFFF',
        },
        '& ::placeholder': {
            opacity: 1,
            color: '#FFFFFF',
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: '2px solid #E6E6E6',
            borderRadius: '8px',
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: '2px solid #E6E6E6'
        },
    },
    AccordionTitle: {
        fontFamily: 'Poppins !important',
        color: '#000',
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: 'normal',
        fontStyle: 'normal',
    },
    QuestionTitle: {
        fontFamily: 'Poppins !important',
        color: '#000',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 600,
        textDecorationLine: 'underline',
        lineHeight: 'normal',
    },
    AnswerTitle: {
        color: '#000',
        fontFamily: 'Poppins !important',
        fontSize: '12px',
        fontStyle: 'normal',
        lineHeight: 'normal',
        fontWeight: 600,
    },
    QuestionTitleNew: {
        fontFamily: 'Poppins !important',
        color: '#000',
        fontStyle: 'normal',
        fontSize: '14px',
        lineHeight: 'normal',
        fontWeight: 600,
    },
    root: {
        marginTop: '100px',
        display: 'flex',
        flexDirection: 'column',
        minHeight: "100vh",
        boxShadow: 'none',
        alignItems: 'center',
        '@media(max-width: 1156px)': {
          marginTop: '70px',
        },
      },
      dialogBackground: {
        "& .MuiBackdrop-root": {
          background: '#0000004D',
        }
      },
      dialog: {
        display: 'flex',
        flexDirection: 'column',
        gap: '40px',
        padding: '24px',
        '@media(max-width: 697px)': {
          padding: '16px',
        },
        height: '283px',
      },
      markBox: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '124px',
        height: '124px',
        borderRadius: '50%'
      }
});

//@ts-ignore
export default withStyles(styles)(BuyerFaq);
export { BuyerFaq }
// Customizable Area End