import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import { getStorageData } from "../../../framework/src/Utilities";
const navigation = require("react-navigation");

export interface ValidResponseType {
    data: object;
    meta: object;
}

export interface InvalidResponseType {
    errors: string;
}

interface HelpAndSupportRes {
    help_and_support: {
        id: number;
        name: string;
        email: string;
        message: string;
        status: string;
        account_id: number;
        created_at: string;
        updated_at: string;
    }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    // Customizable Area Start
    navigation: typeof navigation;
    classes: Record<string, string>;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    isOpen: boolean;
    sellerName: string;
    sellerEmail: string;
    comment: string;
    nameError: string;
    emailError: string;
    commentError: string;
    sucessModal: boolean;
    helpAndSupportRes: HelpAndSupportRes;
    // Customizable Area End
}

interface SS {
    id: number;
    // Customizable Area Start
    // Customizable Area End
}

export default class HelpAndSupportFormController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    postHelpAndSupportAPICallId: string = "";
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.NavigationPayLoadMessage)
        ];

        this.state = {
            isOpen: true,
            sellerName: "",
            sellerEmail: "",
            comment: "",
            nameError: "",
            emailError: "",
            commentError: "",
            sucessModal: false,
            helpAndSupportRes: {} as HelpAndSupportRes
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            if (this.isValidResponse(responseJson)) {
                this.apiSuccessCallBacks(apiRequestCallId, responseJson);
            }
        }
        // Customizable Area End
    }
    // Customizable Area Start
    isValidResponse = (responseJson: ValidResponseType) => {
        return responseJson;
    };

    apiSuccessCallBacks = (apiRequestCallId: string, responseJson: ValidResponseType & HelpAndSupportRes) => {
        if (apiRequestCallId === this.postHelpAndSupportAPICallId) {
            this.postHelpSuccessCallBack(responseJson);
        }
    };

    apiCall = async (valueData: {
        contentType?: string;
        method?: string;
        endPoint?: string;
        body?: {};
        type?: string;
    }) => {
        const { contentType, method, endPoint, body, type } = valueData;
        const token = (await getStorageData("singupLogin")) || "";
        const header = {
            "Content-Type": contentType,
            token,
        };
        let apiBody = body;
        if (type === "") {
            apiBody = JSON.stringify(body);
        }
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        body &&
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                apiBody
            );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
    };

    toggleSideBar = () => {
        this.setState({ isOpen: !this.state.isOpen })
    }

    postHelpAndSupportApi = async () => {
        this.postHelpAndSupportAPICallId = await this.apiCall({
            contentType: configJSON.validationApiContentType,
            method: configJSON.exampleAPiMethod,
            endPoint: configJSON.postHelpAndSupportEndpoint,
            body: {
                "help": {
                    "name": this.state.sellerName,
                    "email": this.state.sellerEmail,
                    "message": this.state.comment
                }
            },
            type: ""
        });
    };

    postHelpSuccessCallBack = (responJson: HelpAndSupportRes) => {
        if (responJson) {
            this.setState({ sellerName: "", sellerEmail: "", comment: "", sucessModal: true, helpAndSupportRes: responJson })
        }
    };

    handleSellerName = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ sellerName: event.target.value, nameError: "" });
    };

    handleSellerEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ sellerEmail: event.target.value, emailError: "" });
    };

    handleSellerComment = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        this.setState({ comment: event.target.value, commentError: "" });
    };

    handleSubmitForm = () => {
        const { sellerName, sellerEmail, comment } = this.state;

        let isValid = true;
        let nameError = "";
        let emailError = "";
        let commentError = "";

        if (!sellerName.trim()) {
            nameError = "Name can't be blank.";
            isValid = false;
        }

        if (!sellerEmail.trim()) {
            emailError = "Email can't be blank.";
            isValid = false;
        } else if (!configJSON.emailRegex.test(sellerEmail)) {
            emailError = "Please enter a valid email address.";
            isValid = false;
        }

        if (!comment.trim()) {
            commentError = "Comment can't be blank.";
            isValid = false;
        }

        if (!isValid) {
            this.setState({ nameError, emailError, commentError });
            return;
        }
        this.postHelpAndSupportApi();
    };

    sucessMessageClose = () => {
        this.setState({ sucessModal: false })
    };
    // Customizable Area End
}