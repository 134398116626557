import React, { Component } from "react";
import {
    // Customizable Area Start
    Container,
    Box,
    Modal,
    Button,
    Typography
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import {
    leftArrow,
    whiteAppLogo
} from "./assets";
import EffectiveDateFormat from '../../../components/src/EffectiveDateFormat.web';
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";

const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});

interface ModalProps {
    showModal: boolean;
    id: any
    classes: any;
    sellerTermsConditionData: any
    navigation: any
    sellerModalOpen: boolean
    handleSellerModalClose: any;

}
// Customizable Area End

class SellerTermsAndCondtion extends Component<ModalProps>{
    constructor(props: ModalProps) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        // Customizable Area End
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <Modal
                    open={this.props.sellerModalOpen}
                    onClose={this.props.handleSellerModalClose}
                    className={classes.sectionTermsAndCondtion}
                >
                    <Box>
                        <Box className="termsConditionHeader">
                            <Box className="termsConditionHeaderInner">
                                <img src={whiteAppLogo} alt="" />
                            </Box>
                        </Box>
                        <Box className="leftArrow">
                            <Box className="leftArrowInner">
                                <Button onClick={this.props.handleSellerModalClose} data-test-id="closeButton">
                                    <img src={leftArrow} alt="" />
                                </Button>
                            </Box>
                        </Box>
                        <Container maxWidth="xl">
                            <h4 className="sellerModalHeading">{this.props.sellerTermsConditionData.heading}</h4>
                            {
                                this.props.sellerTermsConditionData.effectiveDate && (
                                    <Box className="sellerModalEffectiveDate">
                                        <Typography>
                                            <strong>Effective Date:</strong>
                                        </Typography>
                                        <Typography style={{ marginLeft: '5px', marginTop: '-3px' }}><strong><EffectiveDateFormat content={this.props.sellerTermsConditionData.effectiveDate} /></strong></Typography>
                                    </Box>
                                )
                            }
                            <Box className="termsAndCondtiondata">
                                <p dangerouslySetInnerHTML={{
                                    __html: this.props.sellerTermsConditionData.description
                                }}></p>
                            </Box>
                        </Container>
                    </Box>
                </Modal>
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const styles = (theme: any) => ({
    sectionTermsAndCondtion: {
        minHeight: '100vh',
        overflowY: 'scroll' as const,
        '& > div': {
            backgroundColor: '#ffffff',
        },
        '& .termsConditionHeader': {
            width: '100%',
            height: '100px',
            backgroundColor: '#000000',
            '& .termsConditionHeaderInner': {
                '& img': {
                    objectFit: 'contain',
                    marginLeft: '64px',
                    [theme.breakpoints.down('xs')]: {
                        marginLeft: '23px',
                    },
                },
            },
        },
        '& .leftArrow': {
            '& .leftArrowInner': {
                width: '56px',
                height: '56px',
                display: 'flex',
                border: '1px solid rgba(0, 0, 0, .3)',
                borderRadius: '50%',
                margin: '70px 70px 50px 70px',
                [theme.breakpoints.down('xs')]: {
                    marginLeft: '30px',
                },
                '& img': {
                    marginLeft: '-11px',
                },
                '& a': {
                    margin: 'auto'
                },
            },
        },
        '& .sellerModalHeading': {
            paddingLeft: '100px',
            fontSize: '20px',
            lineHeight: '30px',
            fontWeight: '600',
            [theme.breakpoints.down('sm')]: {
                paddingLeft: '50px',
            },
            [theme.breakpoints.down('xs')]: {
                paddingLeft: '20px',
            },
        },
        '& .sellerModalEffectiveDate': {
            paddingLeft: '100px',
            color: '#000',
            fontSize: '18px',
            fontWeight: 'bold',
            display: 'flex', 
            alignItems: 'center',
            fontStyle: 'normal',
            [theme.breakpoints.down('sm')]: {
                paddingLeft: '50px',
            },
            [theme.breakpoints.down('xs')]: {
                paddingLeft: '20px',
            },
        },
        '& .termsAndCondtiondata': {
            padding: '0px 100px 100px 100px',
            [theme.breakpoints.down('sm')]: {
                padding: '0px 50px 50px 50px',
            },
            [theme.breakpoints.down('xs')]: {
                padding: '0px 18px 20px 18px',
            },
            '& p': {
                fontSize: '18px',
                lineHeight: '27px',
            },
        },
    }
});

export default withStyles(styles)(SellerTermsAndCondtion);
export { SellerTermsAndCondtion }
// Customizable Area End
