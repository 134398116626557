import React from "react";
// Customizable Area Start
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import { Box, Grid, Button, FormControl, InputLabel, Select, TextField, Typography, Switch, FormHelperText } from "@material-ui/core";
import ManufacturingDetailsController, { Props } from "./ManufacturingDetailsController.web";
import { ExpandMoreRounded } from '@material-ui/icons';
import { Additems, Upload, Correct, cancel } from "./assets";
import Autocomplete from '@material-ui/lab/Autocomplete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
export const configJSON = require("./config");

const IOSSwitch = withStyles((theme: Theme) =>
    createStyles({
        root: {
            width: 49,
            height: 27,
            padding: 0,
            margin: 0,
        },
        switchBase: {
            padding: "3px 0 3px 4px",
            '&$checked': {
                transform: 'translateX(23px)',
                color: theme.palette.common.white,
                '& + $track': {
                    backgroundColor: '#F5EA16',
                    border: "1px solid #d9d8d8",
                    opacity: 1,
                },
            },
            '&$focusVisible $thumb': {
                color: '#F5EA16',
                border: '6px solid #fff',
            },
        },
        thumb: {
            width: 20,
            height: 20,
            backgroundColor: "#444",
        },
        track: {
            borderRadius: 27 / 2,
            backgroundColor: 'white',
            border: "1px solid #d9d8d8",
            opacity: 1,
        },
        checked: {},
        focusVisible: {},
    }),
)(Switch);
// Customizable Area End

export class ManufacturingDetails extends ManufacturingDetailsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    countryBox() {
        const styles = this.props.classes;
        const {countryList, county} = this.state
        return (
            <Autocomplete
                id="combo-box-demo"
                options={countryList.map((item: any) => item.name)}
                getOptionLabel={(option: any) => option}
                style={{ width: '100%' }}
                onChange={this.handleCountryChange}
                data-test-id="countryTestId"
                value={county}
                renderInput={(params: any) => (
                    <TextField
                        {...params}
                        className={styles.formControl_Option_TextBox}
                        error={this.state.countryError}
                        helperText={this.state.countryError ? configJSON.packagingDetailsError : ""}
                        placeholder="Country"
                        variant="outlined"
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <ExpandMoreIcon style={{ height: '32px', width: '32px' }} />
                            ),
                        }}
                    />)}
            />
        )
    }
    manufactureDetailBox() {
        const styles = this.props.classes;
        return (
            <TextField
                data-testid="manufacturedetailsTestId"
                id="outlined-required"
                error={this.state.manufacturerDetailsError}
                helperText={this.state.manufacturerDetailsError ? configJSON.packagingDetailsError : ""}
                onChange={this.handleManufacturerDetails}
                className={styles.formControl_Option_TextBox}
                label="Manufacturer Details"
                variant="outlined"
                value={this.state.manufacturerDetails}
            />
        )
    }
    packerBox() {
        const styles = this.props.classes
        return (
            <TextField
                data-testid="packerDetailsTestId"
                id="outlined-required"
                error={this.state.packerDetailsError}
                helperText={this.state.packerDetailsError ? configJSON.packagingDetailsError : ""}
                onChange={this.handlePackerDetails}
                className={styles.formControl_Option_TextBox}
                label="Packer Details"
                variant="outlined"
                value={this.state.packerDetails}
            />
        )
    }
    formfield = (item: any, index: number) => {
        const styles = this.props.classes;
        return (
            <FormControl variant="outlined" className={styles.formControl}>
                <InputLabel style={{ color: this.state.storageSizeError[index] ? 'red' : 'inherit' }} htmlFor="outlined-age-native-simple">{item.attributes.name}</InputLabel>
                <Select
                    key={index}
                    data-testid={`storageSlectSizeTestId_${index}`}
                    IconComponent={ExpandMoreRounded}
                    native
                    error={this.state.storageSizeError[index]}
                    onChange={(event) => this.handleStorageFieldSelect(event, index)}
                    label={item.attributes.name}
                    className={styles.formControl_Option_Selected}
                    value={this.state.storageSize[index]}
                >
                    <option disabled></option>
                    {item.attributes.specification_data.map((val: any) => {
                        return (
                            <>
                                <option value={val} key={item.attributes.name}>{val}</option>
                            </>
                        )
                    })}
                </Select>
                <FormHelperText error={this.state.storageSizeError[index]}>{this.state.storageSizeError[index] ? configJSON.packagingDetailsError : ""}</FormHelperText>
            </FormControl>
        )
    }
    // Customizable Area End
    render() {
        // Customizable Area Start
        const styles = this.props.classes;
        return (
            <>
                <Box className={styles.manufacturing_Details_Main_Container} data-test-id="mainContainer">
                    <Box className={styles.manufacturing_Details_Main_Heading_Container}>
                        <Typography className={styles.detailsTitle}>{configJSON.manufacturingDetails}</Typography>
                    </Box>
                    <form  data-testid="submitButtonTestId">
                        <Grid container>
                            <Grid item xs={12} sm={12} md={12} lg={8} >
                                <Box className={styles.manufacturing_Details_Left_Container}>
                                    {this.countryBox()}
                                    {this.manufactureDetailBox()}
                                    {this.packerBox()}
                                    
                                    {this.state.manufacturingProductDetails?.data?.attributes?.product_detail_specifications?.data.length > 0 &&
                                    <>
                                    <Box className={styles.productDetailsContainer}>
                                        <Typography className={styles.detailsTitle}>{configJSON.productDetails}</Typography>
                                    </Box>
                                    <Box className={styles.multiple_Selection}>
                                                {this.state.manufacturingProductDetails.data.attributes?.product_detail_specifications?.data?.map((item: any, index: number) => {
                                                    return (
                                                        <>
                                                            {item.attributes?.specification_type === "text" ?
                                                                <>
                                                                    <TextField
                                                                        key={index}
                                                                        data-testid={`storageSizeTestId_${index}`}
                                                                        id="outlined-required"
                                                                        error={this.state.storageSizeError[index]}
                                                                        helperText={this.state.storageSizeError[index] ? configJSON.packagingDetailsError : ""}
                                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleStorageField(event, index)}
                                                                        className={styles.formControl_Option_TextBox}
                                                                        label={item.attributes.name}
                                                                        variant="outlined"
                                                                        value={this.state.storageSize[index]}
                                                                    />
                                                                </>
                                                                :
                                                                <>
                                                                    {this.formfield(item, index)}
                                                                </>
                                                            }
                                                        </>
                                                    )
                                                })
                                                }
                                    </Box>
                                    </>
                                    }
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={4}>
                                <Box className={styles.manufacturing_Details_Right_Container}>
                                    <Box className={styles.right_Container_Radio_Button_Container}>
                                        <Box className={styles.radio_Button_Container}>
                                            <Box className={styles.radio_Button_Text_Container}>
                                                <Typography className={styles.radio_Button_Text_Heading}>{configJSON.returnExchange}</Typography>
                                                <Typography className={styles.radio_Button_Text}>{configJSON.provide}</Typography>
                                            </Box>
                                            <IOSSwitch
                                                data-testid="exchangeCheckTestId"
                                                checked={this.state.exchangeCheck}
                                                onChange={this.handleExchangeCheckChange}
                                                color="default"
                                                inputProps={{ 'aria-label': 'iOS style switch' }}
                                                value={this.state.exchangeCheck}
                                            />
                                        </Box>
                                        <Box className={styles.radio_Button_Container}>
                                            <Box className={styles.radio_Button_Text_Container}>
                                                <Typography className={styles.radio_Button_Text_Heading}>{configJSON.returnRefund}</Typography>
                                                <Typography className={styles.radio_Button_Text}>{configJSON.provide}</Typography>
                                            </Box>
                                            <IOSSwitch
                                                data-testid="refundCheckTestId"
                                                checked={this.state.refundCheck}
                                                onChange={this.handleRefundCheckChange}
                                                color="default"
                                                inputProps={{ 'aria-label': 'iOS style switch' }}
                                                value={this.state.refundCheck}
                                            />
                                        </Box>
                                    </Box>
                                    <Box className={styles.right_Container_Size_Chart_Container}>
                                        <Typography className={styles.manufacturing_Details_Size_Chart_Heading}>{configJSON.chart}</Typography>
                                        <Box className={this.state.chartPictureName === "" ||  this.state.chartPictureName === null ? styles.size_Chart_Container : styles.hide_Container}>
                                            <label
                                                htmlFor="contained-button-file"
                                                className={styles.size_Chart_Container_Label}
                                            >
                                                <input
                                                    data-testid="sizeChartTestId"
                                                    accept="image/*"
                                                    id="contained-button-file"
                                                    style={{ display: "none" }}
                                                    type="file"
                                                    onChange={this.handleUploadChartPicture}
                                                />
                                                <img src={Upload} alt="Upload" className={styles.size_Chart_Upload_Img} />
                                                <Typography className={styles.size_Chart_Upload_Text}><span className={styles.size_Chart_Upload_Text_Span}>{configJSON.upload}</span>{" "}{configJSON.here}</Typography>
                                            </label>
                                        </Box>
                                        <Box className={this.state.chartPictureName === "" || this.state.chartPictureName === null ? styles.hide_Container : styles.size_Chart_Container}>
                                            <Box className={styles.size_Chart_Uploaded_Container} data-testid="sizeChartImagePreview">
                                                <Box className={styles.size_Chart_Uploaded_Box} >
                                                    <img src={Correct} alt="Correct" className={styles.size_chart_Correct} />
                                                    <Box className={styles.size_Chart_Text_Container}>
                                                        <Typography className={styles.size_Chart_Text_File_Name}>{this.state.chartPictureName}</Typography>
                                                        <Typography className={styles.size_Chart_Text_File_Size}>{this.state.chartPictureSize}{" "}{configJSON.kb}</Typography>
                                                    </Box>
                                                </Box>
                                                <img src={cancel} alt="cancel" className={styles.size_Chart_Cancel_Btn} onClick={this.handleCancelUpload} />
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Box className={styles.addVaraintBox} onClick={()=>{this.handleManufacturingDetailsSubmit("addVaraint")}}>
                                        <img  src={Additems} alt="Additems" className={styles.addItemsImage} />
                                        <Typography className={styles.addVariant}>{configJSON.addEdit}<span  className={styles.addVariantTextUnderLine}>{configJSON.variant}</span></Typography>
                                    </Box>
                                </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>

                                <Box className={styles.manufacturing_Submit_Button_Container}>
                                    <Button  data-testid="saveButton" onClick={()=>{this.handleManufacturingDetailsSubmit("save")}} className={styles.manufacturing_Submit_Button}>{configJSON.ave}</Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </>
        );
        // Customizable Area End
    }
}
// Customizable Area Start
const styles = () =>
    createStyles({
        manufacturing_Details_Main_Container: {
            padding: "30px 60px 45px 55px",
            "@media (max-width: 600px)": {
                padding: "30px 3% 45px",
            }
        },
        manufacturing_Details_Main_Heading_Container: {
            marginBottom: "25px"
        },
        productDetailsContainer: {
            marginTop: "-2px"
        },
        detailsTitle: {
            color: "#555",
            fontFeatureSettings: "'clig' off, 'liga' off",
            fontFamily: "Poppins",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "normal",
        },
        manufacturing_Details_Left_Container: {
            paddingRight: "4%",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            gap: "26px",
            flexDirection: "column",
            "@media (max-width: 1279px)": {
                paddingRight: "0px",
            }
        },
        manufacturing_Details_Right_Container: {
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            gap: "45px",
            flexDirection: "column",
            "@media (max-width: 1279px)": {
                paddingTop: "26px",
            }
        },
        formControl_Option_TextBox: {
            width: "100%",
            height: "56px",
            color: "#444",
            fontFeatureSettings: "'clig' off, 'liga' off",
            fontFamily: "Poppins",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "normal",
            '& .MuiInputBase-root': {
                paddingRight: '10px !important',
                '&.Mui-focused': {
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderRadius: '8px',
                    },
                },
                '& .MuiOutlinedInput-notchedOutline': {
                    borderRadius: '8px',
                },
            },
            "@media (max-width: 560px)": {
                width: "100%",
            }
        },
        formControl_Option_Selected: {
            height: "100%",
            color: "#444",
            fontFeatureSettings: "'clig' off, 'liga' off",
            fontFamily: "Poppins",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "normal",
            "& .MuiSelect-select": {
                height: "fit-content !important",
                background: "transparent"
            },
            "& .MuiListItem-root": {
                height: "100%",
            },
            "& .MuiOutlinedInput-notchedOutline": {
                borderRadius: '8px',
            },
            "@media (max-width: 560px)": {
                width: "100%",
            }
        },
        formControl: {
            width: "100%",
            height: "56px",
            marginTop: '10px'
        },
        addVaraintBox: {
            marginTop:"1.5em",
            display:"flex",
            alignItems:"center",
            gap:"4px"
        },
        addItemsImage: {
            width: "24px",
            height: "24px",
            cursor: "pointer"
        },
        addVariant: {
            color: "#555",
            fontFeatureSettings: "'clig' off, 'liga' off",
            fontFamily: "Poppins",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "normal",
        },
        addVariantTextUnderLine: {
            fontWeight: 600,
            textDecorationLine: "underline",
            cursor: "pointer"
        },
        right_Container_Radio_Button_Container: {
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            gap: "16px",
            flexDirection: "column",
            width: "100%",
        },
        radio_Button_Container: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            gap: "10px",
            width: "100%",
            "@media (max-width: 380px)": {
                flexDirection: "column",
            }
        },
        radio_Button_Text_Container: {
            display: "flex",
            gap: "8px",
            flexDirection: "column",
        },
        radio_Button_Text_Heading: {
            color: "#444",
            fontFeatureSettings: "'clig' off, 'liga' off",
            fontFamily: "Poppins",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "normal",
        },
        radio_Button_Text: {
            color: "#555",
            fontFeatureSettings: "'clig' off, 'liga' off",
            fontFamily: "Poppins",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "normal",
        },
        right_Container_Size_Chart_Container: {
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            gap: "12px",
            flexDirection: "column",
            width: "100%",
        },
        manufacturing_Details_Size_Chart_Heading: {
            color: "#555",
            fontFeatureSettings: "'clig' off, 'liga' off",
            fontFamily: "Poppins",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "normal",
        },
        size_Chart_Container: {
            border: "1px dashed rgba(68, 68, 68, 0.60)",
            borderRadius: "6px",
            height: "82px",
            width: "100%",
        },
        size_Chart_Container_Label: {
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "8px",
            flexDirection: "column",
            cursor: "pointer"
        },
        size_Chart_Upload_Img: {
            width: "30px",
            height: "30px",
        },
        size_Chart_Upload_Text: {
            color: "#555",
            fontFeatureSettings: "'clig' off, 'liga' off",
            fontFamily: "Poppins",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "normal",
        },
        size_Chart_Upload_Text_Span: {
            fontWeight: 600,
            textDecorationLine: "underline"
        },
        manufacturing_Submit_Button_Container: {
            display: "flex",
            justifyContent: "flex-end",
            paddingTop: "42px",
        },
        manufacturing_Submit_Button: {
            height: "48px",
            background: "#F5EA16",
            width: "350px",
            display: "flex",
            borderRadius: "30px",
            alignItems: "center",
            justifyContent: "center",
            fontFeatureSettings: "'clig' off, 'liga' off",
            color: "#555",
            fontSize: "16px",
            fontFamily: "Poppins",
            fontWeight: 500,
            fontStyle: "normal",
            cursor: "pointer",
            textTransform: "unset",
            lineHeight: "normal",
            "@media (max-width: 620px)": {
                padding: "0 6%",
                width: "100%",
            },
            "&:hover": {
                background: "#F5EA16",
            },
        },
        hide_Container: {
            display: "none"
        },
        size_Chart_Uploaded_Container: {
            width: "100%",
            height: "100%",
            padding: "0 12px 0 16px",
            display: "flex",
            justifyContent: "space-between",
        },
        size_Chart_Uploaded_Box: {
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: "12px",
        },
        size_chart_Correct: {
            width: "32px",
            height: "32px"
        },
        size_Chart_Text_Container: {
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: "5px",
            flexDirection: "column",
        },
        size_Chart_Text_File_Name: {
            color: "#2E2E2E",
            fontFeatureSettings: "'clig' off, 'liga' off",
            fontFamily: "Poppins",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "normal",
        },
        size_Chart_Text_File_Size: {
            color: "#2E2E2E",
            fontFeatureSettings: "'clig' off, 'liga' off",
            fontFamily: "Poppins",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: 300,
            lineHeight: "normal",
        },
        size_Chart_Cancel_Btn: {
            marginTop: "12px",
            width: "18px",
            height: "18px",
            cursor: "pointer",
        },
        multiple_Selection:{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            gap: "16px",
            flexDirection: "column",
            width: "100%",
        }
    });
export default withStyles(styles)(ManufacturingDetails)
// Customizable Area End