import React from "react";
// Customizable Area Start
import InventoryListingController ,{Props} from "./InventoryListingController.web";
import { createTheme,  withStyles, Theme } from "@material-ui/core/styles";
import { Box,Typography,Checkbox} from "@material-ui/core";
import {selectAllButton,deleteIcon,checkBoxIcon,addVariantIcon,editProductIcon} from "./assets";
import { CheckboxProps } from "@material-ui/core/Checkbox";
import { configJSON } from "./AddNewProductController.web";
import ConfirmationModal from "../../../components/src/ConfirmationModal.web";
import Pagination from "@material-ui/lab/Pagination";
import SuccessDialog from "../../../components/src/SuccesDailogMessage.web";
import SnackBarAlert from "../../../components/src/SnackBarAlert.web"

let config = require('../../../framework/src/config')



const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
});

export const webStyles = (theme: Theme) => ({
    eachCardSection:{
        borderRadius: "10px",
        margin:"2em 0em",
        boxShadow: '0px 8px 20px 0px rgba(0, 0, 0,0.2)',
        width: '100%',
        height: 'auto',
        display: 'flex', 
        flexDirection: 'row' as const,
        alignItems: 'center' as const,
        padding: "1em 0em",
        cursor:"pointer",
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    listingMainContainer:{
        paddingRight:"3em"
    },
    eachImageSection:{
        borderRadius: '8px',
        border: '1px solid rgba(68, 68, 68, 0.10)',
        background: '#FFF',
        width:"6.5em",
        padding:"0.5em",
        display: "flex", 
       justifyContent: "center", 
       alignItems: "center"
    },
    title:{
        color: '#444',
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
    },
    description:{
        color: 'rgba(68, 68, 68, 0.70)',
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
        overflow: 'hidden'as const ,
        display: '-webkit-box' as const,
        WebkitBoxOrient: 'vertical' as const,
        WebkitLineClamp: 2,
        textOverflow: 'ellipsis' as const,
    },
    imageStyles:{
        width: '84px',
        height: "84px" 
    },
    descriptionContainer:{
        flex: 1 ,
        margin:'0em 1em'},
    mrp:{ 
        marginRight:'1em',
        color: '#444',
        fontSize: '20px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: 'normal',
    },
    deleteSection:{
        marginTop:"2em"
    },
    iconSections:{
        display:"flex",
        justifyContent:'flex-end'
    },
    selectAllButton:{
        marginRight:"1.5em",
        cursor:"pointer"
    },
    checkBoxIcon:{
        width:"18px",
        height:"18px"
    },
    deleteButton:{
        cursor:"pointer"
    },
    actionIcon: {
        maxWidth: '100%',
        height: 'auto',
        verticalAlign: 'middle',
        cursor:"pointer"
      },
      imageActionContainter: {
        display: 'flex',
        alignItems: 'center',
      },
      actionText: {
        marginLeft: '0.2em',
        marginRight:"1em",
        color: '#444',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
      },
      imageSection:{
        display:"flex"
      },
      paginationContainer:{
        display:"flex",
        justifyContent:"center",
        margin:"1em 0em"
      }
      
    
});

export const YellowCheckbox = withStyles({
    root: {
        '&$checked': {
            color: "yellow",
        },
    },
    checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);


// Customizable Area End

export class InventoryListing extends InventoryListingController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    // Customizable Area End
    render() {
        // Customizable Area Start
       const {classes,inventoryManagementList} = this.props
       const { checkedItems } = this.state
       const count = Object.values(checkedItems).filter(value => value).length;
       const singleSelect = count === 1;
       const multipleSelect = count >= 1;
        return (
          <Box className={classes.listingMainContainer} data-test-id="mainContainer">
            <Box className={classes.iconSections}>
                {
                   singleSelect &&
                    <Box style={{display:"flex",marginRight:"1em",marginTop:"2em"}}>
                       <Box className={classes.imageActionContainter}>
                                <img 
                                    src={editProductIcon}
                                    alt="editProductIcon" 
                                    className={classes.actionIcon}
                                    data-test-id="editProductIcon"
                                    onClick={this.handleEditProduct}
                                />
                                <Typography className={classes.actionText}>{configJSON.edit}</Typography>
                                </Box>

                        <Box  className={classes.imageActionContainter}>
                            <img 
                                src={addVariantIcon}
                                alt="addVariantIcon" 
                                className={classes.actionIcon}
                                data-test-id="addVariantIcon"
                                onClick={this.handleAddVariant}
                            />
                            <Typography className={classes.actionText}>{configJSON.addVariant}</Typography>
                        </Box>
                    </Box>
                }
                <Box className={classes.deleteSection}>
                    <img 
                    src={selectAllButton}
                    alt="selectAllButton" 
                    onClick={this.handleSelectAllToggle} 
                    className={classes.selectAllButton}
                    data-test-id="selectAllButton"
                     />
                    <img 
                    src={deleteIcon} 
                    alt="deleteIcon" 
                    data-test-id="deleteButton"
                    className={classes.deleteButton}
                    onClick={multipleSelect ? this.handleOpenDeleteDialog : undefined}/>
                </Box>
            </Box>
           {inventoryManagementList?.map((eachInventory) => {
                    const itemId = eachInventory?.id.toString()
                    const {attributes} = eachInventory                     
                    return (
                        <Box key={itemId} className={classes.eachCardSection} onClick={()=>this.handleClickOfEachProduct(itemId)}>
                               <Box className={classes.imageSection}>
                                <YellowCheckbox
                                    data-test-id={`checkbox${itemId}`}
                                    checked={!!checkedItems[itemId]}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleCheckboxChange(event, itemId)}
                                    checkedIcon={<img src={checkBoxIcon} alt="customCheckboxIcon" className={classes.checkBoxIcon}/>}
                                    onClick={(event) => event.stopPropagation()}
                                />
                              
                                <Box className={classes.eachImageSection} >
                                    {eachInventory?.attributes?.product_images &&
                                        eachInventory.attributes.product_images.length > 0 &&
                                        eachInventory.attributes.product_images[0]?.url ? (
                                        <img
                                            src={config.baseURL + eachInventory.attributes.product_images[0].url}
                                            alt="inventoryImage"
                                            className={classes.imageStyles}
                                            data-testid="inventory-image"
                                        />
                                    ) : (
                                        <img
                                            src="path/to/your/placeholder/image.png"
                                            alt="InventoryImage"
                                            className={classes.imageStyles}
                                            data-testid="placeholder-image"
                                        />
                                    )}
                                </Box>
                                </Box>
                              

                                <Box className={classes.descriptionContainer} >
                                    <Box> 
                                        <Typography className={classes.title}>
                                            {attributes?.title}
                                        </Typography>
                                        <Typography className={classes.description}>
                                            {attributes?.description}
                                        </Typography>
                                    </Box>
                                </Box>

                                <Box className={classes.mrp} sx={{fontFamily: "Arial !important" }}>
                                        {configJSON.rupeeSymbol}{attributes?.mrp}                                  
                                </Box>

                        </Box>
                        );
           })}

            <SuccessDialog
                    data-test-id="sucessMessage"
                    open={this.state.errorModal}
                    closeFn={this.errorModalClose}
                    successTitle={configJSON.sucessMessage}
                    successMessage={configJSON.deleteInventoryMessage}
                />
           <Box className={classes.paginationContainer}>
           <Pagination data-test-id="inventoryPagination" count={this.props.perPageCount} page={this.props.page} onChange={this.props.handleChangePagination} />
           </Box>
            <ConfirmationModal
                    open={this.state.openDeleteDialog}
                    handleClose={this.handleCloseDeleteDialog}
                    handleDelete={this.handleDeleteSelectedItem} 
                    title={configJSON.areYouWantToDelte}
                    description={configJSON.deleteDescription}
                    cancelButtonText={configJSON.cancel}
                    deleteButtonText={configJSON.delete}
                />
                <SnackBarAlert data-test-id="snackBarCloseTestId"   snackBarProps={this.state.hasSuccessErrorForInv} handleCloseSnack={this.handleCloseSnack} />
          </Box>

            );
        // Customizable Area End
    }
}


// Customizable Area Start
export default withStyles(webStyles)(InventoryListing)
// Customizable Area End