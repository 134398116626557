Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.fedxApiContentType = "application/json";
exports.fedxApiMethodType = "POST";
exports.fedxApiMethodTypeGet = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "fedexintegration";
exports.labelBodyText = "fedexintegration Body";
exports.crateShippmentErr = "Please Fill the mendatory fields";
exports.crateShippmentMailErr = "Invalid Email Id"
exports.errorCrateShippmentErr = "*";
exports.createShippmentAPiEndPoint = "/fedex_integration/shipments";
exports.fedxGetShippmentDetailsEndpoint = "/fedex_integration/shipments/";

exports.btnExampleTitle = "CLICK ME";
// Customizable Area End