import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
const configJSON = require("./config");
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    classes: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    token: string;
    inputValue: string;
    showMobileField: boolean;
    phoneError: string;
    emailError: string;
    label: string;
    phone: string;
    email: string;
    selectedValue: any;
    loading: boolean;
    dataSource: any;
    showAlert: boolean;
    otpState: any;
    time: any;
    interval: any;
    isTimerRunning: boolean;
    password: string;
    showPassword: boolean;
    copyPassword: string;
    passwordError: string;
    confirmPassword: string;
    showConfirmPassword: boolean;
    copyConfirmPassword: string;
    confirmPasswordError: string;
    errorMessage: any;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class BuyerForgotPasswordController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    getCountryCodeApiCallId: string = '';
    sendOtpApiCallId: string = '';
    validateOTPApiCallId: string = '';
    resetPasswordApiCallId: string = '';
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
        ];

        this.state = {
            loading: false,
            token: '',
            inputValue: '',
            showMobileField: false,
            phoneError: '',
            emailError: '',
            label: 'Email / Mobile',
            phone: '',
            email: '',
            selectedValue: {},
            dataSource: [],
            showAlert: false,
            otpState: '',
            time: 30,
            interval: null,
            isTimerRunning: false,
            password: '',
            showPassword: false,
            copyPassword: '',
            passwordError: '',
            confirmPassword: '',
            showConfirmPassword: false,
            copyConfirmPassword: '',
            confirmPasswordError: '',
            errorMessage: ''
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId === this.getCountryCodeApiCallId) {
                if (responseJson.data) {
                    this.setState({
                        dataSource: responseJson.data,
                        selectedValue: responseJson.data
                    });
                }
            } else if (apiRequestCallId === this.sendOtpApiCallId) {
                this.setState({ loading: false });
                if (responseJson.data) {
                    setStorageData('verifyOtpToken', responseJson.meta.token);
                    (window.location.pathname === '/BuyerForgotPassword' && setStorageData("resendOTP", this.state.showMobileField ? this.state.phone : this.state.email));
                    this.props.navigation.navigate(`BuyerVerifyOtp`)
                    this.setState({ time: 30, isTimerRunning: true });
                    this.startTimer();
                }
                else if (responseJson.errors[0]) {
                    this.setState({ showAlert: true, errorMessage: Object.values(responseJson.errors[0]) })
                }
            } else if (apiRequestCallId === this.validateOTPApiCallId) {
                this.setState({ loading: false });
                if (responseJson.messages?.[0]) {
                    removeStorageData('verifyOtpToken')
                    removeStorageData('resendOTP')
                    setStorageData('resetPasswordToken', responseJson.meta.token);
                    this.props.navigation.navigate(`BuyerResetPassword`)
                }
                else if (responseJson.errors?.[0]) {
                    this.setState({ showAlert: true, errorMessage: Object.values(responseJson.errors[0]) })
                }
            } else if (apiRequestCallId === this.resetPasswordApiCallId) {
                this.setState({ loading: false });
                if (responseJson.data) {
                    removeStorageData('resetPasswordToken')
                    this.props.navigation.navigate(`BuyerLogin`)
                }
            }

        }
        // Customizable Area End
    }

    async componentDidMount() {
        // Customizable Area Start
        this.startTimer();
        // Customizable Area End
    }

    // Customizable Area Start

    startTimer = () => {
        this.setState({ isTimerRunning: true }, () => {
            this.setState({
                interval: setInterval(() => {
                    this.setState((prevState) => ({
                        time: prevState.time > 0 ? prevState.time - 1 : 0,
                    }));
                }, 1000),
            });
        });
    };

    closeAlert = () => {
        this.setState({ showAlert: false })
    }

    handleOTPState = (val: any) => {
        this.setState({ otpState: val })
    }

    setError = (stateKey: keyof S, errorMsg: string) => {
        this.setState((prevState) => ({
            ...prevState,
            [stateKey]: errorMsg,
        }));
    };

    handleInputValidation = () => {
        const { showMobileField, phone, email } = this.state;

        if (showMobileField) {
            if (phone.trim().length === 0) {
                this.setState({ phoneError: "" });
                return true;
            }
            if (phone.length !== 12) {
                this.setError("phoneError", 'Please Enter Valid Mobile number');
                return false;
            }
            this.setState({ phoneError: "" });
        } else {
            if (!showMobileField) {
                if (email.trim().length === 0) {
                    this.setState({ emailError: "" });
                    return true;
                }
                if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,3}$/g.test(email)) {
                    this.setError("emailError", 'Enter valid email i.e. johndoe@gmail.com');
                    return false;
                }
                this.setState({ emailError: "" });
            }
        }

        return true;
    };

    handleInputChange = (event: any) => {
        const inputValue = event.target.value;
        const hasCharacter = /[a-zA-Z]/.test(inputValue);

        if (this.state.showMobileField) {
            const numericValue = inputValue.replace(/\D/g, '');
            this.setState({ phone: numericValue, phoneError: "" }, () => {
                if (this.state.phone.trim().length === 0) {
                    this.setState({ phoneError: "" });
                }
            });
        } else {
            this.setState({ email: inputValue, emailError: "" }, () => {
                if (this.state.email.trim().length === 0) {
                    this.setState({ emailError: "" });
                }
            });
        }

        this.setState({ showMobileField: inputValue === "" ? false : !hasCharacter });
    };

    sendOtp = () => {
        const { showMobileField, phone, email } = this.state;

        let isValid = true;

        if (showMobileField) {
            if (phone.length !== 12) {
                this.setError("phoneError", 'Please Enter Valid Mobile number');
                isValid = false;
            } else {
                this.setState({ phoneError: "" });
            }
        } else {
            if (email !== "" && !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,3}$/g.test(email)) {
                this.setError("emailError", 'Enter valid email i.e. johndoe@gmail.com');
                isValid = false;
            } else if (email == "") {
                this.setError("emailError", 'Please Enter Valid Email Id / Mobile number');
                isValid = false;
            } else {
                this.setState({ emailError: "" });
            }
        }

        if (isValid) {
            this.continueSendOtp();
        }
    };

    handlePasswordKey = (event: any) => {
        const { showPassword, copyPassword } = this.state;
        const keyCode = event.keyCode || event.which;
        if (keyCode == 8) {
            const position = event.target.selectionStart;
            if (!showPassword) {
                let value = copyPassword.split("")
                value.splice(position - 1, 1)
                this.setState({ copyPassword: value.join("") })

            }
        }
    }

    handleConfirmPasswordKey = (event: any) => {
        const { showConfirmPassword, copyConfirmPassword } = this.state;
        const keyCode = event.keyCode || event.which;
        if (keyCode == 8) {
            const position1 = event.target.selectionStart;
            if (!showConfirmPassword) {
                let value = copyConfirmPassword.split("")
                value.splice(position1 - 1, 1)
                this.setState({ copyConfirmPassword: value.join("") })

            }
        }
    }

    handlePasswordValidation = () => {
        const { copyPassword, copyConfirmPassword } = this.state;

        const resetErrors = () => {
            this.setState({
                passwordError: "",
                confirmPasswordError: ""
            });
        };

        if (copyPassword.trim().length === 0) {
            resetErrors();
            return true;
        }

        const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%^&+=!]).{8,}$/;
        if (!passwordRegex.test(copyPassword)) {
            this.setError("passwordError", configJSON.errorPasswordNotValid);
            return false;
        }

        resetErrors();

        if (copyConfirmPassword.trim().length === 0) {
            return true;
        }

        if (copyPassword !== copyConfirmPassword) {
            this.setError("confirmPasswordError", configJSON.confirmPwdError);
            return false;
        }

        return true;
    };

    handlePasswordChange = (event: any) => {
        const { showPassword, copyPassword } = this.state;
        const { value } = event.target;
        this.setState({ password: value });

        const updatedCopyPassword = showPassword ? value : copyPassword + value.split("*").join("");
        this.setState({ copyPassword: updatedCopyPassword, passwordError: "" });
    };

    toggleShowPassword = () => {
        this.setState((prevState) => ({
            showPassword: !prevState.showPassword,
        }));
    };

    handleConfirmPasswordChange = (event: any) => {
        const { value } = event.target;
        const { showConfirmPassword, copyConfirmPassword } = this.state;

        this.setState({ confirmPassword: value });

        const updatedCopyConfirmPassword = showConfirmPassword ? value : copyConfirmPassword + value.split("*").join("");
        this.setState({ copyConfirmPassword: updatedCopyConfirmPassword, confirmPasswordError: "" });
    };

    toggleShowConfirmPassword = () => {
        this.setState((prevState) => ({
            showConfirmPassword: !prevState.showConfirmPassword,
        }));
    };

    handleResetPassword = () => {
        const { copyPassword, copyConfirmPassword } = this.state;

        if (!/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%^&+=!]).{8,}$/.test(copyPassword)) {
            this.setError("passwordError", configJSON.errorPasswordNotValid);
        } else {
            this.setState({ passwordError: "" });

            if (copyPassword !== copyConfirmPassword) {
                this.setError("confirmPasswordError", configJSON.confirmPwdError);
            } else {
                this.setState({ confirmPasswordError: "" });
                this.resetPassword();
            }
        }
    };

    getCountryCode = () => {
        const header = { "Content-Type": "application/json" };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getCountryCodeApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `account/accounts/country_code_and_flag`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    continueSendOtp = () => {
        this.setState({ time: 30, isTimerRunning: true, loading: true });
        const { email, phone } = this.state

        const mobileBody = {
            "data": {
                "attributes": {
                    "full_phone_number": phone
                }
            }
        }

        const emailBody = {
            "data": {
                "attributes": {
                    "email": email
                }
            }
        }

        const header = { "Content-Type": "application/json" };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.sendOtpApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_forgot_password/otps`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(this.state.showMobileField ? mobileBody : emailBody));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    resendOtp = async () => {
        clearInterval(this.state.interval!);
        this.setState({ loading: true });
        const payload = await getStorageData("resendOTP");
        const mobileBody = {
            "data": {
                "attributes": {
                    "full_phone_number": payload
                }
            }
        }

        const emailBody = {
            "data": {
                "attributes": {
                    "email": payload
                }
            }
        }

        const header = { "Content-Type": "application/json" };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.sendOtpApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_forgot_password/otps`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(/[a-zA-Z]/.test(payload) ? emailBody : mobileBody));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    validateOTP = async () => {
        this.setState({ loading: true });

        const httpBody = {
            "data": {
                "otp_code": this.state.otpState
            }
        }

        const header = { "Content-Type": "application/json", token: await getStorageData('verifyOtpToken') };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.validateOTPApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_forgot_password/otp_confirmations`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    resetPassword = async () => {
        this.setState({ loading: true });

        const { copyPassword } = this.state

        const httpBody = {
            "data": {
                "token": await getStorageData('resetPasswordToken'),
                "new_password": copyPassword
            }
        }

        const header = { "Content-Type": "application/json" };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.resetPasswordApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_forgot_password/passwords`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    // Customizable Area End
}
