import React from "react";

import {
    // Customizable Area Start
    Box,
    Grid,
    Typography,
    Button,
    Container
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import {
    vectorTransparent,
    verctorBlack,
    bannerIcon1,
    bannerIcon2,
    bannerIcon3,
} from "./assets";
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import { Link } from 'react-router-dom';
const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});
// Customizable Area End

import LandingPageControllerWeb, {
    Props,
} from "./LandingPageControllerWeb";

class LandingPageBanner extends LandingPageControllerWeb {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const { landingPageBannerData } = this.state;
        // Customizable Area End
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <section className={classes.sectionBanner}>
                    <Container maxWidth={"xl"}>
                        <Box className={'bannerWrapper'}>
                            {landingPageBannerData?.map((items: any) => (
                                <Box className={'bannerInner'}>
                                    <Grid className={classes.bannerFlexWrapper} container spacing={2}>
                                        <Grid className={'bannerFlexColumn'} item lg={5}>
                                            <Box className={'bannerLeft'}>
                                                <Box sx={{ display: 'flex', flexDirection: 'column', gridGap: '20px' }} className={'bannerLeftInner'}>
                                                    <Typography variant={'h1'} component={'h1'}>{'Welcome to Flixoo Live '}</Typography>
                                                    <Typography variant={'h6'} component={'h6'}>{items.attributes.title}</Typography>
                                                    <Typography component={'p'}>{items.attributes.description}</Typography>
                                                    <Button
                                                        component={Link}
                                                        to={'/GetStartedSignup'}
                                                        variant="contained"
                                                    >
                                                        {'get started'}
                                                    </Button>
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid className={'bannerFlexColumn'} item lg={7}>
                                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                                style={{ position: 'relative', marginTop: '25px' }}>
                                                <Box className={classes.verctorBlack}>
                                                    <img src={verctorBlack} alt="" />
                                                    <Box className={"vectorTransparent"}>
                                                        <img src={vectorTransparent} alt="" />
                                                    </Box>
                                                </Box>
                                                <Box
                                                    className={classes.frameBannerWrapper}
                                                    sx={{ display: 'flex', gridGap: '15px' }}>
                                                    <Box className={"frameBanner1 frameBannerColumn"}>
                                                        <img src={items.attributes.images} alt="" />
                                                        <Box className={"bannerImg1"}>
                                                            <img src={bannerIcon3} alt="" />
                                                        </Box>
                                                    </Box>
                                                    <Box className={"frameBanner2 frameBannerColumn"}>
                                                        <img src={items.attributes.images} alt="" />
                                                        <Box className={"bannerImg2"}>
                                                            <img src={bannerIcon2} alt="" />
                                                        </Box>
                                                        <Box className={"bannerImg3"}>
                                                            <img src={bannerIcon1} alt="" />
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            ))}
                        </Box>
                    </Container>
                </section>
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const styles = (theme: any) => ({
    sectionBanner: {
        marginTop: '98px',
        background: "linear-gradient(to bottom, rgba(245, 234, 22, 1), rgba(255, 243, 16, 0.5))",
        minHeight: '700px',
        height: '740px', 
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('1441')]: {
            marginTop: '70px',
            marginBottom: '160px',
            height: '780px',
        },
        [theme.breakpoints.down('md')]: {
            height: 'auto',
            marginBottom: '0px',
            paddingTop: '120px'
        },
        [theme.breakpoints.down('sm')]: {
            paddingTop: '0px'
        },
        '& img': {
            maxWidth: '100%',
            objectFit: 'cover',
        },
        '& .bannerWrapper': {
            maxWidth: '95%',
            margin: '0 auto',
            '& .bannerLeftInner': {
                [theme.breakpoints.down('md')]: {
                    maxWidth: '300px !important'
                },
                [theme.breakpoints.down('sm')]: {
                    maxWidth: '100% !important'
                },
                '& h1': {
                    maxWidth: '80%',
                    fontFamily: 'Poppins-Medium',
                    fontSize: '64px',
                    lineHeight: '70px',
                },
                '& h6': {
                    fontFamily: 'Poppins-Medium',
                    fontSize: '24px',
                    lineHeight: '36px',
                },
                '& p': {
                    fontSize: '18px',
                    lineHeight: '27px',
                    maxWidth: '393px',
                    fontFamily: 'Poppins-Regular',
                },
                '& a': {
                    fontSize: '14px',
                    lineHeight: '16px',
                    fontWeight: '0.3px',
                    width: '169px',
                    maxWidth: '169px',
                    height: '32px',
                    marginTop: '50px',
                    backgroundColor: '#000000',
                    color: '#ffffff',
                    textTransform: 'capitalize',
                    borderRadius: '4px',
                },
            },
        },
    },
    bannerFlexWrapper: {
        [theme.breakpoints.down('md')]: {
            gridGap: '20px',
        },
        "& .bannerFlexColumn": {
            [theme.breakpoints.down('md')]: {
                padding: '50px 0px 160px 0px'
            },
        },
    },
    verctorBlack: {
        position: 'relative' as const,
        [theme.breakpoints.down('1280')]: {
            marginRight: '-34%'
        },
        '& img': {
            [theme.breakpoints.down('768')]: {
                maxWidth: '65%',
            },
            [theme.breakpoints.down('xs')]: {
                maxWidth: '57%'
            },
        },
        '& .vectorTransparent': {
            position: 'absolute',
            top: '-58px',
            left: '25%',
            [theme.breakpoints.down('xs')]: {
                top: '-50px',
                left: '14%',
            },
        },
    },
    frameBannerWrapper: {
        position: 'absolute' as const,
        top: '-4%',
        left: 'initial',
        [theme.breakpoints.down('1280')]: {
            left: '0%',
        },
        '& img': {
            [theme.breakpoints.down('md')]: {
                maxWidth: 'initial',
            },
            [theme.breakpoints.down('768')]: {
                maxWidth: '100%',
            },
        },
        '& .frameBannerColumn:last-child': {
            marginTop: '56px'
        },
        '& .frameBanner1': {
            position: 'relative',
            '& .bannerImg1': {
                position: 'absolute' as const,
                top: '10%',
                left: '-30%',
                zIndex: '9',
                [theme.breakpoints.down('md')]: {
                    top: '-38%',
                    left: '30%',
                    '& img': {
                        maxWidth: '75px',
                    },
                },
                '& img': {
                    maxWidth: '75px',
                },
            },
        },
        '& .frameBanner2': {
            position: 'relative',
            '& .bannerImg2': {
                position: 'absolute' as const,
                top: '-35%',
                right: '0%',
                zIndex: '9',
                [theme.breakpoints.down('md')]: {
                    top: '-34%',
                    right: '0%',
                },
                [theme.breakpoints.down('768')]: {
                    top: '-50%',
                },
            },
            '& .bannerImg3': {
                position: 'absolute' as const,
                bottom: '-19%',
                right: '-51%',
                zIndex: '9',
                [theme.breakpoints.down('1441')]: {
                    bottom: '-42%',
                    right: '-42%',
                },
                [theme.breakpoints.down('1366')]: {
                    bottom: '-42%',
                    left: '-33%',
                },
                [theme.breakpoints.down('1280')]: {
                    bottom: '-28%',
                    left: '100%',
                    '& img': {
                        maxWidth: '75px',
                    },
                },
                [theme.breakpoints.down('sm')]: {
                    bottom: '-25%',
                    left: '-70%',
                    '& img': {
                        maxWidth: '75px',
                    },
                },
            },
        },
    },
});

export default withStyles(styles)(LandingPageBanner);
export { LandingPageBanner }
// Customizable Area End
