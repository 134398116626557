import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";

import React, { createRef } from "react";
import { RouteComponentProps } from "react-router-dom";
import SendbirdChat, { FileCompat, SendbirdChatWith } from "@sendbird/chat";
import { GroupChannel, GroupChannelModule, GroupChannelFilter, GroupChannelListOrder } from "@sendbird/chat/groupChannel";
export const SENDBIRD_INFO = { appId: '0D3940BD-1C3C-4E1F-8F3E-85F71F5B2372' };
import SwiperCore, { Navigation } from 'swiper';
import { sendStoryImage } from "../../../components/src/SendMsgOnStory.web";
import moment from "moment";
import { FileMessageCreateParams, UserMessageCreateParams } from "@sendbird/chat/message";
export interface Category {
  type: string;
  id: number;
  attributes: {
    name: string;
    id: number;
    light_icon: string
    slug: string;
    updated_at: string;
    selected: boolean
    created_at: string;
  }
}

interface CommentData {
  channel_url: string
}

export type TopSelling = Array<{
  catalogue: {
      id: number;
      title: string;
      discount: string;
      selling_price: number;
      mrp: number;
      catalogue_image: string;
      catalogue_variant_ids: number[];
      on_order: boolean;
      bargain: boolean;
  };
  store: {
      id: number;
      name: string;
      business_name: string;
      email: string;
      address: {
          id: number;
          address_line_1: string;
          address_line_2: string;
          pin_code: number;
          city: string;
          state: string;
          country: string;
          latitude: number;
          longitude: number | null;
          account_id: number;
          created_at: string;
          updated_at: string;
          distance: number | null;
      };
      profile_photo: string;
      background_image: string;
  };
}>;

export interface Address {
  id: number;
  address_line_1: string;
  address_line_2: string;
  pin_code: number;
  city: string;
  state: string;
  country: string;
  latitude: number;
  longitude: number | null;
  account_id: number;
  created_at: string;
  updated_at: string;
  distance: number | null;
}

export interface Account {
  id: number;
  name: string;
  business_name: string;
  phone_number: string;
  email: string;
  address: Address | null;
  profile_photo: string | null;
  background_image: string | null;
}

export interface CatalogueNew {
  id: number;
  title: string;
  discount: string;
  selling_price: number;
  mrp: number;
  catalogue_image: string;
  catalogue_variant_ids: number[];
  on_order: boolean;
  bargain: boolean;
}

export interface RecommendedProduct {
  account: Account;
  catalogues: CatalogueNew[];
}

// If you want to store an array of `RecommendedProduct`
export type RecommendedProductsArray = RecommendedProduct[];




 export const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  responsive: [
    {
      breakpoint: 1024, // Tablet
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
      },
    },
  ],
};

export interface ValidResponseType {
    id: string
    data: [];
    message: ""
  };

export interface Item {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
    slug: string;
    light_icon: string;
    created_at: string;
    updated_at: string;
    selected: boolean;
  };
}


interface Banner {
  id: string;
  type: string;
  attributes: {
    name: string;
    status: string;
    platform: string;
    redirection_url: string;
    duration: string;
    start_at: string;
    expire_at: string;
    banner: string
  }
}

export interface OnOrderData {
  title: string;
  description: string;
  sellerId: number;
  accountFullName: string;
  hostImage: string;
  productImageUrl: string;
  productId: string,
}

interface AccountPayLoad {
  id: number;
  full_name: string;
}

interface ProductImage {
  url: string;
  id: number,
}

interface CatalogueAttributes {
  title: string;
  description: string;
  account: AccountPayLoad;
  host_image?: string;
  product_images: ProductImage[];
}

interface Catalogue {
  id: string;
  type: string;
  attributes: CatalogueAttributes;
}

interface ProductIds {
  product_id: number,
  favourite_id: number
}

interface WishListCatalogue {
  product_ids : ProductIds[]
}


interface OnOrderApiResponse {
  catalogues: {
    data: Catalogue[];
  };
  wishlist_items : WishListCatalogue
}

interface ApiPayloadType{
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  type?: string;
}

interface CreateDataPayload{
    attributes: {
      id: string
    }
}
interface CreateOnOrderApiRes{
  channel_url: string;
  data: CreateDataPayload;
}

export interface StoryUploaders {
  id: string;
  type: string;
  attributes: StoryAttributes;
}

interface StoryAttributes {
  id: number;
    title: string;
    description: string;
    duration: number; 
    seen: boolean;
    location: string | null; 
    color: string | null; 
    activated: boolean;
    created_at: string; 
    updated_at: string; 
    creator_details: CreatorDetails;
    media: StoryMedia;
    duration_with_seconds: string;
    liked: boolean;
}

interface CreatorDetails {
  id: number;
  full_name: string;
  profile_url: string | null;
}

interface StoryMedia {
    id: number;
    filename: string;
    url: string;
    type: string;
}

interface AllStoryResponse {
  data: Array<StoryUploaders>
}

interface CityCollections {
    popular_cities: string[];
    other_cities: string[];
  }

interface LikeResponse {
  message: string;
  data: {
    id: number;
    type: string;
    attributes: {
      likeable_id: number;
      likeable_type: string;
      like_by_id: number;
      created_at: string;
      updated_at: string;
    }
  };
}
SwiperCore.use([Navigation]);
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends RouteComponentProps {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  isOpen?: boolean;
  history: any;
  closeModal?: () => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  isVisibleModal: boolean;
  openItemIndex: number | null;
  displayPopup: boolean;
  showSidebar: boolean;
  scrollPosition: number;
  isChecked: boolean;
  currentID: string | null;
  chooseCategoriesOpenClose: boolean
  getSubCategoriesdata: any
  subCateId: any;
  showLoader: boolean;
  searchValue: any;
  topSellingData: TopSelling;
  bargainData: any;
  recommendData: RecommendedProductsArray;
  liveStreamData: any;
  getLiveEventsSearchData: any;
  getShowsSearchedData: any;
  showAllLiveEvents: boolean;
  showAllTopSelling: boolean;
  showingAll: boolean;
  message: string;
  messageModel: boolean;
  subCategoryIDs: any;
  tokenPresentOrNot:string | null
  seletAllCategories:boolean;
  viewType: string;
  categoriesSidbarData: Array<{[key:string]: string}>
  categoryData: Array<Category>;
  categorySortValue: string;
  showAllOnOrders: boolean;
  isSuccessModal: boolean;
  onOrderData: any;
  selectedOnOrder: OnOrderData | null;
  wishListOnOrder: Array<ProductIds>;
  selectedFiles: File[];
  imgUploadErr: string;
  expectedDate: Date | null;
  description: string;
  quantity: number,
  descriptionErr: boolean;
  channelUrl: string;
  channel:  GroupChannel
  hasUserInteracted: boolean
  instaModal: boolean
  instaModalIndex: number
  allStoryHeader: Array<StoryUploaders>;
  userStory : Array<StoryUploaders>;
  goToSlide: string;
  storyMuted: boolean;
  storyPaused: boolean;
  currentStoryIndex: number;
  currentSlide: number;
  bannerData: Array<Banner>;
  categorySlice: {[key:string]: number};
  loader: boolean;
  citiesModal: boolean;
  messageModal: boolean;
  currentLocation: string;
  citiesData: CityCollections;
  searchKeyword: string;
  latitude:number | null;
  messageText: string;
  commentStoryId: StoryAttributes;
  longitude: number | null;
  isRequestModal: boolean;
  prodOnOrder: OnOrderData[];
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];

    this.state = {
      loading: false,
      isVisibleModal: false,
      openItemIndex: null,
      displayPopup: false,
      showSidebar: false,
      scrollPosition: 0,
      isChecked: false,
      currentID: '',
      chooseCategoriesOpenClose: false,
      getSubCategoriesdata: [],
      subCateId: '',
      showLoader: false,
      searchValue: '',
      topSellingData: [],
      bargainData: [],
      recommendData: [],
      liveStreamData:[],
      getLiveEventsSearchData: [],
      getShowsSearchedData: [],
      showAllLiveEvents: false,
      showAllTopSelling: false,
      showingAll: false,
      message: '',
      messageModel: false,
      subCategoryIDs: [],
      tokenPresentOrNot:null,
      seletAllCategories:false,
      viewType: "",
      categoriesSidbarData :[
        {
          name: "newest"
        },
        {
          name: "most popular"
        },
        {
          name: "most products"
        },
        {
          name: "alphabetical (A to Z)"
        },
        {
          name: "Alphabetical (Z to A)"
        },
      ],
      categoryData: [],
      categorySortValue: "newest",
      showAllOnOrders: false,
      isRequestModal: false,
      isSuccessModal: false,
      onOrderData: [],
      selectedOnOrder: null,
      wishListOnOrder: [],
      selectedFiles: [],
      imgUploadErr: "",
      expectedDate: new Date(),
      description: "",
      quantity: 1,
      descriptionErr: false,
      channelUrl: "",
      hasUserInteracted: false,
      instaModal: false,
      allStoryHeader: [],
      userStory : [],
      goToSlide: "",
      storyMuted: false,
      storyPaused: false,
      currentStoryIndex: 0,
      currentSlide: 0,
      instaModalIndex: 0,
      bannerData: [],
       categorySlice:{
          startIdx: 0,
          endIdx: 10
        },
        loader: true,
        citiesModal: false,
        messageModal: false,
        currentLocation: configJSON.selectLocation,
        citiesData:{
            popular_cities: [],
            other_cities: []
          },
          searchKeyword: "",
          latitude:  null,
          longitude: null,
          messageText: "",
        commentStoryId: {} as StoryAttributes,
        channel: {} as GroupChannel,
          prodOnOrder: [],
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (this.isApiSuccess(responseJson)) {
      this.apiSuccess(apiRequestCallId, responseJson);
      this.apiSuccessBanner(apiRequestCallId, responseJson);
    }

    if (this.isValidResponse(responseJson)) {
        this.apiSuccessCallBacks(apiRequestCallId, responseJson);
      }

    if (apiRequestCallId===this.allUserStoriesCallId){
        this.allUserStoriesSuccessCallBack(responseJson)
    };
    if (apiRequestCallId=== this.viewStoryCallId){
      this.viewStorySuccessCallBack(responseJson)
    };
    if (apiRequestCallId=== this.likeStoryCallId){
      this.likeStorySuccessCallBack(responseJson)
    };

    if (apiRequestCallId=== this.getCitiesId){
        this.cityDataSuccessCallBack(responseJson)
      };

      if (apiRequestCallId=== this.getLatLongId){
        this.latlongResponseSuccessCallBack(responseJson)
      };

      this.handleCityResponse(from, message)
      this.handleCitiesResponse(from, message)
      this.handlegetLatLongResponse(from, message)
    // Customizable Area End
  }

  // Customizable Area Start
  getCategoryDataApiID: string = "";
  getStoresApiID: string = "";
  getTopSellingApiID: string = "";
  getBargainApiID: string = "";
  getRecommendApiID: string = "";
  getLiveStreamApiID: string = "";
  getOnOrderDataApiID: string = "";
  postOnOrderDataApiId: string = "";
  allUserStoriesCallId: string = "";
  viewStoryCallId: string = "";
  likeStoryCallId:string = "";
  getBannersApiID: string = "";
  getLocationCityId: string = "";
  getChooseCategoryApiCallId: string = "";
  getTopOrderApiCallId: string = ""
  getCitiesId: string = "";
  getLatLongId: string = "";
  fileInputRef: React.RefObject<HTMLInputElement> = createRef();
  dateInputRef: React.RefObject<HTMLInputElement> = createRef();
  sendBird: SendbirdChatWith<GroupChannelModule[]> | null = null;
  swiperInstance: SwiperCore | null = null;
  postCommentOnStoryCallId: string = "";
  async componentDidMount() {
    setTimeout(() => {
      this.setState({
          loader: false
      })
    }, 3000); 
    const tokenPresentOrNot =  await getStorageData('buerLoginToken')
    this.setState({tokenPresentOrNot:tokenPresentOrNot})
    this.getCategories();
    this.getOnOrderData();
    this.getCitiesData()
    this.getTopSellingData()
    this.getBargainData()
    this.getRecommendData()
    this.getLiveStreamData();
    this.fetchAllUserStories();
    this.getBanners()
    this.getChooseCategoryManually()
    this.getTopOrderData()
    if(tokenPresentOrNot !== null){
      this.initializeSendBirdSDK();
    }
    const currentCity = await getStorageData("userCity")
    if(currentCity){
      this.setState({currentLocation: currentCity})
      this.selectedCity(currentCity)
    }else{
      this.selectedCity(configJSON.defaultAddress.name)
    }
    const headingElement = document.getElementById("myref")
    if(headingElement) headingElement.scrollIntoView({behavior: 'smooth'})

  }

  isValidResponse = (responseJson: ValidResponseType) => {
    return responseJson;
  };

  apiSuccessCallBacks = (apiRequestCallId: string, responseJson: any) => {

    if (this.getRecommendApiID === apiRequestCallId) {
        this.setState({
            recommendData:responseJson.recommended_products
        })
       }
       
    if (this.getTopSellingApiID === apiRequestCallId) {
     this.setState({
        topSellingData: responseJson.top_selling
     })
   }
  if (this.getOnOrderDataApiID === apiRequestCallId) {
    this.onOrderApiSuccessCallBack(responseJson);
   }
  if (this.postCommentOnStoryCallId === apiRequestCallId) {
    this.sendCommentSuccessCallBack(responseJson);
   }

   if(this.postOnOrderDataApiId){
    this.createOnOrderSuccessCallBack(responseJson)
   }

   if (this.getBargainApiID === apiRequestCallId) {
    this.setState({
        bargainData:responseJson.data
    })
   }

   if (this.getLiveStreamApiID === apiRequestCallId) {
    this.setState({
     liveStreamData:responseJson.live_streams.data
    })
   }
   
   }


  initializeSendBirdSDK = async () => {
    const buyerData = await getStorageData("Buyer_Data");
    const buyerObj = JSON.parse(buyerData);
    let buyerId = await buyerObj?.sendbird_credential?.user_id;
    let buyerToken = await buyerObj?.sendbird_credential?.access_token;
    const sendbirdChat = SendbirdChat.init({
      appId: SENDBIRD_INFO.appId,
      localCacheEnabled: true,
      modules: [new GroupChannelModule()]
    });
    try {
      await sendbirdChat.connect(buyerId, buyerToken);
    } catch (error) {
    }
    await sendbirdChat.setChannelInvitationPreference(true);
    this.sendBird = sendbirdChat;
  };


checkTokenPresentOrNot=()=>{
  if(this.state.tokenPresentOrNot === null){
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage),"GetStartedLogin");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message)
  }
}


  closeMessageModal = () => {
    this.setState({ messageModel: !this.state.messageModel })
  }


  apiCall = async (apiReqData: ApiPayloadType) => {
    const { contentType, method, endPoint, body, type } = apiReqData;
    this.setState({ loading: true });
    const tokenPresentOrNot = this.state.tokenPresentOrNot ?? "";
    const header = {
      "Content-Type": contentType,
      token: tokenPresentOrNot,
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    body && type != 'formData' ?
      requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )
      : requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  getOnOrderData = async () => {
    this.getOnOrderDataApiID = await this.apiCallFunction({
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.getOnOrderCollectionEndPoint,
    });
  };


  getTopSellingData = async () => {
    this.getTopSellingApiID = await this.apiCallFunction({
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.getTopSellingEndPoint,
    });
  };

  getBargainData = async () => {
    this.getBargainApiID = await this.apiCallFunction({
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.getBargainDataEndPoint,
    });
  };

  getRecommendData = async () => {
    this.getRecommendApiID = await this.apiCallFunction({
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.getRecommendEndPoint,
    });
  };

  getLiveStreamData = async () => {
    this.getLiveStreamApiID = await this.apiCallFunction({
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.getLiveStreamEndPoint,
    });
  };


  onOrderApiSuccessCallBack = (responseJson : OnOrderApiResponse) => {
    let onOrderSet : OnOrderData[] = []; 
    responseJson.catalogues.data.forEach((item) => {
      onOrderSet.push({
          title: item.attributes.title,
          description: item.attributes.description,
          sellerId: item.attributes.account.id,
          accountFullName: item.attributes.account.full_name,
          hostImage: item.attributes.host_image || '',
          productImageUrl: item.attributes?.product_images ? item.attributes?.product_images[0]?.url : '',
          productId: item.id,
      })
    });
    this.setState({ onOrderData: responseJson.catalogues.data, prodOnOrder: onOrderSet });
  };


  handleGoToFeed = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "BuyerFeed");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }



  apiCallFunction = async (data: {method: string; endPoint: string;}) => {
    this.setState({
      loading: true
    })
    let { method, endPoint } = data;
    const header = { 
      "Content-Type": "application/json",
    //   token: await getStorageData('buerLoginToken')
    };
    let apiRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    apiRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    apiRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    apiRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    apiRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
    return apiRequestMessage.messageId;
  };

  getCategories = async() => {
    this.getCategoryDataApiID = await this.apiCallFunction({
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.getCategoriesEndPoint
    })
  }

  isApiSuccess = (response: {data:  Array<Category>}) => {
    return response ? true : false
  }

  apiSuccessBanner = (apiCallId: string, response:  {data: Array<Banner>}) => {
    if (apiCallId === this.getBannersApiID) {
      this.setState({
        bannerData: response.data
      })
    }
  }

  apiSuccess = (apiCallId: string, response: {data:  Array<Category>}) => {
    if (apiCallId === this.getCategoryDataApiID) {
      this.setState({
        categoryData: response.data
      })
    }
    this.setState({
      loading: false
    })
  }


  handleClickOpen = (index: number) => {
    this.viewStory(index);
    this.setState({ instaModalIndex: index, currentSlide: index })
  };

  viewStory = async(index: number) => {
    this.viewStoryCallId = await this.apiCall(
      {
        contentType: configJSON.validationApiContentType,
        method: configJSON.validationApiMethodType,
        endPoint:`${configJSON.viewStoryNewEndPoint}?user_id=${this.state.allStoryHeader[index].attributes.creator_details.id}&story_id=${this.state.allStoryHeader[index].attributes.id}`,
        type: ""
      }
    );
  };

  handleClose = () => {
    this.setState({ instaModal: false })
  };

  handlePrev = (userIndex?: number, direct?: boolean, event?: React.MouseEvent<HTMLDivElement | HTMLButtonElement, MouseEvent>) => {
    event?.stopPropagation();
    if (this.state.currentStoryIndex - 1 >= 0 && !direct) {
      this.changeStory("prev");
    } else {
      if (this.swiperInstance) {
        if(userIndex){
          this.viewStory(userIndex - 1);
          this.setState({ goToSlide: "prev", currentSlide: userIndex - 1 });
        }
      }
    }
  };

  handleNext = (userIndex?: number, direct?: boolean, event?: React.MouseEvent<HTMLDivElement | HTMLButtonElement, MouseEvent>) => {
    event?.stopPropagation();
    let { allStoryHeader } = this.state;
    if (this.state.currentStoryIndex + 1 < this.state.userStory.length && !direct) {
      this.changeStory("next");
    } else {
      if (this.swiperInstance) {
        allStoryHeader[this.state.currentSlide].attributes.seen = true;
        this.setState({
          allStoryHeader
        }, () => {
           if(userIndex){
             this.viewStory(userIndex + 1);
             this.setState({ goToSlide: "next", currentSlide: userIndex + 1 });
           }
        })
      }
    }
  };

  setSwiperRef = (swiper: SwiperCore) => {
    this.swiperInstance = swiper;
  }; event?: React.MouseEvent<HTMLDivElement | HTMLButtonElement, MouseEvent>

  handlePrevNext = (event?: React.MouseEvent<HTMLDivElement, MouseEvent>, userIndex?: number) => {
    if(event && userIndex){
      const element = event.currentTarget;
      const parent = element.parentElement
      const classNames = parent?.classList;
      if (classNames?.contains('swiper-slide-prev')) {
        this.handlePrev(userIndex + 1, true, event);
      } else if (classNames?.contains('swiper-slide-next')) {
        this.handleNext(userIndex - 1, true, event);
      }
    }
  };

  fetchAllUserStories = async() => {
    this.allUserStoriesCallId = await this.apiCallFunction({
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.getAllStoriesEndPoint,
    });
  };

  getBanners = async() => {
    this.getBannersApiID = await this.apiCallFunction({
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.getBannersEndPoint
    })
  }

  allUserStoriesSuccessCallBack = (response: AllStoryResponse) => {
    if (response.data) {
      this.setState({
        allStoryHeader: response.data
      })
    }
  };

  viewStorySuccessCallBack = (response: any) => {
    if(response.errors){
      this.checkTokenPresentOrNot()
    }else{
    let { goToSlide } = this.state;
    this.setState({ instaModal: true, userStory : response.data, currentStoryIndex: 0 }, () => {
      if (goToSlide === "next") {
        this.swiperInstance?.slideNext();
      } else if (goToSlide === "prev") {
        this.swiperInstance?.slidePrev();
      }
      this.setState({ goToSlide: "" });
    });
  }
  };

  calculateHours = (createdAt: string) => {
    const createdTime = new Date(createdAt).getTime();
    const currentTime = Date.now();
    const timeDifference = currentTime - createdTime;
    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);

    if (hours > 0) {
      return `${hours}h`;
    } else if (minutes > 0) {
      return `${minutes}m`;
    } else {
      return `${seconds}s`;
    }
  };

toggleMute = () => {
  this.setState({ storyMuted: !this.state.storyMuted });
};

likeStory = async (storyId: StoryAttributes) => {
    this.likeStoryCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.exampleAPiMethod,
      endPoint: configJSON.likeStoryEndPoint,
      body: {
        "data": {
          "attributes": {
            "likeable_id": storyId.id,
            "likeable_type": "BxBlockPosts::Story"
          }
        }
      },
      type: ""
    })
  };

  likeStorySuccessCallBack = (response: LikeResponse) => {
    let { userStory } = this.state;
    const oldStory = userStory;
    if (response.data) {
      oldStory.forEach((item, index) => {
        if (item.attributes.id === response.data.attributes.likeable_id) {
          userStory[index].attributes.liked = !item.attributes.liked;
        }
      })
    };
    if (response.message) {
      userStory[this.state.currentStoryIndex].attributes.liked = !userStory[this.state.currentStoryIndex].attributes.liked;
    }
    this.setState({ userStory });
  };

  showNextButton = (userIndex: number) => {
    const { allStoryHeader, userStory, currentStoryIndex } = this.state;
    if (userIndex + 1 < allStoryHeader.length || (userIndex + 1 === allStoryHeader.length && currentStoryIndex + 1 < userStory.length)) {
      return true;
    }
    return false
  };

  showPrevButton = (userIndex: number) => {
    const { currentStoryIndex } = this.state;
    if (userIndex > 0 || (userIndex === 0 && currentStoryIndex !== 0)) {
      return true;
    }
    return false
  };
  
  changeStory = (direction: "next" | "prev") => {
    let { currentStoryIndex, userStory, currentSlide, allStoryHeader } = this.state;

    if (userStory.length === 0) return;

    let newIndex = currentStoryIndex;

    if (direction === 'next') {
      newIndex = currentStoryIndex + 1;
    } else if (direction === 'prev') {
      newIndex = currentStoryIndex - 1;
    }
    if (newIndex < 0) {
      this.handlePrev(currentSlide, true);
      newIndex = 0;
    } else if (newIndex === userStory.length) {
      if (currentSlide + 1 < allStoryHeader.length) {
        this.handleNext(currentSlide, true);
      } else {
        allStoryHeader[currentSlide].attributes.seen = true;
        this.handleClose();
      }
      newIndex = 0;
    }

    this.setState({ currentStoryIndex: newIndex, allStoryHeader });
  };


  handleChangeLocation=()=>{
    this.setState({citiesModal:true})
  }

  handleCloseLocation=()=>{
    this.setState({citiesModal:false})
  }

cityDataSuccessCallBack=(responseJson: any)=>{
    if (responseJson) {
      this.setState({
        citiesData: responseJson
      });
    }
}

latlongResponseSuccessCallBack=(responseJson: any)=>{
    if (responseJson) {
     const latitude =responseJson[0].lat
     const longitude =  responseJson[0].lon
     this.setState({latitude:latitude,longitude:longitude},()=>{
      this.getStores(this.state.currentLocation);
     })
    }
}


searchCity=(event:React.ChangeEvent<{ value: string }>)=>{
  this.setState({searchKeyword:event.target.value},()=>{
    this.getCitiesData()
  })
}


getCitiesData=async()=>{
  const endPoint = `${configJSON.getCities}${this.state.searchKeyword}`
  const header = { "Content-Type": 'application/json' };
  const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
  this.getCitiesId = requestMessage.messageId;
  requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
  requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
  requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.validationApiMethodType);
  runEngine.sendMessage(requestMessage.id, requestMessage);
}

doGetChooseCategory(data: {
  contentType?: string;
  method?: string;
  endPoint?: string;
  type?: string;
}) {
  const { contentType, method, endPoint } = data;
  const header = {
    "Content-Type": contentType,
    token: getStorageData("token"),
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)

  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  this.getChooseCategoryApiCallId = requestMessage.messageId
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    endPoint
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    method
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return requestMessage.messageId;
}


getChooseCategoryManually() {
  this.doGetChooseCategory({
    contentType: configJSON.ApiContentType,
    method: configJSON.apiGetMethod,
    endPoint: "",
  });
}


doGetTopOrderApiCall(data: {
  contentType?: string;
  method?: string;
  endPoint?: string;
  type?: string;
}) {
  const { contentType, method, endPoint } = data;
  const header = {
    "Content-Type": contentType,
    token: getStorageData("token"),
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)

  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  this.getTopOrderApiCallId = requestMessage.messageId
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    endPoint
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    method
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return requestMessage.messageId;
}


getTopOrderData() {
  this.doGetTopOrderApiCall({
    contentType: configJSON.ApiContentType,
    method: configJSON.apiGetMethod,
    endPoint: "",
  });
}

handleCityResponse = (from: string, message: Message) => {
  if (this.getLocationCityId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
    let currentCity;
      if (responseJson) {
        if (responseJson.address.city) {
          currentCity = responseJson.address.city.replace(" District", "").replace(" City", "");
        } else if (responseJson.address.county) {
          currentCity = responseJson.address.county.replace(" District", "").replace(" City", "");
        } else {
          currentCity = responseJson.address.state_district.replace(" District", "").replace(" City", "");
        }
        this.setState({
          currentLocation: currentCity
        });
        setStorageData('userCity', currentCity);
        this.handleCloseLocation()
      }
      this.selectedCity(currentCity)
  }
};

handleCitiesResponse=(from: string, message: Message)=>{
  if (this.getCitiesId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
    const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson) {
      this.setState({
        citiesData: responseJson
      });
    }
}
};

handlegetLatLongResponse=(from: string, message: Message)=>{
  if (this.getLatLongId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
    const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson) {
     const latitude =responseJson[0].lat
     const longitude =  responseJson[0].lon
     this.setState({latitude:latitude,longitude:longitude},()=>{
      this.getStores(this.state.currentLocation);
     })
    }
}
};

selectedCity=(eachCity:string)=>{
  setStorageData('userCity', eachCity);
  this.setState({currentLocation:eachCity,loading:true})
  const endPoint = `${configJSON.getLatLong}${eachCity}&format=json&addressdetails=1&limit=1`
  const header = { "Content-Type": 'application/json' };
  const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
  this.getLatLongId = requestMessage.messageId;
  requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
  requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
  requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.validationApiMethodType);
  runEngine.sendMessage(requestMessage.id, requestMessage);

  this.handleCloseLocation();
}

getStores = async(city:string) => {
    const endpoint = `${configJSON.getStoresEndPoint}?latitude=${this.state.latitude}&longitude=${this.state.longitude}&city=${city}`
    this.getStoresApiID = await this.apiCallFunction({
      method: configJSON.validationApiMethodType,
      endPoint: endpoint
    })
  }

  loadChannels = async (channelURL: string, textMessage: string) => {
    const groupChannelFilter = new GroupChannelFilter();

    groupChannelFilter.includeEmpty = true;
    const collection = this.sendBird?.groupChannel.createGroupChannelCollection({
      filter: groupChannelFilter,
      order: GroupChannelListOrder.LATEST_LAST_MESSAGE,
    });
    const channels = await collection?.loadMore();
    if (channels && channels.length > 0) {
      let channelIndex = channels.findIndex((channel) => channel.url === channelURL);
      this.setState({ channel: channels[channelIndex] }, () => {
        sendStoryImage(textMessage, this.state.channel, this.state.commentStoryId?.media);
      });
    }
  };

  handleNavigate = (prodId: number) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "ProductDescription");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationScreenNameMessage), prodId);
    this.send(message);
  };

  handleNavigateToStore = (storeId: any) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "Store");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationScreenNameMessage), storeId);
    this.send(message);
  };

  createOnOrderSuccessCallBack = (responJson: CreateOnOrderApiRes) => {
    this.setState({ isSuccessModal: true, isRequestModal: false, channelUrl: responJson.channel_url, loading: false }, async () => {
      this.loadOnOrderChannels(responJson.channel_url || '', responJson.data.attributes.id || '');
    });
  };

  loadOnOrderChannels = async (channelURL: string, productId: string) => {
    const grpChnlFilter = new GroupChannelFilter();
    grpChnlFilter.includeEmpty = true;
    const channelCollection = this.sendBird?.groupChannel.createGroupChannelCollection({
      order: GroupChannelListOrder.LATEST_LAST_MESSAGE,
      filter: grpChnlFilter,
    });
    const allChannels = await channelCollection?.loadMore();
    if (allChannels && allChannels.length > 0) {
      let channelIndex = allChannels.findIndex((channel) => channel.url === channelURL);
      this.setState({ channel: allChannels[channelIndex] }, () => {
        this.sendMessageSendBird(productId);
      });
    }
  };


  postCommentOnStoryData = async () => {
    this.postCommentOnStoryCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.exampleAPiMethod,
      endPoint: configJSON.commentOnStory,
      body: {
        "comment": {
          "story_id": this.state.commentStoryId.id,
          "comment": this.state.messageText
        }
      },
      type: ""
    });
  };

  sendCommentSuccessCallBack = (responJson: CommentData) => {
    this.setState({ channelUrl: responJson.channel_url }, () => {
      this.loadChannels(responJson.channel_url || '', this.state.messageText)
    });
  };

  handleMessage = (value: string) => {
    this.setState({
      messageText: value
    });
  };

  handleStoryId = (storyId: StoryAttributes) => {
    this.setState({
      commentStoryId: storyId
    }, () => this.postCommentOnStoryData());
  }

  sendMessageSendBird = async (productId: string) => {
    const { channel, description, selectedOnOrder, quantity, expectedDate, selectedFiles } = this.state;
    if (!channel) return;
    try {
      if (selectedOnOrder) {
        const messageData = JSON.stringify({ productId: productId, title: selectedOnOrder.title, description: description, quantity: quantity, expectedDate: expectedDate, productUrl: selectedOnOrder.productImageUrl, onOrderStatus: "pending" })
        const param: UserMessageCreateParams = {
          message: messageData,
          data: JSON.stringify({ delivered: false, read: false }),
          customType: 'on_order',
        };
        channel?.sendUserMessage(param).onSucceeded(async (sentMessage) => {
          if (sentMessage) {
            const params = {
              customType: 'on_order',
              data: JSON.stringify({ delivered: true, read: false }),
            };
            try {
              await channel.updateUserMessage(sentMessage.messageId, params);
            } catch (error) {
            }
            sentMessage['data'] = JSON.stringify({ delivered: true, read: false });
          }
        });
        if (selectedFiles.length > 0) {
          this.handleFileSend();
        }
        this.setState({ selectedOnOrder: null, description: "", quantity: 1, expectedDate: new Date() });
      }
    } catch (error) {
    }
  };

  handleFileSend = async () => {
    const { channel, selectedFiles } = this.state;
    if (!channel) return;
    selectedFiles.forEach((files) => {
      const fileMessageParams: FileMessageCreateParams = {
        file: files as FileCompat,
        customType: "image",
        data: JSON.stringify({ delivered: false, read: false }),
      }
      channel?.sendFileMessage(fileMessageParams).onSucceeded(async (sentMessage) => {
        if (sentMessage) {
          const params = {
            customType: "image",
            data: JSON.stringify({ delivered: true, read: false }),
          };
          try {
            await channel.updateFileMessage(sentMessage.messageId, params);
          } catch (error) {
          }
          sentMessage['data'] = JSON.stringify({ delivered: true, read: false });
        }
      });
    })
    this.setState({ selectedFiles: [] });
  };

  toggleOnOrderModal = () => {
    this.setState({ isRequestModal: !this.state.isRequestModal })
  };

  truncatedTitle = (title: string) => {
    if (title.length <= 24) {
      return title;
    }
    return title.substring(0, 24) + ' ...';
  };

  truncatedDescription = (description: string) => {
    if (description.length <= 45) {
      return description;
    }
    return description.substring(0, 45) + ' ...';
  };

  handleUploadClick = () => {
    if (this.fileInputRef.current) {
      this.setState({ imgUploadErr: "" });
      this.fileInputRef.current.click();
    }
  };

  handleRemoveImage = (indexToRemove: number) => {
    const { selectedFiles } = this.state;
    const newArr = selectedFiles.filter((_, i) => i !== indexToRemove);
    this.setState({ selectedFiles: newArr, imgUploadErr: "" })
  };

  handleDateChange = (date: Date | null) => {
    this.setState({ expectedDate: date });
  };

  updateOrDeleteItem = (itemQuantity: number, isIncrement: boolean) => {
    if (isIncrement) {
      this.setState({ quantity: itemQuantity + 1 });
    } else if (!isIncrement && itemQuantity > 1) {
      this.setState({ quantity: itemQuantity - 1 });
    }
  };

  handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const validImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];
      const newImages: File[] = Array.from(files);
      const validFiles: File[] = [];
      const invalidFiles: File[] = [];
      let isSizeExceed: boolean = false;

      newImages.forEach((file) => {
        const isDuplicate = this.state.selectedFiles.some((selectedFile) => selectedFile.name === file.name && selectedFile.size === file.size);
        if (!validImageTypes.includes(file.type)) {
          invalidFiles.push(file);
        } else if (file.size > 5 * 1024 * 1024) {
          this.setState({ imgUploadErr: "Some files exceed the 5 MB size limit!" });
          isSizeExceed = true;
        } else if (!isDuplicate) {
          validFiles.push(file);
        }
      });

      if (this.state.selectedFiles.length + validFiles.length > 4) {
        this.setState({ imgUploadErr: "You can upload a maximum of 4 images only" });
        return;
      }

      if (invalidFiles.length > 0) {
        this.setState({ imgUploadErr: "Only PNG, JPEG, or JPG files are allowed." });
      }

      if (!isSizeExceed && validFiles.length > 0) {
        this.setState((prevState) => ({
          selectedFiles: [...prevState.selectedFiles, ...validFiles],
        }));
      }
    }
  };

  handleOnClickSubmit = async () => {
    const { description } = this.state;
    if (description.trim() === "") {
      this.setState({ descriptionErr: true });
      return;
    }
    await this.postOnOrderData();
  };

  postOnOrderData = async () => {
    const { description, quantity, expectedDate, selectedFiles, selectedOnOrder } = this.state;
    this.setState({ isRequestModal: false });
    const formdata = new FormData();
    formdata.append("catalogue_id", String(selectedOnOrder?.productId));
    formdata.append("quantity", String(quantity));
    formdata.append("request_detail", description);
    formdata.append("expected_delivery", moment(expectedDate).format("DD/MM/YYYY"));

    selectedFiles.length && selectedFiles.forEach((file) => {
      formdata.append("attachments[]", file, file.name);
    });

    this.postOnOrderDataApiId = await this.apiCall({
      method: configJSON.exampleAPiMethod,
      endPoint: configJSON.createOnOrderEndPoint,
      body: formdata,
      type: "formData"
    });
  };

  toogleSuccessModal = () => {
    this.setState({ isSuccessModal: !this.state.isSuccessModal });
  };

  handleNavigateToChat = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "ChatScreen");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  handleDescriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ description: event.target.value, descriptionErr: false })
  };

  handleOnOrderRequestModal = (prodID: string) =>{
    this.checkTokenPresentOrNot();
    const selectedData = this.state.prodOnOrder.filter((item) => item.productId === prodID);
    this.setState({isRequestModal: !this.state.isRequestModal,selectedFiles: [], description: "", descriptionErr: false, imgUploadErr: "", selectedOnOrder: selectedData[0]});
  };

  handleTopSellingOnOrderReq = (prodID: string) => {
    this.checkTokenPresentOrNot();
    const selectedProd = this.state.topSellingData.filter((item) => item.catalogue.id === Number(prodID));
    let productSet: OnOrderData[] = [];
    selectedProd.forEach((item) => {
      productSet.push({
        title: item.catalogue.title,
        description: item.catalogue.title,
        sellerId: item.store.id,
        accountFullName: item.store.name,
        hostImage: item.store.profile_photo || '',
        productImageUrl: item.catalogue?.catalogue_image ? item.catalogue?.catalogue_image : '',
        productId: String(item.catalogue.id),
      })
    });
    this.setState({ isRequestModal: !this.state.isRequestModal, selectedFiles: [], description: "", descriptionErr: false, imgUploadErr: "", selectedOnOrder: productSet[0] });
  };
  // Customizable Area End
}
