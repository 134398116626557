import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
// Customizable Area Start
interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object | string;
}

interface PackageDetailsValidResponse {
  data: object;
  packaging_details: object;
  packaging_detail: object;
}

interface PackageDetailsValidResponseMessage {
  message: string;
}

export interface PackageDetailsErrorResponse {
  errors: [
    {
      token: string;
      Tasklist: string;
    }
  ]
}

export interface GetPackageDetailsResponse {
  packaging_details: {
    id: number,
    package_length: string,
    package_weight: string,
    package_breadh: string,
    package_height: string,
    catalogue_id: number,
    account_id: number,
    hsn: string,
    tax_code: string,
    created_at: string,
    updated_at: string
  }
}

export interface PackageDetailsResponse {
  catalogue_id: number,
  packaging_detail: {
    package_length: number,
    package_weight: number,
    package_breadh: number,
    package_height: number,
    hsn: string,
    tax_code: string
  }
}

export interface PutPackageDetailsResponse{
    inventory: {
        catalogue_id: number
        id: number
        package_length: number,
        package_weight: number,
        package_breadh: number,
        package_height: number,
        hsn: string,
        tax_code: string,
        account_id: number,
        created_at: string,
        updated_at: string
    }
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  classes: Record<string, string>;
  handleActiveStep: (step: number) => void;
  // Customizable Area End
}
export interface S {
  // Customizable Area Start
  packageWeight: string;
  packageLenght: string;
  packageBreadth: string;
  packageHeight: string;
  packageHSN: string;
  packageTax: string;
  packageWeightRequire: boolean;
  packageLenghtRequire: boolean;
  packageBreadthRequire: boolean;
  packageHeightRequire: boolean;
  packageHSNRequire: boolean;
  packageTaxRequire: boolean;
  postApiHit: boolean;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: number;
  // Customizable Area End
}


export default class PackagingDetailsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  postPackageDetailsApiCallId: string = "";
  getPackageDetailsApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      // Customizable Area End
    ];

    // Customizable Area Start
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    // Customizable Area End

    this.state = {
      // Customizable Area Start
      packageWeight: "",
      packageLenght: "",
      packageBreadth: "",
      packageHeight: "",
      packageHSN: "",
      packageTax: "",
      packageWeightRequire: false,
      packageLenghtRequire: false,
      packageBreadthRequire: false,
      packageHeightRequire: false,
      packageHSNRequire: false,
      packageTaxRequire: false,
      postApiHit: true,
      // Customizable Area End
    };
  }


  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (this.isPackageDetailsValidResponse(responseJson)) {
        this.responseSuccessCall(apiRequestCallId, responseJson);
      } else if (this.isPackageDetailsValidResponseMessage(responseJson)) {
        this.responseSuccessCall(apiRequestCallId, responseJson);
      } else if (this.isPackageDetailsVal(responseJson)) {
        this.responseSuccessCall(apiRequestCallId, responseJson);
      } else if (this.isPackageDetailsInValidResponse(responseJson)) {
        this.responseFailureCall(apiRequestCallId, responseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    await setStorageData('currentStep', 3);
    this.getPackageDetailsResponse();
  }

  handlePackageWeight = (event: React.ChangeEvent<HTMLInputElement>) => {
    const regex = /^(?:\d+\.\d+|\d+\.|\.\d+|\d+)$/;
    if (event.target.value === '' || regex.test(event.target.value)) {
      this.setState({
        packageWeight: event.target.value,
        packageWeightRequire: false
      });
    }
  };

  handlePackageLenght = (event: React.ChangeEvent<HTMLInputElement>) => {
    const regex = /^(?:\d+\.\d+|\d+\.|\.\d+|\d+)$/;
    if (event.target.value === '' || regex.test(event.target.value)) {
      this.setState({
        packageLenght: event.target.value,
        packageLenghtRequire: false
      });
    }
  };

  handlePackageBreadth = (event: React.ChangeEvent<HTMLInputElement>) => {
    const regex = /^(?:\d+\.\d+|\d+\.|\.\d+|\d+)$/;
    if (event.target.value === '' || regex.test(event.target.value)) {
      this.setState({
        packageBreadth: event.target.value,
        packageBreadthRequire: false
      });
    }
  };

  handlePackageHeight = (event: React.ChangeEvent<HTMLInputElement>) => {
    const regex = /^(?:\d+\.\d+|\d+\.|\.\d+|\d+)$/;
    if (event.target.value === '' || regex.test(event.target.value)) {
      this.setState({
        packageHeight: event.target.value,
        packageHeightRequire: false
      });
    }
  };

  handlePackageHSN = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      packageHSN: event.target.value,
      packageHSNRequire: false
    });
  };

  handlePackageTax = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      packageTax: event.target.value,
      packageTaxRequire: false
    });
  };

  handlePackageDetailsSubmit = (preventEvent: { preventDefault: () => void }) => {
    preventEvent.preventDefault()

    if (this.state.packageWeight.length === 0) {
      this.setState({
        packageWeightRequire: true,
      });
    }
    if (this.state.packageLenght.length === 0) {
      this.setState({
        packageLenghtRequire: true,
      });
    }
    if (this.state.packageBreadth.length === 0) {
      this.setState({
        packageBreadthRequire: true,
      });
    }
    if (this.state.packageHeight.length === 0) {
      this.setState({
        packageHeightRequire: true,
      });
    }
    if (this.state.packageHSN.length === 0) {
      this.setState({
        packageHSNRequire: true,
      });
    }
    if (this.state.packageTax.length === 0) {
      this.setState({
        packageTaxRequire: true,
      });
    }
    if (this.state.packageWeight.length !== 0 && this.state.packageLenght.length !== 0 && this.state.packageBreadth.length !== 0 && this.state.packageHeight.length !== 0 && this.state.packageHSN.length !== 0 && this.state.packageTax.length !== 0) {
      this.getAllTaskListDataShow();
    }
  };

  packageDetailsApiCall = (data: APIPayloadType) => {
    const token = localStorage.getItem("singupLogin");
    let { contentType, method, endPoint, body } = data;
    const header = {
      "Content-Type": contentType,
      token : token ? token : ""
    };
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(

        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  isPackageDetailsValidResponse = (responseJson: PackageDetailsValidResponse ) => {
    return responseJson && (responseJson.data || responseJson.packaging_details || responseJson.packaging_detail);
  };
  isPackageDetailsVal = (responseJson: PutPackageDetailsResponse ) => {
    return responseJson && (responseJson.inventory);
  };
  isPackageDetailsValidResponseMessage = (responseJson: PackageDetailsValidResponseMessage) => {
    return responseJson && responseJson.message;
  };
  isPackageDetailsInValidResponse = (responseJson: PackageDetailsErrorResponse) => {
    return responseJson && responseJson.errors;
  };

  getAllTaskListDataShow = async () => {
    const catalogueID = await getStorageData("catalogueID");
    let raw = JSON.stringify({
      "catalogue_id": catalogueID,
      "packaging_detail": {
        "package_length": Number(this.state.packageLenght),
        "package_weight": Number(this.state.packageWeight),
        "package_breadh": Number(this.state.packageBreadth),
        "package_height": Number(this.state.packageHeight),
        "hsn": this.state.packageHSN,
        "tax_code": this.state.packageTax
      }
    });
    this.postPackageDetailsApiCallId = this.packageDetailsApiCall({
      contentType: configJSON.validationApiContentType,
      method: this.state.postApiHit ? configJSON.exampleAPiMethod : configJSON.exampleAPiMethodPut,
      endPoint: configJSON.pakageDetailsApiendPoint,
      body: raw
    });
  };

  getPackageDetailsResponse = async () => {
    const catalogueID = await getStorageData("catalogueID");
    this.getPackageDetailsApiCallId = this.packageDetailsApiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.getPakageDetailsApiendPoint+catalogueID}`
    });
  };

  responseSuccessCall = async (apiRequestCallId: string, responseJson: PackageDetailsResponse & GetPackageDetailsResponse) => {
    if (apiRequestCallId === this.postPackageDetailsApiCallId) {
      this.postInventorySuccessCall();
    }
    if (apiRequestCallId === this.getPackageDetailsApiCallId) {
      this.getPackageDetailsSuccessCall(responseJson);
    }
  };

  responseFailureCall = async (apiRequestCallId: string, responseJson: PackageDetailsResponse) => {
    if (apiRequestCallId === this.postPackageDetailsApiCallId) {
      this.postInventoryFailureCall();
    }
  };

  postInventorySuccessCall = () => {

    this.props.handleActiveStep(4)
  };

  postInventoryFailureCall = () => {
  };

  getPackageDetailsSuccessCall = (responseJson: GetPackageDetailsResponse) => {
      this.setState({
        packageWeight: responseJson.packaging_details.package_weight,
        packageLenght: responseJson.packaging_details.package_length,
        packageBreadth: responseJson.packaging_details.package_breadh,
        packageHeight: responseJson.packaging_details.package_height,
        packageHSN: responseJson.packaging_details.hsn,
        packageTax: responseJson.packaging_details.tax_code,
        postApiHit: false
      })
    
  };
  // Customizable Area End
}