Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "cfmultiplecarts1";
exports.labelBodyText = "cfmultiplecarts1 Body";

exports.getApiMethod = 'GET'
exports.postApiMethod = 'POST'
exports.deleteApiMethod = 'DELETE'
exports.putApiMethod = 'PUT'
exports.getCartDataEndPoint = "bx_block_order_management/cart_items";
exports.putCartUpdateEndPoint = "bx_block_order_management/update_cart_item";
exports.deleteCartDataEndPoint = "bx_block_order_management/remove_item_from_cart";
exports.deleteStoreEndPoint = "bx_block_address/store_addresses/";
exports.clearCartEndPoint = "bx_block_order_management/clear_cart";

exports.btnExampleTitle = "CLICK ME";

exports.cartTxt = "Your Cart",
exports.clearAllTxt = "Clear All"
// Customizable Area End