import React from "react";
// Customizable Area Start
import ProductDetailsController,{Props,configJSON} from "./ProductDetailsController.web";
import { createTheme, withStyles, Theme } from "@material-ui/core/styles";
import { Box ,Grid ,IconButton, Typography,Button} from "@material-ui/core";
import Carousel from "../../../components/src/CustomCarousel.web";
import SellerSidebar from "../../landingpage/src/SellerSidebar.web";
import {hamburgerIc,editIcon,backButton} from "./assets";
import Loader from "../../../components/src/Loader.web";

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
});

export const webStyles = (theme: Theme) => ({
    productDetailsMainContainer:{
        marginTop:"5em",
        display:"flex",
    },
    productDetailsInnerContainer:{
        paddingTop:"4%",
        width:"100%",
        paddingLeft:"2em",
    },
    [`@media (min-width: 992px)`]: {
        hideHamburgerMenu: {
          display: 'none'
        },
        },
        hideHamburgerMenuSection:{
            display:"flex",
            justifyContent:"end"
        },
        productDetailTitle:{
            color: '#444',
            fontFeatureSettings: "'clig' off, 'liga' off",
            fontFamily: 'Poppins',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: 'normal'
        },
        productDescription:{
            color: 'rgba(68, 68, 68, 0.70)',
            fontFeatureSettings: "'clig' off, 'liga' off",
            fontFamily: 'Poppins',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: 'normal'
        },
        productDetailsText:{
            color: 'rgba(68, 68, 68, 0.70)',
            fontFeatureSettings: "'clig' off, 'liga' off",
            fontFamily: 'Poppins',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: 'normal'
        },
        verticalDivider:{
            width:"1px",
            height: "34px",
            background: "rgba(68, 68, 68, 0.20)",
            color:"red",
            padding:"0.2px",
            margin:"0em 3em"
        },
        horizontalDivider:{
            width: "476px",
            height: "1px",
            padding:"0.2px",
            background: "rgba(68, 68, 68, 0.20)"
        },
        horizontalDivider1:{
            width: "650px",
            height: "1px",
            padding:"0.2px",
            background: "rgba(68, 68, 68, 0.20)"
        },
        manufacturingDetailSection:{
            margin:"2em 0em"
        },
        customCarousel: {
            margin: 0,
            padding: 0,
        },
        carouselContainer:{
            height:"365px", 
            marginRight:"5em"
        },
        productDescriptionContainer:{
            marginTop:"2em"
        },
        productDescriptionEachSection:{
            display:"flex", 
            margin:"2em 0em",
            [theme.breakpoints.down('sm')]: {
            flexDirection: 'column', 
            },
        },
        colorTitle:{
            color: '#444',
            fontFeatureSettings: "'clig' off, 'liga' off",
            fontFamily: 'Poppins',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: 'normal'
        },
        activeVariant:{
            display: "inline-block", 
            margin: "0.5em",
            cursor:'pointer',
            width: "50px",
            height: "50px", 
            borderRadius: "50%",   
            border:"2px solid #F5EA16",        
        },
        inActiveVariant:{
            display: "inline-block", 
            margin: "0.5em",
            cursor:'pointer',
            width: "40px",
            height: "40px", 
            borderRadius: "50%",  
            border:"1.27px solid black",
            padding:"5px"  
        },
        editProductButton:{
            width: '60%',
            height: '3.2em',
            background: '#F5EA16',
            borderRadius:"25px",
            color: '#444',
            fontFeatureSettings: "'clig' off, 'liga' off",
            fontFamily: 'Poppins',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: 'normal',
            pointer:"cursor",
            textTransform:"none" as const,
            marginTop:"2em",
            "&:hover":{
                background: '#F5EA16',
            },
            [theme.breakpoints.down('sm')]: {
                padding: '1rem 6rem', 
            },
        },
        backBtnNavigation:{
            marginBottom:"2em"
        },
        upNav:{
            display:"flex",
            alignItems:"center"
        },
        upNavsku:{
            fontFamily: 'Poppins',
            fontSize:"14px",
            fontWeight:400,
            lineHeight:"21px",
            marginLeft:"5px",
            color:"#444444"
        },

        upNavtitle:{
            fontFamily: 'Poppins',
            fontSize:"20px",
            fontWeight:500,
            lineHeight:"30px",
            marginLeft:"5px"
        },

});
// Customizable Area End

export class ProductDetails extends ProductDetailsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
     isObjectEmpty=(obj: {})=>{
        return Object.keys(obj).length === 0;
      }

    // Customizable Area End
    render() {
        // Customizable Area Start
        
        const {eachProductData,isSideBarOpen,selectedVariantObject,loading} =  this.state
        console.log("prod",eachProductData);
        
        const {classes} = this.props
        const productImages = this.state.selectedVariantImages.length > 0 ? this.state.selectedVariantImages : eachProductData?.attributes?.product_images || [];
        const productVideos =  this.state.selectedVariantVideos.length > 0 ?  this.state.selectedVariantVideos:eachProductData?.attributes?.product_videos || [];
        const hasVariants = eachProductData && Object.keys(eachProductData).length > 0;
        return (
          <Box className={classes.productDetailsMainContainer}  data-test-id="productDetailmainContainer">
             <div className="dashboard-outer">
                            <div className="dashboard-inner">
                              <SellerSidebar
                                isOpen={isSideBarOpen}
                                navigation={""}
                                id={""}
                                classes={undefined}
                                closeModal={this.handleSideBarOpenClose}
                                data-test-id="sellerSidebar"
                                activetabName= "inventory"
                                />
                            </div>
             </div>
            <Box className={classes.productDetailsInnerContainer}>
            <Box className={classes.hideHamburgerMenuSection}>
                                    <IconButton
                                        className={`${classes.hideHamburgerMenu}`}
                                        data-test-id="hamburger-btn"
                                        onClick={() => this.handleSideBarOpenClose()}
                                    >
                                        <img src={hamburgerIc} alt="hamburger" />
                                    </IconButton>
            </Box>
             <Box className={classes.backBtnNavigation}>
                <Box m={0} className={classes.upNav} ><img src={backButton} alt="backButton" onClick={() => this.handleBackNavigation()} />
                    <Box>
                     <Typography className={classes.upNavtitle}>{eachProductData?.attributes?.title}</Typography>
                     <Typography className={classes.upNavsku}>SKU ID: {eachProductData?.attributes?.sku}</Typography>
                        </Box>
                    </Box>
              </Box>
            <Loader loading={loading} />
                <Grid container>
                    <Grid item xs={12} lg={6}>
                        <Box className={classes.leftContainer}>
                                <Box className={classes.carouselContainer}>
                                <Carousel className={classes.customCarousel} images={productImages} videos={productVideos} />
                                </Box>
                                <Box className={classes.productDescriptionContainer}>
                                    <Typography className={classes.productDetailTitle}>{configJSON.productDescription}</Typography>
                                    <Typography className={classes.productDescription}>{eachProductData?.attributes?.description}</Typography>
                                  
                                    <Box className={classes.productDescriptionEachSection}>
                                        <Box >
                                        <Typography className={classes.productDetailTitle}>{configJSON.mrp}</Typography>
                                        <Typography className={classes.productDescription}>{eachProductData?.attributes?.mrp}</Typography>
                                        </Box>
                                        <Box className={classes.verticalDivider}></Box>
                                        <Box>
                                        <Typography className={classes.productDetailTitle}>{configJSON.yourSellingPrice}</Typography>
                                        <Typography className={classes.productDescription}>{eachProductData?.attributes?.selling_price}</Typography>
                                        </Box>
                                        <Box className={classes.verticalDivider}></Box>
                                        <Box>
                                        <Typography className={classes.productDetailTitle}>{configJSON.brand}</Typography>
                                        <Typography className={classes.productDescription}>{eachProductData?.attributes?.brand?.name??"--"}</Typography>
                                        </Box>
                                        <Box className={classes.verticalDivider}></Box>
                                        <Box>
                                        <Typography className={classes.productDetailTitle}>{configJSON.stockQuantity}</Typography>
                                        <Typography className={classes.productDescription}  data-test-id="stockQuantity">{ eachProductData?.attributes?.inventory_details?.stock_quantity}</Typography>
                                        </Box>

                                    </Box>
                                    <Box className={classes.horizontalDivider1}></Box>
                                    <Box  className={classes.productDescriptionEachSection}>
                                        <Box >
                                        <Typography className={classes.productDetailTitle}>{configJSON.fulfilmentBy}</Typography>
                                        <Typography className={classes.productDescription}>{eachProductData?.attributes?.inventory_details?.fullfilment_by}</Typography>
                                        </Box>
                                        <Box className={classes.verticalDivider}></Box>
                                        <Box>
                                        <Typography className={classes.productDetailTitle}>{configJSON.procurementType}</Typography>
                                        <Typography className={classes.productDescription}>{eachProductData?.attributes?.inventory_details?.procurement_type}</Typography>
                                        </Box>
                                        <Box className={classes.verticalDivider}></Box>
                                        <Box>
                                        <Typography className={classes.productDetailTitle}>{configJSON.ProcurementSLA}</Typography>
                                        <Typography className={classes.productDescription}>{eachProductData?.attributes?.inventory_details?.procurement_sla}</Typography>
                                        </Box>
                                    </Box>
                                    <Box className={classes.horizontalDivider1}></Box>
                                    <Box className={classes.productDescriptionEachSection}>
                                        <Box >
                                        <Typography className={classes.productDetailTitle}>{configJSON.taxcode}</Typography>
                                        <Typography className={classes.productDescription}>{eachProductData?.attributes?.packaging_detail?.tax_code}</Typography>
                                        </Box>
                                        <Box className={classes.verticalDivider}></Box>
                                        <Box>
                                        <Typography className={classes.productDetailTitle}>{configJSON.shipmentProvider}</Typography>
                                        <Typography className={classes.productDescription}>{eachProductData?.attributes?.packaging_detail?.tax_code}</Typography>
                                        </Box>
                                        <Box className={classes.verticalDivider}></Box>
                                        <Box>
                                        <Typography className={classes.productDetailTitle}>{configJSON.hsn}</Typography>
                                        <Typography className={classes.productDescription}>{eachProductData?.attributes?.packaging_detail?.hsn}</Typography>
                                        </Box>
                                    </Box>
                                    <Box className={classes.horizontalDivider}></Box>
                                    <Box className={classes.manufacturingDetailSection}>
                                        <Typography className={classes.productDetailTitle}>{configJSON.returnAndExchange}</Typography>
                                        <Typography className={classes.productDescription}>{eachProductData?.attributes?.manufacturing_detail?.data?.attributes?.return_and_exchange?configJSON.Yes:configJSON.No}</Typography>
                                    </Box>

                                </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} lg={6} className={classes.rightContainer}>
                                <Box >
                                {hasVariants && (
                                    <Box data-test-id="varaintsContainer" >
                                        <Typography className={classes.colorTitle}>{configJSON.colors}</Typography>
                                        {Object.keys(eachProductData.attributes.catalogue_variants).map((colorKey) => {
                                        const colorData = eachProductData.attributes.catalogue_variants[colorKey];
                                        return(
                                            <Box key={colorData.catalogue_variant_color_id} 
                                            data-test-id={`eachColor${colorData.catalogue_variant_color_id}`}
                                            className={ colorData.catalogue_variant_color_id === selectedVariantObject?.catalogue_variant_color_id ?classes.activeVariant :classes.inActiveVariant}
                                            onClick={() => this.handleVariantColorClick(colorData)}
                                            style={{ 
                                            backgroundColor: colorKey,
                                              }} 
                                             />
                                        )}
                                        )}
                                    </Box>
                                    
                                   )}

                                <Button data-test-id="edit-variant-btn" onClick={()=>this.isObjectEmpty(selectedVariantObject)?this.handleEditProduct():this.handleClickOfEachProduct(eachProductData.id)}  startIcon={<img src={editIcon} />} className={classes.editProductButton}>{this.isObjectEmpty(selectedVariantObject)?configJSON.editProduct:configJSON.editVaraint}</Button>
                                </Box>
                                {/* will be use for reviews */}
                                <Box></Box>
                    </Grid>
                </Grid>
            </Box>
          </Box>
        );
        // Customizable Area End
    }
}


// Customizable Area Start
export default withStyles(webStyles)(ProductDetails)
// Customizable Area End