import React, { Component } from "react";
import { Grid, Typography, Box } from "@material-ui/core";
// import { recommendProduct } from './assets';

// Define the interface for a single product
interface Product {
    id: number;
    discount?: string; // Optional
    imageUrl: string;
    title: string;
    catalogue_image: string;
    selling_price: string;
    mrp:string;
}

interface CreaterMallProps {
    products: Product[]; 
    handleNavigate: (prodId: number) => void;
}

class ProductCard extends Component<CreaterMallProps> {
    render() {
        const { products } = this.props;

        return (
            <>
                {products.map((product) => (
                    <Grid item key={product.id} >
                        <Box
                            style={{
                                borderRadius: "4px",
                                backgroundColor: "#fff",
                                textAlign: "center",
                                position: "relative",
                                maxWidth: "188px",
                                margin: "auto",
                            }}
                            onClick={()=> this.props.handleNavigate(product.id)}
                        >
                            {product.discount && (
                                <Typography
                                    style={{
                                        position: "absolute",
                                       
                                        backgroundColor: "#000000",
                                        color: "#fff",
                                        padding: "2px 8px",
                                        borderRadius: "4px",
                                        fontSize: "12px",
                                        fontWeight: 600,
                                        width:"68px",
                                        height:"28px"
                                    }}
                                >
                                    {product.discount}
                                </Typography>
                            )}

                            {/* Product Image */}
                            <img
                                src={product.catalogue_image}
                                alt={product.title}

                                style={{
                                    width: "188px",
                                    height: "164px",
                                    maxHeight: "150px",
                                    objectFit: "cover",
                                    marginBottom: "16px",
                                    borderRadius: "4px",
                                }}
                            />


                            {/* Product Title */}
                            <Typography
                                style={{
                                    fontSize: "18px",
                                    fontWeight: 600,
                                    marginBottom: "8px",
                                    textAlign:"left",
                                    width:"177px",
                                    color:"#2E2E2E"
                                }}
                            >
                                {product.title}
                            </Typography>

                            {/* Price Section */}
                            <Grid
                                container
                                justifyContent="flex-start"
                                alignItems="center"
                                style={{ gap: "8px" }}
                            >
                                <Typography
                                    style={{
                                        fontSize: "16px",
                                        fontWeight: 700,
                                        color: "#4E4E4E",
                                    }}
                                >
                                    ₹{product.selling_price}
                                </Typography>
                                <Typography
                                    style={{
                                        fontSize: "16px",
                                        fontWeight: 700,
                                        textDecoration: "line-through",
                                        color: "#6E6E6E",
                                    }}
                                >
                                    ₹{product.mrp}
                                </Typography>
                            </Grid>
                        </Box>
                    </Grid>
                ))}
            </>
        );
    }
}

export default ProductCard;
