import { StoryMedia } from "../../blocks/postcreation/src/SellerFeedController.web";
import { UserMessageCreateParams, FileMessageCreateParams } from "@sendbird/chat/message";
import { GroupChannel } from "@sendbird/chat/groupChannel";
type Alignment = "left" | "right" | "center" | "justify";

export const getFileFromUrl = async (url: string): Promise<File> => {
    const response = await fetch(url);
    const blob = await response.blob();
    const fileName = url.split('/').pop() || 'defaultFileName';
    return new File([blob], fileName);
};

export const sendStoryImage = async (textMessage: string, channel: GroupChannel, media: StoryMedia) => {
    if (!channel) return;

    if (!media) return;

    let fileUrl = media.url;

    if (!fileUrl) return;
    try {
        const file = await getFileFromUrl(fileUrl);
        const fileMessageParams: FileMessageCreateParams = {
            file: file,
            customType: "image",
            data: JSON.stringify({ delivered: false, read: false, message: "" }),
        };

        channel?.sendFileMessage(fileMessageParams).onSucceeded(async (sentMessage) => {
            if (sentMessage) {
                const params = {
                    customType: 'image',
                    data: JSON.stringify({ delivered: true, read: false, message: "" }),
                };

                try {
                    await channel.updateFileMessage(sentMessage.messageId, params);
                    sendMessageSendBird(textMessage, channel);
                } catch (error) {
                    console.error(error);
                }

                sentMessage['data'] = JSON.stringify({ delivered: true, read: false, message: "" });
            }
        });
    } catch (error) {
        console.error("Error fetching the file:", error);
    }
};

export const sendMessageSendBird = async (textMessage: string, channel: GroupChannel) => {
    if (textMessage.trim() === '') return;
    try {
        const msgParams: UserMessageCreateParams = {
            message: textMessage,
            data: JSON.stringify({ delivered: false, read: false }),
        };
        channel?.sendUserMessage(msgParams).onSucceeded(async (sentMessage) => {
            if (sentMessage) {
                const sendbirdParams = {
                    customType: 'custom',
                    data: JSON.stringify({ delivered: true, read: false }),
                };
                try {
                    await channel.updateUserMessage(sentMessage.messageId, sendbirdParams);
                } catch (error) {
                }
                sentMessage['data'] = JSON.stringify({ delivered: true, read: false })
                // this.setState({ commentStoryId: {} as StoryAttributes })
            }
        });
    } catch (error) {
    }
};

export const generateImageWithText = ( 
    previewUrl: string,
    textInStory: {
        text: string;
        textSize: string;
        x: number;
        y: number;
        isDragging: boolean;
        offsetX: number;
        offsetY: number;
        textColor: string;
        textFamily: string;
    }[],
    storyTxtAlignment: Alignment,
    setStateCallback: (newState: { finalImage: File }) => void
) => {
    const image = new Image();
    image.src = previewUrl;

    image.onload = () => {
        const aspectRatio = image.width / image.height;

        const canvas = document.createElement('canvas');
        canvas.height = canvas.width / aspectRatio;

        const context = canvas.getContext('2d');
        if (context) {
            context.fillStyle = 'white';
            context.fillRect(0, 0, canvas.width, canvas.height);

            context.drawImage(image, 0, 0, canvas.width, canvas.height);

            textInStory.forEach((textItem) => {
                const scaledTextSize =
                    (parseInt(textItem.textSize, 7) / 100) * canvas.width;
                context.font = `${scaledTextSize}px ${textItem.textFamily}`;
                context.fillStyle = textItem.textColor;

                switch (storyTxtAlignment) {
                    case 'left':
                        context.textAlign = 'left';
                        break;
                    case 'center':
                        context.textAlign = 'center';
                        break;
                    case 'right':
                        context.textAlign = 'right';
                        break;
                    default:
                        context.textAlign = 'left';
                }

                let adjustedX = textItem.x;
                if (context.textAlign === 'center') {
                    adjustedX = canvas.width / 2;
                } else if (context.textAlign === 'right') {
                    adjustedX = canvas.width - textItem.x;
                }

                const metrics = context.measureText(textItem.text);
                const textHeight =
                    metrics.actualBoundingBoxAscent +
                    metrics.actualBoundingBoxDescent;
                const adjustedY = textItem.y + textHeight / 2;

                context.fillText(textItem.text, adjustedX, adjustedY);
            });

            canvas.toBlob((blob) => {
                if (blob) {
                    const file = new File([blob], 'generated-image.png', {
                        type: 'image/png',
                    });
                    setStateCallback({ finalImage: file });
                }
            }, 'image/png');
        }
    };
};
