import React, { useState } from 'react';
import Slider from 'react-slick';
import { makeStyles } from '@material-ui/core/styles';
import Loader from './Loader';
let config = require('../../framework/src/config')

const useStyles = makeStyles(theme => ({
  root: {
    margin: 'auto',
    maxHeight:"500px"
  },

  dots: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },
  dot: {
    display: 'inline-block',
    width: 10,
    height: 10,
    borderRadius: '50%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    margin: '0 5px',
    cursor: 'pointer',
  
  },
  activeDot: { 
    backgroundColor: 'black', 
  },
  image:{
    width: '100%',
    height: '100%',
    objectFit: 'contain'
  }

}));

const Carousel = ({ images, videos }:any) => {
  const classes = useStyles();
  const [currentSlide, setCurrentSlide] = useState(0);

  const handleDotClick = (index: number) => {
    setCurrentSlide(index);
  };

  const settings = {
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: (dots: string | number | boolean | {} | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactNodeArray | React.ReactPortal | null | undefined) => (
      <div className={classes.dots}>
        {dots}
      </div>
    ),
    customPaging: (index: number) => (
     <div className={`${classes.dot} ${currentSlide === index ? classes.activeDot : ''}`} onClick={() => handleDotClick(index)} />
    ),
  };

  const renderView = () => {
    const imgsVds = images.length >= 1  && videos.length >= 1;
    const imgs = images.length > 1;
    const vds = videos.length > 1;
    const img1 = images.length === 1;

    switch(true){
      case imgsVds :
        return <Slider {...settings} afterChange={setCurrentSlide}>
        {images.map((image: { id: React.Key | undefined; url: string | undefined; }) => (
          <div key={image.id} >
            <img src={config.baseURL + "/" + image.url} alt={`Image ${image.id}`} className={classes.image}/>
        </div>
        ))}
        {videos.map((video: { id: React.Key | undefined; url: string | undefined; }) => (
          <div key={video.id}>
              <video controls  className={classes.image}>
                <source src={config.baseURL + "/" + video.url} type="video/mp4" />
              </video>
          </div>
        ))}
      </Slider>;
      case imgs:
        return <Slider {...settings} afterChange={setCurrentSlide}>
      {images.map((image: { id: React.Key | undefined; url: string | undefined; }) => (
        <div key={image.id} >
          <img src={config.baseURL + "/" + image.url} alt={`Image ${image.id}`} className={classes.image}/>
      </div>
      ))}
    </Slider>
    case vds:
      <Slider {...settings} afterChange={setCurrentSlide}>
      {videos.map((video: { id: React.Key | undefined; url: string | undefined; }) => (
        <div key={video.id}>
            <video controls  className={classes.image}>
              <source src={config.baseURL + "/" + video.url} type="video/mp4" />
            </video>
        </div>
      ))}
    </Slider>
    case img1:
      return <div>
      <img src={config.baseURL + "/" + images[0]?.url} alt={`Image ${images[0]?.id}`} className={classes.image}/>
    </div>
    default:
      ''
    }
  }

  return (
    <div className={classes.root}>
      {
        renderView()
      }
    </div>
  );
};

export default Carousel;
