import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, withStyles } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
const truthyValue = (key: any) => {
  if (key !== "" || key !== null || !key.length) {
      return key
  } else {
      return ""
  }     
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import PrivacySettingsController, {
  Props,
  configJSON,
} from "./PrivacySettingsController";
import EffectiveDateFormat from "../../../components/src/EffectiveDateFormat.web";

export class PrivacySettings extends PrivacySettingsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  abstractPrivacyData = () => {
    let mainHeading = ""
    let description = ""
    let effectiveDate = ""
    if (this.state.generalPrivacyData) {
        mainHeading = truthyValue(this.state.generalPrivacyData.heading)
        description = truthyValue(this.state.generalPrivacyData.description)
        effectiveDate = truthyValue(this.state.generalPrivacyData.effective_date)
    }
    return { mainHeading, description, effectiveDate }
}
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { mainHeading, description, effectiveDate } = this.abstractPrivacyData();
    // Customizable Area End
    return (
      // Customizable Area Start
      <div className={classes.initialHeight}>
        <div id="myref"></div>
        <Box className={classes.termsConditionHeader}>
          
        </Box>
        <Box className={classes.mainDiv}>
          <Box className={classes.titleWrap}>
            <Typography
              className={classes.title}>{mainHeading}</Typography>
          </Box>
          {effectiveDate &&
          <div style={{display: 'flex', alignItems: 'center'}}>
            <Typography
                className={classes.contentTitleOuter}>
                Effective Date:
              </Typography>
              <Typography className={classes.contentTitleOuter} style={{ marginLeft: '5px', marginTop: '-5px' }}><strong><EffectiveDateFormat content={effectiveDate} /></strong></Typography>
            </div>
          }
          <Box className={classes.contentWrap}>
            <Typography className={classes.content}
              dangerouslySetInnerHTML={{ __html: description }}></Typography>
          </Box>
        </Box>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = (theme: any) => ({
  initialHeight: {
      minHeight: '90vh'
  },
  mainDiv:{
      maxWidth: '1160px', 
      width: '100%', 
      margin: '0 auto',
      padding: '30px 15px 65px',
      '@media (max-width: 991px)': {
          padding: '15px 15px 65px',
      },
  },
  title: {
      color: '#000',
      fontSize: '24px',
      fontFamily: 'Poppins !important',
      fontStyle: 'normal',
      fontWeight: 600,
      letterSpacing: '0.3px',
      lineHeight: '24px',
  },
  contentTitleOuter: {
      fontFamily: 'Poppins !important',
      color: '#000',
      fontSize: '18px',
      fontWeight: 500,
      fontStyle: 'normal',
      padding: '22px 0',
      lineHeight: 'normal',
      // '@media (max-width: 1180px)': {
      //     padding: '22px 50px 0',
      // },
      // '@media (max-width: 525px)': {
      //     padding: '15px 15px 0',
      // },
  },
  content: {
      color: '#000',
      fontSize: '18px',
      fontFamily: 'Poppins !important',
      fontWeight: 400,
      padding: '15px 0',
      fontStyle: 'normal',
      lineHeight: 'normal',
  },
  yellowContent: {
      fontFamily: 'Poppins !important',
      color: '#F5EA16',
      fontWeight: 700,
      fontStyle: 'normal',
      fontSize: '18px',
      lineHeight: 'normal',
  },
  contentWrap: {
      margin: '0 7px'
      // padding: '0 175px',
      // '@media (max-width: 1180px)': {
      //     padding: '0 58px',
      // },
      // '@media (max-width: 525px)': {
      //     padding: '0 20px',
      // },
  },
  contentTitle: {
      fontFamily: 'Poppins !important',
      color: '#000',
      fontStyle: 'normal',
      fontSize: '18px',
      fontWeight: 600,
      padding: '15px 0',
      lineHeight: 'normal',
  },
  contentTitleLite: {
      color: '#000',
      fontSize: '18px',
      fontStyle: 'normal',
      fontFamily: 'Poppins !important',
      padding: '15px 0',
      fontWeight: 500,
      lineHeight: 'normal',
  },
  blueLinkText: {
      color: '#001AFF',
      fontStyle: 'normal',
      fontSize: '18px',
      fontFamily: 'Poppins !important',
      fontWeight: 400,
      textDecoration: 'none',
      lineHeight: 'normal',
  },
  termsConditionHeader: {
      width: '100%',
      height: '100px',
      backgroundColor: '#000000',
  },
  termsConditionHeaderInner: {
      marginLeft: '64px',
  }
});
export default withStyles(styles)(PrivacySettings)
// Customizable Area End
