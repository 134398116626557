Object.defineProperty(exports, "__esModule", {
    value: true
  });
  
  // Customizable Area Start
  exports.validationApiContentType = "application/json";
  exports.validationApiMethodType = "GET";
  exports.exampleAPiEndPoint = "EXAMPLE_URL";
  exports.exampleAPiMethod = "POST";
  exports.exampleApiContentType = "application/json";
  exports.textInputPlaceHolder = "Enter Text";
  exports.labelTitleText = "cfdigitalmall";
  exports.labelBodyText = "cfdigitalmall Body";
  exports.addToCartEndPoint = "/bx_block_order_management/add_item_to_cart"
  exports.getCity = "https://nominatim.openstreetmap.org/reverse?"
  exports.getLatLong = "https://nominatim.openstreetmap.org/search?q="
  
  exports.btnExampleTitle = "CLICK ME";
  exports.storeTxt = "Stores";
  exports.seeAllTxt = "See all"; 
  exports.categoryTxt = "Categories";
  exports.visitStoreTxt = "Visit store"; 
  
  exports.getBannersEndPoint = "bx_block_landingpage/digital_mall/get_banners?platform=web";
  exports.getCategoriesEndPoint = "bx_block_categories/categories";
  exports.getSubCategoriesEndPoint = "bx_block_categories/sub_categories?category_id=";
  exports.getStoresEndPoint = "bx_block_landingpage/digital_mall/get_stores";
  
  exports.storeEndPoint = "bx_block_landingpage/digital_mall/visit_store?id="
  exports.getCities = "/bx_block_landingpage/digital_mall/get_cities?search="
  exports.storeName  = "Store Name"
  exports.locationMock ="3km, Jp nagar phase 1"
  exports.numberMock = "+91 7231992744"
  exports.emailMock ="johndoe12@email.com"
  exports.visitSeller = "Visit Seller"
  exports.bookAppointment = "Book Appointment"
  exports.ratingMock = "4.5"
  exports.products="Products"
  exports.live = "Live Events"
  exports.storeLocation = "Store Location"
  exports.goToFeed = "GO TO FEED"
  exports.addToCart = "Add to Cart"
  exports.rupeeSymbol ="₹"
  exports.goToFeedTxt = "GO TO FEED"
  exports.sortTxt = "Sort"
  exports.currentLocation = "Current Location"
  exports.city = "Bangalore"
  exports.searchForCity = "Search for your city"
  exports.useCurrentLocation = "Use current location"
  exports.popularCities = "Popular cities"
  exports.otherCities = "Other cities"
  exports.unsupportedMessage = "Location is not supported by this browser."
  exports.userDeniedGeoLocation = "User denied the request for Loaction."
  exports.locatonInformationUnavailable ="Location information is unavailable."
  exports.locationTimedOut ="The request to get user location timed out"
  exports.selectLocation = "Select Location"
  exports.defaultAddress ={
    "lat": "22.5726459",
    "lon": "88.3638953",
    "class": "boundary",
    "type": "administrative",
    "place_rank": 14,
    "importance": 0.634038534627831,
    "addresstype": "city",
    "name": "Kolkata"
  }


// Customizable Area End