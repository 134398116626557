import React from "react";
import {
    // Customizable Area Start
    Box, Grid, Typography, styled, Button
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
import EmailAccountLoginController, {
    Props,
} from "./EmailAccountLoginController";
import { App_Logo, Collage, Wave, indiaFlag, iphoneImage, iphoneLogo } from "../../email-account-registration/src/assets";
// Customizable Area End

export default class GetStartedLogin extends EmailAccountLoginController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start    
    // Customizable Area End
    render() {
        // Customizable Area Start
        return (
            <>
                <ParentGrid container>
                    <ImageGridMain item md={6}>
                        <OuterBox>
                            <ImageLogoMain src={App_Logo} alt="Logo" />
                            <ImageBox style={{ display: 'flex', position: 'relative' }}>
                                <Box style={{ background: '#F5EA16', borderRadius: '48px', width: 403, height: 378, position: 'relative' }}>
                                    <img style={{ position: 'absolute', left: 20, top: -40 }} src={iphoneImage} alt="Logo Image 1" />
                                    <img style={{ position: 'absolute', right: 20, bottom: -30, zIndex: 3 }} src={iphoneLogo} alt="Logo Image 2" />
                                </Box>
                                <Box style={{ background: '#F5EA16', borderRadius: '32px', width: 358, height: 357, position: 'relative', top: 280, left: -50 }}>
                                    <img style={{ borderRadius: '22px', position: 'absolute', top: -20, left: -20 }} src={Collage} alt="Logo Image 3" />
                                </Box>
                            </ImageBox>
                        </OuterBox>
                    </ImageGridMain>
                    <RightGrid item md={6}>
                        <WaveImg src={Wave} alt="overlayWave" />
                        <WaveImg2 src={Wave} alt="overlayWave" />
                        <ContentBox>
                            <Box>
                                <HeadingTypography>Go Live, Buy, Sell all at one place</HeadingTypography>
                                <ButtonBox >
                                    <BuyerButton variant="contained" color="primary" onClick={this.navigateToBuyerLogin} >
                                        Log In  as Buyer
                                    </BuyerButton>
                                    <BuyerButton variant="contained" color="primary" onClick={this.navigateToSellerLogin}>
                                        Log In as Seller
                                    </BuyerButton>
                                </ButtonBox>
                                <Box style={{ color: 'white', textAlign: 'center', marginTop: 40, marginBottom: 110 }}>
                                    Don’t have an account?

                                    <span style={{ color: "#F5EA16", cursor: "pointer", marginLeft: "5px" }} onClick={this.navigateToSignupPage}>
                                        Sign Up
                                    </span>
                                    <Box sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center', gridGap: '5px', height: '16'
                                    }}
                                        style={{ marginTop: '28px' }}>
                                        <img src={indiaFlag} alt="flag" />
                                        <Typography component={'p'} style={{ fontFamily: 'Poppins-Regular', fontSize: '16px', }}>Made in Bharat</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </ContentBox>
                    </RightGrid>
                </ParentGrid>
            </>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const ParentGrid = styled(Grid)({
    background: "#000",
})
const OuterBox = styled(Box)({
    position: 'relative',
    maxWidth: 800,
    '@media(min-width: 1750px)': {
        transformOrigin: 'top',
        transform: 'scale(0.8)',
        minHeight: '100vh',
        marginTop: 50,
    },
    '@media(min-width: 1280px) and (max-width: 1750px)': {
        transformOrigin: 'top',
        transform: 'scale(0.65)',
        minHeight: '100vh',
        marginTop: 50,
    },
    '@media(min-width: 1100px) and (max-width: 1280px)': {
        transformOrigin: 'top',
        transform: 'scale(0.5)',
        minHeight: '100vh',
        marginTop: 50,
    },
    '@media(min-width: 960px) and (max-width: 1100px)': {
        transformOrigin: 'top',
        transform: 'scale(0.4)',
        minHeight: '100vh',
        marginTop: 50,
    },
    '@media(min-width: 767px) and (max-width: 959px)': {
        marginTop: 50,
        transform: 'scale(0.7)',
        transformOrigin: 'top',
    },
    '@media(min-width: 500px) and (max-width: 767px)': {
        marginTop: 50,
        transform: 'scale(0.6)',
        transformOrigin: 'top',
    },
    '@media(min-width: 1920px)': {
        minHeight: '100vh',
    },
    '@media(min-width: 100px) and (max-width: 500px)': {
        marginTop: 50,
        transform: 'scale(0.5)',
        transformOrigin: 'top',
    },
})
const ImageBox = styled(Box)({
    marginLeft: 40,
    marginTop: 250,
    '@mediaand (min-width: 1920px)': {
        height: 700
    },
    '@media(min-width: 1280px) and (max-width: 1550px)': {
        transform: 'scale(0.9)',
    },
    '@media(max-width: 1920px)': {
        marginTop: 200
    },
})
const RightGrid = styled(Grid)({
    width: '100%',
    background: 'black',
    overflow: "hidden",
    position: "relative",
    height: '100vh',
    '@media(max-width: 500px)': {
        marginTop: -110,
    },
    '@media(min-width: 500px) and (max-width: 767px)': {
        marginTop: -100,
    },
    '@media(max-width: 960px)': {
        height: 'unset'
    },
})
const ContentBox = styled(Box)({
    justifyContent: 'center',
    display: 'flex',
    marginTop: 173,
    '@media(max-width: 959px)': {
        marginTop: 30,
        marginLeft: 0,
    },
    '@media(min-width: 1280px) and (max-width: 1750px)': {
        marginTop: 0,
        transform: 'scale(0.65)',
    },
    '@media(min-width: 959px) and (max-width: 1290px)': {
        marginTop: 50,
        transform: 'scale(0.8)',
    },
    '@media(min-width: 960px) and (max-width: 1100px)': {
        marginTop: 50,
        transform: 'scale(0.6)',
        transformOrigin: 'top',
    },
    '@media(min-width: 1100px) and (max-width: 1280px)': {
        transform: 'scale(0.6)',
        marginTop: 100,
        transformOrigin: 'top',
    },
    '@media(min-width: 1750px)': {
        transformOrigin: 'top',
        transform: 'scale(0.8)',
        marginTop: 100,
    },
})
const ImageGridMain = styled(Grid)({
    backdropFilter: 'blur(13.591408729553223px)',
    background: 'rgba(255, 255, 255, 0.25)',
    display: 'flex',
    width: "100%",
    position: 'relative',
    justifyContent: 'center',
    height: '100vh',
    '@media(max-width: 959px)': {
        alignItems: 'flex-end',
        height: 'unset',
        border: 'none',
        background: 'unset',
    },
    '@media(min-width: 1200)': {
        height: "100%",
    },
    '@media(min-width: 500px) and (max-width: 959px)': {
        paddingBottom: 50,
    },
})

const ImageLogoMain = styled('img')({
    top: 50,
    position: 'absolute',
    left: 20,
    '@media(max-width: 1920px)': {
        left: 0,
        top: 0,
    },
    '@media(min-width: 960px) and (max-width: 1919px)': {
        left: 20
    },
})

const HeadingTypography = styled(Typography)({
    textAlign: 'center',
    color: '#FFF',
    paddingBottom: 10,
    fontFamily: "'Poppins-Regular', sans-serif",
    fontStyle: 'normal',
    fontSize: '60px',
    lineHeight: 'normal',
    fontWeight: 500,
    maxWidth: 710,
    '@media(max-width: 959px)': {
        letterSpacing: '-1.8px',
        fontSize: '44px',
        paddingInline: 20,
        lineHeight: '58px',
    },
    '@media(max-width: 1280px)': {
        fontSize: '50px',
    }
})

const ButtonBox = styled(Box)({
    margin: 'auto',
    marginTop: 90,
    textAlign: 'center',
    '& .MuiButton-label': {
        textTransform: 'initial'
    },
    '@media(max-width: 512px)': {
        margin: "0px 20px"
    },
    '@media(max-width: 959px)': {
        marginTop: 30
    },
})
const BuyerButton = styled(Button)({
    border: '1px solid #F5EA16',
    borderRadius: '50px',
    width: '100%',
    background: 'black',
    margin: '50px auto 0',
    maxWidth: '412px',
    color: '#fff',
    height: '68px',
    fontFamily: 'Poppins-Regular',
    textAlign: 'center',
    fontStyle: 'normal',
    fontSize: '20px',
    lineHeight: 'normal',
    fontWeight: 500,
    letterSpacing: '0.417px',
    "&:hover": {
        color: 'black',
        background: "#F5EA16",
    },
});

const WaveImg = styled('img')({
    bottom: 0,
    position: 'absolute',
    width: '100%'
})
const WaveImg2 = styled('img')({
    bottom: 0,
    position: 'absolute',
    width: '100%',
    left: -169,
})
// Customizable Area End
