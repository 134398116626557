import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { Linking, Platform, PermissionsAndroid } from "react-native";
import StorageProvider from '../../../framework/src/StorageProvider'
import Geolocation from 'react-native-geolocation-service';
import { WithStyles } from "@material-ui/core";
import React from "react";
import { getStorageData } from "../../../framework/src/Utilities";
interface CountryListItem {
  countryCode: string;
  name: string;
}

type CountryList = CountryListItem[];

interface StateListItem {
  countryCode: string;
  name: string;
}

type StateList = StateListItem[];

interface CityListResponse {
  cities: string[];
}

type CityList = string[];

interface Status {
  id: number;
  status: string;
  comment: string
}
interface Comment {
  [key: number]: string;
}

export interface LinkedAddressDetails {
  id: number;
  linked_to: number;
  linked_store_name: string;
  status: string;
  comments: Comment[];
}

export interface StoreAddress {
  id: number;
  address_line_1: string;
  address_line_2: string;
  pin_code: number;
  city: string;
  state: string;
  country: string;
  latitude: number;
  longitude: number;
  account_id: number;
  created_at: string;  
  updated_at: string; 
}
export interface AddressObject {
  linked_address_details: LinkedAddressDetails;
  store_address: StoreAddress[];
}

interface PendingLinkedAddressDetails {
  id: number;
  linked_to: number;
  linked_store_name: string;
  status: string;
  comments: Comment[];
}

export interface PendingStoreAddress {
  id: number;
  address_line_1: string;
  address_line_2: string;
  pin_code: number;
  city: string;
  state: string;
  country: string;
  latitude: number;
  longitude: number;
  account_id: number;
  created_at: string;
  updated_at: string;
  other_linked_account: Array<string>;
}

export interface LinkedStore {
  linked_address_details: PendingLinkedAddressDetails;
  store_address: PendingStoreAddress[];
}

export interface SellersList {
  id: string;
  email: string;
  business_name: string;
  user_name: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles{
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  initialRegion: any;
  searchText: string;
  mainMarker: any;
  showStoreInfoModal: boolean;
  storeInfo: any;
  showMapsIcon: boolean;
  rememberChoice: boolean;
  loader: boolean;
  token: string;
  machinesList: Array<{}>;
  isSearchBoxFocused: boolean;
  platform: string;
  openAddStoreAddress:boolean
  addressOne:string
  addressTwo:string
  storeAddressPincode:number | null
  countryList: CountryList;
  stateList: StateList;
  cityList: CityList;
  
  selectedCountry: string | undefined | null;
  selectedState:  string | undefined | null;
  selectedCity: string | undefined | null;

  countryErrorMsg:string
  stateErrorMsg:string
  cityErrorMsg:string

  longitudinal:number | null
  latitudinal:number  | null
  linkStoreModal: boolean;
  searchSeller: string;
  sellerEmail: string;
  comment: string;
  sellersList: Array<SellersList>;
  isSellerListOpen: boolean;
  linkedStores: Array<AddressObject>;
  statusModal: boolean;
  statusModalStoreDetails: Array<LinkedStore>;
  isPopupVisible: boolean;
  popupMessage: string;
  loading: boolean;
  deleteModal: boolean;
  deleteID: null | number;
  nameError: boolean;
  addressDetails:StoreAddress[];
  addressid: number | null;
  deleteAddressModal:boolean;
  deleteAddressID:null | number;
  addressformError: string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class StoreLocatorController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  searchInputRef: any;
  getVendingMachinesAPICallID: string = "";
  getVendingMachinesBySearchAPICallID: string = "";
  getCountryListApiCallId: string = "";
  getPickUpStateListApiCallId:string="";
  getPickUpCityListApiCallId:string="";
  sellersApiID: string = "";
  linkStoreApiID: string = "";
  allStoreApiID: string = "";
  deleteLinkedStoreApiID: string = "";
  getPendingLinkedAddressApiID: string = "";
  postAcceptRequestApiID: string = "";
  postRejectRequestApiID: string = "";
  dropdownRef: React.RefObject<HTMLDivElement>;
  addStoreAddressApiID: string="";
  deleteCurrentStoreApiID:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.dropdownRef = React.createRef();
    // Customizable Area End
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      initialRegion: {},
      searchText: "",
      mainMarker: null,
      showStoreInfoModal: false,
      showMapsIcon: false,
      storeInfo: {},
      rememberChoice: false,
      loader: true,
      token: "",
      machinesList: [],
      isSearchBoxFocused: false,
      platform: Platform.OS,
      openAddStoreAddress:false,
      addressOne:"",
      addressTwo:"",
      storeAddressPincode:null,
      countryList: [],
      stateList: [],
      cityList: [],

      selectedCountry: '',
      selectedState: '',
      selectedCity: '',

      countryErrorMsg:'',
      stateErrorMsg:'',
      cityErrorMsg:'',
      longitudinal:null,
      latitudinal:null,
      linkStoreModal: false,
      sellersList: [],
      isSellerListOpen: false,
      searchSeller: "",
      sellerEmail: "",
      comment: "",
      linkedStores: [],
      statusModal: false,
      statusModalStoreDetails: [],
      isPopupVisible: false,
      popupMessage: "",
      loading: false,
      deleteModal: false,
      deleteAddressModal:false,
      deleteID: null,
      nameError: false,
      addressDetails:[],
      addressid:null,
      deleteAddressID:null,
      addressformError:""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
     
      const apiRequestCallId = message.getData( getName(MessageEnum.RestAPIResponceDataMessage) );

      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if(responseJson){
        this.handleApiSuccess(apiRequestCallId, responseJson);
      } 

      if(responseJson.message || responseJson.error){
        const message = responseJson.message ?? responseJson.error
        this.handleApiFailure(apiRequestCallId, message)
      }
      
          

    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };
  
  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };
  
  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let dataMessage = new Message(getName(MessageEnum.AccoutLoginSuccess));
    dataMessage.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(dataMessage);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount() {
    // this.focusMethod();
    this.getCountryList();
    this.getSellers();
    this.getAllStore();

    document.addEventListener("click", this.handleClickOutside);
  }

  async componentWillUnmount(): Promise<void> {
    document.removeEventListener("click", this.handleClickOutside)
  }

  setCountryList = (responseJson: {countries:{countryCode:string,name:string}}) => {
    if (responseJson.countries) {
        this.setState({
            countryList: Object.entries(responseJson.countries).map(([countryCode, name]) => ({ countryCode, name })),
        });
    }
  }

  markerPressed = (marker:object) => {
    this.setState({showStoreInfoModal: true, storeInfo: marker})
  }

  closeModal = () => {
    this.setState({showStoreInfoModal: false, storeInfo: {}, showMapsIcon: false})
  }

  changeRememberChoice = () => {
    this.setState({rememberChoice: !this.state.rememberChoice})
  }

  goHerePressed = () => {
    if(this.state.platform === "android") 
    {
      this.setState({showStoreInfoModal: false })
      this.openGoogleMaps()
    }
    else
      this.setState({showStoreInfoModal: false, showMapsIcon: true})
  }


  searchTextChange = async(text: string) => {
    this.setState({searchText: text, loader: true, isSearchBoxFocused: false})
    if(text.length > 0){
      let token = await StorageProvider.get("USER_TOKEN");
      this.apiCallIDForVendingMachines(token, text.toLowerCase())
    }
    else{
      this.getVendingMachines();
    }
  }

  apiCallIDForVendingMachines = (token: string, text: string) => {
    const header = {
      "Content-Type":"application/json",
    }
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getVendingMachinesBySearchAPICallID = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.searchVendingMachinesEndpoint+text
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  openMaps = () => {
    const scheme = 'maps:0,0?q='
    const latLng = `${this.state.storeInfo.attributes.latitude},${this.state.storeInfo.attributes.longitude}`;
    const label = this.state.storeInfo.attributes.title
    const mapUrl = `${scheme}${label}@${latLng}`
    Linking.openURL(mapUrl)
  }

  openGoogleMaps = () => {
      const scheme = 'geo:0,0?q='
      const latLng = `${this.state.storeInfo.attributes.latitude},${this.state.storeInfo.attributes.longitude}`;
      const label = this.state.storeInfo.attributes.title
      const urlGoogleMaps = `${scheme}${latLng}(${label})`
      Linking.openURL(urlGoogleMaps)
  }

  getCurrentLocation = () => {
    this.setState({ searchText: "" })
    Geolocation.getCurrentPosition(
      (position) => {
        const {latitude,longitude } = position.coords
        this.setState({ mainMarker : {latitude, longitude},   });
        this.getVendingMachines();
      },
      (error) => {
        alert("Unable to track your location. "+ error) 
        this.getVendingMachines();
      },
      { enableHighAccuracy: true, timeout: 15000, maximumAge: 10000 }
    )
  }

  getVendingMachines = async() => {
      const header = {
        "Content-Type":"application/json",
      }
      const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
      this.getVendingMachinesAPICallID=requestMessage.messageId
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getVendingMachinesEndpoint
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    
  }

  storeListofMachines = (responseJson:any) => {
    if(responseJson.data){
      if(this.state.searchText !== "" ){
        this.setState({ initialRegion : {
          latitude: responseJson.data[0].attributes.latitude,
          longitude: responseJson.data[0].attributes.longitude,
          latitudeDelta: 0.0922,
          longitudeDelta: 0.0421,
        }})
      } else if(this.state.mainMarker){
        this.setState({ initialRegion : {
          latitude: this.state.mainMarker.latitude,
          longitude: this.state.mainMarker.longitude,
          latitudeDelta: 0.0922,
          longitudeDelta: 0.0421,
        }})
      }
      
      this.setState({ machinesList : [...responseJson.data], loader: false })
    }
    else{
      this.setState({ machinesList : [], loader: false })
      alert(responseJson.message)
    }
  }

  hasLocationPermissions = async () => {
    const granted = await PermissionsAndroid.request(PermissionsAndroid.PERMISSIONS.ACCESS_FINE_LOCATION);
    if (granted === PermissionsAndroid.RESULTS.GRANTED) {
      return true;
    } else { 
      return false;
    }
  }

  checkIOSPermissions = (result: string) => {
    if (result === 'granted') {
      this.getCurrentLocation();
    }
    else{
      this.getVendingMachines();
    }
  }
  checkAndroidSide = (permission: boolean) => {
    if(permission){
      this.getCurrentLocation();
    }else {
      this.showAlert(
        "Alert",
        "Unable to track your Location, Permissions denied"
      );
      this.getVendingMachines();
    }
  }

  focusMethod = async () => {
    this.setState({loader : true})
    if(this.state.platform === 'ios'){
      let result = await Geolocation.requestAuthorization('whenInUse')
      this.checkIOSPermissions(result)
    }
    if(this.state.platform === 'android'){
      const permission = await this.hasLocationPermissions();
      this.checkAndroidSide(permission);
    }
  }
  changeTextSearch = (text:string) => {
    this.setState({searchText: text})
  }
  onFocus = () => {
    this.setState({isSearchBoxFocused : true})
  }
  handleStoreAdress=()=>{
    this.setState({openAddStoreAddress:true})
  }
  handleCloseStoreAddressModal=()=>{
    this.setState({openAddStoreAddress:false})
  }
  handleTextFieldChange = (event: React.ChangeEvent<HTMLInputElement>, field: keyof S) => {
    this.setState({ [field]: event.target.value, isSellerListOpen: field === "searchSeller" ? true : false }  as unknown as Pick<S, keyof S>, ()=>{
      if(this.state.searchSeller){
        this.setState({
          nameError: false
        })
      }
    });
  };
  handleChangePickUpPincode = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (/^\d{0,6}$/.test(value)) {
      this.setState({ storeAddressPincode: value === '' ? null : Number(value) });
    }
  };
  handleLatAndLongChange = (event:React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const numValue = value === '' ? null : Number(value);
    if (numValue === null || !isNaN(numValue)) {
      this.setState({
        [name]: numValue
      } as Pick<unknown, keyof unknown>);
    }
  };

  getCountryList = () => {
    const header = { "Content-Type": "application/json"
    };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCountryListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.countryList);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getStateList = () => {
    const { selectedCountry, countryList } = this.state;
    const header = {
      "Content-Type": "application/json"
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPickUpStateListApiCallId = requestMessage.messageId;

    requestMessage.addData(getName(
      MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.stateList}?country_code=${countryList.find((item: {name:string}) => item.name === selectedCountry)?.countryCode}`);

    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType);

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getCityList = () => {
    const { selectedCountry, countryList, stateList, selectedState } = this.state;

    const countrySlug = countryList.find((item: {name:string}) =>
      item.name === selectedCountry)?.countryCode;
    const stateSlug = stateList.find((item:{name:string}) =>
      item.name === selectedState)?.countryCode;

    const header = {
      "Content-Type": "application/json"
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPickUpCityListApiCallId = requestMessage.messageId;

    requestMessage.addData(getName(
      MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.cityList}?country_code=${countrySlug}&state_code=${stateSlug}`);

    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(getName(
      MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType);

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleCountryChange = (event: React.ChangeEvent<{}>, newValue: string | null |undefined) => {
    this.setState({
      selectedCountry: newValue,
      selectedState: '',
      stateList: [],
      selectedCity: '',
      cityList: [],
    }, () => {
      newValue && this.getStateList();
    });
    this.setState({ countryErrorMsg: "" });
  }
  handleStateChange = (event: React.ChangeEvent<{}>, newValue: string |null) => {
    this.setState({
      selectedState: newValue,
      selectedCity: '',
      cityList: []
    }, () => {
      newValue && this.getCityList();
    });
    this.setState({ stateErrorMsg: "" });
  }
  handleCityChange = (event: React.ChangeEvent<{}>, newValue: string |null) => {
    this.setState({
      selectedCity: newValue
    });
    this.setState({ cityErrorMsg: "" });
  }

  
  ValidateCountry = () => {
    const { selectedCountry } = this.state;
    if (!selectedCountry) {
      this.setError("countryErrorMsg", configJSON.countryErrorMsg);
      return false;
    } else {
      this.setState({ countryErrorMsg: "" });
      return true;
    }
  }
  ValidateState = () => {
    const { selectedState } = this.state;
    if (!selectedState) {
      this.setError("stateErrorMsg", configJSON.stateErrorMsg);
      return false;
    } else {
      this.setState({ stateErrorMsg: "" });
      return true;
    }
  }
  ValidateCity = () => {
    const { selectedCity } = this.state;
    if (!selectedCity) {
      this.setError("cityErrorMsg", configJSON.cityErrorMsg);
      return false;
    } else {
      this.setState({ cityErrorMsg: "" });
      return true;
    }
  }

  setError = (stateKey: keyof S, errorMsg: string) => {
    this.setState((prevState) => ({
      ...prevState,
      [stateKey]: errorMsg,
    }));
  };
  setStateList = (responseJson: {states:{countryCode:string,name:string}}) => {
    if (responseJson.states) {
      this.setState({
        stateList: Object.entries(responseJson.states).map(([countryCode, name]) => ({ countryCode, name })),
        selectedState: Object.entries(responseJson.states).map(([countryCode, name]) => ({ countryCode, name })).find((state:{name:string} ) => state.name === this.state.selectedState)?.name
      });
    }
  }
  setCityList = (responseJson: {cities:string[]}) => {
    if (responseJson.cities) {
      this.setState({
        cityList: responseJson.cities,
        selectedCity: this.state.selectedCity
      });
    }
  }

  handleCloseLinkStoreModal = () => {
    this.setState({
      linkStoreModal: false,
      searchSeller: "",
      sellerEmail: "",
      comment: "",
      nameError: false,
    })
  }

  handleOpenLinkStoreModal = () => {
    this.setState({
      linkStoreModal: true
    })
  }

  handleApi = async(data:{method: string; endpoint: string; body?:{}}) => {
    const {method, endpoint, body} = data;
    const token = await getStorageData("singupLogin");;
    const header = {
      "Content-Type": "application/json",
      token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(getName(
      MessageEnum.RestAPIResponceEndPointMessage), endpoint);

    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(getName(
      MessageEnum.RestAPIRequestMethodMessage),
      method);

      body && requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return requestMessage.messageId
  };



  

  handleApiSuccess = async(apiID:string, response: {accounts: Array<SellersList>} & {store_addresses: Array<StoreAddress>, linked_addresses: Array<AddressObject>} & {pending_linked_addresses: Array<LinkedStore>} & {countries:{countryCode:string,name:string}} & {states:{countryCode:string,name:string}} & {cities:string[]}) => {
    this.setState({
      loading: false
    });

    if(apiID === this.getVendingMachinesAPICallID || apiID ===  this.getVendingMachinesBySearchAPICallID){
      this.storeListofMachines(response);
    }
    if(apiID === this.getCountryListApiCallId){
      this.setCountryList(response);
    }
    if(apiID === this.getPickUpStateListApiCallId){
      this.setStateList(response);
      this.getCityList();
    }
    
    if(apiID === this.getPickUpCityListApiCallId){
      this.setCityList(response);
    }

    if(apiID === this.sellersApiID){
      this.setState({
        sellersList: response.accounts,
      });
    };

    if(apiID === this.linkStoreApiID){
      this.setState({
        searchSeller: "",
        sellerEmail: "",
        comment: ""
      });
      this.getAllStore();
    };

    if(apiID === this.addStoreAddressApiID){
      this.getAllStore();
    };

    if(apiID === this.allStoreApiID){
      const addressAll = response.store_addresses
      if (addressAll !== undefined) {
      this.setState({
        addressid: addressAll[0].id,
        addressOne: addressAll[0].address_line_1,
        addressTwo: addressAll[0].address_line_2,
        storeAddressPincode: addressAll[0].pin_code,
        selectedCountry: addressAll[0].country,
        selectedState: addressAll[0].state,
        selectedCity: addressAll[0].city,
        longitudinal: addressAll[0].longitude,
        latitudinal: addressAll[0].latitude
      })};
      this.setState({
        linkedStores: response.linked_addresses || "",
        addressDetails:response.store_addresses || ""
      });
    };

    if(apiID === this.deleteLinkedStoreApiID){
      this.getAllStore();
      this.setState({
        deleteModal: false
      })
    };

    if(apiID === this.deleteCurrentStoreApiID){
      this.getAllStore();
      this.setState({
        deleteAddressModal: false
      });
    };


    if(apiID === this.getPendingLinkedAddressApiID){
      if(response.pending_linked_addresses){
        this.setState({
          statusModal:  true,
          statusModalStoreDetails: response.pending_linked_addresses
        });
      };
    };

    if(apiID === this.postAcceptRequestApiID){
      this.getAllStore();
      this.setState({
        statusModal: false
      });
    };

    if(apiID === this.postRejectRequestApiID){
      this.getAllStore();
      this.setState({
        statusModal: false
      });
    };
  };

  handleApiFailure = (apiID:string, message: string) => {
    this.setState({
      loading: false
    })
    if(apiID === this.linkStoreApiID){
      this.setState({
        linkStoreModal: false,
        isPopupVisible: true,
        popupMessage: message,
        searchSeller: "",
        sellerEmail: "",
        comment: ""
      });
    };

    if(apiID === this.deleteLinkedStoreApiID){
      this.setState({
        isPopupVisible: true,
        popupMessage: message
      });
    };

    if(apiID === this.deleteCurrentStoreApiID){
      this.setState({
        isPopupVisible: true,
        popupMessage: message
      });
    };

    if(apiID === this.getPendingLinkedAddressApiID){
      this.setState({
        isPopupVisible: true,
        popupMessage: message
      });
    };

    if(apiID === this.postAcceptRequestApiID){
      this.setState({
        statusModal: false,
        isPopupVisible: true,
        popupMessage: message
      });
    };

    if(apiID === this.postRejectRequestApiID){
      this.setState({
        statusModal: false,
        isPopupVisible: true,
        popupMessage: message
      });
    };
  };


  getSellers = async() => {
    this.setState({
      loading: true
    });
    this.sellersApiID = await this.handleApi({
      method: configJSON.validationApiMethodType,
      endpoint: configJSON.getSellersEndPoint
    });
  };

  postLinkStore = async() => {
    if(this.state.searchSeller){
      const body = {
        email: this.state.sellerEmail,
        business_name: this.state.searchSeller,
        comment: this.state.comment
      };
      this.linkStoreApiID = await this.handleApi({
        method: configJSON.exampleAPiMethod,
        endpoint: configJSON.postLinkStoreEndPoint,
        body
      });
      this.setState({
        loading: true,
        linkStoreModal: false,
        nameError: false
      });
    } else{
      this.setState({
        nameError: true
      })
    }
  };

  getAllStore = async() => {
    this.setState({
      loading: true
    });
    this.allStoreApiID = await this.handleApi({
      method: configJSON.validationApiMethodType,
      endpoint: configJSON.getAllStoreEndPoint
    });
  };


  handleClickedStore = (businessName:string, email: string) => {
    this.setState({
      searchSeller: businessName,
      sellerEmail: email,
      isSellerListOpen: false
    });
  };

  deleteLinkedStore = async () => {
    this.setState({
      loading: true
    });
    this.deleteLinkedStoreApiID = await this.handleApi({
      method: configJSON.deleteMethod,
      endpoint: configJSON.deleteLinkedStoreEndPoint + this.state.deleteID
    });
  };

  getPendingLinkedAddress = async() => {
    this.setState({
      loading: true
    });
    this.getPendingLinkedAddressApiID = await this.handleApi({
      method: configJSON.validationApiMethodType,
      endpoint: configJSON.getPendingLinkedAddressEndPoint
    });
  };

  postAcceptRequest = async(storeID: number) => {
    this.setState({
      loading: true
    });
    const body = {
      id:storeID,
      status: "accepted"
    }
    this.postAcceptRequestApiID = await this.handleApi({
      method: configJSON.exampleAPiMethod,
      endpoint: configJSON.postAcceptRequestEndPoint,
      body
    });
  };

  postRejectRequest = async(storeID: number) => {
    this.setState({
      loading: true
    });
    const body = {
      id: storeID,
      status: "rejected"
    }
    this.postRejectRequestApiID = await this.handleApi({
      method: configJSON.exampleAPiMethod,
      endpoint: configJSON.postAcceptRequestEndPoint,
      body
    });
  };

  handleStatusModalOpen = () => {
    this.getPendingLinkedAddress();
  };

  handleStatusModalClose = () => {
    this.setState({
      statusModal: false
    });
  };

  closePopup = () => {
    this.setState({
      isPopupVisible: false
    });
  };

  handleClickOutside = (event:MouseEvent) => {
    if (this.dropdownRef.current && !this.dropdownRef.current.contains(event.target as Node)) {
      this.setState({
        isSellerListOpen: false
      });
    };
  };

  onFocusHandler = () => {
    this.setState({
      isSellerListOpen: true
    });
  };

  handleSearchSellerList = () => {
    return this.state.sellersList.filter((seller:SellersList) => seller.business_name.toLowerCase().includes(this.state.searchSeller.toLowerCase()) || seller.user_name.toLowerCase().includes(this.state.searchSeller.toLowerCase()));
  };

  handleDeleteModalOpen = (deleteID:number) => {
    this.setState({
      deleteModal: true,
      deleteID
    });
  };
  handleDeleteCurrentAddress = (deleteAddressID:number) => {
    this.setState({
      deleteAddressModal: true,
      deleteAddressID
    });
  };
  
  handleDeleteCurrentAddressClose = () => {
    this.setState({
      deleteAddressModal: false
    });
  }

  deleteCurrentStoreAddress = async () => {
    this.setState({
      loading: true
    });
    this.deleteCurrentStoreApiID = await this.handleApi({
      method: configJSON.deleteMethod,
      endpoint: `${configJSON.getAllStoreEndPoint}/${this.state.deleteAddressID}`
    });
    this.setState({
      addressid: null,
      addressOne: "",
      addressTwo: "",
      storeAddressPincode: null,
      selectedCountry: "",
      selectedState: "",
      selectedCity: "",
      longitudinal: null,
      latitudinal:null
    });
  };

  validateFormFields = () => {
    const { addressOne, addressTwo, storeAddressPincode, selectedCountry, selectedState, selectedCity } = this.state;
  
    if (!addressOne || !addressTwo || !storeAddressPincode || !selectedCountry || !selectedState || !selectedCity) {
      this.setState({ addressformError: 'Please fill all the fields.' });
      return false;
    }
    
    this.setState({ addressformError: '' }); // Clear the error if everything is filled
    return true;
  };
  

  handleDeleteModalClose = () => {
    this.setState({
      deleteModal: false
    });
  };

  renderComment = (comments: Array<{[key:number]: string}>) => {
    const latestCommentObj = comments.pop();
    if(latestCommentObj){
      return Object.values(latestCommentObj)[0];
    }
    return "";
  }

  postAddStoreAddress = async () => {
      if(this.validateFormFields()){
        let body = {
          address:{
          address_line_1: this.state.addressOne,
          address_line_2: this.state.addressTwo,
          pin_code: this.state.storeAddressPincode,
          city: this.state.selectedCity,
          state: this.state.selectedState,
          country: this.state.selectedCountry,
          latitude: this.state.latitudinal || "",
          longitude: this.state.longitudinal || "",
          }
        };
        if(this.state.addressDetails.length>0){
          this.addStoreAddressApiID = await this.handleApi({
            method: configJSON.exampleUpdateMethod,
            endpoint: `${configJSON.getAllStoreEndPoint}/${this.state.addressid}`,
            body
          });
        }else {
          this.addStoreAddressApiID = await this.handleApi({
          method: configJSON.exampleAPiMethod,
          endpoint: configJSON.getAllStoreEndPoint,
          body
        });
        }
        
        this.setState({
          loading: true,
          openAddStoreAddress:false,
          nameError: false
        });
      } else{
        this.setState({
          nameError: true
        })
      }
  };
  
  // Customizable Area End
}
