// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { WithStyles } from "@material-ui/core";
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
import { Message } from "../../../framework/src/Message";
export interface HasSuccessErrorTypeForInv {
    isOpen: boolean;
    isSeverity: 'success' | 'info' | 'warning' | 'error';
    isMessage: string;
  }
// Customizable Area End

export const configJSON = require("./config");
interface CheckedItems {
    [itemId: string]: boolean;
}
export interface Props extends WithStyles{
    navigation: any;
    id: string;
    // Customizable Area Start
    inventoryManagementList?:Array<any>
    handleResponseDelete:()=>void
    page:number
    handleChangePagination:(event:any,value:number)=>void
    perPageCount:number
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    checkedItems: CheckedItems;
    isSelectAllChecked: boolean
    openDeleteDialog:boolean;
    errorModal: boolean;
    hasSuccessErrorForInv: HasSuccessErrorTypeForInv;
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class InventoryListingController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    cataloguesDeleteId: string = ""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area End
        ];

        // Customizable Area Start
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
        // Customizable Area End

        this.state = {
            // Customizable Area Start
            checkedItems: {},
            isSelectAllChecked: false,
            openDeleteDialog:false,
            errorModal: false,
            hasSuccessErrorForInv: {
                isOpen: false,
                isSeverity: "success",
                isMessage: ""
              },
            // Customizable Area End
        };

    }


    // Customizable Area Start
    async componentDidMount() {
        removeStorageData("catalogueID")
      }
      async componentDidUpdate(prevProps: Props) {
        if (prevProps.page !== this.props.page) {
            this.setState({ checkedItems: {} });
        }
    }

    async receive(from: string, message: Message) {
        this.handleDeleteResponse(from, message)
    }
    errorModalClose = () => {
        this.setState({ errorModal: !this.state.errorModal })
    }
    handleDeleteResponse=(from: string, message: Message)=>{
        if (this.cataloguesDeleteId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
          const apiResponse = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
    
          if (apiResponse?.errors) {
            alert(apiResponse?.errors)
          } else if (Array.isArray(apiResponse.message) && apiResponse.message.length > 0) {
            this.snackBarOpenInv(true,"error",apiResponse.message[0])
          } else {
            this.props.handleResponseDelete()
            console.log(apiResponse?.message)
            this.errorModalClose();
          }
        }
        }
        
    handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, itemId: string) => {
        const { checkedItems } = this.state;
        this.setState({
            checkedItems: {
                ...checkedItems,
                [itemId]: event.target.checked,
            },
        });
    };

    handleSelectAllToggle = () => {
        const { inventoryManagementList } = this.props;
        const { checkedItems, isSelectAllChecked } = this.state;
        const newCheckedItems = { ...checkedItems };
    
        const updatedSelectAllState = !isSelectAllChecked;
    
        inventoryManagementList?.forEach(item => {
            newCheckedItems[item.id.toString()] = updatedSelectAllState;
        });
    
        this.setState({
            checkedItems: newCheckedItems,
            isSelectAllChecked: updatedSelectAllState
        });
    };


    handleDeleteSelected = async () => {
        const { checkedItems } = this.state;
        
         const ItemsTobeDeleted = [];
        for(const key in checkedItems) {
            if (checkedItems[key] === true) {
                ItemsTobeDeleted.push(Number(key));
            }
        }

        await this.handleDeleteAPI(ItemsTobeDeleted)
        const updatedCheckedItems = { ...checkedItems };
        ItemsTobeDeleted.forEach(key => {
            delete updatedCheckedItems[key];
        });
    
        this.setState({ checkedItems: updatedCheckedItems });
    };

    handleOpenDeleteDialog = () => {
        this.setState({ openDeleteDialog: true });
    };

    handleCloseDeleteDialog = () => {
        this.setState({ openDeleteDialog: false });
    };

    handleClickOfEachProduct =(itemId:string)=>{
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage),"ProductDetails");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        message.addData(getName(MessageEnum.NavigationScreenNameMessage), itemId);
        this.send(message)
    }

    handleDeleteSelectedItem = () => {
        this.handleDeleteSelected();
        this.handleCloseDeleteDialog();
    };



    handleDeleteAPI=async(ItemsTobeDeleted:Array<number>)=>{
        const singupLogin =  await getStorageData("singupLogin");
     
        const headers = {
            token: singupLogin,
            "content-type": configJSON.validationApiContentType,
          };
          
        const cataloguesDeleteMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.cataloguesDeleteId = cataloguesDeleteMsg.messageId;
      
        cataloguesDeleteMsg.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.deleteAPIEndpoint
        );
        cataloguesDeleteMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
          );
        cataloguesDeleteMsg.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.deleteMethodType
        );

        cataloguesDeleteMsg.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify({"ids":ItemsTobeDeleted})
        );
      
        runEngine.sendMessage(cataloguesDeleteMsg.id, cataloguesDeleteMsg);
    }

    handleAddVariant = () => {
        const { checkedItems } = this.state
        const catalogueId = Number(Object.keys(checkedItems).find(key => checkedItems[key]));
        setStorageData("addVariantCatalogueID", catalogueId)
        removeStorageData("editVariantCatalogueID")
        removeStorageData("editVarientID")
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(
            getName(MessageEnum.NavigationTargetMessage),
            "AddProductVariants"
        );
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message)
    }
    
    handleEditProduct = () => {
        const { checkedItems } = this.state
        const catalogueId = Number(Object.keys(checkedItems).find(key => checkedItems[key]));
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage),"EditProduct");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        message.addData(getName(MessageEnum.NavigationScreenNameMessage), catalogueId);
        this.send(message)
    }

    handleCloseSnack = () => {
        this.setState({
            hasSuccessErrorForInv: {
                isOpen: false,
                isSeverity: "success",
                isMessage: ""
            }
        });
    };

    snackBarOpenInv = (isOpen: boolean, isSeverity: 'success' | 'info' | 'warning' | 'error', message: string) => {
        this.setState({
            hasSuccessErrorForInv: {
                isOpen: isOpen,
                isSeverity: isSeverity,
                isMessage: message
            }
        });
    };
    // Customizable Area End
}