export const qrLogo = require("../assets/qrFlixooLogo.png");
export const tickIcon = require("../assets/tickIcon.png");
export const CloseIc = require('../assets/close.svg');
export const flxooLiveLogo  =  require('../assets/flixooLive.png');
export const downloadIcon  =  require('../assets/downloadIcon.png');
export const copy = require("../assets/copy.png");
export const qrCodeTop = require("../assets/qrcodeTop.png");
export const qrCodeBottom = require("../assets/qrcodeBottom.png");



