import React from "react";
// Customizable Area Start
import SellerForgotPasswordController, {
    Props
} from "./SellerForgotPasswordController.web";

import { withStyles, StyleRules } from "@material-ui/core/styles";
import {
    Box,
    Grid,
    Button
} from '@material-ui/core';
import { App_Logo, Wave } from "../../email-account-registration/src/assets";
import OtpInputField from "../../email-account-registration/src/OtpInputField";
import { FormBoxVerifyOTP, HeadingTypography, LeftGrid, LogoText, RightBox, RightGrid, StyledButton, SubHeadingTypography } from "../../../components/src/CustomComponentSellerForgotPassword.web";
import Loader from "../../../components/src/Loader.web";
import LoginFailModalWeb from "../../../components/src/LoginFailModal.web";
// Customizable Area End

const styles: StyleRules = {
    // Customizable Area Start
    root: {
        background: "#000",
        minHeight: "100vh"
    },
    firstWave: {
        position: 'absolute',
        bottom: 0,
        width: '100%'
    },
    secondWave: {
        position: 'absolute',
        bottom: 0,
        right: '12%',
        width: '100%'
    },
    resendBtn: {
        color: '#FFFFFF',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '18px',
        cursor: "pointer",
        textDecoration: 'underline',
        textTransform: 'none',
    },
    // Customizable Area End
}

export class SellerVerifyOtp extends SellerForgotPasswordController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;

        return (
            <Grid container className={classes.root}>
                <Loader loading={this.state.Loading} />

                <LeftGrid item md={5} >
                    <Box>
                        <img src={App_Logo} alt="2" />
                        <LogoText >Live shopping & market place</LogoText>
                    </Box>
                </LeftGrid>

                <RightGrid item md={7} >

                    <RightBox>
                        <HeadingTypography>Enter Verification Code</HeadingTypography>
                        <SubHeadingTypography>{this.state.isMobileSelected ? "Enter the OTP that was sent to your registered email address." : "Enter the OTP you got in your 10 digit mobile number"}</SubHeadingTypography>
                        <FormBoxVerifyOTP container>
                            <OtpInputField value={this.state.OtpState} valueLength={6} onChange={this.handleOTPState} />
                            <Button
                                className={classes.resendBtn}
                                onClick={this.resendOtp}
                            >
                                Re-send the code.
                            </Button>
                        </FormBoxVerifyOTP>
                        <StyledButton onClick={this.validateOTP}>Verify</StyledButton>
                    </RightBox>

                    <LoginFailModalWeb
                        displayPopup={this.state.ShowAlert}
                        closeErrorPopup={this.closeAlert}
                        titleMessage={'Error'}
                        errorMessage={this.state.errMessage} />
                </RightGrid>

                <img src={Wave} className={classes.firstWave} alt="wave2" />
                <img src={Wave} className={classes.secondWave} alt="wave2" />
            </Grid>
        )
        // Customizable Area End
    }
}

// Customizable Area Start
export default withStyles(styles)(SellerVerifyOtp);
// Customizable Area End