import React from "react";
// Customizable Area Start
import BuyerForgotPasswordController, {
    Props
} from "./BuyerForgotPasswordController.web";

import { withStyles, StyleRules } from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';
import { Grid, Box, Typography, Button } from '@material-ui/core';
import { App_Logo, Wave } from "../../email-account-registration/src/assets";
import { imgLeftFrame } from "./assets";
import { AlertBox, AlertHeadText, AlertText, FormBoxVerifyOTP, HeadingTypography, ImageSubBox, LeftGrid, OverlayBox, RightBox, RightGrid, SmallImageBox, StyledButton, SubHeadingTypography, TimerBox } from "../../../components/src/CustomComponentForgotPassword.web";
import OtpInputField from "../../email-account-registration/src/OtpInputField";
import Loader from "../../../components/src/Loader.web";
import LoginFailModalWeb from "../../../components/src/LoginFailModal.web";
// Customizable Area End

const styles: StyleRules = {
    // Customizable Area Start
    root: {
        background: "#000",
        minHeight: "100vh"
    },
    firstWave: {
        position: 'absolute',
        bottom: 0,
        width: '100%'
    },
    secondWave: {
        position: 'absolute',
        bottom: 0,
        right: '12%',
        width: '100%'
    },
    resendBtn: {
        color: 'white',
        fontSize: '20px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '16px',
        cursor: "pointer",
        opacity: "0.7",
        textDecoration: 'underline',
        textTransform: 'none',
        '&.MuiButton-root.Mui-disabled': {
            color: 'white',
            cursor: "default",
        }
    }
    // Customizable Area End
}

export class BuyerVerifyOtp extends BuyerForgotPasswordController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;

        return (
            <Grid container className={classes.root}>
                <Loader loading={this.state.loading} />
                <LeftGrid item md={5} >
                    <ImageSubBox>
                        <img src={App_Logo} style={{ maxWidth: '100%' }} alt="logo-img2" />
                        <SmallImageBox>
                            <img src={imgLeftFrame} style={{ maxWidth: '100%' }} alt="frame-img2" />
                        </SmallImageBox>
                    </ImageSubBox>
                </LeftGrid>
                <RightGrid item md={7} >
                    <RightBox>
                        <HeadingTypography>OTP</HeadingTypography>
                        <SubHeadingTypography>Please enter the OTP shared via email or SMS</SubHeadingTypography>
                        <FormBoxVerifyOTP container>
                            <OtpInputField value={this.state.otpState} valueLength={6} onChange={this.handleOTPState} />
                            <Box style={{ width: '100%' }}>
                                <TimerBox style={{ display: 'flex', gap: 10, alignItems: 'center', justifyContent: 'space-between' }}>
                                    <Typography style={{
                                        color: 'white',
                                        fontSize: '20px',
                                        fontStyle: 'normal',
                                        fontWeight: 600,
                                        lineHeight: '16px',
                                        opacity: "0.7"
                                    }}>00:{this.state.time < 10 && 0}{this.state.time}</Typography>
                                    <Button
                                        className={classes.resendBtn}
                                        onClick={this.resendOtp}
                                        disabled={this.state.time !== 0}
                                    >
                                        Resend the code
                                    </Button>
                                </TimerBox>
                            </Box>
                        </FormBoxVerifyOTP>
                        <StyledButton onClick={this.validateOTP}>Continue</StyledButton>
                    </RightBox>
                    <img src={Wave} className={classes.firstWave} alt="wave-img3" />
                    <img src={Wave} className={classes.secondWave} alt="wave-img3" />
                    <LoginFailModalWeb
                        displayPopup={this.state.showAlert}
                        closeErrorPopup={this.closeAlert}
                        titleMessage={'Error'}
                        errorMessage={this.state.errorMessage} />
                </RightGrid>
            </Grid>
        )
        // Customizable Area End
    }
}

// Customizable Area Start
export default withStyles(styles)(BuyerVerifyOtp);
// Customizable Area End
