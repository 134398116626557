import React from "react";

import {
    // Customizable Area Start
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Box,
    Typography
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { withStyles } from "@material-ui/core/styles";
import { downArrowIc, whiteAppLogo } from "./assets";
// Customizable Area End

import LandingPageControllerWeb, {
    Props,
} from "./LandingPageControllerWeb";

class FAQ extends LandingPageControllerWeb {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const { generalFaqData } = this.state;
        // Customizable Area End
        return (
            // Customizable Area Start
            <div className={classes.initialHeight}>
                <div id="myref"></div>
                <Box className={classes.termsConditionHeader}>
                    <Box>
                        <img style={{objectFit: 'contain'}} className={classes.termsConditionHeaderInner} src={whiteAppLogo} alt="" />
                    </Box>
                </Box>
                <Box className={classes.mainDiv}>
                    <Box className={classes.titleWrap}>
                        <Typography className={classes.title}>FAQs</Typography>
                    </Box>
                    <div className="accordion-wrap main-div">
                        {generalFaqData?.map((items: any) => (
                            <Accordion className="custom-accordion" key={items.id}>
                                <AccordionSummary className="accordion-head">
                                    <i className="arrow">
                                        <img src={downArrowIc} alt="Down Arrow" />
                                    </i>
                                    {items.title}
                                </AccordionSummary>
                                <AccordionDetails className="accordion-body">
                                    {items.content}
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </div>
                </Box>
            </div>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const styles = (theme: any) => ({
    initialHeight: {
        minHeight: '90vh'
    },
    mainDiv: {
        maxWidth: '1160px',
        width: '100%',
        padding: '30px 15px 65px',
        margin: '0 auto',
        '@media (max-width: 991px)': {
            padding: '15px 15px 65px',
        },
    },
    titleWrap: {
        margin: '0 0 10px'
    },
    title: {
        color: '#000',
        fontFamily: 'Poppins !important',
        fontSize: '24px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '16px',
        letterSpacing: '0.3px',
    },
    termsConditionHeader: {
        width: '100%',
        height: '100px',
        backgroundColor: '#000000',
    },
    termsConditionHeaderInner: {
        // objectFit: 'contain',
        marginLeft: '64px',
    }
});

export default withStyles(styles)(FAQ);
export { FAQ }
// Customizable Area End