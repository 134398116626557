import React from "react";

import {
    // Customizable Area Start
    Box,
    Container,
    Typography,
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});
// Customizable Area End

import LandingPageControllerWeb, {
    Props,
} from "./LandingPageControllerWeb";

class PerformaceCounter extends LandingPageControllerWeb {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const { performanceCounterData } = this.state
        // Customizable Area End
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <section className={`${classes.sectionCounter} ${'sectionPadding'}`}>
                    <Container maxWidth={"md"}>
                        <Box className={"counterWrapper"}>
                            <Box className={"counterInner"}>
                                <Box className={"counterBox"}>
                                    <Typography component={'p'}>{performanceCounterData.products_count}+</Typography>
                                    <span>Products</span>
                                </Box>
                                <Box className={"counterBox"}>
                                    <Typography component={'p'}>{performanceCounterData.seller_count}+</Typography>
                                    <span>Sellers </span>
                                </Box>
                                <Box className={"counterBox"}>
                                    <Typography component={'p'}>{performanceCounterData.live_stream_count}+</Typography>
                                    <span>Live Streams</span>
                                </Box>
                            </Box>
                        </Box>
                    </Container>
                </section>
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const styles = (theme: any) => ({
    sectionCounter: {
        backgroundColor: '#000000',
        padding: '52px',
        margin: '150px 0',
        [theme.breakpoints.down('sm')]: {
            padding: '20px',
            margin: '50px 0',
        },
        '& .counterInner': {
            display: 'flex',
            justifyContent: 'space-between',
            textAlign: 'center',
        },
        '& .counterBox': {
            '& p': {
                color: '#ffffff',
                fontSize: '44.97px',
                lineHeight: '53.96px',
                [theme.breakpoints.down('sm')]: {
                    fontSize: '30px'
                },
            },
            '& span': {
                fontFamily: 'Poppins-Medium',
                color: '#F5EA16',
                fontSize: '14.99px',
                lineHeight: '20.24px',
                marginTop: '8px',
            },
        },
    },
});

export default withStyles(styles)(PerformaceCounter);
export { PerformaceCounter }
// Customizable Area End
