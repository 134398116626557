export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const hamburgerIc = require("../assets/hamburger.svg");
export const calendarIc = require("../assets/calendar.svg");
export const notificationIc = require("../assets/notification.svg");
export const notificationRedDotIc = require("../assets/notification-red-dot.svg");
export const auction = require("../assets/auction.svg");
export const instant = require("../assets/instant.svg");
export const post = require("../assets/post.svg");
export const schedule = require("../assets/schedule.svg");
export const story = require("../assets/story.svg");
export const test = require("../assets/test.svg");
export const searchIcon  = require("../assets/searchIcon.png")
export const backGroundImage = require("../assets/backGroundImage.png")
export const timerDemo = require("../assets/timerDemo.png")
export const profileImage = require("../assets/profileImage.png")
export const noProfileImage = require("../assets/profile_icon.png")
export const archiveIc = require("../assets/archive.svg")
export const archiveBlankIc = require("../assets/archiveBlank.svg")
export const rightArrow = require("../assets/right-arrow.svg")
export const rightWithCircle = require("../assets/right-with-circle.svg")
export const star = require('../assets/star.svg')
export const tShirt = require('../assets/t-shirt.png')
export const facebookIC = require('../assets/facebook.svg')
export const whatsappIC = require('../assets/whatsapp.svg')
export const twitterIC = require('../assets/twitter.svg')
export const threeDotIC = require('../assets/three-dot.svg')
export const chatIc = require('../assets/chat.png')
export const shareIC = require('../assets/share.svg')
export const sendIC = require('../assets/send.svg')
export const CloseIc = require('../assets/close.svg')
export const NotifyIc = require('../assets/notify.svg')
export const hamburgerIcon = require('../assets/hamburger.svg')
export const backButton  = require('../assets/backButton.png')
export const uploadIcon = require("../assets/uploadIcon.png");
export const crossIcon = require("../assets/crossIcon.png");
export const tickIcon = require("../assets/tickIcon.png");
export const removeTagIcon = require("../assets/removeTagIcon.png");
export const infoIcon = require("../assets/info.png");
export const searchModalIcon = require("../assets/search.png");
export const inventoryImg = require("../assets/mic.png");
export const minusImg = require("../assets/minus.png");
export const plusImg = require("../assets/plus.png");
export const filterImg = require("../assets/filter.png");
export const plusSquareImg = require("../assets/plusSquare.png");
export const filledCheckImg = require("../assets/filledCheck.png");
export const liveImg = require("../assets/live.png");
export const liveEyeImg = require("../assets/whiteEye.png");
export const liveClockImg = require("../assets/liveClock.png");
export const liveDotsImg = require("../assets/liveDots.png");
export const liveMicImg = require("../assets/liveMic.png");
export const liveVideoImg = require("../assets/liveVideo.png");
export const livePauseImg = require("../assets/livePause.png");
export const liveFullScreenImg = require("../assets/liveFullScreen.png");
export const liveEndImg = require("../assets/liveEnd.png");
export const plusNormalImg = require("../assets/plusNormal.png");
export const deleteImg = require("../assets/delete.png");
export const editImg = require("../assets/edit.png");
export const liveChatEmogImg = require("../assets/liveChatEmog.png");
export const disableMinusImg = require("../assets/disableMinus.png");
export const checkImg = require("../assets/check.png");
export const rectangleUserImg = require("../assets/Rectangle 787.png");











