import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  Grid,
  IconButton,
  Typography
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { hamburgerIc } from "../../InventoryManagement3/src/assets";
import { helpAndSupportImage, messageIcon } from "./assets";
import SuccessDialog from "../../../components/src/SuccesDailogMessage.web"

export const webStyles = {
  settingsInnerContainer: {
    paddingTop: "1%",
    width: "100%",
  },
  hideSideHamburgerSection: {
    display: "flex",
    justifyContent: "end"
  },
  hideHamburger: {
    position: "absolute" as const,
    top: "10px",
    right: "10px"
  },
  [`@media (min-width: 992px)`]: {
    hideHamburger: {
      display: 'none' as const,
      position: "absolute" as const,
      top: "10px",
      right: "10px"
    },
  },
  settingsMainContainer: {
    margin: "3em 0em 0em",
    display: "flex",
    '@media (min-width: 1399px)': {
      margin: "4em 0em 0em",
    }
  },

  helpAndSupportForm: {
    boxSizing: "border-box" as const,
    height: "255px",
    backgroundImage: `url(${helpAndSupportImage})`,
    display: "flex",
    justifyContent: "center",
    position: "relative" as const
  },
  outerWrapper: {
    display: "flex",
    justifyContent: "space-between",
    '@media (max-width: 740px)': {
      display: "block" as const
    }
  },
  HelpHeadingWrapper: {
    fontFamily: 'Poppins !important',
    color: "white",
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "center",
    alignItems: "center" as const,
    width: "370px",
    textAlign: "center" as const
  },
  HelpAndSupportHeading: {
    fontSize: "32px",
    fontWeight: 600,
    lineHeight: "48px"
  },
  HelpReadTandC: {
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px"
  },
  chatGrid: {
    fontFamily: 'Poppins !important',
    color: "#000000",
    fontSize: "32px",
    fontWeight: 600,
    lineHeight: "48px",
    padding: "50px",
    margin: "40px 0",
    '@media (max-width: 740px)': {
      padding: "30px",
      margin: "0"
    }
  },
  chatContainer: {
    fontFamily: 'Poppins !important',
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "30px",
    marginBottom: "20px"
  },
  chatWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "0.68px solid #DCDCDC",
    borderRadius: "10px",
    padding: "25px 50px",
    maxWidth: "435px"
  },
  chatWidUsHeading: {
    fontFamily: 'Poppins !important',
    color: "#000000",
    fontSize: "19px",
    fontWeight: 600,
    lineHeight: "28px",
    paddingLeft: "30px"
  },
  inputFieldWrapper: {
    margin: "10px 0"
  },
  inputField: {
    border: "0.5px solid #DCDCDC",
    borderRadius: "8px",
    height: "36px",
    width: "270px",
    fontFamily: 'Poppins !important',
    paddingLeft: "10px",
    "&::placeholder": {
      fontSize: "12px",
      fontWeight: 400,
      lineHeight: "18px"
    },
    "&:focus": {
      outline: "0.5px solid #DCDCDC"
    }
  },
  errorText: {
    color: "red",
    fontSize: "11px",
    marginTop: "5px",
  },
  textAreaInput: {
    maxHeight: "200px",
    minHeight: "75px",
    resize: "none" as const,
    width: "270px",
    border: "0.5px solid #DCDCDC",
    borderRadius: "8px",
    fontFamily: 'Poppins !important',
    paddingLeft: "10px",
    paddingTop: "5px",
    "&::placeholder": {
      fontSize: "12px",
      fontWeight: 400,
      lineHeight: "18px"
    },
    "&:focus": {
      outline: "0.5px solid #DCDCDC",
    },
  },
  buttonFieldWrapper: {
    marginTop: "30px"
  },
  submitBtn: {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "18px",
    fontFamily: 'Poppins !important',
    display: 'inline-flex' as const,
    padding: '10px 20px',
    alignItems: 'flex-start' as const,
    gap: '10px',
    borderRadius: '20px',
    background: '#F5EA16',
    textTransform: "none" as const,
    cursor: "pointer",
    "&:hover": {
      background: '#F5EA16',
    }
  },
  verticalBorder: {
    borderLeft: "1px solid #DCDCDC",
    height: "355px",
    width: "auto",
    margin: "65px 0",
    '@media (max-width: 740px)': {
      display: "none" as const
    }
  },
};
// Customizable Area End
import SellerSidebar from "../../landingpage/src/SellerSidebar.web";
import HelpAndSupportFormController, { configJSON } from "./HelpAndSupportFormController.web";

class HelpAndSupportForm extends HelpAndSupportFormController {
  // Customizable Area Start
  // Customizable Area End

  render() {
    const { classes } = this.props;
    return (
      // Customizable Area Start
      <Box data-test-id="mainContainer" className={classes.settingsMainContainer}>
        <div className="dashboard-outer">
          <Box className="dashboard-inner">
            <SellerSidebar
              activetabName="Settings"
              id={""}
              navigation={""}
              data-test-id="sellerSidebar"
              isOpen={this.state.isOpen}
              closeModal={this.toggleSideBar}
              classes={undefined}
            />
          </Box>
        </div>

        <Box className={classes.settingsInnerContainer}>
          <Box className={classes.helpAndSupportForm}>
            <IconButton
              className={`${classes.hideHamburger}`}
              data-test-id="hamburger-btn"
              onClick={() => this.toggleSideBar()}
            >
              <img src={hamburgerIc} alt="hamburger" />
            </IconButton>
            <Box className={classes.HelpHeadingWrapper}>
              <Typography className={classes.HelpAndSupportHeading}>
                {configJSON.HelpAndSupport}
              </Typography>
              <Typography className={classes.HelpReadTandC}>
                {configJSON.HelpSupportreadTandC}
              </Typography>
            </Box>
          </Box>
          <Box className={classes.outerWrapper}>
            <Grid item xs={12} md={6} lg={6} className={classes.chatGrid}>
              <Typography className={classes.chatContainer}>
                {configJSON.chat}
              </Typography>
              <Box className={classes.chatWrapper}>
                <img src={messageIcon} alt="image" width={63} height={54} />
                <Box className={classes.chatWidUsHeading}>{configJSON.chatWithUs}</Box>
              </Box>
            </Grid>

            <Box className={classes.verticalBorder} />

            <Grid item xs={12} md={6} lg={6} className={classes.chatGrid}>
              <Typography className={classes.chatContainer}>
                {configJSON.writeToUs}
              </Typography>
              <Box className={classes.inputFieldWrapper}>
                <input
                  className={classes.inputField}
                  data-test-id="inputName"
                  placeholder="Your name"
                  value={this.state.sellerName}
                  onChange={this.handleSellerName}
                />
                {this.state.nameError && <Typography className={classes.errorText}>{this.state.nameError}</Typography>}
              </Box>
              <Box className={classes.inputFieldWrapper}>
                <input
                  className={classes.inputField}
                  data-test-id="inputEmail"
                  placeholder="Your email"
                  value={this.state.sellerEmail}
                  onChange={this.handleSellerEmail}
                />
                {this.state.emailError && <Typography className={classes.errorText}>{this.state.emailError}</Typography>}
              </Box>
              <Box className={classes.inputFieldWrapper}>
                <textarea
                  name="Text1"
                  data-test-id="inputComment"
                  placeholder="Write to us something"
                  className={classes.textAreaInput}
                  value={this.state.comment}
                  onChange={this.handleSellerComment}
                />
                {this.state.commentError && <Typography className={classes.errorText}>{this.state.commentError}</Typography>}
              </Box>
              <Box className={classes.buttonFieldWrapper}>
                <Button data-test-id="GetInTouchBtn" onClick={this.handleSubmitForm} className={classes.submitBtn}>{configJSON.submit}</Button>
              </Box>
            </Grid>
          </Box>
        </Box>

        <SuccessDialog
          data-test-id="sucessMessage"
          open={this.state.sucessModal}
          closeFn={this.sucessMessageClose}
          successTitle={configJSON.storyHeading+` ${this.state?.helpAndSupportRes?.help_and_support?.name}`}
          successMessage={configJSON.storyUploaded}
        />
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withStyles(webStyles)(HelpAndSupportForm)
export { HelpAndSupportForm }
// Customizable Area End
