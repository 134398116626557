import React from "react";

import {
    // Customizable Area Start
    Box,
    Container,
    Typography,
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});
// Customizable Area End

import LandingPageControllerWeb, {
    Props,
} from "./LandingPageControllerWeb";

class AboutPlateform extends LandingPageControllerWeb {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const { aboutPlateformData } = this.state;
        console.log("aboutPlateformData--->>", aboutPlateformData)
        // Customizable Area End
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <section className={`${classes.sectionAboutPlateform} ${'sectionPadding'}`}>
                    <Container maxWidth={'lg'}>
                        <Box className={classes.AboutPlateformWrapper}>
                            <Box className={classes.AboutPlateformInner}>
                                <Box className={classes.AboutPlateformTop}>
                                    <Typography className={classes.sectionHeading} variant={'h1'} component={'h1'}>{'About the platform'}</Typography>
                                    <Box className={classes.boxWrapper}>
                                        <Box className={classes.boxColumn}>
                                            {aboutPlateformData?.map((items: any, index: number) => (
                                                <Box className={classes.innerBoxWrapper} key={index}>
                                                    <Box className={'imgSide innerBoxColumn'} >
                                                        <img src={items.attributes.image} alt="" />
                                                    </Box>
                                                    <Box sx={{ display: 'flex', flexDirection: 'column', gridGap: '20px' }} className={'contentSide innerBoxColumn'}>
                                                        <Box className={'YellowBorder'}></Box>
                                                        <Typography variant={'h1'} component={'h1'}>
                                                            {items.attributes.title}
                                                        </Typography>
                                                        <Typography component={'p'}>
                                                            {items.attributes.description}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            ))}
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Container>
                </section>
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const styles = (theme: any) => ({
    sectionAboutPlateform: {
        paddingBottom: '80px',
        [theme.breakpoints.down('md')]: {
            paddingBottom: '0px',
        },
    },
    boxColumn: {
        display: 'flex',
        flexDirection: 'column' as const,
        gridGap: '100px',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'row',
            justifyContent: 'center',
            gridGap: '50px',
        },
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    innerBoxWrapper: {
        display: 'flex',
        flexWrap: 'wrap' as const,
        justifyContent: 'space-between',
        alignItems: 'center',
        gridGap: '20px',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center',
        },
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
        '&:last-child': {
            flexDirection: 'row-reverse',
            [theme.breakpoints.down('md')]: {
                flexDirection: 'row',
                justifyContent: 'center',
            },
            '& .YellowBorder': {
                display: 'none',
            },
            '& p': {
                maxWidth: '475px',
                [theme.breakpoints.down('md')]: {
                    maxWidth: '100%',
                },
            }
        },
        '& .innerBoxColumn': {
            maxWidth: '50%',
            [theme.breakpoints.down('sm')]: {
                maxWidth: '100%',
            },
            '& img': {
                maxWidth: '100%',
            },
        },
        '& .contentSide': {
            maxWidth: '571px',
            '& .YellowBorder': {
                width: '162px',
                height: '14px',
                backgroundColor: '#F5EA16',
            },
            '& h1': {
                fontSize: '64px',
                lineHeight: '72px',
            },
            '& p': {
                fontFamily: 'Poppins-Regular',
                fontSize: '18px',
                lineHeight: '27px',
                letterSpacing: '0.25px',
            },
        },
    },
    sectionHeading: {
        fontSize: '64px',
        lineHeight: '72px',
        textAlign: 'center' as const,
        marginBottom: '75px',
    },
});

export default withStyles(styles)(AboutPlateform);
export { AboutPlateform }
// Customizable Area End
