Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.loginAPiEndPoint = "login/login";
exports.loginAPiMethod = "POST";
exports.loginApiContentType = "application/json";

// Customizable Area Start
exports.errorEmailNotValid = "Email not valid.";
exports.errorPasswordNotValid = "Password not valid.";
exports.placeHolderEmail = "John_#110";
exports.placeHolderPassword = "Enter your password";
exports.labelHeader = "The reason we require login";
exports.btnTxtLogin = "LOG IN";
exports.labelRememberMe = "Remember me";
exports.btnTxtSocialLogin = "LOGIN WITH SOCIAL MEDIA";
exports.labelOr = "OR";
exports.labelTitle = "Log in";
exports.sellerLoginApiUrl="bx_block_login/logins/seller_login"
exports.sellerLoginMobileApi = "bx_block_login/logins/phone_login"
exports.sellerLoginMobileOtpApi = "bx_block_login/logins/verify_otp"

exports.imgPasswordInVisiblePath = "assets/ic_password_invisible.png";
exports.imgPasswordVisiblePath = "assets/ic_password_visible.png";
exports.googleLoginEndPoint = "bx_block_login/logins/google_login"
exports.buyerProfileAPIEndpoint = "account_block/get_buyer_profile"

exports.apiEndPointGetCountryCodes = "account/accounts/country_code_and_flag";
exports.apiGetCountryCodesType = "GET";

exports.phoneBalnkError = "Enter mobile number";
exports.phoneError = "Please Enter Valid Mobile number";
exports.otpError = "Please Enter Valid OTP";
exports.sellerEmailUserBalnkError = "Enter Username/Email id";
exports.sellerEmailUserError = "Please enter valid username or valid email";
exports.sellerPasswordBalnkError = "Enter Password";
exports.sellerPasswordError = "Password must contain 1 Uppercase, 1 Lowercase, 1 number & 1 special character.";

exports.resendotpEndpointEmail="send_email_otp";
exports.googleLoginApiEndPoint = "/account_block/google_login_or_signup"

exports.contentTypeApiAddDetail = "application/json";
exports.apiMethodTypeAddDetail = "POST";
exports.resendotpEndpointMobile="account/accounts/send_otp"
exports.orText = "OR"
// Customizable Area End