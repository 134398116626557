import React from 'react'
import { useGoogleLogin, GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import { googleLogo } from '../../blocks/email-account-login/src/assets';

interface Props {
    onSuccess:Function;
}

const GoogleButton = (props: Props) => {

    const login = useGoogleLogin({
        onSuccess: async (tokenResponse:any) => {
            console.log(tokenResponse, 'response from Google'); // Logs the token response
            const accessToken = tokenResponse.access_token;
    
            // Fetch user info from Google using the access token
            const userInfoResponse = await fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
    
            const userInfo = await userInfoResponse.json();
            props.onSuccess(userInfo)
            console.log(userInfo, 'User info fetched with access token');
        },
        onError: () => {
            console.log('Login Failed');
        }
    });

  return (
          <div style={classes.socialSignInLogo}>
              <img data-test-id="connectGoogle" onClick={() => login()} src={googleLogo} alt="Google_Logo" />
          </div>
  )
}


const classes = {
    socialSignInLogo: {
        padding: '8px 20px',
        border: '1px solid #808080',
        borderRadius: '15px',
        cursor: 'pointer'
    },
}

export default GoogleButton