export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const imgSearch = require("../assets/search-normal.png");
export const summy = require("../assets/dumy.png");
export const imgCancelOrder = require("../assets/cancelOrder.png");
export const imgShippingClock = require("../assets/shippingClock.png");
export const imgOnHoldClock = require("../assets/onHoldClock.png");
export const imgReadyTruck = require("../assets/readyTruck.png");
export const imgShipedtruck = require("../assets/shippedTruck.png");
export const cancelTicked = require("../assets/cancelTicked.png");



export const searchIc = require("../assets/search-black.svg");
export const calendarIc = require("../assets/calendar.svg");
export const notificationIc = require("../assets/notification.svg");
export const notificationRedDotIc = require("../assets/notification-red-dot.svg");
export const bookIc = require("../assets/book.png");
export const cancelledIc = require("../assets/cancelled.svg");
export const deliveredIc = require("../assets/delivered.svg");
export const dispatchedIc = require("../assets/dispatched.svg");
export const returnedIc = require("../assets/returned.svg");
export const sucessImageIcon  = require("../assets/sucessImageIcon.png")

export const timerImg = require("../assets/timer.png");