import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import React from "react";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import * as htmlToImage from 'html-to-image';
import download from 'downloadjs';
export const configJSON = require("./config");
export const frameworkConfigJSON = require("../../../framework/src/config");
const navigation = require("react-navigation")

interface Role {
    id: number;
    name: string;
    created_at: string;
    updated_at: string;
    page_names: string[];
  }
  
  interface Account {
    id: string;
    type: string;
    attributes: {
      activated: boolean;
      qr_generated: boolean;
      country_code: string;
      email: string;
      upi_id: string;
      full_name: string;
      user_name: string;
      business_popup: boolean;
      full_phone_number: string;
      phone_number: string;
      type: string | null;
      created_at: string;
      updated_at: string;
      device_id: string;
      unique_auth_id: string;
      role: Role;
      is_disabled: boolean;
      my_bio: string;
      updated_mobile_number: string;
      updated_email: string;
      same_drop_off: boolean;
      shipment_type: string;
      business_name: string;
      business_type: string;
      profile_photo: string;
      background_photo: string;
      qr_code: string;
    };
  }
  
// Customizable Area End

export interface Props {
  navigation: typeof navigation;
  id: string;
  // Customizable Area Start
  onQrButtonClick: () => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  profileQRModal: boolean;
  sellerProfileData: Partial<Account>;
  tooltipCopyUrl:boolean;
  imageError: string;
  errorModal: boolean;
  isGenerate: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ProfileQRCodeController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  sellerProfileDataApiId:string = "";
  qrRef: React.RefObject<HTMLDivElement>;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      profileQRModal: false,
      sellerProfileData: {},
      tooltipCopyUrl:false,
      imageError: "",
      errorModal: false,
      isGenerate: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
        this.qrRef = React.createRef();
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    this.getSellerProfileData()
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        const apiRequestCallId = message.getData( getName(MessageEnum.RestAPIResponceDataMessage) );
        let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        if(apiRequestCallId === this.sellerProfileDataApiId){
            if (responseJson && responseJson.account && responseJson.account.data) {
                this.setState({
                  sellerProfileData: responseJson.account.data,
                  isGenerate: false
                })
              }
        }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidUpdate(prevProps: Props, prevState:S) {
    if (prevState.isGenerate !== this.state.isGenerate && this.state.isGenerate === true) {
      this.getSellerProfileData()
    }
  }

  getSellerProfileData = async () => {
    this.props.onQrButtonClick();
    const sellertoken = await getStorageData("singupLogin");
    if (sellertoken) {
      const header = {
        "token": sellertoken,
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.sellerProfileDataApiId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.sellerProfileDataAPIEndpoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  handleOpenProfileQRModal = () => {
    this.setState({
        profileQRModal: true
    });
  };

  handleCloseProfileQRModal = () => {
    this.setState({
        profileQRModal: false
    });
  };

  handleCopyURL = (value :string) => {
    navigator.clipboard.writeText(value);
    this.setState({tooltipCopyUrl:true})
    setTimeout(() => {
      this.setState({tooltipCopyUrl:false})
    }, 2000);
};

handledownloadButton=()=>{
    const qrElement = this.qrRef?.current;
    if(qrElement){
        htmlToImage.toPng(qrElement)
          .then((dataUrl: string | File | Blob | Uint8Array) => {
            download(dataUrl, 'flixoo-qr-code.png');
          })
          .catch((error:unknown) => {
            this.setState({
              imageError: configJSON.someThingWentWrong,
              errorModal: true
            });
          });
    } else{
        this.setState({
            imageError: configJSON.someThingWentWrong,
            errorModal: true
          });
    }
  };

  renderDynamicText = () => {
    if(this.state.isGenerate) return configJSON.generating;
    return this.state.sellerProfileData?.attributes?.qr_generated ? configJSON.view : configJSON.generate
  }
  // Customizable Area End
}