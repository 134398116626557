import React from "react";
import {
    // Customizable Area Start
    Container,
    Box,
    Typography,
    Modal,
    Button,
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import {
    leftArrow,
    FlixooLogo
} from "./assets";
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import { Message } from "../../framework/src/Message";
import { BlockComponent } from "../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../framework/src/RunEngine";
import EffectiveDateFormat from "./EffectiveDateFormat.web";

const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});


export interface Props {
    classes?: any;
    policyOrTermsData?: any
    policyOrTermsOpen: boolean
    handleClose?: ()=>void;
    navigation?: any;
  }

  interface S {
  }
  
  interface SS {
  }



// Customizable Area End

class PolicyAndTerms extends BlockComponent<
Props,
S,
SS
>{
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
        this.subScribedMessages = [ 
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
        ];
        // Customizable Area End
    }

    // Customizable Area Start
   
    handleNavigation=()=>{
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage),"SellerDashboard");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message)
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        // Customizable Area End
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <Modal
                    open={this.props.policyOrTermsOpen}
                    onClose={this.props.handleClose}
                    className={classes.sectionTermsAndCondtion}
                >
                    <Box>
                        <Box className={classes.termsConditionHeader}>
                            <Box className={classes.termsConditionHeaderInner} onClick={this.handleNavigation}>
                                <img src={FlixooLogo} alt="" />
                            </Box>
                        </Box>
                        <Box>
                            <Box className={classes.leftArrowInner}>
                                <Button onClick={this.props.handleClose} data-test-id="closeButton">
                                    <img src={leftArrow} alt="leftArrow" />
                                </Button>
                            </Box>
                        </Box>
                        <Container maxWidth="xl">
                        <h4 className={classes.policyOrTermsHeading}>{this.props.policyOrTermsData.heading}</h4>
                            {this.props.policyOrTermsData?.effective_date && (
                                <Box className={classes.effectiveDate}>
                                    <Typography>
                                        <strong>Effective Date:</strong>
                                    </Typography>
                                    <Typography style={{ marginLeft: '5px', marginTop: '-3px' }}><strong><EffectiveDateFormat content={this.props.policyOrTermsData?.effective_date} /></strong></Typography>
                                </Box>)}
                            <Box className={classes.termsAndCondtiondata}>
                                <p dangerouslySetInnerHTML={{
                                    __html: this.props.policyOrTermsData.description
                                }}></p>
                            </Box>
                        </Container>
                    </Box>
                </Modal>
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const styles = (theme: any) => ({
    sectionTermsAndCondtion: {
        minHeight: '100vh',
        overflowY: 'scroll' as const,
        '& > div': {
            backgroundColor: '#ffffff',
        },
    },
   leftArrowInner: {
            width: '56px',
            height: '56px',
            display: 'flex',
            border: '1px solid rgba(0, 0, 0, .3)',
            borderRadius: '50%',
            margin: '70px 70px 50px 70px',
            [theme.breakpoints.down('xs')]: {
                marginLeft: '30px',
            },
            '& img': {
                marginLeft: '-11px',
            },
            '& a': {
                margin: 'auto'
            },
    },
    termsConditionHeader: {
        width: '100%',
        height: '100px',
        backgroundColor: '#000000',
       
    },
    termsConditionHeaderInner: {
        '& img': {
            objectFit: 'contain' as const,
            marginLeft: '64px',
            [theme.breakpoints.down('xs')]: {
                marginLeft: '23px',
            },
        },
        cursor:"pointer"
    },
    effectiveDate:{
        marginLeft:"5.5em",
        color: '#000',
        display: 'flex',
        fontFamily: 'Poppins !importantt',
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal'
    },
    termsAndCondtiondata: {
        padding: '0px 100px 100px 100px',
        [theme.breakpoints.down('sm')]: {
            padding: '0px 50px 50px 50px',
        },
        [theme.breakpoints.down('xs')]: {
            padding: '0px 18px 20px 18px',
        },
        '& p': {
            fontSize: '18px',
            lineHeight: '27px',
        },
    },
    policyOrTermsHeading: {
        paddingLeft: '100px',
        fontSize: '20px',
        lineHeight: '30px',
        fontWeight: 600,
        [theme.breakpoints.down('sm')]: {
            paddingLeft: '50px',
        },
        [theme.breakpoints.down('xs')]: {
            paddingLeft: '20px',
        },
    },
});

export default withStyles(styles)(PolicyAndTerms);
// Customizable Area End
