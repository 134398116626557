import React from "react";
import {
  // Customizable Area Start
  IconButton,
  Button,
  Box,
  styled
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import {
  closeIc,
  dashboardIc,
  dashboardGrayIc,
  createIc,
  createGrayIc,
  feedIc,
  feedGrayIc,
  ordersIc,
  ordersGrayIc,
  inventoryIc,
  inventoryGrayIc,
  messageIc,
  messageGrayIc,
  paymentIc,
  paymentGrayIc,
  settingIc,
 settingGrayIc,
} from "./assets";
import Avatar from '@material-ui/core/Avatar';
import { Link } from "react-router-dom";
// Customizable Area End

import LandingPageControllerWeb, { Props } from "./LandingPageControllerWeb";
import StayTuned from "../../../components/src/StayTuned.web";

class SellerSidebar extends LandingPageControllerWeb {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  Sidebar = [
    {
      id: 1,
      title: "Dashboard",
      iconDefault: dashboardIc,
      iconActive: dashboardGrayIc,
      tabName: 'dashboard',
      toNavigate: "/SellerDashboard"
    },
    {
      id: 2,
      title: "Create",
      iconDefault: createIc,
      iconActive: createGrayIc,
      tabName: 'create',
      toNavigate: "/LiveStreaming"
    },
    {
      id: 3,
      title: "Feed",
      iconDefault: feedIc,
      iconActive: feedGrayIc,
      tabName: 'feed',
      toNavigate: "/SellerFeed"
    },
    {
      id: 4,
      title: "Orders",
      iconDefault: ordersIc,
      iconActive: ordersGrayIc,
      tabName: 'orders',
      toNavigate: "/SellerOrderList"
    },
    {
      id: 5,
      title: "Inventory",
      iconDefault: inventoryIc,
      iconActive: inventoryGrayIc,
      tabName: 'inventory',
      toNavigate: "/InventoryManagement"
    },
    {
      id: 6,
      title: "Messages",
      iconDefault: messageIc,
      iconActive: messageGrayIc,
      tabName: 'messages',
      toNavigate: "/SellerChat"
    },
    {
      id: 7,
      title: "Payments",
      iconDefault: paymentIc,
      iconActive: paymentGrayIc,
      tabName: 'payments',
      toNavigate: "/SellerDashboard"
    },
    {
      id: 8,
      title: "Settings",
      iconDefault: settingIc,
      iconActive: settingGrayIc,
      tabName: 'Settings',
      toNavigate: "/Settings"
    },
  ]

  handleSidebarMenu = (item: any) => {
    return (
       item.tabName === "payments" || item.tabName === "settings"
        ?
        <Button onClick={this.openStayTunedBox} data-test-id={`sidebar-${item.tabName}`} className={`list-item-link ${item.tabName == this.props.activetabName ? "active" : ""}`}>
          <i>
            {item.tabName == this.props.activetabName
              ? <img src={item.iconActive} alt={item.tabName} className="on-active" />
              : <img src={item.iconDefault} alt={item.tabName} className="default" />
            }
          </i>
          {item.title}
        </Button>
        :
        <Link to={item?.toNavigate ?? "/"} className={`list-item-link ${item.tabName == this.props.activetabName ? "active" : ""}`}>
          <i>
            {item.tabName == this.props.activetabName
              ? <img src={item.iconActive} alt={item.tabName} className="on-active" />
              : <img src={item.iconDefault} alt={item.tabName} className="default" />
            }
          </i>
          {item.title}
        </Link>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <div className={this.props.isOpen ? 'dashboard-sidebar' : 'dashboard-sidebar sidebar-open'}>
        <div className="sidebar-inner">
          <IconButton className="close-btn" onClick={this.props.closeModal}>
            <img src={closeIc} alt="close" />
          </IconButton>
          <div className="detail-wrap">
            <div className="img-wrap">
              <Link to="/SellerProfile" className="avatar-link">
                <Avatar className={`avatar-img ${this.props.classes}`}>
                  {this.state.sellerProfileName.charAt(0).toUpperCase()}
                </Avatar>
              </Link>
            </div>
            <div className="detail">
              <span className="title">Good Morning</span>
              <Link to="/SellerProfile" className="name">{this.state.sellerProfileName}</Link>
            </div>
          </div>
          <ListWrap className="list-wrap">
            <ul className="list-inner">
              {this.Sidebar.map((item: any) => {
                return (
                  <li className="list-item" key={item.id}>
                    {this.handleSidebarMenu(item)}
                  </li>
                )
              })}
            </ul>
          </ListWrap>
        </div>
        <StayTuned data-test-id="stayTuneClose" openTunedBox={this.state.openTunedBox} closeTunedBox={this.closeTunedBox} />
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const ListWrap = styled(Box)({
  "& a":{
    width: "max-content"
  },
  "& a:hover":{
    backgroundColor:"rgba(0, 0, 0, 0.04)"
  },
  "& a.active:hover":{
    backgroundColor:"unset"
  }
})
export default SellerSidebar;
// Customizable Area End
