import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string,
  faq: any,
  isVisible: boolean,
  activeId: number,
  activeTitle: string,
  activeContent: string,
  activeCreatedAt: string,
  activeUpdatedAt: string,
  title: string,
  content: string,
  value: any,
  generalFaqData: any;
  BuyerFaqData: {
    buyer: {
      id: number,
      title: string,
      content: string,
      created_at: string,
      updated_at: string,
      faq_for: string
    }[],
    popular_question: {
      id: number,
      title: string,
      content: string,
      created_at: string,
      updated_at: string,
      faq_for: string
    }[],
    general_for_buyer: {
      id: number,
      title: string,
      content: string,
      created_at: string,
      updated_at: string,
      faq_for: string
    }[],
    safety_and_guidline: {
      id: number,
      title: string,
      content: string,
      created_at: string,
      updated_at: string,
      faq_for: string
    }[]

  },
  openAccordions: any;
  userName: string;
  userEmail: string;
  description: string;
  selectedAccor: string;
  searchQuery: string;
  userEmailError: string;
  successDialog: boolean;
  failDialog: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class InteractivefaqsSharedController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  value: any;
  editorState: any;
  faqApiCallId: any;
  deleteFaqApiCallId: any;
  addFaqApiCallId: any;
  richtext: any
  editor: any;
  createHelpTicketApiCallId: string = '';
  apiGetPopularFaq: any;
  apiGetBuyerFaq: any;
  onChange: (editorState: any) => void;
  setEditor: (editor: any) => void;
  focusEditor: () => void;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.editorState = null;//createEditorStateWithText("");

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      token: "",
      faq: [],
      isVisible: false,
      activeId: 0,
      activeTitle: "",
      activeContent: "",
      activeCreatedAt: "",
      activeUpdatedAt: "",
      title: "",
      content: "",
      value: this.value,
      generalFaqData: [],
      BuyerFaqData: {
        buyer: [],
        safety_and_guidline: [], 
        general_for_buyer: [], 
        popular_question: []
      },
      openAccordions: {
        buyers: false,
        general: false,
        safety: false,
        write: false
      },
      userName: '',
      userEmail: '',
      description: '',
      selectedAccor: '',
      searchQuery: '',
      userEmailError: '',
      successDialog: false,
      failDialog: false
    };
    this.onChange = (value) => { this.value = value; this.setState({ value: value }) };
    this.setEditor = (editor) => {
      this.editor = editor;
    };
    this.focusEditor = () => {
      if (this.editor) {
        this.editor.focus();
      }
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      this.handleSessionResponse(message);
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      this.handleRestApiResponse(message);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getBuyerFaqsData();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener('willFocus', () => {
        this.getToken();
      });
    }

  }

  getToken = () => {
    const msg: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(msg);
  }

  handleSessionResponse(message: Message) {
    const sessionToken = message.getData(getName(MessageEnum.SessionResponseToken));
    this.setState({ token: sessionToken });
    this.getFaq(sessionToken);
  }

  handleRestApiResponse(message: Message) {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

    if (responseJson) {
      this.handleApiResponseWithData(apiRequestCallId, responseJson);
    } else if (errorResponse) {
      this.handleApiErrorResponse(responseJson, errorResponse);
    }
  }

  handleApiResponseWithData(apiRequestCallId: string, responseJson: any) {
    if (apiRequestCallId === this.faqApiCallId) {
      this.setState({ faq: responseJson.data.faqs });
    } else if (apiRequestCallId === this.addFaqApiCallId) {
      this.props.navigation.goBack();
    } else if (apiRequestCallId === this.deleteFaqApiCallId && !responseJson.data) {
      this.setState({ isVisible: false });
      this.getFaq(this.state.token);
    }
    this.handleSpecialApiRequests(apiRequestCallId, responseJson);
  }

  handleApiErrorResponse(responseJson: any, errorResponse: any) {
    this.parseApiErrorResponse(responseJson);
    this.parseApiCatchErrorResponse(errorResponse);
  }

  handleSpecialApiRequests(apiRequestCallId: string, responseJson: any) {
    switch (apiRequestCallId) {
      case this.apiGetBuyerFaq:
        this.setBuyerFaqData(responseJson);
        break;
      case this.createHelpTicketApiCallId:
        this.resetFormData(responseJson);
        break;
      default:
        break;
    }
  }

  resetFormData = (responseJson: any) => {
    if (responseJson.help_and_support) {
      this.setState({
        successDialog: true,
        userName: '',
        userEmail: '',
        description: ''
      });
    } else if (responseJson.errors) {
      this.setState({
        failDialog: true
      })
    }
  }

  handleSuccessDialogBox = () => {
    this.setState({
      successDialog: !this.state.successDialog
    })
  }

  handleFailDialogBox = () => {
    this.setState({
      failDialog: !this.state.failDialog
    })
  }

  setModal = (item: any) => {
    this.setState({
      activeId: item.id,
      activeTitle: item.title,
      activeContent: item.content,
      activeCreatedAt: item.created_at,
      activeUpdatedAt: item.updated_at,
      isVisible: !this.state.isVisible
    })
  }
  hideModal = () => {
    this.setState({ isVisible: !this.state.isVisible });
  }

  addFaq() {
    //@ts-ignore
    this.props.navigation.navigate("AddInteractivefaqs")
  }

  setTitle = (txt: string) => {
    this.setState({ title: txt })
  }

  deleteFAQ(id: number) {
    this.deleteFaqApiCall(configJSON.faqApiEndPoint + `/${id}`)
  }


  setBuyerFaqData = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.setState({
        BuyerFaqData: responseJson.data
      });
    }
  }

  handleUserNamefaq = (event: any) => {
    this.setState({
      userName: event.target.value
    })
  }

  handleUserEmailfaq = (event: any) => {
    this.setState({
      userEmail: event.target.value
    })
  }

  handleDescriptionfaq = (event: any) => {
    this.setState({
      description: event.target.value
    })
  }

  validateEmail = (): boolean => {
    const { userEmail } = this.state;
    if (!userEmail || !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(userEmail)) {
    this.setState({ userEmailError: "Enter valid email i.e. johndoe@gmail.com"});
      return false;
    }
    this.setState({ userEmailError: "" });
    return true;
  }

  writeUsFaq = async () => {
    const { userEmail, userName, description } = this.state;
    let email = this.validateEmail();
    if (email) {
      const httpBody = {
        "help": {
          "name": userName,
          "email": userEmail,
          "message": description
        }
      }
      const header = { "Content-Type": "application/json", "token": await getStorageData("buerLoginToken") };
      const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
      this.createHelpTicketApiCallId = requestMessage.messageId;
      requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), 'bx_block_help_centre/help_and_supports');
      requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
      requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
      requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  toggleAccordion = (accordionType: string) => {
    switch (accordionType) {
      case 'buyers':
      case 'general':
      case 'safety':
      case 'write':
        this.setState((prevState) => ({
          openAccordions: {
            ...Object.keys(prevState.openAccordions).reduce((acc: Record<string, boolean>, key) => {
              acc[key] = key === accordionType ? !prevState.openAccordions[key] : false;
              return acc;
            }, {})
          }
        }))
        break;
      default:
        break;
    }
  }
  getBuyerFaqsData: any = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetBuyerFaq = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.generalFaqApiEndPointBuyer
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleSearchInputChange = (event: any) => {
    this.setState({ searchQuery: event.target.value });
  };

  getFilteredPopularFaqData = () => {
    const { searchQuery, BuyerFaqData } = this.state;
    const faqCategories = Object.keys(BuyerFaqData);
    const filteredFaqs: typeof BuyerFaqData.buyer = [];
    faqCategories.forEach((category: string) => {
      const categoryWiseFilteredFaqs: typeof BuyerFaqData.buyer = BuyerFaqData[category as keyof typeof  BuyerFaqData].filter((faqItem) => faqItem.title.toLowerCase().includes(searchQuery.toLowerCase()) || faqItem.content.toLowerCase().includes(searchQuery.toLowerCase()))
      filteredFaqs.push(...categoryWiseFilteredFaqs)
    })
    return filteredFaqs;
  }

  addFaqCall = () => {
    if (this.state.title.trim() === "") {
      this.showAlert(configJSON.configError, configJSON.configErrorTitle)
      return false;
    }
    else if (this.state.content === "") {
      this.showAlert(configJSON.configError, configJSON.configErrorContent)
      return false;
    }
    else {
      var data = {
        "title": this.state.title,
        "content": this.state.content
      }

      const header = {
        "Content-Type": configJSON.faqApiApiContentType,
        "token": this.state.token
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addFaqApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.faqApiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    };
  }

  deleteFaqApiCall = (endPointURL: string,) => {
    const header = {
      "Content-Type": configJSON.faqApiApiContentType,
      "token": this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteFaqApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPointURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getFaq = (token: string) => {
    const header = {
      "Content-Type": configJSON.faqApiApiContentType,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.faqApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.faqApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getPopularFaqsData: any = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetPopularFaq = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.generalFaqApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Customizable Area End
}